import React, { useState } from 'react';
import infoIconBlack from '@images/icons/info-black-rebrand-new.svg'; //use the existing asset and avoid duplicates
import infoIconGreen from '@images/icons/homePage/infoIconActive.svg';
import { formatDataContext } from '@/utils/homePageUtil';
import { Popover } from 'antd';
import { useBetterNavigate } from '@/utils';
import { useHomePageContext } from '@/contexts/HomepageContext';
import { useMyHomePageContext } from '@/contexts/MyHomePageContext';

interface TileFooterProps {
    className: string;
    tile: any;
    userUpdatedFilterValues: any;
}

export function TileFooter({ className, tile, userUpdatedFilterValues }: TileFooterProps) {
    const { reportletData } = useMyHomePageContext();

    const [isHovered, toggleHover] = useState(false);
    const [extraInfo, setExtraInfo] = useState(false);
    const navigate = useBetterNavigate();

    const extraInfoHandler = () => {
        setExtraInfo(true);
    };
    const toggleHoverState = async () => {
        toggleHover(!isHovered);
    };
    const dataContextPopover = (data) => {
        return (
            <div className={`${className}__popover`} key={data}>
                {data.map((context) => (
                    <div className={`${className}__popover_context`} key={context.key}>
                        <div className="context_key">
                            <b>{`${context.key}:`}&nbsp;&nbsp;</b>
                        </div>
                        <div className="context_value">
                            {(context.key === 'Focus Brand' && typeof context.value !== String)
                                ? Object.keys(context.value)
                                      .map((key) => context.value[key])
                                      .join(', ')
                                : context.value}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={`${className}__footer`} key={tile?.id}>
            {reportletData.linkToDefault ? <b>Data Context: Defaults</b> : <b>Data Context: Custom</b>}
            <Popover
                content={dataContextPopover(formatDataContext(tile, navigate, extraInfo, extraInfoHandler))}
                placement={'bottom'}
                trigger="hover"
            >
                <img
                    className={`${className}__icon`}
                    width={16}
                    height={16}
                    src={isHovered ? infoIconGreen : infoIconBlack}
                    onMouseEnter={() => toggleHoverState()}
                    onMouseLeave={() => toggleHoverState()}
                />
            </Popover>
        </div>
    );
}
