import { ByzzerLink } from '@/components/form';
import React from 'react';
import parse from 'react-html-parser';
import iconassortmentactionalert from '@/images/icons/alerticons/icon-assortment-action-alert.svg';
import iconassortment from '@/images/icons/alerticons/icon-assortment.svg';
import iconbrandtrend from '@/images/icons/alerticons/icon-brand-trend.svg';
import icongrowthalert from '@/images/icons/alerticons/icon-growth-alert.svg';
import iconmarketshare from '@/images/icons/alerticons/icon-market-share.svg';
import iconmarkettrendalert from '@/images/icons/alerticons/icon-market-trend-alert.svg';
import iconmarkettrends from '@/images/icons/alerticons/icon-market-trends.svg';
import iconpricepromotiontrend from '@/images/icons/alerticons/icon-price-position-trend.svg';
import iconpricing from '@/images/icons/alerticons/icon-pricing.svg';
import iconpromotions from '@/images/icons/alerticons/icon-promotions.svg';
import {
    coloredSpan, formatDateMMDDYYYY, formatPercent, 
    formatPercentWithoutSign, formatPercentWithSign, getDirection, 
    getDirectionWithNullCheck, greenColor, numberWith2Decimal, 
    redColor, returnColorNameByDirection, returnNewtext, toTitleCase, 
    txtColor, valueCheck
} from '../utils/alertUtil2_0';
import {useBetterNavigate} from "@/utils";
import { ByzzerButton } from '@byzzer/ui-components';
import { focusKPIOptions } from '@/components/FocusKPISelector/focusKPI.constants';

function AlertCardView2_0(props) {

    const navigate = useBetterNavigate();
    let alert = props.CardData;
    let viewReportAlertsSKU = props.viewReportAlertsSKU;
    const alertMap = props.alertMap;
    let results = alert.results;
    let alertResults = JSON.parse(JSON.stringify(results).replace(/Fmcg/g, 'FMCG'));
    let alertConfig = alert.actionConfig.insightParams ? alert.actionConfig.insightParams : alert.actionConfig;
    alertConfig.focus_brand = toTitleCase(alertConfig.focus_brand);
    alertResults.filter_brand = toTitleCase(alertResults.filter_brand);
    alertConfig.category = toTitleCase(alertConfig.category);  
    let custom_category ;

    const blurbValuePrefix = alertConfig?.focusKPI === 'Dollar Sales' ? '$' : '';
    const blurbValueSuffix = `${(Boolean(!blurbValuePrefix) ? ' ' : '')}${focusKPIOptions.find(({value}) => value === alertConfig?.focusKPI)?.data?.suffixDisplay}`;

    if(alertMap[alert.productSku] === 'MARKET_SHARE' || alertMap[alert.productSku] === 'ECOSYSTEM_ALERT' || alertMap[alert.productSku] === 'GROWTH_ALERT'){
        
        if (results.origParam !== undefined && results.origParam !== null) {
            custom_category = results.origParam.productSelections[0].category.length > 1 ? "the categories" : toTitleCase(results.origParam.productSelections[0].category[0]);
        } else {
            custom_category = alertConfig.category;
        }
    }
    
    // ------------------------------------CORE ALERTS------------------------------------

    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'MARKET_SHARE') {
        
        let share_of_category_change_gain_or_decline = getDirection(
            alertResults.share_of_category_change_gain_or_decline.val,
            'gain',
            'decline',
            'change'
        );

        alert.title = 'Market Share Alert';
        alert.blurb = parse(
            `${alertResults.filter_brand}'s share of ${custom_category} is ${formatPercent(
                alertResults.share_of_category
            )}%, ${txtColor(`${share_of_category_change_gain_or_decline} of 
                ${formatPercent(
                alertResults.share_of_category_change
            )} points`, `
            ${share_of_category_change_gain_or_decline}
            `)
            }.`
        );
        alert.iconPath = iconmarketshare;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'COMPETITIVE_PRICING') {
        alert.title = 'Pricing Alert';
        alert.blurb = parse(
            `${alertResults.filter_brand}'s pricing position vs. competitors is 
             ${txtColor(`${formatPercentWithSign(alertResults.ratio_change.value)}%`, alertResults.ratio_change.value, true)}.`
        );
        alert.iconPath = iconpricing;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'DISTRIBUTION_GAIN_AND_LOSSES') {
        let share_of_tdp_diff_direction = getDirection(
            alertResults.share_of_tdp_diff_direction.val,
            'increased',
            'decreased',
            'changed'
        );

        alert.title = 'Distribution Alert';
        alert.blurb = parse(
            `${alertResults.filter_brand}'s share of distribution ${txtColor(`${share_of_tdp_diff_direction} by 
            ${formatPercent(Math.abs(alertResults.share_of_tdp_diff))} share points`, share_of_tdp_diff_direction)}.`
        );
        alert.iconPath = iconassortment;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'PROMOTION_EFFICIENCY') {
        let defParams = {
            positive: 'gained',
            negative: 'declined',
            flat: 'changed',
            delisted: 'declined',
            defValue: '',
            ty: alertResults.change_promotion_efficiency_direction.ty,
            ly: alertResults.change_promotion_efficiency_direction.ly,
        };
        let change_promotion_efficiency_direction = getDirectionWithNullCheck(alertResults.change_promotion_efficiency_direction.value, defParams);

        alert.title = 'Promotion Efficiency Alert';
        let change_promotion_efficiency_txt = `${returnNewtext
            (
                `${txtColor
                    (
                            `${change_promotion_efficiency_direction} by
                            ${formatPercent(Math.abs(alertResults.change_promotion_efficiency_direction.value))}%`,
                            `${change_promotion_efficiency_direction}`
                    )}`, 
                change_promotion_efficiency_direction
            )}`;

        if(change_promotion_efficiency_txt !== ''){
            change_promotion_efficiency_txt = ', ' + change_promotion_efficiency_txt;
        }
        alert.blurb = parse(
            `${alertResults.filter_brand}'s promotion efficiency is $${numberWith2Decimal(alertResults.promotion_efficiency)}${change_promotion_efficiency_txt}.`
        );
        alert.iconPath = iconpromotions;       
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'ECOSYSTEM_ALERT') {
        let defParams = {
            positive: 'up',
            negative: 'down',
            flat: 'flat',
            delisted: 'delisted',
            defValue: 'new',
            ty: alertResults.category_sales_change_direction.ty,
            ly: alertResults.category_sales_change_direction.ly,
        };

        let category_sales_change_direction = getDirectionWithNullCheck(alertResults.category_sales_change_direction.value, defParams);
        alert.title = 'Market Performance Alert';        
        let alertResults_category_sales_change_display_market_performance = valueCheck(category_sales_change_direction) ? ` ${formatPercent(Math.abs(alertResults.category_sales_change_direction.value))}%` : '';        
        let market_custom_category = (custom_category === "the categories") ? "The categories'" : custom_category ;
        alert.blurb = parse(
            `${market_custom_category} sales are ${blurbValuePrefix}${numberWith2Decimal(
                alertResults.category_sales
            )}${blurbValueSuffix}, ${txtColor(`${category_sales_change_direction}${alertResults_category_sales_change_display_market_performance}`, category_sales_change_direction)}.`
        );

        alert.iconPath = iconmarkettrends;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'GROWTH_ALERT') {
        let defParams = {
            positive: 'up',
            negative: 'down',
            flat: 'flat',
            defValue: 'new',
            delisted: 'delisted',
            ty: alertResults.category_sales_change_direction.ty || '', 
            ly: alertResults.category_sales_change_direction.ly || '', 
        };
        let category_sales_change_direction = getDirectionWithNullCheck(alertResults.category_sales_change_direction.value, defParams);

        alert.title = 'Growth Alert';
        alert.blurb = parse(
            `${alertResults.filter_brand} sales in ${custom_category} were
             ${valueCheck(category_sales_change_direction)
                ? `${txtColor(`${category_sales_change_direction} 
                        ${formatPercentWithoutSign(alertResults.category_sales_change)}%`, 
                        `${category_sales_change_direction}`)}`
                : `${category_sales_change_direction}`}        
            vs. the previous 4 weeks.`
        );
        alert.iconPath = icongrowthalert;  
    }

    // ------------------------------------SMART ALERTS------------------------------------

    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'BRAND_TREND_ALERT') {
        let defParams = {
            positive: 'up',
            negative: 'down',
            flat: 'flat',
            delisted: 'Delisted',
            defValue: 'new',
            ty: '',
            ly: '',
        };

        let sales_change_direction = getDirectionWithNullCheck(alertResults.sales_change_direction.value, defParams);
        alert.title = 'Brand Trend Alert';
        alert.blurb = parse(
            `${toTitleCase(alertResults.sales_change_brand)} in ${toTitleCase(alertResults.sales_change_category)} is 
                
             ${coloredSpan(`${returnColorNameByDirection(alertResults.sales_change_direction.value)}`, sales_change_direction)}
                ${valueCheck(sales_change_direction)
                    ?   `${coloredSpan(`${returnColorNameByDirection(alertResults.sales_change_direction.value)}`, `${formatPercent(Math.abs(alertResults.sales_change_direction.value))}%`)}`
                    : ''}
                    in sales this week vs. the previous 7 weeks.`
        );
        alert.iconPath = iconbrandtrend;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'PRICE_POSITION_TREND_ALERT') {
        alert.title = 'Price Position Trend Alert';
        alert.blurb = parse(
            `${toTitleCase(alertResults.filter_brand)} in ${toTitleCase(alertResults.filter_category,true)} is priced at the ${formatPercent(Math.abs(alertResults.price_percentile))}% percentile in ${toTitleCase(alertResults.top_market)}.`
        );
        alert.iconPath = iconpricepromotiontrend;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'MARKET_TREND_ALERT') {
        let defParamsSale = {
            positive: 'up',
            negative: 'down',
            flat: 'flat',
            defValue: 'new',
            delisted: 'delisted',
            ty: alertResults.sales_change_direction.ty,
            ly: alertResults.sales_change_direction.ly,
        };

        let sales_change_direction = getDirectionWithNullCheck(alertResults.sales_change_direction.value, defParamsSale);   
        alert.title = 'Market Trend Alert';
        let alertResults_category_sales_change_display_market_performance = valueCheck(alertResults.category_sales_change_direction) ? ` ${formatPercent(alertResults.category_sales_change)}%` : '';
        alert.blurb = parse(
            `Market is 
            ${coloredSpan(`${returnColorNameByDirection(sales_change_direction)}`, sales_change_direction)} 
            ${coloredSpan(`${returnColorNameByDirection(sales_change_direction)}`, `${formatPercentWithoutSign(alertResults.sales_change_direction.value)}%`)} 
            in sales this week vs. the previous 7 weeks`       
        );
        alert.iconPath = iconmarkettrendalert;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'ASSORTMENT_ACTION') {
        alert.title = 'Assortment Opportunity Alert';
        let duration = formatDateMMDDYYYY(alertResults.filter_duration.split('to')[1].trim());
        alert.blurb = parse(
            `There is ${coloredSpan(redColor, `$${numberWith2Decimal(alertResults.at_risk_dollar_impact)} at risk`)} and ${coloredSpan(greenColor, `$${numberWith2Decimal(alertResults.expand_dollar_impact)} in opportunities`)} to expand `
        );
        alert.iconPath = iconassortmentactionalert;
    }

    const redirectToReport = async () => {
        navigate(`/dashboard/my_reports/views/${alert.productSku}`);
    };

    return (
        <>
            <div className="card">
                <div className="card_image">
                    <img src={alert.iconPath} alt={alert.title} />
                </div>
                <h2 className="card_heading_report">{alert.title}</h2>
                <p className="card_blurb">{alert.blurb}</p>

                <ByzzerButton
                    label={'See More'}
                    onClick={() => {
                        props.handleClick(alert);
                    }}
                />
            </div>

        </>
    );
}

export default AlertCardView2_0;
