import {DodColDef, DodDimension, DodDimensionType, SortType} from "@/types/DodRun";

export type DodFilterType<FilterType extends string = string, DataType = any> = {
    type: FilterType;
    /**
     * Arbitrary payload that can hold extra information about the type of filter
     * (i.e. characteristic code and display no characteristics)
     */
    data?: DataType;
}

export type DodProductFilterType =
    'departments' |
    'superCategories' |
    'categories' |
    'subcategories' |
    'upcs' |
    'productDescriptions' |
    'parentCompanies' |
    'manufacturers' |
    'brands' |
    'characteristics' |
    'customCharacteristics' |
    'ppgs'

export type DodMarketFilterType = 'all' | 'channels' | 'accounts' | 'stateLines' | 'majorMarkets' | 'regions' | 'divisions';

export type DodTimePeriodFilterType = 'latestDates' | 'weeks' | '4weeks' | 'months' | 'quarters' | 'years' | 'custom' | 'saved' | '4StaticWeeks' | 'previousPeriods';

export type DodFactFilterType =  any;

export enum DodTimePeriodFilterTypes {
    TIME_PERIODS = 'timePeriods',
    LATEST_DATES = 'latestDates',
    WEEKS = 'weeks',
    FOUR_WEEKS = '4weeks',
    MONTHS = 'months',
    QUARTERS = 'quarters',
    YEARS = 'years',
    SUMMED = 'summedTimePeriods',
    CUSTOM_TIME_PERIOD = 'customTimePeriod',
    FOUR_WEEKS_STATIC = '4StaticWeeks',
    PREVIOUS_PERIOD_DATES = 'previousPeriods',
}

export enum DodProductFilterTypes {
    DEPARTMENTS = 'departments',
    SUPER_CATEGORIES = 'superCategories',
    CATEGORIES = 'categories',
    SUBCATEGORIES = 'subcategories',
    UPC = 'upcs',
    PRODUCT_DESCRIPTIONS = 'productDescriptions',
    PARENT_COMPANIES = 'parentCompanies',
    MANUFACTURERS = 'manufacturers',
    BRANDS = 'brands',
    CHARACTERISTICS = 'characteristics',
    CUSTOM_CHARACTERISTICS = 'customCharacteristics',
    PPGS = 'ppgs'
}

export enum DodProductParentFilterTypes {
  hierarchy = 'hierarchy',
  brandAndManufacturer = 'brandAndManufacturer',
  characteristics = 'characteristics',
  customCharacteristics = 'customCharacteristics',
  ppgs = 'ppgs'
}

export enum DodTimePeriodFilterTypeValueMap {
    latestDates = 'pe',
    weeks = '1w',
    '4weeks' = '4w',
    months = '1m',
    quarters = '1q',
    years = '1y',
    '4StaticWeeks' = '4s',
    previousPeriods = 'pp'
}

export enum DodSavedSelectionTypes {
  MARKET = 'market',
  PRODUCT = 'product',
  TIME_PERIOD = 'time_period',
}

export interface RowColConfig {
    axis: 'row' | 'col';
    /**
     * I think this can be eventually be removed because the type of the dimension irrelevant.
     * But this greatly in the interim to simplify support for the legacy data structure
     */
    type: DodDimensionType;
    /**
     * The actual dimension that should be displayed on the axis.  This can be any of the known dimensions or a
     * characteristic or custom characteristic name.  Need to determine if this Should be the name or the of the
     * characteristic.
     */
    dim: DodDimension | string;
    /**
     * Direction the values will be sorted.  Defaults based on dimension type
     */
    sortType: SortType | 'default';
    /**
     * All of the values available for the dimension
     */
    values: string[];
    /**
     * Should the dimensions values be used to create tabs in Excel or pages in csv.  Defaults to false.
     */
    pageBy: boolean;
    /**
     * The dimension values be hidden in the output.  Defaults to false.
     */
    hide: boolean;
    /**
     * defaults to false.
     */
    stack: boolean;
}

export type DodRunConfigWizardMode = 'create' | 'edit' | 'layout' | 'editSchedule' | 'editScheduleSeries';

export interface DodPresetConfig {
    /**
     * Overall order of dimension types
     */
    order: DodDimensionType[];
    /**
     * Mapping of dimension types to axes
     */
    axes: Record<DodDimensionType, 'row' | 'col'>;
    /**
     * Overrides to apply to the dimensions.  hide, pageBy and stack default to false. sortType defaults to 'default'
     */
    dimConfigs: Record<string, Partial<Pick<RowColConfig, 'hide' | 'pageBy' | 'stack' | 'sortType'>>>;
}
