import React from 'react';
import { AlertRunConfig } from '@/types/AlertRun';
import { getRunConfigOptionsBySku, getFlattenedRunConfigOptionsBySku } from '@/services/product.service';
import { useUser } from '@/contexts/UserContext';
import { MarketRunConfigOptions, ProductRunConfigOptions } from '@/types/RunConfigOptions';
import { AlertRunConfigOptions } from "@/types/AlertRunConfigOptions";
import { useTenantApi } from '@/hooks/useTenantApi';

export type AlertRunValidationError = {
    prop: string;
};

export const useAlertRunService = () => {
    const { defaultRunConfig, user} = useUser();
    const { getCategoriesByBrands} = useTenantApi();

    // console.log(`alertRun.service - useAlertRunService fired - 'defaultRunConfig' ===>>`, defaultRunConfig);

    return {
        /**
         * Caution this is not implemented yet
         * @param fromSku
         * @param toSku
         * @param runConfig
         */
        async getDefaultAlertRunConfigBySku(sku?: string | null): Promise<Partial<AlertRunConfig>> {
            let includedRunConfigValues: Partial<AlertRunConfig> = {
                productSelections: [],
                focusBrands: [],
                categories: [],
                characteristics: [],
                markets: []
            };

            if (!sku) return includedRunConfigValues;

            const runConfigOptions = getRunConfigOptionsBySku(sku);
            const flattenedConfigOptions = getFlattenedRunConfigOptionsBySku(sku);
            //TODO: create one more const which should set default time period for omni reports     
            const hasMarketRunConfigOptions = Boolean(runConfigOptions?.find(({ type }) => type === 'market'));

            const defaultCategoriesLength = Number(defaultRunConfig?.categories?.length ?? 0);
            const defaultBrandsLength = Number(defaultRunConfig?.brands?.length ?? 0);
            const defaulMarketsLength = Number(defaultRunConfig?.markets?.length ?? 0);

            const maxBrands = flattenedConfigOptions?.maxBrands ?? flattenedConfigOptions?.maxFocusBrands ?? Infinity;
            const maxCategories = flattenedConfigOptions?.maxCategories ?? Infinity;
            const maxMarkets = flattenedConfigOptions?.maxMarkets ?? Infinity;

            if (
                flattenedConfigOptions.includeProductSelections
            ) {
                const defaultBrands = defaultRunConfig?.brands ?? [];
                const defaultCategories = defaultRunConfig?.categories ?? [];

                includedRunConfigValues.productSelections = [{
                    focusBrands: defaultBrandsLength <= maxBrands ? defaultBrands : [],
                    categories: defaultCategoriesLength <= maxCategories ? defaultCategories : []
                }]
            }

            if (
                flattenedConfigOptions.includeFocusBrands &&
                Boolean(defaultRunConfig?.brands?.length) && 
                defaultBrandsLength <= maxBrands
            ) {
                includedRunConfigValues.focusBrands = [defaultRunConfig?.brands ?? []].flat();
            }

            if (
                flattenedConfigOptions.includeCategories &&
                Boolean(includedRunConfigValues?.focusBrands?.length) && 
                defaultCategoriesLength <= maxCategories
            ) {
                const categoriesForBrands =  await getCategoriesByBrands(includedRunConfigValues?.focusBrands!);
                // Filtering out category defaults that don't overlap with categories for Alerts
                const filteredDefaultCategories = defaultRunConfig?.categories?.filter((dfltCat) => categoriesForBrands.includes(dfltCat)) ?? [];
                includedRunConfigValues.categories = [filteredDefaultCategories].flat();
            }

            if (
                flattenedConfigOptions.includeCharacteristics &&
                Boolean(includedRunConfigValues?.categories?.length)
            ) {
                includedRunConfigValues.characteristics = defaultRunConfig?.characteristics;
            }

            // markets only apply to rms reports for now.
            // todo: consider adding support different market types
            if (
                hasMarketRunConfigOptions &&
                flattenedConfigOptions.datatype === 'rms' &&
                !flattenedConfigOptions.requireRemainingMarket &&
                Boolean(defaultRunConfig?.markets?.length) &&
                (Boolean(includedRunConfigValues?.categories?.length) || Boolean(includedRunConfigValues.productSelections?.[0]?.categories?.length)) &&
                defaulMarketsLength <= maxMarkets
            ) {
                includedRunConfigValues.markets = defaultRunConfig?.markets;
            }

            return includedRunConfigValues;
        },

        validateRunConfigForSku(
            sku: string | undefined,
            runConfig: AlertRunConfig
        ): boolean | AlertRunValidationError[] {
            if (!sku) return false;

            const { datatype, ...allRunConfigOptions } = getFlattenedRunConfigOptionsBySku(sku);

            const runConfigOptions = getRunConfigOptionsBySku(sku);

            const marketRequired = Boolean((runConfigOptions as AlertRunConfigOptions[])?.find(({ type }) => type === 'market'));
            const marketsToWatchRequired = Boolean((runConfigOptions as AlertRunConfigOptions[])?.find(({ type }) => type === 'markets_to_watch'));

            const hasMarkets = Boolean(runConfig.markets?.length);
            const hasMarketsToWatch = Boolean(runConfig.marketsToWatch?.channel?.length);

            const hasAllRequiredValues = Boolean(
                (!allRunConfigOptions.requireFocusBrands || runConfig.focusBrands?.length) &&
                (!allRunConfigOptions.requireCategories || runConfig.categories?.length) &&
                (!allRunConfigOptions.requireProductSelections || runConfig.productSelections?.every((ps) => Boolean(ps?.categories?.length) && Boolean(ps?.focusBrands?.length))) &&
                (!allRunConfigOptions.requireCharacteristics || runConfig.characteristics?.every((c) => Boolean(c?.characteristic) && Boolean(c?.condition) && Boolean(c?.value))) &&
                (hasMarkets || !marketRequired) && 
                (hasMarketsToWatch || !marketsToWatchRequired) && 
                (Boolean(runConfig?.notification?.recipientEmails?.length))
            );
            return hasAllRequiredValues; 
        },

    };
};
