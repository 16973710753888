import React, { useState } from 'react';
import ByzzerModal2 from '@/components/modals/ByzzerModal2';
import { ByzzerButton } from '@byzzer/ui-components';

const filterBaseClassName = 'filter_panel';

function FilterMessageModal({ handleApply, closeFilterPanel, showWarningMessagePopup }) {
    return (
        <>
            {showWarningMessagePopup && (
                <ByzzerModal2 className={`${filterBaseClassName}__byzzer-modal`} wrapperClassName type>
                    <ByzzerModal2.Content className={`${filterBaseClassName}__byzzer-modal`}>
                        <div className="filter_panel__popup_button">
                            You haven’t applied your selections yet. Do you want to apply them now?
                            <div className="filter_panel__popup_button_apply">
                                <ByzzerButton
                                    className="filter_panel__popup_button_apply_button"
                                    label={'Apply selections'}
                                    onClick={handleApply}
                                />
                            </div>
                            <div className="filter_panel__popup_button_close">
                                <ByzzerButton
                                    className="filter_panel__popup_button_close_button"
                                    type={'outline'}
                                    label={'Close without applying selections'}
                                    onClick={closeFilterPanel}
                                />
                            </div>
                        </div>
                    </ByzzerModal2.Content>
                </ByzzerModal2>
            )}
            ;
        </>
    );
}

export default FilterMessageModal;
