import React from 'react';
import DownArrow from '@images/icons/down-arrow.svg';
import EyeIcon from '@images/icons/eyeOpenIcon.svg';
import DownloadIcon from '@images/icons/niq-download-dark.svg';
import { useEffect, useState } from 'react';
import { ByzzerCollapseEvent } from './ByzzerExpandableTable';
import { Column } from './ByzzerExpandableTableColumn';
import classNames from 'classnames';
import InlineSVG from 'react-inlinesvg/esm';
import { format } from 'date-fns';
import {useTenantApi} from '@/hooks/useTenantApi';
import { Spinner } from '@/components/Spinner/Spinner';
import { MarketMap } from '@/types/MarketMaps';

interface ByzzerTableRowProps {
    data: any;
    columns: Column[];
    childLevel: number;
    childDataKey?: string;
    collapseAllEvent: ByzzerCollapseEvent;
    hideChildren: ByzzerCollapseEvent;
    className?: string;
    rowKey?: string;
    rowColor?: (rowData: any) => string | undefined;
    visibleOnInit?: (rowData: any) => boolean;
    expandParent: () => void;
}

const ExpandableTableRow: React.FC<ByzzerTableRowProps> = ({
    data,
    columns,
    childLevel,
    childDataKey,
    collapseAllEvent,
    hideChildren,
    className,
    rowKey,
    rowColor,
    visibleOnInit,
    expandParent,
}) => {
    //controls whether child rows are displayed as well as the rotated state of the collapse icon
    const [collapsed, setCollapsed] = useState(true);
    const { downloadMarketMapFile, downloadMarketMapsFolder } = useTenantApi();
    const [hidden, setHidden] = useState(true);
    const [downloadRow, setDownloadRow] = useState({});
    const [hideRowChildren, setHideRowChildren] = useState<ByzzerCollapseEvent>({
        timestamp: 0,
        collapse: undefined,
    });

    //function to be passed to the child rows, expands the parent
    // const expand = () => {
    //     setCollapsed(false);
    //     setHidden(false);
    //     expandParent();
    // };
    //     //if this row is to be displayed on initialization, we do not want to expand it or any
    //     //of its children. this check can be removed to make visible all children that return true from visibleOnInit
    const expand =
        visibleOnInit && visibleOnInit(data)
            ? () => {}
            : () => {
                  setCollapsed(true);
                  setHidden(false);
                  expandParent();
              };
    const iconClasses = `${className}--icon`;
    const collapseIconClasses = classNames(`${className}--icon`, `${collapsed ? `${className}--icon-collapsed` : ''}`);
    const childLevelClasses = classNames(`${className}__data`, `child-${childLevel}`);

    const trClasses = classNames(className, `${hidden && childLevel !== 0 ? `${className}-display-none` : ''}`);
    const trStyles = rowColor && rowColor(data) ? { backgroundColor: rowColor(data) } : {};

    //componentDidMount
    useEffect(() => {
        if (visibleOnInit && visibleOnInit(data)) {
            expandParent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data && data.expand && data.fieldPath == 'folder.file') {
            expandParent();
        }
    }, [data]);

    useEffect(() => {
        if (hidden) {
            setCollapsed(true);
        }

        if (!hidden && !collapsed) {
            setHideRowChildren({ collapse: false, timestamp: Date.now() });
        }
    }, [hidden]);

    useEffect(() => {
        if (typeof collapseAllEvent.collapse == 'boolean') {
            setCollapsed(collapseAllEvent.collapse);
            if (collapseAllEvent.collapse) {
                setHidden(true);
            } else {
                setHidden(false);
            }
        }
    }, [collapseAllEvent]);

    useEffect(() => {
        if (typeof hideChildren.collapse == 'boolean') {
            if (hideChildren.collapse) {
                setHidden(true);
                setHideRowChildren(hideChildren);
            } else {
                setHidden(false);
            }
        }
    }, [hideChildren]);

    let childCount = 0;
    const childRows: any = data[childDataKey || 'child']?.map((data: any) => {
        if (data?.updatedAt) {
            data = { ...data, updatedAt: format(new Date(data.updatedAt), 'LLLL dd, yyyy') };
        }

        const rowKeyValue = () => {
            if (rowKey) {
                return childCount++ + data[rowKey];
            } else {
                return childLevel + Object.values(data).join();
            }
        };
        return (
            <ExpandableTableRow
                key={rowKeyValue()}
                collapseAllEvent={collapseAllEvent}
                columns={columns}
                data={data}
                childLevel={childLevel + 1}
                childDataKey={childDataKey}
                rowKey={rowKey}
                className={className}
                rowColor={rowColor}
                visibleOnInit={visibleOnInit}
                expandParent={expand}
                hideChildren={hideRowChildren}
            ></ExpandableTableRow>
        );
    });

    const renderCollapse = (rowData: any) => {
        if (rowData[childDataKey || 'child'] && rowData[childDataKey || 'child'].length > 0) {
            return (
                <InlineSVG
                    src={DownArrow}
                    className={collapseIconClasses}
                    onClick={(e) => {
                        setCollapsed(!collapsed);
                        if (!collapsed) {
                            // setHidden(true);
                            setHideRowChildren({ collapse: true, timestamp: e.timeStamp });
                        } else {
                            setHideRowChildren({ collapse: false, timestamp: e.timeStamp });
                        }
                    }}
                />
            );
        }
        return <span className={collapseIconClasses}></span>;
    };

    const downloadFile = async (data: MarketMap) => {
        if (data.fieldPath == 'folder') {
            setDownloadRow({ [data.folderId!]: true });
            await downloadMarketMapsFolder(data.folderId!, data?.folderName);
            setDownloadRow({ [data.folderId!]: false });
        }
        if (data.fieldPath == 'folder.file') {
            setDownloadRow({ [data.fileId!]: true });            
            await downloadMarketMapFile(data);
            setDownloadRow({ [data.fileId!]: false });
        }
    };

    const renderTableDataContents = (column: Column) => {
        if (typeof column.key == 'string') {
            let className = typeof column.class == 'string' ? column.class : column.class?.[0];
            return <span className={className}>{data[column.key]}</span>;
        } else {
            return column.key.map((key, keyIndex) => {
                if (data[key] && key === 'link') {
                    return (
                        <>
                            {/* <InlineSVG className={iconClasses} src={EyeIcon} /> */}
                            {((data['fileId'] in downloadRow && !downloadRow[data['fileId']]) || !(data['fileId'] in downloadRow)) && (
                                <span
                                    //key={data[rowKey] + columnData.key + columnDataIndex}
                                    className={`${className}--anchor-tag`}
                                    onClick={() => {
                                        downloadFile(data);
                                    }}
                                >
                                    <InlineSVG className={iconClasses} src={DownloadIcon} />
                                </span>
                            )}

                            {data['fileId'] in downloadRow && downloadRow[data['fileId']] && (
                                <span className={`${className}--spinner`}>
                                    <Spinner />
                                </span>
                            )}
                        </>
                    );
                } else if (data[key] && key === 'files') {
                    return (
                        <>
                            {((data['folderId'] in downloadRow && !downloadRow[data['folderId']]) || !(data['folderId'] in downloadRow)) && (
                                <span
                                    //key={data[rowKey] + columnData.key + columnDataIndex}
                                    className={`${className}--anchor-tag`}
                                    onClick={() => {
                                        downloadFile(data);
                                    }}
                                >
                                    <InlineSVG className={iconClasses} src={DownloadIcon} />
                                </span>
                            )}

                            {data['folderId'] in downloadRow && downloadRow[data['folderId']] && (
                                <span className={`${className}--spinner`}>
                                    <Spinner />
                                </span>
                            )}
                        </>
                    );
                } else if (data[key]) {
                    let className = typeof column.class == 'string' ? column.class : column.class?.[keyIndex];
                    //let className = classNames[columnDataIndex] || column.class;
                    return (
                        <span
                            //key={data[rowKey] + columnData.key + columnDataIndex}
                            className={className}
                        >
                            {data[key]}
                        </span>
                    );
                }
                return undefined;
            });
        }
    };

    const mapRowTd = (rowKeyValue: any, index: number, column: Column) => {
        let childClasses;
        let collapse;
        let dataClassName;

        if (index === 0) {
            childClasses = childLevelClasses;
            collapse = renderCollapse(data);
            dataClassName = 'row-background-first';
        } else if (index === columns.length - 1) {
            dataClassName = 'row-background-last';
        } else {
            dataClassName = 'row-background-middle';
        }

        return (
            <td key={rowKeyValue + index} className={childClasses} style={trStyles}>
                <div className={classNames(childClasses, 'row-underline')}>
                    <div className={classNames(childClasses, dataClassName)}>
                        {collapse}
                        {renderTableDataContents(column)}
                    </div>
                </div>
            </td>
        );
    };

    const rowData = columns?.map((column, index, arr) => {
        const rowKeyValue = () => {
            if (rowKey) {
                return data[rowKey];
            } else {
                return childLevel + Object.values(data).join();
            }
        };

        return mapRowTd(rowKeyValue(), index, column);
    });

    const key = typeof columns[0].key == 'string' ? columns[0].key : columns[0].key[childLevel];

    return (
        <>
            <tr
                className={classNames(
                    trClasses,
                    childLevel !== 0 ? `${className}--child-row ${childLevel}` : `${className}--parent-row`
                )}
                key={childLevel + data[key]}
            >
                {rowData}
            </tr>
            {childRows}
        </>
    );
};

export default ExpandableTableRow;
