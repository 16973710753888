export const errorCodesInfo = 
{
    'signin': {
        'title': 'There was an issue with your login',
        'errorInfo': 'Invalid Credentials',
        'description': 'Please contact Byzzer Customer Service for support'
    },
    'signup': {
        'title': 'There was an issue with your sign up',
        'errorInfo': '',
        'description': 'Please contact Byzzer Customer Service for support'
    },
    'generic': {
        'title': 'Looks like something went wrong',
        'errorInfo': '',
        'description': 'Please contact Byzzer Customer Service for quick support.'
    },
    'company_not_found': {
        'title': 'Your company could not be verified',
        'errorInfo': '',
        'description': 'Your company is not receiving access to Byzzer at this time. Please contact Customer Service with any questions'
    },
    'duplicate_email': {
        'title': 'Looks like an account already exists with this email address',
        'errorInfo': '',
        'description': 'Try logging in with your existing credentials or reach out to Customer Service for support at byzzer.support@smb.nielseniq.com'
    },
    'invalid_email_address': {
        'title': 'Your email address is not valid, please re-enter your email address',
        'errorInfo': '',
        'description': 'Reach out to Customer Service for support at byzzer.support@smb.nielseniq.com if needed'
    },
    'domain_restricted.competitor': {
        'title': 'Your email address is not valid, please re-enter your email address',
        'errorInfo': '',
        'description': 'Reach out to Customer Service for support at byzzer.support@smb.nielseniq.com if needed'
    },
    'service_unavailable': {
        'title': 'Looks like there is an issue with your account access',
        'errorInfo': '',
        'description': 'Please reach out to Customer Service for support at byzzer.support@smb.nielseniq.com'
    },
    'invalid_password': {
        'title': 'Please re-enter a password',
        'errorInfo': 'Your password requires at least 12 characters, one number, one capital, and a special character.',
        'description': 'Reach out to Customer Service for support at byzzer.support@smb.nielseniq.com if needed.'
    },
    'invalid_phone_number': {
        'title': 'Please update your phone number and re-submit',
        'errorInfo': 'Your phone number must be 10 digits.',
        'description': 'Please reach out to Customer Service for support at byzzer.support@smb.nielseniq.com'
    },
    'domain_restricted.generic': {
        'title': 'Your email address is not valid',
        'errorInfo': '',
        'description': 'Access to Byzzer requires a business email address. Please enter a non-personal email address.'
    },
    'domain_mismatch': {
        'title': 'Your email domain does not match the Account’s on file',
        'errorInfo': '',
        'description': 'Please reach out to Customer Service for support byzzer.support@smb.nielseniq.com'
    },
};