import React, {ChangeEventHandler, ReactNode, useState, useEffect} from 'react';
import classnames from "classnames";

export type ByzzerSwitchProps<ValueType> = {
    label?: ReactNode;
    name?: string;
    value?: ValueType;
    onChange: ChangeEventHandler<HTMLInputElement>;
    className?: string;
    disabled?: boolean;
    children?: ReactNode;
    checked?: boolean;
    labelPosition?: 'left' | 'right';
}

const baseClassName = 'byzzer-switch';

export function ByzzerSwitch(
    {
        children,
        label = children,
        name,
        value,
        onChange,
        className,
        disabled,
        labelPosition = 'right'
    }: ByzzerSwitchProps<any>) {

    const [internalValue, setInternalValue] = useState<boolean>(false);

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    return (
        <label className={classnames(baseClassName, className, {
            [`${baseClassName}--disabled`]: disabled,
            [`${baseClassName}--label-on-${labelPosition}`]: labelPosition
        })}>
            <input checked={internalValue} type={'checkbox'} onChange={onChange} name={name} disabled={disabled}/>
            <div className={classnames(`${baseClassName}__toggle`, {
                [`${baseClassName}__toggle--disabled`]: disabled
            })}/>
            {label && (
                <div className={classnames(`${baseClassName}__label`, {
                    [`${baseClassName}__label--disabled`]: disabled
                })}>
                    {label}
                </div>
            )}
        </label>
    )
}
export default ByzzerSwitch;
