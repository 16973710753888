import './AlertRecipientSelector.scss';
import React, { useState, useEffect, useContext } from 'react';
import { ByzzerChangeEventHandler, ByzzerCheckableChangeEvent } from '@byzzer/ui-components';
import classnames from 'classnames';
import { ByzzerRadio, ByzzerRadioGroup } from '@byzzer/ui-components';
import { useUser } from '@/contexts/UserContext';
import {useTenantApi} from '@/hooks/useTenantApi';
import AlertTeamMemberTable from './AlertTeamMemberTable';
import { nanoid } from 'nanoid';
import { AlertRecipientLabelTipCode, AlertRecipientType, AlertRecipients } from '@/types/AlertRun';
import { SelectorLabelInfo } from '../SelectorLabelInfo';
import { AlertRunConfigWizardContext } from '../ConfigurationEditors/AlertConfigurationEditor/AlertRunConfigWizard/AlertRunConfigWizardContext';
import { TeamMember } from '@/types/InvitationTypes';

const baseClassName = 'alert-recipient-selector';

const recipientTypes: {
    value: AlertRecipientType;
    selectorlabelInfoCode: AlertRecipientLabelTipCode;
    showAsterisk?: boolean;
}[] = [
    { value: 'just-me', selectorlabelInfoCode: 'alertRecipientJustMe' },
    { value: 'my-team', selectorlabelInfoCode: 'alertRecipientMyTeam', showAsterisk: true },
    { value: 'everyone', selectorlabelInfoCode: 'alertRecipientEveryOne', showAsterisk: true },
    { value: 'selected-member', selectorlabelInfoCode: 'alertRecipientSelectedMember' },
];

export type AlertRecipientSelectorProps = {
    name?: string;
    value?: AlertRecipients;
    onChange?: ByzzerChangeEventHandler<AlertRecipients>;
    className?: string;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>;

const MULTI_TENANT_RECIPIENT_FOOTNOTE = 'Note: Team members from third parties are not eligible to receive Alerts.';
export function AlertRecipientSelector({ 
    name, 
    value, 
    onChange, 
    className, 
    ...props 
}: AlertRecipientSelectorProps) {
    const { getTeamMembers } = useTenantApi();
    const [internalValue, setInternalValue] = useState<AlertRecipients | undefined>();
    const { user } = useUser();
    const [allNonMultiTenantMembers, setAllNonMultiTenantMembers] = useState<TeamMember[]>([]);
    const [nonMultiTenantTeamMembers, setNonMultiTenantTeamMembers] = useState<TeamMember[]>([]);
    const [multiTenantMemberEmails, setMultiTenantMemberEmails] = useState<string[]>([]);
    const [allMembersLoaded, setAllMembersLoaded] = useState<boolean>(false);
    const { sku } = useContext(
        AlertRunConfigWizardContext
    );

    useEffect(() => {
        (async () => {
            const teamMembers = await getTeamMembers(); // todo: see if this API can have a type added.
            const multiTenantMemberEmails = teamMembers?.filter((member) => member?.multiTenantEnabled && member?.primaryCompanyId !== user?.primaryCompanyId).map(handleMemberEmailsMap)
            setMultiTenantMemberEmails(multiTenantMemberEmails);
            const nonMultiTenantMembers = teamMembers.filter((member) => !multiTenantMemberEmails.includes(member.email))
            setAllNonMultiTenantMembers(nonMultiTenantMembers);
            setNonMultiTenantTeamMembers(nonMultiTenantMembers.filter((member) => user?.teamId === member?.teamId));
            setAllMembersLoaded(true);
        })();
    }, []);

    useEffect(() => {
        setInternalValue(value);
    }, [value])

    const handleMemberEmailsMap = ({email}: TeamMember) => email;
    
    const handleRecipientTypeChange = (e) => {
        const recipientType: AlertRecipientType = e.value;
        
        let newRecipientEmails: string[];

        switch (recipientType) {
            case 'my-team':
                newRecipientEmails = nonMultiTenantTeamMembers?.map(handleMemberEmailsMap);
                break;
            case 'everyone':
                newRecipientEmails = allNonMultiTenantMembers?.map(handleMemberEmailsMap);
                break;
            case 'just-me':
            case 'selected-member':
            default:
                newRecipientEmails = [user!.email]; // logged in user is always part of notification
        }

        onChange?.({
            name,
            value: {
                recipientType,
                recipientEmails: newRecipientEmails
            },
        });

    }

    const recipientTypeHasNoOptionsShouldBeDisabled = (recipientType: AlertRecipientType): boolean => {
        // If there are no team members, disable the option
        switch (recipientType) {
            case 'my-team':
                return Boolean(!nonMultiTenantTeamMembers?.map(handleMemberEmailsMap)?.length)
            case 'everyone':
            case 'selected-member':
                return Boolean(!allNonMultiTenantMembers?.map(handleMemberEmailsMap)?.length)
            default: 
                return false;
        }
    }

    const handleTeamMemberSelection = (e: ByzzerCheckableChangeEvent<string>, selectedMember: TeamMember): void => {
        let newNotificationEmails = JSON.parse(JSON.stringify(internalValue?.recipientEmails ?? []));

        if (!e?.checked) {
            newNotificationEmails = newNotificationEmails?.filter((newNotificationEmail) => newNotificationEmail !== selectedMember.email)
        } else {
            newNotificationEmails = [...newNotificationEmails, selectedMember?.email]
        }

        onChange?.({
            name,
            value: {
                recipientType: 'selected-member',
                recipientEmails: newNotificationEmails
            }
        });
    }

    const showFootnote = Boolean(multiTenantMemberEmails?.length);

    return (
        <div className={classnames(baseClassName)} { ...props }>
            <div className={classnames(`${baseClassName}__recipient-type-options`)}>
                <ByzzerRadioGroup name={name} value={internalValue?.recipientType}>
                    {recipientTypes?.map(({showAsterisk, ...recipientType}) => {
                        const asterisk = showAsterisk && showFootnote ? '*' : '';
                        return (
                            <ByzzerRadio
                                className={classnames(`${baseClassName}__label`, {
                                    [`${baseClassName}__label--no-options-disabled`]: recipientTypeHasNoOptionsShouldBeDisabled(recipientType?.value)
                                })}
                                name={name}
                                label={<><SelectorLabelInfo sku={sku!} selectorCode={recipientType.selectorlabelInfoCode} />{asterisk}</>}
                                disabled={!allMembersLoaded || recipientTypeHasNoOptionsShouldBeDisabled(recipientType?.value)}
                                value={recipientType.value}
                                labelPlacement={'right'}
                                checked={value?.recipientType === recipientType.value}
                                onChange={handleRecipientTypeChange}
                                key={`${recipientType.value}${recipientType.selectorlabelInfoCode}`}
                            />
                        )
                    })}
                </ByzzerRadioGroup>
            </div>
            <div className={classnames(`${baseClassName}__member-table`)}>
                {internalValue?.recipientType === 'selected-member' && (
                    <AlertTeamMemberTable
                        everyoneFromMySubscription={allNonMultiTenantMembers}
                        handleChange={handleTeamMemberSelection}
                        currentRecipientEmails={internalValue?.recipientEmails}
                    />
                )}
            </div>
            {showFootnote && <p className={classnames(`${baseClassName}__footnote`)}>* {MULTI_TENANT_RECIPIENT_FOOTNOTE}</p>}
        </div>
    );
}

AlertRecipientSelector.displayName = 'AlertRecipientSelector';