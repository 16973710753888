export const MIME_TYPES = {
    "pdf": "application/pdf",
    "ppt": "application/vnd.ms-powerpoint",
    "pptx":"application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "doc": "application/msword",
    "docx":"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "xls": "application/vnd.ms-excel",
    "xlsx":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "xlsb":"application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    "zip": "application/zip",
    "csv": "text/csv"
} as const;