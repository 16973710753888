import './StaticValues.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import { FormattedValue } from '../FormattedValue';
import { SimulatorConfig, SimulatorDatatype } from '@/types/SimulatorTypes';

export type SimulatorStaticInputValuesProps = {
    simulatorConfig: SimulatorConfig;
    value: Record<string, number | undefined>;
    datatypeMap: Record<string, SimulatorDatatype | undefined>;
    name: string;
} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'simulator-static-values';

export function StaticValues({
    className,
    simulatorConfig,
    datatypeMap,
    value,
    name,
    ...props
}: SimulatorStaticInputValuesProps) {


    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <header>
                <h1 className={'simulator-preview__title'}>Helpful Values</h1>
            </header>
            <main className={`${baseClassName}__values`}>
                {simulatorConfig?.staticValuesConfig?.filter(((config) => !(value[config.inputCodes[0]] === null)))?.map(({label, inputCodes}, i) => {
                    const code = inputCodes[0];
                    const type = datatypeMap[code];
                    const formattedValue = value[code];
                    return (
                        <div className={`${baseClassName}__value`} key={i}>
                            <div>{label}</div>
                            <FormattedValue type={type} value={formattedValue}/>
                        </div>
                    );
                })}
            </main>
        </div>
    );
}

export default StaticValues;

StaticValues.displayName = 'StaticValues';