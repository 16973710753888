import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import {Outlet} from 'react-router-dom';
import classnames from 'classnames';
import './MySimulators.scss';
import DashboardContent from "@/components/dashboard/DashboardContent";

export const MySimulators = ({
 ...props
}) => {
    const baseClassName = 'byz-scenario-simulator';

    const links = [
        {
            text: 'Simulate',
            url: '/dashboard/my_simulators/subscription_simulators',
        },
        // {
        //     text: 'Saved Scenarios',
        //     url: '/dashboard/my_simulators/saved_scenarios',
        // },
    ]

    return (
        // @ts-ignore
        <DashboardContent 
            title={'Price & Promotion Simulators'} 
            links={links} 
            className={classnames(`${baseClassName}`)}
        >
            <Outlet/>
        </DashboardContent>
    );

};

export default MySimulators;

MySimulators.displayName = 'MySimulators';