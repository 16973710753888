import './SegmentRunConfigFiltersStep.scss';
import React, {ReactNode, useEffect, useRef, useState} from "react";
import classnames from "classnames";
import {SegmentRunConfigFilters,
    SegmentRunConfigFiltersProps
} from "./SegmentRunConfigFilters";
import {AccordionWizard, StepRef} from "@byzzer/ui-components";

export type SegmentRunConfigFiltersStepProps = {
    title?: ReactNode;
} & SegmentRunConfigFiltersProps;

const baseClassName = 'segment-run-config-filters-step';

export const SegmentRunConfigFiltersStep = React.forwardRef<StepRef, SegmentRunConfigFiltersStepProps>(
    ({className, title, ...props}, ref) => {

    const [filtersValid, setFiltersValid] = useState<boolean>(false);

    function handleValidationChange(e: ByzzerValidityChangeEvent) {
        setFiltersValid(e.isValid)
    }

    return <AccordionWizard.Step className={classnames(baseClassName, className)} ref={ref} title={title}>
        <AccordionWizard.Header className={classnames({
            'report-run-config-wizard-header--valid': filtersValid
        })}>
            <h1 className={'report-run-config-wizard__step-title'}>{title}</h1>
        </AccordionWizard.Header>
        <AccordionWizard.Content>
            <SegmentRunConfigFilters {...props} onValidityChange={handleValidationChange}/>
        </AccordionWizard.Content>
        <AccordionWizard.Actions disableNext={!filtersValid} nextDisabledTip={'You must choose all required values to continue.'}/>
    </AccordionWizard.Step>
});
