import React, {useEffect, useState} from 'react';
import {trackEvent} from "@/components/SiteAnalytics";
import _ from "lodash";
import {createPortal} from "react-dom";
import classnames from "classnames";


const baseClassName = 'byzzer-icon'

export function TipIcon({
                            className,
                            type = 'info',
                            theme = 'dark',
                            tipMode = 'inline',
                            trackClick,
                            children,
                            onClick,
                            tipTrigger = 'click',
                            allowDefaultEvents="true",
                            ...props
                        }) {

    const [showTip, setShowTip] = useState(false);

    useEffect(() => {

    })

    const classes = classnames(
        className,
        'byzzer-icon',
        {
            'byzzer-icon--tip-open': showTip,
            [`byzzer-icon--${type}`]: type,
            [`byzzer-icon--${type}-${theme}`]: type && theme,
        }
    )

    function onIconClick(e) {
        
        e.stopPropagation();
        if(allowDefaultEvents){
        e.preventDefault();
    }
        if (children) {
            setShowTip(true);
        }
        onClick?.(e)
        if (trackClick) {
            trackEvent({
                type: 'click',
                name: _.isString(trackClick) ? trackClick : trackClick.name,
                data: trackClick.data
            })
        }
    }

    function onCloseClick(e) {
        e.stopPropagation();
        if(allowDefaultEvents){
        e.preventDefault();
    }
        setShowTip(false);
    }

    function renderContent() {
        const content =
            <div className={classnames('byzzer-icon__tip', {
                'byzzer-icon__tip--popup': tipMode === 'popup',
                'byzzer-icon__tip--inline': tipMode === 'inline',
            })}>
                <header className={'byzzer-icon__tip-header'}>
                    <i className={'byzzer-icon__tip-close'} onClick={onCloseClick}/>
                </header>
                <main className={'byzzer-icon__tip-content'}>{children}</main>
            </div>
        if (tipMode === 'popup') {
            return createPortal(<div className={`${baseClassName}__tip-wrapper`}>
                {content}
                </div>, document.body);
        } else {
            return content;
        }
    }

    return <div className={classes} onClick={onIconClick} {...props}>
        {Boolean(children && showTip) && renderContent()}
    </div>
}
