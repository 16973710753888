import './FilterGroup.scss';
import React, {ReactNode, useEffect, useState} from "react";
import classnames from "classnames";

export type FilterGroupProps = {} & React.HTMLAttributes<HTMLDivElement>;


export function FilterGroup({className,children, ...props}: FilterGroupProps) {
    const baseClassName = 'filter-group';
    return <div className={classnames(baseClassName, className)} {...props}>
        {children}
    </div>
}

export type FilterSectionProps = {
    disabled?: boolean;
    disabledTip?: ReactNode;
} & OnlyRenderableIf & React.HTMLAttributes<HTMLDivElement>;

export function FilterSection({className, children, onlyRenderIf, disabled, ...props}: FilterSectionProps) {
    const baseClassName = 'filter-group-section';

    if (onlyRenderIf !== true) return <></>;

    return <div className={classnames(baseClassName, className, {
        [`${baseClassName}--disabled`]: disabled
    })} {...props}>
        {children}
    </div>
}

