import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {getAllReports} from "@/services/product.service";
import {useApp} from "@/contexts/UserContext";

export function PPGReportList({ className }) {
    const rootClassNames = classNames(className, 'ppg-reports');

    const [titles, setTitles] = useState([]);
    const {allProducts} = useApp();

    useEffect(() => {
        if (allProducts) {
            const pppReportTitles = getAllReports()
                // todo: switch this to use supportsPpg (change needed in multiple places so this being left alone for now)
                .map(({ title, metadata }) => (metadata?.ppgReport ? title : false))
                .filter(Boolean)
                .sort();
            setTitles(pppReportTitles);
        }
    }, [allProducts]);

    return (
        <div className={rootClassNames}>
            <h1 className={'ppg-reports__title'}>Reports Featuring PPGs</h1>
            <div className={'ppg-reports__summary'}>
                You won’t see your PPGs in every report. Below is a list of reports where they will be used:
            </div>
            <ul className={'ppg-reports__list'}>
                {titles.map((title) => (
                    <li className={'ppg-reports__report'} key={title}>
                        {title}
                    </li>
                ))}
            </ul>
        </div>
    );
}
