import React from 'react';
import { NameThumbnail } from '@/components/HelperComponents';
import './QuoteReview.scss';
import { ByzzerDate } from '@byzzer/ui-components';

type QuoteReviewHeaderProps = {
    quoteId?: number | string;
    userName?: string;
    role?: string;
    email?: string;
    dateOfExpiry?: string;
    baseClassName?: string;
    nameTile?: string;
    termInMonths?: number;
};

const dateOfAccess = new Date();

// Todo: Move this function to a helper or util file
export function getContractEndDateByTerm(termInMonths: number, startDate = new Date()) {
    const currentDate = new Date(startDate);
    const integerMonths = Math.floor(termInMonths);
    const fractionalPart = termInMonths - integerMonths;

    // Add integer months
    currentDate.setMonth(currentDate.getMonth() + integerMonths);

    // Calculate the day adjustment for the fractional part
    const daysInCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const dayAdjustment = Math.round(fractionalPart * daysInCurrentMonth);

    // Add the fractional part and 1 day less for netsuite contract
    currentDate.setDate(currentDate.getDate() + dayAdjustment - 1);

    return currentDate;
}

const QuoteReviewHeader = ({
    quoteId,
    userName,
    role,
    email,
    dateOfExpiry,
    baseClassName,
    termInMonths,
}: QuoteReviewHeaderProps) => {
    const name = userName?.split(' ');
    const nameTile = name && name?.length > 1 ? name[0][0] + name[1][0] : '';

    return (
        <div className={`${baseClassName}__header`}>
            <div className={`${baseClassName}__header-info`}>
                <div className="quote">
                    <p>Quote # - {quoteId}</p>
                </div>
                <div className="user-info">
                    <div className="profile">
                        {quoteId ? <NameThumbnail name={nameTile || ''} /> : null}
                        <h2 className='user_name'>{userName?.toUpperCase() || ''}</h2>
                        {role && <i>({role || ''})</i>}
                    </div>
                    {
                        email &&
                        <div className='domain'>
                            <NameThumbnail />
                            <i>{email || ""}</i>
                        </div>
                    }
                </div>
            </div>
            <div className="dates">
                {/* Update the date logic - use our default date formatting function */}
                <p>Quote expiration date: {dateOfExpiry}</p>
                <p>Date of access: {' '}
                    {Boolean(termInMonths) && (
                        <ByzzerDate
                            date={dateOfAccess}
                            format={'M/dd/yyyy'}
                            timeZone={'America/New_York'} // todo: move to a constant.  check other instances of this as well
                        />
                    )}
                </p>
                <p>Contract end date: {' '}
                    {Boolean(termInMonths) && (
                        <ByzzerDate
                            date={getContractEndDateByTerm(termInMonths!, dateOfAccess)}
                            format={'M/dd/yyyy'}
                            timeZone={'America/New_York'}
                        />
                    )}
                </p>
            </div>
        </div>
    );
};

export default QuoteReviewHeader;
