import './SortToggle.scss';
import classnames from "classnames";
import React from "react";

const baseClassName = 'sort-toggle';
export function SortToggle({className, onSort, active, direction, ...props}) {

    return (
        <div className={classnames(baseClassName, className)}>
            <i className={classnames(`${baseClassName}__arrow`, `${baseClassName}__arrow--asc`, {
                [`${baseClassName}__arrow--active`]: direction === SortToggle.SORT_ASC && active
            })} onClick={() => onSort?.(SortToggle.SORT_ASC)}/>
            <i className={classnames(`${baseClassName}__arrow`, `${baseClassName}__arrow--desc`, {
                [`${baseClassName}__arrow--active`]: direction === SortToggle.SORT_DESC && active
            })} onClick={() => onSort?.(SortToggle.SORT_DESC)}/>
        </div>
    )
}

SortToggle.SORT_DESC = -1;
SortToggle.SORT_ASC = 1;

export default SortToggle;