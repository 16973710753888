import React, { useState, useEffect } from 'react';
import './PriorApprovedForm.scss';
import classNames from 'classnames';
import InlineSVG from 'react-inlinesvg';
import infoIcon from '@images/icons/info-black.svg';
import {ByzzerModal} from '@/components/form';
import GreenDot from '@/images/icons/GreenDot.svg';
import YellowDot from '@/images/icons/YellowDot.svg';
import RedDot from '@/images/icons/RedDot.svg';
import Loader from '@/components/Spin';
import {useTenantApi} from '@/hooks/useTenantApi';
import { ByzzerLink } from '@/components/form';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { TrackClick } from '@/analytics';
import DashboardContent from '@/components/dashboard/DashboardContent';

const MARKET_101_URL = "https://learn.byzzer.ai/en/articles/6289646-markets-101-channels-geographies-accounts-and-more";

type PriorApprovalRetailer = {
    masterCompanyCode: string;
    masterCompanyName: string;
}

export function PriorApprovedForm() {
    const { getPriorApprovalStatus, getPriorApprovalRetailers } = useTenantApi();
    const [statusInfo, setStatusInfo] = useState(false);
    const [expireInfo, setExpireInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([{}]);
    const [priorApprovalRetailers, setPriorApprovalRetailers] = useState<PriorApprovalRetailer[]>([]);
    console.log('priorApprovalRetailers=', priorApprovalRetailers)
    useEffect(() => {
        renderPriorApprovalData();
    }, []);

    const mainClasses = classNames('prior-approved-form');
    const subClasses = classNames('prior-approved-form__subclass');

    const renderPriorApprovalData = async () => {
        setLoading(true);
        const approvalStatus = await getPriorApprovalStatus();
        if (approvalStatus) {
            setTableData(approvalStatus);
        }
        const priorApprovalRetailersList: PriorApprovalRetailer[] = await getPriorApprovalRetailers();
        setPriorApprovalRetailers(
            priorApprovalRetailersList?.length
                ? priorApprovalRetailersList?.sort((a, b) => {
                      return a?.masterCompanyName?.localeCompare(b?.masterCompanyName, 'en', { sensitivity: 'base' });
                  })
                : []
        );
        setLoading(false);
    };

    function approvalStatus(status) {
        switch (status) {
            case 'approved':
                return (
                    <span>
                        <img
                            className={'prior-approved-form__request-status-table-icon-status'}
                            src={GreenDot}
                            alt={''}
                        />
                        Approved
                    </span>
                );
            case 'pending':
                return (
                    <span>
                        <img
                            className={'prior-approved-form__request-status-table-icon-status'}
                            src={YellowDot}
                            alt={''}
                        />
                        Pending
                    </span>
                );
            case 'denied':
                return (
                    <span>
                        <img
                            className={'prior-approved-form__request-status-table-icon-status'}
                            src={RedDot}
                            alt={''}
                        />
                        Denied
                    </span>
                );
            default:
                return <span className="prior-approved-form__request-status-table-null">--</span>;
        }
    }
    function positionPopup($event) {
        console.log($event);
    }
    return (
        <DashboardContent className={mainClasses} title={'Prior Approval Markets'}>
            <ByzzerMask loading={loading} />
            <div className={'prior-approved-form__section'}>
                <div className="prior-approved-form__title">What is Prior Approval?</div>
                <div className="prior-approved-form__subclass-subheader-content">
                    Prior Approval is when a retailer does not allow their data to be released to another company unless
                    they have directly approved it. The data for these retailers will still be included in channel-level
                    and geographical aggregates, but will not be available as a separate market to run data on unless
                    you get the required approval form the retailer. To learn more, read our article &nbsp;
                    <ByzzerLink>
                        <TrackClick name={`Prior Approval Info Market 101 clicked`}>
                            <a
                                href={MARKET_101_URL}
                                target="_blank"
                                className={'prior-approved-form__subclass-subheader-content-route'}
                                rel="noreferrer"
                            >
                                <span>Markets 101.</span>
                            </a>
                        </TrackClick>
                    </ByzzerLink>
                    <br />
                    <br />
                    Retailer approval is managed by NIQ. Our customer service team can help you get an approval
                    request started.
                </div>
            </div>
            <div className={'prior-approved-form__section'}>
                <div className="prior-approved-form__title">Which retailers require Prior Approval?</div>
                <div className="prior-approved-form__subclass-subheader-content">
                    In order to run reports or receive data for any of the below retailers, you will need to get Prior
                    Approval.
                    <div className="prior-approved-form__subclass-subheader-content-list">
                        <div className="prior-approved-form__subclass-subheader-content-list-section">
                            {priorApprovalRetailers?.map((retailer) => (
                            <div key={retailer?.masterCompanyCode} className="prior-approved-form__subclass-subheader-content-list-item">
                                {retailer?.masterCompanyName}
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className={'prior-approved-form__section'}>
                <div className="prior-approved-form__title">How to request Prior Approval</div>
                <div className="prior-approved-form__subclass-subheader-content">
                    Send an email to&nbsp;
                    <TrackClick name={`Prior Approval Nav Email clicked`}>
                        <span className={'prior-approved-form__subclass-subheader-content-email-address'}>
                            your account rep
                        </span>
                    </TrackClick>
                    &nbsp;to request Prior Approval to any of the above markets. Please include the following
                    information in your email:
                    <br />
                    <br />
                    1. Which of the Prior Approval markets you would like access to, and whether or not your product is
                    already carried in those retailers.
                    <br />
                    2. Your company name. If you own multiple brands, also include the Brand names.
                    <br />
                    3. Your account rep will keep you informed and notify you once you are approved. Please note - you
                    do not need to reach out a retailer buyer.
                </div>
            </div>
            <div className={'prior-approved-form__section'}>
                <div className="prior-approved-form__title">Prior Approval Request Status</div>
                <div className="prior-approved-form__request-status-content">
                    Track the status of Prior Approval requests that have already been submitted. Reach out to customer
                    service with any questions.
                </div>
                <table className="prior-approved-form__request-status-table">
                    <tbody>
                        <tr>
                            <th>Retailer</th>
                            <th>
                                Status
                                <span className="prior-approved-form__request-status-table-icon">
                                    <i>
                                        <TrackClick name={`Prior Approval Info Status clicked`}>
                                            <InlineSVG
                                                src={infoIcon}
                                                onClick={() => {
                                                    setStatusInfo(!statusInfo);
                                                }}
                                            />
                                        </TrackClick>
                                    </i>
                                </span>
                            </th>
                            <th>Date Submitted</th>
                            <th>Date Approved</th>
                            <th>
                                Expiration Date
                                <span className="prior-approved-form__request-status-table-icon">
                                    <i>
                                        <TrackClick name={`Prior Approval Info Expiration clicked`}>
                                            <InlineSVG
                                                src={infoIcon}
                                                onClick={() => {
                                                    setExpireInfo(!expireInfo);
                                                }}
                                            />
                                        </TrackClick>
                                    </i>
                                </span>
                            </th>
                        </tr>
                        {tableData?.length > 0 &&
                            tableData?.map((item: any, index) => (
                                <tr key={index}>
                                    {item.retailer ? (
                                        <td>{item.retailer}</td>
                                    ) : (
                                        <td className="prior-approved-form__request-status-table-null">--</td>
                                    )}
                                    {item.status ? (
                                        <td>{approvalStatus(item.status)}</td>
                                    ) : (
                                        <td className="prior-approved-form__request-status-table-null">--</td>
                                    )}
                                    {item.submitDate ? (
                                        <td>{item.submitDate}</td>
                                    ) : (
                                        <td className="prior-approved-form__request-status-table-null">--</td>
                                    )}
                                    {item.approvedDate ? (
                                        <td>{item.approvedDate}</td>
                                    ) : (
                                        <td className="prior-approved-form__request-status-table-null">--</td>
                                    )}
                                    {item.expiryDate ? (
                                        <td>{item.expiryDate}</td>
                                    ) : (
                                        <td className="prior-approved-form__request-status-table-null">--</td>
                                    )}
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            <ByzzerModal
                show={statusInfo}
                onClose={() => setStatusInfo(!statusInfo)}
                size={'small'}
                headerType={'none'}
                className="byzzer-model-position"
            >
                <div className="prior-approved-form__request-status-table-info">
                    <span className="prior-approved-form__request-status-table-info-title">Pending:</span>
                    &nbsp;the request has been sent to NIQ and the retailer. Response from the retailer is
                    pending.
                </div>
                <div className="prior-approved-form__request-status-table-info">
                    <span className="prior-approved-form__request-status-table-info-title">Approved:</span>
                    &nbsp; the retailer has approved your request, and their market should be available to select when
                    you run a report. If the retailer is also a premium market, reach out to customer service in order
                    to purchase.
                </div>
                <div className="prior-approved-form__request-status-table-info">
                    <span className="prior-approved-form__request-status-table-info-title">Denied:</span>
                    &nbsp;the retailer did not approve your request, so you cannot run reports on their markets.
                </div>
                <br />
                <div className="prior-approved-form__request-status-table-info-note">
                    If a market you requested is not listed, it may not have been submitted by byzzer yet. Reach out to
                    customer service to follow up on the status of any requests.
                </div>
            </ByzzerModal>
            <ByzzerModal
                show={expireInfo}
                onClose={() => setExpireInfo(!expireInfo)}
                size={'small'}
                headerType={'none'}
                className="byzzer-model-position"
            >
                <div className="prior-approved-form__request-status-table-info">
                    <span className="prior-approved-form__request-status-table-info-title">Expiration Date:</span>
                    &nbsp; some Prior Approval retailers require re-approval on a cadence, such as annually. You will
                    need to submit another approval request to continue access beyond the expiration date.
                </div>
            </ByzzerModal>
        </DashboardContent>
    );
}
export default PriorApprovedForm;
