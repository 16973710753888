import './Dashboard.scss';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { DashboardNav } from '@/components/dashboard';
import DashboardHeader from '@/components/dashboard/DashboardHeader';
import { useUser } from '@/contexts/UserContext';
import { FreeUserDashboardNav, FreeUserDashboardHeader } from '@/components/FreeUserDashboard';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';

export default function Dashboard() {
    const { subscription, preSales, company, loadingTenantData } = useUser();

    if (loadingTenantData) {
        return <ByzzerMask show={true} loading={true} isFullScreen={true} />
    }

    return (
        <div className="dashboard">
            {subscription?.metadata?.isFree && preSales && company?.features?.freeAccountGroup  ? (
                <>
                    <FreeUserDashboardNav />
                    <FreeUserDashboardHeader />
                </>
            ) : (
                <>
                    <DashboardNav />
                    <DashboardHeader />
                </>
            )}

            <Outlet />
        </div>
    );
}
