import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ByzzerButton} from '@byzzer/ui-components';
import {confirm} from '@/components/form/ByzzerModal';
import './DodCancelActionButton.scss';

export const DodCancelActionButton = () => {

    const navigate = useNavigate();

    async function handleCancel(): Promise<void>  {
        if(!(await confirm({
            title: 'Confirm Action',
            content: <>
                <p>Are you sure you want to go back to the DOD history page? All of your changes will be lost.</p>
            </>,
            yesLabel: 'No',
            noLabel: 'Yes, Go To History'
        }))) {
            navigate('/dashboard/extracts/history', {replace: true});
        }
    }

    return <ByzzerButton type="negative" label={'Cancel'} onClick={handleCancel} />;
};

export default DodCancelActionButton;
