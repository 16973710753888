import { FC, ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';
import './ReviewAndRunStories.scss';
import { useStoriesContext } from '@/views/story/buildStory/StoriesContext';
import ImageSlider from '@/components/story/storyPreview/ImageSlider';
import { getFileAlias, getStorySliderData, STORY_SUMMARY_SECTIONS } from '@/utils/storiesUtil';
import { CANNOT_RUN_STORY_MESSAGE } from '@/constants/stories.constants';
import { ByzzerTextInput, ByzzerTipIcon, WizardContext } from '@byzzer/ui-components';

const ReviewAndRunStories: FC = (): ReactElement => {
    const BASE_CLASS = 'run-story';
    const {
        storyDeckObjectiveList,
        dataSpecificationValues,
        storyInsightsValidation,
        storyCustomName,
        storyName,
        updatePanelStatus,
        selectorsConfigurations,
        previewMessage,
        updatePanelMessage,
        setStoryCustomName,
        validateStoryName,
        storyNameErrorMsg,
        isStoryNameValid
    } = useStoriesContext();
    const selectedObjectives = storyDeckObjectiveList.filter((objective) => objective.isChecked === 'checked');
    const [currentObjectiveIndex, setCurrentObjectiveIndex] = useState(0);
    const lastValidatedConfig = useRef<string>('');
    const {activeUid, enabledUids} = useContext(WizardContext);
   
    useEffect(() => {
        if (activeUid === enabledUids[enabledUids.length - 1]) {
            validateStoryName();
        }
    }, [storyCustomName]);
    useEffect(() => {
        const comparableRunConfig = JSON.stringify(dataSpecificationValues.runConfig);

        // don't bother revalidating the config if it hasn't changed
        if (lastValidatedConfig.current === comparableRunConfig) return;
        const numberOfPendingSelectors = selectorsConfigurations.filter((selector) => selector.status === 'pending')
            .length;

        if (!numberOfPendingSelectors) {
            const customName: string = getFileAlias(dataSpecificationValues, storyName)
            setStoryCustomName(customName);
           if (activeUid === enabledUids[enabledUids.length - 1]) {
               validateStoryName(customName);
               storyInsightsValidation();
               lastValidatedConfig.current = comparableRunConfig;
           }
        } else {
            updatePanelStatus(selectorsConfigurations.length - 1, 'error');
            updatePanelMessage(selectorsConfigurations.length - 1, {
                type: 'error',
                content: CANNOT_RUN_STORY_MESSAGE,
            });
        }
    }, [dataSpecificationValues.runConfig, activeUid]);
    
    function getCurrentIndex(objectiveIndex) {
        let objective = getStorySliderData(selectedObjectives).filter((objective) => {
            return objective.objectiveIndex === objectiveIndex;
        });
        return objective[0].previewImageIndex;
    }
    const changeClassName = (divIndex, event = []) => {
        if (event.length !== 0) {
            let index = getCurrentIndex(divIndex);
            setCurrentObjectiveIndex(index);
        }
    };
    const objectiveIndexChange = useCallback((index, slideIndex = -1) => {
        changeClassName(index);
        if (slideIndex !== -1) {
            setCurrentObjectiveIndex(slideIndex);
        }
    }, []);
    const updateStoryCustomName = (e: ByzzerChangeEvent<string>) => {
        validateStoryName(e.value);
        setStoryCustomName(e.value);
    };
    
    return (
        <>
            <div className={`${BASE_CLASS}__summary_container`}>
                <div className={`${BASE_CLASS}__summary_container__selections`}>
                    {STORY_SUMMARY_SECTIONS.map((attribute) => {
                        if (
                            Object.keys(dataSpecificationValues.runConfig).includes(attribute.key) &&
                            Object.keys(dataSpecificationValues.runConfig[attribute.key]).length
                        ) {
                            return (
                                <div className={`${BASE_CLASS}__summary_container__selections__storySelectors`}>
                                    {attribute.dom(dataSpecificationValues.runConfig[attribute.key])}
                                </div>
                            );
                        }
                    })}
                    <div className={`${BASE_CLASS}__story_alias_container`}>
                        {
                            <>
                                <ByzzerTextInput
                                    label={'Story Name :'}
                                    onChange={(event: any) => updateStoryCustomName(event)}
                                    placeholder={'Give your story a unique/custom name'}
                                    name={storyCustomName}
                                    value={storyCustomName}
                                    className={`${BASE_CLASS}__story_alias_container__input`}
                                />
                                <span
                                    className={`${BASE_CLASS}__story_alias_container__editIcon`}
                                    title="Edit Storyname"
                                />
                                {!isStoryNameValid && <ByzzerTipIcon type="error" tip={storyNameErrorMsg} />}
                            </>
                        }
                    </div>
                </div>
                <div className={`${BASE_CLASS}__summary_container__preview`}>
                    {(previewMessage?.type === 'warning' || previewMessage?.type === '') &&
                        selectedObjectives.length && (
                            <ImageSlider
                                objectiveList={getStorySliderData(selectedObjectives)}
                                objectiveIndex={currentObjectiveIndex}
                                objectiveIndexChange={objectiveIndexChange}
                                selectorName={'review_and_run'}
                                validationErrorMessage={previewMessage}
                            />
                        )}
                </div>
            </div>
        </>
    );
};
export default ReviewAndRunStories;
