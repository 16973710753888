import React, { useMemo } from 'react';
import { ByzzerRadio, ByzzerButton } from '@/components/form';
import { DodRunConfigWizardMode } from '@/components/DodConfigEditor/types';
import { ScheduledUserDetails } from '@/types/DodRun';
import { useApp } from '@/contexts/UserContext';

interface ModifyExtractProps {
    editOption: DodRunConfigWizardMode | undefined;
    seriesName: string | undefined;
    scheduledBy: ScheduledUserDetails | undefined;
    disableNextButton: boolean;
    onNextClicked: (value: DodRunConfigWizardMode) => void;
    saveEditOption: (value: DodRunConfigWizardMode) => void;
}

const DISABLED_OPTION_TIP = "You cannot modify this scheduled series because you did not create it";

function ModifyExtract(props: ModifyExtractProps) {
    const { user } = useApp();
    const isEditRestricted: boolean = useMemo(() => user?.id !== props.scheduledBy?.id, [user, props.scheduledBy])

    const modifyOptions = [
        {
            name: `Edit the selections for the entire series "${props?.seriesName}"`,
            value: 'editScheduleSeries',
            disabled: isEditRestricted,
            disabledTip: DISABLED_OPTION_TIP,
        },
        {
            name: `Edit only the schedule for the series "${props?.seriesName}"`,
            value: 'editSchedule',
            disabled: isEditRestricted,
            disabledTip: DISABLED_OPTION_TIP,
        },
        {
            name: `Create a new run by copying the selections from "${props?.seriesName}"`,
            value: 'edit',
            disabled: false,
        },
    ];

    const radioChanged = (event) => {
        props.saveEditOption(event.target.value);
    };

    const isRadioChecked = (value: string) => {
        return value === props.editOption;
    };

    const nextClickedHandler = () => {
        props.onNextClicked(props.editOption as DodRunConfigWizardMode);
    };

    return (
        <>
            {modifyOptions.map((val, index) => {
                return (
                    <React.Fragment key={index}>
                        <div className="extracts-edit-container__edit-option">
                            {/* @ts-ignore Temporary ignore due to JSX component. // TODO: convert to TypeScript */}
                            <ByzzerRadio
                                className="extracts-edit-container__edit-option-label"
                                key={val.value}
                                label={val.name}
                                value={val.value}
                                onChange={radioChanged}
                                checked={isRadioChecked(val.value)}
                                disabled={val.disabled}
                                disabledTip={val.disabledTip}
                            />
                        </div>
                    </React.Fragment>
                );
            })}
            <br />
            <div className="modify-dod-btn-modal">
                <ByzzerButton
                    label="Next"
                    onClick={nextClickedHandler}
                    disabled={props.disableNextButton}
                ></ByzzerButton>
            </div>
        </>
    );
}

export default ModifyExtract;