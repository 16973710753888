import React from 'react';
import classNames from "classnames";

export const ByzzerStep = ({ name, active, stepNumber, done, header, children }) => {
    const statusClassNames = classNames({
        [`active`]: active,
        [`done`]: done,
    });

    return (
        <>
            <div className={`byzzer-step ${statusClassNames}`}>
                <div className="byzzer-step-circle ">
                    <span>{stepNumber}</span>
                </div>
                <div className="byzzer-step-title">{name}</div>
                <div className="byzzer-step-bar-left"></div>
                <div className="byzzer-step-bar-right"></div>
            </div>

        </>
    )
}
export default ByzzerStep;
