import { useEffect } from 'react';
import { useStoriesContext } from '@/views/story/buildStory/StoriesContext';
import { FilterGroup } from '@/components/ConfigurationEditors/FilterGroup';
import { DemographicDimensionSelect } from '@/components/DemographicDimensionSelect';
import { selectorStates } from '@/config/globalVars';
export const StoryShopperSegmentSelector = () => {
    const {
        dataSpecificationValues,
        setDataSpecificationValues,
        updatePanelStatus,
        getSelectorConfiguration,
    } = useStoriesContext();
    let { selectorConfiguration, selectorIndex } = getSelectorConfiguration('shopper_segment');
    let { includeDemographics, maxDemographicDimensions, requireDemographicDimensions } = selectorConfiguration;
    const updateDemographicDimensions = (e: ByzzerChangeEvent<any>) => {
        setDataSpecificationValues({
            ...dataSpecificationValues,
            runConfig: { ...dataSpecificationValues.runConfig, demographicDimensions: e.value },
        });
    };
    useEffect(() => {
        //TODO : THIS CODE WILL BE MOVED TO storiesContext FILE
        if (dataSpecificationValues.runConfig.demographicDimensions.length) {
            updatePanelStatus(selectorIndex, selectorStates.completed);
        } else {
            updatePanelStatus(selectorIndex, selectorStates.pending);
        }
    }, [dataSpecificationValues.runConfig]);
    return (
        <>
            <FilterGroup>
                <DemographicDimensionSelect
                    onlyRenderIf={includeDemographics}
                    name={'demographicDimensions'}
                    placeholder={'Select from the list'}
                    maxSelections={maxDemographicDimensions ?? 5}
                    value={dataSpecificationValues.runConfig.demographicDimensions}
                    onChange={(e) => updateDemographicDimensions(e)}
                    sku={ dataSpecificationValues.sku }
                    required={requireDemographicDimensions}
                />
            </FilterGroup>
        </>
    );
};
