import { ByzzerLink } from "@/components/form";
import { useNavigate } from 'react-router-dom';
import React, { useState } from "react";
import { create as createModal } from "react-modal-promise";
import ByzzerModal2 from "@/components/modals/ByzzerModal2";

export function TeamInfo({ onResolve, ...props }) {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function onCloseClick() {
        onResolve({ function: "close" });
    }
    function redirectToReportRun() {
        navigate({ pathname: '/dashboard/my_subscription/report_runs', }, {replace: true});
        onResolve({ function: "redirect" });
    }

    return (
        <ByzzerModal2 wrapperClassName='info-modal' className="subscription-users__modal-info">
            <ByzzerModal2.Header className="subscription-users__modal-title" onClose={onCloseClick}>Teams</ByzzerModal2.Header>
            <ByzzerModal2.Content className="subscription-users__modal-content-info">
                <ByzzerModal2.Mask show={loading}>{loading.message}</ByzzerModal2.Mask>
                <div className='subscription-users__data-contents-table-info'>
                    Teams can be used to manage report run allocation across all members of your account. If you choose
                    to allocate runs by team in the
                    &nbsp;
                    <ByzzerLink label={'Report Runs'} onClick={redirectToReportRun} />
                    &nbsp;
                    page, any member not assigned to a team will not be able to run reports.
                </div>
            </ByzzerModal2.Content>
        </ByzzerModal2>
    );
}

export const openTeamInfo = createModal(TeamInfo);