import React, { ReactNode, forwardRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import { TrackClick } from '@/analytics';
import { NavLink, useLocation } from 'react-router-dom';
import '../dashboard/DashboardNavItem.scss';

type FreeUserDashboardNavItemProps = {
    disabled?: boolean;
    icon?: string;
    children?: ReactNode;
    content?: ReactNode;
    url: string;
    otherUrls?: string[];
    type?: string;
    onClick?: any;
    className?: string;
    title: string;
    collapsed?: boolean;
    isHeader?: boolean;
    toggleFromParent?: boolean;
    toggleParentMenu?: boolean;
};

export const FreeUserDashboardNavItem = forwardRef<HTMLAnchorElement, FreeUserDashboardNavItemProps>(
    (
        {
            disabled = true,
            icon,
            children,
            content = children,
            url,
            otherUrls,
            type,
            onClick,
            className,
            title,
            collapsed,
            isHeader = false,
            toggleFromParent = false,
            toggleParentMenu,
            ...props
        },
        ref
    ) => {
        const baseClassName = 'dashboard-nav-item';
        const [active, setActive] = useState(false);
        const { pathname } = useLocation();
        const paths: string[] = otherUrls?.length ? [url, ...otherUrls] : [url];

        useEffect(() => {
            if (!pathname) return;
            const pathnameMatches = paths?.filter((pathToCheck) => pathname.search(pathToCheck) > -1)?.length > 0;
            setActive(pathnameMatches);
        }, [pathname]);

        const handleClick = (e) => {
            if (disabled) e.preventDefault(); // prevent navigation if disabled
        };

        return (
            <TrackClick name={`${content} dashboard nav item clicked`}>
                <NavLink
                    to={url}
                    ref={ref}
                    className={classnames(baseClassName, className, `${baseClassName}--${type}`, {
                        [`${baseClassName}--active`]: active,
                        [`${baseClassName}--collapsed`]: collapsed,
                        [`${baseClassName}--expanded`]: !collapsed,
                        [`${baseClassName}--disabled`]: disabled,
                    })}
                    {...props}
                >
                    <div className={`${baseClassName}__text`}>{content}</div>
                </NavLink>
            </TrackClick>
        );
    }
);
export default FreeUserDashboardNavItem;
