import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './AlertConfigSummary.scss';
import { UserAlertSummary } from "@/types/AlertRun";

import AlertConfigSelection from './AlertConfigSelection';
import {useBetterNavigate} from '@/utils';
import { nanoid } from "nanoid";
import { ByzzerChip } from "@/components/ByzzerChip";


export type AlertConfigSummaryProps = {
    baseClass?: string;
    userAlerts: Partial<UserAlertSummary>[];
    showEditDeleteActions?: boolean;
}

export const AlertConfigSummary = ({
    baseClass,
    userAlerts,
    showEditDeleteActions,
    ...props
}: AlertConfigSummaryProps) => {
    const navigate = useBetterNavigate();
    const baseClassName = baseClass ?? 'byz-alert-config-summary';

    return (
        <>
            {userAlerts?.map((userAlert, index) => {
                const { id: alertConfigId, productSku, refactoredConfig } = userAlert;

                // todo: change this to use the runconfigoptions include statements instead of evaluating values
                const showProdSels = Boolean(refactoredConfig?.productSelections?.length);
                const showFocusBrandsAndCats = !showProdSels;
                const showChars = refactoredConfig?.characteristics && refactoredConfig?.characteristics?.some((char) => Boolean(char.value?.length));
                const showMrkts = refactoredConfig?.markets && Boolean(refactoredConfig?.markets?.length);
                const showMtw = Boolean(refactoredConfig?.marketsToWatch) && Boolean(JSON.stringify(refactoredConfig?.marketsToWatch) !== '{}');
                const showNotif = refactoredConfig?.notification?.recipientEmails && Boolean(refactoredConfig?.notification?.recipientEmails?.length);
                const showTimePeriod = Boolean(refactoredConfig?.timePeriod?.period);
                return (
                    <div className={classnames(`${baseClassName}__alert-config`)} key={index}>
                        <div className={classnames(`${baseClassName}__alert-config-selections`)}>
                            {showProdSels && (
                                <AlertConfigSelection
                                    name={"Product Selection(s)"}
                                    content={                                    
                                        refactoredConfig?.productSelections?.map((ps) => (
                                            <ByzzerChip key={nanoid()}>
                                                <p>
                                                    <span className={classnames(`${baseClassName}__product-selections-brands`)}>Focus Brand:</span> {[...(ps?.focusBrands ?? [])].flat().join("; ")}
                                                </p>
                                                &nbsp; &nbsp;
                                                <p>
                                                    <span className={classnames(`${baseClassName}__product-selections-categories`)}>Category(s):</span> {[...(ps?.categories ?? [])].flat().join("; ")}
                                                </p>
                                            </ByzzerChip>
                                        ))
                                    }
                                    baseClass={baseClassName}
                                />
                            )}
                            {showFocusBrandsAndCats && (
                                <AlertConfigSelection
                                    name={"Focus Brand(s)"}
                                    content={                                    
                                        <ByzzerChip key={nanoid()}>
                                            {Boolean(refactoredConfig?.focusBrands?.length) && refactoredConfig?.focusBrands?.join("; ")}
                                        </ByzzerChip>
                                    }
                                    baseClass={baseClassName}
                                />
                            )}
                            {showFocusBrandsAndCats && (
                                <AlertConfigSelection
                                    name={"Category(s)"}
                                    content={                                    
                                        <ByzzerChip key={nanoid()}>
                                            {Boolean(refactoredConfig?.categories?.length) && refactoredConfig?.categories?.join("; ")}
                                        </ByzzerChip> 
                                    }
                                    baseClass={baseClassName}
                                />
                            )}
                            {showChars && (
                                <AlertConfigSelection
                                    name={"Characteristic(s)"}
                                    content={                                    
                                        <>
                                            {refactoredConfig?.characteristics?.map((selection) => {
                                                return (
                                                    <ByzzerChip key={nanoid()}>
                                                        <p>{selection?.characteristicDisplayValue} {selection?.condition} {selection?.value?.join(', ')}</p>
                                                    </ByzzerChip>
                                                )
                                            })}
                                        </> 
                                    }
                                    baseClass={baseClassName}
                                />
                            )}
                            {showMrkts && (
                                <AlertConfigSelection
                                    name={"Focus Market"}
                                    content={
                                        <>
                                            {refactoredConfig.markets?.map((runConfigMarket) => {
                                                return (
                                                    <ByzzerChip key={nanoid()}>
                                                        <p>{
                                                            runConfigMarket?.remainingMarketRunConfig?.name
                                                            ? `${runConfigMarket?.name} vs ${runConfigMarket?.remainingMarketRunConfig?.name}`
                                                            : runConfigMarket?.name
                                                        }</p>
                                                        
                                                    </ByzzerChip>
                                                )
                                            })}
                                        </>
                                    }
                                    baseClass={baseClassName}
                                />
                            )}
                            {showMtw && (
                                <AlertConfigSelection
                                    name={"Market to Watch"}
                                    content={
                                        <ByzzerChip>
                                            <p>{`${refactoredConfig?.marketsToWatch?.channel} (${refactoredConfig?.marketsToWatch?.marketType})`}</p>
                                        </ByzzerChip>
                                    }
                                    baseClass={baseClassName}
                                />
                            )}
                            {showTimePeriod && (
                                <AlertConfigSelection 
                                    name={'Time Period'}
                                    content={
                                        <ByzzerChip>
                                            <p>{`${refactoredConfig?.timePeriod?.period}`}</p>
                                        </ByzzerChip>
                                    }
                                />
                            )}
                            {showNotif && (
                                <AlertConfigSelection
                                    name={"Notification Recipient(s)"}
                                    content={
                                        <>
                                            {refactoredConfig?.notification?.recipientEmails?.map((notif) => {
                                                return (
                                                    <ByzzerChip key={nanoid()}>
                                                        <p>{((notif as any)?.email as string) ?? (notif as string)}</p>
                                                    </ByzzerChip>
                                                )
                                            })}
                                        </>
                                    }
                                    baseClass={baseClassName}
                                />
                            )}
                        </div>
                    </div>
                )                                        
            })}
        </>
    );

};

export default AlertConfigSummary;

AlertConfigSummary.displayName = 'AlertConfigSummary';