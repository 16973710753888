import { useEffect, useState } from 'react';
import { useStoriesContext } from '@/views/story/buildStory/StoriesContext';
import { selectorStates } from '@/config/globalVars';
import './StoryMarketPerformanceSelector.scss';
import { ByzzerInlineSelect, ByzzerRadio, ByzzerSelect } from '@byzzer/ui-components';
import ByzzerMarketSearch from '@/components/MarketSelection/ByzzerMarketSearch/ByzzerMarketSearch';
import { MarketPicker } from '@/components/MarketPicker';
import { MARKET_TYPES } from '@/components/MarketSelection/MarketTypeSelector/MarketTypeSelectorConstants';
import { MARKET_SELECTOR_TYPE } from '@/constants/stories.constants';
import { SelectorLabelInfo } from '@/components/SelectorLabelInfo/SelectorLabelInfo';

export const StoryMarketPerformanceSelector = () => {
    const {
        dataSpecificationValues,
        setDataSpecificationValues,
        getSelectorConfiguration,
        updatePanelStatus,
        selectedPerformanceMarkets,
        setSelectedPerformanceMarkets,
    } = useStoriesContext();
    const BASE_CLASS = 'performance-market';
    let { selectorConfiguration, selectorIndex } = getSelectorConfiguration('market_performance');
    const marketTypeSelection =
        selectorConfiguration?.allowMarketTypeSelection &&
        Boolean(dataSpecificationValues?.runConfig?.performanceMarkets?.length)
            ? 'custom'
            : selectorConfiguration?.allowMarketTypeSelection
            ? 'across'
            : 'custom';
    const [marketSelectorType, setMarketSelectorType] = useState(marketTypeSelection);
    const [comparisonTypeData, setComparisonTypeData] = useState(
        MARKET_TYPES.map((element) => {
            return { ...element, selected: false };
        })
    );
    useEffect(() => {
        //TODO : THIS CODE WILL BE MOVED TO storiesContext FILE
        if (
            selectedPerformanceMarkets.length === 0 &&
            !dataSpecificationValues.runConfig.comparisonPerformanceMarkets.channel
        ) {
            updatePanelStatus(selectorIndex, selectorStates.pending);
        } else {
            updatePanelStatus(selectorIndex, selectorStates.completed);
        }
        onContextChange('performanceMarkets', selectedPerformanceMarkets);
    }, [dataSpecificationValues.runConfig.comparisonPerformanceMarkets, selectedPerformanceMarkets]);
    useEffect(() => {
        let tempComparisonTypeData = [...comparisonTypeData];
        tempComparisonTypeData.forEach((element) => {
            if (element.title === dataSpecificationValues.runConfig.marketSelectionsSummary) {
                element.selected = true;
            }
        });
    }, []);

    const onContextChange = (name: string, value: any) => {
        setDataSpecificationValues({
            ...dataSpecificationValues,
            runConfig: {
                ...dataSpecificationValues.runConfig,
                [name]: value,
            },
        });
    };
    const updateSelectedMarket = (e: ByzzerChangeEvent<any[]>) => {
        setSelectedPerformanceMarkets(e.value);
    };
    const changeSelector = (selectorType) => {
        if (selectorType.value === 'custom') onContextChange('comparisonPerformanceMarkets', []);
        else setSelectedPerformanceMarkets([]);
        setMarketSelectorType(selectorType.value);
    };
    const updateMarketSelectionsSummary = (index) => {
        let data = comparisonTypeData.map((element, i) => {
            return { ...element, selected: i === index };
        });
        onContextChange('marketSelectionsSummary', data[index].title);
        setComparisonTypeData(data);
    };

    return (
        <div className={BASE_CLASS}>
            {marketSelectorType === 'across' ? (
                <AcrossMarketPicker
                    changeSelector={changeSelector}
                    comparisonTypeData={comparisonTypeData}
                    dataSpecificationValues={dataSpecificationValues}
                    updateMarketSelectionsSummary={updateMarketSelectionsSummary}
                    setDataSpecificationValues={setDataSpecificationValues}
                    marketSelectorType={marketSelectorType}
                />
            ) : (
                <CustomMarketPicker
                    changeSelector={changeSelector}
                    updateSelectedMarket={updateSelectedMarket}
                    selectedPerformanceMarkets={selectedPerformanceMarkets}
                    dataSpecificationValues={dataSpecificationValues}
                    selectorConfigurations={selectorConfiguration}
                    marketSelectorType={marketSelectorType}
                />
            )}
        </div>
    );
};

const AcrossMarketPicker = ({
    changeSelector,
    comparisonTypeData,
    dataSpecificationValues,
    updateMarketSelectionsSummary,
    setDataSpecificationValues,
    marketSelectorType,
}) => {
    const BASE_CLASS = 'market_type_container';
    return (
        <>
            <div className={`${BASE_CLASS}__content`}>
                <p className={`${BASE_CLASS}__content__subtext`}>What type of comparison is right for you?</p>
                <p className={`${BASE_CLASS}__content__subtext`}>
                    We will show you your top 10 markets based on the analysis type you select or{' '}
                    <ByzzerInlineSelect
                        name={'marketSelectorType'}
                        value={marketSelectorType}
                        options={MARKET_SELECTOR_TYPE}
                        onChange={changeSelector}
                    />{' '}
                </p>
            </div>

            <div className={`${BASE_CLASS}__selector`}>
                <b className={`${BASE_CLASS}__selector__subtext`}>I want to compare my product performance…</b>
                {comparisonTypeData.map((item, index) => {
                    return (
                        <div key={index}>
                            <ByzzerRadio
                                label={item.title}
                                checked={item.title === dataSpecificationValues.runConfig.marketSelectionsSummary}
                                onChange={() => updateMarketSelectionsSummary(index)}
                            />
                            {item.selected ? (
                                <ByzzerSelect
                                    name={'comparisonPerformanceMarkets'}
                                    value={dataSpecificationValues.runConfig.comparisonPerformanceMarkets.channel}
                                    onChange={(e) => {
                                        setDataSpecificationValues({
                                            ...dataSpecificationValues,
                                            runConfig: {
                                                ...dataSpecificationValues.runConfig,
                                                comparisonPerformanceMarkets: {
                                                    marketType: item.marketType,
                                                    marketLevel: item.marketLevel,
                                                    channel: e.value,
                                                },
                                            },
                                        });
                                    }}
                                    placeholder={'Select the channel from the list'}
                                    options={item.children}
                                    
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
};
const CustomMarketPicker = ({
    changeSelector,
    updateSelectedMarket,
    selectedPerformanceMarkets,
    dataSpecificationValues,
    selectorConfigurations,
    marketSelectorType,
}) => {
    return (
        <>
            <div className="selected-markets-count">
                <p>
                    <b>{`Number of market(s) selected: ${selectedPerformanceMarkets.length}`}</b>
                </p>
                <p>
                    <ByzzerInlineSelect
                        name={'marketSelectorType'}
                        value={marketSelectorType}
                        options={MARKET_SELECTOR_TYPE}
                        onChange={changeSelector}
                    />
                </p>
            </div>
            <div>
                <ByzzerMarketSearch
                    name={'markets'}
                    reportSku={dataSpecificationValues.sku}
                    categories={dataSpecificationValues.runConfig.categories}
                    value={selectedPerformanceMarkets}
                    onChange={updateSelectedMarket}
                    label={<SelectorLabelInfo 
                            selectorCode={'focusMarkets'} 
                            sku={ dataSpecificationValues.sku } 
                            max={ selectorConfigurations.maxMarkets }
                        />}
                    maxSelections={selectorConfigurations.maxMarkets}
                />

                <MarketPicker
                    name={'markets'}
                    // reportSku={localStorage['storySku']}
                    productSku={dataSpecificationValues.sku}
                    categories={dataSpecificationValues.runConfig.categories}
                    value={selectedPerformanceMarkets}
                    onChange={updateSelectedMarket}
                    maxSelections={selectorConfigurations.maxMarkets}
                    displayMarketGroup={dataSpecificationValues.runConfig.datatype}
                    
                />
            </div>
        </>
    );
};

export default StoryMarketPerformanceSelector;
