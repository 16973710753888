export const HIERARCHY_INFO = <>
    <p>The granularity of the data will be based on the selected fields.</p>
    <p>For example, if you want UPC-level data, make sure you add the UPC field to your selections.</p>
</>;

export const BRAND_AND_MANUFACTURER_INFO = <>
    <p>Add Brand and Manufacturer detail to your run using the fields in this section.</p>
</>;

export const CHARACTERISTICS_INFO = <>
    <p>Add additional product details to your run by incorporating characteristics, such as Form or Flavor.</p>
</>;

export const SAVED_PRODUCT_INFO = <>
    <p>Find your saved products in this section.</p>
    <p>You can add items to the "My Saved Products" section by clicking "Save" in the right-hand panel after making your
        product selections.</p>
    <p>This is great for quickly accessing your most frequently used products.</p>
</>;

export const CUSTOM_CHAR_INFO = <>
    <p>Find your Custom Characteristics located here.</p>
    <p>Create a Custom Characteristic using the Custom Characteristic option in the middle panel.</p>
</>;

export const PPG_INFO = <>
    <p>Find your Promoted Product Groups located here.</p>
    <p>Create your customized PPG using the PPG Tool under My Items menu.</p>
</>;

export const CATEGORY_SELECTION_INFO = <>
    <p>Select at least one category</p>
</>
