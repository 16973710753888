import { useOutlet } from 'react-router-dom';
import React, { forwardRef } from 'react';

export const OutletWithProps = forwardRef<any, { [key: string]: any }>((props = {}, ref) => {
    const outlet = useOutlet();
    if (outlet?.props?.children) {
        return React.cloneElement(outlet.props.children, { ...props, ref });
    }
    return null;
});

export default OutletWithProps;
