import { useEffect } from 'react';
import { useUser } from '@/contexts/UserContext';
import HomePage from '../HomePage/HomePage';
import MyHomePage from '../MyHomePage/MyHomePage';
import { HomepageContextProvider } from '@/contexts/HomepageContext';
import { MyHomepageContextProvider } from '@/contexts/MyHomePageContext';
import { openPersonasEditor } from './PersonasEditor';

export default function HomePageConfigEditor({ ...props }) {
    const { subscription, user, isCsrObo } = useUser();

    useEffect(() => {
        if (user) {
            const { showPersonasPopup = false } = user.metadata;

            ;(async () => {                
                if (showPersonasPopup && user?.tcsAccepted && !isCsrObo) { // Opens only under specific conditions 
                    try {
                        // @ts-ignore
                        await openPersonasEditor({includeSkip: true, includeSubmit: true});
                    } catch (error) {
                        throw error;
                    }
                }
            })()
        }
    }, [user?.tcsAccepted]);

    return (
        <>
            {subscription?.metadata?.isFree ? (
                <MyHomepageContextProvider>
                    <MyHomePage className={undefined} />
                </MyHomepageContextProvider>
            ) : (
                <HomepageContextProvider>
                    <HomePage className={undefined} />
                </HomepageContextProvider>
            )}
        </>
    );
}
