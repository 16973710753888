import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { ByzzerChangeEventHandler, ByzzerSelect } from '@byzzer/ui-components';
import classnames from 'classnames';
import {useTenantApi} from '@/hooks/useTenantApi';
import { charsToBeFilteredOut, specificCharsExclusionReportSkus } from '@/config/globalVars';
import {
    additionalCharacteristicsDimensions,
    additionalCharDimensionSkus,
    productLevelsHigherThanCategory,
} from '@/config/reportSelectorVars.config';
import { CharacteristicDimension } from '@/types/ReportRun';
import { useApp } from '@/contexts/UserContext';
import { SelectorLabelInfo } from '../SelectorLabelInfo';

const baseClassName = 'characteristics-dimention-select';

export type CharacteristicsDimension = CharacteristicDimension[]; // need to check type to align with ProductRunConfigFilters

export type CharacteristicsDimensionSelectProps = {
    name?: string;
    value?: CharacteristicDimension[];
    categories?: string[];
    onChange?: ByzzerChangeEventHandler<CharacteristicDimension[]>;
    maxSelections?: number;
    includeCustomCharacteristics?: boolean;
    allowClear?: boolean;
    sku?: string;
    productLevel?: string;
    label?: ReactNode;
    required?: boolean;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>;

export function CharacteristicsDimensionSelect({
    className,
    onChange,
    value,
    name,
    categories,
    maxSelections,
    includeCustomCharacteristics = true,
    allowClear,
    label,
    sku = '',
    productLevel = 'Category',
    required,
    ...props
}: CharacteristicsDimensionSelectProps) {
    const [selectedCharacteristics, setSelectedCharacteristics] = useState<any>([]);
    const [characteristicsOption, setCharacteristicsOption] = useState<CharacteristicDimension[]>([]);
    const basicCharacteristics = useRef<CharacteristicDimension[]>([]);
    const { customCharacteristics: customCharacteristicsContext } = useApp();
    const CHAR_DIM_LABEL= (
        <>
            <p>
                <b>This report includes fields for characteristics that you get to define.</b>
            </p>
            <span>
                Choose {maxSelections} characteristic(s) to show in your report. Category, Subcategory, and Brand will
                always be included by default.
            </span>
            <span>
                The dimension you choose will break the category down into comparative groups based on that
                characteristic. Do you want to compare different brands, or the performance of different features such
                as flavor or pack type?
            </span>
        </>
    ); 
    const { getCharacteristicsForCategories } = useTenantApi();
    // todo - added functionality to accept a prop and set an internalValue using it

    useEffect(() => {
        (async () => {
            updateCharacteristicsContext();
        })();
    }, [includeCustomCharacteristics]);

    useEffect(() => {
        (async () => {
            await fetchCharacteristicsForCategories(categories ?? []);
            updateCharacteristicsContext();
        })();
    }, [categories]);

    useEffect(() => {
        setSelectedCharacteristics(value ?? [])
    }, [value])

    const fetchCharacteristicsForCategories = async (categories) => {
        if (!categories?.length) {
            setSelectedCharacteristics([]);
            basicCharacteristics.current = [];
            return;
        }
        const characteristicsForCategories = await getCharacteristicsForCategories(categories);
        const basicCharacteristicsValue: CharacteristicDimension[] = characteristicsForCategories.map(
            (characteristic) => ({
                characteristicsCode: characteristic.code,
                characteristic: characteristic.displayName,
                isCustom: false,
            })
        );
        basicCharacteristics.current = basicCharacteristicsValue;
    };

    function updateCharacteristicsContext() {
        let matchingCustomCharacteristics: CharacteristicDimension[] = [];

        if (includeCustomCharacteristics) {
            matchingCustomCharacteristics = customCharacteristicsContext
                .filter((item) => categories?.map((category) => category)?.some((i) => item?.categories?.includes(i)))
                .map((customCharacteristic) => ({
                    characteristicsCode: String(customCharacteristic.id),
                    characteristic: `${customCharacteristic.label} (Custom)`,
                    isCustom: true,
                }));
        }
        let characteristicsMap: CharacteristicDimension[] = [
            ...matchingCustomCharacteristics,
            ...basicCharacteristics.current,
        ];

        if (specificCharsExclusionReportSkus.includes(sku)) {
            // BYZ-3311
            if (charsToBeFilteredOut.length > 0) {
                characteristicsMap = characteristicsMap?.filter(
                    (c) => !charsToBeFilteredOut.includes(c.characteristic)
                );
            }
        }
        if (
            additionalCharDimensionSkus?.includes(sku) &&
            (productLevelsHigherThanCategory?.includes(productLevel) || (categories && categories?.length > 1))
        ) {
            // BYZ-5986, BYZ-6603
            characteristicsMap = [...characteristicsMap, ...additionalCharacteristicsDimensions];
        }
        setCharacteristicsOption(characteristicsMap);
    }

    function handleChange(e: ByzzerChangeEvent<string | string[] | null | undefined>) {
        const value = characteristicsOption.filter((characteristic) => {
            return Array.isArray(e.value)
                ? e.value?.includes(characteristic.characteristicsCode)
                : characteristic.characteristicsCode === e.value;
        });

        if (onChange) {
            onChange({
                name,
                value,
            });
        }
        setSelectedCharacteristics([...value] ?? []);
    }

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            {Number(sku) ? (
                <SelectorLabelInfo
                    sku={sku}
                    selectorCode={'characteristicDimensions'}
                    required={required}
                    includeSuffix={!required}
                    max={maxSelections}
                    isLabelBold={true}
                />
            ) : (
                CHAR_DIM_LABEL
            )}

            <ByzzerSelect
                name={name}
                value={selectedCharacteristics.map((characteristic) => characteristic.characteristicsCode)}
                onChange={handleChange}
                label={label}
                options={characteristicsOption?.map((c) => ({
                    display: c.characteristic,
                    value: c.characteristicsCode,
                }))}
                placeholder={'Select from the list'}
                allowClear={allowClear}
                maxSelections={Number(maxSelections) > 0 ? maxSelections : undefined}
                allowMultiple={Number(maxSelections) > 0} // greater than zero means they set a max, would be undefined otherwise.
            />
        </div>
    );
}

export default CharacteristicsDimensionSelect;
