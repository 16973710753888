import './UserProfileAlerts.scss';
import React, {useState} from 'react';
import classnames from 'classnames';
import AlertsList from "@/components/AlertsList/AlertsList";
import {useBetterNavigate} from "@/utils";

const baseClassName = 'user-profile-alerts';

export default function UserProfileAlerts({className, ...props}) {

    const navigate = useBetterNavigate()
    function onBack() {
        navigate(-1);
    }
    function onComplete() {
        navigate(-1);
    }
    return <AlertsList className={classnames(baseClassName, className)} {...props} onBack={onBack} onComplete={onComplete}/>
}
