import './HomePage.scss';
import { useState } from 'react';
import classnames from 'classnames';
import { GreetingTile } from './GreetingTile';
import { Notification } from './Notification';
import DashboardSimpleContent from '@/components/dashboard/DashboardSimpleContent';
import { useUser } from '@/contexts/UserContext';

import _ from 'lodash';
import { addMinutes } from 'date-fns';
import { HomePageFilter } from './HomePageFilter';

import { HomepageContextProvider } from '@/contexts/HomepageContext';
import { TileContent } from './TileContent';

const baseClassName = 'home-page';

export default function HomePage({ className, ...props }) {

    const [loading, setLoading] = useState(false);
    // const [tiles, setTiles] = useState([]);
    const { user, categories, subscription } = useUser();
    const [enableFilterPanel, setEnableFilterPanel] = useState(false);
    const [confirmClose, setConfirmClose] = useState(false);
    const [showWarningMessagePopup, setShowWarningMessagePopup] = useState(false);

    const onFilterPanelOpen = () => {
        setEnableFilterPanel(!enableFilterPanel);
    };
    const onFilterPanelClose = () => {
        if (confirmClose) {
            setShowWarningMessagePopup(true);
        } else {
            setShowWarningMessagePopup(false);
            setEnableFilterPanel(false);
        }
    };

    const onModalFilterPanelClose = () => {
        setShowWarningMessagePopup(false);
        setEnableFilterPanel(false);
    };

    const handleConfirmClose = (state) => {
        setConfirmClose(state);
    };
    /** fetch the contract expiry date for display */
    const subscriptionEndDate = subscription?.endDate;
    const formattedSubscriptionEndDate = !subscriptionEndDate ? undefined : new Date(
        addMinutes(new Date(subscriptionEndDate), new Date(subscriptionEndDate).getTimezoneOffset())
    );

    const handleFilterState = async () => {
        setShowWarningMessagePopup(false);
        setEnableFilterPanel(false);
        // setUserUpdatedFilterValues(true);
        setConfirmClose(false);
    };
    return (
        <DashboardSimpleContent className={classnames(baseClassName, className)} {...props}>
            <div className={`${baseClassName}__dashboard`}>
                <HomepageContextProvider>
                    {!subscription?.active ? (
                        <div className={`${baseClassName}__dashboard__expiry`}>
                            <p>
                                <span className={`${baseClassName}__title__expiry`}>
                                    Welcome{`, ${user?.firstName}`}!
                                </span>
                            </p>
                            <p>
                                {Boolean(formattedSubscriptionEndDate) ? <span>{`
       Your contract has expired on ${formattedSubscriptionEndDate}. Please reach out to your sales representative to renew your contract.`}</span> : <></>}
                            </p>
                        </div>
                    ) : (
                        <>
                            <GreetingTile
                                className={`${baseClassName}__greeting`}
                                onFilterPanelOpen={onFilterPanelOpen}
                                onFilterPanelClose={onFilterPanelClose}
                                enableFilterPanel={enableFilterPanel}
                            />
                            <HomePageFilter
                                className={'filter_panel__dropdown'}
                                handleFilterState={handleFilterState}
                                enableFilterPanel={enableFilterPanel}
                                handleConfirmClose={(state) => handleConfirmClose(state)}
                                closeFilterPanel={onFilterPanelClose}
                                // processFilterValues={processFilterValues}
                                showWarningMessagePopup={showWarningMessagePopup}
                                onModalFilterPanelClose={onModalFilterPanelClose}
                            />
                            <div className={`${baseClassName}__tiles`}>
                                <TileContent />
                            </div>
                        </>
                    )}
                </HomepageContextProvider>
            </div>

            {/* Notifications */}
            <div className={`${baseClassName}__notifications`}>
                <h2 className={`${baseClassName}__title`}>Latest Updates</h2>
                <Notification category={categories} dashboardLoading={loading} />
            </div>
        </DashboardSimpleContent>
    );
}
