import calendarIcon from '@/images/icons/calendarRangeIcon.svg';

export const FLOATING_DATE_PICKER_FILTER_PARAMS = {
  filterParams: {
    inRangeFloatingFilterDateFormat: 'M/D/YY',
    inRangeInclusive: true,    
    filterOptions: [
      "equals",
      "inRange",
      "greaterThan",
      "lessThan",
      "notEqual",
      "blank",
      "notBlank"
    ]
  },
  icons:{
    filter: ()=> `<img src=${calendarIcon} alt="calendar-icon"/>`,
  }
}