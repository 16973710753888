import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import './ByzzerOverallScoreDial.scss';
import {fetchColorsForGrade} from "@/views/scorecard/fetchColorsForGrade";

type ByzzerOverallScoreDialProps = {
    className?: string;
    value?: number;
    grade?: string;
    unfilledStyle?: 'dark' | 'light';
};

const ByzzerOverallScoreDial = ({ 
    className, 
    value = 0, 
    grade = "No Data", 
    unfilledStyle = 'light'
}: ByzzerOverallScoreDialProps) => {
    let dataA = [{ value: 0 }, { value: 100 }];
    let dataB = [{ value: 0 }, { value: 100 }];
    let dataC = [{ value: 0 }, { value: 100 }];
    let dataD = [{ value: 0 }, { value: 100 }];
    let dataE = [{ value: 0 }, { value: 100 }];

    if (value > 100 && value <= 200) {
        dataA = [{ value: value - 100 }, { value: 100 - (value - 100) }]
    }
    else if (value > 200 && value < 300) {
        dataA = [{ value: 100 }, { value: 0 }]
        dataB = [{ value: value - 200 }, { value: 100 - (value - 200) }]
    }
    else if (value > 300 && value <= 400) {
        dataA = [{ value: 100 }, { value: 0 }]
        dataB = [{ value: 100 }, { value: 0 }]
        dataC = [{ value: value - 300 }, { value: 100 - (value - 300) }]
    }
    else if (value > 400 && value <= 500) {
        dataA = [{ value: 100 }, { value: 0 }]
        dataB = [{ value: 100 }, { value: 0 }]
        dataC = [{ value: 100 }, { value: 0 }]
        dataD = [{ value: value - 400 }, { value: 100 - (value - 400) }]
    }
    else if (value > 500) {
        dataA = [{ value: 100 }, { value: 0 }]
        dataB = [{ value: 100 }, { value: 0 }]
        dataC = [{ value: 100 }, { value: 0 }]
        dataD = [{ value: 100 }, { value: 0 }]
        dataE = [{ value: value - 500 }, { value: 100 - (value - 500) }]
    }
    return (
        <div className={`byzzer-overall-score-dial ${className}`}>
            <div className='byzzer-overall-score-dial__container'>
                <ResponsiveContainer className='byzzer-overall-score-dial__responsive-container' width={"100%"} height={"100%"}>
                    <PieChart >
                        <text x={"4%"} y={"95%"} textAnchor="middle" dominantBaseline="middle" className='byzzer-overall-score-dial__score-label'>100</text>
                        <text x={"95%"} y={"95%"} textAnchor="middle" dominantBaseline="middle" className='byzzer-overall-score-dial__score-label'>600</text>
                        {grade !== 'No Data' && <text x={"50%"} y={"74%"} textAnchor="middle" dominantBaseline="middle" className='byzzer-overall-score-dial__overall-grade'>{grade}</text>}
                        <text x={"50%"} y={"92%"} textAnchor="middle" dominantBaseline="middle" className='byzzer-overall-score-dial__overall-score'>{grade === 'No Data' ? grade : value}</text>
                        <text x={"13%"} y={"86%"} textAnchor="middle" dominantBaseline="middle" className='byzzer-overall-score-dial__grade-label'>F</text>
                        <text x={"27%"} y={"40%"} textAnchor="middle" dominantBaseline="middle" className='byzzer-overall-score-dial__grade-label'>D</text>
                        <text x={"50%"} y={"28%"} textAnchor="middle" dominantBaseline="middle" className='byzzer-overall-score-dial__grade-label'>C</text>
                        <text x={"73%"} y={"40%"} textAnchor="middle" dominantBaseline="middle" className='byzzer-overall-score-dial__grade-label'>B</text>
                        <text x={"87%"} y={"86%"} textAnchor="middle" dominantBaseline="middle" className='byzzer-overall-score-dial__grade-label'>A</text>
                        <Pie startAngle={180}
                            endAngle={145}
                            innerRadius={'149%'}
                            outerRadius={'185%'}
                            data={dataA}
                            dataKey="value"
                            labelLine={false}
                            //blendStroke
                            isAnimationActive={false}
                            cx={"50%"}
                            cy={"91%"}>
                            <Cell className={`byzzer-overall-score-dial__cell-filled ${fetchColorsForGrade(grade)}`} />
                            <Cell className={`byzzer-overall-score-dial__cell-unfilled--${unfilledStyle}`} />
                        </Pie>
                        <Pie startAngle={143}
                            endAngle={109}
                            innerRadius={'149%'}
                            outerRadius={'185%'}
                            data={dataB}
                            dataKey="value"
                            labelLine={false}
                            //blendStroke
                            isAnimationActive={false}
                            cx={"50%"}
                            cy={"91%"}>
                            <Cell className={`byzzer-overall-score-dial__cell-filled ${fetchColorsForGrade(grade)}`} />
                            <Cell className={`byzzer-overall-score-dial__cell-unfilled--${unfilledStyle}`} />
                        </Pie>
                        <Pie startAngle={107}
                            endAngle={73}
                            innerRadius={'149%'}
                            outerRadius={'185%'}
                            data={dataC}
                            dataKey="value"
                            labelLine={false}
                            //blendStroke
                            isAnimationActive={false}
                            cx={"50%"}
                            cy={"91%"} >
                            <Cell className={`byzzer-overall-score-dial__cell-filled ${fetchColorsForGrade(grade)}`} />
                            <Cell className={`byzzer-overall-score-dial__cell-unfilled--${unfilledStyle}`} />
                        </Pie>
                        <Pie startAngle={71}
                            endAngle={37}
                            innerRadius={'149%'}
                            outerRadius={'185%'}
                            data={dataD}
                            dataKey="value"
                            labelLine={false}
                            //blendStroke
                            isAnimationActive={false}
                            cx={"50%"}
                            cy={"91%"} >
                            <Cell className={`byzzer-overall-score-dial__cell-filled ${fetchColorsForGrade(grade)}`} />
                            <Cell className={`byzzer-overall-score-dial__cell-unfilled--${unfilledStyle}`} />
                        </Pie>
                        <Pie startAngle={35}
                            endAngle={0}
                            innerRadius={'149%'}
                            outerRadius={'185%'}
                            data={dataE}
                            dataKey="value"
                            labelLine={false}
                            //blendStroke
                            isAnimationActive={false}
                            cx={"50%"}
                            cy={"91%"} >
                            <Cell className={`byzzer-overall-score-dial__cell-filled ${fetchColorsForGrade(grade)}`} />
                            <Cell className={`byzzer-overall-score-dial__cell-unfilled--${unfilledStyle}`} />
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>
            <div className='byzzer-overall-score-dial__title'>Overall Score</div>
        </div>

    )
}

export default ByzzerOverallScoreDial;
