/* eslint-disable react-hooks/rules-of-hooks */
import './CharacteristicCriteriaBuilder.scss';
import React, {createContext, useEffect, useRef, useState} from 'react';
import classnames from 'classnames';
import {ByzzerButton, ByzzerChangeEventHandler,} from '@byzzer/ui-components';
import {CharacteristicCondition} from '@/types/ReportRun';
import {clone} from 'lodash';
import {useTenantApi} from '@/hooks/useTenantApi';
import {WithUid} from '@/types/WithUid';
import {nanoid} from 'nanoid';
import {useApp} from '@/contexts/UserContext';
import {CharacteristicConditionInput} from './CharacteristicCriteriaInput';
import {upcCharacteristicOption} from '@/config/globalVars';

type CharacteristicCriteria = CharacteristicCondition[];

const BLANK_CHARACTERISTIC_CONDITION: CharacteristicCondition = {
    characteristic: '',
    characteristicDisplayValue: '',
    condition: 'is',
    value: [],
    isCustom: false,
};

export type CharacteristicOption = {
    characteristicsCode: string;
    characteristic: string;
    isCustom?: boolean;
};

type CharacteristicCriteriaContextValue = {
    characteristics: CharacteristicOption[];
    selectedCharacteristics: string[];
};

export const CharacteristicCriteriaContext = createContext<CharacteristicCriteriaContextValue>({} as any);

export type CharacteristicCriteriaBuilderProps = {
    name?: string;
    value?: CharacteristicCriteria;
    categories?: string[];
    brand?: string;
    onChange?: ByzzerChangeEventHandler<CharacteristicCriteria>;
    onCharacteristicsChange?: (characteristics: string[]) => void;
    joinText?: 'and' | 'or' | string;
    maxConditions?: number;
    minConditions?: number;
    showLabelsOnAllRows?: boolean; // defaults to false
    excludeCharacteristicIsNot?: boolean;
    fieldsToRenderAsLargeList?: string[];
    includeUpcOption?: boolean;
} & OnlyRenderableIf &
    Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>;

export function CharacteristicCriteriaBuilder({
    className,
    onChange,
    value,
    name,
    categories,
    brand,
    onlyRenderIf = true,
    joinText,
    showLabelsOnAllRows = false,
    maxConditions,
    minConditions = 1, //added default value so 1 row is always available
    excludeCharacteristicIsNot = false, //added default value as false this can be changed in alby config for enabling/disabling 'is not' option for characteristic (BYZ-9680)
    fieldsToRenderAsLargeList,
    includeUpcOption,
    ...props
}: CharacteristicCriteriaBuilderProps) {
    if (onlyRenderIf === false) return <></>;
    const { getCharacteristicsForCategories, getCustomChars, getCharacteristicValuesForCategories } = useTenantApi();
    const baseClassName = 'characteristic-criteria-builder';
    const [conditions, setConditions] = useState<WithUid<CharacteristicCondition, string>[]>([]);
    const [contextValue, setContextValue] = useState<CharacteristicCriteriaContextValue>({
        characteristics: [],
        selectedCharacteristics: [],
    });

    const basicCharacteristics = useRef<any[]>([]);
    const disabledKeys = useRef<string[]>([]);
    const initialValueLoaded = useRef<Boolean>(false);
    const { customCharacteristics: customCharacteristicsContext } = useApp();

    useEffect(() => {
        (async () => {
            // await fetchCustomCharacteristics(); // moved to global context
            updateCharacteristicsContext();
        })();
        // if (value!.length > 1) {
        //     uids.current = Array.from({length: Number(value?.length)}).map(() => nanoid()) // creates an array of given length tied to length of our conditions
        // }
    }, []);

    useEffect(() => {
        (async () => {
            await fetchCharacteristicsForCategories(categories ?? []);
            updateCharacteristicsContext();
        })();
    }, [categories]);

    useEffect(() => {
        // TODO - create logic to map given value to conditions.  probably only needed when defaults are given or rendering for first time
        // only do this on load for given value list.  otherwise, changes will be handled in onchange to prevent rebuidling list every time.

        if (!initialValueLoaded.current ) {
            if (value?.[0]?.characteristic?.length) {
                // some preset value(s) to load, either from a prior report run, user defaults, or coming back to this after unmounting
                // console.log("(SHOULD I FIRE? LOADING DEFAULT OR RE-MOUNTING...?) CharacteristicCriteriaBuilder - 'value', 'categories' ===>> ", value, categories)
                let conditionsWithUids = value?.map((condition) => ({
                    value: condition,
                    uid: nanoid()
                }));
                updateCharacteristicsContext();
                setConditions(conditionsWithUids);
                initialValueLoaded.current = true; // finished initial load script, set to true to prevent above from reoccuring
            } else { // after mounting and after clicking radio button and no prior value to load, create a new default condition
                // console.log("(I THINK I SHOULD FIRE TO START THIS THING OFF?!) CharacteristicCriteriaBuilder - 'value', 'categories' ===>> ", value, categories)
                setConditions([createDefaultCondition()]);
            }
        }

    }, [value]);

    useEffect(() => {
        disabledKeys.current = conditions.map((condition) => condition.value.characteristic ?? '');
    }, [conditions]);

    // async function fetchCustomCharacteristics() { // moved to global context
    //     const customCharacteristicsMap = await getCustomChars();
    //     customCharacteristics.current = customCharacteristicsMap;
    // }

    const fetchCharacteristicsForCategories = async (categories) => {
        if (!categories?.length) {
            setConditions([createDefaultCondition()]);
            basicCharacteristics.current = [];
            return;
        }
        const characteristicsForCategories = [
            ...(await getCharacteristicsForCategories(categories)),
            ...(includeUpcOption ? [upcCharacteristicOption] : [])
        ];
        const basicCharacteristicsValue: CharacteristicOption[] = characteristicsForCategories.map(
            (characteristic) => ({
                // TODO: From DoD refactor merge - see what the correct values are for these
                characteristicsCode: characteristic?.code ?? characteristic?.chr_code,
                characteristic: characteristic?.displayName ?? characteristic?.chr_display_name,
                isCustom: false,
            })
        );
        basicCharacteristics.current = basicCharacteristicsValue.sort((charA, charB) => charA.characteristic.localeCompare(charB.characteristic, 'en', {sensitivity: 'base'}));
    };

    function updateCharacteristicsContext() {
        const matchingCustomCharacteristics: CharacteristicOption[] = customCharacteristicsContext
            .filter((item) => categories?.map((category) => category)?.some((i) => item?.categories?.includes(i)))
            .map((customCharacteristic) => ({
                characteristicsCode: String(customCharacteristic.id),
                characteristic: `${customCharacteristic.label} (Custom)`,
                isCustom: true,
            }));

        const characteristics = [...matchingCustomCharacteristics, ...basicCharacteristics.current];

        setContextValue((value) => ({
            ...value,
            characteristics: characteristics,
        }));
    }

    function updateStates(conditionsWithUids: WithUid<CharacteristicCondition, string>[], onChange?: ByzzerChangeEventHandler<CharacteristicCriteria>, ) {
        if (onChange) { // onChange should fire together with setConditions
            onChange({
                name,
                value: conditionsWithUids.filter((condition) => condition?.value?.characteristic).map((condition) => condition.value), // send only condition value without uid
            });
        }
        setConditions(conditionsWithUids); // setConditions should fire together with onChange; triggers render of conditions, which kicks off updates in children conditions elements
    }

    function handleChange(e: ByzzerChangeEvent<CharacteristicCondition>, index) {
        const conditionsWithUids = conditions.map((condition, i) => {
            if (condition.uid === index) {
                const characteristic = contextValue.characteristics.find(
                    (c) => c.characteristicsCode === e.value.characteristic
                );
                let value = e.value;
                value.characteristicDisplayValue = characteristic?.characteristic ?? '';
                value.isCustom = characteristic?.isCustom ?? false;
                return { value, uid: condition.uid };
            } else {
                return condition;
            }
        });
        // since this is currently not technically a 100% controlled component, for performance/UX reasons, state update functions are combined as one function and should fire together and be treated as one until better solution is implemented
        updateStates(conditionsWithUids, onChange);
    }

    function add() {
        setConditions((currentConditions) => [...currentConditions, createDefaultCondition()]);
        // https://stackoverflow.com/questions/39549424/how-to-create-unique-keys-for-react-elements
    }

    function remove(key: string) {
        const filteredConditions = conditions.filter((condition, i) => key !== condition.uid);
        updateStates(filteredConditions, onChange)
    }

    function createDefaultCondition() {
        return { value: clone(BLANK_CHARACTERISTIC_CONDITION), uid: nanoid() }
    }

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <CharacteristicCriteriaContext.Provider value={contextValue}>
                {conditions.map(({ value, uid }, index) => (
                    <React.Fragment key={uid}>
                        {joinText && index > 0 && (
                            <div className={classnames(`${baseClassName}__label-text`)}>{joinText.toUpperCase()}</div>
                        )}
                        <CharacteristicConditionInput
                            value={value}
                            includeLabels={showLabelsOnAllRows ? true : index === 0}
                            onChange={(e) => handleChange(e, uid)}
                            actions={
                                <>
                                    <ByzzerButton
                                        onClick={() => add()}
                                        disabled={(maxConditions ?? Infinity) <= conditions.length}
                                    >
                                        +
                                    </ByzzerButton>

                                    <ByzzerButton
                                        onClick={() => remove(uid)}
                                        disabled={minConditions >= conditions.length}
                                    >
                                        -
                                    </ByzzerButton>
                                </>
                            }
                            disabledKeys={disabledKeys.current}
                            categories={categories ?? []}
                            brand={brand}
                            name={name}
                            excludeCharacteristicIsNot={excludeCharacteristicIsNot}
                            renderLargeList={fieldsToRenderAsLargeList?.includes(value?.characteristic!)} // todo: rename this to something like renderValuesAsLargeList
                            searchableFields={[upcCharacteristicOption.chr_code]}
                        />
                    </React.Fragment>
                ))}
            </CharacteristicCriteriaContext.Provider>
        </div>
    );
}
