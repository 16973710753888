import React, {useEffect, useState} from 'react';
import InlineSVG from 'react-inlinesvg';
import eyeOpenIcon from '@images/icons/eyeOpenIcon.svg';
import eyeClosedIcon from '@images/icons/eyeClosedIcon.svg';
import classnames from "classnames";

/**
 * @param label
 * @param name
 * @param value
 * @param placeholder
 * @param sublabel
 * @param onChange
 * @param type
 * @param className
 * @param button
 * @param disabled
 * @param onIconClick
 * @param onButtonClick
 * @param errors
 * @param validate
 * boolean
 * @param props
 * @default true
 * true, will validate the form with regex validation
 * false, will skip the regex validation
 *
 */

const baseClassName = 'byzzer-input';

// todo: add support controlling the error display via a param, currently it's only driven by the input.validity
export function ByzzerInput({
                         label,
                         name,
                         value ,
                         placeholder,
                         sublabel,
                         onChange,
                         type = 'text',
                         className,
                         button,
                         disabled,
                         locked,
                         onIconClick,
                         onButtonClick,
                         errors = {},
                         validate = true,
                         onBlur,
                         variant = 'default', // default, primary, secondary
                         ...props
                     }) {
    const [$type, setType] = useState(type);
    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [$value, setValue] = useState(value);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        setValue(value);
    }, [value]);

    useEffect(() => {
        if (type === 'password') {
            setType(showPassword ? 'text' : 'password');
        }
    }, [showPassword])

    function togglePassword() {
        setShowPassword(value => !value);
    }

    function handleOnChange(e) {

        setIsValid(e.target.validity.valid);
        setValue(e.target.value);
        if (!e.target.validity.valid) {
            for (const [error, message] of Object.entries(errors)) {
                if (e.target.validity[error]) {
                    setErrorMessage(message);
                    break;
                }
            }
        }

        onChange?.(e);
    }

    return (
        <label className={classnames(baseClassName, className, {
            [`${baseClassName}--disabled`]: disabled,
            [`${baseClassName}--locked`]: locked,
        })}>
            {label && (
                <span className={'byzzer-input__label'}>{label}</span>
            )}
            <div className={classnames(`${baseClassName}__wrapper`, {
                [`${baseClassName}__wrapper--${variant}`]: variant
            })}>
                <input
                    placeholder={placeholder}
                    name={name}
                    value={$value ?? ''}
                    onChange={handleOnChange}
                    type={$type}
                    disabled={disabled ? disabled : false}
                    onBlur={onBlur ? onBlur : null}
                    {...props}
                />
                {sublabel && $value === '' && (
                    <span
                        className={`byzzer-input__sublabel ${disabled ? 'byzzer-input__sublabel--disabled' : ''}`}
                    >
                            {sublabel}
                        </span>
                )}
                {type === 'password' && (
                    <i className={classnames(`${baseClassName}__password-toggle`, {
                        [`${baseClassName}__password-toggle--on`]: showPassword
                    })} onClick={togglePassword}></i>
                )}
                {button && (
                    <span className={'byzzer-input__button'} onClick={onButtonClick}>
                            {button}
                        </span>
                )}
            </div>
            {Boolean(!isValid && errorMessage) && (
                <span className={'byzzer-input__error'}>{errorMessage}</span>
            )}
        </label>
    );
}

export default ByzzerInput;
