import { useCallback, useState, FC, ReactElement } from 'react';
import './StoryObjectivePreview.scss';
import { getStorySliderData } from '@/utils/storiesUtil';
import { useStoriesContext } from '@/views/story/buildStory/StoriesContext';
import ImageSlider from '@/components/story/storyPreview/ImageSlider';
import StoryObjectiveSelections from '@/components/story/storyObjective/StoryObjectiveSelections';
const StoryObjectivePreview: FC = (): ReactElement => {
    const BASE_CLASS = 'story-preview';
    const [currentObjectiveIndex, setCurrentObjectiveIndex] = useState(0);
    const [clickIndex, setClickIndex] = useState(0);
    const { objectiveList } = useStoriesContext();
    const storySliderData = getStorySliderData(objectiveList.filter((objective) => objective.isChecked === 'checked'));
    const changeClassName = (divIndex, event = []) => {
        setClickIndex(divIndex);
        if (event.length !== 0) {
            let index = getCurrentIndex(divIndex);
            setCurrentObjectiveIndex(index);
        }
    };
    const objectiveIndexChange = useCallback((index, slideIndex = -1) => {
        changeClassName(index);
        if (slideIndex !== -1) {
            setCurrentObjectiveIndex(slideIndex);
        }
    }, []);
    function getCurrentIndex(objectiveIndex) {
        let objective = storySliderData.filter((objective) => {
            return objective.objectiveIndex === objectiveIndex;
        });
        return objective[0].previewImageIndex;
    }
    return (
        <>
            <div className={`${BASE_CLASS}__container`}>
                <div className={`${BASE_CLASS}__container__list-container`}>
                    <header className={`${BASE_CLASS}__container__list-container__sub-heading`}>
                        <span>Selected Objectives</span>
                    </header>
                    <div className={`${BASE_CLASS}__container__list-container__list`}>
                        {objectiveList
                            .filter((objective) => objective.isChecked === 'checked')
                            .map((objective, index) => (
                                <PreviewSection
                                    key={index}
                                    className={`${BASE_CLASS}__container__list-container__list__objective-text${
                                        clickIndex === index ? `-selected` : ``
                                    }`}
                                    onClick={(event) => changeClassName(index, event)}
                                    objective={objective}
                                />
                            ))}
                    </div>
                </div>
                {Boolean(storySliderData.length) && (
                    <div className={`${BASE_CLASS}__container__story-image-slider-container`}>
                        <ImageSlider
                            objectiveList={storySliderData}
                            objectiveIndex={currentObjectiveIndex}
                            objectiveIndexChange={objectiveIndexChange}
                            selectorName={'objective_preview'}
                            validationErrorMessage={undefined}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

const PreviewSection = (props) => {
    return (
        <div className={props.className} onClick={props.onClick}>
            <StoryObjectiveSelections
                className={props.className}
                disabled={true}
                objective={props.objective}
                tagRequired={true}
                displayValue={true}
                enableTooltip={false}
            ></StoryObjectiveSelections>
        </div>
    );
};
export default StoryObjectivePreview;
