import React from 'react';
import {ByzzerButton} from "@/components/form";

export function ChooseEditActionStep({onComplete, char, busy}) {

    let actions;

    if (char?.type !== 'Characteristics') {
        actions = (<>
            <ByzzerButton busy={busy} onClick={() => onComplete('rename')}>Rename</ByzzerButton>
            <ByzzerButton busy={busy} onClick={() => onComplete('upload-upcs')}>Upload new UPC-segment list</ByzzerButton>
            {/* <ByzzerButton busy={busy} onClick={() => onComplete('remove-upcs')}>Change to Characteristic Groups</ByzzerButton> */}
        </>);
    } else {
        actions = (<>
            <ByzzerButton busy={busy} onClick={() => onComplete('rename')}>Rename</ByzzerButton>
            <ByzzerButton busy={busy} onClick={() => onComplete('change-rules')}>Change Characteristic Rules</ByzzerButton>
            {/* <ByzzerButton busy={busy} onClick={() => onComplete('upload-upcs')}>Change to UPC Upload</ByzzerButton> */}
        </>);
    }

    return (
        <div className={'char-editor__content'}>
            <div className="char-editor__actions">{actions}</div>
        </div>
    );
}