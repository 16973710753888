import React, {useRef} from 'react';
import './ppg.scss';
import {PPGDefinition} from './PPGDefinition';
import {PPGReportList} from './PPGReportList';
import {MyPPGs} from './MyPPGs';
import {openPPGEditor} from './editor/PPGEditor';

export function PPGs() {
    const listRef = useRef();

    async function refreshPpgs() {
        listRef.current.refresh();
    }

    async function onCreatePPG(allowUpload) {
        if (
            await openPPGEditor({
                allowUpload,
            })
        ) {
            refreshPpgs();
        }
    }

    return (
        <div className={'ppgs'}>
            <PPGDefinition className={'ppgs__card'} onCreatePPG={onCreatePPG} />
            <PPGReportList className={'ppgs__card'} />
            <MyPPGs ref={listRef} />
        </div>
    );
}
