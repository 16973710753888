import './DodTimePeriodFilterTypePicker.scss';
import React, { useState } from 'react';
import classnames from 'classnames';
import {
    DodFilterType,
    DodMarketFilterType,
    DodProductFilterType,
    DodTimePeriodFilterType
} from '@/components/DodConfigEditor/types';
import { ByzzerChangeEventHandler } from '@byzzer/ui-components';
import { DodFilters } from '@/types/DodRun';
import {
    DodFilterTypeOptionGroup,
    DodFilterTypePicker,
    DodFilterTypePickerProps
} from '@/components/DodConfigEditor/common';
import { DodPreset } from '@/types/ApiTypes';

const baseClassName = 'dod-time-period-filter-type-picker';

export type DodTimePeriodFilterTypePickerProps = {
    name?: string;
    className?: string;
    value: DodTimePeriodFilterType;
    onChange: ByzzerChangeEventHandler<DodTimePeriodFilterType>;
    filters: DodFilters;
    tipDelay?: number | [number | null, number | null];
    onPresetSelect: ByzzerChangeEventHandler<DodPreset>;
} & Partial<Omit<DodFilterTypePickerProps<DodFilters, DodTimePeriodFilterType>, 'initialOptionGroups'>>;

export function DodTimePeriodFilterTypePicker({
    name,
    className,
    onChange,
    filters,
    ...props
}: DodTimePeriodFilterTypePickerProps) {
    const [optionGroups] = useState<DodFilterTypeOptionGroup<DodFilters, DodTimePeriodFilterType>[]>([
        {
            label: 'Time Periods',
            options: [
                { label: 'Periods ending latest week', value: 'latestDates', key: 'latestDates' },
                { label: 'Previous Periods', value: 'previousPeriods', key: 'previousPeriods' },
                { label: 'Weeks', value: 'weeks' },
                { label: '4 Weeks (Trended)', value: '4weeks' },
                { label: '4 Weeks (Static)', value: '4StaticWeeks' },
                { label: 'Months', value: 'months' },
                { label: 'Quarters', value: 'quarters' },
                { label: 'Years', value: 'years' },
                { label: 'Custom Time Period', value: 'custom' },
            ],
        },
    ]);
    function handleChange(e: ByzzerChangeEvent<DodTimePeriodFilterType>): void {
        onChange({
            name,
            value: e.value
        });
    }
    return (
        <DodFilterTypePicker
            className={classnames(baseClassName, className)}
            filters={filters}
            initialOptionGroups={optionGroups}
            filterPlaceholder={'Search Time Period Folders'}
            presetType={'time_period'}
            presetGroupLabel={'My Saved Time Periods'}
            onChange={handleChange}
            {...props}
        />
    );
}

export default DodTimePeriodFilterTypePicker;
