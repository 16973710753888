import React, {useRef} from 'react';
import './CustomCharacteristics.scss';
import { openCharEditor } from "@/views/CustomCharacteristics/editor/CharEditor";
import CustomCharacteristicsTable from './CustomCharacteristicTable';
import { CharDefinition } from './CharacteristicsDefination';
import DashboardContent from "@/components/dashboard/DashboardContent";

export function CustomCharacteristics() {

    const listRef = useRef();

    async function refreshPpgs() {
        listRef.current.refresh();
    }

    function updateCustomCharData(customCharObj) {
        listRef.current.updateFilteredCustomCharList(customCharObj);
    }

    async function onCreateChar(allowUpload) {
        if (await openCharEditor({
            allowUpload, updateCustomCharData
        })) {
            console.log("Upload Successfull")
            refreshPpgs();
        }
    }
    const subTitle = <p>Create your own characteristics which you can use throughout the byzzer platform. This feature allows you to run reports using customized segments that are unique to your business.</p>

    return (
        <DashboardContent className={'custom-characteristics'} title={'Custom Characteristics'} subTitle={subTitle}>
            <CharDefinition onCreateChar={onCreateChar}/>
            <CustomCharacteristicsTable ref={listRef}/>
        </DashboardContent>
    );
}

export default CustomCharacteristics;
