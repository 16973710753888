import {useEffect} from "react";
import mixpanel from "mixpanel-browser";
import {mixpanelConfig} from "@/config";
import {useUser} from "@/contexts/UserContext";
import {useAnalyticsEvent} from "@/analytics/AnalyticsContext";

const disableMixPanel = Boolean(!mixpanelConfig.token);

export function useMixPanel() {

    const {user, company} = useUser();
    const event = useAnalyticsEvent();

    useEffect(() => {
        if(!disableMixPanel) {
            mixpanel.init(mixpanelConfig.token)
        }
    }, []);

    useEffect(() => {
        if (!user || disableMixPanel) {
            // mixpanel.reset();
            return;
        }

        if(company) {
            mixpanel.set_group('Company', user.companyId, (resp) => {
                const group = mixpanel.get_group('Company', user.companyId);
                group.set(company);
            });
        }

        mixpanel.identify(user.id);
        mixpanel.people.set({
            ...user,
            $email: user.email,
            $first_name: user.firstName,
            $last_name: user.lastName,
            email: undefined,
            firstName: undefined,
            lastName: undefined,
            company: undefined,
        })
        mixpanel.register({
            userId: user.id,
            companyId: user.companyId,
        });
    }, [user, company]);

    useEffect(() => {

        if (!event || disableMixPanel) return;

        if (event.type === 'navigation') {
            mixpanel.track_pageview({
                path: String(event.data!.path)
            });
        } else {
            mixpanel.track(event.name!, {
                $type: event.type,
                ...event.data,
            })
        }
    }, [event]);
}
