import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './ProductStep.scss';
import { ByzzerButton, StepRef, WithUid, WizardActions, WizardContent, WizardHeader, WizardStep } from "@byzzer/ui-components";
import ProductFilters, { ProductFiltersProps } from "./ProductFilters";
import { OnboardingStepCode } from "@/pages/Onboarding/OnboardingWizard";

export type ProductStepProps = {
    title?: ReactNode;
    onComplete?: () => void;
    onCancel?: () => void;
    enabled?: boolean;
    code?: OnboardingStepCode;
} & ProductFiltersProps;

export const ProductStep = forwardRef<StepRef, ProductStepProps>((
    {
        className,
        title,
        onComplete,
        onCancel,
        enabled,
        ...props
    },
    ref
) => {
    const baseClassName = 'byz-product-step';

    function handleCancelClick() {
        onCancel?.();
    }

    function handleComplete(): boolean {
        onComplete?.();
        return false;
    }

    return <WizardStep 
            className={classnames(baseClassName, className)} 
            ref={ref}
            title={title}
            enabled
        >
            <WizardHeader>
                <h1 className={'report-run-config-wizard__step-title'}>{title}</h1>
            </WizardHeader>
            <WizardContent>
                <ProductFilters 
                    {...props}
                />
            </WizardContent>
            <WizardActions
                nextText="Save"
                beforeNext={handleComplete}
            >
                <ByzzerButton onClick={handleCancelClick} type={'negative'}>Cancel</ByzzerButton>
            </WizardActions>
        </WizardStep>
    

});

export default ProductStep;

ProductStep.displayName = 'ProductStep';