import './DodLayoutConditionBuilder.scss';
import React, {useMemo} from 'react';
import classnames from 'classnames';
import {DodFactSet, FactCondition} from '@/types/DodRun';

export type DodLayoutConditionBuilderProps = {
    className?: string;
    name?: string;
    value: FactCondition[];
    facts: DodFactSet[];
    onEdit?(): void;
    onClear?(): void;
    title?: string;
    mode: string | null;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const COMPARATOR_MAP = {
    lt: 'less than',
    gt: 'greater than',
    eq: 'equal to',
};

const baseClassName = 'dod-layout-condition-builder';

export function DodLayoutConditionBuilder({
  className,
  name,
  facts,
  value,
  onEdit,
  onClear,
  title,
  mode,
  ...props
}: DodLayoutConditionBuilderProps) {
  const isLayoutMode = mode === 'layout';

  return (
      <div className={classnames(baseClassName, className)} {...props}>
          <header className={`${baseClassName}__header`}>
              <h1 className={`${baseClassName}__header--title`}>Conditions:</h1>
              <div className={`${baseClassName}accordion-wizard-step__actions`}>
                  <button
                      className={classnames(`${baseClassName}__action`, `${baseClassName}__action--edit`)}
                      onClick={onEdit}
                      disabled={isLayoutMode}
                  />
                  {Boolean(value?.length) && (
                      <button
                          className={classnames(`${baseClassName}__action`, `${baseClassName}__action--delete`)}
                          onClick={onClear}
                          disabled={isLayoutMode}
                      />
                  )}
              </div>
          </header>
          <DodConditionSummary className={`${baseClassName}__summary`} conditions={value} />
      </div>
  );
}

interface DodConditionSummaryProps {
    className?: string;
    conditions: FactCondition[];
    facts?: DodFactSet[];
}

function DodConditionSummary({conditions, className}: DodConditionSummaryProps) {

    const summary = useMemo<string>(() => {

        if (!conditions.length) {
            return `You do not have any conditions applied.`
        }

        let summary: string = '';
        conditions.forEach((condition, index) => {
            if (index > 0) {
                summary += ` ${condition.condition.toLocaleUpperCase()} `;
            }
            summary += ` "${condition.fact}" `;
            summary += ' is ';
            summary += ` ${COMPARATOR_MAP[condition.comparator]} `;
            //BYZ-12119 - adding .replace method to format condition.value to display thousand seperator 
            summary +=
                condition.value && condition.fact.includes('$') && !condition.fact.includes('% Change vs Year-Ago')
                    ? ` $${condition.value.replace(/^\$/, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
                    : condition.value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        });
        return `All products where ${summary} based on each market and time period.`;
    }, [conditions])

    return <div className={className}>
        {summary}
    </div>
}

export default DodLayoutConditionBuilder;
