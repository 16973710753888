import './ReportRunConfigFilters.scss';
import React, { useEffect, useState } from 'react';
import { RunConfigOptions } from '@/types/RunConfigOptions';
import { ProductRunConfigOptions } from '@/types/RunConfigOptions';
import classnames from 'classnames';
import { FilterGroup, FilterSection } from '@/components/ConfigurationEditors/FilterGroup';
import { ByzzerButton, ByzzerSelect } from '@byzzer/ui-components';
import { OptionalRequired } from '@/components/OptionalRequired/OptionalRequired';
import { ByzzerBrandSearch } from '@/components/ByzzerBrandSearch';
import { AggregationLevelSelect } from '@/components/ConfigurationEditors/AggregationLevelSelect';
import { CharacteristicCriteriaBuilder } from '@/components/CharacteristicCriteriaBuilder';
import { ReportRunConfig } from '@/types/ReportRun';
import { CharacteristicsDimensionSelect } from '@/components/CharacteristicsDimensionSelect/CharacteristicsDimensionSelect';
import ByzzerGrowthThresholdSelect from '@/components/ByzzerGrowthThresholdSelect/ByzzerGrowthThresholdSelect';
import { ByzzerSalesThresholdSelect } from '@/components/ByzzerSalesThresholdSelect';
import ByzzerPPGSelect from '@/components/ByzzerPPGSelect/ByzzerPPGSelect';
import { OmniManufacturerSearch } from '@/components/OmniManufacturerSearch';
import { OmniBrandSearch } from '@/components/OmniBrandSearch';
import { SubCategorySelect } from '@/components/SubCategorySelect';
import { ProTipsSection } from '@/components/ProTipsSection';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { omniProductLevelOptions } from '@/constants/omniProducts.constants';
import { SelectorLabelInfo } from '@/components/SelectorLabelInfo';
import FocusKPISelector from '@/components/FocusKPISelector/FocusKPISelector';
import { focusKPIOptions } from '@/components/FocusKPISelector/focusKPI.constants';

export type ReportRunConfigFiltersProps = React.HTMLAttributes<HTMLDivElement> & {
    className?: string;
    runType?: RunType;
    sku?: string;
    runConfigOptions?: RunConfigOptions[];
    defaultValues?: Partial<ReportRunConfig>;
    onCancel?: () => void;
    onApply?: (config: any) => void;
} & Partial<Omit<ProductRunConfigOptions, 'type' | 'title'>>;

const baseClassName = 'report-run-config-filters';

export function ReportRunConfigFilters({
    includeAggregationLevel = false,
    includeFocusBrand = false, // todo: remove after configs are migrated
    includeFocusBrands = includeFocusBrand,
    includeBrands = false,
    includeOmniProducts = false,
    includeCompetitiveProducts = false,
    includeCategories = includeCompetitiveProducts,
    includeOmniCategories = false,
    includeProductSubcategory = false,
    includeCharacteristicDimensions = false,
    includeCharacteristics = false,
    includeKeyCharacteristic = false,
    includeKeyCharacteristics = includeKeyCharacteristic,
    includePPGs = false,
    includeProductLevel = false,
    thresholdType,
    includeGrowthThresholds = thresholdType === 'growth',
    includeSalesThresholds = thresholdType === 'sales',
    includeAttributeGroup = false,
    includeAttributes = false,
    includeFocusKPI = false,

    excludeCharacteristicIsNot,

    maxAttributeGroups,
    maxBrands,
    maxCategories,
    maxFocusBrands,
    maxCharacteristicDimensions,
    maxCharacteristics,
    maxGrowthThresholds,
    maxSalesThresholds,
    maxKeyCharacteristics,
    maxPPGs,
    maxOmniProducts,
    maxAttributes,

    defaultValues,
    onCancel,
    onApply,
    sku,
    ...props
}: ReportRunConfigFiltersProps) {
    const [internalValue, setInternalValue] = useState<Partial<ReportRunConfig>>({});
    const includeThresholds = includeGrowthThresholds || includeSalesThresholds;
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (defaultValues) {
            setInternalValue(defaultValues);
            setLoading(false);
        }
    }, [defaultValues]);

    function handleChange(e: ByzzerChangeEvent<unknown>) {
        setInternalValue((value) => ({
            ...value,
            [e.name as keyof ReportRunConfig]: e.value,
        }));
    }

    function onCancelClick() {
        onCancel?.();
    }

    function onApplyClick() {
        onApply?.(internalValue);
    }

    return (
        <>
            <ByzzerMask show={loading} loading={loading} />
            <header className={`${baseClassName}__header`}>
                <div className={`${baseClassName}__title`}>Filter Current Report</div>
                <div className={`${baseClassName}__subtitle`}>Applying filters will not use a report run</div>
            </header>
            <FilterGroup className={classnames(baseClassName)}>
                <div className={`${baseClassName}__list`}>
                    <FilterSection
                        onlyRenderIf={
                            includeFocusBrands || includeBrands || includeAggregationLevel || includeProductSubcategory
                        }
                    >
                        <ByzzerBrandSearch
                            onlyRenderIf={includeFocusBrands}
                            name={'focusBrands'}
                            maxSelections={maxFocusBrands}
                            value={internalValue.focusBrands}
                            onChange={handleChange}
                            label={<SelectorLabelInfo selectorCode={'focusBrands'} sku={sku!} max={maxFocusBrands} />}
                        />
                        {/* TODO: check competitive brand selection */}
                        {/* Sub-Category (this section might also include competitive brand selection) */}
                        <SubCategorySelect
                            categories={internalValue.categories}
                            onChange={handleChange}
                            onlyRenderIf={includeProductSubcategory}
                            disabled={!internalValue.categories?.length}
                            placeholder={'All'}
                            // todo: use the requireSubcategories flag instead of hard coding this to true
                            label={
                                <SelectorLabelInfo
                                    selectorCode={'productSubCategory'}
                                    sku={sku!}
                                    required={false}
                                    includeSuffix={true}
                                    optionalSuffix={'(optional)'}
                                />
                            }
                            value={internalValue.subcategories}
                            name={'subcategories'}
                        />
                        <ByzzerBrandSearch
                            onlyRenderIf={includeBrands}
                            name={'brands'}
                            value={internalValue.brands as string[]}
                            onChange={handleChange}
                            label={<SelectorLabelInfo selectorCode={'brands'} sku={sku!} max={maxBrands} />}
                            categories={internalValue.categories}
                            maxSelections={maxBrands}
                        />
                        <AggregationLevelSelect
                            name={'aggregationLevel'}
                            onlyRenderIf={includeAggregationLevel}
                            value={internalValue.aggregationLevel} // matches name now
                            onChange={handleChange}
                            label={<SelectorLabelInfo selectorCode={'aggregationLevel'} sku={sku!} />}
                        />
                    </FilterSection>
                    <FilterSection onlyRenderIf={includeOmniCategories || includeOmniProducts}>
                        <ByzzerSelect
                            label={<SelectorLabelInfo selectorCode={'omniProducts'} sku={sku!} required={false} />}
                            name={'omniProductLevel'}
                            options={omniProductLevelOptions}
                            value={internalValue.omniProductLevel}
                            onChange={handleChange}
                            placeholder={'Select a product level'}
                            disabled={!internalValue.categories?.length}
                            allowClear={false}
                        />
                        <OmniBrandSearch
                            name={'omniFocusProducts'}
                            onlyRenderIf={
                                includeOmniProducts &&
                                (internalValue.omniProductLevel === 'brand' || !internalValue.omniProductLevel)
                            }
                            value={internalValue.omniFocusProducts}
                            onChange={handleChange}
                            label={
                                <>
                                    <SelectorLabelInfo selectorCode={'omniBrands'} sku={sku!} max={maxOmniProducts} />
                                </>
                            }
                            maxBrands={maxOmniProducts}
                            placeholder={'Search for brands'}
                            disabled={!internalValue.omniProductLevel || !internalValue.categories?.length}
                            omniCategories={internalValue.categories!}
                        />
                        <OmniManufacturerSearch
                            name={'omniFocusProducts'}
                            onlyRenderIf={includeOmniProducts && internalValue.omniProductLevel === 'manufacturer'}
                            value={internalValue.omniFocusProducts}
                            onChange={handleChange}
                            label={
                                <>
                                    <SelectorLabelInfo selectorCode={'omniManufacturer'} sku={sku!} max={maxOmniProducts} />
                                </>
                            }
                            maxSelections={maxOmniProducts}
                            disabled={!internalValue.omniProductLevel || !internalValue.categories?.length}
                            placeholder={'Search for manufacturers'}
                            omniCategories={internalValue.categories!}
                        />
                    </FilterSection>

                    <FilterSection onlyRenderIf={includeCharacteristicDimensions}>
                        <CharacteristicsDimensionSelect
                            name={'productDimensions'}
                            categories={internalValue.categories}
                            onChange={handleChange}
                            value={internalValue?.productDimensions}
                            label={
                                <OptionalRequired required={false}>Choose your characteristic dimension: </OptionalRequired>
                            }
                            maxSelections={maxCharacteristicDimensions}
                        />
                    </FilterSection>
                    <FilterSection onlyRenderIf={includeThresholds}>
                        <ProTipsSection
                            includeThresholds={includeThresholds}
                            includeSalesThresholds={includeSalesThresholds}
                        />
                        {/* todo: verify the name of the property for these or just handle it on the server side */}
                        <ByzzerSalesThresholdSelect
                            name={'salesThresholds'}
                            onChange={handleChange}
                            label={
                                <>
                                    <SelectorLabelInfo
                                        selectorCode={'salesThreshold'}
                                        sku={sku!}
                                        max={maxSalesThresholds}
                                        includeSuffix={true}
                                        optionalSuffix={'(Optional)'}
                                    />
                                </>
                            }
                            value={internalValue.salesThresholds}
                            onlyRenderIf={includeSalesThresholds}
                            maxSelections={maxSalesThresholds}
                        />
                        <ByzzerGrowthThresholdSelect
                            name={'growthThresholds'}
                            onChange={handleChange}
                            label={
                                <>
                                    <SelectorLabelInfo
                                        selectorCode={'growthThreshold'}
                                        sku={sku!}
                                        includeSuffix={true}
                                        optionalSuffix={'(Optional)'}
                                    />
                                </>
                            }
                            value={internalValue.growthThresholds}
                            onlyRenderIf={includeGrowthThresholds}
                        />
                    </FilterSection>
                    <FilterSection onlyRenderIf={includePPGs}>
                        <ByzzerPPGSelect
                            label="PPG Definition"
                            name={'ppgId'}
                            onChange={handleChange}
                            value={internalValue.ppgId}
                            disabled={!internalValue?.categories?.length}
                            categories={internalValue?.categories}
                        />
                    </FilterSection>
                    <FilterSection onlyRenderIf={includeCharacteristics}>
                        <span className={`${baseClassName}__label-text`}> Apply characteristic filters</span>
                        <CharacteristicCriteriaBuilder
                            name={'characteristics'}
                            value={internalValue.characteristics}
                            categories={internalValue.categories}
                            maxConditions={maxCharacteristics}
                            onChange={handleChange}
                            key={internalValue.characteristics?.length}
                            excludeCharacteristicIsNot={excludeCharacteristicIsNot}
                            includeUpcOption={true} // should eventually be set in Alby
                        />
                    </FilterSection>
                    <FilterSection onlyRenderIf={includeKeyCharacteristics}>
                        <span className={`${baseClassName}__label-text`}>Key Characteristics</span>
                        <CharacteristicCriteriaBuilder
                            name={'characteristics'}
                            joinText={'or'}
                            maxConditions={maxKeyCharacteristics}
                            value={internalValue.characteristics}
                            categories={internalValue.categories}
                            onChange={handleChange}
                            excludeCharacteristicIsNot={excludeCharacteristicIsNot}
                        />
                    </FilterSection>
                    <FocusKPISelector
                        onlyRenderIf={includeFocusKPI && defaultValues?.datatype === 'rms'}
                        label={<SelectorLabelInfo sku={sku!} selectorCode={'focusKPI'} />}
                        name={'focusKPI'}
                        value={internalValue.focusKPI}
                        onChange={handleChange}
                        placeholder={'Select Facts'}
                        options={focusKPIOptions}
                    />
                </div>
            </FilterGroup>

            <footer className={`${baseClassName}__actions`}>
                <ByzzerButton onClick={onCancelClick} type={'negative'}>
                    Cancel
                </ByzzerButton>

                <ByzzerButton onClick={onApplyClick}>Apply</ByzzerButton>
            </footer>
        </>
    );
}