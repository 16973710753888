import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './BrandCategoriesComboBuilder.scss';
import { ProductSelection } from "@/types/ReportRun";
import { ByzzerBrandSearch } from '@/components/ByzzerBrandSearch';
import { ByzzerCategorySelect } from '@/components/ByzzerCategorySelect';
import { OptionalRequired } from '@/components/OptionalRequired';
import { ByzzerChangeEventHandler, ByzzerButton } from '@byzzer/ui-components';
import { WithUid } from '@/types/WithUid';
import { nanoid } from 'nanoid';
import { clone } from 'lodash';
import { BrandCategoriesComboInput } from './BrandCategoriesComboInput';

export type BrandCategoriesComboBuilderProps = {
    name?: string;
    value: ProductSelection[];
    onChange: ByzzerChangeEventHandler<ProductSelection[]>;
    className?: string;
    minFocusBrands?: number;
    maxFocusBrands?: number;
    minCategories?: number;
    maxCategories?: number;
    minProductSelections?: number;
    maxProductSelections?: number;
    requireFocusBrands?: boolean;
    requireCategories?: boolean;
    requireProductSelections?: boolean;
}

const BLANK_PRODUCT_SELECTION: ProductSelection = {
    focusBrands: [],
    categories: []
};

export const BrandCategoriesComboBuilder = ({
    name,
    value,
    onChange,
    className,
    minFocusBrands,
    maxFocusBrands,
    minCategories,
    maxCategories,
    minProductSelections = 1,
    maxProductSelections = 1,
    requireFocusBrands,
    requireCategories,
    requireProductSelections,
    ...props
}: BrandCategoriesComboBuilderProps) => {

    const [internalValue, setInternalValue] = useState<WithUid<ProductSelection, string>[]>([]);
    const initialValueLoaded = useRef<Boolean>(false);

    const baseClassName = 'byz-brand-category-combo-builder';


    useEffect(() => { 
        // TODO - create logic to map given value to conditions.  probably only needed when defaults are given or rendering for first time
        // only do this on load for given value list.  otherwise, changes will be handled in onchange to prevent rebuidling list every time.
    // console.log(`BrandCategoriesComboBuilder - useEffect / 'value' updated ===>> `, value);
        if (!initialValueLoaded.current ) {
            if (value?.[0]?.focusBrands?.length || value?.[0]?.categories?.length) { 
                // some preset value(s) to load, either from a prior report run, user defaults, or coming back to this after unmounting
                // console.log("(SHOULD I FIRE? LOADING DEFAULT OR RE-MOUNTING...?) ProductSelectionBuilder - value ===>> ", value)
                const valuesWithUids = value?.map((val) => ({ 
                    value: val, 
                    uid: nanoid() 
                }));
                setInternalValue(valuesWithUids);
                initialValueLoaded.current = true; // finished initial load script, set to true to prevent above from reoccuring
            } else { // after mounting and after clicking radio button and no prior value to load, create a new default condition
                // console.log("(I THINK I SHOULD FIRE TO START THIS THING OFF?!) ProductSelectionBuilder - value ===>> ", value)
                setInternalValue([createDefaultProductSelection()]);
            }
        }
    }, [value]);

    useEffect(() => {
        // console.log(`BrandCategoriesComboBuilder - useEffect / 'internalValue' updated ===>> `, internalValue);
    }, [internalValue])


    function updateStates(
        valuesWithUids: WithUid<ProductSelection, string>[], 
        onChange?: (e: ByzzerChangeEvent<ProductSelection[]>) => void
    ) {
        if (onChange) { // onChange should fire together with setInternalValue
            onChange({
                name,
                value: valuesWithUids.map((productSelection) => productSelection.value), // send only condition value without uid
            });
        }
        // triggers render of conditions, which kicks off updates in children conditions elements
        setInternalValue(valuesWithUids); // setInternalValue should fire together with onChange
    }




    function handleChange(e: ByzzerChangeEvent<ProductSelection>, index) {
        // console.log("BrandCategoriesComboBuilder - handleChange fired - 'e', 'index (uid)' ===>> ", e, index)
        const valuesWithUids = internalValue.map((val, i) => {
            if (val.uid === index) {
                return { value: e.value, uid: val.uid }
            } else {
                return val
            }
        });
        // since this is currently not 100% a controlled component, for performance/UX reasons, state update functions are combined as one function and should fire together and be treated as one until better solution is implemented
        updateStates(valuesWithUids, onChange)
    }

    function createDefaultProductSelection() {
        return { 
            value: clone(BLANK_PRODUCT_SELECTION), 
            uid: nanoid()
        }
    }

    function add() {
        setInternalValue((currentProductSelections) => [...currentProductSelections, createDefaultProductSelection()]);
        // https://stackoverflow.com/questions/39549424/how-to-create-unique-keys-for-react-elements
    }

    function remove(productSelectionUid: string) {
        const filteredProductSelections = internalValue.filter((productSelection, i) => productSelectionUid !== productSelection.uid);
        updateStates(filteredProductSelections, onChange)
    }

    return (
        <div className={classnames(baseClassName)} {...props}>
            {internalValue.map(({ value: productSelection, uid }, index) => {
                return (
                    <React.Fragment key={uid}>
                        <BrandCategoriesComboInput
                            name={'productSelections'}
                            value={productSelection}
                            onChange={(e) => handleChange(e, uid)}
                            className={className}
                            minCategories={minCategories}
                            maxCategories={maxCategories}
                            minFocusBrands={minFocusBrands}
                            maxFocusBrands={maxFocusBrands}
                            requireFocusBrands={requireFocusBrands}
                            requireCategories={requireCategories}
                            requireProductSelections={requireProductSelections}
                            actions={maxProductSelections <= 1 ? undefined : (
                                <>
                                    <ByzzerButton
                                        label="+"
                                        onClick={add}
                                        disabled={(maxProductSelections ?? Infinity) <= internalValue.length}
                                    />
                                    <ByzzerButton
                                        label="-"
                                        onClick={() => remove(uid)}
                                        disabled={minProductSelections >= internalValue.length}
                                    />
                                </>
                            )}
                        />
                        {Boolean(internalValue?.length-1 !== index) && <hr/>}
                    </React.Fragment>
                )


            })}
        </div>
    )

};

export default BrandCategoriesComboBuilder;

BrandCategoriesComboBuilder.displayName = 'BrandCategoriesComboBuilder';