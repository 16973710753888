import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useUser } from '@/contexts/UserContext';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import QuoteReviewHeader from './QuoteReviewHeader';
import QuoteCart from './QuoteCart';
import { QuoteFooter } from '../QuoteFooter';
import { QuoteDetail } from '@/types/QuoteTypes';
import './QuoteReview.scss';

const baseClassName = 'quote-review';

type QuoteReviewPropsType = {
    placeOrder?: () => void;
}

export default function QuoteReview({ placeOrder }: QuoteReviewPropsType) {
    const navigate = useNavigate();

    const { userQuote } = useUser();

    const [loadingMessage, setLoadingMessage] = useState<string>('');
    const [quoteData, setQuoteData] = useState<QuoteDetail>();

    const userName = quoteData?.quoteDetails?.employee?.firstName + ' ' + quoteData?.quoteDetails?.employee?.lastName;

    useEffect(() => {
        if (userQuote && userQuote?.quoteData) {
            setQuoteData(userQuote?.quoteData)
            setLoadingMessage('');
        }
    }, [userQuote]);

    // NOTE: FETCH THE DATA IN THIS COMPONENT AND PASS THE QUOTE TO OTHER AS PROPS

    const handlePlaceOrder = useCallback(() => {
        // note: If every data seems correct, then we should move to PAYMENT screen
        navigate(`/quote/accept`);
    }, []);

    return (
        <>
            <ByzzerMask loading={Boolean(loadingMessage)} show={Boolean(loadingMessage)}>
                <span>{loadingMessage}</span>
            </ByzzerMask>
            <QuoteReviewHeader
                quoteId={quoteData?.id}
                userName={userName}
                role={quoteData?.quoteDetails?.employee?.isSalesRep === 'T' ? 'Sale Representative' : ''}
                email={undefined}
                baseClassName={baseClassName}
                dateOfExpiry={quoteData?.quoteDetails?.expiryDate}
                termInMonths={quoteData?.quoteDetails?.termInMonths}
            />
            {/* Order table will be here */}
            <QuoteCart baseClassName={baseClassName} quoteItems={quoteData?.quoteDetails?.items || []} />
            {/* Footer with order buttons */}
            <QuoteFooter baseClassName={baseClassName} acceptOrder={placeOrder || handlePlaceOrder} quote={quoteData} setLoadingMessage={setLoadingMessage}/>
        </>
    );
}
