import './ProductLookup.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { openErrorModal } from '@/components/form/ByzzerModal';
import {useTenantApi} from '@/hooks/useTenantApi';
import { ByzzerMask } from "@/components/ByzzerMask/ByzzerMask";
import DashboardContent from '../dashboard/DashboardContent';


export function ProductLookup() {
    const { getReportLinkByName } = useTenantApi();
    const baseClassName = 'product-lookup'
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [embedUrl, setEmbedUrl] = useState('');

    useEffect(() => {
        getProductReport('product');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProductReport = async (reportName) => {
        try {
            setLoading(true);
            const { url } = await getReportLinkByName(reportName);
            //Sanity check.  Success message but empty url
            if (!url?.length) {
                await openErrorModal({
                    title: `Report Error`,
                    content: (
                        <>
                            <p>We were unable to generate the product list at this time.</p>
                            <p>Please try again later or contact customer support.</p>
                        </>
                    ),
                });
                navigate(-1);
                return;
            }

            setEmbedUrl(url);

            // Adding delay to reset loader to handle the gap of looker embed iframe render
            setTimeout(() => setLoading(false), 2500);

        } catch (err) {
            alert(err?.message);
            navigate(-1);
            setLoading(false);
        }
    };

    return (
        <div className={`${baseClassName}__page-container`}>
            <ByzzerMask show={loading} loading={loading} />
            {embedUrl && (
                <iframe src={embedUrl} className={`${baseClassName}__looker-report`}
                    title="Product View Embed"></iframe>
            )}
        </div>
    );
}

export default ProductLookup;
