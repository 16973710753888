// To Do: Refactor this file based on latest coding standards
import '../../../pages/MyAlertsPage/MyAlertsPage.scss';
import { ByzzerButton } from '@byzzer/ui-components';
import parse from 'react-html-parser';
import {
    generateTable,
    formatPercent,
    toTitleCase,
    formatDateMMDDYYYY,
    numberWithCommas,
    formatPrice,
    formatPercentWithSign,
    generateOrderedList,
    generateBasicOrderedList,
    generateOrderedListWithBrandAndGrowth,
    textBold,
} from '../utils/alertUtil1_0';
import {AlertViewActions}  from '../utils/helpers';

function MyAlertView1_0({ goBack, ...props }) {
    const { 
        alertData: {
            createdBy
        }
    } = props;
    let currentAlertBody = [];


    const tableConfig = {
        percentKeys: [],
        titleCaseColumns: [],
        dollarColumns: [],
        columnMapping: {},
        defaultValue: {},
    };

    const footerLink = '<a target="_blank" href="https://learn.byzzer.ai">Byzzer Resources</a>';
    let footerText =
        'FMCG Retailers or Fast Moving Consumer Goods is composed of Grocery, Drug, Mass, Dollar, Club, and Military Retailers. For more information about your alerts and the data powering them, head to the ' +
        footerLink;

    let alert = props.alertData;
    // let alertResults = alert.results.data ? JSON.parse(alert.results).data : JSON.parse(alert.results);
    let alertResults = props.alertData.results;
    const alertMap = props.alertMap;
    let config = { ...tableConfig };



    let duration = formatDateMMDDYYYY(alertResults.filter_duration.split('to')[1].trim());
    let filter_data = {
        filter_category: toTitleCase(alertResults.filter_category),
        filter_market: toTitleCase(alertResults.filter_market),
        filter_brand: toTitleCase(alertResults.filter_brand),
    };
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'MARKET_SHARE') {
        config.columnMapping = {
            market: 'Market',
            brand_level_share: 'Brand $ Share',
            share_change: 'Share pt Chg',
            brand_level_brand_growth: 'Brand Growth',
        };
        config.defaultValue = {
            share_change: 'N/A',
            brand_level_brand_growth: 'N/A',
        };
        config.percentKeys = ['brand_level_share', 'brand_level_brand_growth'];
        let filter_duration = `4 weeks ending ${duration}`;

        currentAlertBody.push(<div className="detailed_card_subheading">Your Performance:</div>);
        currentAlertBody.push(
            `In the last ${filter_duration}, ${filter_data.filter_brand} ${alertResults.share_of_category_change_gained_or_lost
            } market share by ${formatPercent(alertResults.share_of_category_change)} points ${(
                alertResults.reason || ''
            )
                .toString()
                .replace('the category', '')} ${filter_data.filter_category}.`
        );
        currentAlertBody.push(<div className="detailed_card_subheading">Your category performance:</div>);
        currentAlertBody.push(
            `Overall sales in the ${filter_data.filter_category} category are $${numberWithCommas(
                alertResults.total_dollars
            )}. The category sales ${alertResults.category_sales_change_direction} ${formatPercent(
                alertResults.category_sales_change
            )}% and your sales ${alertResults.sales_change_percent_direction} ${formatPercent(
                alertResults.sales_change_percent
            )}%, resulting in a ${formatPercent(alertResults.share_of_category_change)} share point ${alertResults.share_of_category_change_gain_or_decline
            } to ${formatPercent(alertResults.share_of_category)}% vs  prior year.`
        );
        currentAlertBody.push(
            parse(
                `Are you keeping pace with other brands in ${filter_data.filter_category
                }? Find out how you compare on the ${textBold('Brand Share Report.')}`
            )
        );

        currentAlertBody.push(<div className="detailed_card_subheading">Your performance by market:</div>);

        let highest_market_text = '';
        if (alertResults.biggest_share_gains_by_market?.length) {
            highest_market_text = `${filter_data.filter_brand}'s greatest share increase was ${alertResults.highest_market
                } where you gained ${formatPercent(alertResults.highest_share_change)} share points.`;
        }

        currentAlertBody.push(
            parse(
                `At the market level, ${filter_data.filter_brand} gained share in ${alertResults.gained_share_from_total_retailers
                } markets and lost share in ${alertResults.lost_share_from_total_retailers
                } markets. ${highest_market_text} To find out the drivers of your performance check out ${textBold(
                    'Business Drivers Waterfall Report'
                )} which quantifies the volume impact of levers such as price, promotion and distribution.`
            )
        );
        if (alertResults.biggest_share_gains_by_market?.length) {
            currentAlertBody.push(<div className="detailed_card_subheading">Your biggest share gains were:</div>);
            currentAlertBody.push(parse(generateTable(alertResults.biggest_share_gains_by_market, config)));
        }

        let lowest_market_text = '';
        if (alertResults.biggest_share_losses_by_market?.length) {
            lowest_market_text = `${filter_data.filter_brand}'s greatest share decrease was ${alertResults.lowest_market
                } where you lost ${formatPercent(alertResults.lowest_share_change)} share points.`;
        }

        currentAlertBody.push(
            parse(
                `${lowest_market_text} Benchmark the performance of your ${filter_data.filter_brand
                } products to other Brands on the ${textBold('Product Performance Report.')}`
            )
        );

        if (alertResults.biggest_share_losses_by_market?.length) {
            currentAlertBody.push(<div className="detailed_card_subheading">Your biggest share losses were:</div>);
            currentAlertBody.push(parse(generateTable(alertResults.biggest_share_losses_by_market, config)));
        }

        currentAlertBody.push(
            `This alert was prepared for ${filter_data.filter_brand} on the ${filter_data.filter_category} based on FMCG Retailers for the ${filter_duration} vs. Last Year.`
        );
        currentAlertBody.push(parse(footerText));

    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'COMPETITIVE_PRICING') {
        config.columnMapping = {
            brand: 'Brand',
            total_average_price_for_l4w: 'Avg Price',
            non_promo_average_price_for_l4w: 'NP Price',
            everyday_price_change: 'NP % Chg',
            sales_promo: '% Sales Promo',
            any_promo_average_price_for_l4w: 'Promo Price',
            promotion_price_change: 'Promo Price % Chg',
        };
        config.defaultValue = {
            non_promo_average_price_for_l4w: 'N/A',
            everyday_price_change: 'N/A',
            sales_promo: 'N/A',
            any_promo_average_price_for_l4w: 'No discount',
        };
        config.percentKeys = ['everyday_price_change', 'sales_promo', 'promotion_price_change'];
        config.titleCaseColumns = ['brand'];
        config.dollarColumns = [
            'total_average_price_for_l4w',
            'non_promo_average_price_for_l4w',
            'any_promo_average_price_for_l4w',
        ];
        let filter_duration = `4 weeks ending ${duration}`;

        currentAlertBody.push(<div className="detailed_card_subheading">Your Pricing:</div>);
        currentAlertBody.push(
            parse(
                `The ${filter_data.filter_brand}'s average price was $${alertResults.total_average_price_for_l4w_yes
                } for the ${filter_duration}, ${alertResults.total_average_price_change_yes_direction
                } ${formatPercent(
                    alertResults.total_average_price_change_yes
                )}% vs. prior 4 weeks, causing your ratio to competitors to change by ${formatPercent(
                    alertResults.ratio_change
                )}% in ${filter_data.filter_category}. ${alertResults.ratio_change_inc_decr
                } in your pricing ratio to competitors indicates you are relatively ${alertResults.ratio_change_more_less
                } expensive than your competitors than you were in the previous 4 weeks. Understand how your price position is changing vs. the category and other brands in the ${textBold(
                    'Price Comparison vs. Category Report.'
                )}`
            )
        );

        currentAlertBody.push(<div className="detailed_card_subheading">Your Category Pricing:</div>);
        currentAlertBody.push(
            `The average price for ${filter_data.filter_category} is $${alertResults.total_average_price_for_l4w_no
            }, ${alertResults.total_average_price_change_no_direction} ${formatPercent(
                alertResults.total_average_price_change_no
            )}% vs. prior 4 weeks. The average price is impacted by the everyday non-promoted prices, promoted prices, and proportion of sales that are promoted.`
        );
        currentAlertBody.push(
            `The average everyday price was $${formatPrice(
                alertResults.non_promo_average_price_for_l4w
            )}, ${formatPercentWithSign(alertResults.change_non_promo_price)}% vs. prior 4 weeks.`
        );
        currentAlertBody.push(
            `The average promoted price was $${alertResults.any_promo_average_price_for_l4w === 'No discount'
                ? alertResults.any_promo_average_price_for_l4w
                : formatPrice(alertResults.any_promo_average_price_for_l4w)
            }, ${formatPercentWithSign(alertResults.change_promo_price)}% vs. prior 4 weeks.`
        );
        currentAlertBody.push(
            `${formatPercent(alertResults.on_deal)}% of volume was sold on promotion, ${formatPercentWithSign(
                alertResults.on_deal_change
            )}% vs. prior 4 weeks.`
        );

        currentAlertBody.push(<div className="detailed_card_subheading">Your Competitor Pricing:</div>);

        currentAlertBody.push(
            parse(
                `What brands to watch? Check out which brands had the greatest pricing changes over the past 4 weeks in ${filter_data.filter_category
                }. For more details on the everyday vs. promoted price run the ${textBold(
                    'Price Change Over Time Report.'
                )}`
            )
        );
        if (alertResults.biggest_price_change?.length) {
            currentAlertBody.push(
                `Brands with the biggest increase in average price, with details on the Everyday/Non-promoted(NP) Price and Promoted Price:`
            );
            currentAlertBody.push(parse(generateTable(alertResults.biggest_price_change, config)));
        }
        if (alertResults.lowest_price_change?.length) {
            currentAlertBody.push(
                `Brands with the biggest decrease in average price, with details on the Everyday/Non-promoted(NP) Price and Promoted Price:`
            );
            currentAlertBody.push(parse(generateTable(alertResults.lowest_price_change, config)));
        }

        currentAlertBody.push(
            `This alert was prepared for ${filter_data.filter_brand} on the ${filter_data.filter_category} based on FMCG Retailers for the ${filter_duration} vs. Previous 4 Weeks.`
        );
        currentAlertBody.push(parse(footerText));

        // setCurrentAlertBody(currentAlertBody);
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'DISTRIBUTION_GAIN_AND_LOSSES') {
        config.percentKeys = ['tdp_share', 'category_share'];
        config.columnMapping = {
            market: 'Market',
            tdp_share: 'Ditro Shr',
            tdp_share_change: 'Distro Shr pt Chg',
            category_share: 'Cat Share',
            category_share_pt_change: 'Cat Shr pt Chg',
            distribution_fair_share: 'Distro Fair Shr',
        };
        config.defaultValue = {
            tdp_share_change: 'New market',
            category_share_pt_change: 'New market',
        };
        let filter_duration = `13 weeks ending ${duration}`;
        currentAlertBody.push(<div className="detailed_card_subheading">Your Performance:</div>);
        currentAlertBody.push(
            parse(
                `${filter_data.filter_brand}'s distribution is ${alertResults.category_tdps_change_ya_direction
                } ${formatPercent(
                    alertResults.category_tdps_change_ya
                )}% in the latest ${filter_duration} and your share of distribution in ${filter_data.filter_category
                } has ${alertResults.share_of_tdp_diff_direction} by ${Math.abs(
                    formatPercent(alertResults.share_of_tdp_diff)
                )} share points from ${formatPercent(alertResults.share_of_tdp_year_ago)}% to ${formatPercent(
                    alertResults.share_of_tdp
                )}% vs. last year. Investigate your performance and share of distribution by week on the ${textBold(
                    'Category and Brand Trend Report.'
                )} Your distribution is made up of all the SKUs your brand offers multiplied by each retailer-store selling. Your share of distribution is how well your products are represented in the category. For a full review of ${filter_data.filter_brand
                }'s distribution vs the category and other brands run the ${textBold(
                    'Assortment Landscape Smart Report.'
                )}`
            )
        );
        currentAlertBody.push(
            `Benchmark your share of distribution to your share of sales to ensure you are receiving your fair share. Your share of distribution is ${formatPercent(
                alertResults.share_of_tdp
            )}% and your share of sales is ${formatPercent(alertResults.share_of_sales)}% meaning you are ${alertResults.category_sales_share_direction
            } distributed based on your performance and you should ${alertResults.category_sales_share_comment}.`
        );

        currentAlertBody.push(<div className="detailed_card_subheading">Your Category Performance:</div>);
        currentAlertBody.push(
            `${filter_data.filter_category} distribution is ${alertResults.category_tdps_change_percent_direction
            } ${formatPercent(alertResults.category_tdps_change_percent)}%`
        );
        if (alertResults.biggest_gain_brands?.length) {
            currentAlertBody.push(
                `The Brands with the biggest gains in distribution for ${filter_data.filter_category} were:`
            );
            currentAlertBody.push(parse(generateBasicOrderedList(alertResults.biggest_gain_brands)));
        }
        if (alertResults.biggest_gain_brands?.length) {
            currentAlertBody.push(`The biggest declines in distribution were:`);
            currentAlertBody.push(parse(generateBasicOrderedList(alertResults.biggest_declined_brands)));
        }

        currentAlertBody.push(
            parse(
                `Benchmark the share of distribution and distribution growth of your products to other Brands with the ${textBold(
                    'Product Performance Report.'
                )}`
            )
        );
        currentAlertBody.push(<div className="detailed_card_subheading">Your performance across markets:</div>);

        let highest_increase_share_pt_market = '';
        let highest_decline_share_pt_market = '';
        let highest_incr_decr_share_pt_market_text = `. ${filter_data.filter_brand} had the greatest `;
        if (alertResults.highest_increase_tdp_share_change_pt > 0) {
            highest_increase_share_pt_market = `increase in distribution in ${alertResults.highest_increase_by_market
                } (${formatPercent(
                    alertResults.highest_increase_tdp_share_diff
                )} distribution share pts to ${formatPercent(alertResults.highest_increase_tdp_share)}%)`;
        }
        if (alertResults.highest_decline_tdp_share_change_pt < 0) {
            highest_decline_share_pt_market = `distribution decline in ${alertResults.highest_decline_by_market
                } (${formatPercent(alertResults.highest_decline_tdp_share_diff)} share pts to ${formatPercent(
                    alertResults.highest_decline_tdp_share
                )}%)`;
        }
        if (highest_increase_share_pt_market && highest_decline_share_pt_market)
            highest_incr_decr_share_pt_market_text +=
                highest_increase_share_pt_market + ' and ' + highest_decline_share_pt_market;
        else if (highest_increase_share_pt_market)
            highest_incr_decr_share_pt_market_text += highest_increase_share_pt_market;
        else if (highest_decline_share_pt_market)
            highest_incr_decr_share_pt_market_text += highest_decline_share_pt_market;
        else highest_incr_decr_share_pt_market_text = '';

        currentAlertBody.push(
            parse(
                `Investigate your Distribution Share to understand where you are not capturing your fair share and should increase distribution or are capturing your fair share and should focus on increasing velocity${highest_incr_decr_share_pt_market_text}. Find out if you are capturing your fair share of distribution based on your performance with the ${textBold(
                    'Market Opportunity Report.'
                )}`
            )
        );
        if (alertResults.biggest_gains?.length) {
            currentAlertBody.push(
                `Compare ${filter_data.filter_brand}'s distribution share (Distro Shr) to ${filter_data.filter_brand}'s category share (Cat Shr) to understand if you are over or under your fair share. ${filter_data.filter_brand}'s biggest distribution (distro) gains were:`
            );
            currentAlertBody.push(parse(generateTable(alertResults.biggest_gains, config)));
        }
        if (alertResults.biggest_declines?.length) {
            currentAlertBody.push(
                `Compare ${filter_data.filter_brand}'s distribution share (Distro Shr) to ${filter_data.filter_brand}'s category share (Cat Shr) to understand if you are over or under your fair share. ${filter_data.filter_brand}'s biggest distribution (distro) losses were:`
            );
            currentAlertBody.push(parse(generateTable(alertResults.biggest_declines, config)));
        }

        currentAlertBody.push(
            `This alert was prepared for ${filter_data.filter_brand} on ${filter_data.filter_category} based on FMCG Retailers for the ${filter_duration} vs. Last Year.`
        );
        currentAlertBody.push(parse(footerText));

        // setCurrentAlertBody(currentAlertBody);
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'PROMOTION_EFFICIENCY') {
        config.columnMapping = {
            market: 'Market',
            promotion_efficiency: 'Promotion Efficiency',
            change_in_promotion_efficiency: 'Change in Promotion Efficiency',
        };
        config.defaultValue = {
            change_in_promotion_efficiency: 'New market',
            promotion_efficiency: 'N/A',
        };
        config.percentKeys = ['change_in_promotion_efficiency'];
        config.dollarColumns = ['promotion_efficiency'];
        let filter_duration = `13 weeks ending ${duration}`;
        let opportunity_text = alertResults.opportunity
            ? `Bringing promotions up to best in class levels is worth $${numberWithCommas(
                alertResults.opportunity
            )}`
            : '';

        currentAlertBody.push(<div className="detailed_card_subheading">Your Performance:</div>);
        currentAlertBody.push(
            parse(
                `In the ${filter_duration}, ${filter_data.filter_brand}'s promotion efficiency in ${filter_data.filter_category
                } is $${alertResults.promotion_efficiency
                }, which means for every $1 spent discounting the product, the return is $${alertResults.promotion_efficiency
                } in lift (incremental sales dollars). The greater your incremental sales per $1 spent, the more efficient the promotion for you and the retailer. ${filter_data.filter_brand
                }'s efficiency ${alertResults.change_promotion_efficiency_direction} by ${formatPercent(
                    Math.abs(alertResults.change_promotion_efficiency)
                )}% and is $${formatPrice(alertResults.promotion_efficiency_vs_best_in_class)} ${alertResults.efficiency_more_or_less
                } efficient vs. best in class promotions in your category. ${opportunity_text}. Evaluate your promotion efficiency over time with the ${textBold(
                    'Promotion Incremental Sales Trend Report.'
                )}`
            )
        );
        currentAlertBody.push(<div className="detailed_card_subheading">Your Performance across Markets:</div>);
        currentAlertBody.push(
            parse(
                `At the market level, ${alertResults.total_biggest_increase
                } market(s) have seen an increase in promotion efficiency while ${alertResults.total_biggest_decline
                } market(s) have seen a decline in promotion efficiency. Review your promotion efficiency by market with the ${textBold(
                    'Promotion Efficiency Comparison Report'
                )} or dive deep on a market and the merchandising support with the ${textBold(
                    'Promotion Support and Lift Report.'
                )}`
            )
        );
        if (alertResults.biggest_increase?.length) {
            currentAlertBody.push(
                `Markets with the biggest increase in promotion efficiency for ${filter_data.filter_brand}:`
            );
            currentAlertBody.push(parse(generateTable(alertResults.biggest_increase, config)));
        }
        if (alertResults.biggest_declines?.length) {
            currentAlertBody.push(
                `Markets with the biggest decline in promotion efficiency for ${filter_data.filter_brand}:`
            );
            currentAlertBody.push(parse(generateTable(alertResults.biggest_declines, config)));
        }

        currentAlertBody.push(
            `This alert was prepared for ${filter_data.filter_brand} on the ${filter_data.filter_category} based on FMCG Retailers for the ${filter_duration} vs. Last Year. `
        );
        currentAlertBody.push(parse(footerText));

        // setCurrentAlertBody(currentAlertBody);
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'ECOSYSTEM_ALERT') {
        console.log('alert result-- 237', alertResults);
        let filter_duration = `4 weeks ending ${duration}`;

        // currentAlertBody.push(`Duration: ${alertResults.filter_duration}`);

        // currentAlertBody.push(`Market: ${alertResults.filter_market}`);

        currentAlertBody.push(<div className="detailed_card_subheading">Market Performance:</div>);

        currentAlertBody.push(
            parse(
                `In the latest ${filter_duration}, ${toTitleCase(
                    alertResults.category
                )} sales are $${numberWithCommas(alertResults.category_sales)}, ${alertResults.category_sales_change_direction
                } ${formatPercent(alertResults.category_sales_change)}% vs. the prior year.  ${formatPercent(
                    alertResults.sales_from_top_10_market
                )}% of sales are coming from the top 10 markets. Dive deeper into the performance drivers - velocity, distribution, price, promotion efficiency -  of a market with the ${textBold(
                    'Promo vs Non Promo Decomp Tree Report.'
                )}`
            )
        );

        if (alertResults.top_market_sales?.length) {
            currentAlertBody.push(`The largest markets are:`);
            currentAlertBody.push(parse(generateOrderedList(alertResults.top_market_sales, 'top_market_sales')));
        }

        if (alertResults.highest_growing_market?.length) {
            currentAlertBody.push(`The quickest growing markets are:`);
            currentAlertBody.push(
                parse(generateOrderedList(alertResults.highest_growing_market, 'growing_market'))
            );
        }

        // {{custom.highest_growing_market}}
        if (alertResults.lowest_growing_market?.length) {
            currentAlertBody.push(`The biggest decline by market are in: `);
            currentAlertBody.push(parse(generateOrderedList(alertResults.lowest_growing_market, 'growing_market')));
        }

        currentAlertBody.push(<div className="detailed_card_subheading">Brand Performance:</div>);

        // {{custom.lowest_growing_market}}

        currentAlertBody.push(
            parse(
                `${formatPercent(
                    alertResults.sales_from_top_10_brands
                )}% of sales are coming from the top 10 brands which ${alertResults.sales_change_from_top_10_brands_direction
                } ${formatPercent(
                    alertResults.sales_change_from_top_10_brands
                )}%. For a full landscape of the brands in the category and their performance check out the ${textBold(
                    'Brand Ranking Report.'
                )}`
            )
        );

        if (alertResults.largest_brand_sales?.length) {
            currentAlertBody.push(`The largest brands in the category are:`);
            currentAlertBody.push(
                parse(generateOrderedList(alertResults.largest_brand_sales, 'largest_brand_sales'))
            );
        }

        if (alertResults.highest_growth_brands?.length) {
            currentAlertBody.push(`The highest growth brands vs. the last year:`);
            currentAlertBody.push(parse(generateOrderedListWithBrandAndGrowth(alertResults.highest_growth_brands)));
        }

        if (alertResults.growing_brands?.length) {
            currentAlertBody.push(`The quickest growing brands vs. the last 4 weeks:`);
            currentAlertBody.push(parse(generateOrderedListWithBrandAndGrowth(alertResults.growing_brands)));
        }

        currentAlertBody.push(
            `This alert was prepared on ${filter_data.filter_category} category based on FMCG Retailers for the ${filter_duration} vs. Last Year.`
        );
        currentAlertBody.push(parse(footerText));
    }

    // setCurrentAlertBody(currentAlertBody);
    const showCreatedBy = Boolean(createdBy?.fullName && createdBy?.email);

    return (
        <>
            <div className="my-alerts detailed-my-alerts">
                <div className="my-alerts__heading"> {alert.title} Alert</div>

                <AlertViewActions goBack={goBack}/>

                <div className="my-alerts__sub-heading"> For the latest week, ending {duration}</div>
                {showCreatedBy && <div>Created by {createdBy?.fullName} ({createdBy?.email})</div>}
            </div>


            <div
                className="detailed_card detailed_card_scroll"
                style={{
                    gridColumnStart: 1,
                    gridColumnEnd: 5,
                }}
            >
                {currentAlertBody &&
                    currentAlertBody.map((section, index) => {
                        return (
                            <div className="card_paragraph">
                                {/* <ol className="list_container">
                                    <li> */}
                                {section}

                                {/* </li>
                                </ol> */}
                            </div>
                        );
                    })}
            </div>


        </>
    )
}
// const mapState = (state) => ({
//     user: state.account.user,
// });
// export default connect(mapState)(MyAlertView1_0);
export default MyAlertView1_0