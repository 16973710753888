import { forwardRef } from 'react';
import Carousel, { CarouselProps } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './ByzzerCarousel.scss';

/**Link to follow for documenatation :
 * https://www.npmjs.com/package/react-multi-carousel
 * https://github.com/YIZHUANG/react-multi-carousel/blob/master/stories/index.stories.js
 * https://react-multi-carousel.surge.sh/
 */

export type ByzzerCarouselProps = {
    containerClass?: string;
    className?: string;
    slidesToSlide?: number;
    playSpeed?: number;
    itemClass?: string;
    infinite?: boolean;
    draggable?: boolean;
    swipeable?: boolean;
    autoplay?: boolean;
    showDots?: boolean;
} & Partial<CarouselProps>;

export const ByzzerCarousel = forwardRef<Carousel, ByzzerCarouselProps>(
    (
        {
            containerClass = 'container-with-dots',
            slidesToSlide = 1,
            className,
            playSpeed = 1000,
            itemClass,
            infinite = true,
            draggable = false,
            swipeable = false,
            autoPlay = false,
            showDots = true,
            ...props
        },
        ref
    ) => {
        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 5,
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1,
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
            },
        };

        return (
            <Carousel
                containerClass={containerClass}
                className={className}
                infinite={infinite}
                responsive={responsive}
                draggable={draggable}
                swipeable={swipeable}
                slidesToSlide={slidesToSlide}
                autoPlaySpeed={playSpeed}
                autoPlay={autoPlay}
                itemClass={itemClass}
                showDots={showDots}
            >
                {props.children}
            </Carousel>
        );
    }
);
export default ByzzerCarousel;

ByzzerCarousel.displayName = 'ByzzerCarousel';
