import { forwardRef, ReactNode, useEffect, useState } from 'react';
import './ByzzerPPGSelect.scss';
import classnames from 'classnames';
import { ByzzerChangeEventHandler, ByzzerSelect, ByzzerSelectOption, WithValue } from '@byzzer/ui-components';
import { ByzzerLink } from '@/components/form';
import { useNavigate } from 'react-router-dom';
import { getPPGOptionsforCategories, DEFAULT_PPG_OPTION, PPGOption } from '@/services/ppg.service';
import { SelectorLabelInfo } from '../SelectorLabelInfo/SelectorLabelInfo';

const baseClassName = 'ppg-select';

export type ByzzerPPGSelectProps = {
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<number | null>;
    value?: number | null;
    disabledOptions?: string[];
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    categories?: string[];
    allowClear?: boolean;
    showDescription?: boolean;
    dontUseDefault?: boolean;    
    sku?:string;
    required?:boolean;
    maxPPG?:number;
} & OnlyRenderableIf;

const ByzzerPPGSelect = forwardRef<WithValue<string | null>, ByzzerPPGSelectProps>(
    (
        {
            onChange,
            name,
            label,
            className,
            disabledOptions = [],
            placeholder = 'Select PPG Definition',
            dontUseDefault = false,
            value,
            disabled,
            categories,
            onlyRenderIf,
            allowClear = false,
            showDescription = true,    
            sku,    
            required,
            maxPPG,    
        },
        ref
    ) => {
        const [internalValue, setInternalValue] = useState<string>('');
        const [ppgOptions, setPpgOptions] = useState<ByzzerSelectOption[]>([]);
        const navigate = useNavigate();

        useEffect(() => {
            // setPpgOptions([]);
            if (value) {
                setInternalValue(value.toString());
            } else {
                setInternalValue('');
            }
            // console.log(`ByzzerPPGSelect - useEffect / 'value' updated ===>> `, value);
        }, [value]);

        useEffect(() => {
            // console.log(`ByzzerPPGSelect - useEffect / 'internalValue' updated ===>> `, internalValue);
        }, [internalValue]);

        useEffect(() => {
            if (categories?.length) {
                loadPPGOptions();
            } else {
                setPpgOptions([]);
            }
        }, [categories]);

        const handleChange = (e: ByzzerChangeEvent<string>) => {
            const newValue = e.value;
            if (onChange) {
                onChange({
                    name,
                    value: +newValue,
                    data: e.data
                })
            } else {
                setInternalValue(newValue)
            }
        }

        const loadPPGOptions = async () => {
            // const ppgOptionsList = getPPGOptionsforCategories(categories).filter((ppg) => !(dontUseDefault ? [-1,-2] : [])?.includes(ppg?.value));
            const ppgOptionsList = getPPGOptionsforCategories(categories)?.map((option) => {
                return {
                    ...option,
                    value: option?.value?.toString()
                }
            });

            const defaultOptions = ppgOptionsList?.filter((option) => +option?.value < 0) ?? [];
            const otherOptions = ppgOptionsList?.filter((option) => +option?.value >= 0) ?? [];

            setPpgOptions([
                ...defaultOptions,
                ...otherOptions
            ]);
        };

        const redirectToPPG = () => {
            navigate('/dashboard/ppgs', { replace: true });
        };

        const labelAndSubText = () => {
            return (
                <>
                    {/* <div className={`${baseClassName}__label`}>
                        {label}
                    </div> */}
                    {showDescription && ( // TODO: a temp workaround to hide a description that's built into ppgselect.  ultimately we'll want to move that description up-n-out.  for now just hiding it
                        <>
                            <div className={`${baseClassName}__description`}>
                                {ppgOptions?.length === 0 ? (
                                    <>
                                        Your Team has not created any PPG list for the selected categories. Head to{' '}
                                        {/* @ts-ignore */}
                                        <ByzzerLink onClick={redirectToPPG}>PPGs</ByzzerLink> to define your own.
                                    </>
                                ) : (
                                    <>Choose which PPG definition to use.</>
                                )}
                                <SelectorLabelInfo
                                    selectorCode={'ppgId'}
                                    sku={sku as string}
                                    required={required}
                                    includeSuffix={!required}
                                    isLabelBold={true}
                                    max={maxPPG}
                                />
                            </div>
                        </>
                    )}
                </>
            );
        };

        if (onlyRenderIf === false) return <></>;

        return (
            <>
                {labelAndSubText()}
                <ByzzerSelect
                    ref={ref}
                    name={name}
                    className={classnames(baseClassName, className)}
                    options={ppgOptions}
                    disabled={disabled}
                    placeholder={placeholder}
                    value={internalValue}
                    disabledOptions={disabledOptions}
                    onChange={handleChange}
                    allowMultiple={false}
                    allowClear={allowClear}
                    label={label}
                    defaultMenuIsOpen={false}
                />
            </>
        );
    }
);

export default ByzzerPPGSelect;
