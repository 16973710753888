import { SimulatorRunConfig } from "../../../types/SimulatorTypes";

export const emptyPreset: SimulatorRunConfig = {
    categories: [],
    brands: [], // ['RED BULL (RED BULL NORTH AMERICA INC)']
    ppgId: null, // '-1']
    ppgGroupNames: [], // 'RED BULL (RED BULL NORTH AMERICA INC) | 20 FLUID OUNCE | 1 Pack | SHELF STABLE ENERGY BEVERAGES',
    timePeriod: { type: 'relative' }
}

const mikesDefault: SimulatorRunConfig = {
    "categories": [
        "HARD SELTZER AND FLAVORED MALT BEVERAGE"
    ],
    "brands": [
        "MIKE'S (MIKE'S HARD LEMONADE CO)"
    ],
    "ppgId": -1,
    "ppgGroupNames": [
        "MIKE'S (MIKE'S HARD LEMONADE CO) | 11.2 FLUID OUNCE | 12 Pack | HARD SELTZER AND FLAVORED MALT BEVERAGE"
    ],
    "timePeriod": {
        "type": "relative",
        "period": "Latest 13 Weeks"
    },
    "markets": [
        {
            "name": "Total FMCG Retailers + Liquor + Convenience",
            "path": "\\total_fmcg_retailers_liquor_convenience",
            "key": "115102",
            "masterCompany": "Nielsen Liquor Channel",
            "marketGroup": "Market",
            "isRemaining": false
        }
    ]
}

const emmaDefault: SimulatorRunConfig = {
    "categories": [
        "COFFEE"
    ],
    "brands": [],
    "ppgId": 690,
    "ppgGroupNames": [
        "CHARLESTON COFFEE ROASTERS (CHARLESTON COFFEE ROASTERS, INC.) | WHOLE BEAN | UNFLAVORED | 12 OUNCE | 1 Pack"
    ],
    "timePeriod": {
        "type": "relative",
        "period": "Latest 52 Weeks"
    },
    "markets": [
        {
            "name": "Total Grocery Stores",
            "path": "\\total_grocery_stores",
            "key": "54426",
            "masterCompany": "Nielsen Grocery Channel",
            "marketGroup": "Market",
            "isRemaining": false
        }
    ]
}

export const PRESET_DEFAULT_FILTER_VALUES = ((preset): SimulatorRunConfig | undefined => {
    switch (preset) {
        case 'true':
        case 'mikesDefault':
            return JSON.parse(JSON.stringify(mikesDefault));
        case 'empty':
        case 'blank':
        case 'emptyDefault':
        case 'emptyPreset':
            return JSON.parse(JSON.stringify(emptyPreset));
        case 'emma':
        case 'emmaCoffee':
            return JSON.parse(JSON.stringify(emmaDefault));
        case 'false':
        case 'undefined':
        default:
            return undefined;
    }
})(window.localStorage['use-simulator-preset']);