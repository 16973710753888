import React, { useState } from "react";
import classnames from 'classnames';
import './LegalTermsAndConditions.scss';
import { ByzzerButton, ByzzerCheckableChangeEvent, ByzzerCheckbox } from "@byzzer/ui-components";
import {useTenantApi} from "@/hooks/useTenantApi";
import { Spinner } from "@/components/Spinner/Spinner";
import { useUser } from "@/contexts/UserContext";
import { ByzzerLogo } from "@/components/ByzzerLogo";
import { ByzzerLink } from "@/components/form";
import { openTermsAndConditions } from "@/components/modals/TermsAndConditions";

export function LegalTermsAndConditions() {
    const { saveTcsSelection } = useTenantApi();

    const baseClassName = 'byz-legal-terms-and-conditions';

    const [agreeToTcsChecked, setAgreeToTcsChecked] = useState<boolean>(false);
    const [busy, setBusy] = useState<boolean>(false);
    const { refreshUser } = useUser();

    function handleCheck(e: ByzzerCheckableChangeEvent<string>) {
        setAgreeToTcsChecked(e.checked);
    }

    async function handleAgreeClick() {
        setBusy(true);

        try {
            await saveTcsSelection(true);
            await refreshUser({
                tcsAccepted: true
            });
        } catch (err) {
            
        }
    }

    const terms = [
        <>
            Sharing or providing access to NIQ data with your external third-party vendors requires written prior
            approval from NIQ with limited exceptions for:
            <ul>
                <li>Staff augmentation personnel (e.g., temporary employee backfill working solely for you). </li>
                <li>
                    IT services and support (e.g., building a data lake for you) as defined in our licensing documents
                    and where the information does not leave your control.{' '}
                </li>
            </ul>
        </>,
        <>Sharing retailer-identifiable data with another retailer is prohibited. </>,
        <>Sharing and storing NIQ data outside of your databases and platforms requires written prior approval.</>,
        <>
            Sharing or loading any NIQ data into a public AI tool/platform (ChatGPT, Bard, etc.) is prohibited.
            Inclusion into a private AI tool or platform requires written prior approval.
        </>,
        <>
            The use of bots or any other automated means of accessing NIQ systems or extracting NIQ data is prohibited.
        </>,
    ];

    return (
        <div className={classnames(baseClassName)}>
            <ByzzerLogo />

            <main className={classnames(`${baseClassName}__content`)}>
                <div className={classnames(`${baseClassName}__title`)}>
                    <p>Important Reminder of Critical Usage Requirements</p>
                </div>

                <section className={classnames(`${baseClassName}__tcs`)}>
                    <ul>
                        {terms.map((term, i) => (
                            <li key={i}>{term}</li>
                        ))}
                    </ul>
                </section>
        
                <section className={classnames(`${baseClassName}__more-info`)}>
                    <p>For more information, please review the <ByzzerLink label={'NielsenIQ Portal Services Terms & Conditions'} onClick={openTermsAndConditions}/>.</p>
                </section>

                {busy && <Spinner />}

                {!busy && (<>
                    <section className={classnames(`${baseClassName}__actions`)}>
                        <div className={classnames(`${baseClassName}__input`)}>
                            <ByzzerCheckbox checked={agreeToTcsChecked} onChange={handleCheck}/>
                            <p className={classnames(`${baseClassName}__fine-print`)}>
                                By clicking here, you agree to the Important Reminder of Critical Usage Requirements and the NielsenIQ Portal Services Terms and Conditions set forth above.
                            </p>
                        </div>
                        <div className={classnames(`${baseClassName}__button-wrapper`)}>                        
                            <ByzzerButton label={'Accept'} disabled={!agreeToTcsChecked} onClick={handleAgreeClick} />
                        </div>
                    </section>
                </>)}
            </main>
        </div>
    );

};

export default LegalTermsAndConditions;

LegalTermsAndConditions.displayName = 'LegalTermsAndConditions';