import {RowColConfig} from "./types";
import {DodAxisDef} from "@/types/DodRun";
import {cloneDeep} from "lodash";

export function toAxisDef(colConfig: RowColConfig): DodAxisDef {

    const def: any = cloneDeep(colConfig)
    delete def.axis;
    def.order = def.values;
    delete def.values;
    def.sortType = def.sortType === 'default' ? 'manual' : def.sortType;

    return def;
}
