import React, { ReactNode, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useApp } from '@/contexts/UserContext';
import { OptionalRequired } from '../OptionalRequired';
import { CharacteristicDimension } from '@/types/ReportRun';
import {useTenantApi} from '@/hooks/useTenantApi';
import { ByzzerChangeEventHandler, ByzzerSelect, ByzzerTipIcon } from '@byzzer/ui-components';
import { charsToBeFilteredOut, specificCharsExclusionReportSkus } from '@/config/globalVars';
import {additionalCharacteristicsDimensions,additionalCharDimensionSkus,productLevelsHigherThanCategory} from '@/config/reportSelectorVars.config';
import { SelectorLabelInfo } from '../SelectorLabelInfo/SelectorLabelInfo';

const baseClassName = 'characteristics-dimension-select';

export type CharacteristicsDimension = CharacteristicDimension[]; 

export type CharacteristicsDimensionSelectProps = {
    sku?: string;
    name?: string;
    label?: ReactNode;
    allowClear?: boolean;
    productLevel?: string;
    categories?: string[];
    maxSelections?: number;
    value?: CharacteristicDimension[];
    includeCustomCharacteristics?: boolean;
    requireCharacteristicDimensions?:boolean;
    onChange?: ByzzerChangeEventHandler<CharacteristicDimension[]>;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>;

export function StoryCharacteristicsDimensionSelect({
    name,
    value,
    label,
    onChange,
    sku = '',
    className,
    allowClear,
    categories,
    maxSelections,
    productLevel = 'Category',
    requireCharacteristicDimensions,
    includeCustomCharacteristics = true,
    ...props
}: CharacteristicsDimensionSelectProps) {
    const [selectedCharacteristics, setSelectedCharacteristics] = useState<any>([]);
    const [characteristicsOption, setCharacteristicsOption] = useState<CharacteristicDimension[]>([]);
    const basicCharacteristics = useRef<CharacteristicDimension[]>([]);
    const { customCharacteristics: customCharacteristicsContext } = useApp();
    const { getCharacteristicsForCategories } = useTenantApi();
    useEffect(() => {
        (async () => {
            updateCharacteristicsContext();
        })();
    }, [includeCustomCharacteristics]);

    useEffect(() => {
        (async () => {
            await fetchCharacteristicsForCategories(categories ?? []);
            updateCharacteristicsContext();
        })();
    }, [categories]);

    useEffect(() => {
        setSelectedCharacteristics(value ?? [])
    }, [value])

    const fetchCharacteristicsForCategories = async (categories) => {
        if (!categories?.length) {
            setSelectedCharacteristics([]);
            basicCharacteristics.current = [];
            return;
        }
    const characteristicsForCategories = await getCharacteristicsForCategories(categories);
    const basicCharacteristicsValue: CharacteristicDimension[] = characteristicsForCategories.map(
            (characteristic) => ({
                characteristicsCode: characteristic.code,
                characteristic: characteristic.displayName,
                isCustom: false,
            })
        );
        basicCharacteristics.current = basicCharacteristicsValue;
    };

    function updateCharacteristicsContext() {
        let matchingCustomCharacteristics: CharacteristicDimension[] = [];

        if (includeCustomCharacteristics) {
            matchingCustomCharacteristics = customCharacteristicsContext
                .filter((item) => categories?.map((category) => category)?.some((i) => item?.categories?.includes(i)))
                .map((customCharacteristic) => ({
                    characteristicsCode: String(customCharacteristic.id),
                    characteristic: `${customCharacteristic.label} (Custom)`,
                    isCustom: true,
                }));
        }
        let characteristicsMap: CharacteristicDimension[] = [
            ...matchingCustomCharacteristics,
            ...basicCharacteristics.current,
        ];

        if (specificCharsExclusionReportSkus.includes(sku)) {
           
            if (charsToBeFilteredOut.length > 0) {
                characteristicsMap = characteristicsMap?.filter(
                    (c) => !charsToBeFilteredOut.includes(c.characteristic)
                );
            }
        }
        if (
            additionalCharDimensionSkus?.includes(sku) &&
            (productLevelsHigherThanCategory?.includes(productLevel) || (categories && categories?.length > 1))
        ) {
            characteristicsMap = [...characteristicsMap, ...additionalCharacteristicsDimensions];
        }
        setCharacteristicsOption(characteristicsMap);
    }

    function handleChange(e: ByzzerChangeEvent<string | string[] | null | undefined>) {
        const value = characteristicsOption.filter((characteristic) => {
            return Array.isArray(e.value)
                ? e.value?.includes(characteristic.characteristicsCode)
                : characteristic.characteristicsCode === e.value;
        });

        if (onChange) {
            onChange({
                name,
                value,
            });
        }
        setSelectedCharacteristics([...value] ?? []);
    }

    return (
        <div className={classnames(baseClassName, className)} {...props}>
                     
            <ByzzerSelect
                name={name}
                onChange={handleChange}
                allowClear={allowClear}
                placeholder={'Select from the list'}
                allowMultiple={Number(maxSelections) > 0} 
                options={characteristicsOption?.map((c) => ({
                    display: c.characteristic,
                    value: c.characteristicsCode,
                }))}
                maxSelections={Number(maxSelections) > 0 ? maxSelections : undefined}
                value={selectedCharacteristics.map((characteristic) => characteristic.characteristicsCode)}
                label={                    
                    <SelectorLabelInfo
                        sku={sku}
                        selectorCode = {'characteristicDimensions'}  
                        required={requireCharacteristicDimensions}                        
                        max={maxSelections}
                    />
                }
            />
        </div>
    );
}

export default StoryCharacteristicsDimensionSelect;
