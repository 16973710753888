import '../dashboard/DashboardNav.scss';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { FreeUserDashboardNavItem } from '@/components/FreeUserDashboard';
import { useUserSettingsState } from '@/utils/hooks';
import { useUser } from '@/contexts/UserContext';
import { TrackClick } from '@/analytics';
const baseClassName = 'dashboard-nav';

type MenuItem = {
    content: string;
    type: string;
    url: string;
    otherUrls?: string[];
    title: string;
    disabled?: boolean;
};

export function FreeUserDashboardNav({ disabled = false }) {
    const [collapsed, setCollapsed] = useUserSettingsState('navMnuCollapsed', true);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const {user, company} = useUser();

    const allMenuItems = [
        {
            content: 'Home',
            type: 'home',
            url: '/dashboard/home',
            otherUrls: [],
            title: 'Home',
            disabled:false

        },
        {
            content: 'Alerts',
            type: 'alerts',
            url: '/dashboard/my_alerts',
            otherUrls: ['/dashboard/configure_alert'],
            title: 'My Alerts',
        },
        {
            content: 'Reports',
            type: 'reports',
            url: '/dashboard/my_reports',
            otherUrls: ['/dashboard/configure_report'],
            title: 'My Reports',
        },
        {
            content: 'Brand Score',
            type: 'scorecard',
            url: '/dashboard/my_scorecard',
            otherUrls: [],
            title: 'Brand Score',
        },
        {
            content: 'Data On Demand',
            type: 'extracts',
            url: '/dashboard/extracts/history',
            otherUrls: ['/dashboard/extract_editor'],
            title: 'Data On Demand',
        },
        {
            content: 'Stories',
            type: 'stories',
            url: '/dashboard/stories',
            otherUrls: ['/dashboard/story_builder/'],
            title: 'My Stories',
        },
        {
            content: 'Simulate',
            type: 'simulator',
            url: '/dashboard/my_simulators',
            otherUrls: ['/dashboard/configure_simulator'],
            title: 'Simulate',
        },
    ];
    useEffect(() => {
        if (collapsed) {
            setMenuItems(allMenuItems.map((item) => item).flat());
        } else {
            setMenuItems(allMenuItems);
        }
    }, [collapsed]);

    function toggleCollapsed() {
        setCollapsed(!collapsed);
    }

    const disabledItemsOnInactivedSubscription = ['Brand Score']; // per BYZ-8812


    return (
        <div
            className={classnames(baseClassName, {
                [`${baseClassName}--collapsed`]: collapsed,
            })}
        >
            <main className={`${baseClassName}__content`}>
                {menuItems.map((menuItem) => (
                    <FreeUserDashboardNavItem
                        key={menuItem.content}
                        collapsed={collapsed}
                        {...menuItem}
                    />
                ))}
            </main>
            <TrackClick
                name={'dashboard-nav-collapse-triggered'}
                data={{
                    currentState: collapsed,
                    userEmail: user?.email,
                    companyName: company?.displayName
                }}
            >
                <div className={`${baseClassName}__collapse-trigger`} onClick={toggleCollapsed} />
            </TrackClick>

        </div>
    );
}

export default FreeUserDashboardNav;
