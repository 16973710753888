import React from 'react';
import './ByzzerStepper.scss';
export const ByzzerStepper = ({children}) => {
    return (
        <div className="byzzer-stepper-horizontal byzzer-layout">
            { children }
        </div>
    );
};

export default ByzzerStepper;
