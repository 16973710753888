import { storySkus, selectorLabels, marketTypeValues } from '@/config/globalVars';
import { ByzzerDate } from '@byzzer/ui-components';
import {formatInTimeZone} from "date-fns-tz";
import { STORY_TIME_ZONE } from '@/constants/stories.constants';
import { ByzzerChip } from '@/components/ByzzerChip';

export const STORY_CONSTANTS = {
    colorGreen1: '#2d6df6',
    colorBlack: 'black',
    colorWhite: 'white',
};

export const toTitleCase = (str) => {
    if (str === null || str === undefined) {
        return '';
    }
    return str.replace(/\w\S*/g, function (txt) {
        if (txt.indexOf('/') > -1) {
            let first = txt.split('/')[0];
            let second = txt.split('/')[1];
            return (
                first.charAt(0).toUpperCase() +
                first.substr(1).toLowerCase() +
                '/' +
                second.charAt(0).toUpperCase() +
                second.substr(1).toLowerCase()
            );
        } else {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    });
};

const getFileAliasString = (list) => {
    return list.length > 1 ? toTitleCase(list[0]) + ', +' + (list.length - 1) + ' more' : toTitleCase(list[0]);
};

// File Alias for story displayed on Step Review&Run Step of Story_Builder
export const getFileAlias = (dataSpecification, storyName) => { 
    let alias;
    let { sku } = dataSpecification;
    let { runConfig } = dataSpecification;
    let marketName = runConfig.markets?.map((market) => market.name).join(', ');
    let productSel = runConfig.focusBrands.join(', ');
    let subCategories = runConfig.subcategories.join(', ');
    let category = runConfig.categories.join(', ');
    let time =
        runConfig.timePeriod?.type === 'relative'
            ? runConfig.timePeriod.period
            : runConfig.timePeriod.period.weeks +
              ' weeks ending ' +
              formatInTimeZone(new Date(runConfig?.timePeriod?.period.endDate), STORY_TIME_ZONE, 'yyyy-MM-dd');
    const productAlias = dataSpecification.runConfig.productAlias;
    switch (sku.toString()) {
        case storySkus.priceAndPromotion:
            alias =
                toTitleCase(productSel) +
                ' ' +
                toTitleCase(category) +
                ' ' +
                toTitleCase(storyName) +
                '  for ' +
                marketName +
                ' for ' +
                time;
            break;
        case storySkus.shopperSnapshot:
            alias = productAlias + ' ' + toTitleCase(storyName) + ' for ' + marketName + ' for ' + time;
            break;
        default:
            alias = toTitleCase(category) + ' ' + toTitleCase(storyName) + ' for ' + marketName + ' for ' + time;
            break;
    }
    return alias;
};

export const storyAppendMore = (listValue, aliasValue, storySku, appendMore = true, listValueType = '') => {
    let responseValue = '';
    // if alias is provided
    if (aliasValue !== undefined && aliasValue !== '') {
        return aliasValue;
    }

    // if no alias is provided
    if (listValue !== undefined || listValue?.length > 0) {
        if (Array.isArray(listValue) && listValueType !== 'dynamic_story') {
            // if listValue (brand, category, etc) is array
            if (listValue?.length > 1 && appendMore) {
                // for cat mnmgt story
                if (storySku === storySkus.categoryManagement) {
                    if (listValueType === 'market') {
                        let regularMarket = listValue[0]?.toUpperCase().includes('COMPETITIVE')
                            ? [listValue[1]]
                            : [listValue[0]];
                        responseValue += regularMarket;
                    }
                    if (listValueType === 'category' || listValueType === 'dynamic_story') {
                        responseValue += getFileAliasString(listValue);
                    }
                } else {
                    // for other stories
                    responseValue += getFileAliasString(listValue);
                }
            } else {
                responseValue = toTitleCase(
                    listValue
                        .map((value) => {
                            if (typeof value === 'string') {
                                return value;
                            } else {
                                return value.name;
                            }
                        })
                        .join(', ')
                );
            }
        } else if (
            !Array.isArray(listValue) &&
            listValueType === 'dynamic_story' &&
            Object.keys(listValue)?.length > 0 &&
            appendMore
        ) {
            // if listValue is Not an array
            let list = [];
            if (storySku === storySkus.brandReview) {
                list =
                    listValue?.brandManufacturerLevel === 'Focus Brand'
                        ? listValue?.focusBrand || []
                        : listValue?.manufacturer || [];
            } else if (storySku === storySkus.priceAndPromotion) {
                list = listValue?.focusBrand || [];
            } else {
                const categories = listValue?.competitiveProductSet?.categories || [];
                const subCategories = listValue?.competitiveProductSet?.subcategories || [];
                const brandName = listValue?.brand || [];
                if (categories.length > 0 && subCategories.length === 0 && brandName.length === 0) {
                    list = categories;
                } else if (categories.length > 0 && subCategories.length > 0 && brandName.length === 0) {
                    list = subCategories;
                } else if (categories.length > 0 && subCategories.length > 0 && brandName.length > 0) {
                    list = brandName;
                } else {
                    list = brandName;
                }
            }
            responseValue += getFileAliasString(list);
        } else {
            responseValue += toTitleCase(String(listValue));
        }
    }
    return responseValue;
};

export const modifyObjectiveList = (objectives, userAccessLevel, objectiveKeys) => {
    let accessLevel = getAccessLevel(userAccessLevel);
    let objectiveList = [];
    if (objectives !== undefined) {
        Object.entries(objectives).forEach(([key, value]) => {
            if (value.subscriptionAccessLevel <= accessLevel) {
                value.subscribe = 'available';
            } else if (value.subscriptionAccessLevel === 5) {
                value.subscribe = 'coming_soon';
            } else {
                value.subscribe = 'not_available';
            }
            objectiveList.push(value);
        });
    }

    if (objectiveList?.length !== 0 && objectiveKeys !== undefined) {
        objectiveList.forEach((objective) => {
            if (!objectiveKeys.includes(objective.slideName) && objective.subscribe === 'available') {
                objective.retailerReview = 'optional';
            }
        });
    }

    const list = [];
    let count = 0;

    if (objectiveList.length !== 0) {
        let serialNo = '';
        let isChecked = 'unChecked';
        let slideType = '';
        let displayMsg = '';
        objectiveList.sort((a, b) => a.displayOrder - b.displayOrder);

        objectiveList.forEach((objective) => {
            if (
                (objective.retailerReview === 'include' && objective.subscribe === 'available') ||
                (objectiveKeys !== undefined &&
                    objectiveKeys.includes(objective.slideName) &&
                    objective.subscribe === 'available')
            ) {
                count += 1;
                serialNo = count !== 0 ? count.toString() : '';
                isChecked = 'checked';
            } else {
                serialNo = '';
                isChecked = 'unChecked';
            }

            if (objective.subscribe === 'coming_soon') {
                slideType = 'coming soon';
                displayMsg = 'Coming Soon';
            } else if (objective.subscribe === 'not_available') {
                slideType = 'not subscribed';
                displayMsg = 'Upgrade your subscription for access to this objective';
            } else if (objective.subscribe === 'available') {
                slideType = 'available';
                displayMsg = '';
            }

            list.push({
                displayOrder: objective.displayOrder,
                retailerReview: objective.retailerReview,
                productSku: objective.productSku,
                tag: objective.tag,
                objectiveName: objective.objectiveName,
                objectiveInfo: objective.objectiveInfo,
                serialNo: serialNo,
                isChecked: isChecked,
                slideName: objective.slideName,
                slideOrder: objective.slideOrder,
                slideType: slideType,
                displayMsg: displayMsg,
                subscribe: objective.subscribe,
                dividerSlide: objective?.dividerSlide || [],
            });
        });
    }

    return { list: list, count: count };
};

export const getAccessLevel = (accessLevel) => {
    if (accessLevel) {
        switch (accessLevel) {
            case 'tracked_manufacturer':
                return '1';
            case 'retailer':
                return '2';
            case 'all_else':
                return '3';
            case 'unknown':
                return '4';
            case 'banks_finance':
                return '5';
            default:
                break;
        }
    }
    return null;
};

export const getStoryPayload = (reqBody, data) => {
    let newReqBody = reqBody;
    let { productSku, storyCustomName, storyType, storyParams } = reqBody;
    let {
        brand,
        category,
        subcategory,
        characteristicFilters,
        objectives,
        categoryAlias,
        marketAlias,
        market,
        focusMarketKeys,
        marketSelectionTypes,
        marketPerformanceSelectionTypes,
        timePeriodSelections,
        retentionData,
    } = storyParams;

    let storySpecificObj = {};
    let { dataSpecificationValues } = data;
    let { shopperSegmentSelections } = dataSpecificationValues;
    const productSel = dataSpecificationValues?.focusProductSelections;
    const marketPerformanceSel = dataSpecificationValues?.marketPerformanceSelections;
    const marketsToWatchSelection = dataSpecificationValues?.marketsToWatchSelection;
    const marketSelectionsSummary =
        dataSpecificationValues?.marketSelectionsSummary?.byMarketType ??
        dataSpecificationValues?.marketSelectionsSummary;

    switch (productSku) {
        case storySkus.categoryReview:
            break;
        case storySkus.categoryManagement:
            let focusMrktKeys = [];
            let comparisonMrktKeys = [];
            dataSpecificationValues?.focusMarketsSelectedData?.forEach((market) => {
                if (market?.marketType === marketTypeValues.REGULAR) {
                    focusMrktKeys.push(market?.mrkt_key);
                    newReqBody.storyParams.market = [market.name];
                } else if (market?.marketType === marketTypeValues.CHANNEL) {
                    comparisonMrktKeys.push(market?.rm_channel_mrkt_key);
                    newReqBody.storyParams.comparisonMarket = [market.rm_channel_mrkt_disp_name];
                    newReqBody.storyParams.comparisonMarketType = 'remaining';
                } else if (market?.marketType === marketTypeValues.RETAILER) {
                    comparisonMrktKeys.push(market?.rm_xaoc_mrkt_key);
                    newReqBody.storyParams.comparisonMarket = [market.rm_xaoc_mrkt_disp_name];
                    newReqBody.storyParams.comparisonMarketType = 'remaining';
                } else if (
                    market?.marketType === marketTypeValues.COMPARATIVE ||
                    market?.marketType === marketTypeValues.COMPARITIVE
                ) {
                    comparisonMrktKeys.push(market?.comparative_mrkt_key);
                    newReqBody.storyParams.comparisonMarket = [market.comparative_mkt_disp_name];
                    newReqBody.storyParams.comparisonMarketType = 'comparative';
                }
            });
            newReqBody.storyParams.competitiveMarket = newReqBody.storyParams.comparisonMarket;
            newReqBody.storyParams.focusMarketKeys = focusMrktKeys;
            newReqBody.storyParams.comparisonMarketKeys = comparisonMrktKeys;
            if (!newReqBody.storyParams?.brand) {
                newReqBody.storyParams.brand = [];
            }
            newReqBody = {
                productSku,
                storyCustomName,
                storyType,
                storyParams: {
                    brand,
                    category,
                    subcategory,
                    characteristicFilters,
                    objectives,
                    categoryAlias,
                    marketAlias,
                    market,
                    focusMarketKeys,
                    marketSelectionTypes,
                    timePeriodSelections,
                    ...newReqBody.storyParams,
                    retentionData,
                },
            };
            break;
        case storySkus.shopperSnapshot:
            newReqBody = {
                productSku,
                storyCustomName,
                storyType,
                storyParams: {
                    brand,
                    category,
                    subcategory,
                    characteristicFilters,
                    objectives,
                    categoryAlias,
                    marketAlias,
                    market,
                    focusMarketKeys,
                    marketSelectionTypes,
                    timePeriodSelections,
                    retentionData: {
                        ...storyParams?.retentionData,
                    },
                },
            };
            break;
        case storySkus.brandReview:
            // New keys specific to Brand review story
            if (productSel?.brandManufacturerLevel === 'Manufacturer') {
                storySpecificObj.manufacturer = productSel?.manufacturer || [];
            }
            storySpecificObj.brandManufacturerLevel = productSel?.brandManufacturerLevel || '';
            storySpecificObj.productAlias = productSel?.brandManufacturerAlias || '';
            storySpecificObj.marketForMarketPerformance = marketPerformanceSel?.focusMarket?.byMarketName?.markets;
            if (marketPerformanceSel?.marketKeys?.length) {
                storySpecificObj.marketKeysForMarketPerformance = marketPerformanceSel?.marketKeys.filter(
                    (e) => e !== null
                );
            } else {
                storySpecificObj.marketKeysForMarketPerformance = [];
            }
            storySpecificObj.marketsToWatchSelection = marketsToWatchSelection || {};

            if (typeof marketSelectionsSummary === 'string') {
                storySpecificObj.marketSelectionsSummary = marketSelectionsSummary || '';
            } else if (Object.keys(marketSelectionsSummary)?.length > 0) {
                storySpecificObj.marketSelectionsSummary =
                    marketSelectionsSummary?.comparisonTypeChannel && marketSelectionsSummary?.comparisonTypeSelected
                        ? `${marketSelectionsSummary?.comparisonTypeChannel} ${marketSelectionsSummary?.comparisonTypeSelected}`
                        : ``;
            }

            newReqBody = {
                productSku,
                storyCustomName,
                storyType,
                storyParams: {
                    brand,
                    category,
                    subcategory,
                    characteristicFilters,
                    objectives,
                    categoryAlias,
                    marketAlias,
                    market,
                    focusMarketKeys,
                    marketSelectionTypes,
                    marketPerformanceSelectionTypes,
                    timePeriodSelections,
                    ...storySpecificObj,
                    retentionData: {
                        marketOption: dataSpecificationValues.marketOption,
                        ...storyParams?.retentionData,
                    },
                },
            };
            break;
        case storySkus.priceAndPromotion:
            storySpecificObj.brandAlias = productSel?.brandAlias || '';
            storySpecificObj.ppgData = productSel?.ppgData || {};

            // removiving slide selection as objective 6 as it contains only a divider slide
            if (storyParams.objectives.objective_6) {
                storyParams.objectives.objective_6 = [];
            }
            newReqBody = {
                productSku,
                storyCustomName,
                storyType,
                storyParams: {
                    brand,
                    category,
                    subcategory,
                    characteristicFilters,
                    objectives,
                    categoryAlias,
                    marketAlias,
                    market,
                    focusMarketKeys,
                    marketSelectionTypes,
                    timePeriodSelections,
                    ...storySpecificObj,
                    retentionData: {
                        ...storyParams?.retentionData,
                    },
                },
            };
            break;
        case storySkus.categoryTrendAnalysis:
            storySpecificObj.characteristicsAlias = productSel?.characteristicsAlias || '';
            newReqBody = {
                productSku,
                storyCustomName,
                storyType,
                storyParams: {
                    category,
                    subcategory,
                    characteristicFilters,
                    objectives,
                    categoryAlias,
                    shopperSegmentSelections,
                    marketAlias,
                    market,
                    focusMarketKeys,
                    marketSelectionTypes,
                    timePeriodSelections,
                    ...storySpecificObj,
                    retentionData: {
                        ...storyParams?.retentionData,
                    },
                },
            };
            break;
        default:
            break;
    }

    return newReqBody;
};

export const filterPanelsByObjectives = (sku, storySelector, selectorLabelName = '', action = false) => {
    let resp = storySelector;
    if (sku === storySkus.categoryReview) {
        if (action) {
            // add panel
            resp = storySelector.filter((elem) => elem.selectorName === selectorLabelName);
        } else {
            // remove panel
            resp = storySelector.filter((elem) => elem.selectorName !== selectorLabelName);
        }
    }
    if (sku === storySkus.categoryTrendAnalysis) {
        resp = storySelector.filter((elem) => elem.selectorType !== selectorLabelName);
    }

    if (sku === storySkus.brandReview) {
        if (action) {
            // add panel
            resp = storySelector.filter(
                (elem) =>
                    elem.subSelectors?.selectorFunction === 'marketPerformance' ||
                    elem.subSelectors?.selectorFunction === 'multiMarketsStory'
            );
        } else {
            // remove panel
            resp = storySelector.filter(
                (elem) =>
                    !(
                        elem.subSelectors?.selectorFunction === 'marketPerformance' ||
                        elem.subSelectors?.selectorFunction === 'multiMarketsStory'
                    )
            );

            resp = resp.filter(
                (elem) =>
                    !(
                        elem.selectorName === selectorLabels.marketPerformanceObjective ||
                        elem.selectorName === selectorLabels.focusMarkets
                    )
            );
        }
    }

    return resp;
};

export const clearPanelsData = (data, selectorLabelName) => {
    if (selectorLabelName === selectorLabels.characteristic) {
        data.refreshCharacteristicFeature = false;
        data.characteristicSelections = '';
        data.characteristicOptions = [];
    } else if (selectorLabelName === selectorLabels.comparisonMarket) {
        data.refreshComparisonMarkets = false;
        data.comparisonMarketSelections = {};
    } else if (selectorLabelName === selectorLabels.panelTimePeriod) {
        data.panelTimePeriodSelections = {};
    } else if (selectorLabelName === 'marketPerformance' || selectorLabelName === 'multiMarketsStory') {
        data.focusMarketsPerformanceSelectedData = [];
        data.marketPerformanceSelections = {};
        data.marketsToWatchSelection = {};
        data.marketSelectionsSummary = {};
    }
};

//#1.36 - Optimization: create one object instead of multiple variables
export const initializeStoredValues = () => {
    localStorage.removeItem('objectivesList');
    localStorage.removeItem('selectedObjectives');
    localStorage.removeItem('storySku');
    localStorage.removeItem('objectiveChanged');
    localStorage.removeItem('dataSpecification');
    localStorage.removeItem('SelectorStateData');
    localStorage.removeItem('activeStep');
    localStorage.removeItem('fromBack');
    localStorage.removeItem('storySelectors');
    localStorage.removeItem('editStory');
    localStorage.removeItem('viewStory');
    localStorage.removeItem('modifySelections');
    localStorage.removeItem('storyName');
    localStorage.removeItem('storiesTraining');
    localStorage.removeItem('storyData');
    localStorage.removeItem('action');
};

export const getStorySliderData = (objectiveList) => {
    let previewImgList = [];
    let counter = 0;

    objectiveList.forEach((objective, index) => {
        if (objective?.dividerSlide && objective?.dividerSlide?.length > 0) {
            previewImgList.push({
                objectiveName: objective.objectiveName,
                objectiveHeader: objective?.dividerSlide[0]?.objectiveHeader,
                objectiveAbout: objective?.dividerSlide[0]?.objectiveAbout,
                previewImg: objective?.dividerSlide[0]?.previewImg,
                objectiveIndex: index,
                previewImageIndex: counter,
            });
            counter += 1;
        }
        objective.objectiveInfo.forEach((objectiveInfo) => {
            previewImgList.push({
                objectiveName: objective.objectiveName,
                objectiveHeader: objectiveInfo.objectiveHeader,
                objectiveAbout: objectiveInfo.objectiveAbout,
                previewImg: objectiveInfo.previewImg,
                objectiveIndex: index,
                previewImageIndex: counter,
            });
            counter += 1;
        });
    });
    return previewImgList;
};
export function getSelectorDependents(selectorName) {
    switch (selectorName) {
        case 'focusBrands':
            return { alias: 'brandAlias' };
        case 'categories':
            return {
                alias: 'categoryAlias',
                dependent: [
                    { name: 'characteristicsDimension', value: [] },
                    { name: 'characteristics', value: [] },
                ],
            };
        case 'manufacturers':
            return { alias: 'manufacturerAlias' };
        case 'shopper':
            return { alias: 'categoryAlias', dependent: [{ name: 'characteristics', value: [] }] };
    }
}

const BASE_CLASS = 'run-story';
// TODO: Have to move this storySelector container to reviewandrun tsx file (sprint: 1.63)
export const STORY_SUMMARY_SECTIONS = [
    {
        key: 'focusBrands',
        dom: (focusBrands) => {
            return (
                <>
                    <h3>Focus Brands</h3>
                    <div className={'story-summary-chips'}>
                        {focusBrands.map((focusBrand) => {
                            return <ByzzerChip>{focusBrand}</ByzzerChip>;
                        })}
                    </div>
                </>
            );
        },
    },
    {
        key: 'manufacturers',
        dom: (manufacturers) => {
            return (
                <>
                    <h3>Manufacturers</h3>
                    <div className={'story-summary-chips'}>
                        {manufacturers.map((focusBrand) => {
                            return <ByzzerChip>{focusBrand}</ByzzerChip>;
                        })}
                    </div>
                </>
            );
        },
    },
    {
        key: 'brands',
        dom: (brands) => {
            return (
                <>
                    <h3>Brands</h3>
                    <div className={'story-summary-chips'}>
                        {brands.map((brand) => {
                            return <ByzzerChip>{brand}</ByzzerChip>;
                        })}
                    </div>
                </>
            );
        },
    },
    {
        key: 'categories',
        dom: (categories) => {
            return (
                <>
                    <h3>Categories</h3>
                    <div className={'story-summary-chips'}>
                        {categories.map((category) => {
                            return <ByzzerChip>{category}</ByzzerChip>;
                        })}
                    </div>
                </>
            );
        },
    },
    {
        key: 'subcategories',
        dom: (subcategories) => {
            return (
                <>
                    <h3>Sub Categories</h3>
                    <div className={'story-summary-chips'}>
                        {subcategories.map((subcategory) => {
                            return <ByzzerChip>{subcategory}</ByzzerChip>;
                        })}
                    </div>
                </>
            );
        },
    },
    {
        key: 'ppgData',
        dom: (ppgData) => {
            return (
                <>
                    <h3>PPG Definition</h3>
                    <div className={'story-summary-chips'}>
                        <ByzzerChip>{ppgData?.display}</ByzzerChip>
                    </div>
                </>
            );
        },
    },
    {
        key: 'characteristics',
        dom: (characteristics) => {
            return (
                <>
                    <h3>Characteristics</h3>
                    <div className={'story-summary-chips'}>
                        {characteristics.map((characteristic) => {
                            return (
                                <ByzzerChip>
                                    {characteristic.characteristicDisplayValue +
                                        ' ' +
                                        characteristic.condition +
                                        ' ' +
                                        characteristic.value.join(', ')}
                                </ByzzerChip>
                            );
                        })}
                    </div>
                </>
            );
        },
    },
    {
        key: 'demographics',
        dom: (demographics) => {
            return (
                <>
                    <h3>Demographics</h3>
                    <div className={'story-summary-chips'}>
                        {demographics.map((demographic) => {
                            return (
                                <ByzzerChip>
                                    {demographic.demographic +
                                        ' ' +
                                        demographic.condition +
                                        ' ' +
                                        demographic.value.join(', ')}
                                </ByzzerChip>
                            );
                        })}
                    </div>
                </>
            );
        },
    },
    {
        key: 'markets',
        dom: (markets) => {
            return (
                <>
                    <h3>Markets</h3>
                    <div className={'story-summary-chips'}>
                        {markets.map((market) => {
                            return (
                                <ByzzerChip>
                                    {market?.remainingMarketRunConfig?.name
                                        ? `${market?.name} vs ${market?.remainingMarketRunConfig?.name}`
                                        : market?.name}
                                </ByzzerChip>
                            );
                        })}
                    </div>
                </>
            );
        },
    },
    {
        key: 'characteristicsDimension',
        dom: (characteristicsDimension) => {
            return (
                <>
                    <h3>Charaterstic Dimensions</h3>
                    <div className={'story-summary-chips'}>
                        {characteristicsDimension.map((characteristic) => {
                            return <ByzzerChip>{characteristic.characteristic}</ByzzerChip>;
                        })}
                    </div>
                </>
            );
        },
    },
    {
        key: 'demographicDimensions',
        dom: (demographicDimensions) => {
            return (
                <>
                    <h3>Demographic Dimensions</h3>
                    <div className={'story-summary-chips'}>
                        {demographicDimensions.map((demographicDimension) => {
                            return <ByzzerChip>{demographicDimension.display}</ByzzerChip>;
                        })}
                    </div>
                </>
            );
        },
    },
    {
        key: 'timePeriod',
        dom: (timePeriod) => {
            if(timePeriod.period){
            return (
                <>
                    <h3>Time Period</h3>
                    <div className={'story-summary-chips'}>
                        {timePeriod.type === 'relative' ? (
                            <ByzzerChip>{timePeriod.period}</ByzzerChip>
                        ) : (
                            <ByzzerChip>
                                {`${timePeriod.period?.weeks} weeks ending`}
                                <ByzzerDate
                                    date={timePeriod.period?.endDate ? timePeriod.period.endDate : new Date()}
                                    format={'yyyy-MM-dd'}
                                    timeZone={STORY_TIME_ZONE}
                                />
                            </ByzzerChip>
                        )}
                    </div>
                </>
            )}else{
                return<></>
            }
        },
    },
    {
        key: 'performanceMarkets',
        dom: (performanceMarkets) => {
            return (
                <>
                    <h3>Performance Markets</h3>
                    <div className={'story-summary-chips'}>
                        {performanceMarkets.map((performanceMarket) => {
                            return <ByzzerChip>{performanceMarket.name}</ByzzerChip>;
                        })}
                    </div>
                </>
            );
        },
    },
    {
        key: 'comparisonPerformanceMarkets',
        dom: (comparisonPerformanceMarkets) => {
            return comparisonPerformanceMarkets?.marketType.length ? (
                <>
                    <h3>Comparison Performance Markets</h3>
                    <div className={'story-summary-chips'}>
                        <ByzzerChip>{`${comparisonPerformanceMarkets.channel}(${comparisonPerformanceMarkets.marketType})`}</ByzzerChip>
                    </div>
                </>
            ) : (
                <></>
            );
        },
    },

];
