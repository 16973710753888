import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import './QuoteContent.scss';
import classnames from 'classnames';
import { QuoteAccept, QuoteReview } from '@/components/Quote';
import { memoize } from 'lodash';
import { ByzzerTabs } from '@/components/ByzzerTabs';

const baseClassName = 'quote-content';

export type TabType = {
    title: string;
    key: string;
    content: string | JSX.Element | ReactElement;
    disabled?: boolean;
    showFooter?: boolean;
    tabFooter?: string | JSX.Element | ReactElement;
}

const Tabs = memoize(({tabs, activeTabIndex, handleClick}: {tabs: TabType[]; activeTabIndex: number; handleClick: (key: string, index: number) => void;}) => (
    <>
        {tabs.map((tab, i) => {
            return (
                <div
                    key={tab.title}
                    className={classnames(`${baseClassName}__tab`, {
                        [`${baseClassName}__tab--active`]: i === activeTabIndex,
                        [`${baseClassName}__tab--disabled`]: tab.disabled,
                    })}
                    onClick={() => handleClick(tab.key, i)}
                >
                    <p>{tab.title}</p>
                </div>
            )
        })}
    </>
));

type QuoteTabs = 'reviewPurchase' | 'reviewPayment';

export const tabsList: {display: string, value: QuoteTabs}[] = [
    {display: "Review Purchase", value: "reviewPurchase"},
    {display: "Review Payment", value: "reviewPayment"}
]

export function QuoteContent() {
    const [activeTab, setActiveTab] = useState<QuoteTabs>('reviewPurchase');

    const handleSetTab = (tab: QuoteTabs) => {
        setActiveTab(tab);
    };

    return (
        <div className={classnames(`${baseClassName}`)}>
            {/* Titles of the tabs */}
            <div className={classnames(`${baseClassName}__tabs`)}>
                <ByzzerTabs<QuoteTabs>
                    tabsList={tabsList}
                    onClick={handleSetTab}
                    activeTab={activeTab}
                    type={'filled'}
                />
            </div>
            {/* Content of each selected tabs */}
            <div className={classnames(`${baseClassName}__content`)}>
                {activeTab === 'reviewPayment' && <QuoteAccept />}
                {activeTab === 'reviewPurchase' && <QuoteReview placeOrder={() => setActiveTab('reviewPayment')} />}
            </div>
        </div>
    );
}

export default QuoteContent;
