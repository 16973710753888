import React, {useCallback, useEffect, useState} from 'react';
import AlertsList from '@/components/AlertsList/AlertsList';
import { MyAlertsProps } from '@/pages/MyAlertsPage';

export const AvailableAlerts = (props: MyAlertsProps) => {
    return (
        <AlertsList {...props}/>
    );
}
 
export default AvailableAlerts;

AvailableAlerts.displayName = 'AvailableAlerts';