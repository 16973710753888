import React, {useLayoutEffect, useRef, useState} from "react";

export type ControlledInputProps = React.InputHTMLAttributes<HTMLInputElement>;

export function ControlledInput({value, onChange, ...props}: ControlledInputProps) {

    const [cursor, setCursor] = useState(null);
    const ref = useRef<HTMLInputElement>(null);

    useLayoutEffect(() => {
        ref.current?.setSelectionRange(cursor, cursor);
    }, [ref, cursor, value]);

    const handleChange = (e) => {
        setCursor(e.target.selectionStart);
        onChange?.(e);
    };

    return <input ref={ref} value={value} onChange={handleChange} {...props} />;
}

export default ControlledInput;
