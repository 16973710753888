import React, { useState, useEffect, useImperativeHandle, useRef, forwardRef } from "react";
import classnames from 'classnames';
import './AboutYou.scss';
import { WizardActions, WizardContent, WizardHeader, WizardStep } from "@byzzer/ui-components";
import { useTenantApi } from "@/hooks";
import { OnboardingStepProps } from "../../OnboardingWizard";
import AboutYouContent, { PersonaSelections, OTHER_PERSONA_CODE, defaultPersonaSelections } from "./AboutYouContent";
import { useUser } from "@/contexts/UserContext";

export const AboutYou = forwardRef(({
    action,
    isLastStep,
    step,
    onNext,
    onSkip,
    setBusy,
    title,
    nextText,
    busy,
    code,
    ...props
}: OnboardingStepProps, ref) => {
    const {savePersonas} = useTenantApi();
    const { refreshUser, user } = useUser();
    const stepRef = useRef<any>();
    const [personaSelections, setPersonaSelections] = useState(defaultPersonaSelections);
    const [shouldSaveData, setShouldSaveData] = useState(false);
    
    const { selectedPersonas, otherPersonaDesc } = personaSelections;

    const canSubmit = selectedPersonas.length && !(selectedPersonas.includes(OTHER_PERSONA_CODE) && !otherPersonaDesc);

    useEffect(() => {
        ;(async () => {
            if (shouldSaveData && canSubmit) {
                await savePersonas({
                    personaCodes: selectedPersonas, 
                    otherPersonaDesc
                });
                refreshUser({
                    metadata: {
                        ...user?.metadata,
                        personaCodes: selectedPersonas,
                        otherPersonaDesc
                    }
                });
                onNext();
            }
            setShouldSaveData(false);
        })()
    }, [shouldSaveData]);

    // this is required to allow multiple refs to the step.  needs the dependency array or will cause infinite loop
    useImperativeHandle(ref, () => stepRef.current, []);

    function handleNext(id?: string) {
        setBusy?.(true);
        setShouldSaveData(true);
        return false;
    }

    async function handleChange({ value }: ByzzerChangeEvent<PersonaSelections>) {
        setPersonaSelections(value);
    }

    return (
        <WizardStep byzRef={stepRef} title={title} id={step}>
            <WizardHeader className={classnames({
                // 'report-run-config-wizard-header--valid': filtersValid
            })}>
                <h1 className={`report-run-config-wizard__step-title`}>
                    {title}
                </h1>
            </WizardHeader>
            <WizardContent>
                <AboutYouContent 
                    onChange={handleChange}
                    value={personaSelections}
                    code={code}
                />
            </WizardContent>
            <WizardActions 
                disableNext={!canSubmit} 
                nextDisabledTip={'Please select or enter a role to continue.'} 
                beforeNext={handleNext}
                nextText={nextText}
            />
        </WizardStep>
    );

});

export default AboutYou;

AboutYou.displayName = 'AboutYou';

