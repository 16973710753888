import './ResetPassword.scss';
import { DashboardNav } from "@/components/dashboard";
import DashboardHeader from "@/components/dashboard/DashboardHeader";
import DashboardContent from "@/components/dashboard/DashboardContent";
import UserPassword from "@/components/UserPassword";
import { useUser } from '@/contexts/UserContext';

export type ResetPasswordProps = {
    data?: any;
}

export const ResetPassword = ({
    ...props
}: ResetPasswordProps) => {

const { user } = useUser();
    return (
        <div className="dashboard">
            {/* @ts-ignore */}
            <DashboardNav disabled={user?.resetPassword}/>
            {/* @ts-ignore */}
            <DashboardHeader disabled={user?.resetPassword}/>
            {/* @ts-ignore */}
            <DashboardContent title={'Reset Password'} loading={false} subTitle={""}>
                <UserPassword shouldUpdateResetPasswordFlag={true}/>
            </DashboardContent>
        </div>
    );

};

export default ResetPassword;

ResetPassword.displayName = 'ResetPassword';