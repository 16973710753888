import { useUser } from "@/contexts/UserContext";
import { useTenantApi } from "@/hooks";
import { useState } from "react";
import { create as createModal } from "react-modal-promise";
import AboutYouContent, { PersonaSelections } from "../Onboarding/OnboardingSteps/AboutYou/AboutYouContent";
import ByzzerModal2 from "@/components/modals/ByzzerModal2";
import classnames from "classnames";
import './PersonasEditor.scss'

const baseClassName = 'byz-personas-editor';

export function PersonasEditor({ onResolve, onClose, includeSkip, includeSubmit, ...props }) {
    const { refreshUser, user, company } = useUser();
    const { savePersonas, skipPersonas } = useTenantApi();
    const [busy, setBusy] = useState(false);

    async function handlePersonaSkipClick() {
        if (includeSkip) {
            setBusy(true);
            try {
                await skipPersonas();
                refreshUser({
                    ...user,
                    metadata: {
                        ...user?.metadata,
                        showPersonasPopup: false
                    }
                })
            } catch (error) {
                throw error;
                // tbd
            } finally {
                onResolve(false);
            }
        } else {
            onResolve(false)
        }
    }

    async function handlePersonaSubmitClick({selectedPersonas, otherPersonaDesc}: PersonaSelections) {
        setBusy(true);
        try {
            await savePersonas({
                personaCodes: selectedPersonas, 
                otherPersonaDesc
            });
            refreshUser({
                metadata: {
                    ...user?.metadata,
                    personaCodes: selectedPersonas,
                    otherPersonaDesc,
                    showPersonasPopup: false
                }
            });
        } catch (error) {
            throw error;
            // tbd
        } finally {
            onResolve(true);
        }
    }


    return (
        // @ts-ignore
        <ByzzerModal2
            className={classnames(`${baseClassName}__modal`)}
        >
            {/* @ts-ignore */}
            <ByzzerModal2.Header 
                shouldTrackClose={true}
                trackCloseName={'persona_popup_skipped'}
                trackCloseData={{
                    companyName: company?.displayName,
                    authorizedUserId: user?.authorizedUserId,
                    userEmail: user?.email,
                    previousSkippedPersonasDtm: user?.metadata?.skippedPersonasDtm
                }}
                onClose={handlePersonaSkipClick}
            >
                <div className={classnames(`${baseClassName}__modal-headings`)}>
                    <p className={classnames(`${baseClassName}__modal-heading`)}>Help us understand you better</p>
                    <p className={classnames(`${baseClassName}__modal-subheading`)}>Select the role(s) that best describe your duties</p>
                </div>
            </ByzzerModal2.Header>
            {/* @ts-ignore */}
            <ByzzerModal2.Content>
                {/* @ts-ignore */}
                <ByzzerModal2.Mask show={busy}/>
                <AboutYouContent
                    value={{
                        selectedPersonas: user?.metadata?.personaCodes ?? [],
                        otherPersonaDesc: user?.metadata?.otherPersonaDesc ?? ''
                    }}
                    includeSkip={includeSkip}
                    includeSubmit={includeSubmit}
                    onSkip={handlePersonaSkipClick}
                    onSubmit={handlePersonaSubmitClick}
                />
            </ByzzerModal2.Content>
        </ByzzerModal2>
    );
}
// @ts-ignore
export const openPersonasEditor = createModal(PersonasEditor);