import classnames from 'classnames';
import React, {ChangeEvent, useImperativeHandle,} from 'react';
import {useByzzerRadioContext} from "./ByzzerRadioGroupContext";
import {ByzzerCheckableChangeEventHandler} from "@byzzer/ui-components";

export type ByzzerRadioProps<ValueType extends string | number | boolean> = {
    name?: string;
    label?: React.ReactNode;
    onChange?: ByzzerCheckableChangeEventHandler<ValueType>;
    checked?: boolean;
    disabled?: boolean;
    value?: ValueType;
    labelPlacement?: 'top' | 'bottom' | 'left' | 'right';
    children?: React.ReactNode;
    radioRef?: React.Ref<ByzzerRadioRef<ValueType>>;
} & Partial<Omit<React.HTMLAttributes<HTMLLabelElement>, 'onChange'>>;

export type ByzzerRadioRef<T extends string | number | boolean> = {
    value?: T;
    checked: boolean
}

const baseClassName = 'byz-radio';

export function ByzzerRadio<ValueType extends string | number | boolean = string>(
    {
        onChange,
        name,
        checked,
        className,
        disabled,
        value,
        labelPlacement = 'right',
        children,
        label = children,
        radioRef,
        ...props
    }: ByzzerRadioProps<ValueType>) {

    const {id: groupId, value: contextValue, triggerChange} = useByzzerRadioContext<ValueType>();

    function handleRadioChange(e: ChangeEvent<HTMLInputElement>) {
        const event = {
            value: value!,
            name,
            sourceEvent: e,
            checked: e.target.checked,
        };

        if (e.target.checked) triggerChange(event);
        onChange?.(event);
    }

    useImperativeHandle(radioRef, () => ({
        value,
        checked: contextValue === value,
    }), [contextValue, value]);

    return (
        <label className={classnames(baseClassName, className, {
            [`${baseClassName}--label-${labelPlacement}`]: labelPlacement,
            [`${baseClassName}--disabled`]: disabled,
        })} {...props}>
            <input
                type={'radio'}
                onChange={handleRadioChange}
                checked={contextValue === value}
                name={groupId}
                className={`${baseClassName}__input`}
                disabled={disabled}
                value={value as string}
            />
            <div className={classnames(`${baseClassName}__toggle`, {
                [`${baseClassName}__toggle--disabled`]: disabled,
            })}/>
            {label !== undefined && (
                <div className={classnames(`${baseClassName}__label`, {
                    [`${baseClassName}__label--disabled`]: disabled,
                })}>
                    {label}
                </div>
            )}
        </label>
    );
}

ByzzerRadio.displayName = 'ByzzerRadio';
