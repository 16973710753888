import { TipIcon } from '@/components/icons';
import { ByzzerMultiSelect } from '@/components/form';
import React from 'react';
import { useUser } from '@/contexts/UserContext';

export function CategorySelector({ onChange, categories, variant = 'default'}) {
    const {categories: subscriptionCategories} = useUser();
    return (
        <section className="ppg-editor__category-selector">
            <h2 className="ppg-editor__section-title">
                Select your Category(s):
                {/* @ts-ignore */}
                <TipIcon type={'info'}>
                    <p>
                        Choose which category(s) to apply your PPG list to. A PPG list can cross multiple categories,
                        but keep in mind, if a single PPG contains UPCs from different categories, if you run a report
                        on one category, the data for the PPG will include only the UPCs in the category of the report.
                    </p>
                </TipIcon>
            </h2>
            {/* @ts-ignore */}
            <ByzzerMultiSelect
                className={'ppg-editor__category-selector__selector'}
                name={'competitive-productset'}
                placeholder="Select from the list"
                multiSelectWithCheckBox
                value={categories}
                selectedOptions={categories}
                onChange={onChange}
                options={subscriptionCategories}
                seperator={','}
                variant={variant}
            />
        </section>
    );
}
