//constant used in storyProductSelector on categorySelection as a warning message.
export const MESSAGE_NO_CATEGORY_MATCH =
    'The categories you have selected do not contain any of your focus brands. Please select at least one category with a green dot.';
export const MESSAGE_NO_CATEGORY_MATCH_FOR_MANUFACTURER =
    'The categories you have selected do not contain your manufacturer. Please select at least one category with a green dot';
//This list i used in storyProductSelector file over categoriesSelector Input as a dropdown select
export const CATEGORY_AGGREGATION_LEVELS = [
    { display: 'Category', value: 'category' },
    { display: 'Super-Category', value: 'super_category' },
    { display: 'Department', value: 'department' },
    { display: 'Total Store', value: 'total_store' },
];
//This list i used in storyProductSelector file over focusBrandSelector Input as a dropdown select
export const BRAND_AGGREGATION_LEVELS = [
    { display: 'Focus Brand', value: 'brand' },
    { display: 'Manufacturer', value: 'manufacturer' },
];
//This list i used in storyMarketPerformanceSelector file over marketPicker Input as a dropdown select
export const MARKET_SELECTOR_TYPE = [
    { display: 'select by market type ', value: 'across' },
    { display: 'select a custom list of markets', value: 'custom' },
];

export const  CANNOT_RUN_STORY_MESSAGE = 'You cannot run this report because you have not selected all of the required values.';

export const STORY_TIME_ZONE = 'America/New_York';