import { scoreCardSelectorAttributes } from '@/utils/reportSelectorUtil';
import { ByzzerLink } from '@/components/form';
import React from 'react';
import { defaultConfigs } from '@/config/globalVars';

export const scorecardConfigs = [
    {
        sku: 'promotion_scorecard',
        title: 'Promotions: Scorecard',
        orderInTable: 0,
        orderInList: 1,
        selectors: scoreCardSelectorAttributes(defaultConfigs.report),
        driver: 'promotion',
        lever: 'Scorecard',
        key: 'Scorecard',
        ppgReport: true, // This attribute will be there as true for PPG based Reports
    },
    {
        sku: 'promotion_action_plan',
        title: 'Promotions: Action Plan',
        orderInTable: 0,
        orderInList: 2,
        selectors: scoreCardSelectorAttributes(defaultConfigs.report),
        driver: 'promotion',
        lever: 'Action Plan',
        key: 'Action Plan',
        ppgReport: true, // This attribute will be there as true for PPG based Reports
    },
    {
        sku: 'promotion_threshold',
        title: 'Promotions: Promoted Price Thresholds',
        orderInTable: 3,
        orderInList: 5,
        selectors: scoreCardSelectorAttributes(defaultConfigs.report),
        driver: 'promotion',
        lever: 'Promoted Price Thresholds',
        key: 'Threshold',
        ppgReport: true, // This attribute will be there as true for PPG based Reports
        popupDescription:  <>
            <p>
                Promoted price threshold identifies what proportion of your items are meeting key promoted price points which have an additional impact on consumers beyond the elasticity effect. This is based on the modeled sales impact of crossing a key price point such as $0.99.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink href=" https://learn.byzzer.ai/en/articles/6289675-understanding-your-promotion-scores" rel="noreferrer" target="_blank">understanding your promotion scores</ByzzerLink></span>
        </>
    },
    {
        sku: 'promotion_efficiency',
        title: 'Promotions: Efficiency',
        orderInTable: 1,
        orderInList: 3,
        selectors: scoreCardSelectorAttributes(defaultConfigs.report),
        driver: 'promotion',
        lever: 'Efficiency',
        key: 'Efficiency',
        ppgReport: true, // This attribute will be there as true for PPG based Reports
        popupDescription:  <>
            <p>
                Promotion efficiency quantifies how much incremental lift you drive with each dollar invested in marking down price on promotions. If a small price discount (investment) yields a big increase in sales, that promotion has high efficiency.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink href=" https://learn.byzzer.ai/en/articles/6289675-understanding-your-promotion-scores" rel="noreferrer" target="_blank">understanding your promotion scores</ByzzerLink></span>
        </>
    },
    {
        sku: 'promotion_timing',
        title: 'Promotions: Promotion Timing',
        orderInTable: 5,
        orderInList: 7,
        selectors: scoreCardSelectorAttributes(defaultConfigs.report),
        driver: 'promotion',
        lever: 'Promotion Timing',
        key: 'Timing',
        ppgReport: true, // This attribute will be there as true for PPG based Reports
        popupDescription:  <>
            <p>
                Promotion timing is calculated by assessing your brand’s percent of distribution (TDP) on promotion during the best weeks for promotions across the category. Best weeks are the 3 weeks with highest base unit sales
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href=" https://learn.byzzer.ai/en/articles/6289675-understanding-your-promotion-scores" rel="noreferrer" target="_blank">understanding your promotion scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'promotion_fair_of_support',
        title: 'Promotions: Fair Share of Promo Support',
        orderInTable: 4,
        orderInList: 6,
        selectors: scoreCardSelectorAttributes(defaultConfigs.report),
        driver: 'promotion',
        lever: 'Fair Share of Promo Support',
        key: 'Fair of Support',
        ppgReport: true, // This attribute will be there as true for PPG based Reports
        popupDescription:  <>
            <p>
                Fair share of support compares your brand’s support (weeks on promotion) to the promotion lift to identify if the brand is receiving an appropriate amount of support vs the lift and support of other brands in the category.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href=" https://learn.byzzer.ai/en/articles/6289675-understanding-your-promotion-scores" rel="noreferrer" target="_blank">understanding your promotion scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'promotion_depth_of_discount',
        title: 'Promotions: Depth of Discount',
        orderInTable: 2,
        orderInList: 4,
        selectors: scoreCardSelectorAttributes(defaultConfigs.report),
        driver: 'promotion',
        lever: 'Depth of Discount',
        key: 'Depth of Discount',
        ppgReport: true, // This attribute will be there as true for PPG based Reports
        popupDescription:  <>
            <p>
                Optimal depth of discount investigates the percent of your brand's overall promotions that fall into the optimal discount range based on the sales lift. The low end of the optimal discount range is the profit maximizing point and the high end of the discount range is the deepest promotion that can be run at break-even profit vs. baseline profit.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href=" https://learn.byzzer.ai/en/articles/6289675-understanding-your-promotion-scores" rel="noreferrer" target="_blank">understanding your promotion scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'pricing_scorecard',
        title: 'Pricing: Scorecard',
        orderInTable: 0,
        orderInList: 1,
        selectors: scoreCardSelectorAttributes(defaultConfigs.scoreCard),
        driver: 'pricing',
        lever: 'Scorecard',
        key: 'Scorecard',
        ppgReport: true, // This attribute will be there as true for PPG based Reports
    },
    {
        sku: 'pricing_action_plan',
        title: 'Pricing: Action Plan',
        orderInTable: 0,
        orderInList: 2,
        selectors: scoreCardSelectorAttributes(defaultConfigs.scoreCard),
        driver: 'pricing',
        lever: 'Action Plan',
        key: 'Action Plan',
        ppgReport: true, // This attribute will be there as true for PPG based Reports
    },
    {
        sku: 'pricing_price_position',
        title: 'Pricing: Price Position in Category',
        orderInTable: 3,
        orderInList: 5,
        selectors: scoreCardSelectorAttributes(defaultConfigs.report),
        driver: 'pricing',
        lever: 'Price Position in Category',
        key: 'Price Position',
        ppgReport: true, // This attribute will be there as true for PPG based Reports
        popupDescription:  <>
            <p>
                Price position in the category ranks your brand’s non-promoted average price to the rest of the category. If you are ranked towards the median of the category your score is high, if your price is really high or really low your score is low.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href="https://learn.byzzer.ai/en/articles/6289671-understanding-your-pricing-scores" rel="noreferrer" target="_blank">understanding your pricing scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'pricing_everyday_velocity',
        title: 'Pricing: Everyday Velocity',
        orderInTable: 5,
        orderInList: 7,
        selectors: scoreCardSelectorAttributes(defaultConfigs.report),
        driver: 'pricing',
        lever: 'Everyday Velocity',
        key: 'Everyday Velocity',
        ppgReport: true, // This attribute will be there as true for PPG based Reports
        popupDescription:  <>
            <p>
                Everyday Velocity is a core measure used across many reports measured by sales per point of distribution ($/TDP). Everyday velocity specifically looks at sales when non-promoted. The higher your brand’s velocity the more rapidly your sales will grow with each additional point of distribution.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href="https://learn.byzzer.ai/en/articles/6289671-understanding-your-pricing-scores" rel="noreferrer" target="_blank">understanding your pricing scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'pricing_internal_price_ladder',
        title: 'Pricing: Internal Price Ladder',
        orderInTable: 4,
        orderInList: 6,
        selectors: scoreCardSelectorAttributes(defaultConfigs.report),
        driver: 'pricing',
        lever: 'Internal Price Ladder',
        key: 'Internal Price Ladder',
        ppgReport: true, // This attribute will be there as true for PPG based Reports
        popupDescription:  <>
            <p>
                Internal price ladder highlights if you are following common price ladder principles by size, total size and pack size. For example, a product that is 8 oz should have a higher price per ounce than a product which is 20oz. The measure quantifies the percent of items (PPGs) which do not follow a price ladder and benchmarks that to other brands in the category to create a score.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href="https://learn.byzzer.ai/en/articles/6289671-understanding-your-pricing-scores" rel="noreferrer" target="_blank">understanding your pricing scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'pricing_historical_price_changes',
        title: 'Pricing: Historical Price Changes',
        orderInTable: 6,
        orderInList: 8,
        selectors: scoreCardSelectorAttributes(defaultConfigs.report),
        driver: 'pricing',
        lever: 'Historical Price Changes',
        key: 'Historical Price Changes',
        ppgReport: true, // This attribute will be there as true for PPG based Reports
        popupDescription:  <>
            <p>
                Historical price changes rolls activity for the past year all into one measure. This is a composite metric looking at elasticity, total pricing and category pricing to see if any price changes were suboptimal. Sub-optimal price changes can be because you increased price on a responsive item, which can cost you a lot of units or reduced price on a highly cannibalistic item which can hurt your portfolio or maybe did not follow category price for a highly price-competitive item which can hurt your position in the category.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href="https://learn.byzzer.ai/en/articles/6289671-understanding-your-pricing-scores" rel="noreferrer" target="_blank">understanding your pricing scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'pricing_pricing_strategy',
        title: 'Pricing: Everyday vs. Promotion Strategy',
        orderInTable: 1,
        orderInList: 3,
        selectors: scoreCardSelectorAttributes(defaultConfigs.scoreCard),
        driver: 'pricing',
        lever: 'Everyday vs. Promo Strategy',
        key: 'Pricing Strategy',
        ppgReport: true, // This attribute will be there as true for PPG based Reports
        popupDescription:  <>
            <p>
                Pricing Strategy evaluates if you are following the optimal pricing strategy based on elasticity of your products - everyday vs. when promoted. The score is based on how well your split in sales between promoted and non-promoted and your depth of discount match that optimal strategy and how that compares to other brands in the category.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href="https://learn.byzzer.ai/en/articles/6289671-understanding-your-pricing-scores" rel="noreferrer" target="_blank">understanding your pricing scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'pricing_threshold',
        title: 'Pricing: Regular Price Thresholds',
        orderInTable: 2,
        orderInList: 4,
        selectors: scoreCardSelectorAttributes(defaultConfigs.report),
        driver: 'pricing',
        lever: 'Regular Price Thresholds',
        key: 'Threshold',
        ppgReport: true, // This attribute will be there as true for PPG based Reports
        popupDescription:  <>
            <p>
                Price threshold identifies what proportion of your items are meeting key promoted price points which have an additional impact on consumers beyond the elasticity effect. This is based on the modeled sales impact of crossing a key price point such as $0.99.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href="https://learn.byzzer.ai/en/articles/6289671-understanding-your-pricing-scores" rel="noreferrer" target="_blank">understanding your pricing scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'assortment_scorecard',
        title: 'Assortment: Scorecard',
        orderInTable: 0,
        orderInList: 1,
        selectors: scoreCardSelectorAttributes(defaultConfigs.scoreCard),
        driver: 'assortment',
        lever: 'Scorecard',
        key: 'Scorecard',
    },
    {
        sku: 'assortment_action_plan',
        title: 'Assortment: Action Plan',
        orderInTable: 0,
        orderInList: 2,
        selectors: scoreCardSelectorAttributes(defaultConfigs.scoreCard),
        driver: 'assortment',
        lever: 'Action Plan',
        key: 'Action Plan'
    },
    {
        sku: 'assortment_assortment_index',
        title: 'Assortment: Assortment Score Index',
        orderInTable: 1,
        orderInList: 3,
        selectors: scoreCardSelectorAttributes(defaultConfigs.scoreCard),
        driver: 'assortment',
        lever: 'Assortment Score Index',
        key: 'Assortment Index',
        popupDescription:  <>
            <p>
                Assortment index benchmarks the brand’s performance in the market to the average for the category on Sales, Growth, Velocity, Distribution, and share growth. The higher your index, the more differentiated your brand’s performance is in the category.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href="https://learn.byzzer.ai/en/articles/6289664-understanding-your-assortment-scores" rel="noreferrer" target="_blank">understanding your assortment scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'assortment_velocity',
        title: 'Assortment: Velocity',
        orderInTable: 2,
        orderInList: 4,
        selectors: scoreCardSelectorAttributes(defaultConfigs.report),
        driver: 'assortment',
        lever: 'Velocity',
        key: 'Velocity',
        popupDescription:  <>
            <p>
                Velocity is a core measure used across many reports measured by sales per point of distribution ($/TDP). The higher your brand’s velocity the more rapidly your sales will grow with each additional point of distribution.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href="https://learn.byzzer.ai/en/articles/6289664-understanding-your-assortment-scores" rel="noreferrer" target="_blank">understanding your assortment scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'assortment_opportunity',
        title: 'Assortment: Opportunities and Risks',
        orderInTable: 3,
        orderInList: 5,
        selectors: scoreCardSelectorAttributes(defaultConfigs.scoreCard),
        driver: 'assortment',
        lever: 'Opportunities and Risks',
        key: 'Opportunity',
        popupDescription:  <>
            <p>
                Opportunity Index compares the number of items which are expansion opportunities for your brand in the market vs. the average number for the category. An item is an opportunity to expand if it ranks in the top 20% based on it the item’s Assortment Rank.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href="https://learn.byzzer.ai/en/articles/6289664-understanding-your-assortment-scores" rel="noreferrer" target="_blank">understanding your assortment scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'assortment_at_risk',
        title: 'Assortment: Opportunities and Risks',
        orderInTable: 4,
        orderInList: 5,
        selectors: scoreCardSelectorAttributes(defaultConfigs.scoreCard),
        driver: 'assortment',
        lever: 'Opportunities and Risks',
        key: 'At Risk',
        popupDescription:  <>
            <p>
                At Risk Index compares the number of items which are at risk for your brand in the market vs. the average number for the category. An item is at risk if it ranks in the bottom 20% based on it the item’s Assortment Rank.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href="https://learn.byzzer.ai/en/articles/6289664-understanding-your-assortment-scores" rel="noreferrer" target="_blank">understanding your assortment scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'product_scorecard',
        title: 'New Products: Scorecard',
        orderInTable: 0,
        orderInList: 1,
        selectors: scoreCardSelectorAttributes(['product:option2', 'market:option1']),
        driver: 'product',
        lever: 'Scorecard',
        key: 'Scorecard'
    },
    {
        sku: 'product_action_plan',
        title: 'New Products: Action plan',
        orderInTable: 0,
        orderInList: 2,
        selectors: scoreCardSelectorAttributes(['product:option2', 'market:option1']),
        driver: 'product',
        lever: 'Action Plan',
        key: 'Action Plan'
    },
    {
        sku: 'product_market_fit',
        title: 'New Products: Market Fit',
        orderInTable: 0,
        orderInList: 6,
        keyCharacteristics: true,
        selectors: scoreCardSelectorAttributes(['product:option2', 'market:option1']),
        driver: 'product',
        lever: 'Market Fit',
        key: null
    },
    {
        sku: 'product_distribution',
        title: 'New Products: Distribution Curve',
        orderInTable: 1,
        orderInList: 3,
        selectors: scoreCardSelectorAttributes(['product:option2', 'market:option1']),
        driver: 'product',
        lever: 'Distribution Curve',
        key: 'Distribution',
        popupDescription:  <>
            <p>
                Distribution curve tracks distribution since launch of your new products vs. other new products in distribution at 13 weeks. This allows you to benchmark your new products' speed to market coverage. Is your new product getting the distribution that other new products were getting at 13 weeks? If your product has been in the market for more or less than 13 weeks, jump into the dashboard to see the trend at the exact number of weeks your product has been selling.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href="https://learn.byzzer.ai/en/articles/6292226-understanding-your-new-product-scores" rel="noreferrer" target="_blank">understanding your new product scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'product_sales',
        title: 'New Products: Sales Curve',
        orderInTable: 2,
        orderInList: 4,
        selectors: scoreCardSelectorAttributes(['product:option2', 'market:option1']),
        driver: 'product',
        lever: 'Sales Curve',
        key: 'Sales',
        popupDescription:  <>
            <p>
                Sales curve tracks $ sales since launch of your new products vs. other new products at 13 weeks. This allows you to benchmark your new products’ sales growth in the market. If your product has been in the market for more or less than 13 weeks, jump into the dashboard to see the trend at the exact number of weeks your product has been selling.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href="https://learn.byzzer.ai/en/articles/6292226-understanding-your-new-product-scores" rel="noreferrer" target="_blank">understanding your new product scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'product_velocity',
        title: 'New Products: Velocity',
        orderInTable: 3,
        orderInList: 5,
        selectors: scoreCardSelectorAttributes(['product:option2', 'market:option1']),
        driver: 'product',
        lever: 'Velocity',
        key: 'Velocity',
        popupDescription:  <>
            <p>
                Time-Based Velocity ranks your new items unit sales per week to other new items in the category. In order for your products to succeed long term, they have to measure up to other products in the category in their ability to drive sales every week.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href="https://learn.byzzer.ai/en/articles/6292226-understanding-your-new-product-scores" rel="noreferrer" target="_blank">understanding your new product scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'product_tdp_upside',
        title: 'New Products: TDP Upside',
        orderInTable: 4,
        orderInList: 7,
        selectors: scoreCardSelectorAttributes(['product:option2', 'market:option1']),
        driver: 'product',
        lever: 'TDP Upside',
        key: 'TDP UPside',
        popupDescription:  <>
            <p>
                TDP upside compares your current distribution (TDP) to your targeted distribution. Target distribution for each market is projected based on the expected performance once it becomes an established product. If your new product is in the top 10% of new products based on velocity, we would expect it to long-term become part of the top 10% of all products in the category. This would result in your product reaching the average distribution of the top 10% of all products in the category for the market.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href="https://learn.byzzer.ai/en/articles/6292226-understanding-your-new-product-scores" rel="noreferrer" target="_blank">understanding your new product scores</a></ByzzerLink></span>
        </>
    },
    {
        sku: 'product_hit_rate_vs_coverage',
        title: 'New Products: Hit Rate vs. Coverage',
        orderInTable: 5,
        orderInList: 8,
        selectors: scoreCardSelectorAttributes(['product:option2', 'market:option1']),
        driver: 'product',
        lever: 'Hit Rate vs. Coverage',
        key: 'Hit Rate vs Coverage',
        popupDescription:  <>
            <p>
                Hit Rate vs. Coverage looks and the relevance of market coverage in your new item launches. The hit rate is the proportion of stores in the retailer which are carrying the new item. The coverage is the retailer’s share of the category sales (retailer + remaining market) to highlight the retailer's importance. If you are covering a lot of a retailer’s stores AND it is a retailer that represents a lot of the category sales in the market, your score will be higher.
            </p>
            <br />
            <span>For more information on the metrics and calculations refer to the complete guide to <ByzzerLink><a href="https://learn.byzzer.ai/en/articles/6292226-understanding-your-new-product-scores" rel="noreferrer" target="_blank">understanding your new product scores</a></ByzzerLink></span>
        </>
    }
]

export const SCORECARDS_BY_ID = scorecardConfigs.reduce((map, config) => {
    map[config.sku] = config;
    return map;
}, {})
