import React, { useMemo } from 'react';
import './MySubscription.scss';
import DashboardContent from "@/components/dashboard/DashboardContent";
import OutletWithProps from "@/components/OutletWithProps";
import { useUser } from '@/contexts/UserContext';
import useIsMobile from '@/hooks/useIsMobile';

function MySubscription() {
    const { userQuote, company, preSales, user } = useUser();
    const isMobile = useIsMobile();
    const { accessLevel, features } = company;
    const disableQuoteForViewerRole = user?.role === 'viewer';    
    const links = useMemo(() => {

        let availableLinks = [];

        if (!isMobile) {
            availableLinks.push(
                { url: "/dashboard/my_subscription/subscription", text: 'Subscription' },
                { url: "/dashboard/my_subscription/members", text: 'Members' },
            );
        }

        if (accessLevel === 'tracked_manufacturer' && (!preSales || features?.freeAccountGroup !== 'B') && !isMobile) {
            // enabling this tab only for "tracked_manufacturer"
            availableLinks.push({ url: '/dashboard/my_subscription/brands', text: 'Brand Info' });
        } 
        if (userQuote && Boolean(Object.keys(userQuote).length) && !disableQuoteForViewerRole && !isMobile) { // NOTE: if quote has some values then we're enabling the quote tab.
            availableLinks.push({ url: "/dashboard/my_subscription/quote", text: 'Quote' })
        }

        return availableLinks;
    }, [userQuote, accessLevel]);

    return <DashboardContent title={'Company Subscription'} className={'my_subscription'} links={links}>
        <OutletWithProps />
    </DashboardContent>
}

export default MySubscription;
