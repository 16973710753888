import { useUser } from "@/contexts/UserContext";
// import { SimulatorRunConfig } from "@/types/StoriesTypes";
import { getFlattenedRunConfigOptionsBySku, getRunConfigOptionsBySku } from "./product.service";
import { SimulatorRunConfig } from "@/types/SimulatorTypes";
import { emptyFilterRunConfig } from "@/pages/SimulatorConfigEditor";

export const useSimulatorRunService = () => {
    const { defaultRunConfig } = useUser();
    return {
        
        getSimulatorDefaultRunConfigBySku(sku?: string | null): SimulatorRunConfig {
            const includedRunConfigValues: Partial<SimulatorRunConfig> = emptyFilterRunConfig;

            if (!sku) return includedRunConfigValues;

            const runConfigOptions = getRunConfigOptionsBySku(sku);
            const flattenedConfigOptions = getFlattenedRunConfigOptionsBySku(sku);

            const includeTimePeriod =
                flattenedConfigOptions.datatype !== 'omni' &&
                Boolean(runConfigOptions.find(({ type }) => type === 'time_period'));

            const hasMarketRunConfigOptions = Boolean(runConfigOptions?.find(({ type }) => type === 'market'));

            const defaultCategoriesLength = Number(defaultRunConfig?.categories?.length ?? 0);
            const defaultBrandsLength = Number(defaultRunConfig?.brands?.length ?? 0);

            if (
                flattenedConfigOptions.includeCategories &&
                Boolean(defaultRunConfig?.categories?.length)
            ) {
                const maxCategories = flattenedConfigOptions?.maxCategories ?? Infinity;
                if (defaultCategoriesLength <= maxCategories) {
                    includedRunConfigValues.categories = Array.isArray(defaultRunConfig?.categories)? defaultRunConfig?.categories: [];
                }
            }

            if (
                flattenedConfigOptions.includeBrands && 
                Boolean(includedRunConfigValues.categories?.length)
            ) {
                const maxBrands = flattenedConfigOptions?.maxBrands ?? Infinity;
                if (defaultBrandsLength <= maxBrands) {
                    includedRunConfigValues.brands = defaultRunConfig?.brands;
                }
            }

            // markets only apply to rms reports for now.
            // todo: consider adding support different market types
            if (
                hasMarketRunConfigOptions && 
                Boolean(includedRunConfigValues.categories?.length) &&
                flattenedConfigOptions.datatype === 'rms'
                // !flattenedConfigOptions?.requireRemainingMarket
            ) {                
                includedRunConfigValues.markets = defaultRunConfig?.markets;
            }

            // time periods don't apply to omni reports b/c they use a different format
            // todo: consider adding support multiple time period types
            if (includeTimePeriod && defaultRunConfig?.timePeriod) {
                includedRunConfigValues.timePeriod = defaultRunConfig?.timePeriod;
            }

            return includedRunConfigValues;
        },
    }
}