import React, { useEffect, useState } from 'react';
import { ReportRunConfig } from '@/types/ReportRun';
import {useTenantApi} from '@/hooks/useTenantApi';
import { getFlattenedRunConfigOptionsBySku } from '@/services/product.service';
import { ProductRunConfigOptions } from '@/types/RunConfigOptions';
import { ReportRunConfigFilters } from './ReportRunConfigFilters';

export type ReportRunConfigFiltersEditorProps = React.HTMLAttributes<HTMLDivElement> & {
    reportSku?: string;
    onCancel?: () => void;
    reportId?: number;
    onApply?: (config: any) => void;
    reportRunConfig?: Partial<ReportRunConfig>;
};

export function ReportRunConfigFiltersEditor({
    className,
    reportSku,
    onCancel,
    reportId,
    onApply,
    reportRunConfig,
    ...props
}: ReportRunConfigFiltersEditorProps) {
    const { getReportRunConfigById } = useTenantApi();
    const [runConfigOptions, setRunConfigOptions] = useState<ProductRunConfigOptions>();
    const [defaultRunConfig, setDefaultRunConfig] = useState<Partial<ReportRunConfig>>();

    const runType: RunType = 'subscription';

    useEffect(() => {
        (async () => {
            await loadConfig();
        })();
    }, [reportSku]);

    async function loadConfig() {
        try {
            if (reportRunConfig) {
                setDefaultRunConfig(reportRunConfig);
            } else if (reportId) {
                try {
                    let reportRun = await getReportRunConfigById(Number(reportId));
                    setDefaultRunConfig(reportRun?.configuration);
                } catch (err) {
                    console.error(err);
                }
            }
            const flattenedConfigOptions = getFlattenedRunConfigOptionsBySku(reportSku!) as ProductRunConfigOptions;
            setRunConfigOptions(flattenedConfigOptions);
        } finally {
            // setLoading(false);
        }
    }

    return (
        // @ts-ignore
        <ReportRunConfigFilters
            sku={reportSku!}
            runType={runType}
            defaultValues={defaultRunConfig}
            {...runConfigOptions}
            onCancel={onCancel}
            onApply={onApply}
        />
    );
}

export default ReportRunConfigFiltersEditor;
