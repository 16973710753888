import React from 'react';
import { ReportRunConfig } from '@/types/ReportRun';
import { getRunConfigOptionsBySku, getFlattenedRunConfigOptionsBySku } from '@/services/product.service';
import { useUser } from '@/contexts/UserContext';
import { MarketRunConfigOptions, ProductRunConfigOptions, RunConfigOptions } from '@/types/RunConfigOptions';
import { focusKPIOptions } from '@/components/FocusKPISelector/focusKPI.constants';

export type ReportRunValidationError = {
    prop: string;
};

export const useReportRunService = () => {
    const { defaultRunConfig } = useUser();

    return {
        /**
         * Caution this is not implemented yet
         * @param fromSku
         * @param toSku
         * @param runConfig
         */
        getUsableRunConfigOptionsBetweenSkus(
            fromSku: string,
            toSku: string,
            runConfig: Partial<ReportRunConfig>
        ): Partial<ReportRunConfig> {
            const fromOptions = getFlattenedRunConfigOptionsBySku(fromSku);
            const toOptions = getFlattenedRunConfigOptionsBySku(toSku);

            return {};
        },
        getDefaultRunConfigBySku(sku?: string | null): Partial<ReportRunConfig> {
            let includedRunConfigValues: Partial<ReportRunConfig> = {};

            if (!sku) return includedRunConfigValues;

            const runConfigOptions = getRunConfigOptionsBySku(sku);
            const flattenedConfigOptions = getFlattenedRunConfigOptionsBySku(sku);

            const includeTimePeriod =
                flattenedConfigOptions.datatype !== 'omni' &&
                Boolean((runConfigOptions as RunConfigOptions[]).find(({ type }) => type === 'time_period'));
           
            //TODO: create one more const which should set default time period for omni reports     
            const hasMarketRunConfigOptions = Boolean((runConfigOptions as RunConfigOptions[])?.find(({ type }) => type === 'market'));
            const defaultBrandsLength = defaultRunConfig?.brands?.length ?? 0;

            includedRunConfigValues.datatype = flattenedConfigOptions.datatype; // todo: check to see if this actually needed. keeping for now

            if ((flattenedConfigOptions as ProductRunConfigOptions).includeBrands) {
                const maxBrands = (flattenedConfigOptions as ProductRunConfigOptions)?.maxBrands ?? 0;
                includedRunConfigValues.brands =
                    ((flattenedConfigOptions as ProductRunConfigOptions).maxBrands && maxBrands < defaultBrandsLength)
                        ? []
                        : defaultRunConfig?.brands;
            }
            if ((flattenedConfigOptions as ProductRunConfigOptions).includeCategories) {
                includedRunConfigValues.categories =  Array.isArray(defaultRunConfig?.categories)? defaultRunConfig?.categories: [];
            }
            if ((flattenedConfigOptions as ProductRunConfigOptions).requirePPGs) {
                // we dont currently have ppg in defaults, but it will set it here if we do include.  this solves a more immediate issue of ppg id not getting loading in the run config when it's required
               includedRunConfigValues.ppgId = defaultRunConfig?.ppgId ?? -1;
           }
            if ((flattenedConfigOptions as ProductRunConfigOptions).includeCharacteristics) {
                includedRunConfigValues.characteristics = defaultRunConfig?.characteristics;
            }
            if ((flattenedConfigOptions as ProductRunConfigOptions).includeFocusBrands) {
                const maxFocusBrands = (flattenedConfigOptions as ProductRunConfigOptions)?.maxFocusBrands ?? 0;
                includedRunConfigValues.focusBrands =
                   ((flattenedConfigOptions as ProductRunConfigOptions).maxFocusBrands &&
                    maxFocusBrands < defaultBrandsLength)
                        ? []
                        : defaultRunConfig?.brands;
            }

            if ((flattenedConfigOptions as ProductRunConfigOptions).includeDemographicDimensions) {
                includedRunConfigValues.demographicDimensions = [
                    { display: 'Age of Head of Household', value: 'HEAD_OF_HOUSEHOLD' },
                    { display: 'Household Size', value: 'HH_SIZE' },
                    { display: 'Income', value: 'INCOME' },
                    { display: 'Race', value: 'RACE' },
                ];
            }

            // markets only apply to rms reports for now.
            // todo: consider adding support different market types
            if (
                hasMarketRunConfigOptions &&
                flattenedConfigOptions.datatype === 'rms' &&
                !(flattenedConfigOptions as MarketRunConfigOptions).requireRemainingMarket
            ) {
                includedRunConfigValues.markets = defaultRunConfig?.markets;
            }

            // todo: Adding a separate condition because it needs to be removed later
            if (
                hasMarketRunConfigOptions &&
                (flattenedConfigOptions as MarketRunConfigOptions).allowMarketTypeSelection
            ) {
                includedRunConfigValues.markets = [];
            }

            // time periods don't apply to omni reports b/c they use a different format
            // todo: consider adding support multiple time period types
            if (includeTimePeriod) {
                includedRunConfigValues.timePeriod = defaultRunConfig?.timePeriod;
            }
            
            //TODO:the below condition needs to be discussed with Yong today
            if ((flattenedConfigOptions as ProductRunConfigOptions).includeFocusKPI) {              
                includedRunConfigValues.focusKPI = defaultRunConfig?.focusKPI ?? 'Dollar Sales'; //BYZ-12683
            }

            return includedRunConfigValues;
        },
        // todo: this method is almost same as getDefaultRunConfigBySku(sku), need to check if we can move common code
        // todo: change runconfig: any to runconfig: Partial<ReportRunConfig> after completion of legacy configs conversion to new structure
        getReportRunConfig(sku?: string | null, runConfig?: any): Partial<ReportRunConfig> {
            const includedRunConfigValues: Partial<ReportRunConfig> = {
                brands: [],
                categories: [],
                characteristics: [],
                focusBrands: [],
                omniFocusProducts: [],
                markets: [],
                subMarkets: [],
                productDimensions: [],
                subcategories: [],
                demographicDimensions: [],
                demographics: [],
                themeAttribute: [],
                salesThresholds: [],
                growthThresholds: [],
                ppgId: 0, //BYZ-12235;fix
                timePeriod: undefined
            };

            if (!sku || runConfig?.length === 0) return includedRunConfigValues;

            const flattenedConfigOptions = getFlattenedRunConfigOptionsBySku(sku);

            const runConfigOptions = getRunConfigOptionsBySku(sku);

            const hasMarketRunConfigOptions = Boolean((runConfigOptions as RunConfigOptions[])?.find(({ type }) => type === 'market'));
            
            const maxMarkets = (flattenedConfigOptions as MarketRunConfigOptions)?.maxMarkets ?? 0;

            includedRunConfigValues.datatype = flattenedConfigOptions.datatype; // todo: check to see if this actually needed. keeping for now

            if ((flattenedConfigOptions as ProductRunConfigOptions).includeBrands) {
                includedRunConfigValues.brands = runConfig?.brands;
            }
            if ((flattenedConfigOptions as ProductRunConfigOptions).includeCategories) {
                includedRunConfigValues.categories = runConfig?.categories;
            }
            if ((flattenedConfigOptions as ProductRunConfigOptions).includeCharacteristics) {
                includedRunConfigValues.characteristics = runConfig?.characteristics;
            }
            if ((flattenedConfigOptions as ProductRunConfigOptions).includeFocusBrands) {
                includedRunConfigValues.focusBrands = runConfig?.focusBrands;
            }
            if ((flattenedConfigOptions as ProductRunConfigOptions).includeCharacteristicDimensions) {
                includedRunConfigValues.productDimensions = runConfig?.productDimensions;
            }
            if ((flattenedConfigOptions as ProductRunConfigOptions).includeGrowthThresholds) {
                includedRunConfigValues.growthThresholds = runConfig?.growthThresholds;
            }
            if ((flattenedConfigOptions as ProductRunConfigOptions).includeSalesThresholds) {
                includedRunConfigValues.salesThresholds = runConfig?.salesThresholds;
            }
            if ((flattenedConfigOptions as ProductRunConfigOptions).includeDemographicDimensions) {
                includedRunConfigValues.demographicDimensions = runConfig?.demographicDimensions;
            }
            if ((flattenedConfigOptions as ProductRunConfigOptions).includeDemographics) {
                includedRunConfigValues.demographics = runConfig?.demographics;
            }
            if ((flattenedConfigOptions as ProductRunConfigOptions).includePPGs) {
                includedRunConfigValues.ppgId = runConfig?.ppgId;
            }
            // markets only apply to rms reports for now.
            // todo: consider adding support different market types
            if (
                hasMarketRunConfigOptions &&
                flattenedConfigOptions.datatype === 'rms' &&
                !(flattenedConfigOptions as MarketRunConfigOptions).requireRemainingMarket && 
                maxMarkets >= runConfig?.markets?.length
            ) {
                includedRunConfigValues.markets = runConfig?.markets;
            }
            
             // fix for BYZ-12296, If data types are different timeperiod value should not be retained from previous report run
            if (flattenedConfigOptions.datatype === runConfig?.datatype) {
                includedRunConfigValues.timePeriod = runConfig?.timePeriod;
            }

            //TODO:the below condition needs to be discussed with Yong today
            if ((flattenedConfigOptions as ProductRunConfigOptions).includeFocusKPI) {              
                includedRunConfigValues.focusKPI = runConfig?.focusKPI
            }

            return includedRunConfigValues;
        },
        // todo: improve validation and actually store the bad values so we can display them to user
        validateRunConfigForSku(
            sku: string | undefined,
            runConfig: ReportRunConfig
        ): boolean | ReportRunValidationError[] {
            if (!sku) return false;

            const { datatype, ...allRunConfigOptions } = getFlattenedRunConfigOptionsBySku(sku);

            const runConfigOptions = getRunConfigOptionsBySku(sku);

            const marketRequired = Boolean((runConfigOptions as RunConfigOptions[])?.find(({ type }) => type === 'market'));

            const productOptions = allRunConfigOptions as ProductRunConfigOptions;
            const marketOptions = allRunConfigOptions as MarketRunConfigOptions;
            let hasAllOmniValues = true;
            if (datatype === 'omni') {
                hasAllOmniValues = Boolean(
                    (!productOptions.requireOmniCategories || runConfig.categories?.length) &&
                        (!productOptions.requireOmniProducts || runConfig.omniFocusProducts?.length) &&
                        (!marketOptions.requireOmniSubMarkets || runConfig.subMarkets?.length)
                );
            }

            const hasMarkets = Boolean(runConfig.markets?.length || runConfig.comparisonMarket?.channel?.length) && runConfig.markets?.every((item)=>!item?.disabledMarketNode);
            const hasAllRequiredValues = Boolean(
                    (!productOptions.requireBrands || ((productOptions.minBrands && runConfig.brands?.length! >= productOptions.minBrands) || (!productOptions.minBrands && runConfig.brands?.length))) &&
                    (!productOptions.requireCategories || runConfig.categories?.length) &&
                    (!productOptions.requireCharacteristicDimensions || runConfig.productDimensions?.length) &&
                    (!productOptions.requireCharacteristics || runConfig.characteristics?.length) &&
                    (!productOptions.requireDemographics || runConfig.demographics?.length) &&
                    (!productOptions.requireDemographicDimensions || runConfig.demographicDimensions?.length) &&
                    (!productOptions.requireFocusBrands || ((productOptions.minFocusBrands && runConfig.focusBrands?.length! >= productOptions.minFocusBrands) || (!productOptions.minFocusBrands && runConfig.focusBrands?.length))) &&
                    (!productOptions.requireGrowthThresholds || runConfig.growthThresholds?.length) &&
                    (!productOptions.requireKeyCharacteristics || runConfig.characteristics?.length) &&
                    (!productOptions.requireProductSubcategory || runConfig.subcategories?.length) &&
                    (!productOptions.requireSalesThresholds || runConfig.salesThresholds?.length) &&
                    (!productOptions.requirePPGs || runConfig.ppgId)
            );

            return hasAllRequiredValues && hasAllOmniValues && (hasMarkets || !marketRequired);
        },
    };
};
