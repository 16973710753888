import { ByzzerTipIcon } from "@byzzer/ui-components";
import classnames from "classnames";


// TODO: Create separate CSS file for this component to obviate the need for the baseclassname prop

export function CategoryIntersectionOption({children, ...props}: any, baseClassName: string) {
    const metadata = props?.data?.data;
    const {
        categoryMatch,
        aggregationlevel,
        categories,
        unlicensedCategoriesCount,
    } = metadata;
    const isMatch = Boolean(categoryMatch?.length);
    return (aggregationlevel === 'total_store' ? <>{children}</> : (<>
        <div className={classnames(`${baseClassName}__indicator-container`)}>
            <span className={classnames({
                [`${baseClassName}--match-result-is-match`]: isMatch,
                [`${baseClassName}--match-result-no-match`]: !isMatch
            })}/>
        </div>
        <div className={classnames(`${baseClassName}__label-wrapper`)}>
            {children} 
            {aggregationlevel !== 'category' && Boolean(categoryMatch?.length) && (
                <ByzzerTipIcon 
                    tip={<>
                        You've licensed {categories?.length} out of {categories?.length + unlicensedCategoriesCount} category(s) within this {aggregationlevel.replace("_","")}: {categories?.map((cat) => <p key={cat}>- {cat}</p>)} 
                    </>}
                />
            )}
        </div>
    </>))
}

export default CategoryIntersectionOption;

CategoryIntersectionOption.displayName = 'CategoryIntersectionOption';