import classnames from "classnames";
import {TipIcon} from "@/components/icons";
import {SortToggle} from '@/components/SortToggle/SortToggle'
import React from "react";

export function ScorecardTableHeader({
                                         onTitleClick,
                                         onSortClick,
                                         sortActive,
                                         sortDirection,
                                         children,
                                         lever,
                                         tipTitle = lever,
                                         tip,
                                         baseClass,
                                         tipComponent,
                                         ...props
                                     }) {
    const baseClassName = baseClass ?? 'scorecard';

    return <th className={`${baseClassName}-table-header`} {...props}>
        <div className={`${baseClassName}-table-header__wrapper`}>
            <div className={classnames(`${baseClassName}-table-header__content`, {
                [`${baseClassName}-table-header__content--clickable`]: Boolean(onTitleClick)
            })} onClick={onTitleClick}>{children}</div>
            <div className={`${baseClassName}-table-header__controls`}>
                {!tip && tipComponent}
                {tip && (
                    <TipIcon theme={'dark'}
                             tipMode={'popup'}
                             allowDefaultEvents={false}
                             trackClick={`Brand Score Scorecards Info ${tipTitle} clicked`}>
                        {tipTitle && <div className={`${baseClassName}-table-header__tip-title`}>{tipTitle}</div>}
                        <div className={`${baseClassName}-table-header__tip`}>{tip}</div>
                    </TipIcon>
                )}
                <SortToggle active={sortActive} direction={sortDirection} onSort={onSortClick}/>
            </div>
        </div>
    </th>
}
