import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ByzzerButton, ByzzerCheckbox } from '@byzzer/ui-components';
import { useUser } from '@/contexts/UserContext';
import { focusBrandMaxLimit } from '@/config/userPrefs.config';
import { ByzzerBrandSearch } from '../ByzzerBrandSearch';
import "./BrandInfo.scss";
import {useTenantApi} from '@/hooks/useTenantApi';
import classnames from 'classnames';
import { ByzzerChip } from '@/components/ByzzerChip';
import { ByzzerMask } from '../ByzzerMask/ByzzerMask';

type FocusBrandType = {
    focusBrand: string[];
}

const baseClassName = 'brand-info';



const BrandInfo = () => {
    const { updateTenantCompanyBrandInfo } = useTenantApi();
    const navigate = useNavigate();
    const { user, categories, brands, brandNotRetailingYet, refreshCompanyBrandInfo } = useUser();
    const disableEditBRand = user?.role !== "admin";

    const [isAdmin, setIsAdmin] = useState<boolean>();
    const [editMode, setEditMode] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [selectedBrands, setSelectedBrands] = useState<string[]>(brands ?? []);
    const [isBrandNotSelling, setIsBrandNotSelling] = useState<boolean>(brandNotRetailingYet ?? false);

    useEffect(() => {
        const { configJson } = user?.settings || {};
        const isAdmin = user?.role === 'admin';
        setIsAdmin(isAdmin)
        setSelectedBrands(brands ?? []);
        setIsBrandNotSelling(brandNotRetailingYet ?? false);
    }, [brands, brandNotRetailingYet]);

    const redirectToPreference = () => {
        navigate('/dashboard/user_profile/preferences')
    }

    const handleBrandSave = async () => {
        setIsLoading(true);
        try {
            // const { settings: {brandNotRetailingYet: updatedBrandNotRetailingYet} } = await updateTenantCompanySettings<boolean>('brandNotRetailingYet', isBrandNotSelling);
            // const { brands: updatedBrands } = await updateTenantBrands(isBrandNotSelling ? [] : selectedBrands);
            const {
                brands: updatedBrands,
                brandNotRetailingYet: updatedBrandNotRetailingYet
            } = await updateTenantCompanyBrandInfo({
                brands: isBrandNotSelling ? [] : selectedBrands,
                brandNotRetailingYet: isBrandNotSelling
            });
            
            refreshCompanyBrandInfo({
                brands: updatedBrands, 
                brandNotRetailingYet: updatedBrandNotRetailingYet
            });
            setEditMode(false);
            setIsLoading(false);
        } catch (err) {
            // Todo: Add error handling
            console.error(err);
            setEditMode(false);
            setIsLoading(false);
        }
    }

    const changePreferences = ({value}: ByzzerChangeEvent<string[]>) => {
        console.log("value: ", value);
        setSelectedBrands(value);
    }

    const renderBrandChips = (
        isBrandNotSelling ? <p>Your brand is not yet selling in retailers</p> :
        selectedBrands?.length ? selectedBrands?.map((brand, idx) => (
                <ByzzerChip className='' label={brand} key={`${brand}-${idx}`} />
            )) : <p>No Brands set for your company</p>
    )

    const BrandTags = () => (
        <div className={`${baseClassName}__summary-brands`}>
            <div className='brand-chips'>
                {renderBrandChips}
            </div>
            {isAdmin ? (
                <div className='edit_container' onClick={() => setEditMode(true)} >
                    <span className={classnames(`edit_btn`)} />
                    <span className={classnames(`edit_text`)}>Edit</span>
                </div>
            ) : null}
        </div>
    )

    const handleBrandSelling = (value) => {
        setIsBrandNotSelling(value.checked);
        if (value.checked) {
            setSelectedBrands([]);
        }
    }

    function handleCancelEditMode() {
        setIsBrandNotSelling(brandNotRetailingYet ?? false)
        setSelectedBrands(brands ?? [])
        setEditMode(false)
    }

    const EditBrandPreferences = () => (
        <div className={`${baseClassName}__summary-brands-edit`}>
            <div className='brand_not_selling_box'>
                <ByzzerCheckbox
                    name="brandNotSelling"
                    checked={isBrandNotSelling}
                    label="My brand isn't selling in retailers yet"
                    onChange={handleBrandSelling}
                />
            </div>
            <div className='brands-search-container'>
                <ByzzerBrandSearch
                    name={'focusBrands'}
                    allowSearchOfAllBrands={true}
                    value={selectedBrands}
                    onChange={changePreferences}
                    label={'Select Your Focus Brands'}
                    maxSelections={focusBrandMaxLimit}
                    disabled={isBrandNotSelling}
                />
            </div>
            <div className='brands-btns'>
                <ByzzerButton label="Cancel" type='negative' onClick={handleCancelEditMode} />
                <ByzzerButton label='Save' onClick={handleBrandSave} />
            </div>
        </div>
    )

    return (
        <div className={`${baseClassName}`}>
            <ByzzerMask show={isLoading} loading={isLoading}/>
            <div className={`${baseClassName}__heading`}>
                <div className={`${baseClassName}__heading-title`}>Subscription Brands</div>
            </div>
            <div className={`${baseClassName}__summary`}>
                <div className={`${baseClassName}__summary-description`}>
                    <p>Set the brands that belong to your company's portfolio so that Byzzer can tailor insights & updates to your brand.</p>
                    <p>These selections will not be used as your report defaults - those can be set in the <span className='hyperlink' onClick={redirectToPreference}>Preferences</span> page</p>
                </div>
                {
                    !isAdmin &&
                    <div className='contact_admin'>
                        <p>Contact your admin to make changes to subscription brands.</p>
                    </div>
                }
                {!editMode ? <BrandTags /> : <EditBrandPreferences />}
            </div>
        </div>
    );
}

export default BrandInfo;