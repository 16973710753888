import React from 'react';
import './ReportInformation.scss';

const ReportInformationSidePanel = ({ skuInformation }) => {
    return (
        <>
            <div className={'report-information__side__1'}>
                <span className={'report-information__side__1__header'}>Business Questions:</span>
                {skuInformation?.businessQuestions && skuInformation?.businessQuestions.map((val) => {
                    return <span key={val} className={'report-information__side__1__desc'}>{val}</span>;
                })}
            </div>
            <div className={'report-information__side__1'}>
                <span className={'report-information__side__1__header'}>Key Feature(s):</span>
                {skuInformation?.features && skuInformation?.features.map((val) => {
                    return <span key={val} className={'report-information__side__1__desc'}>{val}</span>;
                })}
            </div>
            <div className={'report-information__side__1'}>
                <span className={'report-information__side__1__header'}>Roles:</span>
                <span className={'report-information__side__1__desc'}>
                    {skuInformation?.roles && skuInformation?.roles.map((val, index) => {
                        if (index < skuInformation?.roles.length - 1) {
                            return val + ', ';
                        } else {
                            return val;
                        }
                    })}
                </span>
            </div>
            <div className={'report-information__side__1'}>
                <span className={'report-information__side__1__header'}>Recommended Cadence:</span>
                {<span className={'report-information__side__1__desc'}>{skuInformation?.cadence}</span>}
            </div>
        </>
    );
};
export default ReportInformationSidePanel;
