import React, {useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import './MyReportsPage.scss';
import DashboardContent from "@/components/dashboard/DashboardContent";
import {useUser} from "@/contexts/UserContext";
import useState from "react-usestateref";

export default function MyReportsPage() {

    const {downloadableProducts} = useUser();
    const [links, setLinks] = useState([]);

    useEffect(() => {
        if(downloadableProducts?.length) {
            setLinks([
                {
                    text: 'Subscription Reports',
                    url: '/dashboard/my_reports/subscription',
                },
                {
                    text: 'Ad Hoc Reports',
                    url: '/dashboard/my_reports/ad_hoc',
                },
                {
                    text: 'Canned Reports',
                    url: '/dashboard/my_reports/downloads',
                },
                {
                    text: 'Report History',
                    url: '/dashboard/my_reports/history',
                },
            ])
        } else {
            setLinks([
                {
                    text: 'Subscription Reports',
                    url: '/dashboard/my_reports/subscription',
                },
                {
                    text: 'Ad Hoc Reports',
                    url: '/dashboard/my_reports/ad_hoc',
                },
                {
                    text: 'Report History',
                    url: '/dashboard/my_reports/history',
                },
            ])
        }
    }, [downloadableProducts])

    return (
        <DashboardContent title={'My Reports'} links={links} className={'my-reports'}>
            <Outlet/>
        </DashboardContent>
    );
};
