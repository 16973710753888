import React, {createRef, ReactNode, useEffect, useId, useRef, useState} from 'react';
import {ByzzerRadioGroupContext, ByzzerRadioGroupContextValue, ByzzerRadioGroupProvider} from './ByzzerRadioGroupContext';
import {ByzzerChangeEventHandler} from "@byzzer/ui-components";


export type ByzzerRadioGroupProps<T extends string | number | boolean | undefined = string> = {
    name?: string;
    children?: ReactNode;
    onChange?: ByzzerChangeEventHandler<T | undefined>;
    value?: T;
};

export function ByzzerRadioGroup<ValueType extends string | number | boolean | undefined>(
    {
        children,
        onChange,
        name,
        value
    }: ByzzerRadioGroupProps<ValueType>) {

    // we have to create a reference to the onChange callback to prevent stale references in the context value
    const onChangeRef = useRef<ByzzerChangeEventHandler<ValueType | undefined> | undefined>(onChange);
    onChangeRef.current = onChange;

    const id = useId();
    const [contextValue, setContextValue] = useState<ByzzerRadioGroupContextValue<ValueType>>({
        id,
        value,
        setValue(value?: ValueType) {
            setContextValue((current) => ({
                ...current,
                value,
            }));
            onChangeRef.current?.({
                name,
                value,
            });
        },
        triggerChange(e) {
            onChangeRef.current?.({
                name,
                value: e.value,
            });
        },
    });

    useEffect(() => {
        setContextValue((contextValue: ByzzerRadioGroupContextValue<ValueType>) => {

        return {
            ...contextValue,
            value
        }
    });
    }, [value])

    return (
        <ByzzerRadioGroupProvider value={contextValue}>
            {children}
        </ByzzerRadioGroupProvider>
    );
}

ByzzerRadioGroup.displayName = 'ByzzerRadioGroup';
