export const productsFolderList = [
    {
        key: 1,
        title: 'Hierarchy',
        description:
            'The granularity of the data will be based on the selected fields. For example, if you want UPC-level data, make sure you add the UPC field to your selections.',
        folders: [
            {
                key: 1,
                folderSection: 'Hierarchy',
                name: 'Department',
            },
            {
                key: 2,
                folderSection: 'Hierarchy',
                name: 'Super Category',
            },
            {
                key: 3,
                folderSection: 'Hierarchy',
                name: 'Category',
            },
            {
                key: 4,
                folderSection: 'Hierarchy',
                name: 'Subcategory',
            },
            {
                key: 5,
                folderSection: 'Hierarchy',
                name: 'UPC',
            },
            {
                key: 6,
                folderSection: 'Hierarchy',
                name: 'Product Description',
            },
        ],
    },
    {
        key: 2,
        title: 'Brand & Manufacturer',
        description: 'Add Brand and Manufacturer detail to your run using the fields in this section.',
        folders: [
            {
                key: 1,
                folderSection: 'Brand & Manufacturer',
                name: 'Parent Company',
            },
            {
                key: 2,
                folderSection: 'Brand & Manufacturer',
                name: 'Manufacturer',
            },
            {
                key: 3,
                folderSection: 'Brand & Manufacturer',
                name: 'Brand',
            },
            // {
            //     key: 4,
            //     name: 'PPG',
            // },
        ],
    },
    {
        key: 3,
        title: 'Characteristics',
        description:
            'Add additional product details to your run by incorporating characteristics, such as Form or Flavor.',
        folders: [],
    },
    {
        key: 4,
        title: 'My Custom Characteristics',
        description:
            'Find your Custom Characteristics located here. Create a Custom Characteristic using the Custom Characteristic option in the middle panel.',
        folders: [],
    },
    {
        key: 5,
        title: 'My Saved Products',
        description:
            'Find your saved products in this section. You can add items to the My Saved Products section by clicking Save in the right-hand panel after making your product selections. This is great for quickly accessing your most frequently used products.',
        folders: [],
    },
];
export const marketsFolderList = [
    {
        key: 1,
        title: 'Market Type',
        folders: [
            {
                key: 1,
                name: 'All',
                restrictAccessLevel: [],
            },
            {
                key: 2,
                name: 'Channels',
                restrictAccessLevel: [],
            },
            {
                key: 3,
                name: 'Accounts',
                restrictAccessLevel: [3, 4],
            },
            {
                key: 4,
                name: 'Statelines',
                restrictAccessLevel: [],
            },
            {
                key: 5,
                name: 'Major Markets',
                restrictAccessLevel: [],
            },
            {
                key: 6,
                name: 'Regions',
                restrictAccessLevel: [],
            },
            {
                key: 7,
                name: 'Divisions',
                restrictAccessLevel: [],
            },
        ],
    },
    {
        key: 2,
        title: 'My Saved Markets',
        description:
            'Find your saved markets in this section. You can add items to the My Saved Markets section by clicking Save in the right-hand panel after making your market selections. This is great for quickly accessing your most frequently used markets.',
        folders: [],
    },
];

export const searchOnlyProdFolders = ['UPC', 'Brand', 'Parent Company', 'Manufacturer' /*'Product Description'*/];
export const nonCharFolders = [
    'Department',
    'Super Category',
    'Category',
    'Subcategory',
    'UPC',
    'Brand',
    'Parent Company',
    'Manufacturer',
    'PPG',
];
export const categoryWarningMessage = 'Please choose at least 1 category before proceeding.';
export const categoryNotRequiredItems = ['Department', 'Super Category', 'Category', 'My Saved Products'];
export const perPageResultsCount = 100;
export const resultsToFetchCount = 10000;
export const charsDirectoriesList = ['Characteristics' /*, 'My Custom Characteristics'*/];
export const factConditionsFactType = 'cngYa';
export const createRunButtonHoverMessage="As a viewer, you don't have access to create a run. Reach out to your account admin to upgrade your member type to User."


export const extractErrorStatusMsgs = {
    INVALID_INSERT_QUERY: "Insert Query failed. Reach out to the chat for help with this run.",
    INVALID_TABLE_QUERY: "Table Query failed. Reach out to the chat for help with this run.",
    INVALID_VIEW_QUERY: "View Query failed. Reach out to the chat for help with this run.",
    EXTRACT_NOT_FOUND: "Extract not found. Reach out to the chat for help with this run.",
    NO_VALID_MARKET: "No valid markets were included in your selections. This may be because you selected a market that you no longer have access to. Reach out to the chat for more help.",
    NO_VALID_CATEGORY: "No valid categories were included in your selections. This may be because you selected a category that you no longer license. Reach out to the chat for more help.",
    MANDATORY_INPUT_MISSING: "A required input is missing from your run. Check your selections to confirm you have Products, Markets, Time Periods, and Facts selected. Reach out to the chat for more help.",
    REQUEST_ABORTED: "Request Terminated. Reach out to the chat for help with this run."
}