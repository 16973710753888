import './StoriesPage.scss'
import React from 'react'
import {Outlet} from "react-router-dom";
import DashboardContent from "@/components/dashboard/DashboardContent";

const links = [{
	text: 'Library',
	url: '/dashboard/stories/library',
}, {
	text: 'History',
	url: '/dashboard/stories/history',
}];
export function StoriesPage() {

	return (
		<DashboardContent title={'Stories'} links={links} className={'stories'}>
			<Outlet/>
		</DashboardContent>
	)
}

export default StoriesPage;