import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ByzzerButton, ByzzerRadio, ByzzerSelect, ByzzerLink } from './form';
import { setSelectorAttributes } from '@/utils/reportSelectorUtil';
import { setStorySelectorAttributes } from '@/utils/storySelectorUtil';
import './SelectMarketType.scss';
import { useUser } from '@/contexts/UserContext';
import { switchMarketSelector } from '@/utils/reportSelectorUtil';
import { selectorStates, alertConstants } from '@/config/globalVars';

const SelectMarketType = (props) => {
    const { user, company } = useUser();
    const [comparisonTypeSelected, setComparisonTypeSelected] = useState('');
    const [comparisonTypeChannel, setComparisonTypeChannel] = useState('');
    const [comparisonTypeData, setComparisonTypeData] = useState([
        {
            title: 'across different retailers',
            selected: false,
            options: [
                'All',
                'Military',
                'Liquor',
                'Grocery',
                'Ecom',
                'Convenience',
                'Club',
                'Drug',
                'Dollar',
                'Mass',
                'Pet',
            ],
        },
        {
            title: 'across major markets (cities & their surrounding areas)',
            selected: false,
            options: ['All FMCG', 'Drug', 'Grocery', 'Convenience', 'Liquor'],
        },
        {
            title: 'across state lines',
            selected: false,
            options: ['All FMCG', 'Grocery', 'Convenience', 'Liquor'],
        },
        {
            title: 'across US regions',
            selected: false,
            options: ['All FMCG', 'Drug', 'Grocery', 'Convenience'],
        },
    ]);
    const [showUpdate, setShowUpdate] = useState(false);
    let fromBackOnce;

    useEffect(() => {
        if (['all alse', 'unknown', 'all_else'].indexOf(company?.accessLevel.toLowerCase()) >= 0) {
            setComparisonTypeData(comparisonTypeData.filter((item) => item.title !== 'across different retailers'));
        }
        fromBackOnce = props?.fromBack;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            props.productType === 'story' && fromBackOnce &&
            props.selectorState.marketOption === 'market:option2' &&
            props.selectorState?.marketPerformanceSelections?.focusMarket?.byMarketName?.markets !== undefined &&
            props.selectorState.marketPerformanceSelections?.focusMarket?.byMarketName?.markets?.length > 0
        ) {
                switchMarketSelector('market:option2', props, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },  [props.fromBack]);

    useEffect(() => {
        if(props.reportSelectors?.clearMarketsToWatch) {
            clearMarketsToWatch();
        }
    }, [props.reportSelectors?.clearMarketsToWatch]);

    useEffect(() => {
        if (
            (
                props?.subSelectors?.markets?.requireComparisonMarketType &&
                props?.reportSelectors?.comparisonMarketTypeSelection !== '' &&
                comparisonTypeSelected === ''
            ) ||
            (
                props?.productType === 'story' && 
                props.selectorState?.marketsToWatchSelection !== undefined && 
                Object.keys(props.selectorState?.marketsToWatchSelection).length !== 0
            )
        ) {
            initializeMarketTypeSelection();
        }
    }, [props?.reportSelectors?.comparisonMarketTypeSelection]);

    const handleSelectMarketType = (index) => {
        let data = comparisonTypeData;
        for (let i = 0; i < data.length; i++) {
            // const element = array[index];
            data[i].selected = false;
        }
        data[index].selected = true;
        setComparisonTypeSelected(data[index].title);
        setComparisonTypeData(data);
        setComparisonTypeChannel('');
        if (props?.subSelectors?.markets?.requireComparisonMarketType) {
            let tempMarketSelection = JSON.parse(JSON.stringify(props.reportSelectors));
            tempMarketSelection.comparisonMarketTypeSelection = '';
            props.submitComparisonTypeSelections?.(tempMarketSelection);
        }
        setShowUpdateButton();
    };

    const setShowUpdateButton = () => {
        setShowUpdate(true);
        let selectionsToSelState = JSON.parse(
            JSON.stringify(props.saveBtnType === alertConstants.alertButtonType ? props?.reportSelectors : props?.selectorState)
        );
        if (props?.productType === 'story') {
            selectionsToSelState.selectorsStatus[props.stepNo].status = selectorStates.pending;
            props.setSelectorsData({ selectorsStatus: selectionsToSelState.selectorsStatus });
            props.generateReport(selectionsToSelState);
        }
        if (props.saveBtnType === 'alertSelector') {
            if (selectionsToSelState?.selectorsStatus[props.stepNo].status === selectorStates.completed) {
                //TODO : remove commented code later
                // selectionsToSelState.selectorsStatus[props.stepNo].status = selectorStates.pending;
                // selectionsToSelState.activeCollapsePanel = [props.stepNo];
                props.submitComparisonTypeSelections?.(selectionsToSelState,true);
            }
        }
    };

    const clearMarketsToWatch = () => {
        let data = comparisonTypeData;
        data.forEach(e => e.selected = false);
        setComparisonTypeData(data);
        setComparisonTypeSelected('');
        setComparisonTypeChannel('');

        if (props.saveBtnType !== alertConstants.alertButtonType) {
            let tempMarketSelection = JSON.parse(JSON.stringify(props.reportSelectors));
            tempMarketSelection.comparisonMarketTypeSelection = '';
            tempMarketSelection.clearMarketsToWatch = false;
            props.submitComparisonTypeSelections?.(tempMarketSelection);
        }
    }

    const marketTypeSelectionSet = (channel) => {
        const marketType = {
            'across different retailers': 'Account',
            'across major markets (cities & their surrounding areas)': 'Major Market',
            'across state lines': 'Stateline',
            'across US regions': 'Region',
        }[comparisonTypeSelected];
        const marketLevel = {
            'across different retailers': 'Level 1',
            'across major markets (cities & their surrounding areas)': '',
            'across state lines': '',
            'across US regions': '',
        }[comparisonTypeSelected];
        let marketChannel = comparisonTypeChannel;
        // TODO: can be moved to parent component or backend
        if (channel !== undefined) {
            if (comparisonTypeSelected === 'across different retailers') {
                marketChannel = channel === 'All' ? 'Any' : channel;
            } else {
                marketChannel = channel === 'All FMCG' ? 'FMCG' : channel;
            }
        } else {
            if (comparisonTypeSelected === 'across different retailers') {
                marketChannel = comparisonTypeChannel === 'All' ? 'Any' : comparisonTypeChannel;
            } else {
                marketChannel = comparisonTypeChannel === 'All FMCG' ? 'FMCG' : comparisonTypeChannel;
            }
        }
        const marketTypeSet = {
            // For Option5
            marketType,
            marketLevel,
            channel: marketChannel,
        };
        return marketTypeSet;
    };

    const submitComparisonTypeSelections = () => {
        const marketData = {
            focusMarket: {
                byMarketType: marketTypeSelectionSet(),
            },
        };
        let currentSelectorStatusState = JSON.parse(JSON.stringify(props.selectorState.selectorsStatus));
        currentSelectorStatusState[props.stepNo].status = selectorStates.completed;
        let currentSelectorState  = { ...props.selectorState };

        currentSelectorState.selectorsStatus = currentSelectorStatusState;
        currentSelectorState.activeCollapsePanel = props.saveBtnType !== 'Generate' ? [props.stepNo + 1] : [];
        currentSelectorState.marketsToWatchSelection = marketData?.focusMarket?.byMarketType;
        currentSelectorState.marketSelectionsSummary = { byMarketType: { comparisonTypeChannel, comparisonTypeSelected } };
        currentSelectorState.marketOption = 'market:option5';
        // clear other market panel data to empty  
        currentSelectorState.marketPerformanceSelections = {};
        currentSelectorState.focusMarketsPerformanceSelectedData = [];
        if (props?.productType !== 'story')
            currentSelectorState.focusMarketSelections = marketData;
        props.setSelectorsData(currentSelectorState);
        props.generateReport(currentSelectorState);
    };


    const wrapperClasses = classNames('select-market-type');

    const updateComparisonTypeChannel = () => {

        const marketData = marketTypeSelectionSet(comparisonTypeChannel);

        let tempMarketSelection = JSON.parse(JSON.stringify(props.reportSelectors));
        tempMarketSelection.selectorsStatus[props.stepNo].status = selectorStates.completed;
        tempMarketSelection.activeCollapsePanel =
            props.saveBtnType !== 'Generate' || tempMarketSelection.selectorsStatus.length !== props.stepNo
                ? [props.stepNo + 1]
                : [];
        tempMarketSelection.comparisonMarketTypeSelection = marketData;
        // TODO: remove commented code later
        // tempMarketSelection.marketTypeUpdated = true;
        props.submitComparisonTypeSelections?.(tempMarketSelection);
    };

    const initializeMarketTypeSelection = () => {
        let marketTypeSelection;
        if (props?.productType === 'story') {
            marketTypeSelection = props.selectorState?.marketsToWatchSelection;
        } else {
            marketTypeSelection = props?.reportSelectors?.comparisonMarketTypeSelection;
        }

        const comparisonType = {
            'Account': 'across different retailers',
            'Major Market': 'across major markets (cities & their surrounding areas)',
            'Stateline': 'across state lines',
            'Region': 'across US regions',
        }[marketTypeSelection?.marketType];

        let comparisonMarketChannel = '';
        if (comparisonType === 'across different retailers') {
            comparisonMarketChannel = marketTypeSelection.channel === 'Any' ? 'All' : marketTypeSelection.channel;
        } else {
            comparisonMarketChannel = marketTypeSelection.channel === 'FMCG' ? 'All FMCG' : marketTypeSelection.channel;
        }
        setComparisonTypeSelected(comparisonType);
        setComparisonTypeChannel(comparisonMarketChannel);
        comparisonTypeData.forEach((value) => {
            if (value.title === comparisonType) {
                value.selected = true;
            }
        });
    };

    const btnLabel = () => {
        if ((props?.productType === 'story' || props?.saveBtnType === 'alertSelector') && props?.fromBack) {
            return showUpdate ? 'Update' : 'Next';
        } else if ((props?.productType === 'story' || props?.saveBtnType === 'alertSelector') && !props?.fromBack) {
            return 'Next';
        } else {
            return props.saveBtnType === 'Generate' ? 'Generate My Report' : 'Next';
        }
    }

    return (
        <div className={wrapperClasses}>
            <div className="select-market-type__intro">
                {!props?.subSelectors?.markets?.requireComparisonMarketType && (
                    <>
                        <div className="select-market-type__intro-question">
                            What type of comparison is right for you?
                        </div>
                        <div className="select-market-type__intro-message">
                            {`We will show you your top ${
                                props?.subSelectors?.selectorFunction === 'marketPerformance' ? 10 : 20
                            } markets based on the analysis type you select or`}
                            <span>
                                <ByzzerLink
                                    label="select a custom list of markets"
                                    onClick={() => switchMarketSelector('market:option2', props)}
                                />
                            </span>
                        </div>
                    </>
                )}
                {props?.subSelectors?.markets?.requireComparisonMarketType && (
                    <div className="select-market-type__intro-question">
                        Select the market type for the Markets to watch section of the alert
                    </div>
                )}
                <div>I want to compare my product performance…</div>
            </div>
            <div className="select-market-type__data">
                {comparisonTypeData.map((item, index) => {
                    return (
                        <div key={index}>
                            <ByzzerRadio
                                label={item.title}
                                checked={item.title === comparisonTypeSelected}
                                onChange={() => handleSelectMarketType(index)}
                            />
                            {item.selected ? (
                                <ByzzerSelect
                                    name={'name'}
                                    value={comparisonTypeChannel}
                                    onChange={(e) => {
                                        setComparisonTypeChannel(e);
                                        setShowUpdateButton();
                                    }}
                                    placeholder={'Select the channel from the list'}
                                    options={item.options}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    );
                })}
            </div>
            {!props?.fromBack && (
                <div className="select-market-type__continue-btn">
                    <ByzzerButton
                        disabled={comparisonTypeSelected !== '' && comparisonTypeChannel !== '' ? false : true}
                        onClick={
                            !props?.subSelectors?.markets?.requireComparisonMarketType
                                ? () => submitComparisonTypeSelections()
                                : () => updateComparisonTypeChannel()
                        }
                        label={btnLabel()}
                    />
                </div>
            )}

            {props?.fromBack && (
                <div className="select-market-type__continue-btn">
                    <ByzzerButton
                        disabled={
                            showUpdate
                                ? comparisonTypeSelected !== '' && comparisonTypeChannel !== ''
                                    ? false
                                    : true
                                : true
                        }
                        onClick={
                            !props?.subSelectors?.markets?.requireComparisonMarketType
                                ? () => submitComparisonTypeSelections()
                                : () => updateComparisonTypeChannel()
                        }
                        label={btnLabel()}
                    />
                </div>
            )}

        </div>
    );
};

export default SelectMarketType;
