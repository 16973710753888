import './SegmentRunConfigFilters.scss';
import React, {forwardRef, ReactNode, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import classnames from "classnames";
import {ReportRunConfig} from "@/types/ReportRun";
import {FilterGroup, FilterSection} from "@/components/ConfigurationEditors/FilterGroup";
import {DemographicDimensionSelect} from "@/components/DemographicDimensionSelect";
import {
    ReportRunConfigWizardContext,
    ReportRunWizardState
} from "@/components/ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigWizard/ReportRunConfigWizardContext";
import {DemographicCriteriaBuilder} from '@/components/DemographicCriteriaBuilder';
import {ByzzerRadio, ByzzerRadioGroup} from "@byzzer/ui-components";
import { SelectorLabelInfo } from '@/components/SelectorLabelInfo';

export type SegmentRunConfigFilterValue = Partial<ReportRunConfig>;

export type SegmentRunConfigFiltersProps = {
    name?: string;
    onChange?: (e: ByzzerChangeEvent<SegmentRunConfigFilterValue>) => void;
    onValidityChange?: (e: ByzzerValidityChangeEvent) => void;
    value?: SegmentRunConfigFilterValue;
    summary?: ReactNode;
    datatype?: string;
    includeDemographics?: boolean;
    includeDemographicDimensions?: boolean;
    requireDemographics?: boolean;
    requireDemographicDimensions?: boolean;
    maxDemographics?: number;
    maxDemographicDimensions?: number;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export type SegmentRunConfigFiltersRef = {
    value: SegmentRunConfigFiltersValue;
    isValid: boolean;
};

type SegmentRunConfigFiltersValue = Partial<ReportRunConfig>;

const baseClassName = 'segment-report-run-filters';

export const SegmentRunConfigFilters = forwardRef<SegmentRunConfigFiltersRef, SegmentRunConfigFiltersProps>((
    {
        className,
        onChange,
        onValidityChange,
        name,
        summary,
        includeDemographics,
        includeDemographicDimensions,
        requireDemographics,
        requireDemographicDimensions,
        maxDemographics,
        maxDemographicDimensions,
        ...props
    }, ref) => {
    const {value: contextValue, onChange: onContextChange, sku} = useContext(ReportRunConfigWizardContext);
    const [internalValue, setInternalValue] = useState<SegmentRunConfigFiltersValue>({});
    const [state, setState] = useState<ReportRunWizardState>({
        enableDemographics: false
    });
    const wasValid = useRef<boolean>(false);

    useEffect(() => {

        const isValid = checkValidity();

        if (wasValid.current !== isValid) {
            onValidityChange?.({
                name,
                isValid
            });

            wasValid.current = isValid;
        }
    }, [internalValue]);

    useEffect(() => {
        setInternalValue(contextValue ?? {})
    }, [contextValue]);

    function handleChange(e: ByzzerChangeEvent<unknown>) {
        onContextChange?.(
            e.name as keyof ReportRunConfig,
            e.value,
            e.data
        );
    }

    useImperativeHandle(ref, () => ({
        get value() {
            return internalValue;
        },
        get isValid() {
            return checkValidity()
        }
    }), [internalValue]);

    function checkValidity(): boolean {
        const demographicsAreValid = (
            !requireDemographics &&
            (internalValue.demographics?.filter((demographicCondition) => {
                return (
                    (!demographicCondition?.demographic?.length && !demographicCondition?.condition?.length && !demographicCondition?.value?.length) ||
                    (demographicCondition?.demographic?.length && demographicCondition?.condition?.length && demographicCondition?.value?.length)
                )
            })?.length === internalValue.demographics?.length)
        ) || (
            requireDemographics &&
            state.enableDemographics &&
            (internalValue.demographics?.filter((demographicCondition) => {
                return demographicCondition?.demographic?.length && demographicCondition?.condition?.length && demographicCondition?.value?.length
            })?.length === internalValue.demographics?.length && Number(internalValue.demographics?.length) > 0)
        )

        const demographicDimensionsAreValid = !requireDemographicDimensions || (
            requireDemographicDimensions &&
            Number(internalValue.demographicDimensions?.length) > 0
        )

        return Boolean(demographicsAreValid && demographicDimensionsAreValid);
    }

    function handleEnableDemographicsChange(e: ByzzerChangeEvent<boolean>) {

        if (!e.value) { 
            onContextChange?.('demographics', []); // Reset demographicfilter selections
        }

        setState(state => ({
            ...state,
            enableDemographics: e.value
        }))
    }


    return (
        <FilterGroup className={classnames(baseClassName, className)} {...props}>
            {summary && <div className={`${baseClassName}__summary`}>{summary}</div>}
            <FilterSection onlyRenderIf={includeDemographicDimensions}>
                <DemographicDimensionSelect
                    className={className}
                    name={'demographicDimensions'}
                    onChange={handleChange}
                    value={internalValue.demographicDimensions}
                    placeholder={'Select from the list'}
                    maxSelections={maxDemographicDimensions ?? 5} // todo check with product team to ensure config in alby is set for this.  temporarily keeping 5 until alby is final
                    onlyRenderIf={includeDemographicDimensions}
                    required={requireDemographicDimensions}
                    sku={sku}
                />
            </FilterSection>
            <FilterSection onlyRenderIf={includeDemographics}>
                <SelectorLabelInfo sku={sku!} selectorCode={'demographicDimensionsMessage'} max={maxDemographics} />

                <ByzzerRadioGroup
                    value={state.enableDemographics}
                    onChange={handleEnableDemographicsChange}
                    name={'enableDemographics'}
                >
                    <ByzzerRadio
                        value={false}
                        label={
                            <SelectorLabelInfo
                                sku={sku!}
                                selectorCode={'enableDemographicsNo'}
                                isLabelBold={true}
                                max={maxDemographics}
                            />
                        }
                    />
                    <ByzzerRadio
                        value={true}
                        label={
                            <SelectorLabelInfo
                                sku={sku!}
                                selectorCode={'enableDemographicsYes'}
                                isLabelBold={true}
                                max={maxDemographics}
                            />
                        }
                    />
                </ByzzerRadioGroup>
                <DemographicCriteriaBuilder
                    // className={className}
                    name={'demographics'}
                    onChange={handleChange}
                    value={internalValue.demographics}
                    placeholder={'Select from the list'}
                    searchableFields={['ZIP_CODE']}
                    maxConditions={maxDemographics ?? 5} // todo check with product team to ensure config in alby is set for this.  temporarily keeping 5 until alby is final
                    onlyRenderIf={state.enableDemographics}
                />
            </FilterSection>
        </FilterGroup>
    );

})

SegmentRunConfigFilters.displayName = 'SegmentRunConfigFilters'; // original change to match release
