import './StaticReportViewer.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { openErrorModal } from '@/components/form/ByzzerModal';
import {useTenantApi} from '@/hooks/useTenantApi';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';

export function StaticReportViewer() {
    const baseClassName = 'static-report-viewer';
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [embedUrl, setEmbedUrl] = useState<string | undefined>('');
    const { reportName } = useParams();
    const { getReportLinkByName } = useTenantApi();
    useEffect(() => {
        getProductReport(reportName);
        document.title = getTitleForReportName(reportName);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportName]);

    const getTitleForReportName = (reportName) => {
        let titleName: string = 'Static Report Viewer';
        titleName = {
            'characteristic-explorer-for-client' : 'Characteristic Explorer',
            'characteristic-explorer-for-niq' : 'Characteristic Explorer - Internal',
            'item_coding_explorer' : 'Item Coding Explorer',
        }[reportName];
        return titleName;
    }

    const getProductReport = async (reportName: string | undefined) => {
        try {
            setLoading(true);
            setEmbedUrl('');
            const { url } = await getReportLinkByName(reportName);
            //Sanity check.  Success message but empty url
            if (!url?.length) {
                await openErrorModal({
                    title: `Report Error`,
                    content: (
                        <>
                            <p>We were unable to generate the product list at this time.</p>
                            <p>Please try again later or contact customer support.</p>
                        </>
                    ),
                });
                navigate(-1);
                return;
            }
            setEmbedUrl(url);

            // Adding delay to reset loader to handle the gap of looker embed iframe render
            setTimeout(() => setLoading(false), 2500);
        } catch (err) {
            // @ts-ignore
            alert(err?.message);
            navigate(-1);
            setLoading(false);
        }
    };

    return (
        <div className={`${baseClassName}__page-container`}>
            <ByzzerMask show={loading} loading={loading} />
            {embedUrl && (
                <iframe
                    src={embedUrl}
                    className={`${baseClassName}__looker-report`}
                    title="Product View Embed"
                ></iframe>
            )}
        </div>
    );
}

export default StaticReportViewer;