import React, {createContext, createRef, ReactNode, RefObject, useContext, useMemo, useRef, useState} from "react";

export type ExpandablePanelContextValue = {
    activePanel?: string;
    toggleActivePanel(panel: string): void;
    registerContainer(id: string | undefined, ref: RefObject<HTMLDivElement>): void;
    getContainer(id?: string): Element;
};
export const ExpandablePanelContext = createContext<ExpandablePanelContextValue>({} as ExpandablePanelContextValue);
export const useExpandablePanels = () => useContext(ExpandablePanelContext);

type ExpandablePanelProviderProps = {
    children: ReactNode;
}
export function ExpandablePanelProvider({children}: ExpandablePanelProviderProps) {

    const [activePanel, setActivePanel] = useState<string>();
    const containers = useRef<Record<string, RefObject<HTMLDivElement>>>({})

    const contextValue = useMemo<ExpandablePanelContextValue>(() => ({
        toggleActivePanel(panel: string): void {
            setActivePanel(activePanel => activePanel !== panel ? panel : undefined);
        },
        registerContainer(name: string = 'default', ref: React.RefObject<HTMLDivElement>) {
            containers.current[name] = ref;
        },
        getContainer(name: string = 'default'): Element {
            return containers.current[name]?.current ?? document.body;
        },
        activePanel
    }), [activePanel]);

    return <ExpandablePanelContext.Provider value={contextValue}>
        {children}
    </ExpandablePanelContext.Provider>
}
