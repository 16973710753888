import './DimensionRunConfigFilters.scss';
import React, {ReactNode, useContext} from "react";
import classnames from "classnames";
import {ReportRunConfig} from "@/types/ReportRun";
import { SelectorLabelInfo } from '@/components/SelectorLabelInfo';
import { ReportRunConfigWizardContext } from '../../ReportRunConfigWizard/ReportRunConfigWizardContext';

export type DimensionRunConfigFilterValue = Partial<ReportRunConfig>;

export type DimensionRunConfigFiltersProps = {
    name?: string;
    onChange?: (e: ByzzerChangeEvent<DimensionRunConfigFilterValue>) => void;
    onValidityChange?: (e: ByzzerValidityChangeEvent) => void;
    value?: DimensionRunConfigFilterValue;
    summary?: ReactNode;
    datatype?: string;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'dimension-report-run-filters';

export function DimensionRunConfigFilters({className, onChange, ...props}: DimensionRunConfigFiltersProps) {
    const { sku } = useContext( ReportRunConfigWizardContext );
    return <div className={classnames(baseClassName, className)} {...props}>       
        <SelectorLabelInfo sku={sku as string} selectorCode={'dimensionRunFilter'} isLabelBold={true} />
    </div>
}

export default DimensionRunConfigFilters;
