import React, { useState } from "react";
import { create as createModal } from "react-modal-promise";
import ByzzerModal2 from "@/components/modals/ByzzerModal2";

export function TeamRoleInfo({ onResolve, ...props }) {
    const [loading, setLoading] = useState(false);
    function onCloseClick() {
        onResolve({ function: "close" });
    }

    return (
        <ByzzerModal2 wrapperClassName='info-modal' className="subscription-users__modal-info">
            <ByzzerModal2.Header className="subscription-users__modal-title" onClose={onCloseClick}>Member Types</ByzzerModal2.Header>
            <ByzzerModal2.Content className="subscription-users__modal-content-info">
                <ByzzerModal2.Mask show={loading}>{loading.message}</ByzzerModal2.Mask>
                <div className='subscription-users__data-contents-table-info'>
                    The Member Type will set a team member’s permissions in the product.
                </div>
                <div className='subscription-users__data-contents-table-info'>
                    <span className='subscription-users__data-contents-table-info-title'>
                        Admin:
                    </span>
                    &nbsp;
                    can edit account information, view usage, run reports, and view reports run by other team members
                </div>
                <div className='subscription-users__data-contents-table-info'>
                    <span className='subscription-users__data-contents-table-info-title'>
                        User:
                    </span>
                    &nbsp;
                    can run reports and view reports run by other team members
                </div>
                <div className='subscription-users__data-contents-table-info'>
                    <span className='subscription-users__data-contents-table-info-title'>
                        Viewer:
                    </span>
                    &nbsp;
                    can view reports run by other team members
                </div>
            </ByzzerModal2.Content>
        </ByzzerModal2>
    );
}

export const openTeamRoleInfo = createModal(TeamRoleInfo);