import './ProducTours.scss';
import React, {useEffect, useState} from 'react';
import {createModal} from "react-modal-promise";
import {ByzzerButton, ByzzerModal} from "@/components/form";
import {useIntercom} from "react-use-intercom";
import {useTenantApi} from "@/hooks/useTenantApi";
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@/components/Spinner/Spinner';
import {isEmpty} from "lodash";

let cachedTours = [];
const baseClassName = 'product-tours';

function ProductTours({isOpen, onResolve, ...props}) {
    const {productTourDataFetch} = useTenantApi();

    const navigate = useNavigate();
    const {startTour} = useIntercom();
    const [spin, setSpin] = useState(false);
    const [tours, setTours] = useState(cachedTours);
    const [show, setShow] = useState(isOpen);
    useEffect(() => {
        (async () => {
            try{
                if (isEmpty(tours)) {
                    setSpin(true)
                    cachedTours = await productTourDataFetch();
                    setTours(cachedTours)
                }
            }finally{
                setSpin(false)
            }
        })();
    }, []);
    return (
        <ByzzerModal
            show={isOpen}
            onClose={onResolve}
            size={'medium'}
            type={'info'}
            headerType={'none'}
            className={`${baseClassName}`}
        >
            <div className={`${baseClassName}_modal_container`}>
                {isEmpty(tours) && spin && (
                    <div className={`${baseClassName}_modal_container__spinner`}>
                        <Spinner/>
                    </div>
                )}
                {isEmpty(tours) && !spin && (
                    <div>No tours to display</div>
                )}
                {!isEmpty(tours) && !spin &&  (<>
                    <h1 className={`${baseClassName}_modal_container__title`}>Launch a Product Tour</h1>
                    <p className={`${baseClassName}_modal_container__text`}>
                        Product Tours walk you through different features and use cases within Byzzer. If you're
                        looking for directions on something that isn't listed, let us know through the Chat!
                    </p>
                </>)}
                <table className={`${baseClassName}-table_content`}>
                    <tbody>
                    {tours?.map((tour, index) => (
                        <tr className={`${baseClassName}-table_content__row`} key={index}>
                            <td className={`${baseClassName}-table_content__row_title`}>{tour?.title}</td>
                            <td className={`${baseClassName}-table_content__row_text`}>{tour?.detail}</td>
                            <td className={`${baseClassName}-table_content__row_time`}>{tour?.estimate}</td>
                            <td>
                                <div className="RunNow-button">
                                    <ByzzerButton
                                        onClick={() => {
                                            navigate(tour?.link);
                                            startTour(tour?.tourId);
                                            setShow(!show);
                                        }}
                                    >
                                        Run now
                                    </ByzzerButton>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </ByzzerModal>
    );
}

export const openProductTours = createModal(ProductTours);
