import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './MarketStep.scss';
import { ByzzerButton, StepRef, WithUid, WizardActions, WizardContent, WizardHeader, WizardStep } from "@byzzer/ui-components";
import MarketFilters, { MarketFiltersProps } from "./MarketFilters";

export type MarketStepProps = {
    title?: ReactNode;
    onComplete?: () => void;
    onCancel?: () => void;
    enabled?: boolean;
} & MarketFiltersProps

export const MarketStep = forwardRef<StepRef, MarketStepProps>((
    {
        className,
        title,
        onComplete,
        enabled,
        onCancel,
        ...props
    },
    ref
) => {

    const baseClassName = 'byz-product-step';

    function handleCancelClick() {
        onCancel?.();
    }

    function handleComplete(): boolean {
        onComplete?.();
        return false;
    }

    return (
        <WizardStep 
            className={classnames(baseClassName, className)} 
            ref={ref}
            title={title}
            enabled
        >
            <WizardHeader>
                <h1 className={'report-run-config-wizard__step-title'}>{title}</h1>
            </WizardHeader>
            <WizardContent>
                <MarketFilters 
                    {...props}
                />
            </WizardContent>
            <WizardActions
                nextText="Save"
                beforeNext={handleComplete}
            >
                <ByzzerButton onClick={handleCancelClick} type={'negative'}>Cancel</ByzzerButton>
            </WizardActions>
        </WizardStep>
    )

});

export default MarketStep;

MarketStep.displayName = 'MarketStep';