import { forwardRef, ReactNode, useEffect, useState } from 'react';
import classnames from "classnames";
import {
    ByzzerChangeEventHandler,
    ByzzerSelect,
    WithValue,
} from '@byzzer/ui-components';
import {useApp} from '@/contexts/UserContext';

const baseClassName = 'byz-omni-category-select';

export type ByzzerOmniCategorySelectProps = {
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<string[]>;
    value?: string[];
    disabledOptions?: string[];
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    maxSelections?: number;
} & OnlyRenderableIf;

export const OmniCategorySelect = forwardRef<WithValue<string[]>, ByzzerOmniCategorySelectProps>( (
{
    onChange,
    name,
    label,
    className,
    disabledOptions = [],
    placeholder,
    value,
    disabled,
    onlyRenderIf,
    maxSelections,
}, ref
) => {
        const [internalValue, setInternalValue] = useState<string[]>();
        if (onlyRenderIf === false) return <></>;

        const { omniCategories }  = useApp();

        useEffect(() => {
            setInternalValue(value)
        }, [value])

        return (
                <ByzzerSelect
                    ref={ref}
                    name={name}
                    className={classnames(baseClassName, className)}
                    options={omniCategories}
                    disabled={disabled}
                    placeholder={placeholder}
                    label={label as any}
                    value={internalValue}
                    disabledOptions={disabledOptions}
                    onChange={onChange}
                    allowMultiple={true}
                    maxSelections={maxSelections}
                />
        );
    }
);

export default OmniCategorySelect;

OmniCategorySelect.displayName = 'OmniCategorySelect';