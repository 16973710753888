import { RunConfigMarket, ComparisonMarket, CharacteristicCondition, ProductSelection, LegacyCharacteristicFilter, LegacyProductSelection } from "./ReportRun";

export const ALERT_RELATIVE_TIME_PERIODS = ['Latest 1 Week', 'Latest 4 Weeks', 'Latest 13 Weeks'] as const;
export type AlertRelativePeriod = typeof ALERT_RELATIVE_TIME_PERIODS[number];

export type AlertRelativeTimePeriod = {
    type: 'relative';    
    period?: AlertRelativePeriod;
};

export type AlertTimePeriod = AlertRelativeTimePeriod;

export type AlertRunConfig = { // this should be the totality of 'include[XYZ]' options in Alby
    markets: RunConfigMarket[];
    marketsToWatch?: ComparisonMarket;
    characteristics: CharacteristicCondition[];
    categoryAlias: string;
    notification: AlertRecipients,
    productSelections: ProductSelection[];
    focusBrands: string[];
    categories: string[];
    timePeriod: AlertTimePeriod;
}

export type LastAlertExecutionDetails = {
    message?: string;
    error?: string;
    timestamp?: Date;
    status?: boolean;
};
export type AlertRecipientType = 'just-me' | 'my-team' | 'everyone' | 'selected-member' | '';
export type AlertRecipientLabelTipCode = 'alertRecipientJustMe' | 'alertRecipientMyTeam' | 'alertRecipientEveryOne' | 'alertRecipientSelectedMember' ;

export type AlertRecipients = {
    recipientType: AlertRecipientType;
    recipientEmails: string[];
    alertRecipientLabelTipCode?: AlertRecipientLabelTipCode;
}

export type LegacyAlertRunConfig = {
    categoryAlias: string;
    characteristics?: LegacyCharacteristicFilter[];
    comparisonMarketKeys: string[] // e.g. ['78223', '78223']
    comparisonMarkets: string[]; // e.g. ['BJs Total Competitive Grocery Stores', 'BJs Total Competitive Grocery Stores']
    focusMarketKeys: string[]; // e.g. ['78209', '78209', '78223', '78223']
    focusMarketsSelectedData: MrktDimSelector;
    isMarketValidFormItems?: any; // e.g. {BJs Total: true}
    marketSelections: string[] // e.g. ["BJs Total Competitive Grocery Stores", "BJs Total"]
    marketsToWatch?: ComparisonMarket // e.g. {}
    notificationEmail: string[]; // Sometimes there's an object with a property of email, prob old/bad data and prob only in lower env's.  e.g. ["user1@testco20079.qa.byzzer.com", "paramjeet.nigam@quantiphi.com", "siddhi.bhosle@quantiphi.com"]
    notificationType: string;
    productSelections: LegacyProductSelection[]; // e.g. [{brand: "STARBUCKS KEURIG (NESTLE BEVERAGE CO.)", category: ["COFFEE"]}], will begin setting brand as array, legacy is string
    selectedItems: {
        marketKey: string; // e.g. "78223"
        marketSelType: string; // e.g. "By Channel FMCG"
        marketType: string; // e.g. "channel"
        name: string; // e.g. "BJs Total Competitive Grocery Stores$$78223$$78209"
    }[];
    characteristicFilters?: LegacyCharacteristicFilter[];
    marketsToWatchSelection?: ComparisonMarket // e.g. {}
    mainMarkets?: string[];
    mainMarketKeys?: string[];
    isRefactoredAlert?: boolean;
    lastExecutionDetails?: LastAlertExecutionDetails;
}

export type UserAlertSummary = { // from /api/me/alerts...
    companyId?: number;
    config: LegacyAlertRunConfig;
    refactoredConfig?: AlertRunConfig;
    id: number;
    nsUserId?: number;
    productSku?: string; // e.g. "454"
    status?: 'invalid' | 'valid' | null; // e.g. "valid"
    user?: any; // todo: bring in NsUser
    recipients?: any[]; // todo: bring in the type
    alertProduct?: ProductWithMeta;
    updatedDtm?: Date;
    lastExecutionStatus?: 'complete' | 'failed' | undefined;
}
