import React, { useContext } from 'react';
const baseClassName = 'home-page';
import { Spinner } from '@/components/Spinner/Spinner';
import { HomepageContext, useHomePageContext } from '@/contexts/HomepageContext';
import { isEmpty } from 'lodash';
import { VisualizationTile } from './VisualizationTile';

export const TileContent = React.memo(() => {
    const { isContextLoading } = useContext(HomepageContext);
    const { tiles } = useHomePageContext() ?? {};

    function loadingTiles() {
        return (
            <>
                {[1, 2, 3, 4].map((ele, index) => (
                    <div className={`viz-tile ${baseClassName}__tile`} key={'loading-' + index}>
                        <div className={`${baseClassName}__spinner`} key={index}>
                            {/* <Spinner className={`${baseClassName}__spinner-item`} /> */}
                            <Spinner />
                        </div>
                    </div>
                ))}
            </>
        );
    }

    function defaultTiles() {
        return (
            <>
                {[1, 2, 3, 4].map((ele, index) => (
                    <div className={`${baseClassName}__tile`} key={`tile__${ele}`}>
                        <div key={index} className={`${baseClassName}__default_content`}></div>
                    </div>
                ))}
            </>
        );
    }

    return (
        <>
            <div className={`${baseClassName}__tiles-container`}>
                {isContextLoading
                    ? loadingTiles()
                    : isEmpty(tiles)
                    ? defaultTiles()
                    : tiles.map((tile, index) => (
                              <VisualizationTile key={index} tile={tile} className={`${baseClassName}__tile`} />
                          ))}
            </div>
        </>
    );
});

export default TileContent;
