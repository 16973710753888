import './ByzzerProgressBar.scss';
import { FC } from 'react';
import { Line } from 'rc-progress';
import { useUser } from '@/contexts/UserContext';

export type ByzzerProgressBarProps = {
    className: string;
    title: string;
    currentProgress: number;
    total: number;
    highThreshold: number;
    lowThreshold: number;
};

enum Color {
    Green = '#008a28',
    Red = '#aa213f',
    Yellow = '#ffb500',
    Background = '#D9D9D9'
}

export const ByzzerProgressBar: FC<ByzzerProgressBarProps> = ({
    className,
    title,
    currentProgress,
    total,
    highThreshold,
    lowThreshold,
    ...props
}) => {
    const { subscription } = useUser();
    // Calculate remaining progress
    const remainingProgress = total - currentProgress;
    // Calculate percentage progress
    const progressPercent = (1 - remainingProgress / total) * 100;

    const isSubscriptionInactive = !subscription?.active;

    const barColor = (): string => {
        if (progressPercent >= 100) return Color.Background;

        if (progressPercent >= highThreshold) {
            return Color.Red;
        } 

        if (progressPercent >= lowThreshold) {
            return Color.Yellow;
        } 

        return Color.Green;
    };

    const barText = (value: number, isSubscriptionInactive: boolean) => {
         const text =  isSubscriptionInactive ? 'Expired' : value > 0 ? `${value} Remaining` : '0 Remaining';
         return text;
    }

    return (
        <div className={`${className}`}>
            <div className={`${className}__info`}>
                <div className={`${className}__info-item`}>{title}</div>
                <div className={`${className}__info-item`}>{barText(remainingProgress, isSubscriptionInactive)}</div>
            </div>
            <div className={`${className}__wrapper`}>
                <Line
                    className={`${className}__bar`}
                    percent={100-progressPercent}
                    strokeColor={isSubscriptionInactive ? Color.Background : barColor()}
                    strokeWidth={4}
                    trailWidth={4}
                />
            </div>
        </div>
    );
};

export default ByzzerProgressBar;
