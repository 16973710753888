import { useTenantApi } from "@/hooks/useTenantApi";

type AttributeGroup = {
    charThemeCode: string;
    sku: string;
};
export function useAttributeService() {
    const {getAttributeGroupForCategoris, getAttributesForAttributeGroup} = useTenantApi();

    async function getAttributeGroups(categories: string[], aggregationLevel?: string): Promise<any> {
        const attributeGroups: AttributeGroup[] = await getAttributeGroupForCategoris(categories, aggregationLevel ?? 'category');
        return attributeGroups?.map((attributeGroup) => {
            return { display: attributeGroup.charThemeCode, value: attributeGroup.charThemeCode };
        });
    }

    async function getAttributes(attributeGroup: string[], aggregationLevel?: string): Promise<any> {
        const attributeList = await getAttributesForAttributeGroup(attributeGroup, aggregationLevel ?? 'category');
        let attributes = attributeList?.map((attribute) => {
            return { display: attribute.displayName, value: attribute.displayName };
        });

        return [...attributes];
    }

    return {
        getAttributeGroups,
        getAttributes,
    };
}
