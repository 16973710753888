import './ByzzerLink.scss';
import React, {ReactNode} from "react";
import {trackEvent} from "@/components/SiteAnalytics";
import _ from "lodash";
import classnames from "classnames";

type TrackClickConfig = {
    name: string;
    data?: Record<string, string>;
}

export type ByzzerLinkProps = {
    children?: ReactNode;
    label?: ReactNode;
    trackClick?: any;
    className?: string;
    href?: string;
    target?: any;
    openInNewTab?: boolean;
    onClick?: any;
    theme?: string | 'light';
    from?: string;
}

export function ByzzerLink({
    children,
    label = children,
    trackClick,
    openInNewTab = false,
    onClick,
    className,
    theme = 'light',
    from,
    ...props
}: ByzzerLinkProps) {

    const baseClassName = from === 'alerts' ? 'byzzer-link-alert' : 'byzzer-link';

    function handleClick() {
        onClick?.(label as string);
        if (trackClick) {
            trackEvent({
                type: 'click',
                name: _.isString(trackClick) ? trackClick : trackClick.name,
                data: (trackClick as TrackClickConfig)?.data
            })
        }
    }

    return (
        <span className={classnames(baseClassName, className, {
            [`${baseClassName}--${theme}`]: Boolean(theme)
        })} onClick={handleClick} {...props}>
            <span className={`${baseClassName}__text`}>{label}</span>
        </span>
    )

}

export default ByzzerLink;

ByzzerLink.displayName = 'ByzzerLink';
