import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './ByzzerDateRange.scss';
import React, {useState, useRef, useEffect, forwardRef, useImperativeHandle} from 'react';
import {DateRangePicker} from 'react-date-range';
import {useClickOutsideHandler} from '@/utils/utils';
import moment from 'moment';
import classnames from "classnames";

const baseClassName = `byzzer-date-range`;
export const ByzzerDateRange = forwardRef(({
                                               className,
                                               onChange,
                                               value,
                                               placeholder,
                                               clearText = 'All',
                                               clearDateRange,
                                               initialMonths=2
                                           }, ref) => {

    const compRef = useRef();
    const [dateRange, setDateRange] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [displayValue, setDisplayValue] = useState('');
    const [ranges, setRanges] = useState([{startDate: new Date(), endDate: new Date(), key: 'selection'}])
    const [initialized, setInitialized] = useState(false);


    useEffect(() => {
        if (dateRange) {
            setRanges([{
                ...dateRange,
                key: 'selection'
            }]);
            setDisplayValue(`${moment(dateRange.startDate).format('MM/DD/YYYY')} - ${moment(dateRange.endDate).format('MM/DD/YYYY')}`);
        } else {
            setRanges([{startDate: new Date(), endDate: new Date(), key: 'selection'}]);
            setDisplayValue('');
        }
    }, [dateRange]);

    useEffect(() => {
        if(clearDateRange){
            setDateRange(null);
            onChange(null, null);
        }
    }, [clearDateRange]);

    useClickOutsideHandler(compRef, () => {
        setExpanded(false);
    });

    const handleOnChange = ({selection}) => {
        if (selection) {
            const {startDate, endDate} = selection;
            setDateRange({startDate, endDate});
            onChange?.(startDate, endDate);
        } else {
            setDateRange(null)
            onChange?.();
        }
    };

    const handleShowAll = (e) => {
        e.stopPropagation()
        setInitialized(false);
        setDateRange(null);
        onChange(null, null);
    };

    useImperativeHandle(ref, () => ({
        showAll() {
            handleShowAll()
        }
    }));

    function onHeaderClick({target}) {
        // if(target.matches)
        setExpanded(expanded => !expanded);
    }

    return (
        <div className={classnames(baseClassName, className)} ref={compRef}>
            <div className="byzzer-date-range__header" onClick={onHeaderClick}>
                {!dateRange && <div className={`byzzer-date-range__placeholder${expanded?"__arrow-up":''}`}>{placeholder}</div>}
                {Boolean(dateRange) && (<>
                    <div className={'byzzer-date-range__value'}>{displayValue}</div>
                    <div className={'byzzer-date-range__clear'} onClick={handleShowAll}>{clearText}</div>
                </>)}
            </div>
            {expanded && (
                <DateRangePicker
                    className={`${baseClassName}__date-range-picker`}
                    onChange={handleOnChange}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={initialMonths}
                    ranges={ranges}
                    direction="horizontal"
                    showMonthAndYearPickers={true}
                    showDateDisplay={false}
                    shownDate={false}
                    showPreview={true}
                    dragSelectionEnabled={true}
                />
            )}
        </div>
    );
})
export default ByzzerDateRange;
