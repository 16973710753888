import './DodWizardActions.scss';
import {ByzzerTipIcon, WizardActions, WizardActionsProps} from '@byzzer/ui-components';
import { DodCancelActionButton } from '@/components/DodConfigEditor/common/DodCancelActionButton';
import {ReactNode} from "react";
import classnames from "classnames";
import { TrackClick, useEventDataWithUserInfo } from '@/analytics';

type DodWizardActionsProps = {
    hideCancelButton?: boolean;
    helpTip?: ReactNode;
} & WizardActionsProps

const baseClassName = 'dod-wizard-actions';

export const DodWizardActions = ({
    className,
    disableNext,
    nextDisabledTip,
    hideCancelButton = false,
    helpTip,
    ...props
}: DodWizardActionsProps) => {
    const getEventData = useEventDataWithUserInfo();
    return (
        <WizardActions
            className={classnames(baseClassName, className)}
            disableNext={disableNext}
            nextDisabledTip={nextDisabledTip}
            {...props}
        >
            {Boolean(helpTip) && (
                <TrackClick
                    name="dod_info_icon_click"
                    data={getEventData()}
                >
                    <div className={`${baseClassName}__byz-tip-icon-wrapper`}>
                        <ByzzerTipIcon
                            type={'help'}
                            interactive={true}
                            preventDefaultClickEvent={false}
                            tipTrigger={'click'}
                            tipLocation={'top-start' as any}
                            tip={<div className={`${baseClassName}__help-tip`}>{helpTip}</div>}
                        />
                    </div>
                </TrackClick>
            )}
            {!hideCancelButton && <DodCancelActionButton />}
        </WizardActions>
    );
};

export default DodWizardActions;
