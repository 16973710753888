import React, { useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import useState from 'react-usestateref';
import classnames from 'classnames';
import './SimulatorOutputTables.scss';
import { SimulatorDatatype, SimulatorTableConfig, SimulatorValues } from "@/types/SimulatorTypes";
import { FormattedValue } from "../FormattedValue";
import { ScenarioSimulatorContext } from "../../../ScenarioSimulatorContext";


export type SimulatorOutputTablesProps = {
    value?: Record<string, number | undefined>;
    tableConfigs?: SimulatorTableConfig[];
    className?: string;
    name?: string;
    datatypeMap: Record<string, SimulatorDatatype>;
} & React.HTMLAttributes<HTMLDivElement>;


export const SimulatorOutputTables = ({
    value,
    tableConfigs,
    className,
    datatypeMap,
    name,
    ...props
}: SimulatorOutputTablesProps) => {

    const baseClassName = 'byz-simulator-output-tables';

    const [activeTable, setActiveTable] = useState<SimulatorTableConfig | undefined>();

    const {
        state: {
            collapsed
        }
    } = useContext(ScenarioSimulatorContext);

    useEffect(() => {
        if (tableConfigs) {
            setActiveTable(tableConfigs[0]);
        }
    }, [tableConfigs, collapsed]);

    return <div className={classnames(baseClassName, className)} {...props}>
        <div className={`${baseClassName}__tabs`}>
            {tableConfigs?.map((table, i) => (
                <div key={table.title} className={classnames(`${baseClassName}__tab`, {
                    [`${baseClassName}__tab--active`]: activeTable?.title === table.title
                })} onClick={() => setActiveTable(table)}>
                    <h2>{table.title}</h2>
                </div>
            ))}
        </div>
        <div className={`${baseClassName}__data`}>
            {Boolean(activeTable) && (
                <table>
                    <thead>
                        <tr>
                            {activeTable?.columns.map((col, i) => <th key={i}>{col.title}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {activeTable?.data.map((row, i) => (
                            <tr key={i}>
                                {row.map((code, i) => (
                                    <td 
                                        key={code}>
                                        {Boolean(value?.[code]) ? (
                                            <FormattedValue type={datatypeMap[code]} value={value?.[code]}/>
                                        ) : i === 0 ? ( // do show the row header
                                            code
                                        ) : <> </>} {/* // show blank if no value */}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    </div>

};

export default SimulatorOutputTables;

SimulatorOutputTables.displayName = 'SimulatorOutputTables';