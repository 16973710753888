import React, {useState} from 'react';
import classNames from "classnames";
import {trackEvent} from "@/components/SiteAnalytics";
import _ from "lodash";

export function FavoriteIcon({className, theme = 'dark', trackClick, selected, onClick, ...props}) {

    const classes = classNames(
        className,
        'byzzer-icon', 'favorite-icon',
        {
            [`favorite-icon--${theme}`]: theme,
            [`favorite-icon--selected`]: selected
        }
    )

    function onIconClick(e) {
        onClick?.(e);
        if(trackClick) {
            trackEvent({
                type: 'click',
                name: _.isString(trackClick) ? trackClick : trackClick.name,
                data: trackClick.data
            })
        }
    }

    return <div className={classes} onClick={onIconClick} {...props}/>
}

export default FavoriteIcon;