import { ByzzerButton, ByzzerInput } from "@/components/form";
import React, { useState } from "react";
import { create as createModal } from "react-modal-promise";
import ByzzerModal2 from "@/components/modals/ByzzerModal2";

export function TeamEditor({ team, teamOptions, onResolve, ...props }) {

    const [loading, setLoading] = useState(false);
    const [editTeam, setEditTeam] = useState({
        id: team.value,
        oldTitle: team.display
    })
    const [teamExists, setTeamExists] = useState(false);

    function changeEditTeamName(e) {
        setEditTeam({ ...editTeam, newTitle: e.target.value })
    }

    const handleValidation = () => {
        if (editTeam.newTitle) {
            const existingTeam = teamOptions.find(team => team.display.trim().toLowerCase() === editTeam.newTitle.trim().toLowerCase());
            if (existingTeam) {
                setTeamExists(true);
            } else {
                setTeamExists(false)
            }
        }
    }

    function sumbitEditTeamName() {
        onResolve({ function: "edit", team: editTeam });
    }

    function onCloseClick() {
        onResolve({ function: "close" });
    }

    return (
        <ByzzerModal2 className="subscription-users__modal-editor">
            <ByzzerModal2.Header className="subscription-users__modal-title" onClose={onCloseClick}>Edit Team</ByzzerModal2.Header>
            <ByzzerModal2.Content>
                <ByzzerModal2.Mask show={loading}>{loading.message}</ByzzerModal2.Mask>
                <div className="subscription-users__modal-display">
                    <div className="subscription-users__modal-display-item">
                        <ByzzerInput
                            className={"subscription-users__modal-display-item-input"}
                            value={editTeam.oldTitle}
                            type={'text'}
                            placeholder={'Team Name'}
                            onChange={(e) => changeEditTeamName(e)}
                            required
                            onBlur={handleValidation}
                        />
                        {
                            teamExists && (
                                <span className={'byzzer-input__error'}>Team name already exists</span>
                            )
                        }
                    </div>
                </div>
            </ByzzerModal2.Content>
            <ByzzerModal2.Footer className="subscription-users__modal-footer">
                <ByzzerButton
                    disabled={!editTeam.newTitle || teamExists}
                    onClick={sumbitEditTeamName}
                    label="Done" />
            </ByzzerModal2.Footer>
        </ByzzerModal2>
    );
}

export const openTeamEditor = createModal(TeamEditor);