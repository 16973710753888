import React, {useCallback, useEffect, useState} from 'react';
import {AlertConfigurationGrid} from '@/components/AlertConfiguration';
import { MyAlertsProps } from '@/pages/MyAlertsPage';

export const MyConfiguredAlerts = (props: MyAlertsProps) => {
    return <AlertConfigurationGrid {...props}/>;
}
 
export default MyConfiguredAlerts;

MyConfiguredAlerts.displayName = 'MyConfiguredAlerts';