import {ApiClient} from "@/api/ApiClient";
import {useMemo} from "react";

// todo: consider have different types for public and tenant HttpRequestManagers this makes it possible to enforce the
//  context that an api can used in.


export function useApi() {

    const publicHttpRequestManager = useMemo<ApiClient>(() => {
        return new ApiClient();
    }, []);
    const tenantHttpRequestManager = useMemo<ApiClient>(() => {
        return new ApiClient({
            includeTenantId: true,
            enableAuth: true
        });
    }, []);

    /**
     * This is meant to be used in {@link setOboUserInRequestHeaders}
     * Since this is a class, make sure to call this on every instance
     * @param {string} email the email id of user to be logged in as
     * @param {string} role the role name of CSR Obo
     */
    function setApiOboUser(email, role) {
        tenantHttpRequestManager.setApiOboUser(email,role)
    }

    /**
     * This is meant to be used in {@link clearOboUserFromRequestHeaders}
     */
    function clearApiOboUser() {
        tenantHttpRequestManager.clearApiOboUser()
    }

    /**
     * Call a byzzer public api.  This will not send any authorization or tenant headers as part of the request.
     * @param apiFunction
     */
    function callPublicApi<T extends any[], U>(apiFunction: (...args: [...T,  ApiClient]) => U): (...args: T) => U {

        return (...args: T): U => apiFunction(...args, publicHttpRequestManager);
    }

    /**
     * Call a byzzer tenant specific api.  This will send the current user's auth token authorization and tenant header
     * as part of the request.
     * @param apiFunction
     */
    function callTenantApi<T extends any[], U>(apiFunction: (...args: [...T,  ApiClient]) => U): (...args: T) => U {

        return (...args: T): U => apiFunction(...args, tenantHttpRequestManager);
    }

    return {
        setApiOboUser,
        clearApiOboUser,
        callPublicApi,
        callTenantApi,
    }
}
