import './ByzzerSelect.scss';
import React, {useState, useRef, useEffect} from 'react';
import classnames from 'classnames';
import {useClickOutsideHandler} from '@/utils/utils';
import ByzzerLink from './ByzzerLink';
import {escapeRegExp} from "lodash";

// todo: rewrite using popper.js to control where the selection is displayed
const baseClassName = 'byzzer-select';

export function ByzzerSelect({
                                 readonly,
                                 label,
                                 name,
                                 value,
                                 placeholder,
                                 onChange,
                                 options = [],
                                 optionKey,
                                 className,
                                 disabled,
                                 direction = 'down',
                                 enableToolTip = false,
                                 allowClear = false,
                                 advanceSearch = false,
                                 advanceSearchLabel,
                                 advanceSearchOnClick,
                                 showSearchResultStatus,
                                 clearAllFilters,
                                 linkedDropdown = false,
                                 variant = 'default',
                                 ...props
                             }) {
    const ref = useRef();
    const [filter, setFilter] = useState('');
    const [selection, setSelection] = useState();
    const [expanded, setExpanded] = useState(false);
    const [$options, setOptions] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);

    useEffect(() => {

        // replace all spaces with .* and escape all text strings
        let filterText = filter?.trim().split(/\s+/).map(escapeRegExp).join('.*');

        const filterRegex = new RegExp(filterText || '.*', 'i');
        const filteredOptions = $options
            .filter(({display = ''})=>  display.match?.(filterRegex))
            // .filter((option) => (option.display ?? option).match?.(filterRegex))
            .map((option) => {
                const disabledOptions = props.disabledOptions && props.disabledOptions?.includes(option.display);

                return (
                    <div
                        key={optionKey ? option.value?.[optionKey] : option.value}
                        className={`${baseClassName}-option${disabledOptions ? ' disabled' : ''}`}
                        onClick={!disabledOptions ? () => handleOptionClick(option) : () => {
                        }}
                        title={disabledOptions ? props.disabledOptionMessage : undefined}
                    >
                        {option.display}
                    </div>
                );
            });

        if (showSearchResultStatus) {
            let filteredOptionsNew =
                filteredOptions?.length > 0
                    ? filteredOptions
                    : [<div className={`${baseClassName}-option`}>No Results Found</div>];
            setFilteredOptions(filteredOptionsNew);
        } else {
            setFilteredOptions(filteredOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, $options]);

    useEffect(() => {
        let optionList;
        if (options?.[0]?.hasOwnProperty('display')) {
            optionList = options;
        } else {
            if (!Array.isArray(options)) options = [options];
            optionList = options?.map((v) => ({
                display: v,
                value: v,
            }));
        }
        setSelection(optionList.find((option) => option.value === value || option.display === value));
        setOptions(optionList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, value]);

    useClickOutsideHandler(ref, () => {
        setExpanded(false);
    });

    useEffect(() => {
        if (clearAllFilters) {
            setFilter('');
        }
    }, [clearAllFilters]);

    function handleOptionClick(selection) {
        setFilter('');
        // setSelection(selection);
        onChange?.(selection?.value, selection);
    }

    function toggle() {
        setExpanded(!expanded);
    }

    function updateFilter({ target }) {
        setExpanded(true);
        setFilter(target.value);
    }

    function isAMatch(filterRegex) {
        return (entry) => {
            return !filter || filterRegex.test(entry.display);
        };
    }

    function regEscape(str) {
        return str.replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    const triggerCls = classnames({
        [`${baseClassName}__trigger`]: allowClear ? (!selection?.display ? true : false) : true,
        [`${baseClassName}__close`]: allowClear ? (selection?.display ? true : false) : false,
    });
    const clearSelection = (e) => {
        if (allowClear && selection?.display) {
            e.preventDefault();
            // setSelection();
            onChange?.();
        }
    };
    return (
        <div
            className={classnames(baseClassName, className, {
                [`${baseClassName}--expanded`]: expanded,
                [`${baseClassName}--disabled`]: disabled,
                [`${baseClassName}--has-value`]: Boolean(selection),
            })}
            ref={ref}
        >
            {label && <div className={`${baseClassName}__label`}>{label}</div>}
            <label className={classnames(`${baseClassName}__control ${linkedDropdown && ' linked-dropdown'}`, {
                [`${baseClassName}__control--${variant}`]: variant
            })}>
                <input
                    type={'text'}
                    onChange={updateFilter}
                    className={classnames(`${baseClassName}__input`, {
                        [`${baseClassName}__input--disabled`]: disabled,
                    })}
                    disabled={disabled}
                    onClick={toggle}
                    placeholder={selection?.display || placeholder}
                    value={filter}
                    autoCorrect={'off'}
                    autoComplete={'off'}
                    spellCheck={'off'}
                    readOnly={readonly}
                />
                {enableToolTip && selection?.display && <span className="tooltiptext">{selection?.display}</span>}

                <div className={triggerCls} onClick={(e) => clearSelection(e)} />

                <div className={classnames(`${baseClassName}-options`, `${baseClassName}__options--open-${direction}`)}>
                    <div className={`${baseClassName}-options__scrollarea`}>{filteredOptions}</div>
                    {advanceSearch && (
                        <ByzzerLink
                            label={advanceSearchLabel}
                            onClick={(e) => {
                                setFilter('');
                                advanceSearchOnClick();
                            }}
                        />
                    )}
                </div>
            </label>
        </div>
    );
}

export default ByzzerSelect;
