import './MarketRunConfigFiltersStep.scss';
import React, {ReactNode, useRef, useState} from "react";
import classnames from "classnames";
import {
    MarketRunConfigFilters,
    MarketRunConfigFiltersProps, 
    MESSAGE_NO_CATEGORY_MATCH,
    ProductRunConfigFiltersRef
} from "@/components/ConfigurationEditors/AlertConfigurationEditor/AlertRunConfigFilters";
import {WithUid, WizardActions, WizardContent, WizardHeader, WizardStep} from "@byzzer/ui-components";

export type MarketRunConfigFiltersStepProps = {
    title?: ReactNode;
} & MarketRunConfigFiltersProps;

const baseClassName = 'market-run-config-filters-step';

export const MarketRunConfigFiltersStep = React.forwardRef<WithUid, MarketRunConfigFiltersStepProps>(
    ({className, title, ...props}, ref) => {

        const [filtersValid, setFiltersValid] = useState<boolean>(false);

        function handleValidationChange(e: ByzzerValidityChangeEvent) {
            setFiltersValid(e.isValid);
            if(e.errors?.includes('missing_remaining_markets')) {

            } else {

            }
        }

        return (
            <WizardStep 
                className={classnames(baseClassName, className)} 
                ref={ref} 
                title={title}
            >
                <WizardHeader className={classnames({
                    'alert-run-config-wizard-header--valid': filtersValid
                })}>
                    <h1 className={'alert-run-config-wizard__step-title'}>
                        {title}
                    </h1>
                </WizardHeader>

                <WizardContent>
                    <MarketRunConfigFilters {...props} onValidityChange={handleValidationChange}/>
                </WizardContent>

                <WizardActions 
                    disableNext={!filtersValid}
                    nextDisabledTip={'You must choose all required values to continue.'}
                />
            </WizardStep>
        )
    });
