import { forwardRef, ReactNode, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { ByzzerChangeEventHandler, ByzzerSelect, WithValue, ByzzerSelectOption } from '@byzzer/ui-components';
import { useAttributeService } from '@/services/attribute.service';

export type ByzzerAttributeSelectProps = {
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<string[]>;
    value?: string[];
    disabledOptions?: string[];
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    maxSelections?: number;
    attributeGroup?: string[];
    aggregationLevel?: string;
} & OnlyRenderableIf;

export const AttributeSelect = forwardRef<WithValue<string[]>, ByzzerAttributeSelectProps>(
    (
        {
            onChange,
            name,
            label,
            className,
            disabledOptions = [],
            placeholder,
            value,
            disabled,
            onlyRenderIf,
            maxSelections,
            attributeGroup,
            aggregationLevel,
        },
        ref
    ) => {
        const [internalValue, setInternalValue] = useState<string[]>();
        if (onlyRenderIf === false) return <></>;

        const [attributeOptions, setAttributeOptions] = useState<ByzzerSelectOption[]>([]);
        const { getAttributes } = useAttributeService();

        useEffect(() => {
            setInternalValue(value);
        }, [value]);

        useEffect(() => {
            (async (attributeGroup) => {
                await loadAttributeOptions(attributeGroup, aggregationLevel);
            })(attributeGroup);
        }, [attributeGroup]);

        async function loadAttributeOptions(attributeGroup?: string[], aggregationLevel?: string) {
            if (attributeGroup?.length) {
                const options = await getAttributes(attributeGroup, aggregationLevel);
                setAttributeOptions(options);
            } else return setAttributeOptions([]);
        }

        function handleChange(e: any) {
            onChange?.({
                value: e?.value,
                name,
                data: e?.data,
            });
            setInternalValue(e.value);
        }

        return (
            <ByzzerSelect
                ref={ref}
                name={name}
                className={classnames(className)}
                options={attributeOptions}
                disabled={disabled}
                placeholder={placeholder}
                label={label as any}
                value={internalValue}
                disabledOptions={disabledOptions}
                onChange={handleChange}
                allowMultiple={true}
                maxSelections={maxSelections}
            />
        );
    }
);

export default AttributeSelect;

AttributeSelect.displayName = 'AttributeSelect';
