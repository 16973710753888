import { useState, useContext, createContext, useMemo, useEffect } from 'react';
import { useBetterNavigate } from '@/utils';
import { selectorStates } from '@/config/globalVars';
import {useTenantApi} from '@/hooks/useTenantApi';
import { modifyObjectiveList } from '@/utils/storiesUtil';
import {
    DataSpecificationValues,
    StoriesContext,
    StoryObjectives,
    ValidationMessage,
    StorySelectorConfigOptions,
} from '../../../types/StoriesTypes';
import { isMarketDisabled, useMarketService } from '@/services/market.service';
import { MarketPickerContext } from '@/components/MarketPicker';
import { useUser } from '@/contexts/UserContext';

const dataSpecInitialValue: DataSpecificationValues = {
    sku: '', // not required
    runType: '',
    storyType: '',
    storyCustomName: '',
    runConfig: {
        brands: [],
        markets: [],
        datatype: '',
        ppgData: {},
        timePeriod: {
            type: 'relative',
            period: 'Latest 52 Weeks',
        },
        categories: [],
        objectives: [],
        focusBrands: [],
        marketAlias: '',
        brandAlias: '',
        categoryAlias: '',
        productAlias: '',
        manufacturers: [],
        subcategories: [],
        focusBrandAlias: '',
        manufacturerAlias: '',
        characteristics: [],
        comparisonMarket: [],
        brandAggregationLevel: 'brand',
        comparisonPerformanceMarkets: {
            marketType: '',
            marketLevel: '',
        },
        demographicDimensions: [],
        focusBrandLevelAlias: '',
        demographics: [],
        comparisonMarketAlias: '',
        performanceMarkets: [],
        marketSelectionsSummary: '',
        keyCharacteristicsAlias: '',
        marketSelectionTypes: [],
        characteristicsDimension: [],
        aggregationLevel: 'category',
        categorySelectionAggregationLevel: 'category',
    },
};
const storyContextInitialState: StoriesContext = {
    runStory: {},
    objectiveList: [],
    storyType: '',
    storyName: '',
    updatePanelStatus: {},
    storyCustomName: '',
    setStorySelectors: {},
    selectedMarketType: '',
    resetStoriesContext: {},
    allOutletsSelected: false,
    setSelectedMarketType: {},
    setallOutletsSelected: {},
    storyInsightsValidation: {},
    setValidationInProgress: {},
    previewMessage: undefined,
    setDataSpecificationValues: {},
    categoryToCheckForIntersect: [],
    selectedMarkets: [],
    setSelectedMarkets: {},
    selectedPerformanceMarkets: [],
    setSelectedPerformanceMarkets: {},
    getSelectorConfiguration: {},
    validationInProgress: 'validate',
    updateStoryContextStateValues: {},
    setPreviewMessage: {},
    updatePanelMessage: {},
    setStoryCustomName: {},
    storyDeckObjectiveList: [],
    storySelectors: JSON.parse(localStorage['storySelectors'] || '[]'),
    selectorsConfigurations: JSON.parse(localStorage['storySelectors'] || JSON.stringify([])),
    dataSpecificationValues: JSON.parse(JSON.stringify(dataSpecInitialValue)),
    validateStoryName: {},
    storyNameErrorMsg: undefined,
    isStoryNameValid: true
};
//Initializing the StoriesContext
let StoriesAppContext = createContext(storyContextInitialState);

//Custom hook to import context to your component
export const useStoriesContext = () => {
    return useContext(StoriesAppContext);
};

export const StoriesProvider = ({ children }) => {
    const { buildStories,    dpAbortCtrler,    getCategoriesByBrands,    getCategoriesByManufacturers,    getStoryInsights,} = useTenantApi();
    const navigate = useBetterNavigate();
    let sku: string = localStorage['storySku'];
    const [selectedMarketType, setSelectedMarketType] = useState<string>('');
    const [storyType, setStoryType] = useState<string>(storyContextInitialState.storyType);
    const [storyName, setStoryName] = useState<string>(storyContextInitialState.storyName);
    const [allOutletsSelected, setallOutletsSelected] = useState<boolean>(false);
    const [objectiveList, setObjectiveList] = useState<StoryObjectives[]>([]);
    const [storyDeckObjectiveList, setStoryDeckObjectiveList] = useState<StoryObjectives[]>([]);
    const [validationInProgress, setValidationInProgress] = useState<string>(
        storyContextInitialState.validationInProgress
    );
    const [previewMessage, setPreviewMessage] = useState<ValidationMessage | undefined>(
        storyContextInitialState.previewMessage
    );
    const [storySelectors, setStorySelectors] = useState<StorySelectorConfigOptions[]>(
        JSON.parse(localStorage['storySelectors'] || '[]')
    );
    const [dataSpecificationValues, setDataSpecificationValues] = useState<DataSpecificationValues>(
        storyContextInitialState.dataSpecificationValues
    );
    const [categoryToCheckForIntersect, setCategoryToCheckForIntersect] = useState<string[]>(
        JSON.parse(localStorage['categoryToCheckForIntersect'] || '[]')
    );
    const [selectorsConfigurations, setSelectorConfiguration] = useState<StorySelectorConfigOptions[]>(
        JSON.parse(localStorage['selectorsConfigurations'] || '[]')
    );

    const [storyCustomName, setStoryCustomName] = useState(storyContextInitialState.storyCustomName);
    const [selectedMarkets, setSelectedMarkets] = useState(dataSpecInitialValue.runConfig.markets);
    const [selectedPerformanceMarkets, setSelectedPerformanceMarkets] = useState(dataSpecInitialValue.runConfig.performanceMarkets)
    const regex = /[\\/:*?"<>|]/;  //BYZ-10457; restrict special chars in storyname
    const resetStoriesContext = () => {
        setObjectiveList([]);
        setStorySelectors([]);
        setDataSpecificationValues({ ...dataSpecInitialValue });
        setValidationInProgress('validate');
        localStorage.setItem('selectedObjectives', JSON.stringify([]));
        localStorage.removeItem('storiesTraining');
        localStorage.removeItem('storySelectors');
        localStorage.removeItem('activeStep');
        localStorage.removeItem('dataSpecificationValues');
        localStorage.removeItem('selectorsConfigurations');
    };
    const [storyNameErrorMsg, setStoryNameErrorMsg] = useState<string | undefined>(
        storyContextInitialState.storyNameErrorMsg
    );
    const [isStoryNameValid, setIsStoryNameValid] = useState<boolean>(storyContextInitialState.isStoryNameValid);
    const {getCachedMarketNodeByName} = useMarketService();
    const { requiredMasterCompany, requiredMarketGroup, requireRemainingMarket } = useContext(MarketPickerContext);
    const {
        features: { enableLimitedMarketSubscription },
        accessibleMasterCompanies,
        company,
    } = useUser();
    useEffect(() => {
        localStorage['dataSpecificationValues'] = JSON.stringify(dataSpecificationValues);
    }, [dataSpecificationValues]);
    useEffect(() => {
        localStorage['selectorsConfigurations'] = JSON.stringify(selectorsConfigurations);
    }, [selectorsConfigurations]);
    useEffect(() => {
        if (objectiveList.length && storySelectors.length) {
            // Here we are setting the objective list for the payload
            let tempObjectives = {};
            objectiveList.forEach((objective) => {
                if (objective.isChecked === 'checked') tempObjectives[objective.slideName] = objective.slideOrder;
            });
            if (!Object.keys(dataSpecificationValues.runConfig.objectives).length) {
                setDataSpecificationValues({
                    ...dataSpecificationValues,
                    runConfig: { ...dataSpecificationValues.runConfig, objectives: { ...tempObjectives } },
                });
            }
            setSelectorConfiguration([
                {
                    title: 'Objective Preview',
                    status: 'completed',
                    type: 'objective_preview',
                    validationMessage: undefined,
                },
                ...storySelectors,
                { title: 'Review and Run', status: 'completed', type: 'review_and_run', validationMessage: undefined },
            ]);
            localStorage['selectedObjectives'] = JSON.stringify(objectiveList);
        }
        localStorage['selectedObjectives'] = JSON.stringify(objectiveList);
    }, [objectiveList, storySelectors]);
    useEffect(() => {
        if (dataSpecificationValues.runConfig.focusBrands && dataSpecificationValues.runConfig.focusBrands.length) {
            (async () => {
                const brandCategories = await getCategoriesByBrands(dataSpecificationValues.runConfig.focusBrands);
                setCategoryToCheckForIntersect(brandCategories);
            })();
        }
        if (dataSpecificationValues.runConfig.manufacturers && dataSpecificationValues.runConfig.manufacturers.length) {
            (async () => {
                const manufacturerCategories = await getCategoriesByManufacturers(
                    dataSpecificationValues.runConfig.manufacturers
                );
                setCategoryToCheckForIntersect(manufacturerCategories);
            })();
        }
    }, [dataSpecificationValues.runConfig.focusBrands, dataSpecificationValues.runConfig.manufacturers]);
    
    async function validateStoryName(storyName?: string) {
        if (selectorsConfigurations.length) {
            const name: string = storyName ?? storyCustomName;
            if (name.length < 1) {
                setStoryNameErrorMsg('Please name your Story');
                setIsStoryNameValid(false);
            } else if (name.length > 100) {
                setStoryNameErrorMsg('Story name is too long, please rename your Story');
                setIsStoryNameValid(false);
            } else if (regex.test(name)) {
                setStoryNameErrorMsg(
                    'Storyname can\'t contain any of the following charecters: \\/:*?"<>|, please rename your Story'
                );
                setIsStoryNameValid(false);
            } else {
                setStoryNameErrorMsg(undefined);
                setIsStoryNameValid(true);
            }
        }
    }
    useEffect(() => {
        return () => {
            resetStoriesContext();
        };
    }, []);
    function getSelectorConfiguration(selectorType) {
        let selectorObject = { selectorConfiguration: {}, selectorIndex: 0 };
        selectorsConfigurations.forEach((selector, index) => {
            if (selector.type === selectorType) {
                selectorObject = {
                    selectorConfiguration: selector,
                    selectorIndex: index,
                };
            }
        });
        return selectorObject;
    }

    function updateValidationMessage({ type, errorMessage }) {
        if (type === 'error') {
            updatePanelMessage(selectorsConfigurations.length - 1, {
                type: type,
                content: errorMessage,
            });
            updatePanelStatus(selectorsConfigurations.length - 1, 'error');
        } else {
            setPreviewMessage({
                type: type,
                content: errorMessage,
            });
            updatePanelStatus(selectorsConfigurations.length - 1, 'completed');
        }
    }
    async function storyInsightsValidation() {
        updatePanelStatus(selectorsConfigurations.length - 1, 'processing');
        updatePanelMessage(selectorsConfigurations.length - 1, undefined);
        setPreviewMessage(undefined);
        let tempObjectiveList = JSON.parse(
            JSON.stringify([...objectiveList].filter((objective) => objective.isChecked === 'checked'))
        );
        const { type, errorMessage, objectives } = await getStoryInsights(getStoryConfigs());
        if (type.length) {
            if (type === 'warning') {
                if (Object.keys(objectives).length) {
                    tempObjectiveList.forEach((selectedObjective) => {
                        let objectiveFromResp = objectives[selectedObjective.slideName];
                        let tempObjectiveInfo: any = [];
                        if (objectiveFromResp) {
                            objectiveFromResp.forEach((number) => {
                                selectedObjective.objectiveInfo.forEach((infoObj) => {
                                    if (infoObj.slideNumber === number) {
                                        tempObjectiveInfo.push(infoObj);
                                    }
                                });
                            });
                        }
                        selectedObjective.objectiveInfo = tempObjectiveInfo;
                        selectedObjective.slideOrder = objectiveFromResp;
                    });
                    setStoryDeckObjectiveList(tempObjectiveList);
                } else {
                    setStoryDeckObjectiveList(tempObjectiveList);
                }
            }
        } else {
            setStoryDeckObjectiveList(tempObjectiveList);
        }
        const numberOfPendingSelectors = selectorsConfigurations.filter((selector) => selector.status === 'pending')
            .length;
        if (!numberOfPendingSelectors) {
            updateValidationMessage({ type, errorMessage });
        }
    }
    function getStoryConfigs() {
        return {
            ...dataSpecificationValues,
            storyCustomName: storyCustomName,
        };
    }
    async function runStory(index) {
        if (index === selectorsConfigurations.length - 1 && selectorsConfigurations[index].status === 'completed') {
            updatePanelStatus(selectorsConfigurations.length - 1, 'processing');
            let response: any = await buildStories(getStoryConfigs());
            if (response) {
                navigate('/dashboard/stories/history');
            }
        }
    }
    function updateStoryContextStateValues(storyConfig: any, userData: any, from: string, defaultValues: any) {
        let count: number = 0;
        let tempDataSpecValues: any = { ...dataSpecificationValues };
        let tempSelectorsConfigurations = [...storyConfig.metadata.configOptions];
        tempDataSpecValues = { ...tempDataSpecValues, sku: localStorage['storySku'] };
        tempDataSpecValues = {
            ...tempDataSpecValues,
            storyType: storyConfig.metadata.storyType,
            runType: userData.subscription.type,
        };
        tempSelectorsConfigurations.forEach((selector) => {
            selector['status'] = selectorStates.pending;
            selector['validationMessage'] = undefined;
        });
        setStoryType(storyConfig.metadata.storyType);
        setStoryName(storyConfig.title);
        if (objectiveList.length === 0) {
            let objectivesListFromConfig = storyConfig.metadata.objective_lists;
            const objectivesInfo: any = modifyObjectiveList(objectivesListFromConfig, userData.company.accessLevel);
            if (objectivesInfo.list.length !== 0) {
                objectivesInfo.list.forEach((objective) => {
                    count += 1;
                    objective.serialNo = count;
                });
                let tempObj = [...objectivesInfo.list];
                setObjectiveList(tempObj);
            }
        }
        if (storySelectors.length === 0) {
            let finalSelectorBe;
            if (userData.company.accessLevel !== 'tracked_manufacturer') {
                finalSelectorBe = tempSelectorsConfigurations.filter(
                    (selector) => selector.type !== 'market_performance'
                );
                setStorySelectors(finalSelectorBe);
            } else {
                setStorySelectors(tempSelectorsConfigurations);
            }
        }
        // TODO: Remove this from logic and make the dataSepc obj flat.Can be picked up after library and history page refactoring
        const { categories, focusBrands, categoryAlias, brandAlias, categorySelectionAggregationLevel,markets,performanceMarkets,datatype } = defaultValues;
        tempDataSpecValues = {
            ...tempDataSpecValues,
            runConfig: {
                ...tempDataSpecValues.runConfig,
                ...defaultValues,
                categoryAlias: categoryAlias?.length ? categoryAlias : categories?.length === 1 ? categories[0] : '',
                brandAlias: brandAlias?.length ? brandAlias : focusBrands?.length === 1 ? focusBrands[0] : '',
                categorySelectionAggregationLevel: categorySelectionAggregationLevel?.length
                    ? categorySelectionAggregationLevel
                    : 'category',
            },
        };
        // setSelectedMarkets(markets?.length ? markets : []);
        setSelectedMarkets(
            markets?.length
                ? markets.map((item) => ({
                      ...item,
                      disabledMarketNode: isMarketDisabled(getCachedMarketNodeByName(item.name, categories)!, {
                          requiredMarketGroup,
                          requiredMasterCompany,
                          requireRemainingMarket,
                          enableLimitedMarketSubscription,
                          accessibleMasterCompanies,
                          reportType:datatype,
                          purchasedMarketKeys:company?.purchasedMarketKeys,
                      }),
                  }))
                : []
        );
        setSelectedPerformanceMarkets(performanceMarkets?.length ? performanceMarkets : []);
        setDataSpecificationValues(tempDataSpecValues);
    }
    function updatePanelStatus(panelIndex, status) {
        let tempselectorsConfigurations = [...selectorsConfigurations];
        tempselectorsConfigurations[panelIndex].status = status;
        setSelectorConfiguration([...tempselectorsConfigurations]);
    }
    function updatePanelMessage(panelIndex, message) {
        let tempselectorsConfigurations = [...selectorsConfigurations];
        tempselectorsConfigurations[panelIndex].validationMessage = message;
        setSelectorConfiguration([...tempselectorsConfigurations]);
    }
    const storiesAppData: StoriesContext = useMemo<StoriesContext>(
        (): StoriesContext => ({
            objectiveList: objectiveList,
            storyType: storyType,
            storyName: storyName,
            storySelectors: storySelectors,
            allOutletsSelected: allOutletsSelected,
            selectedMarketType: selectedMarketType,
            validationInProgress: validationInProgress,
            selectorsConfigurations: selectorsConfigurations,
            storyDeckObjectiveList: storyDeckObjectiveList,
            previewMessage: previewMessage,
            dataSpecificationValues: dataSpecificationValues,
            storyCustomName: storyCustomName,
            selectedMarkets: selectedMarkets,
            categoryToCheckForIntersect: categoryToCheckForIntersect,
            selectedPerformanceMarkets: selectedPerformanceMarkets,
            setSelectedPerformanceMarkets: setSelectedPerformanceMarkets,
            runStory: runStory,
            setSelectedMarkets: setSelectedMarkets,
            getSelectorConfiguration: getSelectorConfiguration,
            setStorySelectors: setStorySelectors,
            updatePanelMessage: updatePanelMessage,
            updatePanelStatus: updatePanelStatus,
            resetStoriesContext: resetStoriesContext,
            setSelectedMarketType: setSelectedMarketType,
            setPreviewMessage: setPreviewMessage,
            setallOutletsSelected: setallOutletsSelected,
            setValidationInProgress: setValidationInProgress,
            setStoryCustomName: setStoryCustomName,
            storyInsightsValidation: storyInsightsValidation,
            setDataSpecificationValues: setDataSpecificationValues,
            updateStoryContextStateValues: updateStoryContextStateValues,
            validateStoryName: validateStoryName,
            storyNameErrorMsg: storyNameErrorMsg,
            isStoryNameValid: isStoryNameValid
        }),
        [
            objectiveList,
            storySelectors,
            allOutletsSelected,
            selectedMarketType,
            validationInProgress,
            selectorsConfigurations,
            dataSpecificationValues,
            selectedPerformanceMarkets,
            storyCustomName,
            selectedMarkets,
            categoryToCheckForIntersect,
        ]
    );
    return <StoriesAppContext.Provider value={storiesAppData}>{children}</StoriesAppContext.Provider>;
};
