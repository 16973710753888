import './MarketTypeSelector.scss';
import React, { useState, useEffect } from 'react';
import { MARKET_TYPES } from './MarketTypeSelectorConstants';
import { ByzzerChangeEventHandler, ByzzerSelect } from '@byzzer/ui-components';
import classnames from 'classnames';
import { ByzzerRadio, ByzzerRadioGroup } from '@byzzer/ui-components';
import { useUser } from '@/contexts/UserContext';
import { ComparisonMarket } from "@/types/ReportRun";
import { SelectorLabelInfo } from '@/components/SelectorLabelInfo';
import { MarketTypeOption } from '@/types/RunConfigOptions';

const baseClassName = 'market-type-selector';

const DEFAULT_VALUE = {
    marketType: '',
    marketLevel: '',
    channel: '',
    labelInfoCode: '',
};
export type MarketTypeSelectorProps = {
    name?: string;
    value?: ComparisonMarket;
    onChange?: ByzzerChangeEventHandler<ComparisonMarket>;
    className?: string;
    sku?: string;
    marketTypeOptions?: MarketTypeOption[];
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>;

export function MarketTypeSelector({ name, value, onChange, className, sku, marketTypeOptions, ...props }: MarketTypeSelectorProps) {
    const { user, company } = useUser();
    const [selectedMarketType, setSelectedMarketType] = useState<ComparisonMarket>(DEFAULT_VALUE);
    const MARKET_TYPE_OPTIONS: MarketTypeOption[] = marketTypeOptions ?? MARKET_TYPES;

    useEffect(() => {
        setSelectedMarketType(value ?? DEFAULT_VALUE);
    }, [value]);

    function handleTypeChange(marketType: string, marketLevel: string) {
        const value = {
            ...selectedMarketType,
            marketType: marketType ?? '',
            marketLevel: marketLevel ?? '',
            channel: '',
        };
        if (onChange) {
            onChange({
                name,
                value,
            });
        }
        setSelectedMarketType(value);
    }

    function handleChannelChange(e: any) {
        // let marketChannel =
        //     selectedMarketType.marketType === 'Account' && e.value === 'All'
        //         ? 'Any'
        //         : e.value === 'All FMCG'
        //         ? 'FMCG'
        //         : e.value;

        const value = {
            ...selectedMarketType,
            channel: e.value,
        };

        if (onChange) {
            onChange({
                name,
                value,
                data: e,
            });
        }
        setSelectedMarketType(value);
    }
    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <div className={classnames(`${baseClassName}__heading-text`)}>
                <SelectorLabelInfo selectorCode={'comparisonMarketStaticText'} sku={sku!} />
            </div>
            <ByzzerRadioGroup name={name} value={selectedMarketType.marketType}>
                {MARKET_TYPE_OPTIONS.map((marketOption) => {
                    if (!marketOption.excludedAccessLevels?.includes(company?.accessLevel?.toLowerCase())) {
                        return (
                            <>
                                <ByzzerRadio
                                    className={classnames(`${baseClassName}__label`)}
                                    name={name}
                                    label={
                                        marketOption?.labelInfoCode ?
                                        <SelectorLabelInfo
                                            selectorCode={`comparisonMarket${marketOption.labelInfoCode}`}
                                            sku={sku!}
                                        />: marketOption?.title ?? ''
                                    }
                                    disabled={false}
                                    value={marketOption.marketType}
                                    labelPlacement={'right'}
                                    checked={selectedMarketType.marketType === marketOption.marketType}
                                    onChange={() => handleTypeChange(marketOption.marketType, marketOption.marketLevel ?? '')}
                                />
                                {marketOption.marketType === selectedMarketType.marketType && (
                                    <ByzzerSelect
                                        className={classnames(`${baseClassName}__channel-select`)}
                                        options={marketOption.children}
                                        placeholder="Select from the list"
                                        value={selectedMarketType.channel}
                                        onChange={handleChannelChange}
                                    />
                                )}
                            </>
                        );
                    }
                })}
            </ByzzerRadioGroup>
        </div>
    );
}

MarketTypeSelector.displayName = 'MarketTypeSelector';
