import { ByzzerButton, ByzzerInput } from "@/components/form";
import React from "react";

export function NameChar({
    renameMode,
    title,
    onTitleChange,
    onComplete,
    busy
}) {

    return <div className="char-editor__mapping">
        <section className="char-editor__naming">
            {renameMode ?
                <h2 className="char-editor__naming-title">Enter new name for your Characteristic</h2> :
                <h2 className="char-editor__naming-title">Name your Characteristic</h2>}
            {!renameMode &&
                <div className="char-editor__naming-description">
                    This is how the characteristic will be labeled throughout the product. You’ll see this name when you choose the characteristics for a report and in the header when it is included. It will be available to all members of your team.
                </div>}

            <div className="char-editor__naming-name">
                <ByzzerInput
                    className={'char-editor__naming-input'}
                    type={'text'}
                    placeholder={'Enter name here'}
                    value={title}
                    onChange={e => onTitleChange(e.target.value.trim())}
                />
            </div>
        </section>
        <div className={'char-editor__actions'}>
            <ByzzerButton disabled={title === ''}
                onClick={onComplete}
                busy={busy}>
                {renameMode? 'Rename Characteristic' : 'Save Characteristic'}
            </ByzzerButton>
        </div>
    </div>
}