import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import OverallLine from '@images/overall_line_sm.svg';
import PromotionLine from '@images/promotion_line_sm.svg';
import PricingLine from '@images/pricing_line_sm.svg';
import AssortmentLine from '@images/assortment_line_sm.svg';
import ProductLine from '@images/product_line_sm.svg';
import './ByzzerOverallScoreTrendChart.scss';
import classnames from "classnames";


const ByzzerOverallScoreTrendChart = ({ trendChartData }) => {
    const [selectedOptions, setSelectedOption] = useState(['overall']);
    const [lineHover, setLineHover] = useState('');

    const renderLegend = (props) => {
        const { payload } = props;

        return (
            <div className='trend-chart__legend'>
                <div className='trend-chart__legend-label'>
                    Overall
                    <img className='trend-chart__legend-img' src={OverallLine} alt={'line'} />
                </div>
                <div className='trend-chart__legend-label' >
                    Promotion
                    <img className='trend-chart__legend-img' src={PromotionLine} alt={'line'} />
                </div>
                <div className='trend-chart__legend-label'>
                    Pricing
                    <img className='trend-chart__legend-img' src={PricingLine} alt={'line'} />
                </div>
                <div className='trend-chart__legend-label' >
                    Assortment
                    <img className='trend-chart__legend-img' src={AssortmentLine} alt={'line'} />
                </div>
                <div className='trend-chart__legend-label' >
                    New Products
                    <img className='trend-chart__legend-img' src={ProductLine} alt={'line'} />
                </div>
            </div>
        );
    }
    const changeOption = (option) => {
        let currentOption = selectedOptions;
        if (currentOption.includes(option)) {
            let filteredOption = currentOption.filter(e => e !== option)
            setSelectedOption(JSON.parse(JSON.stringify(filteredOption)))
        }
        else {
            currentOption.push(option);
            setSelectedOption(JSON.parse(JSON.stringify(currentOption)))
        }
    }

    const formatXAxisTick = (value) => {
        if (value && value.length === 10) {
            return (value.substring(5, 7) + "/" + value.substring(2, 4))
        }
        else {
            return "";
        }

    }
    const renderCustomYAxisTick = ({ x, y, payload }) => {
        if (payload.value >= 100 && payload.value < 200) {
            return <text className='trend-chart__axis-label' x={x - 15} y={y - 12} >F</text>
        }
        else if (payload.value >= 200 && payload.value < 300) {
            return <text className='trend-chart__axis-label' x={x - 15} y={y - 12} >D</text>
        }
        else if (payload.value >= 300 && payload.value < 400) {
            return <text className='trend-chart__axis-label' x={x - 15} y={y - 12} >C</text>
        }
        else if (payload.value >= 400 && payload.value < 500) {
            return <text className='trend-chart__axis-label' x={x - 15} y={y - 12} >B</text>
        }
        else if (payload.value >= 500 && payload.value < 600) {
            return <text className='trend-chart__axis-label' x={x - 15} y={y - 12} >A</text>
        }
    }

    const CustomizedCursor = ({ active, payload }) => {
        if (active && payload && payload.length && lineHover !== '') {
            let data = payload.filter(e => e['dataKey'] === lineHover)
            return <div className='trend-chart__tooltip' >
                <div className='trend-chart__tooltip-title' >{data.name}</div>
                <div className='trend-chart__tooltip-date' >{data.payload["wk_ending_dt"]}</div>
                <div className='trend-chart__tooltip-label' >Score: <span className='trend-chart__tooltip-score'>{data.payload[lineHover]}</span></div>
            </div>
        }
        else
            return null;
    }

    return (
        <div className='trend-chart'>
            <div className='trend-chart__options'>
                <div className={classnames('trend-chart__option', {
                    'trend-chart__option--selected': selectedOptions.includes('overall')
                })} onClick={() => changeOption('overall')}>
                    Overall
                </div>
                <div className={classnames('trend-chart__option', {
                    'trend-chart__option--selected': selectedOptions.includes('promotion')
                })} onClick={() => changeOption('promotion')}>
                    Promotion
                </div>
                <div className={classnames('trend-chart__option', {
                    'trend-chart__option--selected': selectedOptions.includes('pricing')
                })} onClick={() => changeOption('pricing')}>
                    Pricing
                </div>
                <div className={classnames('trend-chart__option', {
                    'trend-chart__option--selected': selectedOptions.includes('assortment')
                })} onClick={() => changeOption('assortment')}>
                    Assortment
                </div>
                <div className={classnames('trend-chart__option', {
                    'trend-chart__option--selected': selectedOptions.includes('new_products')
                })} onClick={() => changeOption('new_products')}>
                    New Products
                </div>
            </div>

            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    className='trend-chart__line-chart'
                    data={trendChartData}
                >
                    <CartesianGrid horizontalFill={['#00A3461A', '#CAA2001A', '#C84F001A', '#D201431A', '#6800261A']} stroke={'transparent'} vertical={false} />
                    <XAxis
                        dataKey="wk_ending_dt"
                        minTickGap={50}
                        tickFormatter={formatXAxisTick}
                        padding={{ left: 10, right: 10 }}
                        reversed
                    />
                    <YAxis
                        type="number"
                        tickCount={6}
                        domain={[100, 600]}
                        tickLine={false}
                        allowDataOverflow={true}
                        tick={renderCustomYAxisTick}
                        interval={0}
                    />
                    <Tooltip />
                    <Legend content={renderLegend} />
                    {selectedOptions.includes('overall') && <Line type="linear" name="Overall" dataKey="overall" strokeWidth={2} stroke={lineHover === 'overall' ? "#00F00F" : "#000000"} dot={false} activeDot={false} onMouseEnter={() => setLineHover('overall')} onMouseLeave={() => setLineHover('')} />}
                    {selectedOptions.includes('promotion') && <Line type="linear" name="Promotion" dataKey="promotion_driver" strokeWidth={2} stroke={lineHover === 'promotion_driver' ? "#00F00F" : "#000000"} strokeDasharray="2 5" dot={false} activeDot={false} onMouseEnter={() => setLineHover('promotion_driver')} onMouseLeave={() => setLineHover('')} />}
                    {selectedOptions.includes('pricing') && <Line type="linear" name="Pricing" dataKey="pricing_driver" strokeWidth={2} stroke={lineHover === 'pricing_driver' ? "#00F00F" : "#000000"} strokeDasharray="10 5" dot={false} activeDot={false} onMouseEnter={() => setLineHover('pricing_driver')} onMouseLeave={() => setLineHover('')} />}
                    {selectedOptions.includes('assortment') && <Line type="linear" name="Assortment" dataKey="assortment_driver" strokeWidth={2} stroke={lineHover === 'assortment_driver' ? "#00F00F" : "#000000"} strokeDasharray="50 15" dot={false} activeDot={false} onMouseEnter={() => setLineHover('assortment_driver')} onMouseLeave={() => setLineHover('')} />}
                    {selectedOptions.includes('new_products') && <Line type="linear" name="New Product" dataKey="product_driver" strokeWidth={2} stroke={lineHover === 'product_driver' ? "#00F00F" : "#000000"} strokeDasharray="5 2" dot={false} activeDot={false} onMouseEnter={() => setLineHover('product_driver')} onMouseLeave={() => setLineHover('')} />}
                </LineChart>
            </ResponsiveContainer>

        </div>

    );
}

export default ByzzerOverallScoreTrendChart;