import './DodTimePeriodFilterValuePicker.scss';
import React, {useEffect, useMemo, useState} from 'react';
import classnames from 'classnames';
import {DodFilters, DodTimPeriodFilter, TimePeriodSelections} from '@/types/DodRun';
import {ByzzerChangeEventHandler} from '@byzzer/ui-components';
import {DodTimePeriodFilterType, DodTimePeriodFilterTypeValueMap} from '@/components/DodConfigEditor/types';
import {DodFilterValuePicker, DodFilterValuePickerRef, DodValueOption} from '@/components/DodConfigEditor/common';
import {isAsyncFunction} from '@/utils';
import {useDodTimePeriodService} from '@/services/dodTimePeriod.service';
import {TimePeriod} from '@/types/ReportRun';
import { uniq } from 'lodash';
import {alertSelectionLimitExceed} from '@/components/DodConfigEditor/common/utils'


const baseClassName = 'dod-time-period-filter-value-picker';

export type DodTimePeriodFilterValuePickerProps = {
    name?: string;
    filterType: DodTimePeriodFilterType;
    filters: DodFilters;
    value?: DodTimPeriodFilter<TimePeriodSelections>[];
    onChange?: ByzzerChangeEventHandler<string[]>; // change this
    onApply?: ByzzerChangeEventHandler<string[]>; //change this
    actions?: ActionConfig[];
    pickerRef?: React.Ref<DodFilterValuePickerRef>;
    includeActions?: boolean;
    filterText?: string;
    limit?:number;
    allowedSelectionLimit?:number;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

type FilterValueConfig = {
    getOptions(values: DodFilters, data: any): Promise<DodValueOption[]> | DodValueOption[];
    valueToStrings?(value: TimePeriod[]): string[];
    stringsToValue?(strings: string[]): any;
};

function defaultValueToStrings(value: any) {
    return value;
}

function defaultStringsToValue(strings: string[]) {
    return strings;
}

export function DodTimePeriodFilterValuePicker({
                                                   className,
                                                   filterType,
                                                   filters,
                                                   value,
                                                   onChange,
                                                   onApply,
                                                   limit = Infinity,
                                                   allowedSelectionLimit = Infinity,
                                                   ...props
                                               }: DodTimePeriodFilterValuePickerProps) {
    const [options, setOptions] = useState<DodValueOption[]>([]);
    const {
        latestDates,
        weekEndingDates,
        fourWeeksEndingDates,
        yearEndingDates,
        monthEndingDates,
        quarterEndingDates,
        fourWeeksStaticEndingDates,
        previousPeriodDates
    } = useDodTimePeriodService();
    const filterValueConfigs = useMemo<Record<DodTimePeriodFilterType, FilterValueConfig>>(
        () => ({
            latestDates: {
                getOptions(filters) {
                    return latestDates;
                },
                stringsToValue(value) {
                    if(value === 'all') {
                        return latestDates.map(val => val.value);
                    } else {
                        return value
                    }
                }
            },
            previousPeriods: {
                getOptions(filters) {
                    return previousPeriodDates;
                },
                stringsToValue(value) {
                    if(value === 'all') {
                        return previousPeriodDates.map(val => val.value);
                    } else {
                        return value
                    }
                }
            },
            weeks: {
                getOptions(filters) {
                    return weekEndingDates;
                    // ['1w:1:1', '1w:1:2']
                },
                stringsToValue(value) {
                    if(value === 'all') {
                        return weekEndingDates.map(val => val.value);
                    } else {
                        return value
                    }
                }
            },
            '4weeks': {
                getOptions(filters) {
                    return fourWeeksEndingDates;
                },
                stringsToValue(value) {
                    if(value === 'all') {
                        return fourWeeksEndingDates.map(val => val.value);
                    } else {
                        return value
                    }
                }
            },
            '4StaticWeeks': {
                getOptions(filters) {
                    return fourWeeksStaticEndingDates;
                },
                stringsToValue(value) {
                    if(value === 'all') {
                        return fourWeeksStaticEndingDates.map(val => val.value);
                    } else {
                        return value
                    }
                }
            },
            months: {
                getOptions(filters) {
                    return monthEndingDates;
                },
                stringsToValue(value) {
                    if(value === 'all') {
                        return monthEndingDates.map(val => val.value);
                    } else {
                        return value
                    }
                }
            },
            quarters: {
                getOptions(filters) {
                    return quarterEndingDates;
                },
                stringsToValue(value) {
                    if(value === 'all') {
                        return quarterEndingDates.map(val => val.value);
                    } else {
                        return value
                    }
                }
            },
            years: {
                getOptions(filters) {
                    return yearEndingDates;
                },
                stringsToValue(value) {
                    if(value === 'all') {
                        return yearEndingDates.map(val => val.value);
                    } else {
                        return value
                    }
                }
            },
            custom: {
                getOptions(filters) {
                    return [];
                },
            },
            saved: {
                async getOptions() {
                    return [];
                },
            },
        }),
        [filters, filterType]
    );
    const [normalizedValue, setNormalizedValue] = useState<string[]>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            const {getOptions} = filterValueConfigs[filterType];
            setLoading(isAsyncFunction(getOptions));
            try {
                const options = await getOptions(filters, filterType);
                setOptions(options ?? []);
            } finally {
                setLoading(false);
            }
        })();
    }, [filterType]);

    useEffect(() => {
        const {valueToStrings = defaultValueToStrings} = filterValueConfigs[filterType];
        setNormalizedValue(valueToStrings(value));
    }, [value, filterType, filters]);

    function handleChange(e) {
        const {stringsToValue = defaultStringsToValue} = filterValueConfigs[filterType];
        const oldValue = [...value].filter(val => val.slice(0, 2)  !== DodTimePeriodFilterTypeValueMap[filterType])
        onChange?.({
            ...e,
            value: uniq([...oldValue, ...stringsToValue(e.value)]),
        });
    }

    function handleApply(e) {
        const {stringsToValue = defaultStringsToValue} = filterValueConfigs[filterType];
        const oldValue = [...value].filter(val => val.slice(0, 2)  !== DodTimePeriodFilterTypeValueMap[filterType])
        onApply?.({
            ...e,
            value: uniq([...oldValue, ...stringsToValue(e.value)]),
        });
    }

    return (
        <DodFilterValuePicker
            className={classnames(baseClassName, className)}
            options={options}
            loading={loading}
            onChange={handleChange}
            onApply={handleApply}
            value={normalizedValue}
            skipSorting
            limit={allowedSelectionLimit}
            onLimitExceed = {() => alertSelectionLimitExceed("time periods",limit)}
            enableExplicitAllSelection={true}
            dodWizardStep="time_period"
            {...props}
        />
    );
}

export default DodTimePeriodFilterValuePicker;
