import { ByzzerCheckableChangeEventHandler } from "@byzzer/ui-components";
import React from "react";

// approach take from here: https://stackoverflow.com/a/75210657
// this allows us to use generics with the context value and enforce types
export type ByzzerRadioGroupContextValue<ValueType extends string | number | boolean | undefined> = {
    id?: string;
    value?: ValueType;
    setValue: (value?: ValueType) => void;
    triggerChange: ByzzerCheckableChangeEventHandler<ValueType>;
}

export const ByzzerRadioGroupContext = React.createContext<any>(undefined as any);

ByzzerRadioGroupContext.displayName = 'ByzzerRadioGroupContext';

export function useByzzerRadioContext<ValueType extends string | number | boolean | undefined>(): ByzzerRadioGroupContextValue<ValueType> {

    const context = React.useContext<ByzzerRadioGroupContextValue<ValueType>>(ByzzerRadioGroupContext);
    return context ?? {setValue: () => {}}
}

export type ByzzerRadioGroupProviderProps<ValueType extends string | number | boolean | undefined> = {
    value: ByzzerRadioGroupContextValue<ValueType>
}

export function ByzzerRadioGroupProvider<ValueType extends string | number | boolean | undefined>(
    {
        value,
        children
    }: React.PropsWithChildren<ByzzerRadioGroupProviderProps<ValueType>>) {

    return <ByzzerRadioGroupContext.Provider value={value}>{children}</ByzzerRadioGroupContext.Provider>
}


