import React, { useContext } from 'react';
import { Spinner } from '@/components/Spinner/Spinner';
import { isEmpty } from 'lodash';
import { TileVisualization } from './TileVisualization';
import { useUser } from '@/contexts/UserContext';
import { MyHomepageContext, useMyHomePageContext } from '@/contexts/MyHomePageContext';

export const TileConfigEditor = React.memo(() => {
    const baseClassName = 'my-home-page';
    const { isContextLoading,  } = useContext(MyHomepageContext);
    const { tiles } = useMyHomePageContext() ?? {};
    const { company ,preSales, subscription} = useUser();
    let preSalesLogic =
    (subscription.metadata.isFree && !preSales) ||
    (subscription.metadata.isFree &&
        preSales &&
        company?.features?.freeAccountGroup !== 'A');
    const freePackageTileContainerClass = preSalesLogic ? `free-user`: '';
    function loadingTiles() {
        return (
            <>
                {[1, 2, 3, 4].map((ele, index) => (
                    <div className={`viz-tile ${baseClassName}__tile`} key={'loading-' + index}>
                        <div className={`${baseClassName}__spinner`} key={index}>
                            {/* <Spinner className={`${baseClassName}__spinner-item`} /> */}
                            <Spinner />
                        </div>
                    </div>
                ))}
            </>
        );
    }

    function defaultTiles() {
        return (
            <>
                {[1, 2, 3, 4].map((ele, index) => (
                    <div className={`${baseClassName}__tile`} key={`tile__${ele}`}>
                        <div key={index} className={`${baseClassName}__default_content`}></div>
                    </div>
                ))}
            </>
        );
    }

    return (
        <div
            className={`${baseClassName}__tiles-container ${freePackageTileContainerClass}`}
        >
            {isContextLoading
                ? loadingTiles()
                : isEmpty(tiles)
                ? defaultTiles()
                : tiles.map((tile, index) => (
                      <TileVisualization
                          key={index}
                          tile={tile}
                          className={!tile.data ? `${baseClassName}__free_tile` : `${baseClassName}__tile`}
                      />
                  ))}
        </div>
    );
});

export default TileConfigEditor;
