import './DodFactSetPreview.scss';
import { ReactNode, useEffect, useState, useMemo } from 'react';
import classnames from 'classnames';
import { ErrorBoundary } from 'react-error-boundary';
import { ByzzerChangeEventHandler } from '@byzzer/ui-components';

import { DodFilterPreviewGroup, DodSelectionPreviewNode } from '@/components/DodConfigEditor/common';
import { DodPanel } from '@/components/DodConfigEditor/common/DodPanel';
import { openCreateDodPresetModal } from '@/components/DodConfigEditor/common/DodSavedSelection/DodSaveSelectionModal';
import { useDodWizard } from '@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext';
import {
  DodFactSuffixes,
  factSetToCoreDisplayNames,
  factSetToSharedDisplayNames,
} from '@/components/DodConfigEditor/common/utils';
import { useApp } from '@/contexts/UserContext';
import { useDodService } from '@/services/dodPresets.service';
import { DodFactSet } from '@/types/DodRun';

const baseClassName = 'dod-fact-set-preview';

export type DodFactSetPreviewProps = {
    className?: string;
    children?: ReactNode;
    value: DodFactSet[];
    title?: ReactNode;
    emptyStateContent?: ReactNode;
    onChange?: ByzzerChangeEventHandler<DodFactSet[]>;
    name?: string
};

const savedSelectionDescription = <>
    <p>This will save the full set of selections currently in your selection panel so that you can easily reuse them in
        future extracts.</p>
    <p>Your saved selections will be available in the "My Saved Facts" folder on the left.</p>
</>;

export function DodFactSetPreview({
                                      className,
                                      value,
                                      onChange,
                                      title,
                                      name,
                                      emptyStateContent,
                                      ...props
                                  }: DodFactSetPreviewProps) {

    const {reloadDodPresets} = useApp()
    const {presets, setPreset, deletePreset} = useDodWizard();
    const [nonEmptyGroups, setNonEmptyNodes] = useState<DodSelectionPreviewNode[]>([]);
    const [count, setCount] = useState<number>(0);
    const isEmpty = Boolean(value && nonEmptyGroups?.length === 0);
    const {dodPresetsMap} = useDodService();

    const previewTitle = useMemo<string | JSX.Element>(() => {
        if (presets?.fact?.displayName) {
            return (
                <div className={`${baseClassName}__selection`}>
                    {`${count} selected using`}{' '}
                    <span
                        className={`${baseClassName}__preset-name`}
                        data-title={presets.fact.displayName}
                    >
                        {presets.fact.displayName}
                    </span>
                </div>
            );
        }
        return count > 0 ? `${count} Selected` : '';
    }, [presets, count]);

    useEffect(() => {

        const nonEmptyNodes: DodSelectionPreviewNode[] = [{
            label: 'Core Facts',
            key: 'core',
            values: value.map(factSetToCoreDisplayNames).flat(),
        }, {
            label: 'Share Facts',
            key: 'share',
            values: value.map(factSetToSharedDisplayNames).flat()
        }].filter(v => Boolean(v.values.length))
        setCount(nonEmptyNodes.reduce((total, {values}) => total + values!.length, 0));
        setNonEmptyNodes(nonEmptyNodes);
    }, [value]);

    function handleDeleteGroup(group: DodSelectionPreviewNode) {
        let updatedValue: DodFactSet[] = value ?? [];
        updatedValue = updatedValue.map((factVal) => deleteFactGroup(group?.key, factVal)).filter((factVal) => factHasOneAttribute(factVal));
        onChange?.({
            name,
            value: updatedValue,
        });

        // remove fact preset
        if(presets.fact) {
            deletePreset('fact');
        }
    }

    function handleDeleteGroupValue(mappedValue: string, group: DodSelectionPreviewNode) {
        let updatedValue: DodFactSet[] = value ?? [];
        updatedValue = updatedValue.map((factVal) => deleteFactValue(mappedValue, factVal)).filter((factVal) => factHasOneAttribute(factVal));
        onChange?.({
            name,
            value: updatedValue,
        });

        // remove fact preset
        if(presets.fact) {
            deletePreset('fact');
        }
    }

    function handleDeleteSummedSelection(
        group: DodSelectionPreviewNode,
        index: number
    ) {

    }

    function handleDeleteSummedSelectionValue(group: DodSelectionPreviewNode, index: number, value: string) {

    }

    function handleEditSummedSelection(group: DodSelectionPreviewNode, index: number) {

    }

    async function handleSaveSelections() {
        const preset = await openCreateDodPresetModal({
            description: savedSelectionDescription,
            currentPreset: presets['fact'],
            preset: {
                type: "fact",
                values: value
            },
            dodPresetsMap: dodPresetsMap["fact"]
        });

        if(preset) {
            setPreset('fact', {
                ...preset,
                // @ts-ignore - this needs to be here to remove values and produce a valid preset summary
                values: undefined
            })
            reloadDodPresets();
        }
    }

    // Function to generate the text content
    const generateSelectionText = () => {
        return nonEmptyGroups
        .map((group) => {
            // Group label
            const groupLabel = group.label;
            const includeAll = group.values === 'all';
            // Values under the group
            const valuesText = includeAll ? 'Includes all' : group.values?.map((value) => `  - ${value}`) // Indent values for readability
            .join('\n');
            return `${groupLabel}:\n${valuesText}`;
        })
        .join('\n\n'); // Separate groups by a blank line
    };
    // Function to download the text as a file
    const downloadSelections = () => {
        const content = generateSelectionText();
        const blob = new Blob([content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `fact-selections.txt`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    return (<ErrorBoundary fallback={<></>} onError={handleError}>
        <DodPanel className={classnames(baseClassName, className)}
                  title={previewTitle}
                  actions={[
                    {
                        key: 'download',
                        iconType: 'download',
                        disabled: !count,
                        disabledTip: 'No selections to download',
                        tip: `Download all fact selections.`,
                        onClick: downloadSelections,
                    },
                    {
                      key: 'save',
                      disabled: !count,
                      iconType: 'save',
                      disabledTip: 'You have to select at least one value before you can save you selections.',
                      tip: 'Save your current selections to use in future runs.',
                      onClick: handleSaveSelections,
                  }]}
                  enableFilter={false}>
            <div className={`${baseClassName}__tree`}>
                {isEmpty && <div className={`${baseClassName}__empty-state`}>{emptyStateContent}</div>}
                {nonEmptyGroups?.map((group) => (<DodFilterPreviewGroup
                        key={group.key}
                        group={group}
                        onDelete={handleDeleteGroup}
                        onDeleteValue={handleDeleteGroupValue}
                        onDeleteSummedSelection={handleDeleteSummedSelection}
                        onDeleteSummedSelectionValue={handleDeleteSummedSelectionValue}
                        onEditSummedSelection={handleEditSummedSelection}
                        skipSorting={true}
                    />
                ))}
            </div>
        </DodPanel>
    </ErrorBoundary>);
}

function handleError(err) {
    console.error(err);
}

function deleteFactValue(value: string = '', factSet: DodFactSet): DodFactSet {
    const {
        category,
        categoryYearAgo,
        brand,
        brandYearAgo,
        productSelections,
        productSelectionsYearAgo,
        fact: currentPeriod,
        yearAgo,
        changeVsYearAgo,
        percentChangeVsYearAgo,
        display
    } = factSet;

    if ((value.includes(DodFactSuffixes.perChangeYearAgo) && percentChangeVsYearAgo) && value.includes(display)) {
        factSet.percentChangeVsYearAgo = false;
    } else if (value.includes(DodFactSuffixes.changeYearAgo) && changeVsYearAgo && value.includes(display)) {
        factSet.changeVsYearAgo = false;
    } else if (value.includes(DodFactSuffixes.yearAgo) && yearAgo && value.includes(display)) {
        factSet.yearAgo = false;
    } else if (value.includes(DodFactSuffixes.shareByCategoryYearAgo) && categoryYearAgo && value.includes(display)) {
        factSet.categoryYearAgo = false;
    } else if (value.includes(DodFactSuffixes.shareByCategory) && category && value.includes(display)) {
        factSet.category = false;
    } else if (value.includes(DodFactSuffixes.shareByBrandYearAgo) && brandYearAgo && value.includes(display)) {
        factSet.brandYearAgo = false;
    } else if (value.includes(DodFactSuffixes.shareByBrand) && brand && value.includes(display)) {
        factSet.brand = false;
    } else if (value.includes(DodFactSuffixes.shareByProductSelectionsYearAgo) && productSelectionsYearAgo && value.includes(display)) {
        factSet.productSelectionsYearAgo = false;
    } else if (value.includes(DodFactSuffixes.shareByProductSelections) && productSelections && value.includes(display)) {
        factSet.productSelections = false;
    } else if (currentPeriod && value.includes(display)) {
        factSet.fact = false;
    }
    return factSet;
}

function deleteFactGroup(type: string, factSet: DodFactSet): DodFactSet {
    if (type === 'core') {
        factSet.fact = false;
        factSet.yearAgo = false;
        factSet.changeVsYearAgo = false;
        factSet.percentChangeVsYearAgo = false;
    } else if (type === 'share') {
        factSet.category = false;
        factSet.categoryYearAgo = false;
        factSet.brand = false;
        factSet.brandYearAgo = false;
        factSet.productSelections = false;
        factSet.productSelectionsYearAgo = false;
    }

    return factSet
}

function factHasOneAttribute(factSet: DodFactSet) : Boolean {
    return Boolean(factSet.fact ||
    factSet.yearAgo ||
    factSet.changeVsYearAgo ||
    factSet.percentChangeVsYearAgo ||
    factSet.category  ||
    factSet.categoryYearAgo ||
    factSet.brand ||
    factSet.brandYearAgo ||
    factSet.productSelections ||
    factSet.productSelectionsYearAgo);
}


export default DodFactSetPreview;
