import {CharacteristicCondition} from "@/types/ReportRun";
import {
    ByzzerChangeEventHandler,
    ByzzerConditionInput,
    ByzzerSearchableConditionInput,
    ByzzerSelectOption
} from "@byzzer/ui-components";
import {CharacteristicCriteriaContext, CharacteristicOption} from "./CharacteristicCriteriaBuilder";
import {ReactNode, useContext, useEffect, useRef, useState} from "react";
import {useTenantApi} from "@/hooks/useTenantApi";
import classnames from "classnames";

export type CharacteristicConditionInputProps = {
    value?: CharacteristicCondition;
    onChange?: ByzzerChangeEventHandler<CharacteristicCondition>;
    characteristics?: CharacteristicOption[];
    includeLabels?: boolean;
    categories: string[];
    brand?: string;
    disabledKeys?: string[];
    name?: string;
    className?: string;
    actions?: ReactNode;
    excludeCharacteristicIsNot?: boolean;
    fieldsToRenderAsLargeList?: string[];
    renderLargeList?: boolean;
    searchableFields?: string[];
}


const CHARACTER_COMPARISON_OPERATIONS = ['is', 'is not'];
const OPERATIONS_EXCLUDING_IS_NOT = ['is']; // adding for exclusion of 'is not' option as part of (BYZ-9680)

export function CharacteristicConditionInput({
    className,
    onChange,
    includeLabels,
    value,
    categories,
    brand,
    disabledKeys,
    name,
    actions,
    excludeCharacteristicIsNot,
    fieldsToRenderAsLargeList,
    renderLargeList,
    searchableFields,
    ...props
}: CharacteristicConditionInputProps) {
    const {getCustomCharWithNASegment, getCharacteristicValuesForCategories, getUPCForCategories, searchMultipleUPCs } = useTenantApi();
    const context = useContext(CharacteristicCriteriaContext);
    const baseClassName = 'characteristic-condition';
    const labels = includeLabels
        ? {
              keyLabel: 'Characteristic',
              operationLabel: 'Condition',
              valueLabel: 'Values',
          }
        : {};

    const [internalValue, setInternalValue] = useState<CharacteristicCondition>();
    const [internalValueOptions, setInternalValueOptions] = useState<(ByzzerSelectOption | string)[]>([]);
    const [valueNoOptionsMessage, setValueNoOptionsMessage] = useState<string | undefined>();
    const loadedWithValueOptions = useRef<Boolean>(false);

    const MAX_UPC_SEARCH_RESULTS: number = 500;

    const keyLoadingValueOptionsMessage = Boolean(categories.length) && Boolean(!context.characteristics?.length) && !internalValue?.characteristic ? 'Loading options...' : undefined;
    const loadingValueOptionsMessage = internalValue?.characteristic! && !internalValueOptions?.length ? 'Loading options...' : undefined;

    useEffect(() => {
        if (brand) { // this should be on top, or reworked to not clear char values on load, if used
            // setInternalValueOptions([]);
            // getAndsetInternalValueOptions(internalValue?.characteristic!)
        }
    }, [brand]);

    useEffect(() => {
        if (!loadedWithValueOptions.current && value?.characteristic && !internalValueOptions.length) { // triggers dynamic lookup of value options on load, when value is passed in
            // console.log("(2) CharacteristicConditionInput - useEffect (INITIAL) / value (condition) LOADED ===>> ", value)
            handleKeyChange({
                value: value?.characteristic,
                name
            })
        } else if (loadedWithValueOptions.current && value) { // not sure if &&value is needed
            setInternalValue(value);
        }
    }, [value]);

    useEffect(() => {
        if (!loadedWithValueOptions.current && !internalValueOptions.length) { // responsible for setting 'value' (third select) after value options have loaded, when default value is passed into conditioninput
            // console.log("(3) CharacteristicConditionInput - useEffect (INITIAL)  / internalValueOptions updated - 'internalValueOptions' ===>> ", internalValueOptions)
            loadedWithValueOptions.current = true;
            setInternalValue(value)
        }
    }, [internalValueOptions]);



    function handleChange(e: any) {
        const { value: {
            key, operation, value: charValue, isCustom, characteristicDisplayValue
        } } = e;
        onChange?.({
            value: {
                characteristic: key,
                condition: operation,
                value: charValue?.map((characteristic: ByzzerSelectOption | string) => (characteristic as ByzzerSelectOption)?.value ?? (characteristic as string)),
                isCustom: isCustom,
                characteristicDisplayValue: characteristicDisplayValue
            },
            name
        })
    }

    async function getAndsetInternalValueOptions(value: string) {
        const characteristicMap = context.characteristics?.find((characteristic) => {
            return characteristic.characteristicsCode === value;
        });

        if (characteristicMap?.isCustom) {
            const customCharValuesResponse = await getCustomCharWithNASegment(Number(value));
            setInternalValueOptions(customCharValuesResponse);
        } else if (!searchableFields?.includes(value)) {
            const charValuesForCategoriesResponse = await getCharacteristicValuesForCategories(categories, value);
            setInternalValueOptions(charValuesForCategoriesResponse);
        }
    }

    async function handleKeyChange(e: ByzzerChangeEvent<string | undefined | null>) {
        if (e?.value) {
            getAndsetInternalValueOptions(e.value);
        } else {
            setInternalValue(undefined);
            setInternalValueOptions([]);
        }
    }

    async function searchUPCs(searchText: string): Promise<string[] | ByzzerSelectOption[]> {
        if (searchText === '' || (searchText && Boolean(!searchText.length))) {
            return [];
        }
        let searchTextArray: string[] = searchText.split(',').map((item: string) => item.trim());

        if (Boolean(searchTextArray.length > 1)) {
            const results = await searchMultipleUPCs(searchTextArray) ?? [];

            return results?.upc_details?.map(({upc}) => upc) ?? []; // todo: see if we want this to use ByzzerSelectOption instead at some point
        } else {
            const response = await getUPCForCategories(searchText, brand, categories); // , MAX_UPC_SEARCH_RESULTS

            if (response?.type === 'error') { // todo: rework this to follow restful pattern, maybe
                if (response?.error === 'too_many_results') {
                    setValueNoOptionsMessage(response.message)
                } else if (response?.error === 'not_enough_input') {
                    setValueNoOptionsMessage(response.message)
                } else {
                    setValueNoOptionsMessage(undefined)
                }
                return [];
            } else {
                setValueNoOptionsMessage(undefined) // fall back on default No Options message in byzzersearch
                if (Boolean(!response?.upc_details?.length)) {
                    return [];
                }
                return response?.upc_details?.map(({upc}) => upc) ?? []; // todo: see if we want this to use ByzzerSelectOption instead at some point
            }

        }
    }

    if (Boolean(internalValue?.characteristic) && searchableFields?.includes(internalValue?.characteristic!)) {
        return (
            <ByzzerSearchableConditionInput
                {...labels}
                value={{
                    key: internalValue?.characteristic!,
                    operation: internalValue?.condition,
                    value: internalValue?.value,
                }}
                className={classnames(baseClassName, className)}
                keyOptions={context.characteristics.map((c) => ({
                    display: c.characteristic,
                    value: c.characteristicsCode,
                }))}
                operationOptions={excludeCharacteristicIsNot ? OPERATIONS_EXCLUDING_IS_NOT : CHARACTER_COMPARISON_OPERATIONS}
                onKeyChange={handleKeyChange}
                disabledKeys={disabledKeys}
                minSearchCharacters={4} // todo: make this dynamic. 4 works for upc but might need to be diferent for other fields in future
                search={searchUPCs} // todo: make this dynamic
                actions={actions}
                name={name}
                onChange={handleChange}
                valueNoOptionsMessage={valueNoOptionsMessage}
                onMenuOpen={() => setValueNoOptionsMessage(undefined)} // TODO: Rename valueNoOptionsMessage to 'noValueOptionsMessage' everywhere
            />
        )
    }

    return (
        <ByzzerConditionInput
            // {...props}
            {...labels}
            value={{
                key: internalValue?.characteristic!,
                operation: internalValue?.condition,
                value: internalValue?.value,
            }}
            className={classnames(baseClassName, className)}
            keyOptions={context.characteristics.map((c) => ({
                display: c.characteristic,
                value: c.characteristicsCode,
            }))}
            operationOptions={excludeCharacteristicIsNot ? OPERATIONS_EXCLUDING_IS_NOT : CHARACTER_COMPARISON_OPERATIONS}
            allowMultipleValues={true}
            valueOptions={internalValueOptions}
            onKeyChange={handleKeyChange}
            disabledKeys={disabledKeys}
            onChange={handleChange}
            actions={actions}
            keyPlaceholder={keyLoadingValueOptionsMessage}
            keyLoadingMessage={keyLoadingValueOptionsMessage}
            keyNoOptionsMessage={keyLoadingValueOptionsMessage}
            valuePlaceholder={loadingValueOptionsMessage}
            valueLoadingMessage={loadingValueOptionsMessage}
            valueNoOptionsMessage={loadingValueOptionsMessage}
            renderLargeList={renderLargeList} // todo: rename this to something like renderValuesAsLargeList
        />
    );
}
