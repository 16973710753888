
// ******************************************************************************************************************
// PRIVATE UTILITY FUNCTIONS DO NOT ADD ANY NEW CODE BELOW THIS POINT (THIS IS THE PREVIOUS COMMENT)
// ******************************************************************************************************************

import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {getAuth} from "firebase/auth";
import {baseApiUrl} from "@/config";

type ApiHttpClientOptions = {
    enableAuth?: boolean;
    includeTenantId?: boolean;
}

export class ApiClient {

    private readonly http: AxiosInstance;
    constructor({enableAuth = true, includeTenantId = false}: ApiHttpClientOptions = {}) {

        this.http = axios.create({
            baseURL: baseApiUrl
        })
        if(enableAuth) {
            getAuth().onIdTokenChanged(async (user) => {
                this.http.defaults.headers.common.Authorization = user ? `Bearer ${await user.getIdToken()}` : false;
            })
        }

        if(includeTenantId) {

            this.http.interceptors.request.use(config => ({
                ...config,
                headers: {
                    ...config.headers,
                    ['x-tenant-id']: window.localStorage.getItem('tenantId') ?? false
                }
            }));
        }
    }

    /**
     * This is meant to be used in {@link setOboUserInRequestHeaders} through the instances
     * @param {string} email the email id of user to be logged in as
     * @param {string} role the role name of CSR Obo
     */
    setApiOboUser(email, role) {
        this.http.defaults.headers['X-byzzer-obo'] = email;
        this.http.defaults.headers['X-byzzer-obo-role'] = role;
    }

    /**
     * This is meant to be used in {@link clearOboUserFromRequestHeaders} through the instances
     */
    clearApiOboUser() {
        delete this.http.defaults.headers['X-byzzer-obo'];
        delete this.http.defaults.headers['X-byzzer-obo-role'];
    }

    /**
     * A wrapper around axios.get that adds our custom error handling
     * @param url
     * @param config
     * @returns {Promise<any>}
     */
    async httpGet<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
        try {
            const {data} = await this.http.get(url, config);
            return data;
        } catch (err) {
            throw normalizeHttpError(err);
        }
    }
    /**
     * A wrapper around axios.post that adds our custom error handling
     * @param url
     * @param body
     * @param config
     * @returns {Promise<any>}
     */
    public async httpPost<T = any>(url: string, body: any, config?: AxiosRequestConfig): Promise<T> {
        try {
            const {data} = await this.http.post(url, body, config);
            return data;
        } catch (err) {
            throw normalizeHttpError(err);
        }
    }

    /**
     * A wrapper around axios.post that adds our custom error handling
     * @param url
     * @param body
     * @param config
     * @returns {Promise<AxiosResponse<any>>}
     */
    public async rawHttpPost<T>(url: string, body: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        try {
            return this.http.post(url, body, config);
        } catch (err) {
            throw normalizeHttpError(err);
        }
    }
    /**
     * A wrapper around axios.patch that adds our custom error handling
     * @param url
     * @param body
     * @param config
     * @returns {Promise<any>}
     */
    public async httpPatch<T>(url: string, body: any, config?: AxiosRequestConfig): Promise<T> {
        try {
            const {data} = await this.http.patch(url, body, config);
            return data;
        } catch (err) {
            throw normalizeHttpError(err);
        }
    }
    /**
     * A wrapper around axios.put that adds our custom error handling
     * @param url
     * @param body
     * @param config
     * @returns {Promise<any>}
     */
    public async httpPut<T = any>(url: string, body?: any, config?: AxiosRequestConfig): Promise<T> {
        try {
            const {data} = await this.http.put(url, body, config);
            return data;
        } catch (err) {
            throw normalizeHttpError(err);
        }
    }
    /**
     * A wrapper around axios.delete that adds our custom error handling
     * @param url
     * @param options
     * @returns {Promise<any>}
     */
    public async httpDelete(url, ...options): Promise<void> {
        try {
            const {data} = await this.http.delete(url, ...options);
            return data;
        } catch (err) {
            throw normalizeHttpError(err);
        }
    }
}

/**
 * ApiClient with not authorization or tenant information
 */
export const defaultPublicApiClient = new ApiClient();
/**
 * ApiClient with both authorization and tenant information
 */
export const defaultTenantApiClient = new ApiClient({includeTenantId: true, enableAuth: true});

/**
 *
 * common error handle that should be used by all functions
 * @param err
 */
export function normalizeHttpError(err: any): any {
    // todo: add common error handling and event triggering for connectivity issues
    if(err?.isAxiosError || err?.code === 'ERR_NETWORK') {
        return {
            status: -1,
            code: 'network_error',
        };
    }

    if (err?.response) {
        const { status, data } = err.response;
        return {
            status,
            code: data?.code,
            id: data?.id,
        };
    }

    return err;
}
