import React from 'react';
import iconassortment from '../../../images/icons/alerticons/icon-assortment.svg';
import iconmarketshare from '../../../images/icons/alerticons/icon-market-share.svg';
import iconmarkettrends from '../../../images/icons/alerticons/icon-market-trends.svg';
import iconpricing from '../../../images/icons/alerticons/icon-pricing.svg';
import iconpromotions from '../../../images/icons/alerticons/icon-promotions.svg';
import iconmarkettrendalert from '../../../images/icons/alerticons/icon-market-trend-alert.svg';
import iconassortmentactionalert from '../../../images/icons/alerticons/icon-assortment-action-alert.svg'
import iconbrandtrend from '../../../images/icons/alerticons/icon-brand-trend.svg';
import iconpricepromotiontrend from '../../../images/icons/alerticons/icon-price-position-trend.svg';
import icongrowthalert from '../../../images/icons/alerticons/icon-growth-alert.svg';
import parse from 'react-html-parser';

import {
    returnColorByDirection,
    formatPercent,
    toTitleCase,
    numberWithCommas,
    formatPercentWithSign,
    txtColor,
    redColor,
    greenColor,
    coloredSpan,
    valueCheck,
    formatPercentWithoutSign,
    formatTitle,
    formatDateMMDDYYYY,
    numberWith2Decimal
} from '../utils/alertUtil1_4';
import { ByzzerLink } from '@/components/form';
import { NavLink, useNavigate } from 'react-router-dom';
import { ByzzerButton } from '@byzzer/ui-components';

function AlertCardView1_4(props) {
    let alert = props.CardData;
    let viewReportAlertsSKU = props.viewReportAlertsSKU;
    const alertMap = props.alertMap;
    // let results = JSON.parse(alert.results);
    let results = alert.results;
    //console.log("alert ", alert)

    let alertResults = JSON.parse(JSON.stringify(results).replace(/Fmcg/g, 'FMCG'));
    let alertConfig = alert.actionConfig.insightParams ? alert.actionConfig.insightParams : alert.actionConfig;
    alertConfig.focus_brand = toTitleCase(alertConfig.focus_brand);
    alertConfig.category = toTitleCase(alertConfig.category);


    // ------------------------------------CORE ALERTS------------------------------------

    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'MARKET_SHARE') {
        alert.title = 'Market Share';
        alert.blurb = parse(
            `${alertConfig.focus_brand}'s share of ${alertConfig.category} is ${formatPercent(
                alertResults.share_of_category
            )}%, ${txtColor(`${alertResults.share_of_category_change_gain_or_decline} of ${formatPercent(
                alertResults.share_of_category_change
            )} points`, `${alertResults.share_of_category_change_gain_or_decline}`)}.`
        );
        alert.iconPath = iconmarketshare;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'COMPETITIVE_PRICING') {
        alert.title = 'Pricing';
        alert.blurb = parse(
            `${alertConfig.focus_brand}'s pricing position vs. competitors is ${txtColor(`${formatPercentWithSign(alertResults.ratio_change)}%`, alertResults.ratio_change, true)}.`
        );
        alert.iconPath = iconpricing;
        //alert.blurb = `Total category average price is $${alertResults.data.category_average_price}, ${alertResults.data.category_price_change_direction} ${formatPercent(alertResults.data.category_average_price_ya_change_percent)}% vs. prior year.`
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'DISTRIBUTION_GAIN_AND_LOSSES') {
        alert.title = 'Distribution';
        //alert.blurb = `Category distributions are ${alertResults.data.category_tdp_direction} ${formatPercent(alertResults.data.category_tdps_change_ya)}% vs. prior year`;
        alert.blurb = parse(
            `${alertConfig.focus_brand}'s share of distribution ${txtColor(`${alertResults.share_of_tdp_diff_direction} by ${formatPercent(Math.abs(alertResults.share_of_tdp_diff))} share points`, `${alertResults.share_of_tdp_diff_direction}`)}.`
        );
        alert.iconPath = iconassortment;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'PROMOTION_EFFICIENCY') {
        alert.title = 'Promotion Efficiency';
        alert.blurb = parse(
            `${alertConfig.focus_brand}'s promotion efficiency is $${alertResults.promotion_efficiency
            }, ${alertResults.change_promotion_efficiency_direction} ${txtColor(`${formatPercentWithSign(alertResults.change_promotion_efficiency)}%`, alertResults.change_promotion_efficiency)}.`
        );

        // ${formatPercent(
        //     Math.abs(alertResults.change_promotion_efficiency)
        // )}%`;
        alert.iconPath = iconpromotions;
        //alert.blurb = `Your promotion efficiency is ${alertResults.data.promotion_efficiency}( ${alertResults.data.change_promotion_efficiency_direction} ${alertResults.data.change_promotion_efficiency}% vs. prior year).`
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'ECOSYSTEM_ALERT') {
        alert.title = 'Category Performance';
        let alertResults_category_sales_change_display_market_performance = valueCheck(alertResults.category_sales_change_direction) ? ` ${formatPercent(alertResults.category_sales_change)}%` : '';
        alert.blurb = parse(
            `${toTitleCase(alertResults.category)} sales are $${numberWithCommas(
                alertResults.category_sales
            )}, ${txtColor(`${alertResults.category_sales_change_direction}${alertResults_category_sales_change_display_market_performance}`, `${alertResults.category_sales_change_direction}`)}.`
        );
        alert.iconPath = iconmarkettrends;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'GROWTH_ALERT') {
        alert.title = 'Growth Alert';
        alert.blurb = parse(
            `${alertConfig.focus_brand} sales in ${alertConfig.category} were
            ${valueCheck(alertResults.category_sales_change_direction)
                ? `${txtColor(`${alertResults.category_sales_change_direction} ${formatPercentWithoutSign(alertResults.category_sales_change)}%`, `${alertResults.category_sales_change_direction}`)}`
                : `${alertResults.category_sales_change_direction}`}        
            vs. the previous 4 weeks.`
        );
        alert.iconPath = icongrowthalert;
    }

    // ------------------------------------SMART ALERTS------------------------------------

    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'BRAND_TREND_ALERT') {
        alert.title = 'Brand Trend Alert';
        alert.blurb = parse(
            `${formatTitle(alertResults.sales_change_brand)} in ${formatTitle(alertResults.sales_change_category)} is 
                           <span style="color:${returnColorByDirection(
                alertResults.sales_change_direction
            )}">${alertResults.sales_change_direction}</span>
                ${valueCheck(alertResults.sales_change_direction)
                ? `<span style="color:${returnColorByDirection(
                    alertResults.sales_change_direction
                )}"> ${formatPercent(alertResults.sales_change)}%</span>`
                : ''}
                in sales this week vs. the previous 7 weeks.`
        );
        alert.iconPath = iconbrandtrend;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'PRICE_POSITION_TREND_ALERT') {
        alert.title = 'Price Position Trend Alert';
        alert.blurb = parse(
            `${formatTitle(alertResults.filter_brand)} in ${formatTitle(alertResults.filter_category)} is priced at the ${formatPercent(alertResults.price_percentile)}% percentile in ${formatTitle(alertResults.top_market)}.`
        );
        alert.iconPath = iconpricepromotiontrend;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'MARKET_TREND_ALERT') {
        alert.title = 'Market Trend Alert';
        let alertResults_category_sales_change_display_market_performance = valueCheck(alertResults.category_sales_change_direction) ? ` ${formatPercent(alertResults.category_sales_change)}%` : '';
        alert.blurb = parse(
            `Market is 
            <span style="color:${returnColorByDirection(alertResults.sales_change_direction)}">${alertResults.sales_change_direction}</span>         
            <span style="color:${returnColorByDirection(alertResults.sales_change_direction)}"> 
            ${formatPercentWithoutSign(alertResults.sales_change_percent)}%</span> 
            in sales this week vs. the previous 7 weeks`
            // `${alertResults.sales_change_market} is ${txtColor(`${alertResults.category_sales_last_7w_direction} ${formatPercent(alertResults.category_sales_last_7w_percent)}%`, alertResults.category_sales_last_7w_direction)} in sales this week vs. the previous 7 weeks`
        );
        alert.iconPath = iconmarkettrendalert;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'ASSORTMENT_ACTION') {
        // console.log('alertResults', alertResults)
        alert.title = 'Assortment Opportunity Alert';
        let duration = formatDateMMDDYYYY(alertResults.filter_duration.split('to')[1].trim());
        alert.blurb = parse(
            `There is ${coloredSpan(redColor, `$${numberWith2Decimal(alertResults.at_risk_dollar_impact)} at risk`)} and ${coloredSpan(greenColor, `$${numberWith2Decimal(alertResults.expand_dollar_impact)} in opportunities`)} to expand`
            // `Track brand assortment opportunities and risks across markets of your choice`
        );
        alert.iconPath = iconassortmentactionalert;
    }

    const navigate = useNavigate();
    
    const redirectToReport = async () => {
        navigate({
            pathname: '/my-reports/report-view',
            state: {
                userData: {reportSku: alert.productSku, reportParams: alert.actionConfig, actionId: alert.id},
                sourceUrl: '/dashboard/my_alerts'
            }
        });
    };

    return (
        <>
            <div className="card">
                <div className="card_image">
                    <img src={alert.iconPath} alt={alert.title} />
                    {/* <img src={reportIcon} alt={item.title} />  */}
                </div>
                <h2 className="card_heading_report">{alert.title}</h2>
                <p className="card_blurb">{alert.blurb}</p>                

                {viewReportAlertsSKU.includes(alert.productSku) &&
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '8fr 1fr 8fr'
                        }}>
                        <ByzzerButton
                            label={'See More'}
                            onClick={() => {
                                props.handleClick(alert);
                            }}
                        />

                        <span style={{
                            // fontSize: inherit,
                            fontWeight: 600,
                            color: '#000000', // $colorPrimaryPink,
                            width: '50%'
                            // cursor: pointer,
                            // borderBottom: 1px solid $colorPrimaryPink

                            // &:hover {
                            //   color: $colorPrimaryBlack
                            // }                          
                        }}> | </span>

                        <span>
                            <ByzzerLink
                                label={'View Report'}
                                onClick={() => redirectToReport()}
                            />
                        </span>
                    </div>
                }
                
                {!viewReportAlertsSKU.includes(alert.productSku) &&
                    <ByzzerButton
                        label={'See More'}
                        onClick={() => {
                            props.handleClick(alert);
                        }}
                    />
                }
            </div>
            {/* )} */}

        </>
    );
}





export default AlertCardView1_4;
