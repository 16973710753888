export const formatPercent = (num) => {
    if (num === null || num === undefined) {
        return '';
    }
    let str = (num * 100).toFixed(1);
    let newStr = addComma(str.split('.')[0]) + '.' + str.split('.')[1];
    return newStr.includes('.') ? newStr : newStr + '.0';
};

export const formatPrice = (num) => {
    try {
        if (num === null || num === undefined) {
            return '';
        }
        num = parseFloat(num);
        let decimals = num.toString().split('.')[1];
        if (!decimals) return num + '.00';
        else if (decimals.length === 1) return num + '0';
        else return num.toFixed(2);
    } catch (e) {
        throw e;
    }
};
export const formatPercentWithSign = (num) => {
    if (num === null || num === undefined) {
        return '';
    }
    let addPositive = '';
    if (num > 0) addPositive = '+';
    let str = addPositive + (num * 100).toFixed(1);
    let newStr = addComma(str.split('.')[0]) + '.' + str.split('.')[1];
    return newStr.includes('.') ? newStr : newStr + '.0';
};

// Add default values provided in params in places based on these conditions
export const defaultValue = (num, params) => {
    if (num <= -1 && params.hasOwnProperty('delisted')) return params.delisted;
    else if (
        num === null &&
        params.hasOwnProperty('ty') &&
        params.hasOwnProperty('ly') &&
        params.ly === 0 &&
        params.ty > 0
    )
        return params.defValue;
    else return num;
};

export const toTitleCase = (str, forComma = false) => {
    try{
          if (str === null || str === undefined) {
                return '';
            }
        
          return str.replace(/\w\S*/g, function (txt) {
                let formattedText = '';
                if (txt.indexOf('/') > -1) {
                    formattedText = SplitUpperCase(txt, '/');
                } else if (txt.indexOf(',') > -1 && forComma === true) {
                    formattedText = SplitUpperCase(txt, ',');
                } else {
                    formattedText = txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
                formattedText = formattedText.replace(/Fmcg/g, 'FMCG');
                return formattedText;
            });
    }catch(exception){
            return str;
    }
};

function SplitUpperCase(txt, splChar) {
    let first = txt.split(splChar)[0];
    let splitTxt = txt.split(splChar);
    let txtRes = '';
    if (first) {
        txtRes = first.charAt(0).toUpperCase() + first.substr(1).toLowerCase();
    }

    if(splChar === ',')
    splChar += ' ';
    splitTxt.shift();
    if (splitTxt) {
        splitTxt.forEach((element) => {
            txtRes += splChar + element.charAt(0).toUpperCase() + element.substr(1).toLowerCase();
        });
    }
    return txtRes;
}


export const formatDateDDMMYYYY = (str) => {
    if (str === null || str === undefined) {
        return '';
    }
    function pad(s) {
        return s < 10 ? '0' + s : s;
    }
    let d = new Date(str);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
};


export const formatDateMMDDYYYY = (str) => {
    if (str === null || str === undefined) {
        return '';
    }
    function pad(s) {
        return s < 10 ? '0' + s : s;
    }
    let d = new Date(str);
    return [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join('/');
};

export const formatDateMinusOneMMDDYYYY = (str) => {
    if (str === null || str === undefined) {
        return '';
    }
    function pad(s) {
        return s < 10 ? '0' + s : s;
    }
    let d = new Date(str);
    return [pad(d.getMonth() + 1), pad(d.getDate() - 1), d.getFullYear()].join('/');
};

export const formatDateYYYYMMDD = (str) => {
    if (str === null || str === undefined) {
        return '';
    }
    function pad(s) {
        return s < 10 ? '0' + s : s;
    }
    let d = new Date(str);
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('/');
};

export const numberWithCommas = (num) => {
    if (num > 999 && num < 1000000) {
        return addComma((num / 1000).toFixed(0)) + 'K'; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
        return addComma((num / 1000000).toFixed(0)) + 'M'; // convert to M for number from > 1 million
    } else {
        return num.toFixed(0);
    }
};

export const generateTable = (arr, config = {}) => {
    let htmlStr = "<table style='border-collapse: collapse; border: none;'>";
    let style = `style="text-align: center;"`;
    let marketColWidth = 50;
    let restWidth = (100 - marketColWidth) / (Object.keys(config.columnMapping).length - 1);
    htmlStr += `<colgroup><col style="width:${marketColWidth}vw">`;

    for (let i = 0; i < Object.keys(config.columnMapping).length - 1; i++) {
        htmlStr += `<col style="width:${parseFloat(restWidth.toFixed(1))}%">`;
    }
    htmlStr += '</colgroup><tr style="background: #000000; color: white;">';
    Object.keys(config.columnMapping).forEach((key) => {
        htmlStr += `<th style="text-align: center;">${config.columnMapping[key]}</th>`;
    });
    htmlStr += `</tr>`;
    let flag = 0;
    arr.forEach((item) => {
        flag += 1;
        let backgroundColor = returnRowBackground(flag);
        htmlStr += `<tr style="background: ${backgroundColor};">`;
        Object.keys(config.columnMapping).forEach((key) => {
            if (config.percentKeys.includes(key) && typeof item[key] === 'number') {
                let val = formatPercent(item[key]);
                htmlStr += `<td ${style}> ${val}%</td>`;
            } else if (config.titleCaseColumns.includes(key)) htmlStr += `<td ${style}> ${toTitleCase(item[key])}</td>`;
            else if (config.dollarColumns.includes(key) && typeof item[key] === 'number') {
                let itemVal = Math.abs(formatPrice(item[key]));
                htmlStr += `<td ${style}> ${formatPrice(item[key]) > 0 ? '$'+itemVal : '-$'+itemVal }</td>`;
            }
            else htmlStr += `<td ${style}> ${item[key]}</td>`;
        });
        htmlStr += '</tr>';
    });
    htmlStr += '</table>';
    return htmlStr;
};

const returnRowBackground = (flag) => {
    if(flag % 2 === 0){
        return '#f2f2f2';        
    }else {
        return '#ffffff';
    }
};

export const generateOrderedList = (arr, olType, value) => {
    let htmlStr = '<ol style="margin-left:2em">';
    if (olType === 'growing_market') {

        arr.forEach((item) => {
            htmlStr += `<li>${toTitleCase(item.market)} (${formatPercent(item.of_category)}% of total US FMCG, `;
            if (!valueCheck(item.retailer_growth)) htmlStr += `${item.retailer_growth}) </li>`;
            else if (value === 'pos') {
                htmlStr += `sales are ${returnColorSpan(
                    `${formatPercentWithSign(item.retailer_growth)}%`,
                    `positive`)}) </li>`; // green
            } else if (value === 'neg') {
                htmlStr += `sales are ${returnColorSpan(
                    `${formatPercentWithSign(item.retailer_growth)}%`,
                    `negative`
                )}) </li>`; // red
            }
        });
    } else if (olType === 'top_market_sales') {
        arr.forEach((item) => {
            htmlStr += `<li>${toTitleCase(item.market)} (${formatPercent(
                item.of_sales_from_retailer
            )}% of total US FMCG, `;
            if (!valueCheck(item.change_of_sales_from_retailer)) {
                htmlStr += `${item.change_of_sales_from_retailer})</li>`;
            }
            else {
                htmlStr +=
                    `sales are ${txtColor(
                        `${formatPercentWithSign(item.change_of_sales_from_retailer)}%`,
                        item.change_of_sales_from_retailer
                    )}) </li>`;
            }
        });
    } else if (olType === 'largest_brand_sales') {
        arr.forEach((item) => {
            htmlStr += `<li>${toTitleCase(item.brand)} (${formatPercent(item.of_sales_from_brand)}% of category, `;
            if (!valueCheck(item.change)) htmlStr += ` ${item.change})</li>`;
            else htmlStr += `sales are ${txtColor(`${formatPercentWithSign(item.change)}%`, item.change)}) </li>`;
        });
    }
    else if (olType === 'highest_share_category') {
        arr.forEach((item) => {
            htmlStr += `<li>${toTitleCase(item.brand)}, ${formatPercent(item.share_of_category)}% share, `;
            if (!valueCheck(item.share_point_change_direction))
                htmlStr += `${item.share_point_change_direction}</li>`;
            else
                htmlStr += `<span style="color:${returnColorByDirection(item.share_point_change_direction)}">
                ${item.share_point_change_direction + ' ' + numberWith1Decimal(item.share_point_change)} points</span></li>`;
        });
    }
    else if (olType === 'highest_share_point') {
        arr.forEach((item) => {
            htmlStr += `<li>${toTitleCase(item.brand)}, ${formatPercent(item.share_of_category)}% share, `;
            if (!valueCheck(item.share_point_change_direction))
                htmlStr += `${item.share_point_change_direction}</li>`;
            else
                htmlStr += `<span style="color:${returnColorByDirection(item.share_point_change_direction)}">
                ${item.share_point_change_direction + ' ' + numberWith1Decimal(item.share_point_change)} points</span></li>`;
        });
    }
    htmlStr += '</ol>';
    htmlStr = htmlStr.replace(/Fmcg/g, 'FMCG');
    return htmlStr;
};

export const generateOrderedListWithBrandAndGrowth = (arr) => {
    let htmlStr = '<ol style="margin-left:2em">';
    arr.forEach((item) => {
        htmlStr += `<li>${toTitleCase(item.brand)} (sales are ${numberWithCommas(item.total_dollars)}, `;
        let item_brand_growth_formatted = item.brand_growth;
        let tempColorDirection = valueCheck(item.brand_growth) ? `positive` : `regular`;
        if (valueCheck(item.brand_growth))
            item_brand_growth_formatted = `${formatPercentWithSign(`${item.brand_growth}`)}%`;
        htmlStr += `${returnColorSpan(`${item_brand_growth_formatted}`, tempColorDirection)})</li>`;
    });
    htmlStr += '</ol>';
    return htmlStr;
};

export const generateBasicOrderedList = (arr) => {
    let htmlStr = '<ol style="margin-left:2em">';
    arr.forEach((item) => {
        htmlStr += `<li>${toTitleCase(item)} </li>`;
    });
    htmlStr += '</ol>';
    return htmlStr;
};

function addComma(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const textBold = (str) => {
    return str.bold();
};

// ---------------------------------color and formatting changes ------------------------------

export const getDirection = (num, positive, negative, flat) => {
    if (num >= 0.0005) {
        return positive;
    } else if (num < 0.0005 && num > -0.0005 && num !== null) return flat;
    else if (num <= -0.0005) return negative;
    else return num;
};

// txt = original txt, newtxt is expected txt, value decides the logic
export const returnNewtext = (txt, value) => {
    // if value is null, return empty string, else original text
    let newtxt = value ? txt : '';
    return newtxt;
};

export const coloredSpan = (color, value) => {
    return `<span style="color:${color}">${value}</span>`;
};

export const returnColorSpan = (txt, colorDirection) => {
    let color = '#000000';
    if (colorDirection === 'positive') {
        color = '#0fbc61';
    }
    if (colorDirection === 'negative') {
        color = '#ed4350';
    }
    if (colorDirection === 'flat') {
        color = '#ffbf3f';
    }
    if (colorDirection === 'ByzzerPink') {
        color = '#ef426f'; // pink
    }    
    if (colorDirection === 'ByzzerReportLink') {
        color = ByzzerReportLinkColor;
    }
    return `<span style="color: ${color}">${txt}</span>`;
};

export const promoEffColor = (txt, value) => {
    if (value >= 2) return `<span style="color: #0fbc61">${txt}</span>`; //green
    else if (value < 2 && value >= 1) return `<span style="color: #ed4350">${txt}</span>`; //red
    else return `<span style="color: #ffbf3f">${txt}</span>`; //yellow
};

// text is raw html, value decides the color
export const txtColor = (txt, direction, reverse = false) => {
    let classState = '';

    if (
        (typeof direction === 'string' &&
            (direction === 'up' ||
                direction.includes('increase') ||
                direction === 'over' ||
                direction.includes('gain') ||
                direction === 'grew')) ||
        (typeof direction === 'number' && direction >= 0.0005)
    ) {
        classState = reverse ? 'negative-value' : 'positive-value';
    }
    else if (
        (typeof direction === 'string' &&
            (direction === 'down' ||
                direction.includes('decrease') ||
                direction === 'under' ||
                direction.includes('lost') ||
                direction.includes('decline'))) ||
        (typeof direction === 'number' && direction <= -0.0005)
    ) {
        classState = reverse ? 'positive-value' : 'negative-value';
    }

    else if (
        (typeof direction === 'number' && direction < 0.0005 && direction > -0.0005 && direction !== null) ||
        (typeof direction === 'string' && (direction.includes('change') || direction.includes('flat')))
    ) {
        classState = 'flat-value';
    }
    else {
        classState = 'regular-value';
    }
    let htmlStr = `<span class=${classState}>${txt}</span>`;
    return htmlStr;
};

export const valueCheck = (direction) => {
    if (
        typeof direction === 'string' &&
        (direction.toLowerCase().includes('delisted') ||
            direction.toLowerCase().includes('new') ||
            direction.toLowerCase().includes('eliminated'))
    )
        return false;
    return true;
};

export const numberWith2Decimal = (num) => {
    // Nine Zeroes for Billions
    return Math.abs(num) >= 1.0e9
        ? Math.abs(num / 1.0e9).toFixed(2) + 'B'
        : // Six Zeroes for Millions
        Math.abs(num) >= 1.0e6
            ? Math.abs(num / 1.0e6).toFixed(2) + 'M'
            : // Three Zeroes for Thousands
            Math.abs(num) >= 1.0e3
                ? Math.abs(num / 1.0e3).toFixed(2) + 'K'
                : Math.abs(num).toFixed(2);
};

export const formatPercentWithoutSign = (num) => {
    if (num === null || num === undefined) {
        return num;
    }
    let str = (Math.abs(num) * 100).toFixed(1);
    let newStr = addComma(str.split('.')[0]) + '.' + str.split('.')[1];
    return newStr.includes('.') ? newStr : newStr + '.0';
};

export const flatColor = '#ffbf3f';
export const greenColor = '#0fbc61';
export const orangeColor = '#ed4350'; //'#ff7b4d'
export const byzzerPinkColor = '#ef426f';
export const blackColor = '#000000';
export const redColor = '#ff0000';
export const blueColor = '#0000ff';
export const ByzzerPink = '#ef426f';
export const ByzzerReportLinkColor = '#000000';

export const returnColorByDirection = (direction, reverse = false) => {
    if (
        (typeof direction === 'string' &&
            (direction === 'up' ||
                direction.includes('increase') ||
                direction === 'over' ||
                direction.includes('gain') ||
                direction === 'grew')) ||
        (typeof direction === 'number' && direction >= 0.0005)
    )
        return reverse ? orangeColor : greenColor;
    else if (
        (typeof direction === 'string' &&
            (direction === 'down' ||
                direction.includes('decrease') ||
                direction === 'under' ||
                direction.includes('lost') ||
                direction.includes('decline'))) ||
        (typeof direction === 'number' && direction <= -0.0005)
    )
        return reverse ? greenColor : orangeColor;
    else if (
        (typeof direction === 'number' && direction < 0.0005 && direction > -0.0005 && direction !== null) ||
        (typeof direction === 'string' && (direction.includes('change') || direction.includes('flat')))
    )
        return flatColor;
    else return blackColor;
};

export const generateOrderedListforPricePercentile = (arr) => {
    let htmlStr = '<ol style="margin-left:2em">';
    arr.forEach((item) => {
        htmlStr += `<li>${toTitleCase(item.market)} price percentile of ${formatPercent(item.price_percentile)}%, `;
        if (!valueCheck(item.price_percentile_direction)) htmlStr += `${item.price_percentile_direction}</li>`;

        else htmlStr += `<span style="color:${returnColorByDirection(
            item.price_percentile_direction, true
        )}">${item.price_percentile_direction} ${formatPrice(item.price_percentile_change)}% points</span></li>`;
    });
    htmlStr += '</ol>';
    return htmlStr;
};

export const numberWith1Decimal = (num) => {
    // Nine Zeroes for Billions
    return Math.abs(num) >= 1.0e9
        ? Math.abs(num / 1.0e9).toFixed(1) + 'B'
        : // Six Zeroes for Millions
        Math.abs(num) >= 1.0e6
            ? Math.abs(num / 1.0e6).toFixed(1) + 'M'
            : // Three Zeroes for Thousands
            Math.abs(num) >= 1.0e3
                ? Math.abs(num / 1.0e3).toFixed(1) + 'K'
                : Math.abs(num).toFixed(1);
};

export const pluralCheck = (countNum, word, quote = false) => {
    if (countNum > 1) {
        if (word === 'category') {
            if(quote === true){
                return "categories'";
            }else{
                return 'categories';
            }
        }
        return word + "s";
    }
    return word;
}

export const subtractWeeks = (date, weeks) => {
    if (!date) {
        return date;
    }
    let dt = new Date(date);
    return dt.setDate(dt.getDate() - weeks * 7);
}

export const generateOrderedListforMarket = (arr,brand_arr,filter_brand,olType, value) => {
    let htmlStr = '<ol style="margin-left:2em">';
    if (olType === 'growing_market') {
        arr.forEach((item,index) => {

            let sales_growth_brand = '';	
            let color = '';

            let sales_growth_brand_value = brand_arr[index]['sales_of_focus_brand'];	
            if(sales_growth_brand_value !== 'delisted' && sales_growth_brand_value !== 'new' && sales_growth_brand_value !== '0') {	
                sales_growth_brand = formatPercentWithSign(sales_growth_brand_value)+'%';	
                color = getDirectionWithZero(sales_growth_brand_value,greenColor,redColor,blackColor);	
            } else {	
                sales_growth_brand = sales_growth_brand_value !== '0' ? sales_growth_brand_value : '0.0%';	
                color = blackColor;	
            }
            htmlStr += `<li>${toTitleCase(item.market)} (${formatPercent(item.of_category)}% of total US FMCG, `;
            if (!valueCheck(item.retailer_growth)) htmlStr += `${item.retailer_growth}) </li>`;
            else if (value === 'pos') {
                htmlStr += `sales are ${returnColorSpan(
                    `${formatPercentWithSign(item.retailer_growth)}%`,
                    `positive`)})`; // green
                if(filter_brand) {
                    htmlStr += ` and ${brand_arr[index]['sales_from_retailer']}% of ${filter_brand} US FMCG, <span style="color: ${color}">${sales_growth_brand}</span></li>`
                }
                    else  htmlStr += `</li>`;
            } else if (value === 'neg') {

                htmlStr += `sales are ${returnColorSpan(
                    `${formatPercentWithSign(item.retailer_growth)}%`,
                    `negative`)})`; // red
                if(filter_brand){
                 htmlStr += ` and ${brand_arr[index]['sales_from_retailer']}% of ${filter_brand} US FMCG, <span style="color: ${color}">${sales_growth_brand}</span></li>`
                }
                else htmlStr += `</li>`;

            }
        });
    } else if (olType === 'top_market_sales') {
        arr.forEach((item,index) => {
           
            let largest_sales_market = '';
                        let color = '';
                        let largest_sales_market_value = brand_arr[index]['sales_of_focus_brand'];

                        if(largest_sales_market_value !== 'delisted'  && largest_sales_market_value !== 'new' && largest_sales_market_value !== '0') {
                            largest_sales_market = formatPercentWithSign(largest_sales_market_value)+'%';
                            color = getDirectionWithZero(largest_sales_market_value,greenColor,redColor,blackColor);
                        } else {
                            largest_sales_market = largest_sales_market_value !== '0' ? largest_sales_market_value : '0.0%';
                            color = blackColor;
                        }

                        htmlStr += `<li>${toTitleCase(item.market)} (${formatPercent(
                            item.of_sales_from_retailer
                        )}% of total US FMCG, `;
                        if (!valueCheck(item.change_of_sales_from_retailer))
                        htmlStr += `${item.change_of_sales_from_retailer})`;
                        else
                        htmlStr += `sales are <span style="color:${returnColorByDirection(
                                item.change_of_sales_from_retailer
                            )}">${formatPercentWithSign(item.change_of_sales_from_retailer)}%</span>)`;
                        if(filter_brand) htmlStr += ` and ${brand_arr[index]['sales_from_retailer']}% of ${filter_brand} US FMCG, <span style="color: ${color}">${largest_sales_market}</span></li>`
                        else htmlStr += `</li>`;
        });
    } 
    htmlStr += '</ol>';
    htmlStr = htmlStr.replace(/Fmcg/g, 'FMCG');
    return htmlStr;
};

export const getDirectionWithZero = (num, positive, negative, flat) => {
    if(num === null) return '';
    num = num.toString().replace(/,/g, '');
    if (num > 0) {
        return positive;
    } else if (num === 0) return flat;
    else if (num < 0) return negative;
    else return num;
};

export const checkMultipleCategory = (category_arr , showName = false, quote = false) => {
    let new_category = '';
    let category = (typeof category_arr !== 'string') ? category_arr.map(o => toTitleCase(o)) : toTitleCase(category_arr).split();
   
    if (category.length) {
       
        if (category.length === 1) {
            new_category = category[0];
        }else if (category.length === 2) {
            new_category = category.join(' and ');
        }else if (category.length > 2) {          
            if(showName){
               
               let temp_category = category.slice(0, category.length - 2);
                new_category = temp_category.join(', ') + ', ' + category[category.length - 2] + ' and ' + category[category.length - 1];

             }else{
                new_category = quote ? "the categories'" : "the categories";
            }
        }
        return new_category
    }
    return new_category;    // alert should fail ideally if no category
}

export const generateOrderedListLargestBrandSales = (arr, olType, category) => {
    let htmlStr = '<ol style="margin-left:2em">';
    if (olType === 'largest_brand_sales') {
        arr.forEach((item) => {
            htmlStr += `<li>${toTitleCase(item.brand)} (${formatPercent(item.of_sales_from_brand)}% of ${category}, `;
            if (!valueCheck(item.change)) htmlStr += ` ${item.change})</li>`;
            else htmlStr += `sales are ${txtColor(`${formatPercentWithSign(item.change)}%`, item.change)}) </li>`;
        });
    }
    
    htmlStr += '</ol>';
    htmlStr = htmlStr.replace(/Fmcg/g, 'FMCG');
    return htmlStr;
};
