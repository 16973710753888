import { ProTipsWindow } from './ProTips';
import { ByzzerPopup } from '@byzzer/ui-components';
import './ProTips.scss';

const baseClassName = 'protips-section';

type ProTipsSectionProps = {
    includeThresholds?: boolean;
    includeSalesThresholds?: boolean;
};

export function ProTipsSection({ includeThresholds, includeSalesThresholds }: ProTipsSectionProps) {
    return (
        <>
            <div className={`${baseClassName}__subset-option`}>
                <div className={`${baseClassName}__subset-option-text-thresholds`}>
                    <span>
                        Would you like to filter the manufacturers included in the report based on{' '}
                        {includeThresholds ? 'sales or growth  ' : includeSalesThresholds ? 'sales ' : 'growth '}
                        thresholds? {' '}
                    </span>
                    <ByzzerPopup linkText={'Pro Tips'} content={<ProTipsWindow title="FS Trendsetters Report Pro Tips" />} />
                </div>
            </div>
        </>
    );
}
