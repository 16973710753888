import { useState, useCallback } from 'react';

interface UseUndo<T> {
    addStateToHistory: (currentState: T) => void;
    undo: () => T | null;
    canUndo: boolean;
}

export const useUndo = <T,>(): UseUndo<T> => {
    const [history, setHistory] = useState<T[]>([]);

    const addStateToHistory = useCallback((currentState: T) => {
        setHistory((prevHistory) => [currentState, ...prevHistory]);
    }, []);

    const undo = useCallback(() => {
        if (history.length === 0) return null;
        const [lastState, ...newHistory] = history;
        setHistory(newHistory);
        return lastState;
    }, [history]);

    return { addStateToHistory, undo, canUndo: history.length > 0 };
};
