import './ExpandablePanelToggle.scss';
import classnames from "classnames";
import isString from 'lodash/isString';
import { useExpandablePanels} from "./ExpandablePanelContext";
import {ByzzerButton, ByzzerButtonProps} from "@byzzer/ui-components";
import { useTrackEvent } from '@/analytics';
import { useEffect } from 'react';

export type ExpandablePanelToggleProps = {
    panel: string;
    trackClick?: any;
    forceClosePanel: boolean;
} & ByzzerButtonProps;

const baseClassName = 'panel-expansion-toggle';

export function ExpandablePanelToggle({className, panel, trackClick, forceClosePanel, ...props}: ExpandablePanelToggleProps) {

    const trackEvent = useTrackEvent();
    const {toggleActivePanel, activePanel} = useExpandablePanels();
    const isActive = activePanel === panel && panel !== undefined;

    function handleClick() {
        toggleActivePanel(panel)
        if (trackClick) {
            trackEvent({
                type: 'click',
                name: isString(trackClick) ? trackClick : trackClick.name,
                data: trackClick.data
            })
        }
    }

    useEffect(() => {
        if(forceClosePanel && isActive) {
            toggleActivePanel(panel)
        }
    }, [forceClosePanel])

    return <ByzzerButton
        {...props}
        tipDelay={[750, 0]}
        tipLocation={'bottom-end' as any}
        className={classnames(baseClassName, className, {
        [`${baseClassName}--expanded`]: isActive
    })} onClick={handleClick}/>
}

export default ExpandablePanelToggle;
