import {addMinutes} from "date-fns";

export function toMaxDataDates({ cps, rms, omni }: any): MaxDataDates {
    return {
        cps: normalizeTimezone(cps),
        rms: normalizeTimezone(rms),
        omni: normalizeTimezone(omni),
    };
};

/*

    if(!rawDate) return undefined;

    const date = new Date(rawDate);
    return new Date(addMinutes(date, date.getTimezoneOffset()));
*/
export function normalizeTimezone(rawDate?: string): Date | undefined {
    if (!rawDate) return undefined;

    const date = new Date(rawDate);
    return new Date(addMinutes(date, date.getTimezoneOffset()));
    // if(!date) return undefined;
    // //this will return correct dates for both US and India timezones
    // return new Date(new Date(date).getTime() + (new Date().getTimezoneOffset() * 60000));
}
