import React, { useEffect, useState } from 'react';
import './DownloadReport.scss';
import classNames from 'classnames';
import {useTenantApi} from '@/hooks/useTenantApi';
import { create as openModal } from 'react-modal-promise';
import { ByzzerModalMask } from '@/components/form/ByzzerModal';
import { ByzzerButton, ByzzerCheckbox, ByzzerSelect } from '@/components/form';
import {
    downloadReportHeading,
    downloadReportDescription,
    downloadOptions,
    disabledDownloadOptions,
    disabledOptionMessage,
    downloadReportFooterNote,
    mandatoryTiles,
} from '@/config/reportViewer.config';
import { showErrorModal } from '@/utils';

export function DownloadReport({ actionId, onResolve }) {
    const { fetchReportTileInfo } = useTenantApi();
    const [loading, setLoading] = useState(false);
    const [busy, setBusy] = useState(false);
    const [tileList, setTileList] = useState([]);
    const [selectedTileList, setSelectedTileList] = useState([]);
    const [heading, setHeading] = useState(<>&nbsp;</>);
    const baseClassName = 'download-report';
    const classes = classNames('byzzer-modal-wrapper', `${baseClassName}__wrapper`);

    useEffect(() => {
        loadTileInfo();
    }, []);

    function onCloseClick() {
        onResolve(false);
    }

    async function loadTileInfo() {
        setHeading(downloadReportHeading);
        try {
            setLoading(true);
            const tileData = await fetchReportTileInfo(actionId);
            if (tileData) {
                setTileList(tileData);
                setSelectedTileList(addAllTiles(tileData));
            }
        } catch (err) {
            setLoading(false);
            onResolve(false);
            showErrorModal(err);
        } finally {
            setLoading(false);
        }
    }

    function defaultFirstEnabledItem() {
        let optionValue = '';
        const enabledValues = downloadOptions?.filter((item) => !disabledDownloadOptions?.includes(item));
        if (enabledValues?.length > 0) {
            optionValue = enabledValues[0];
        }
        return optionValue;
    }

    function addAllTiles(tileData) {
        let currentSelections = [...selectedTileList];
        Object.keys(tileData).forEach((tileName) => {
            if (!mandatoryTiles?.includes(tileName)) {
                currentSelections.push({
                    tileName: tileName,
                    tileOption: tileData[tileName][0] ?? '',
                });
            }
        });
        return currentSelections;
    }

    function changeTile(e, tile, allFlag = false) {
        let currentSelections = [...selectedTileList];
        if (e?.target?.checked) {
            if (allFlag) {
                if (Object.keys(tileList)?.length > 0) {
                    currentSelections = addAllTiles(tileList);
                }
            } else {
                currentSelections.push({
                    tileName: tile,
                    tileOption: tileList[tile][0] ?? '',
                });
            }
        } else {
            if (allFlag) {
                currentSelections = [];
            } else {
                const tileIndex = currentSelections.findIndex((val) => val.tileName === tile);
                currentSelections.splice(tileIndex, 1);
            }
        }
        setSelectedTileList(currentSelections);
    }

    function changeTileOption(e, tile) {
        let currentSelections = [...selectedTileList];
        if (e) {
            const tileIndex = currentSelections.findIndex((val) => val.tileName === tile);
            currentSelections[tileIndex].tileOption = e;
        }
        setSelectedTileList(currentSelections);
    }

    function isTileChecked(tile) {
        return mandatoryTiles?.includes(tile) || selectedTileList.map((item) => item.tileName)?.includes(tile);
    }

    function allChecked() {
        return Object.keys(tileList)
            .filter((tl) => !mandatoryTiles?.includes(tl))
            ?.every((v) => selectedTileList.map((item) => item.tileName)?.includes(v));
    }

    function renderTileList() {
        return Object.keys(tileList).map((tile, key) => (
            <div key={key + tile} className={`${baseClassName}__tiles`}>
                <div className={`${baseClassName}__tiles-names`}>
                    <ByzzerCheckbox
                        disabled={mandatoryTiles?.includes(tile)}
                        checked={isTileChecked(tile)}
                        onChange={(e) => changeTile(e, tile)}
                        label={tile}
                    />
                </div>
                {!mandatoryTiles?.includes(tile) && (
                    <div className={`${baseClassName}__tiles-options`}>
                        <ByzzerSelect
                            value={selectedTileList.find((item) => item.tileName === tile)?.tileOption}
                            placeholder={'Select from the list'}
                            options={downloadOptions}
                            disabledOptions={downloadOptions.filter(x => !tileList[tile].includes(x))}
                            disabledOptionMessage={disabledOptionMessage}
                            onChange={(e) => changeTileOption(e, tile)}
                            disabled={!selectedTileList.find((item) => item.tileName === tile)?.tileName}
                        />
                    </div>
                )}
            </div>
        ));
    }

    function disableDownload() {
        return (
            selectedTileList?.length === 0 ||
            selectedTileList.filter((item) => item.tileName !== '')?.length >
                selectedTileList.filter((item) => item.tileOption !== '')?.length
        );
    }

    function onDownload() {
        setBusy(true);
        // These are the Tile options selected, logs below
        //console.log(selectedTileList);
        ///New Download code would come here/////
        const win = window.open('/#/download-report', '_blank');
        win.actionId = actionId;
        win.selectedTileList = selectedTileList;
        setBusy(false);
    }

    return (
        <div className={classes}>
            <div className={`${baseClassName}`}>
                <header className={`${baseClassName}__header`}>
                    <h1 className={`${baseClassName}__header-title`}>{heading}</h1>
                    <i className={`${baseClassName}__close`} onClick={onCloseClick} />
                </header>
                <main className={`${baseClassName}__content`}>
                    <ByzzerModalMask show={loading}>{loading.message}</ByzzerModalMask>
                    <div className={`${baseClassName}__section`}>
                        <div className={`${baseClassName}__description`}>{downloadReportDescription}</div>
                        <div className={`${baseClassName}__selectall`}>
                            <ByzzerCheckbox
                                checked={allChecked()}
                                onChange={(e) => changeTile(e, '', true)}
                                label={'Select All'}
                            />
                        </div>
                        <div className={`${baseClassName}__tiles-outer`}>{renderTileList()}</div>
                        <div className={`${baseClassName}__actions`}>
                            <ByzzerButton
                                label={'Download'}
                                disabled={disableDownload()}
                                onClick={onDownload}
                                busy={busy}
                            />
                        </div>
                        <div className={`${baseClassName}__footer-note`}>{downloadReportFooterNote}</div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export const openDownloadReport = openModal(DownloadReport);
