export const formatPercent = (num) => {
    if (num === null || num === undefined) {
        return '';
    }
    let str = (num * 100).toFixed(1);
    let newStr = addComma(str.split('.')[0]) + '.' + str.split('.')[1];
    return newStr.includes('.') ? newStr : newStr + '.0';
};

export const formatPrice = (num) => {
    try {
        if (num === null || num === undefined) {
            return '';
        }
        num = parseFloat(num);
        let decimals = num.toString().split('.')[1];
        if (!decimals) return num + '.00';
        else if (decimals.length === 1) return num + '0';
        else return num.toFixed(2);
    } catch (e) {
        throw e;
    }
};
export const formatPercentWithSign = (num) => {
    if (num === null || num === undefined) {
        return '';
    }
    let addPositive = '';
    if (num > 0) addPositive = '+';
    let str = addPositive + (num * 100).toFixed(1);
    let newStr = addComma(str.split('.')[0]) + '.' + str.split('.')[1];
    return newStr.includes('.') ? newStr : newStr + '.0';
};
export const toTitleCase = (str) => {
    if (str === null || str === undefined) {
        return '';
    }
    return str.replace(/\w\S*/g, function (txt) {
        if (txt.indexOf('/') > -1) {
            let first = txt.split('/')[0];
            let second = txt.split('/')[1];
            return (
                first.charAt(0).toUpperCase() +
                first.substr(1).toLowerCase() +
                '/' +
                second.charAt(0).toUpperCase() +
                second.substr(1).toLowerCase()
            );
        } else {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    });
};
export const formatDateDDMMYYYY = (str) => {
    if (str === null || str === undefined) {
        return '';
    }
    function pad(s) {
        return s < 10 ? '0' + s : s;
    }
    let d = new Date(str);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
};


export const formatDateMMDDYYYY = (str) => {
    if (str === null || str === undefined) {
        return '';
    }
    function pad(s) {
        return s < 10 ? '0' + s : s;
    }
    let d = new Date(str);
    return [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join('/');
};
export const formatDateYYYYMMDD = (str) => {
    if (str === null || str === undefined) {
        return '';
    }
    function pad(s) {
        return s < 10 ? '0' + s : s;
    }
    let d = new Date(str);
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('/');
};


export const numberWithCommas = (num) => {
    if (num > 999 && num < 1000000) {
        return addComma((num / 1000).toFixed(0)) + 'K'; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
        return addComma((num / 1000000).toFixed(0)) + 'M'; // convert to M for number from > 1 million
    } else {
        return num.toFixed(0);
    }
};

export const generateTable = (arr, config = {}) => {
    let htmlStr = "<table border='1' style='border-collapse: collapse;'>";
    let style = `style="text-align: center;"`;
    let marketColWidth = 50;
    let restWidth = (100 - marketColWidth) / (Object.keys(config.columnMapping).length - 1);
    htmlStr += `<colgroup><col style="width:${marketColWidth}vw">`;

    for (let i = 0; i < Object.keys(config.columnMapping).length - 1; i++) {
        htmlStr += `<col style="width:${parseFloat(restWidth.toFixed(1))}%">`;
    }
    htmlStr += '</colgroup><tr>';
    Object.keys(config.columnMapping).forEach((key) => {
        htmlStr += `<th style="text-align: center;">${config.columnMapping[key]}</th>`;
    });
    htmlStr += `</tr>`;
    arr.forEach((item) => {
        htmlStr += '<tr>';
        Object.keys(config.columnMapping).forEach((key) => {
            if (config.percentKeys.includes(key) && typeof item[key] === 'number') {
                let val = formatPercent(item[key]);
                htmlStr += `<td ${style}> ${val}%</td>`;
            } else if (config.titleCaseColumns.includes(key)) htmlStr += `<td ${style}> ${toTitleCase(item[key])}</td>`;
            else if (config.dollarColumns.includes(key) && typeof item[key] === 'number')
                htmlStr += `<td ${style}> $${formatPrice(item[key])}</td>`;
            else htmlStr += `<td ${style}> ${item[key]}</td>`;
        });
        htmlStr += '</tr>';
    });
    htmlStr += '</table>';
    return htmlStr;
};


export const generateOrderedList = (arr, olType, value) => {
    let htmlStr = '<ol style="margin-left:2em">';
    if (olType === 'growing_market') {

        arr.forEach((item) => {
            htmlStr += `<li>${toTitleCase(item.market)} (${formatPercent(item.of_category)}% of total US FMCG, `;
            if (!valueCheck(item.retailer_growth)) htmlStr += `${item.retailer_growth}) </li>`;
            else if (value == 'pos') {
                htmlStr += `sales are ${returnColorSpan(
                    `${formatPercentWithSign(item.retailer_growth)}%`,
                    `positive`)}) </li>`; // green
            } else if (value == 'neg') {
                htmlStr += `sales are ${returnColorSpan(
                    `${formatPercentWithSign(item.retailer_growth)}%`,
                    `negative`
                )}) </li>`; // red
            }
            // else htmlStr += `sales are ${txtColor(`${formatPercentWithSign(item.retailer_growth)}%`, item.retailer_growth)} ) </li>`;
        });
    } else if (olType === 'top_market_sales') {
        arr.forEach((item) => {
            htmlStr += `<li>${toTitleCase(item.market)} (${formatPercent(
                item.of_sales_from_retailer
            )}% of total US FMCG, `;
            if (!valueCheck(item.change_of_sales_from_retailer)) {
                htmlStr += `${item.change_of_sales_from_retailer})</li>`;
            }
            else {
                htmlStr +=
                    `sales are ${txtColor(
                        `${formatPercentWithSign(item.change_of_sales_from_retailer)}%`,
                        item.change_of_sales_from_retailer
                    )}) </li>`;
            }
        });
    } else if (olType === 'largest_brand_sales') {
        arr.forEach((item) => {
            htmlStr += `<li>${toTitleCase(item.brand)} (${formatPercent(item.of_sales_from_brand)}% of category, `;
            if (!valueCheck(item.change)) htmlStr += ` ${item.change})</li>`;
            else htmlStr += `sales are ${txtColor(`${formatPercentWithSign(item.change)}%`, item.change)}) </li>`;
        });
    }
    htmlStr += '</ol>';
    htmlStr = htmlStr.replace(/Fmcg/g, 'FMCG');
    return htmlStr;
};
export const generateOrderedListWithBrandAndGrowth = (arr) => {
    let htmlStr = '<ol style="margin-left:2em">';
    arr.forEach((item) => {
        htmlStr += `<li>${toTitleCase(item.brand)} (sales are ${numberWithCommas(item.total_dollars)}, `;
        let item_brand_growth_formatted = item.brand_growth;
        let tempColorDirection = valueCheck(item.brand_growth) ? `positive` : `regular`;
        if (valueCheck(item.brand_growth))
            item_brand_growth_formatted = `${formatPercentWithSign(`${item.brand_growth}`)}%`;
        htmlStr += `${returnColorSpan(`${item_brand_growth_formatted}`, tempColorDirection)})</li>`;
    });
    htmlStr += '</ol>';
    return htmlStr;
};
export const generateBasicOrderedList = (arr) => {
    let htmlStr = '<ol style="margin-left:2em">';
    arr.forEach((item) => {
        htmlStr += `<li>${toTitleCase(item)} </li>`;
    });
    htmlStr += '</ol>';
    return htmlStr;
};

function addComma(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const textBold = (str) => {
    return str.bold();
};


// ---------------------------------color and formatting changes ------------------------------

export const getDirection = (num, positive, negative, flat) => {
    if (num >= 0.0005) {
        return positive;
    } else if (num < 0.0005 && num > -0.0005 && num !== null) return flat;
    else if (num <= -0.0005) return negative;
    else return num;
};

// txt = original txt, newtxt is expected txt, value decides the logic
export const returnNewtext = (txt, value) => {
    // if value is null, return empty string, else original text
    let newtxt = value ? txt : '';
    return newtxt;
};

export const returnColorSpan = (txt, colorDirection) => {
    let color = '#000000';
    if (colorDirection === 'positive') {
        color = '#0fbc61';
    }
    if (colorDirection === 'negative') {
        color = '#ed4350';
    }
    if (colorDirection === 'flat') {
        color = '#ffbf3f';
    }
    if (colorDirection === 'ByzzerPink') {
        color = '#ef426f'; // pink
    }
    return `<span style="color: ${color}">${txt}</span>`;
};

export const promoEffColor = (txt, value) => {
    if (value >= 2) return `<span style="color: #0fbc61">${txt}</span>`; //green
    else if (value < 2 && value >= 1) return `<span style="color: #ed4350">${txt}</span>`; //red
    else return `<span style="color: #ffbf3f">${txt}</span>`; //yellow
};

// text is raw html, value decides the color
export const txtColor = (txt, direction, reverse = false) => {
    let classState = '';

    if (
        (typeof direction === 'string' &&
            (direction === 'up' ||
                direction.includes('increase') ||
                direction === 'over' ||
                direction.includes('gain') ||
                direction === 'grew')) ||
        (typeof direction === 'number' && direction >= 0.0005)
    ) {
        classState = reverse ? 'negative-value' : 'positive-value';
    }
    else if (
        (typeof direction === 'string' &&
            (direction === 'down' ||
                direction.includes('decrease') ||
                direction === 'under' ||
                direction.includes('lost') ||
                direction.includes('decline'))) ||
        (typeof direction === 'number' && direction <= -0.0005)
    ) {
        classState = reverse ? 'positive-value' : 'negative-value';
    }

    else if (
        (typeof direction === 'number' && direction < 0.0005 && direction > -0.0005 && direction !== null) ||
        (typeof direction === 'string' && (direction.includes('change') || direction.includes('flat')))
    ) {
        classState = 'flat-value';
    }
    else {
        classState = 'regular-value';
    }
    let htmlStr = `<span class=${classState}>${txt}</span>`;
    return htmlStr;
};


export const valueCheck = (direction) => {
    if (
        typeof direction === 'string' &&
        (direction.toLowerCase().includes('delisted') ||
            direction.toLowerCase().includes('new') ||
            direction.toLowerCase().includes('eliminated'))
    )
        return false;
    return true;
};
