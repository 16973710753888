import React, { useState, useEffect } from 'react';
import '@/pages/ReportViewer/ReportViewer.scss';
import {useTenantApi} from '@/hooks/useTenantApi';
import Loader from '@/components/Spin';
import '@/pages/StoryViewer/BuildStory.scss';
import {alert} from '@/components/form/ByzzerModal';
            
const DownloadReportPage = (props) => {
    const [loading, setLoading] = useState(false);
    const { getReportFromAPI } = useTenantApi();

    const download = async () => {
        try {
            // Used this instead of window.saveAs(blob, 'report'.zip) - to auto close the tab after downloading the file. 
            // In case of window.saveAs the tab closed even before the file was downloaded.
            setLoading(true);
            let requestBody = new Object();
            requestBody.actionId = window.actionId;
            requestBody.tilesToDownload = window.selectedTileList;
            const [blob, zipName] = await getReportFromAPI(requestBody);
            var url = window.URL || window.webkitURL;
            var link = url.createObjectURL(blob);
            var a = document.createElement("a");
            a.setAttribute("download", "dashboard-"+zipName.toLowerCase()+".zip");
            a.setAttribute("href", link);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setLoading(false);
            window.close();
        } catch (err) {
            setLoading(false);
            console.log(err);
            alert({content:'Error downloading report'});
        }
    };
    
    useEffect(() => {
        download();
    }, []);

    return (
        <div>
            {loading && <Loader/>}
        </div>
    );

    
};
export default DownloadReportPage;
