import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { DashboardNav } from '@/components/dashboard';
import DashboardHeader from '@/components/dashboard/DashboardHeader';
import DashboardContent from '@/components/dashboard/DashboardContent';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { useUser } from '@/contexts/UserContext';
import QuoteContent from './QuoteContent';

export const QuotePage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { userQuote } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (!Boolean(userQuote && userQuote.quoteData)) {
            navigate("/dashboard")
        }
    }, [userQuote]);

    return (
        <div className="dashboard">
            <DashboardNav disabled={true} />
            <ByzzerMask loading={isLoading} />
            <DashboardContent title={'Review your Quote'}>
                <QuoteContent />
            </DashboardContent>
            <DashboardHeader disabled={true} className={undefined} />
        </div>
    );
};

export default QuotePage;

QuotePage.displayName = 'Quote Page';
