import './DataPointCalculator.scss';
import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import classNames from 'classnames';
import hashObject from 'object-hash';
import cloneDeep from 'lodash/cloneDeep';
import {nanoid} from "nanoid";
import {useDodWizard} from "@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext";
import {shortNumber} from "@/utils";
import {estimateExtractDataPoints} from "@/api";
import {DodFilters, DodLayoutConfig, DodRunConfig} from '@/types/DodRun';
import { DATA_POINTS_LIMIT } from '@/constants/dod.constants';

const baseClassName = 'data-point-calculator';

export type DataPointCalculatorRef = {
    calculate(runConfig: DodRunConfig): Promise<number | undefined>;
    value: number;
}

export type DataPointCalculatorProps = {
    byzRef: React.Ref<DataPointCalculatorRef>
};

export function DataPointCalculator({byzRef}: DataPointCalculatorProps) {

    const {runConfig, setDataPointCount, setCalculatingDataPoints, allExceptSelectionFields} = useDodWizard();
    const [lastUsedRunConfig, setLastUsedRunConfig] = useState<DodRunConfig>()
    const [value, setValue] = useState<number>(0);
    const [busy, setBusy] = useState(false);
    const calcIdRef = useRef<string | null>(null);
    const lastConfigHash = useRef<string>('');

    const dataPointsLimitExceeded: boolean = value > DATA_POINTS_LIMIT;

    useEffect(() => {
        calculate(runConfig);
    }, [runConfig]);

    useImperativeHandle(byzRef, () => ({
        calculate,
        value
    }), [value])

    function getFiltersWithExcludeValues(filters: DodFilters): DodFilters {
        const allExceptSelectionFieldsSet = new Set(allExceptSelectionFields);
        return {
            ...filters,
            ...Object.fromEntries(
                Object.entries(filters).map(([key, value]) => {
                    if (allExceptSelectionFieldsSet.has(key)) {
                        return [key, { ...value, isExcluded: true }];
                    }
                    if (key === 'characteristics') {
                        const characteristics = value;
                        const characteristicsWithExcludeValues = Object.fromEntries(
                            Object.entries(characteristics).map(([key, value]) => {
                                if (allExceptSelectionFieldsSet.has(key)) {
                                    return [key, { ...value, isExcluded: true }];
                                }
                                return [key, value];
                            })
                        );
                        return [key, characteristicsWithExcludeValues];
                    }
                    if (key === 'customCharacteristics') {
                        const customCharacteristics = value;
                        const customCharacteristicsWithExcludeValues = Object.fromEntries(
                            Object.entries(customCharacteristics).map(([key, value]) => {
                                // @ts-ignore
                                if (allExceptSelectionFieldsSet.has(parseInt(key))) {
                                    return [key, { ...value, isExcluded: true }];
                                }
                                return [key, value];
                            })
                        );
                        return [key, customCharacteristicsWithExcludeValues];
                    }
                    if(key === 'ppgs') {
                        const ppgs = value;
                        const ppgsWithExcludeValues = Object.fromEntries(
                            Object.entries(ppgs).map(([key, value]) => {
                                // @ts-ignore
                                if (allExceptSelectionFieldsSet.has(parseInt(key))) {
                                    return [key, { ...value, isExcluded: true }];
                                }
                                return [key, value];
                            })
                        );
                        return [key, ppgsWithExcludeValues];
                    }
                    return [key, value];
                })
            ),
        };
    }

    async function calculate(runConfig: DodRunConfig): Promise<number | undefined> {
        // prevent unnecessary recalculations
        const {layout, ...rest} = runConfig;
        const hash = hashObject({
            rest,
            layout: shrinkLayoutConfig(layout)
        }, {
            excludeKeys(key: string) {
                return ['includeSubTotals', 'quickLayoutCode', 'savedLayoutId', 'order', 'pageBy', 'sortType', 'type'].includes(key)
            }
        });

        if (lastConfigHash.current === hash) {
            return value;
        }

        const calcId = calcIdRef.current = nanoid();
        lastConfigHash.current = hash;

        try {
            if (!runConfig?.filters.categories.values.length && !runConfig?.filters.categories.summedSelections.length) {
                setValue(0);
                setDataPointCount(0);
                return 0;
            }

            setBusy(true);
            setCalculatingDataPoints(true);

            setLastUsedRunConfig(runConfig);
            // todo: add logic to cancel previous calculate requests

            // TODO: enable All Except feature back in NOV-II release
            // let clonedRunConfig = cloneDeep(runConfig);
            // clonedRunConfig = {...clonedRunConfig, filters: getFiltersWithExcludeValues(clonedRunConfig.filters)};
            const dataPoints = await estimateExtractDataPoints(runConfig);
            // this is a guard to prevent an old long-running calculation from overwriting the most recent calculation
            if (calcId === calcIdRef.current) {
                setValue(dataPoints);
                setDataPointCount(dataPoints);
            }
            return dataPoints;
        } catch (err) {
            // if the request fails send the known count
            if (calcId === calcIdRef.current) {
                return value;
            }
        } finally {
            if (calcId === calcIdRef.current) {
                setBusy(false);
                setCalculatingDataPoints(false);
            }
        }
    }

    async function handleRefreshClick(): Promise<void> {

        if (!lastUsedRunConfig || busy) return;

        await calculate(lastUsedRunConfig);
    }

    return (
        <div
            className={classNames(baseClassName, {
                [`${baseClassName}--calculating`]: busy,
                [`${baseClassName}--over-limit`]: dataPointsLimitExceeded && !busy,
            })}
        >
            <div className={`${baseClassName}__label`}>Data Size</div>
            <div className={`${baseClassName}__value`}>
                {busy ? 'Calculating...' : `${value && Number(value) !== 0 ? shortNumber(value) : 'No'} Data Points`}
            </div>
            <div className={`${baseClassName}__refresh-trigger`} onClick={handleRefreshClick} />
        </div>
    );
}

/**
 * Strips unimportant values from the layout and then normalizes the struture
 * data-point should recalculate when hides/shows a product field, adds/removes a condition,
 * adds/removes Category Totals and stacks by a product field
 */
function shrinkLayoutConfig({rows, columns, includeCategoryTotals}: DodLayoutConfig): object {

    // shrink layout object to compare with previous row cols and prevent unnecessary data recalculations
    return {
        includeCategoryTotals,
        products: [...rows, ...columns].filter((item) => item.type === 'products' && item.dim)
            .sort((a, b) => a.dim.localeCompare(b.dim))
    }
}

export default DataPointCalculator;
