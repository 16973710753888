import React, {forwardRef, ReactNode, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import './ByzzerCategorySelect.scss';
import {ByzzerChangeEventHandler, ByzzerSelectOption, ByzzerSelectOptionGroup, ByzzerWindowedSelect} from '@byzzer/ui-components';
// import {useTestTenantApi} from '@/hooks/useTestTenantApi';
import {useApp} from '@/contexts/UserContext';
import classnames from 'classnames';
import {ReportAggregationLevel} from '@/types/ReportRun';
import {CATEGORY_COVERED_BY_BRAND_TEXT, useCategoryService} from "@/services/category.service";
import CategoryIntersectionOption from './CategoryIntersectionOption';
import CategoryIntersectionMultiValueLabel from './CategoryIntersectionMultiValueLabel';
import {useTenantApi} from '@/hooks/useTenantApi';
import { debounce } from 'lodash';

export type ByzzerCategorySelectRef = {
    optionsWithCategoryMatchFlag: (ByzzerSelectOption | ByzzerSelectOptionGroup)[];
};

export type ByzzerCategorySelectProps = {
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<string[]>;
    value?: string[];
    disabledOptions?: string[];
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    maxSelections?: number;
    categoriesToCheckForIntersect?: string[]; // brand categories, theme categories, etc
    /**
     * The SKU of a theme that match with the categories
     */
    themeSku?: string;
    /**
     *  The brand you wish to find categories for.
     */
    brands?: string[];
    restrictToSubscriptionCategories?: boolean;
    optionComponent?: React.FC;
    categorySelectionAggregationLevel?: ReportAggregationLevel;
    allowClear?: boolean;
    allowMultiple?: boolean;
    shouldDisplayIntersectIndicators?: boolean;
    /**
     * @deprecated use categoryOptionsSource instead.  categoryOptionsSource has default of 'subscription'
     */
    listAllCategoriesInOptions?: boolean;
    groupOptionsByBrandCoverage?: boolean;
    /**
     * categoryOptionsSource has default of 'subscription'
     */
    categoryOptionsSource?: 'subscription' | 'all' | 'brands';
} & OnlyRenderableIf;

const baseClassName = 'byzzer-category-select';

export const ByzzerCategorySelect = forwardRef<ByzzerCategorySelectRef, ByzzerCategorySelectProps>(
    ({
         onChange,
         name,
         label,
         className,
         disabledOptions = [],
         placeholder = '',
         value, // any non-primitative default will cause infinite loop if useEffect is used on it
         disabled,
         categoriesToCheckForIntersect,
         maxSelections,
         onlyRenderIf,
         optionComponent,
         categorySelectionAggregationLevel: aggregationLevel = 'category',
         allowClear = true,
         shouldDisplayIntersectIndicators = false,
         allowMultiple = true,
         listAllCategoriesInOptions,
         groupOptionsByBrandCoverage = false,
         categoryOptionsSource = listAllCategoriesInOptions ? 'all' : 'subscription',
         brands
     }, ref) => {
// const { getCategoryByBrand, getCategoriesForTheme, brandSearch } = useTestTenantApi();
const { getCategoriesByBrands } = useTenantApi();
        const { categories: categoriesFromSubscription, hierarchyTree } = useApp();
        const [optionsWithCategoryMatchFlag, setOptionsWithCategoryMatchFlag] = useState<(ByzzerSelectOption | ByzzerSelectOptionGroup)[]>([]);
        const [internalValue, setInternalValue] = useState<ByzzerSelectOption[]>([]);
        const {
            sortByDisplayName,
            sortByMatch,
            createNewGroupValue,
            createGroupingsFromCategoryValues,
            createCategoryDisplayOptions,
            createAggregationDisplayOptions,
            createExtendedCategoryInfoForAllCategories,
            createExtendedCategoryInfoForSubscribedCategories,
            createListOfAllCategories
        } = useCategoryService();
        const OptionComponent = optionComponent;
        const priorAggregationLevel = useRef<ReportAggregationLevel>(aggregationLevel);
        const categoryInfoTreeRef = useRef<any>();
        const categoryListRef = useRef<any>();

        const isLoadingCategoryOptionsForBrands = categoryOptionsSource === 'brands' && brands?.length && !optionsWithCategoryMatchFlag?.length;

        useEffect(() => {
            ;(async (brands) => {
                await loadBaseCategories(brands)
            })(brands)
        }, [brands])

        async function loadBaseCategories(brands?: string[]) {
            if (categoryOptionsSource === 'brands' && brands) {
                if (brands?.length) {
                    const brandCategories = await getCategoriesByBrands(brands);
                    categoryInfoTreeRef.current = createExtendedCategoryInfoForSubscribedCategories(hierarchyTree, brandCategories);
                    categoryListRef.current = brandCategories;
                    configureOptionsAndSetInUI(aggregationLevel, []);
                } else {
                    setOptionsWithCategoryMatchFlag([]);
                    categoryInfoTreeRef.current = undefined;
                    categoryListRef.current = undefined;
                }
            } else if (categoryOptionsSource === 'all') {
                if (!categoryInfoTreeRef.current) {
                    categoryInfoTreeRef.current = createExtendedCategoryInfoForAllCategories(hierarchyTree);
                }
                if (!categoryListRef.current) {
                    categoryListRef.current = createListOfAllCategories(categoryInfoTreeRef.current);
                }
            } else if (categoryOptionsSource === 'subscription') {
                if (!categoryInfoTreeRef.current) {
                    categoryInfoTreeRef.current = createExtendedCategoryInfoForSubscribedCategories(hierarchyTree, categoriesFromSubscription)
                }
                if (!categoryListRef.current) {
                    categoryListRef.current = categoriesFromSubscription
                }
            }
        };

        useEffect(() => {
            if (!value?.length) {
                setInternalValue([]);
                return;
            }

            if (aggregationLevel === 'total_store') {
                const totalScoreValue = {
                    display: `Total Store (${categoryListRef.current?.length} categories)`,
                    value: 'total_store',
                    data: {
                        categories: categoryListRef.current,
                        aggregationlevel: 'total_store'
                    }
                }
                setInternalValue([totalScoreValue]);
            }

            if (aggregationLevel === 'category') {                
                const categoryValues = value?.map((categoryValue) => {
                    return {
                        display: categoryValue,
                        value: categoryValue,
                        data: {
                            categories: [categoryValue],
                            categoryMatch: [categoriesToCheckForIntersect?.includes(categoryValue)]?.filter(Boolean),
                            aggregationlevel: 'category'
                        }
                    }
                });
                setInternalValue(categoryValues);
            }

            if (aggregationLevel === 'department') {
                const departmentsFromCategoryValues = createGroupingsFromCategoryValues(categoryInfoTreeRef.current?.departmentInfo, value);
                const departmentValue = createNewGroupValue(departmentsFromCategoryValues, 'department', categoriesToCheckForIntersect);
                setInternalValue(departmentValue);
            }

            if (aggregationLevel === 'super_category') {
                const supercategoriesFromCategoryValues = createGroupingsFromCategoryValues(categoryInfoTreeRef.current?.supercategoryInfo, value)
                const supercategoryValue = createNewGroupValue(supercategoriesFromCategoryValues, 'super_category', categoriesToCheckForIntersect);
                setInternalValue(supercategoryValue);
            }

        }, [value, categoriesToCheckForIntersect])

        useEffect(() => {
            if (categoryOptionsSource !== 'brands') {
                categoryInfoTreeRef.current && configureOptionsAndSetInUI(aggregationLevel, categoriesToCheckForIntersect);

                if (priorAggregationLevel.current !== aggregationLevel && aggregationLevel === 'total_store') {
                    onChange?.({
                        value: categoryListRef.current,
                        name
                    })
                }
                priorAggregationLevel.current = aggregationLevel;
            }
        }, [aggregationLevel, categoriesToCheckForIntersect, categoryInfoTreeRef.current, categoryOptionsSource])

        // https://adlm.nielseniq.com/jira/browse/BYZ-1006
        const configureOptionsAndSetInUI = async (aggregationLevel: ReportAggregationLevel = 'category', categoriesToCheckForIntersect: string[] = []) => {
            setOptionsWithCategoryMatchFlag([]);

            switch (aggregationLevel) {
                case 'department':
                case 'super_category':
                    const newAggregationDisplayOptions = createAggregationDisplayOptions(categoryInfoTreeRef.current, categoriesToCheckForIntersect, shouldDisplayIntersectIndicators && groupOptionsByBrandCoverage, aggregationLevel);
                    setOptionsWithCategoryMatchFlag(newAggregationDisplayOptions)
                    break;
                case 'total_store':
                    const newTotalStoreDisplayOptions = [{
                        display: `Total Store (${categoryListRef.current?.length} categories)`,
                        value: 'total_store',
                        data: {
                            categories: categoryListRef.current?.sort(sortByDisplayName),
                            aggregationlevel: 'total_store'
                        }
                    }]
                    setOptionsWithCategoryMatchFlag(newTotalStoreDisplayOptions)
                    break;
                default:
                    const newCategoryDisplayOptions = createCategoryDisplayOptions(categoryListRef.current, categoriesToCheckForIntersect, shouldDisplayIntersectIndicators && groupOptionsByBrandCoverage);
                    setOptionsWithCategoryMatchFlag(newCategoryDisplayOptions)
            }

        }

        useImperativeHandle(
            ref,
            () => ({
                optionsWithCategoryMatchFlag: (
                    optionsWithCategoryMatchFlag?.map((optionOrOptionGroup) => {
                        if ((optionOrOptionGroup as ByzzerSelectOptionGroup)?.label === CATEGORY_COVERED_BY_BRAND_TEXT) {
                            return (optionOrOptionGroup as ByzzerSelectOptionGroup)?.options
                        } else {
                            return optionOrOptionGroup
                        }
                    }).flat()?.filter(option => (option as ByzzerSelectOption)?.data?.categoryMatch)
                )
            }),
            [optionsWithCategoryMatchFlag]
        )

        const debouncedHandleChange = useCallback(
            debounce((newValue) => {
                onChange?.({
                    value: newValue,
                    name,
                    data: {
                        categories: newValue
                    }
                });
            }, 1000), [onChange]
        );

        function handleChange(e: any) {
            debouncedHandleChange.cancel();
            if (!e?.value?.length) {
                setInternalValue([])
                debouncedHandleChange(e?.value)
            } else if (aggregationLevel === 'category') {
                setInternalValue(e?.value); // for performance reason, i'm setting internalValue right away with just a string array to more quickly populate chip(s) in the box.  internalValue is again updated with full ByzzerSelectOption shape after 'value' updates. User will not see any change.  performance of the select component(s) will be reviewed in future ticket(s)
                debouncedHandleChange(e?.value)
            } else {
                const newValue = e?.data?.map((dataItem) => dataItem?.data?.categories).flat();
                debouncedHandleChange(newValue)
            }

        }

        if (onlyRenderIf === false) return <></>;

        return (
            <ByzzerWindowedSelect
                name={name}
                className={classnames(baseClassName, className)}
                options={optionsWithCategoryMatchFlag}
                disabled={disabled || !optionsWithCategoryMatchFlag?.length}
                placeholder={isLoadingCategoryOptionsForBrands ? 'Loading options...' : placeholder}
                label={label}
                value={internalValue}
                maxSelections={maxSelections}
                disabledOptions={disabledOptions}
                onChange={handleChange}
                allowMultiple={allowMultiple}
                optionComponent={shouldDisplayIntersectIndicators ? (props) => CategoryIntersectionOption(props, baseClassName) : undefined}
                multiValueComponent={shouldDisplayIntersectIndicators ? (props) => CategoryIntersectionMultiValueLabel(props, baseClassName) : undefined}
                allowClear={allowClear}
                // defaultMenuIsOpen={true} // if you need to test styling on drop down without it closing on you
            />
        )
    });

export default ByzzerCategorySelect;

ByzzerCategorySelect.displayName = 'ByzzerCategorySelect';



