import {registerNotification} from '../NotificationHandlers';

type ExtractNotificationBody = {
    name: string;
    status: string;
};

registerNotification<ExtractNotificationBody>('data_on_demand_update_toast', ({body}) => ({
    title: 'Data On Demand',
    content: <>
        Report: <b>"{body?.name}"</b>, status changed to{' '} <b>{body?.status}</b>
    </>,
    duration: 10,
}));
