import React, {useEffect, memo} from 'react';
import {parse} from 'qs';
import axios from 'axios';
import {ScriptTag} from './ScriptTag';

const SIGN_UP_ATTRIB_KEY = 'signUpAttributionData';
const URCHIN_PARAMS = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content'
];
const FORM_FIELDS = {
    firstName: 'firstname',
    lastName: 'lastname',
    companyName: 'company',
    utm_source: 'utm_source',
    utm_content: 'utm_content',
    utm_medium: 'utm_medium',
    email: 'email',
    phone: 'phone',
    companyType: 'company_type',
    userId: 'netsuite_contact_id',
    companyId: 'netsuite_customer_id',
    lead_source_event: 'lead_source_event'

};

const signUpTrackingEnabled = import.meta.env.REACT_APP_ENABLE_SIGN_UP_TRACKING === 'true';

export const SiteAnalytics = memo(() => {

    useEffect(() => {

        // capture any attribution query parameters the first time the site is loaded
        const queryParams = parse(window.location.search.substr(1));
        const hasUrchinParams = Object.keys(queryParams).some(param => URCHIN_PARAMS.includes(param));
        if (hasUrchinParams) {
            localStorage.setItem(SIGN_UP_ATTRIB_KEY, JSON.stringify(queryParams));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

});

export function getSignUpAttribution() {
    if(signUpTrackingEnabled) {
        const attribData = JSON.parse(localStorage.getItem(SIGN_UP_ATTRIB_KEY) || '{}');
        // strip the query string from the page uri
        //localStorage.removeItem(SIGN_UP_ATTRIB_KEY);
        return attribData;

    }
}

export function setIdentity(email, data = {}) {

    const _hsq = window._hsq || [];
    _hsq.push(['identify', {
        email,
        ...data,
    }])
}

export function trackEvent(id, value) {
    const _hsq = window._hsq || [];
    _hsq.push(['trackEvent', {
        id,
        value
    }]);
}

export default SiteAnalytics;
export const useAnalytics = () => ({
    getSignUpAttribution,
    setIdentity,
    trackEvent
});
