import './AlertRunConfigSummary.scss';
import React, { ReactNode, useContext } from 'react';
import classnames from 'classnames';
import {
    AlertRunConfig
} from '@/types/AlertRun';
import { AlertRunConfigWizardContext } from '@/components/ConfigurationEditors/AlertConfigurationEditor/AlertRunConfigWizard/AlertRunConfigWizardContext';
import AlertConfigSummary from '@/components/AlertConfiguration/AlertConfigSummary';

export type AlertRunSummaryProps = {
    runConfig?: Partial<AlertRunConfig>;
} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'alert-run-summary';
export type SummarySectionConfig = {
    title?: string;
    prop: keyof AlertRunConfig;
    toValue?: (value: any) => string | string[] | ReactNode;
    toTitle?: (runConfig: Partial<AlertRunConfig>) => ReactNode;
    toChips?: (configValue: any) => ReactNode;
};

export function AlertRunConfigSummary({ className, runConfig, ...props }: AlertRunSummaryProps) {
    const { value: contextRunConfig } = useContext(AlertRunConfigWizardContext);

    return (
        <AlertConfigSummary userAlerts={[{refactoredConfig: contextRunConfig}]} />
    );
}

export default AlertRunConfigSummary;

AlertRunConfigSummary.displayName = 'AlertRunConfigSummary';
