import { filterValuesToStrings } from '@/components/DodConfigEditor/common/utils';
import { useUser } from '@/contexts/UserContext';
import { useCategoryService } from '@/services/category.service';
import { DodFilters } from '@/types/DodRun';

const useAllExceptProductFields = (filters: DodFilters, allExceptSelectionFields: string[]) => {
    const { categories: userCategories } = useUser();
    const isAllSelectionChecked = (field: string) =>
        filters[field].values === 'all' || filters[field].summedSelections.some((ss) => ss.values === 'all');
    const {
        getDepartsForCategories,
        getSubCategoriesForCategories,
        getSuperCategoriesForCategories,
        getSuperCategoriesForDepartments,
        getAllCategoriesByDepartmentsAndSuperCategories,
    } = useCategoryService();
    const userSuperCategories = getSuperCategoriesForCategories(userCategories);

    const departments = getDepartsForCategories(userCategories).filter((department) =>
        allExceptSelectionFields.includes('departments')
            ? !filterValuesToStrings(filters.departments).includes(department)
            : isAllSelectionChecked('departments') || !filterValuesToStrings(filters.departments).length
            ? true
            : filterValuesToStrings(filters.departments).includes(department)
    );

    const superCategories = getSuperCategoriesForDepartments(departments)
        .filter((item) => userSuperCategories.includes(item))
        .filter((superCategory) =>
            allExceptSelectionFields.includes('superCategories')
                ? !filterValuesToStrings(filters.superCategories).includes(superCategory)
                : isAllSelectionChecked('superCategories') ? true : filterValuesToStrings(filters.superCategories).includes(superCategory)
        );

    const categories = getAllCategoriesByDepartmentsAndSuperCategories(
        { values: departments, summedSelections: [] },
        { values: superCategories, summedSelections: [] }
    )
        .filter((category) => userCategories.includes(category))
        .filter((category) =>
            allExceptSelectionFields.includes('categories')
                ? !filterValuesToStrings(filters.categories).includes(category)
                : isAllSelectionChecked('categories') ? true : filterValuesToStrings(filters.categories).includes(category)
        );

    const subCategories = getSubCategoriesForCategories(categories).filter((subCategory) =>
        allExceptSelectionFields.includes('subcategories')
            ? !filterValuesToStrings(filters.subcategories).includes(subCategory)
            : isAllSelectionChecked('subcategories') ? true : filterValuesToStrings(filters.subcategories).includes(subCategory)
    );

    return {
        categories,
        departments,
        subCategories,
        superCategories,
    };
};

export default useAllExceptProductFields;
