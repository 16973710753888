import {CharacteristicCondition} from "@/types/ReportRun";
import {useUser} from "@/contexts/UserContext";
import {intersection} from "lodash";

export type CharacteristicsRequirements = {
    requireCharacteristics?: boolean,
    requireKeyCharacteristics?: boolean
}

export function useCharacteristicService() {

    const {customCharacteristics} = useUser();

    function getAllCustomCharacteristics(): CustomCharacteristic[] {
        return customCharacteristics;
    }

    function getCustomCharacteristicsForCategories(categories: string[]): CustomCharacteristic[] {
        return customCharacteristics.filter(c => Boolean(intersection(c.categories, categories).length));
    }

    function validateCharacteristicSelections(
        characteristics: CharacteristicCondition[],
        requirements: CharacteristicsRequirements
    ): boolean {

        function characteristicsAreCompletelyFilledInAndOrCompletelyEmpty(characteristics: CharacteristicCondition[]): boolean {
            return (Number(characteristics?.filter((characteristicCondition) => (
                (!characteristicCondition?.characteristic?.length && !characteristicCondition?.value?.length) ||
                (characteristicCondition?.characteristic?.length && characteristicCondition?.condition?.length && characteristicCondition?.value?.length)
            ))?.length) === Number(characteristics?.length))
        }

        function characteristicsAreCompletelyFilledIn(characteristics: CharacteristicCondition[]): boolean {
            return (Number(characteristics?.filter((characteristicCondition) => (
                (characteristicCondition?.characteristic?.length && characteristicCondition?.condition?.length && characteristicCondition?.value?.length)
            ))?.length) === Number(characteristics?.length)) && Number(characteristics?.length) > 0
        }

        const characteristicsAreValid = (
            (requirements.requireCharacteristics && characteristicsAreCompletelyFilledIn(characteristics)) ||
            (!requirements.requireCharacteristics && characteristicsAreCompletelyFilledInAndOrCompletelyEmpty(characteristics))
        )

        const keyCharacteristicsAreValid = (
            (requirements?.requireKeyCharacteristics && characteristicsAreCompletelyFilledIn(characteristics)) ||
            (!requirements?.requireKeyCharacteristics && characteristicsAreCompletelyFilledInAndOrCompletelyEmpty(characteristics))
        )

        return Boolean(characteristicsAreValid && keyCharacteristicsAreValid);

    }

    return {
        getAllCustomCharacteristics,
        getCustomCharacteristicsForCategories,
        validateCharacteristicSelections
    }
}