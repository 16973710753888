import './SignIn.scss';
import React, {useEffect, useState} from 'react';
import {ByzzerButton, ByzzerCheckbox, ByzzerInput, ByzzerModal} from '@/components/form';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import {SwitchToSignUp} from '@/components/SwitchTo';
import {openErrorModal} from '@/components/form/ByzzerModal';
import {useUser} from '@/contexts/UserContext';
import {useAccountService} from '@/hooks/useAccountService';
import {useTrackEvent} from '@/analytics/AnalyticsContext';

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const baseClassName = 'sign-in';

export function SignIn({ heading, ...props }) {

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [credentials, setCredentials] = useState({
        email: localStorage.email,
        password: '',
    });
    const [rememberMe, setRememberMe] = useState(localStorage.rememberMe === 'true');
    const navigate = useNavigate();
    const [busy, setBusy] = useState(false);
    const [showExpiredModal, setShowExpiredModal] = useState(false);
    const [showDisabledModal, setShowDisabledModal] = useState(false);
    const { search } = useLocation();
    const  { subscription, company, unacceptedCompanyQuoteId } = useUser();
    const {clearOboUserInfo, login, logout} = useAccountService();
    const trackEvent = useTrackEvent();

    useEffect(() => {
        const isValid =
            credentials.email?.trim() && emailRegex.test(credentials.email?.trim()) && credentials.password?.trim();
        setButtonDisabled(!isValid);
    }, [credentials]);

    function signOut() {
        logout(); // todo: see if the order of these functions matters, might not.
        setBusy(false)
        setShowExpiredModal(false);
        setShowDisabledModal(false);
    }

    useEffect(() => {
        if (Boolean(subscription) && subscription?.pastExpirationGracePeriod && !unacceptedCompanyQuoteId) {
            setBusy(false);
            setShowExpiredModal(true);
        }
    }, [subscription, unacceptedCompanyQuoteId]);

    useEffect(() => {
        if (company?.isDisabled) {
            setBusy(false);
            setShowDisabledModal(true);
        }
    }, [company]);

    useEffect(() => {
        async function checkSSOSignIn() {
            const params = new URLSearchParams(search);
            let signInBySSO = params.get('signInBySSO');
            // @ts-ignore
            if (signInBySSO === 'true' || signInBySSO === true) {
                //remove the search param.  Otherwise an infinite loop occurs because the page redirects
                //before SSO sign in is complete.
                navigate('sign_in');
                await signInWithSSO();
            }
        }
        checkSSOSignIn();
    }, []);

    const emailErrors = {
        valueMissing: 'You forgot to enter a value',
        tooShort: 'Make it longer please',
    };

    const passwordErrors = {
        valueMissing: 'Please enter a password',
    };

    function onChange({ target }) {
        setCredentials({
            ...credentials,
            [target.name]: target.value,
        });
    }

    function updateRememberMe({ target }) {
        setRememberMe(target.checked);
        localStorage.rememberMe = target.checked;
    }

    async function signIn() {
        clearOboUserInfo();
        if (rememberMe) {
            localStorage.email = credentials.email;
        }
        trackEvent({
            name: 'user_login_attempt',
            type: 'user_login_attempt',
            data: {
                email: credentials.email
            }
        });
        try {
            setBusy(true);
            // @ts-ignore
            const result = await login(credentials);
            trackEvent({
                name: 'user_login_success',
                type: 'user_login_success',
                data: {
                    email: credentials.email,
                }
            });
            // @ts-ignore
            setBusy(result);
        } catch (err) {
            trackEvent({
                name: 'user_login_failed',
                type: 'user_login_failed',
                data: {
                    email: credentials?.email,
                }
            });
            await onError(err);
            setBusy(false);
        }
    }

    async function signInWithSSO() {
        //const provider = new firebase.auth.SAMLAuthProvider('saml.nielsen-connect-poc');
        // @ts-ignore
        const clientID = import.meta.env.REACT_APP_CONNECT_SSO_CLIENT_ID;
        const provider = new firebase.auth.SAMLAuthProvider(clientID);
        try {
            let result = await firebase.auth().signInWithPopup(provider);
            console.log("NOW logged in!");
        } catch (error) {
            console.log('Error: ' + error);
        }
    }

    function onError(err) {
        switch (err.code) {
            case 'user_not_found':
                // todo: add support for choosing a different company
                return openErrorModal({
                    title: `Account Not Found`,
                    content: (
                        <>
                            <p>
                                We didn't find an account for <b>{credentials.email}</b>.
                            </p>
                            <p>Please make sure you entered your email address correctly.</p>
                        </>
                    ),
                });
            case 'wrong_password':
                // todo: add support for correcting the password and resubmitting
                return openErrorModal({
                    title: `Wrong Password`,
                    content: (
                        <>
                            <p>Please check the value and try again.</p>
                            <p>
                                Still having issues?{' '}
                                <NavLink replace to={'forgot_password'}>
                                    Reset your password
                                </NavLink>
                                .
                            </p>
                        </>
                    ),
                });
            case 'network_error':
                // todo: add support for correcting the password and resubmitting
                return openErrorModal({
                    title: `Communication Error`,
                    content: (
                        <>
                            <p>We can't reach the Byzzer servers at this time.</p>
                            <p>Please make sure you're connected to the internet.</p>
                        </>
                    ),
                    // @ts-ignore
                    closeButtonText: 'Try Again',
                });
            case 'user_disabled':
                return openErrorModal({
                    title: `Account disabled`,
                    content: (
                        <>
                            <p>Your subscription has been disabled. Please reach out to your Account Manager for more information.</p>
                        </>
                    ),
                });
            default:
                return openErrorModal({
                    title: `Something Unexpected Happened`,
                    content: (
                        <>
                            <p>Fear not our engineering team is on the job.</p>
                        </>
                    ),
                    errorId: err.id
                });
        }
    }

    function signInOnEnter({ key }) {
        if (key === 'Enter' && !buttonDisabled) {
            signIn();
        }
    }

    // function openChat() {
    //     const intercomChatElement = document.querySelector('div[class="intercom-chat"]');
    //     simulateMouseClick(intercomChatElement);
    // }

    return (
        <div className={baseClassName}>
            <header className={`${baseClassName}__header`}>
                <h1 className={`${baseClassName}__title`}>Welcome</h1>
                <SwitchToSignUp />
            </header>
            <ByzzerModal
                onClose={signOut}
                heading={"Byzzer Subscription Expired"}
                show={showExpiredModal}
            >
                Your Byzzer Subscription has expired. Please reach out to your Sales representative to renew your subscription.
            </ByzzerModal>
            <ByzzerModal
                onClose={signOut}
                heading={"Account Disabled"}
                show={showDisabledModal}
            >
                <p>Your subscription has been disabled.  Please reach out to your account manager for more information.</p>
                {/* if we want to add chat and email us back */}
                {/* <div className={'byzzer-modal__contact'}>
                    <span onClick={openChat} className={'byzzer-modal__open-chat'}>
                        Chat With Us
                    </span>
                    |<CustomerSupportLink className={'byzzer-modal__email-us'}>Email Us</CustomerSupportLink>
                </div> */}
            </ByzzerModal>
            <main className={`${baseClassName}__content`}>
                {/* @ts-ignore */}
                <ByzzerInput
                    data-test={`${baseClassName}_email`}
                    className={'email'}
                    label={'Email'}
                    name={'email'}
                    value={credentials.email}
                    onChange={onChange}
                    validate={false}
                    placeholder={'example@mail.com'}
                    type={'email'}
                    required
                    errors={emailErrors}
                    variant={'secondary'}
                />
                {/* @ts-ignore */}
                <ByzzerInput
                    data-test={`${baseClassName}_password`}
                    className={'password'}
                    name={'password'}
                    value={credentials.password}
                    onKeyDown={signInOnEnter}
                    validate={false}
                    onChange={onChange}
                    placeholder={'Password'}
                    type={'password'}
                    label={'Password'}
                    required
                    variant={'secondary'}
                />
                <div className={`${baseClassName}__remember-me`}>
                    {/* @ts-ignore */}
                    <ByzzerCheckbox
                        data-test={`${baseClassName}_remember_me`}
                        label={'Remember Me'}
                        onChange={updateRememberMe}
                        checked={rememberMe}
                    />
                </div>
            </main>
            <footer className={`${baseClassName}__footer`}>
                <ByzzerButton
                    data-test={`${baseClassName}_sing_in`}
                    onClick={signIn}
                    label={'Sign In'}
                    disabled={buttonDisabled || busy}
                    busy={busy}
                />

                <NavLink
                    data-test={`${baseClassName}_forget_password`}
                    className={`byzzer-link ${baseClassName}__forgot-password`}
                    replace
                    to={'forgot_password'}
                >
                    Forgot Password
                </NavLink>
            </footer>
        </div>
    );
}

export default SignIn;
