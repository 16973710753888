import {TimePeriod} from "@/utils/timePeriod/TimePeriod";

const months = {
    '4': 'Jan',
    '8': 'Feb',
    '13': 'Mar',
    '17': 'Apr',
    '21': 'May',
    '26': 'Jun',
    '30': 'Jul',
    '34': 'Aug',
    '39': 'Sep',
    '43': 'Oct',
    '47': 'Nov',
    '0': 'Dec',
}


// todo: move this into the app on context because it doesn't change relative to the current user

export function timePeriodToString(timePeriod: string, endDateCode?: string): string {

    const [type, rawDuration, rawOffset] = timePeriod.split(':');
    let offset = Number(rawOffset);
    const duration = Number(rawDuration);

    if(rawDuration === 'all') {
        return 'All';
    }
    switch (type.slice(0, 2)) {
        case '1m': {
            const monthIndex = months[offset % 52];
            const tp = new TimePeriod(offset);
            const duration = tp.is4455() ? 5 : 4;
            const periodYear = 2018 + Math.floor((offset - 1) / 52);
            return `${monthIndex} ${periodYear} - ${duration} Weeks Ending ${tp.toDateString()}`;
        }
        case '1y': {
            const periodYear = 2018 + Math.floor((offset - 1) / 52);
            return `Full Calendar Year ${periodYear} - 52 Weeks Ending ${new TimePeriod(offset).toDateString()}`;
        }
        case 'cp':
        case 'pp':
        case 'pe': {
            let prefix: string;
            const year = type.slice(0, 2) === "cp" ? (offset / 52) : offset;
            const isYear = year % 1 === 0;
            offset = isYear ? year : offset;
            const offsetUnit = year % 1 === 0 ? 'Year-Ago' : 'Week-Ago';

            if (type === 'pe1y' || type === 'pp1y') {
                prefix = `Year to Date`;
            } else {
                prefix = `${type === "pp" ? 'Previous' : 'Latest'} ${duration} Week${duration === 1 ? '' : 's'}`;
            }
            let subtractOffset = 0;
            if(type.startsWith('pp')) {
                subtractOffset = duration;
            }
            if (offset > 0) {
                
                const endingTp = new TimePeriod(endDateCode!).subtract((isYear ? offset * 52 : offset) + subtractOffset);
                const suffix = `Ending ${endingTp.toDateString()}`
                if (offset === 1) {
                    return `${prefix} ${offsetUnit} ${suffix}`;
                }

                if (offset > 1) {
                    return `${prefix} ${offset}-${offsetUnit} ${suffix}`;
                }
            }
            return `${prefix} Ending ${new TimePeriod(endDateCode!).subtract(subtractOffset).toDateString()}`;
        }
        case '1w': {
            return `1 Week Ending ${new TimePeriod(offset).toDateString()}`;
        }
        case '4s':
        case '4w': {
            return `4 Weeks Ending ${new TimePeriod(offset).toDateString()}`;
        }
        case '1q': {
            const q = Math.floor(((offset - 1) % 52)/13) + 1;
            const year = 18 + Math.floor((offset - 1) / 52);
            return `Q${q} ${year} - 13 Weeks Ending ${new TimePeriod(offset).toDateString()}`;
        }
        case 'cw': {
            return `${duration} Weeks Ending ${new TimePeriod(offset).toDateString()}`;
        }

    }

    return '';
}
