
 // Todo: Replace this with something in-house and get rid of AntD
import { message } from 'antd';

export type ToastTriggerProps = {
    content: string;
    duration?: number;
    type?: 'info' | 'success' | 'error' | 'warning' | 'loading';
}

/**
 * @param 'content' is the message to be displayed in the toast - Required
 * @param 'duration' is the time in seconds that the toast will be displayed - Default is 2 seconds
 * @param 'type' is the type of toast to be displayed - Default is 'success'
 */
export function triggerToast({
    content, 
    duration = 2, // in seconds,
    type = 'success'
}: ToastTriggerProps) {
    if (message) {
        message.destroy(); // if the user fires multiple toasts, we want to destroy the previous one before showing the new one
    }
    message.success({
        content,
        style: {
            marginTop: '20vh',
            zIndex: 9999
        },
        duration,
        type
    });
}

export type TriggerToastType = typeof triggerToast;