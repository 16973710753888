import './DodResourceLink.scss'
import ByzzerLink from '@/components/form/ByzzerLink';
import classNames from 'classnames';

const baseClassName = 'dod-resource-link';
export const DodResourceLink = ({className = ''}) => {
    return <span className={classNames(baseClassName, className)}>
        Need guidance?
        <ByzzerLink target="_blank" href='https://learn.byzzer.ai/en/collections/3501063-data-on-demand'>
            Check out the DOD resources here!
        </ByzzerLink>
    </span>
};

export default DodResourceLink;
