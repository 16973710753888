import './ReportOverlayFilters.scss';
import classNames from 'classnames';
import { MouseEvent, useCallback, useState } from 'react';

export type FilterOverlayProps = {
    title?: string;
    subtitle?: string;
    children?: React.ReactNode;
    footer?: React.ReactNode;
    className?: string;
    closeOverlay?: any;
} & OnlyRenderableIf &
    React.HTMLAttributes<HTMLDivElement>;

export function FilterOverlay({ className, children, onlyRenderIf = true, footer,closeOverlay, ...props }: FilterOverlayProps) {
    const baseClassName = 'filters-overlay';

    if (onlyRenderIf !== true) return <></>;
    const [showFilters, setShowFilters] = useState(false);

    const toggleFilterPanel = useCallback(
        (e: MouseEvent<HTMLElement>) => {
            e.preventDefault();
            console.log('Clicked');
            setShowFilters(!showFilters);
            closeOverlay?.();
        },
        [showFilters]
    );

    return (
        <div
            className={classNames(`${baseClassName}`, {
                [`${baseClassName}--open`]: !showFilters,
            })}
            {...props}
        >
            <div title="Filter Report" className={`${baseClassName}__trigger`}>
                <div className={`${baseClassName}__trigger-close`} onClick={toggleFilterPanel}>
                    Close{' '}
                </div>
            </div>
            <header className={`${baseClassName}__header`}>
                {props.title && <div className={`${baseClassName}__title`}>{props.title}</div>}
                {props.subtitle && <div className={`${baseClassName}__subtitle`}>{props.subtitle}</div>}
            </header>
            <main className={`${baseClassName}__content`}>{children}</main>
            <footer className={classNames(`${baseClassName}__footer`, className)}>{footer}</footer>
        </div>
    );
}
