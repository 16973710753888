import React, { forwardRef, ReactNode } from 'react';
import classnames from 'classnames';
import './FocusKpiStep.scss';
import { ByzzerButton, StepRef, WizardActions, WizardContent, WizardHeader, WizardStep } from '@byzzer/ui-components';
import { FocusKpiFilter, FocusKpiFiltersProps } from './FocusKpiFilter';

export type FocusKpiStepProps = {
    title?: ReactNode;
    onComplete?: () => void;
    onCancel?: () => void;
    enabled?: boolean;
} & FocusKpiFiltersProps;

export const FocusKpiStep = forwardRef<StepRef, FocusKpiStepProps>(
    ({ className, title, onComplete, enabled, onCancel, ...props }, ref) => {
        
        const baseClassName = 'byz-focuskpi-step';

        function handleCancelClick() {
            onCancel?.();
        }

        function handleComplete(): boolean {
            onComplete?.();
            return false;
        }
        return (
            <WizardStep className={classnames(baseClassName, className)} ref={ref} title={title} enabled>
                <WizardHeader>
                    <h1 className={'report-run-config-wizard__step-title'}>{title}</h1>
                </WizardHeader>

                <WizardContent>{<FocusKpiFilter {...props} />}</WizardContent>

                <WizardActions nextText="Save" beforeNext={handleComplete}>
                    <ByzzerButton onClick={handleCancelClick} type={'negative'}>
                        Cancel
                    </ByzzerButton>
                </WizardActions>

            </WizardStep>
        );
    }
);

export default FocusKpiStep;
FocusKpiStep.displayName = 'FocusKpiStep';
