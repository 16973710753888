import { defaultPpgId, defaultPpgName, upcLevelPpgName, upcLevelPpgId } from '@/config/globalVars';

export const DEFAULT_PPG_OPTION = {
    display: defaultPpgName,
    value: defaultPpgId,
};

export const UPC_PPG_OPTION = {
    display: upcLevelPpgName,
    value: upcLevelPpgId,
};

export type PPGOption = {
    display: string;
    value: number;
};
export type PPG = {
    categories?: string[];
    characteristics?: string[];
    createdBy?: any;
    createdDtm?: Date;
    id: number;
    isEditable: boolean;
    title: string;
    type: string;
    updatedDtm: Date;
};

let allPPGs: PPG[] = [];

export function setAllPpgs(ppgs: PPG[]) {
    allPPGs = ppgs;
}

export function getPPGOptionsforCategories(categories?: string[]): PPGOption[] {
    if (categories) {
        const filteredPpgs = allPPGs
            .filter((ppg) => ppg?.categories?.some((category) => categories?.includes(category)))
            .map(({ id, title }) => ({
                display: title,
                value: id,
            }));

        const ppgOptionsList = [DEFAULT_PPG_OPTION, UPC_PPG_OPTION, ...filteredPpgs].sort((a, b) => {
            return a.display.localeCompare(b.display, 'en', { sensitivity: 'base' });
        });
        return ppgOptionsList;
    } else return [];
}

export function getPPGDisplayValue(ppgId: number): string {
    let ppgDisplayValue = '';
    if (ppgId) {
        switch (ppgId) {
            case defaultPpgId:
                ppgDisplayValue = defaultPpgName;
                break;
            case upcLevelPpgId:
                ppgDisplayValue = upcLevelPpgName;
                break;
            default:
                ppgDisplayValue = allPPGs?.find((ppg) => ppg.id === ppgId)?.title ?? '';
                break;
        }
    }

    return ppgDisplayValue;
}
