import React from 'react';
import {ByzzerLink} from '@/components/form/ByzzerLink';

export const timePeriodOptions = [
    'Latest 1 Week',
    'Latest 4 Weeks',
    'Latest 13 Weeks',
    'Latest 52 Weeks',
    'Year to Date',
];

/**
 * business questions with key and values
 * key is for identity purpose'
 * value is for display purpose
 */
export const businessQuestions = [
    {
        key: 0,
        value: 'All',
    },
    {
        key: 1,
        value: 'How is my product, competitors, or category performing?',
    },
    // {
    //     key: 2,
    //     value: 'How does my performance compare to my competitors?',
    // },
    {
        key: 2,
        value: 'Why is my volume or my category volume changing?',
    },
    {
        key: 3,
        value: 'Where do I have opportunity to grow my distribution?',
    },
    {
        key: 4,
        value: 'Is my price competitive in the category?',
    },
    {
        key: 5,
        value: 'How effective are my promotions at driving sales?',
    },
    {
        key: 6,
        value: 'Who are my shoppers, and how are they behaving?',
    },
    {
        key: 7,
        value: 'How are my shoppers or their behavior changing?',
    },
    // {
    //     key: 9,
    //     value: 'How is my brand performing at a Retailer?',
    // },
    {
        key: 8,
        value: 'Where should I invest?',
    },
];

/**
 * report types with key and values
 * key is for identity purpose'
 * value is for display purpose
 */
export const reportTypes = [
    {
        key: 0,
        value: 'All',
    },
    {
        key: 1,
        value: 'Core Base',
    },
    {
        key: 2,
        value: 'Core Advanced',
    },
    {
        key: 3,
        value: 'Smart',
    },
    {
        key: 4,
        value: 'Favorites',
    },
];
/**
 * report sections with key and values
 * key is for identity purpose
 * value is for display purpose
 */
export const orderedSectionHeadersForDemo = [
    'Performance',
    'Wellness Reports',
    'Category Trends',
    'Distribution',
    'Pricing',
    'Promotions',
    'Shopper Behavior',
    'Omni',
];

export const orderedSectionHeaders =
    // @ts-ignore
    import.meta.env.REACT_APP_SHOW_AOD_OMNISECTION === 'true'
        ? orderedSectionHeadersForDemo
        : [
              'Omni',
              'Performance',
              'Wellness Reports',
              'Category Trends',
              'Distribution',
              'Pricing',
              'Promotions',
              'Shopper Behavior',
          ];

export const reportSectionOptions = [
    'All',
    'Category Trends',
    'Distribution',
    'Performance',
    'Pricing',
    'Promotions',
    'Shopper Behavior',
    'Wellness Reports',
    'Omni',
].map((display, value) => ({
    display,
    value
}))

/**
 * roles with key and values
 * key is for identity purpose'
 * role is for display purpose
 */
export const productSkuFeaturesRoles = [
    {
        key: 1,
        role: 'Sales',
    },
    {
        key: 2,
        role: 'Marketing',
    },
    {
        key: 3,
        role: 'Executive',
    },
    {
        key: 4,
        role: 'Innovation',
    },
    {
        key: 5,
        role: 'Analyst',
    },
    {
        key: 6,
        role: 'Brand Manager',
    },
];
export const CadenceTypes = [
    {
        key: 1,
        value: 'Quarterly',
    },
    {
        key: 2,
        value: 'Monthly',
    },
    {
        key: 3,
        value: 'Weekly',
    },
    {
        key: 4,
        value: 'Per Topic',
    },
];

/**
 * product size with key and values
 * key is for identity purpose'
 * value is for display purpose
 */
export const productSizes = [
    {
        key: 0,
        value: 'COUNT',
    },
    {
        key: 1,
        value: 'FLUID OUNCE',
    },
    {
        key: 2,
        value: 'FOOT',
    },
    {
        key: 3,
        value: 'GALLON',
    },
    {
        key: 4,
        value: 'GRAM',
    },
    {
        key: 5,
        value: 'MILLILITER',
    },
    {
        key: 6,
        value: 'OUNCE',
    },
    {
        key: 7,
        value: 'POUND',
    },
    {
        key: 8,
        value: 'SQUARE FOOT',
    },
    {
        key: 9,
        value: 'YARD',
    },
];

export const driverList = [
    {
        name: '$ Sales',
        key: 'sales',
    },
    {
        name: 'Overall Score',
        key: 'gradeValue',
    },
    {
        name: 'Promotion',
        description:
            'How effective is your promotion strategy, based on depth of discount, in-store tactics, promotion timing, and allocation of support.',
        key: 'promotion',
        popupDescription: (
            <>
                <p>
                    The Promotion Function score measures your brand’s effectiveness in driving sales through promotions
                    based on the tactics, support, and timing of your promotions. Drill into the scorecard to see your
                    performance on each of the actions which drive the promotion score.
                </p>
                <br />
                <span>
                    For more information on the metrics and calculations refer to the complete guide to{' '}
                    <ByzzerLink href="https://learn.byzzer.ai/en/articles/6289675-understanding-your-promotion-scores"
                            rel="noreferrer"
                            target="_blank">
                            understanding your promotion scores
                    </ByzzerLink>
                </span>
            </>
        ),
    },
    {
        name: 'Pricing',
        description:
            'How effective is your pricing strategy, based on everyday vs. promoted price investment, internal pricing structure, and historical price changes.',
        key: 'pricing',
        popupDescription: (
            <>
                <p>
                    Pricing Function evaluates your current prices and historical price changes to score your pricing
                    actions. The better your past price decisions have been at matching the optimal category position,
                    the better your scores will be. The pricing scores will take into account category pricing dynamics
                    in addition to your pricing and pricing elasticity or sensitivity.
                </p>
                <br />
                <span>
                    For more information on the metrics and calculations refer to the complete guide to{' '}
                    <ByzzerLink
                            href="https://learn.byzzer.ai/en/articles/6289671-understanding-your-pricing-scores"
                            rel="noreferrer"
                            target="_blank"
                        >
                            understanding your pricing scores
                    </ByzzerLink>
                </span>
            </>
        ),
    },
    {
        name: 'Assortment',
        description:
            'How effective is your assortment strategy, based on where your items are being carried, and how much opportunity or risk you have.',
        key: 'assortment',
        popupDescription: (
            <>
                <p>
                    The assortment functional score assesses your opportunities and risks. The fewer the risks and the
                    more opportunities your brand has, the more room you have to grow and therefore the higher your
                    score. Opportunities will emerge when your brand is performing well but is not fully distributed.
                    When you are fully distributed and don’t have enough opportunities your score will also decline
                    because your brand is saturated in the market already. Risks will emerge when your brand is not
                    performing at the same level of competitors in the category.
                </p>
                <br />
                <span>
                    For more information on the metrics and calculations refer to the complete guide to{' '}
                    <ByzzerLink
                            href="https://learn.byzzer.ai/en/articles/6289664-understanding-your-assortment-scores"
                            rel="noreferrer"
                            target="_blank"
                        >
                            understanding your assortment scores
                    </ByzzerLink>
                </span>
            </>
        ),
    },
    {
        name: 'New Products',
        description:
            'How effective are your newest product launches, based on performance vs. the category and other new products.',
        key: 'product',
        popupDescription: (
            <>
                <p>
                    New Products function benchmarks how your new products are performing vs. other new products in the
                    category. The actions in the function will address if your sales and distribution are keeping pace
                    with other items in the category for the first 13 weeks of performance, coverage and potential. If
                    your product has been in the market for more or less than 13 weeks, jump into the dashboard to see
                    the trend at the exact number of weeks your product has been selling. With this simple score you can
                    identify which items might need attention to accelerate performance before it is too late.
                </p>
                <br />
                <span>
                    For more information on the metrics and calculations refer to the complete guide to{' '}
                    <ByzzerLink
                            href="https://learn.byzzer.ai/en/articles/6292226-understanding-your-new-product-scores"
                            rel="noreferrer"
                            target="_blank"
                        >
                            understanding your new product scores
                    </ByzzerLink>
                </span>
            </>
        ),
    },
];

export const menuData = [
    {
        title: 'Pricing',
        key: 'pricing',
        values: [
            'Everyday vs. Promo Strategy',
            'Regular Price Thresholds',
            'Price Position in Category',
            'Internal Price Ladder',
            'Everyday Velocity',
            'Historical Price Changes',
        ],
    },
    {
        title: 'Promotion',
        key: 'promotion',
        values: [
            'Efficiency',
            'Depth of Discount',
            'Promoted Price Thresholds',
            'Fair Share of Promo Support',
            'Promotion Timing',
        ],
    },
    {
        title: 'Assortment',
        key: 'assortment',
        values: ['Assortment Score Index', 'Velocity', 'Opportunities and Risks'],
    },
    {
        title: 'New Products',
        key: 'product',
        values: ['Distribution Curve', 'Sales Curve', 'Velocity', 'Market Fit', 'TDP Upside', 'Hit Rate vs. Coverage'],
    },
];

export const marketTypes = [
    'Channel',
    'Account',
    'Stateline',
    'Major Market',
    'Region',
    'Division',
    'Remaining Market',
];
// @ts-ignore
const staticContentHost = import.meta.env.REACT_APP_STATIC_PAGE_HOST ?? 'https://byzzer-static-pages.web.app';
export const reportDescriptionUrl = `${staticContentHost}/product_details`;
export const alertsDescriptionUrl = reportDescriptionUrl;
export const ppgTemplateUrl = `${window.location.origin}/ppg-template.xlsx`;
export const customCharacteristicsTemplateUrl = `${window.location.origin}/custom-characteristic-template.xlsx`;
export const defaultPpgId = -1;
export const defaultPpgName = 'Default (Brand+Size+Pack Size)';
export const defaultPpgNameForDod = 'DEFAULT';
export const upcLevelPpgId = -2;
export const upcLevelPpgName = 'Report by UPC';

export const syndicateTimePeriods = [
    {
        key: '1',
        value: 'Periods ending latest week',
    },
    {
        key: '2',
        value: 'Weeks',
    },
    {
        key: '3',
        value: '4 Weeks',
    },
    {
        key: '4',
        value: 'Months',
    },
    {
        key: '5',
        value: 'Quarters',
    },
    {
        key: '6',
        value: 'Years',
    },
    {
        key: '7',
        value: 'Custom Time Period',
    },
];
export const timePeriodOptionsForFolder = [
    'Latest 1 Week',
    'Latest 4 Weeks',
    'Latest 12 Weeks',
    'Latest 13 Weeks',
    'Latest 26 Weeks',
    'Latest 52 Weeks',
    'Year to Date',
];

export const marketTypeValues = {
    REGULAR: 'regular',
    CHANNEL: 'channel',
    RETAILER: 'retailer',
    COMPARATIVE: 'comparitive', // different spelling used in search and regular data for markets
    COMPARITIVE: 'comparative',
};

export const customTabsListForUI = {
    other: 'Other',
    quarterly: 'Quarterly',
    'monthly-4-4-5': 'Monthly (4-4-5 cadence)',
    'monthly-4-4-4': 'Monthly (4-4-4 cadence)',
};

export const customTabsKeyMap = {
    other: '',
    quarterly: '5',
    'monthly-4-4-5': '4',
    'monthly-4-4-4': '3',
};

export const channelList = ['All Outlets', 'Club', 'Convenience', 'Dollar', 'Drug', 'Grocery', 'Mass', 'Other', 'Pet'];

export const duplicateCharacteristicFolders = ['Brand', 'Manufacturer', 'Parent Company', 'Sub-Category'];
export const specificCharsExclusionReportSkus = ['492', '31', '485'];
export const charsToBeFilteredOut = ['Sub-Category', 'Brand'];
export const salesThresholdOptions = ['$5M - $50M', '$50M - $150M', '$150M - $300M'];
export const growthThresholdOptions = ['5%', '10%', '15%', '20%', '25%', '50%', '100%', '200%'];
export const salesThresholdInfoText = `Select a $ Sales threshold to apply a filter to the data based on each manufacturer's total annual sales. Any manufacturers that do not meet the $ Sales threshold will be filtered out of the report. Please note: the $ Sales threshold is based on a market aggregation of Total FMCG Retailers, Total Convenience Stores, Total Pet Stores, and Total Liquor Plus and a time period of Latest 52 Weeks.`;
export const growthThresholdInfoText = `Select a growth threshold to apply a filter to the data based on each manufacturer's Total $ Sales % Change vs Year-Ago. Any manufacturers that do not meet the growth threshold will be filtered out of the report. Please note: the growth threshold is based on a market aggregation of Total FMCG Retailers, Total Convenience Stores, Total Pet Stores, and Total Liquor Plus and a time period of Latest 52 Weeks.`;
export const specificFocusBrandOptionalSkus = ['30', '31', '33', '57', '456', '458', '485', '489', '490', '491', '492','524'];
export const focusProductLevels = ['Total Store', 'Department', 'Supercategory', 'Category'];
export const brandManufacturerLevels = ['Focus Brand', 'Manufacturer'];
export const focusProductLevelLabelInfo =
    'User can change this to other levels to capture wider or narrower data scopes than Category.';
export const brandManufacturerLabelInfo =
    `User can change this to a manufacturer level to include all the brands under a specific manufacturer in your data scope.`;
export const cpsBrandMandatorySkus = ['790'];
export const charsSelectorCustomlyNeededSkus = ['45'];
export const dynamicHierarchyEnabledSkus = ['57'];
export const aggregationHierarchyOptions = ['Total Store', 'Department', 'Supercategory', 'Category'];
export const aggregationHierarchyInfoText =
    'This report is at the brand level, with the option to view brands at different aggregations. Whichever hierarchy level you choose will split the brands at that level.';
export const layoutPopoverCategoryInfoText =
    'Hide Category from your output to remove Category breaks in your data. This will allow you to aggregate data across Categories, so you can aggregate data up to the Super Category level or sum Brand level sales across multiple Categories.';
export const customScheduleDescription = 'Run multiple times from a pre-defined or custom cadence';
export const productLevelTipInfo = 'Run multiple times from a pre-defined or custom cadence';
export const charDimensionsTipInfo = 'Category, Subcategory, and Brand will always be included by default. The dimension you choose will break the category down into comparative groups based on that characteristic. Do you want to compare different brands, or the performance of different features such as flavor or pack type?';
export const keyCharecteristicsTipInfo = 'Select the key characteristics of your brand that will help determine whether a retailer is a good fit for you. For example, if you are a wellness-focused brand, you may want to select Organic is ORGANIC and Natural is NATURAL';
export const categoryAliasTipInfo = 'Rename the category/categories to something that will be relevant to you and the retailer. For example, the categories like Baby Food and Infant Formula with characteristic filter Organic would be renamed to "Organic Baby Feeding';
export const productAliasTipInfo = 'Rename the Product to something that will be relevant to you and the retailer. For example, the categories like Baby Food and Infant Formula with characteristic filter Organic would be renamed to "Organic Baby Feeding';
export const brandAliasTipInfo = 'Rename the brand to something relevant for your selection and will fit in your presentation. For example, if you selected a whole manufacturer, Jane\'s Cookies LLC you might want to rename it to Jane\'s Cookies';
export const manufacturerAliasTipInfo = 'Rename the manufacturer to something relevant for your selection and will fit in your presentation. For example, if you selected a whole manufacturer, Jane\'s Cookies LLC you might want to rename it to Jane\'s Cookies';
export const keyCharecteristicsAliasTipInfo = 'Rename your key characteristic to something that will be concise and clear. This is optional if the existing label is something simple like NATURAL, but if it\'s lengthy you may want to relabel it.';
export const omniTotalMarketsTipInfo = 'The Total Market will be reported to understand overall performance for products across both online and in-store purchasing. It\'s recommended that you choose an Omni market for this selection.';
export const omniSubMarketsTipInfo = 'The Sub-Market will be reported to understand how the performance in the sub-market is driving overall performance for the total market. It\'s recommended that you choose the online market beneath the total market for this selection. For example if you chose a Total Retailer for the prior selection, here you would choose the Online market of the same retailer.';
export const demographicDimensionsTipInfo = 'This report includes an analysis on share of shoppers within different demographic segments. Choose the demographics to be broken out by segment in this report.';
export const remainingMarketTipInfo = (<div className={'market-picker__tip-content'}>
<p>Selecting the remaining market allows you to choose how to compare the focus retailer to its competitors.</p>
<p>For example</p>
<p>
    If you are going to run a alert on a Grocery retailer, you will often have two choices for Remaining market:
    Remaining Grocery, and Remaining FMCG.
</p>
<p>
    If you compete in a category that is typically sold in grocery, and other FMCG channels like Mass or Drug
    aren't as relevant, you can choose Remaining Grocery to make sure the competitive market is applicable.
</p>
<p>
    On the other hand, if sales in other channels like Mass, Drug, Club or Dollar are relevant, you can choose
    Remaining FMCG for a more complete picture of the total competitive market.
</p>
</div>
)
export const demographicDimensionsStaticLabel = (
    <div>
        <p>
            Would you like to filter the report to a subset of shoppers? Are you interested in a particular demographic,
            like shoppers who are a certain age or live in a certain environment?
        </p>
        <p>Apply demographic filters to view data for a specific population.</p>
    </div>
);
export const comparisonMarketStaticLabel = (
    <div>        
        <b>I want to compare my product performance…</b>
    </div>
);
export const storySkus = {
    categoryReview: '521',
    categoryManagement: '524',
    shopperSnapshot: '793',
    brandReview: '832',
    priceAndPromotion: '841',
    categoryTrendAnalysis:'842'
}
export const storySkusForInsightValidation = [storySkus.categoryReview, storySkus.categoryManagement, storySkus.shopperSnapshot, storySkus.brandReview];
export const objectivesModifyPanelSkus = [storySkus.categoryReview, storySkus.brandReview,storySkus.categoryTrendAnalysis];
export const storyToHide = [storySkus.brandReview];
export const warningMessages = {
    categoryAlert: "Please rename your category/characteristic selection with an alias to help create concise slides",
    characteristicAlert: "Please rename your characteristic selection with an alias to help create concise slides",
    characteristicAndCategoryAlert:'You have selected more than one category and characteristic therefore please rename your category and characteristic selection with an alias to help create concise slides.',
    multiBrand: 'You have selected more than one brand/category therefore please rename your brand/category selection with an alias to help create concise slides.',
    addAlias: 'You have selected more than one brand/category which will result in overlapping of content in your story. Please rename the brand/category selection with an alias.',
    categoryWithNoBrands: `You did not select any categories that contain your focus brand. This may result in no data in your`,
    includeBrandManufacturer: 'When applying a brand or manufacturer filter, make sure to include Focus Brand/Manufacturer in your selections.',
    multiCharacteristic: 'To select multiple values of the same characteristic, use the check boxes instead of another filter.',
    identicalFilters: 'Filters should not be identical.',
    identicalCharacteristic: 'You have already selected this characteristic dimension.',
    productSetRequired: 'Please choose atleast one competitive/product set.',
}
//Note :this should be actually deleted as part of code clean up process as everything should be taken up now from the  const selectorLabel and not selectorLabels, selectorLabels seems to have very old labels
export const selectorLabels = {
    product: 'Select Focus Product',
    trend:'Select Focus Trend',
    characteristic: 'Select a Featured Characteristic',
    focusMarket: 'Select Focus Market',
    focusMarkets: 'Select Focus Markets',
    comparisonMarket: 'Select a Comparison Market',
    channelOutlets: 'Select Channels or Outlets',
    outlets: 'Select Outlets',
    timePeriod: 'Select Time Period',
    panelTimePeriod: 'Select Panel Time Period',
    marketPerformanceObjective: 'Select Markets for Market Performance Objective',
    marketsToWatch: 'Select Markets to Watch',
    shopperSegment: 'shopper_segment'
}

/** Define the default labels for AlbyUI Selctors */
export const selectorLabel = {
    focusBrands: 'Choose your focus brand(s)',
    categories: 'Select your category(s)',
    brandAggregationLevel: '',
    manufacturers: 'Choose your manufacturer(s)',
    characteristicDimensions: 'Choose your characteristic dimension(s):',
    characteristicFilters: 'Apply characteristic filter(s)',
    brands: 'Choose your brand(s)',
    keyCharecteristics: 'Choose Your Key Charecteristic(s)',
    ppgId: 'Choose your PPG definition',
    focusMarkets: 'Select your focus market(s)',
    marketAlias: 'Rename your market(s)',
    characteristicsAlias: 'Rename your characteristic(s)',
    categoryAlias: 'Rename your category',
    productAlias: 'Rename your product',
    brandAlias: 'Rename your brand',
    manufacturerAlias: 'Rename your manufacturer',
    keyCharecteristicsAlias: 'Rename your key characteristic',
    presetTp: 'Use a preset',
    customTp: 'Create a custom time period',
    demographicDimensions: 'Choose your demographic area(s):',
    productSubCategory: 'Select your subcategory(s)',
    aggregationLevel: 'Aggregate brands across',
    attributeGroup: 'Select your attribute group',
    attributes: 'Choose your attributes',
    salesThreshold: 'Select your $ sales threshold',
    growthThreshold: 'Select your growth threshold',
    remainingMarkets: 'Select the remaining market(s)',
    remainingMarketToggle: 'Include remaining market(s)',
    dimensionRunFilter: 'Choose your dimension',
    chartData: 'Trending Period:',
    omniCategories: 'Select your category',
    omniProducts: 'Choose the product level',
    omniBrands: 'Select your brand(s)',
    omniBrandFamily: 'Select your brand family',
    omniBrandLow: 'Select your brand low(s)',
    omniManufacturer: 'Select your manufacturer(s)',
    omniTotalMarkets: 'Select the total market(s)',
    omniSubMarkets: 'Select the submarket(s)',
    demographicDimensionsMessage: demographicDimensionsStaticLabel,
    enableDemographicsYes: 'Yes, filter to a certain demographic.',
    enableDemographicsNo: 'No, run my report on all shoppers.',
    alertRecipientJustMe: 'Just Me',
    alertRecipientMyTeam: 'My Team',
    alertRecipientEveryOne: 'Everyone on my subscription',
    alertRecipientSelectedMember: 'Pick specific team member(s)',
    comparisonMarketAccount: 'across different retailers',
    comparisonMarketMajorMarket: 'across major markets (cities & their surrounding areas)',
    comparisonMarketStateline: 'across state lines',
    comparisonMarketRegion: 'across US regions',
    comparisonMarketStaticText: comparisonMarketStaticLabel,
    focusKPI:'Focus KPI',
};
/** Define the default info tips for AlbyUI Selctors */
export const selectorTipInfo = {
    categories: focusProductLevelLabelInfo,
    omniProducts: 'Run multiple times from a pre-defined or custom cadence',
    chartData: 'This selection will organize your data in the Base vs Incremental stacked bar chart and the table.',
    characteristicDimensions: charDimensionsTipInfo,
    characteristicFilters: 'Narrow your data scope to a subset of products using characteristic filters.',
    keyCharecteristics: keyCharecteristicsTipInfo,
    categoryAlias: categoryAliasTipInfo,
    productAlias: productAliasTipInfo,
    brandAlias: brandAliasTipInfo,
    manufacturerAlias: manufacturerAliasTipInfo,
    keyCharecteristicsAlias: keyCharecteristicsAliasTipInfo,
    aggregationLevel: aggregationHierarchyInfoText,
    salesThreshold: salesThresholdInfoText,
    growthThreshold: growthThresholdInfoText,
    omniTotalMarkets: omniTotalMarketsTipInfo,
    omniSubMarkets: omniSubMarketsTipInfo,
    remainingmarketToggle: remainingMarketTipInfo,
    demographicDimensions: demographicDimensionsTipInfo,
    focusKPI:'This selector allows you to configure your reports to a specific KPI.', // BYZ-11572
};
export const alertMessages = {
    noTeamRunsLeft: `Your team does not have any run counts left for this type of story. Ask your admin to
    allocate runs in order to use this story.`,
    usedAllPurchasedCredits: `You have used all remaining purchased credits for the selected story.`,
    upgradeSubscription: `You don't have sufficient runs for this story. Please upgrade your subscription or add
    more runs in the shop.`,
    storyDownloading: `Your Story is Downloading`,
    storyProcessing: `Your Story is Processing and will be available on History Page in few moments.`,
    storyUnavailable: 'Your Story Not available at the moment',
    noRunAgainFeature: `The run again feature is not available for this story. Create a new story
    and you will be able to run that story again from the history.`
}

export const selectorStates = {
    pending: 'pending',
    completed: 'completed'
}
export const eventTypes = {
    storyGenerated: 'story_generated',
    storyFailed:  'story_failed'
}

export const selectorConfigs = {
    marketOption5: 'market:option5' // in progress
}

export const defaultConfigs = {
    story: ['product:option1', 'market:option1', 'timePeriod:default', 'featuredCharacteristic:option1', 'comparisonMarket:option1', 'panelTimePeriod:default'],
    report: ['product:option1', 'market:option1', 'timePeriod:default'],
    scoreCard: ['product:option1', 'market:option3', 'timePeriod:default']
}

export const alertConstants = {
   comparisonMarket :'comparisonMarket',
   alertButtonType : 'alertSelector',
   market:'market'
}

export const ppgPreUploadHeading = 'Upload PPG List';

export const upcCharacteristicOption: Characteristic = { code:'upc', displayName: 'UPC' };

export const myPortFolioStandingsFooterText = 'Top 10 lists are based on 52-week $ Sales and Total FMCG Retailers + Total Convenience Stores + Total Liquor Plus + Total Pet Stores.';
