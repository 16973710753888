import './ByzzerModalContainer.scss';
import React, {createContext, useContext, useEffect, useMemo, useRef} from 'react';
import ModalContainer, {ContainerRef} from "react-modal-promise";
import {useLocation} from "react-router-dom";
import {noop} from "@/utils/noop";

export type ByzzerModalContextValue = {
    reject(id: string): void;
    rejectAll(): void;
    resolve(id: string): void;
    resolveAll(): void;
    getContainer(): ContainerRef | null;
}
export const ByzzerModalContext = createContext<ByzzerModalContextValue>({
    getContainer: noop,
    reject: noop,
    rejectAll: noop,
    resolve: noop,
    resolveAll: noop,
});
export const useByzzerModalContainer = () => useContext(ByzzerModalContext);

export function ByzzerModalContainer({children}) {

    const containerRef = useRef<ContainerRef>(null);
    const location = useLocation();
    const state = useMemo<ByzzerModalContextValue>(() => ({
        reject(id) {
            containerRef.current?.reject(id);
        },
        rejectAll() {
            containerRef.current?.rejectAll();
        },
        resolve(id) {
            containerRef.current?.resolve(id);
        },
        resolveAll() {
            containerRef.current?.resolveAll();
        },
        getContainer(): ContainerRef | null {
            return containerRef.current;
        }
    }), [])

    useEffect(() => {
        containerRef.current?.rejectAll();
    }, [location])

    useEffect(() => {
        // @ts-ignore
        window.byzzerModals = {
            reject(id) {
                containerRef.current?.reject(id)
            },
            resolve(id) {
                containerRef.current?.resolve(id)
            },
            rejectAll() {
                containerRef.current?.rejectAll()
            },
            resolveAll() {
                containerRef.current?.resolveAll()
            },
            getContainer() {
                return containerRef.current;
            }
        }
    }, [containerRef.current]);

    return <ByzzerModalContext.Provider value={state}>
        {children}
        <ModalContainer exitTimeout={0} enterTimeout={0} ref={containerRef}/>
    </ByzzerModalContext.Provider>
}

export default React.memo(ByzzerModalContainer);
