import './Maintenance.scss';
import React from 'react';
import { PageFooter } from '@/components/layout';
import { useUser } from '@/contexts/UserContext';
import maintenanceLogo from '@images/maintenanceLogo.svg';
import './Authentication.scss';
import { useBetterNavigate } from '@/utils';
import { ByzzerLink } from '@/components/form';
import { useAccountService } from '@/hooks/useAccountService';

function DataRefreshMaintenance({  eta }) {
    const { user, isCsrObo } = useUser();
    const {logout} = useAccountService();
    const navigate = useBetterNavigate();
    const signOut = () => {
        logout();
        if (isCsrObo) {
            navigate('/auth/csr_sign_in');
        }
    };

    return (
        // the authentication class is used to show byzzer icon on top of maintenance dialog
        <div className="authentication maintenance-overlay">
            <div className={`authentication__logo`}>
                <div className={`authentication__logo-image`} />
                <div className={`authentication__logo-text`}>Byzzer</div>
            </div>
            <div className="maintenance">
                <img className="maintenance-logo" src={maintenanceLogo} alt="maintenance-logo" />
                <div className="maintenance-content">
                    <h1 className="maintenance-header">Processing Updated Data</h1>
                    <div className="maintenance-content-body">
                        <p>
                            Updated data is currently being processed and will be made available to all Financial
                            Services clients shortly. Byzzer will be updated and available to you with the most current
                            data at that time. For any further queries please mail to
                            <span className="support-link link"> byzzer.support@smb.nielseniq.com</span> or use the
                            chat.
                        </p>
                        <div className="maintenance-eta">
                            {eta && eta > Date.now()
                                ? `Data will be available by ${eta?.toLocaleString('en-US', {
                                      dateStyle: 'long',
                                      timeStyle: 'short',
                                  })}`
                                : `Data will be available shortly!`}
                        </div>
                        {user && (
                            <>
                                <p className="logout-section">
                                    Your session will continue when the data is back, or you may&nbsp;
                                    <ByzzerLink
                                        label="Log out"
                                        onClick={signOut}
                                        trackClick={`maintenance-logout`}
                                    />
                                    &nbsp;now.
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DataRefreshMaintenance;
