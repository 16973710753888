import './DodConditionBuilder.scss';
import {DodFactSet, FactCondition} from "@/types/DodRun";
import React, {ChangeEvent, useMemo} from "react";
import {WithUid} from "@/types/WithUid";
import {ByzzerButton, ByzzerChangeEventHandler, ByzzerConditionInput, ByzzerSelectOption} from "@byzzer/ui-components";
import {factSetToDisplayNames} from "@/components/DodConfigEditor/common/utils";
import {nanoid} from "nanoid";
import {clone} from "lodash";
import {ByzzerSwitch} from "@/components/form";
import classnames from "classnames";

export interface DodConditionEditorProps {
    className?: string;
    value: FactCondition[];
    facts: DodFactSet[];
    onChange?: ByzzerChangeEventHandler<FactCondition[]>;
    minConditions?: number;
    maxConditions?: number;
}

interface DodConditionalBuilderValue {
    display?: string;
    value?: number | string;
    factId?: number | string;
    factType?: string;
    format: string;
}

const COMPARATOR_OPERATORS = [
    {
        display: 'Greater than',
        value: 'gt',
    },
    {
        display: 'Less than',
        value: 'lt',
    },
    {
        display: 'Equal To',
        value: 'eq',
    },
];

const BLANK_FACT_CONDITION: FactCondition = {
    fact: '',
    comparator: 'gt',
    value: '',
    condition: 'and',
};

const baseClassName = 'dod-condition-builder';

export function DodConditionEditor(
    {
        className,
        value,
        facts,
        onChange,
        minConditions = 1,
        maxConditions = Infinity,
    }: DodConditionEditorProps) {

    const conditions = useMemo<WithUid<FactCondition, string>[]>(() => {
        if (value.length) {
            return value.map((condition, uid) => ({
                value: condition,
                uid: `${uid}`,
            }));
        }

        return [createDefaultCondition()];
    }, [value]);

    const factOptions = useMemo<ByzzerSelectOption[]>(() => {
        return facts.map(factSetToDisplayNames).flat().map((fact) => ({
            display: fact,
            value: fact,
        }))
    }, [facts]);

    function getSelectedFacts(fact, selectedFacts): DodConditionalBuilderValue | undefined {
        let tempFactsValues: DodConditionalBuilderValue[] = []
        if (fact.fact) {
            tempFactsValues.push({
                display: fact.display,
                value: fact.display,
                factId: fact.id,
                factType: 'direct',
                format: fact?.format,
            })
        }
        if (fact.yearAgo) {
            tempFactsValues.push({
                display: fact.display + ' Year-Ago',
                value: fact.display + ' Year-Ago',
                factId: fact.id,
                factType: 'ya',
                format: fact?.format,
            })
        }
        if (fact.changeVsYearAgo) {
            tempFactsValues.push({
                display: fact.display + ` Change vs Year-Ago`,
                value: fact.display + ` Change vs Year-Ago`,
                factId: fact.id,
                factType: 'cngYa',
                format: fact?.format,
            })
        }
        if (fact.percentChangeVsYearAgo) {
            tempFactsValues.push({
                display: fact.display + ` % Change vs Year-Ago`,
                value: fact.display + ` % Change vs Year-Ago`,
                factId: fact.id,
                factType: 'percentChangeVsYearAgo',
                format: fact?.format,
            })
        }
        return tempFactsValues.find((fact) => fact.display === selectedFacts);
    }

    function getFormattedFactsValue(selectedFacts) {
        let getObj: DodConditionalBuilderValue = {display: '', value: '', factId: '', factType: '', format: '',}
        facts.forEach((fact) => {
            if (factSetToDisplayNames(fact).includes(selectedFacts)) {
                getObj = getSelectedFacts(fact, selectedFacts) ?? fact
                //logic to get format property for facts (e.g. Total Unit Sales Change vs. Year-Ago) in the required format to add suffix("%") or prefix("$")
            }
        })
        return getObj
    }

    function getFactsValue(selectedFacts) {
        let formattedFactsValue = {
            decimalScale: 0,
            suffix: '',
            prefix: ''
        }
        let decimalScale = 0;
        if (getFormattedFactsValue(selectedFacts).format) {
            let splittedFormat = getFormattedFactsValue(selectedFacts).format.split(';');
            if (splittedFormat[0]) {
                splittedFormat = splittedFormat[0].split('.');
                if (splittedFormat.length >= 2) {
                    var match = splittedFormat[1].match(/^0+/);
                    decimalScale = match ? match[0].length : 0;
                }
            }
        }
        if (selectedFacts) {
            formattedFactsValue['decimalScale'] = getFormattedFactsValue(selectedFacts).format.includes('.') ? decimalScale : 0
            formattedFactsValue['prefix'] = getFormattedFactsValue(selectedFacts).factType !== 'percentChangeVsYearAgo' && getFormattedFactsValue(selectedFacts).format.includes("$") ? '$' : ''
            formattedFactsValue['suffix'] = getFormattedFactsValue(selectedFacts).factType === 'percentChangeVsYearAgo' || getFormattedFactsValue(selectedFacts).format.includes('%') ? '%' : ''
        }
        return formattedFactsValue
    }

    function handleConditionChange(e, uid: string) {
        const {
            value: {key, operation, value},
        } = e;
        const updatedValue = Array.isArray(value) ? value[0] : value
        const conditionIndex = conditions.findIndex((condition) => uid === condition.uid);
        const updatedConditions: FactCondition[] = conditions.map(v => v.value);
        updatedConditions[conditionIndex] = {
            fact: key,
            comparator: operation,
            value: updatedValue ? updatedValue.replace(/[\\^$*+%(,)|[\]{}]/g, '') : '',
            condition: updatedConditions[conditionIndex]?.condition ?? 'and'
        }
        
        onChange?.({
            value: updatedConditions
        })
    }

    function createDefaultCondition() {
        return {value: clone(BLANK_FACT_CONDITION), uid: nanoid()};
    }

    function add() {
        onChange?.({
            value: [...value, clone(BLANK_FACT_CONDITION)]
        })
    }

    function remove(key: string) {
        const filteredConditions = conditions.filter(condition => key !== condition.uid).map(v => v.value);
        onChange?.({
            value: filteredConditions
        })
    }

    function handleJoinTypeChange(e: ChangeEvent<HTMLInputElement>, uid: string) {
        let updatedFactConditions = conditions?.map((condition, i) => {
            if (uid === condition.uid) {
                condition.value.condition = e.target.checked ? 'or' : 'and'
                return condition
            }
            return condition
        }).map(v => v.value)
        onChange?.({
            value: updatedFactConditions
        })
    }

    function isConditionValid(condition: FactCondition) {
        const isValidConditionValue =
            (typeof condition.value === 'string' || typeof condition.value === 'number') && condition.value;
        return Boolean(condition.fact && condition.comparator && isValidConditionValue);
    }


    return <div className={classnames(baseClassName, className)}>
        {conditions.map(({value, uid}, index) => (
            <React.Fragment key={uid}>

                {index > 0 && (
                    <div className={`${baseClassName}__join-type`}>
                        <div className={classnames(`${baseClassName}__join-type-label`, {
                            [`${baseClassName}__join-type-label--active`]: value.condition === 'and',
                        })}>
                            AND
                        </div>
                        <ByzzerSwitch
                            className={`${baseClassName}__join-toggle`}
                            onChange={(e) => handleJoinTypeChange(e, uid)}
                            value={value.condition === 'or'}
                        />
                        <div className={classnames(`${baseClassName}__join-type-label`, {
                            [`${baseClassName}__join-type-label--active`]: value.condition === 'or',
                        })}>
                            OR
                        </div>
                    </div>
                )}
                <ByzzerConditionInput
                    className={classnames(`${baseClassName}__condition`, {
                        [`${baseClassName}__condition--sans-labels`]: index > 0
                    })}
                    value={{
                        key: value.fact,
                        operation: value.comparator,
                        value: [value.value],
                    }}
                    valueSuffix={getFactsValue(value.fact).suffix}
                    valuePrefix={getFactsValue(value.fact).prefix}
                    valueDecimalScale={getFactsValue(value.fact).decimalScale}
                    valueFixedDecimalScale={Boolean(getFactsValue(value.fact).decimalScale)}
                    valueGroupStyle={'thousand'}
                    valueThousandSeperator={true}
                    isValueTextInput={true}
                    keyLabel={'Fact'}
                    operationLabel={'Operator'}
                    valueLabel={'Value'}
                    keyPlaceholder={'Fact'}
                    operationPlaceholder={'Operation'}
                    valuePlaceholder={'Value'}
                    allowMultipleValues={false}
                    keyOptions={factOptions}
                    operationOptions={COMPARATOR_OPERATORS}
                    allowOperationClear={false}
                    allowKeyClear={false}
                    onChange={(e) => handleConditionChange(e, uid)}
                    valueOptions={[]}
                    actions={
                        <>
                            <ByzzerButton onClick={() => add()}
                                          disabled={
                                              maxConditions <= conditions.length ||
                                              !isConditionValid(value)
                                          }
                                          label={'+'}
                            />

                            <ByzzerButton
                                onClick={() => remove(uid)}
                                disabled={minConditions >= conditions.length}
                                label={'-'}
                            />
                        </>
                    }
                />
            </React.Fragment>
        ))}
    </div>
}
