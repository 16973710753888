import './DodMarketFiltersStep.scss';
import React, {ReactNode, useMemo, useState} from 'react';
import classnames from "classnames";
import {StepRef, WizardContent, WizardStep} from '@byzzer/ui-components';
import {DodMarketFilterBuilder, DodMarketFilterBuilderProps} from '@/components/DodConfigEditor/builders';
import {DodWizardActions} from '../DodWizardActions';
import {uniqBy} from "lodash";
import ByzzerLink from '@/components/form/ByzzerLink';
import { DodResourceLink } from '../../common/DodResourceLink';

export type DodMarketFiltersStepProps = {
    title?: ReactNode;
    enabled?: boolean;
    exclude?: boolean;
} & DodMarketFilterBuilderProps;

const baseClassName = 'dod-run-config-filters-step';

const tip = <>
    <p>Select which markets to include in your run. Clicking the folder (such as Channels or Accounts) will allow you to
        choose specific markets of that type in the center panel.</p>
    <p>Selecting markets in the center panel will filter the run to those markets, as well as include the selected
        markets in the output in the location you designate based on your layout.</p>
    <DodResourceLink />
</>

export const DodMarketFiltersStep = React.forwardRef<StepRef, DodMarketFiltersStepProps>(
    ({className, title = 'Markets', enabled, onValidityChange, exclude, ...props}, ref) => {
        const [filtersValid, setFiltersValid] = useState<boolean>(false);
        const count = useMemo<string>(() => {
            const {values, summedSelections} = props.value.markets;
            const allValues = [...values, ...summedSelections.map(ss => ss.values)].flat();
            const count = uniqBy(allValues, 'key').length;
            return count ? `(${count})` : '';
        }, [props.value]);

        function handleValidationChange(e: ByzzerValidityChangeEvent) {
            setFiltersValid(e.isValid);
            onValidityChange?.(e);
        }

        return (
            <WizardStep className={classnames(baseClassName, className)} ref={ref} id={'markets'}
                        title={`${title} ${count}`} enabled={enabled} exclude={exclude}>
                <WizardContent>
                    <DodMarketFilterBuilder {...props} tip={tip} onValidityChange={handleValidationChange}/>
                </WizardContent>
                <DodWizardActions
                    disableNext={!filtersValid}
                    helpTip={tip}
                    nextDisabledTip={'Please choose at least one market to continue.'}/>
            </WizardStep>
        )
            ;
    }
);

DodMarketFiltersStep.displayName = 'DodMarketFiltersStep';
