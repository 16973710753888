import { forwardRef, ReactNode, useEffect, useState } from 'react';
import classnames from "classnames";
import './ByzzerSalesThresholdSelect.scss';
import {
    ByzzerChangeEventHandler,
    ByzzerSelect,
    WithValue
} from '@byzzer/ui-components';
import { salesThresholdOptions } from '@/config/globalVars';

export type ByzzerSalesThresholdSelectProps = {
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<string[]>;
    value?: string[];
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    maxSelections?: number;
} & OnlyRenderableIf;

const baseClassName = 'byzzer-sales-threshold';

export const ByzzerSalesThresholdSelect = forwardRef<WithValue<string[]>, ByzzerSalesThresholdSelectProps>(
    ({
         onChange,
         name,
         label,
         className,
         placeholder = 'Select your $ Sales Threshold',
         value,
         disabled,
         maxSelections,
        onlyRenderIf,
     }, ref) => {

        if(onlyRenderIf === false) return <></>;

        const [internalValue, setInternalValue] = useState<string[]>();

        useEffect(() => {
            setInternalValue(value)
        }, [value])

        return (
            <ByzzerSelect
                ref={ref}
                name={name}
                className={classnames(baseClassName, className)}
                options={salesThresholdOptions}
                disabled={disabled}
                placeholder={placeholder}
                label={label as any}
                value={internalValue}
                maxSelections={maxSelections}
                onChange={onChange}
                allowMultiple={true} // TODO - it might be single select in legacy, see if that is still the case
            />
        )
    });

export default ByzzerSalesThresholdSelect;
