import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './SavedScenarios.scss';

export type SavedScenariosProps = {
    data?: any;
}

export const SavedScenarios = ({
 ...props
}: SavedScenariosProps) => {

    const baseClassName = 'byz-saved-scenarios';


    return (
        <div className={classnames(`${baseClassName}`)}>
           SavedScenarios
        </div>
    );

};

export default SavedScenarios;

SavedScenarios.displayName = 'SavedScenarios';