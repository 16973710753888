import './TeamManager.scss';
import React, {useEffect, useState} from 'react';
import {ByzzerButton} from '@/components/form';
import {useTenantApi} from '@/hooks/useTenantApi';
import CautionIcon from '@/images/icons/CautionIcon.svg';
import {confirm, openErrorModal} from '@/components/form/ByzzerModal';
import {openInvitationEditor} from "@/components/TeamManager/InvitationEditor";
import {openConsultantInvitationEditor} from "@/components/TeamManager/ConsultantInvitationEditor";
import {openManageTeamEditor} from "@/components/TeamManager/ManageTeamEditor";
import {openTeamEditor} from "@/components/TeamManager/TeamEditor";
import {openAddTeamEditor} from "@/components/TeamManager/AddTeamEditor";
import {openMemberEditor} from "@/components/TeamManager/MemberEditor";
import {openTeamRoleInfo} from "@/components/TeamManager/TeamRoleInfo";
import {openTeamInfo} from "@/components/TeamManager/TeamInfo";
import {TipIcon} from "@/components/icons";
import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";
import {useUser} from "@/contexts/UserContext";
import {TrackClick} from "@/analytics";
import {ByzzerLink} from "@/components/form/ByzzerLink";
import classnames from "classnames";
import {openMemberApprovalEditor} from "@/components/TeamManager/MemberApprovalEditor";
import { TeamMember } from '@/types/InvitationTypes';

const memberTypeDisplayMapping = {
    'admin': 'Admin',
    'viewer': 'Viewer',
    'user': 'User',
}

const baseClassName = 'team-manager';

export type ConsultantPreset = {role: string; email: string; teamId: number};

function TeamManager() {
    const { createTeam, deleteTeam, getMyCompanySubscriptionUsage, getTeamMembers, getTeams, removeInvitation, removeUser, updateTeam } = useTenantApi();
    const {user, isCsrObo: isObo, oboRole} = useUser();
    const [loading, setLoading] = useState(false);
    const [members, setMembers] = useState<TeamMember[]>([]);

    const [availableUserCredits, setAvailableUserCredits] = useState(0);
    const [canManage, setCanManage] = useState(user?.role?.toLowerCase() === 'admin');
    const [ready, setReady] = useState(true);
    const [teamOptions, setTeamOptions] = useState<{display: string; value: number}[]>([]);
    const isSalesObo = isObo && oboRole === 'sales'
    useEffect(() => {
        setCanManage(user?.role?.toLowerCase() === 'admin')
    }, [user])
    useEffect(() => {
        loadData();
    }, []);

    async function onTeamRoleInfo() {
        openTeamRoleInfo()
    }

    async function onTeamInfo() {
        openTeamInfo()
    }

    async function loadData() {
        try {
            setLoading(true);

            const [usage, users, teams] = await Promise.all([
                getMyCompanySubscriptionUsage(),
                getTeamMembers(),
                getTeams()
            ])
            setAvailableUserCredits(usage.users?.limit - usage.users.used);
            setMembers(users.map(user => ({
                ...user,
                // join first and last name if either exists
                fullName: user.firstName?.trim?.() || user.lastName?.trim() ?
                    `${user.firstName} ${user.lastName}`.trim() : ''
            })).sort((a, b) => a.fullName?.localeCompare(b.fullName)));

            setTeamOptions(teams.map(({id, displayName}) => ({
                value: id,
                display: displayName
            })));
        } catch (err: any) {
            openErrorModal({
                title: 'Uh oh',
                content: <>
                    <p>Something unexpected happened while sending your invitation.</p>
                </>,
                errorId: err.id
            })
        } finally {
            setLoading(false);
            setReady(true);
        }
    }

    async function onDeleteMember(member: TeamMember) {
        const {configurationCounts, type, id} = member;
        const {countOfAlertsWithOtherRecipients, countOfScheduledSeries} = configurationCounts ?? {};
        const showAlertCount = Boolean(countOfAlertsWithOtherRecipients);
        const showSeriesCount = Boolean(countOfScheduledSeries);
        const showCountMessage = showAlertCount || showSeriesCount;
        if (
            await confirm({
                closeButton: true,
                className: 'subscription-users__remove-container',
                content: (
                    <div className="subscription-users__remove">
                        <div className="subscription-users__remove-header">
                            <img src={CautionIcon} alt={'!'} />
                            <span>Delete Member</span>
                        </div>
                        <div className="subscription-users__remove-subtitle">
                            Are you sure you want to delete this member? {!showCountMessage && <p>This action cannot be undone.</p>}
                        </div>
                        {Number(showCountMessage) > 0 && <div className="subscription-users__remove-content">
                            <p>By proceeding, you will not receive any future deliveries associated with this member:</p>
                            <ul className='subscription-users__remove-list'>
                                {showAlertCount && <li>{countOfAlertsWithOtherRecipients} Alerts that other members receive</li>}
                                {showSeriesCount && <li>{countOfScheduledSeries} Scheduled Data On Demand Series</li>}
                            </ul>
                            <p>This action cannot be undone. Please review carefully before confirming deletion.</p>
                        </div>}
                    </div>
                ),
                yesLabel: 'Remove',
                noLabel: 'Cancel',
            })
        ) {
            if (type === 'invitation') {

                await removeInvitation(id);
            } else {
                await removeUser(id);
            }
            await loadData();
        }
    }

    async function onEditMember(member: TeamMember) {
        let result;
        // if (!member?.isConsultant) {
        //     result = await openMemberEditor({
        //         // @ts-ignore
        //         user: {
        //             role: member.role,
        //             email: member.email,
        //             teamId: member.teamId,
        //         },
        //         teamOptions
        //     });
        // } else {
            result = await openMemberEditor({
                // @ts-ignore
                user: member,
                teamOptions
            });
        // }


        switch (result) {
            case 'updated':
                return loadData();
            case 'delete':
                return onDeleteMember(member);
            default:
        }
    }

    async function onApproveMember(member: TeamMember) {

        if(await openMemberApprovalEditor({
            // @ts-ignore
            member,
            teamOptions
        })) {
            return loadData();
        }
    }

    async function onAddMemberClick() {
        const inviteResult: false | undefined | { type: 'consultant'; data?: ConsultantPreset; } = await openInvitationEditor({
            // @ts-ignore
            teamOptions, availableUserCredits, members
        })
        if (inviteResult && inviteResult?.type === 'consultant') {
            await handleAddConsultant(inviteResult?.data);
        } else if (inviteResult) {
            await loadData();
        }
    }

    async function handleAddConsultant(preset?: ConsultantPreset) {
        if (await openConsultantInvitationEditor({ 
            // @ts-ignore
            teamOptions, availableUserCredits, members, preset
        })) { // if it resolves as truthy, do the needful
            await loadData();
        }
    }

    async function onManageTeamClick() {
        const result = await openManageTeamEditor({
            // @ts-ignore
            teamOptions
        })
        if (result.function === "delete") {
            await onTeamDeleteClick(result.team)
        } else if (result.function === "close") {
            // We shouldnt need to reload the data here, as the user is just closing the modal
            // await loadData(); 
        } else if (result.function === "edit") {
            await onTeamEditClick(result.team)
        } else if (result.function === 'add') {
            await onTeamAddClick()
        }
    }

    async function teamCreation(count, teams) {
        if (count > 1) {
            try {
                await createTeam({displayName: teams[0]})
                teams.shift();
                count = count - 1;
                await teamCreation(count, teams)
                await loadData()
            } catch (err) {
                console.log('error creating/loading team(s)', err)
            } finally {
                setLoading(false);   
            }
        } else {
            try {
                await createTeam({displayName: teams[0]})
                await loadData()
            } catch (err) {
                console.log('error creating/loading team(s)', err)
            } finally {
                setLoading(false);
            }
        }
    }

    async function onTeamAddClick() {
        const result = await openAddTeamEditor({
            // @ts-ignore
            teamOptions
        })
        if (result.function === "close") {
            await loadData();
        } else if (result.function === "add") {
            let count = result?.newTeams.length
            let teams = result?.newTeams
            if (count > 0) {
                setLoading(true);
                teamCreation(count, teams)
            }
        }
    }

    async function onTeamEditClick(team) {
        const result = await openTeamEditor({
            // @ts-ignore
            team, teamOptions
        })
        if (result.function === "close") {
            await loadData();
        } else if (result.function === "edit") {
            await updateTeam(result.team.id, {id: result.team.id, displayName: result.team.newTitle});
            await loadData();
        }
    }

    async function onTeamDeleteClick(team) {
        if (await confirm({
            closeButton: true,
            className: "subscription-users__remove-container",
            content: <div className="subscription-users__remove">
                <img className="subscription-users__remove-icon" src={CautionIcon} alt={"!"}/>
                <div className="subscription-users__remove-title">Remove Team</div>
                <p>Are you sure you want to delete the team, "{team.display}"?</p>
                <br/>
                <p>If you delete this team move all current members to a new team.</p>
                <p>
                    If report runs are allocated by team and a member is not part of a team, the member will not be able
                    to run a report.
                </p>
            </div>,
            yesLabel: 'Yes, Remove',
            noLabel: 'No'
        })) {
            try {
                setLoading(true);
                await deleteTeam(team.value)
                await loadData()
            } catch (err) {
                console.log('error deleting/loading team(s)', err)
            } finally {
                setLoading(false);
            }
        } else {
            await onManageTeamClick()
        }
    }

    function renderActions(member) {

    }

    if (!ready) {
        return <></>
    }

    const memberCount = members.length;

    return (
        <div className={`${baseClassName}`}>
            <div className={`${baseClassName}__heading`}>
                <div className={`${baseClassName}__summary`}>
                    <div className={`${baseClassName}__title`}>Team Members</div>
                    <div className={`${baseClassName}__notes`}>
                        {(canManage && availableUserCredits > 0) && (<>
                            There {memberCount === 1 ? 'is' : 'are'} currently {memberCount} member{memberCount === 1 ? '' : 's'} on
                            your subscription. You can
                            add {availableUserCredits} more.
                        </>)}
                        {/* using less than 1 because of the possibility that more credits where used than are available, it can happen */}
                        {(canManage && availableUserCredits < 1) && (<>
                            There are currently {memberCount} members on company subscription. You have used all of
                            your allotted subscription members. <br/>Remove inactive or pending members or contact your sales rep to purchase more users.
                        </>)}
                    </div>
                </div>
            </div>

            <div className='team-manager__members'>
                <ByzzerMask show={loading} loading={loading}/>
                <table className='team-manager__table'>
                    <thead>
                    <tr>
                        <th><span>Name</span></th>
                        <th><span>Email</span></th>
                        <th>
                            <span>Member Type </span> {/* @ts-ignore */}
                            <TipIcon theme={'light'} onClick={onTeamRoleInfo}/>
                        </th>
                        <th>
                            <span>Team</span> {/* @ts-ignore */}
                            <TipIcon theme={'light'} onClick={onTeamInfo}/></th>
                        <th><span>Status</span></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {members.map(member => (
                        <tr key={member.id}>
                            <td>{member.fullName || '--'}</td>
                            <td>{member.email || '--'}</td>
                            <td>{memberTypeDisplayMapping[member.role] || member.role || '--'}</td>
                            <td>{member.teamName ?? '--'}</td>
                            <td><TeamMemberStatus member={member}/></td>
                            <td>
                                {canManage && !isSalesObo && (
                                    <TeamMemberActions
                                        member={member}
                                        onApprove={onApproveMember}
                                        onEdit={onEditMember}
                                        onDelete={onDeleteMember}/>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <div className={`${baseClassName}__actions`}>
                {canManage && (
                    <>
                        {!isSalesObo && (
                            <TrackClick name={'Sub Members Manage Members clicked'}>
                                <ByzzerButton onClick={onManageTeamClick}>Manage Team</ByzzerButton>
                            </TrackClick>
                        )}
                        {/* <ByzzerButton onClick={handleAddConsultant}>Add A Third Party</ByzzerButton> */}
                        <TrackClick name={'Sub Members Add Team clicked'}>
                            <ByzzerButton onClick={onAddMemberClick}>Add A Member</ByzzerButton>
                        </TrackClick>
                    </>
                )}
            </div>
        </div>
    );
}

// todo: convert this to popup menu
function TeamMemberActions({member, onEdit, onApprove, onDelete, ...props}) {

    const baseClassName = 'team-member-actions';
    const [showEdit, setShowEdit] = useState(false);
    const [showApprove, setShowApprove] = useState(false)

    useEffect(() => {
        setShowEdit(member?.isPending === false);
        setShowApprove(member?.type === 'user' && member?.isPending === true);
    }, [member])

    return <div className={`${baseClassName}`}>
        {showEdit && (
            <div className={classnames(`${baseClassName}__action`, `${baseClassName}__action--edit`)}
                 onClick={() => onEdit(member)}/>
        )}
        {showApprove && (
            <div className={classnames(`${baseClassName}__action`, `${baseClassName}__action--approve`)}
                 onClick={() => onApprove(member)}/>
        )}
        <div className={classnames(`${baseClassName}__action`, `${baseClassName}__action--delete`)}
             onClick={() => onDelete(member)}/>
    </div>
}


function TeamMemberStatus({member}: { member: TeamMember }) {

    const baseClassName = `team-member-status`;
    const [label, setLabel] = useState('--');
    const [stateSuffix, setStateSuffix] = useState('')

    useEffect(() => {

        const {type, isPending, metadata} = member ?? {};
        if (type === 'user' && isPending) {
            setLabel('Pending Approval');
            setStateSuffix('pending-approval')
        } else if (metadata?.isInvitationPendingApproval && metadata?.isInvitationDismissed) {
            setLabel('Not Approved by NIQ');
            setStateSuffix('pending-approval')
        } else if (metadata?.isInvitationPendingApproval) {
            setLabel('Pending NIQ Approval');
            setStateSuffix('pending-approval')
        } else if (type === 'user') {
            setLabel('Active');
            setStateSuffix('active');
        } else if (type === 'invitation') {
            setLabel('Pending Accept')
            setStateSuffix('pending-acceptance')
        } else {
            setLabel('--');
            setStateSuffix('');
        }
    }, [member])
    return <div className={classnames(baseClassName, `${baseClassName}--${stateSuffix}`)}>{label}</div>
}


export default TeamManager;
