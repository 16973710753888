import React, { forwardRef, ReactNode, useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import {
    ByzzerChangeEventHandler,
    ByzzerSelect,
    ByzzerSelectOption,
    WithValue,
    ByzzerTipIcon,
    ByzzerSelectOptionGroup,
} from '@byzzer/ui-components';
import { useTenantApi } from '@/hooks/useTenantApi';
import { RunConfigMarket } from '@/types/ReportRun';
import './OmniMarketSelect.scss';
import { LABELS, MARKET_TYPE } from './omniMarketSelect.constants';

const baseClassName = 'byz-omni-market-select';

export type OmniMarketSelectProps = {
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<RunConfigMarket[]>;
    value?: RunConfigMarket[];
    // disabledOptions?: string[]; // Todo see if additional disabled options are needed.  Already disabling based on entitlement, but using this we could allow further disabling...
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    maxSelections?: number;
    categories?: string[];
    runType: RunType;
    allowMultiple?: boolean;
} & OnlyRenderableIf;

type Market = {
    name?: string;
    key?: string;
    marketType: string;
    isOpen: boolean;
    requiresPurchase?: boolean;
    missingPriorApproval?: boolean;
    categoryNotCovered?: boolean;
    masterCompany?: string;
};

type MarketOption = {
    display: string;
    value: string;
    data: object;
};

type MarketSection = {
    label: string;
    options: MarketOption[];
};

type MarketSections = {
    channel: MarketSection;
    account: MarketSection;
    region: MarketSection;
    requiresPurchaseMissingPriorApproval: MarketSection;
    requiresPurchase: MarketSection;
    missingPriorApproval: MarketSection;
    categoryNotCovered: MarketSection;
};

export const OmniMarketSelect = forwardRef<WithValue<RunConfigMarket>, OmniMarketSelectProps>(
    (
        {
            onChange,
            name,
            label,
            className,
            // disabledOptions = [],
            placeholder,
            value,
            disabled,
            onlyRenderIf,
            maxSelections,
            categories,
            allowMultiple,
            runType,
        },
        ref
    ) => {
        if (onlyRenderIf === false) return <></>;
        const { getOmniMarkets } = useTenantApi();

        const [internalValue, setInternalValue] = useState<ByzzerSelectOption[]>([]);
        const [internalOptions, setInternalOptions] = useState<ByzzerSelectOptionGroup[]>([]);
        const [internalDisabledOptions, setInternalDisabledOptions] = useState<ByzzerSelectOptionGroup[]>([]);
        const initialValueLoaded = useRef<Boolean>(false);

        useEffect(() => {
            if (value && !initialValueLoaded.current) {
                const newInternalValue = value?.map((marketValue) => {
                    return {
                        display: marketValue.name!,
                        value: marketValue.key!,
                    };
                });
                setInternalValue(newInternalValue ?? []);
                initialValueLoaded.current = true;
            } else if (!value || !value?.length) {
                setInternalValue([]);
            }
        }, [value]);

        useEffect(() => {
            const suppressedOptions = internalOptions.filter((optionGroup) => {
                return ![LABELS.CHANNELS, LABELS.ACCOUNTS, LABELS.REGIONS].includes(optionGroup.label);
            });
            setInternalDisabledOptions(suppressedOptions);
        }, [internalOptions]);

        useEffect(() => {
            if (categories?.length) {
                getAndSetMarketsAsOptions(categories);
            }
        }, [categories]);

        const getAndSetMarketsAsOptions = async (categories: string[]) => {
            const marketsResponse = await getOmniMarkets(
                runType, // subscription or adhoc
                categories
            );

            const createMarketOptions = (market: Market): MarketOption => {
                const marketOption = {
                    display: market?.name!,
                    value: market?.key!,
                    data: {
                        ...market,
                    },
                };
                delete marketOption.data?.name;
                delete marketOption.data?.key;
                return marketOption;
            };

            const marketSections: MarketSections = {
                channel: { label: LABELS.CHANNELS, options: [] },
                account: { label: LABELS.ACCOUNTS, options: [] },
                region: { label: LABELS.REGIONS, options: [] },
                requiresPurchaseMissingPriorApproval: {
                    label: LABELS.PREMIUM_MARKET_PRIOR_APPROVAL,
                    options: [],
                },
                requiresPurchase: { label: LABELS.PREMIUM_MARKET_REQUIRES_PURCHASE, options: [] },
                missingPriorApproval: { label: LABELS.REQUIRES_PRIOR_APPROVAL, options: [] },
                categoryNotCovered: { label: LABELS.CATEGORY_NOT_COVERED, options: [] },
            };

            for (const marketObj of marketsResponse) {
                const modifiedMarket = createMarketOptions(marketObj);

                if (marketObj.isOpen) {
                    switch (marketObj.marketType.toLowerCase()) {
                        case MARKET_TYPE.CHANNEL:
                            marketSections.channel.options.push(modifiedMarket);
                            break;
                        case MARKET_TYPE.ACCOUNT:
                            marketSections.account.options.push(modifiedMarket);
                            break;
                        case MARKET_TYPE.REGION:
                            marketSections.region.options.push(modifiedMarket);
                            break;
                    }
                } else {
                    if (marketObj.requiresPurchase) {
                        if (marketObj.missingPriorApproval) {
                            marketSections.requiresPurchaseMissingPriorApproval.options.push(modifiedMarket);
                        } else {
                            marketSections.requiresPurchase.options.push(modifiedMarket);
                        }
                    } else if (marketObj.missingPriorApproval) {
                        marketSections.missingPriorApproval.options.push(modifiedMarket);
                    } else if (marketObj.categoryNotCovered) {
                        marketSections.categoryNotCovered.options.push(modifiedMarket);
                    }
                }
            }

            // Sort options alphabetically for specific categories
            const sortOptionsAlphabetically = (mSec: MarketSection) => {
                mSec.options.sort((obj1, obj2) => obj1.display.localeCompare(obj2.display));
            };

            sortOptionsAlphabetically(marketSections.channel);
            sortOptionsAlphabetically(marketSections.account);
            sortOptionsAlphabetically(marketSections.region);

            setInternalOptions(Object.values(marketSections));
        };

        const handleChange = (e: ByzzerChangeEvent<any>) => {
            setInternalValue(e?.data);

            const newMarketValue = {
                value: e?.data?.map((omniMarketData) => {
                    return {
                        key: omniMarketData?.value,
                        name: omniMarketData?.display,
                    };
                }),
                name,
                data: e?.data,
            };

            onChange?.(newMarketValue);
        };

        return (
            <div className={classnames(baseClassName)}>
                <ByzzerSelect // Todo - Integrate ByzzerSingleSelect and ByzzerMultiSelect and allow choice between single and multi select when using this component
                    ref={ref}
                    name={name}
                    className={classnames(baseClassName, className)}
                    options={internalOptions}
                    disabled={disabled}
                    placeholder={placeholder}
                    label={label}
                    value={internalValue}
                    disabledOptions={internalDisabledOptions}
                    onChange={handleChange}
                    optionComponent={React.memo(MarketIntersectionValueLabel)}
                    allowMultiple={allowMultiple}
                    maxSelections={allowMultiple ? maxSelections : undefined}
                />
            </div>
        );
    }
);

export default OmniMarketSelect;

OmniMarketSelect.displayName = 'OmniMarketSelect';

function MarketIntersectionValueLabel({ data: { data: metadata }, children, ...props }: any) {
    // Todo - build a generic option component, post-refactor
    const entitlementMap = {
        missingPriorApproval: `This market requires prior approval by category from the retailer.`,
        requiresPurchase: `${children} is a premium market. You can purchase access to this market as an add on in our Shop.`,
        categoryNotCovered: `${children} does not have coverage in your selected category.`,
        premiumAndMissingPriorApproval: `This market requires prior approval by category from the retailer. ${children} is also a premium market. You can purchase access to this market as an add on in our Shop.`,
    };

    return (
        <>
            <div className={classnames(`${baseClassName}__indicator-container`)}>
                {metadata?.isOpen ? <span className={`${baseClassName}__mrkt-entitlement--open`} /> : undefined}
                {metadata?.categoryNotCovered ? (
                    <ByzzerTipIcon tip={entitlementMap['categoryNotCovered']} className={baseClassName} />
                ) : undefined}
                {metadata?.missingPriorApproval ? (
                    <ByzzerTipIcon tip={entitlementMap['missingPriorApproval']} className={baseClassName} />
                ) : undefined}
                {metadata?.requiresPurchase ? (
                    <ByzzerTipIcon tip={entitlementMap['requiresPurchase']} className={baseClassName} />
                ) : undefined}
                {metadata?.missingPriorApproval && metadata?.requiresPurchase ? (
                    <ByzzerTipIcon tip={entitlementMap['premiumAndMissingPriorApproval']} className={baseClassName} />
                ) : undefined}
            </div>
            <div className={classnames(`${baseClassName}__label-wrapper`)}>{children}</div>
        </>
    );
}
