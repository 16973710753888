import './DodScheduleStep.scss';
import React, {ReactNode, useState} from 'react';
import classnames from 'classnames';
import hashObject from 'object-hash';
import {
    ByzzerButton,
    ByzzerChangeEventHandler,
    ByzzerTipIcon,
    StepRef,
    WizardContent,
    WizardStep
} from '@byzzer/ui-components';
import {DodSchedule} from '@/types/DodRun';
import {useDodWizard} from "@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext";
import DodScheduleRunBuilder from '../../builders/DodScheduleRunBuilder/DodScheduleRunBuilder';
import {DodCancelActionButton} from '../../common/DodCancelActionButton';
import { DodResourceLink } from '../../common/DodResourceLink';

export type DodScheduleStepProps = {
    title?: ReactNode;
    className?: string;
    value: DodSchedule;
    submitDisabled?: boolean;
    onChange: ByzzerChangeEventHandler<DodSchedule>;
    onCreate(): void;
    onModifySchedule?(): void;
    exclude?: boolean;
    enable?: boolean;
    isEditScheduleMode?: boolean;
    initialSchedule?: DodSchedule;
};

const baseClassName = 'dod-run-config-filters-step';
const tip = <>
    <p>If you are looking to schedule a report to run one time on a date that isn’t available yet, select the first
        option, one-time.</p>
    <p>If you are looking to run data weekly for a period of time (e.g. every week for a quarter or a year), select the
        second option, weekly.</p>
    <p>If you are looking for another cadence, review our monthly and quarterly options to see if any of those cadences
        meet your needs. Otherwise select the custom option to create your own custom cadence.
    </p>
    <DodResourceLink />
</>;
export const DodScheduleStep = React.forwardRef<StepRef, DodScheduleStepProps>(
    ({
         className,
         title = 'Schedule',
         value,
         initialSchedule,
         onCreate,
         onChange,
         onModifySchedule,
         exclude,
         submitDisabled,
         enable,
         isEditScheduleMode
     }, ref) => {
        const {calculatingDataPoints} = useDodWizard();

        function handleBeforeNext(): boolean {
            onCreate();
            return false;
        }

        function handleScheduleChange(e: ByzzerChangeEvent<DodSchedule>): void {
            onChange(e);
        }

        function hasFormValuesChanged() {
            if (isEditScheduleMode) {
                const initialValueHash = hashObject(
                    initialSchedule!,
                    {
                        excludeKeys(key: string) {
                            switch (key) {
                                case 'timePeriods':
                                    return initialSchedule?.frequency !== 'custom';
                                case 'startingTimePeriod':
                                    return initialSchedule?.frequency === 'custom';
                                default:
                                    return false;
                            }
                        },
                    }
                );
                const valueHash = hashObject(
                    value,
                    {
                        excludeKeys(key: string) {
                            switch (key) {
                                case 'timePeriods':
                                    return value.frequency !== 'custom';
                                case 'startingTimePeriod':
                                    return value.frequency === 'custom';
                                default:
                                    return false;
                            }
                        },
                    }
                );
                if (initialValueHash === valueHash) return true;
            }
            return false;
        }

        function isNextDisabled(): boolean {
            return Boolean(
                submitDisabled ||
                !value.frequency ||
                (!value.timePeriods?.length && !value.startingTimePeriod)||
                !value.deliveryDay ||
                !value.deliveryGroupType ||
                !value.deliverToUserIds.length ||
                (value.frequency !== 'one_time' && value.frequency !== 'custom' && !value.duration)
            );
        }

        function getDisabledTip() {
            let tip = 'Please select all required fields to continue.';
            if (calculatingDataPoints) {
                tip = 'Waiting For Data Point Calculation To Complete';
            } else if (submitDisabled) {
                tip = 'Your Run Does Not Contain Any Data';
            } else if (
                isNextDisabled() &&
                !value.deliverToUserIds.length &&
                ['team', 'everyone'].includes(value.deliveryGroupType)
            ) {
                tip = 'There are no active members in company subscription to notify about completed runs.';
            } else if (hasFormValuesChanged()) {
                tip = 'Please make any change in existing schedule to continue.';
            }
            return tip;
        }

        return (
            <WizardStep
                id={'scheduler'}
                className={classnames(baseClassName, className)}
                ref={ref}
                exclude={exclude}
                title={title}
                header={<>Test</>}
                enabled={enable}
            >
                <WizardContent>
                    <DodScheduleRunBuilder
                        value={value}
                        onChange={handleScheduleChange}
                    />
                </WizardContent>
                <footer className={`${baseClassName}__actions`}>
                    <span>
                        <ByzzerTipIcon type={'help'} interactive={true} preventDefaultClickEvent={true} tipTrigger={"click"} tipLocation={'top-start' as any} tip={
                            <div className={`${baseClassName}__help-tip`}>{tip}</div>
                        }/>
                    </span>
                    <span className={`${baseClassName}__actions_button`}>
                        {!isEditScheduleMode &&<DodCancelActionButton/> }
                        <ByzzerButton
                            className={`${baseClassName}__schedule-btn`}
                            disabled={calculatingDataPoints || hasFormValuesChanged() || isNextDisabled()}
                            disabledTip={getDisabledTip()}
                            onClick={isEditScheduleMode ? onModifySchedule : handleBeforeNext}
                        >
                            {isEditScheduleMode ? 'Apply' : 'Create Schedule'}
                        </ByzzerButton>
                    </span>

                </footer>
            </WizardStep>
        );
    }
);
