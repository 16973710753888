import './GetVerified.scss';
import React, {useEffect} from 'react';
import {VerifyMeLink} from '@/components/VerifyMeLink';
import {CustomerSupportLink} from "@/components/CustomerSupportLink";
import {useUser} from "@/contexts/UserContext";
import {useBetterNavigate} from "@/utils";
import { useAccountService } from '@/hooks/useAccountService';

const baseClassName = 'get-verified';

export function GetVerified() {

    const {user} = useUser();
    const navigate = useBetterNavigate();
    const {logout} = useAccountService();

    useEffect(() => {
        if(user?.emailVerified) {
            navigate('/dashboard');
        }
    }, [user])
    return (
        <div className={`${baseClassName}`}>
            <div className={`${baseClassName}__logo`}>
                <div className={`${baseClassName}__logo-image`} />
                <div className={`${baseClassName}__logo-text`}>Byzzer</div>
            </div>
            <div className={`${baseClassName}__content`}>
                <h1 className={`${baseClassName}__title`}>
                    Verify your account
                </h1>
                <p>
                    Your verification email should arrive in the next 5 minutes - be sure to check the spam folder.
                </p>                
                <p>Not seeing the email, or ready to finish signing up now? Send us a chat or contact us at <CustomerSupportLink/>.</p>              
                {/* <p onClick={logout}><b>Logout</b></p> */}
            </div>
        </div>
    );
}