import './DashboardNav.scss';
import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import { DashboardNavItem } from '@/components/dashboard/DashboardNavItem';
import { useUserSettingsState } from '@/utils/hooks';
import { useUser } from '@/contexts/UserContext';
import { TrackClick } from '@/analytics';
import useIsMobile from '@/hooks/useIsMobile';
const baseClassName = 'dashboard-nav';

const allMenuItems = [
    {
        content: 'Home',
        type: 'home',
        url: '/dashboard/home',
        otherUrls: [],
        title: 'Home',
    },
    {
        content: 'Alerts',
        type: 'alerts',
        url: '/dashboard/my_alerts',
        otherUrls: ['/dashboard/configure_alert'],
        title: 'My Alerts',
    },
    {
        content: 'Reports',
        type: 'reports',
        url: '/dashboard/my_reports',
        otherUrls: ['/dashboard/configure_report'],
        title: 'My Reports',
    },
    {
        content: 'Brand Score',
        type: 'scorecard',
        url: '/dashboard/my_scorecard',
        otherUrls: [],
        title: 'Brand Score',
    },
    {
        content: 'Data On Demand',
        type: 'extracts',
        url: '/dashboard/extracts/history',
        otherUrls: ['/dashboard/extract_editor', '/dashboard/extracts/scheduled', '/dashboard/extracts/templates'],
        title: 'Data On Demand',
    },
    {
        content: 'Stories',
        type: 'stories',
        url: '/dashboard/stories',
        otherUrls: ['/dashboard/story_builder/'],
        title: 'My Stories',
    },
    {
        content: 'Simulate',
        type: 'simulator',
        url: '/dashboard/my_simulators',
        otherUrls: ['/dashboard/configure_simulator'],
        title: 'Simulate',
    },
]

const hiddenFeaturesOnMobile = ['reports', 'extracts', 'stories', 'scorecard', 'simulator']; // per BYZ-8812

export function DashboardNav({ disabled = false }) {
    const [collapsed, setCollapsed] = useUserSettingsState('navMnuCollapsed', true);
    const [menuItems, setMenuItems] = useState([]);
    const { subscription, user, company, isMultiTenant } = useUser();
    const isMobile = useIsMobile();

    const showCollapseToggle = !isMobile;

    useEffect(() => {
        const filteredItems = allMenuItems.filter((item) => !isMobile || !hiddenFeaturesOnMobile.includes(item.type));
        if (collapsed) {
            setMenuItems(filteredItems.map((item) => item.subItems ?? item).flat());
        } else {
            setMenuItems(filteredItems);
        }
    }, [collapsed, isMobile]);

    function toggleCollapsed() {
        setCollapsed(!collapsed);
    }

    const disabledItemsOnInactivedSubscription = ['Brand Score']; // per BYZ-8812

    return (
        <div
            className={classnames(baseClassName, {
                [`${baseClassName}--collapsed`]: collapsed,
            })}
        >
            <main className={`${baseClassName}__content`}>
                {menuItems.map((menuItem) => {
                    // disable all items except home for a multi-tenant user to allow return to onboard if they haven't completed it and have nav'd to switch company page
                    const disableExceptHomeForMultiTenantToOnboard = isMultiTenant && user.settings?.onboardingCompleted !== 1 && menuItem.type !== 'home';
                    const disableItem = disabled || disableExceptHomeForMultiTenantToOnboard || (!subscription.active && disabledItemsOnInactivedSubscription.includes(menuItem.title));
                    return (
                        <DashboardNavItem
                            key={menuItem.content}
                            disabled={disableItem}
                            collapsed={collapsed}
                            title={menuItem.title}
                            {...menuItem}
                        />
                    )
                })}
            </main>
            {showCollapseToggle && (
                <TrackClick
                    name={'dashboard-nav-collapse-triggered'}
                    data={{
                        currentState: collapsed,
                        userEmail: user?.email,
                        companyName: company?.displayName
                    }}
                >
                    <div className={`${baseClassName}__collapse-trigger`} onClick={toggleCollapsed} />
                </TrackClick>
            )}
        </div>
    );
}

DashboardNav.displayName = 'DashboardNav';
