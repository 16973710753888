import React, { useRef, ReactNode, useEffect } from 'react';
import useState from 'react-usestateref';
import classnames from 'classnames';
import {
    ByzzerSelectOption, ByzzerChangeEvent,
    ByzzerConditionInput, ByzzerSearchableConditionInput, ByzzerChangeEventHandler, ByzzerSelectOptionGroup,
} from '@byzzer/ui-components';
import { useApp } from '@/contexts/UserContext';
import {useTenantApi} from '@/hooks/useTenantApi';
import { DemographicCondition } from '@/types/ReportRun';

export type DemographicOption = {
    display: string;
    value: string;
};

export type DemographicConditionInputProps = {
    value?: DemographicCondition;
    onChange?: ByzzerChangeEventHandler<DemographicCondition>;
    demographics?: DemographicOption[];
    includeLabels?: boolean;
    disabledKeys?: string[];
    searchableFields?: string[];
    search?: any;
    className?: string;
    actions?: ReactNode;
    name?: string;
    renderLargeList?: boolean;
}
const baseClassName = 'byz-demographic-condition';

export const DemographicConditionInput = ({
    value, 
    onChange, 
    demographics, 
    includeLabels, 
    disabledKeys, 
    searchableFields, 
    search, 
    className,
    actions, 
    name,
    renderLargeList,
    ...props
}: DemographicConditionInputProps) => {
    const { getDemographicValues } = useTenantApi();
    const zipCodeOptionsRef = useRef<string[]>();
    const labels = includeLabels ? {
        keyLabel: 'Demographic',
        operationLabel: 'Condition',
        valueLabel: 'Values',
    } : {};
    const { demographicOptions } = useApp();
    const [internalValue, setInternalValue] = useState<DemographicCondition>();
    const [internalValueOptions, setInternalValueOptions] = useState<(ByzzerSelectOption | string | ByzzerSelectOptionGroup)[]>([]);
    const loadedWithValueOptions = useRef<Boolean>(false);

    useEffect(() => {
        if (!loadedWithValueOptions.current && value?.demographic && !internalValueOptions.length) { // triggers dynamic lookup of value options on load, when value is passed in
            // console.log("(2) DemographicConditionInput - useEffect (INITIAL) / value (condition) LOADED ===>> ", value)
            handleKeyChange?.({
                value: value?.demographic,
                name
            })
        } else if (loadedWithValueOptions.current && value) { // not sure if &&value is needed
            setInternalValue(value);
        }
    }, [value]);

    useEffect(() => {
        if (!loadedWithValueOptions.current && !internalValueOptions.length) { // responsible for setting 'value' (third select) after value options have loaded, when default value is passed into conditioninput
            // console.log("(3) DemographicConditionInput - useEffect (INITIAL)  / internalValueOptions updated - 'internalValueOptions' ===>> ", internalValueOptions)
            loadedWithValueOptions.current = true;
            setInternalValue(value)
        }
    }, [internalValueOptions]);

    function handleChange(e: any) {
        const newValue = {
            demographic: e.value?.key,
            condition: e.value?.operation,
            value: e.value?.value?.map((demographicValue: ByzzerSelectOption | string) => (demographicValue as ByzzerSelectOption)?.value ?? (demographicValue as string)),
            data: e.value.data
        };
        if (onChange) {
            onChange?.({
                value: newValue,
                name
            })
        } 
        else {
            setInternalValue(newValue);
        }
    }

    async function handleKeyChange(e: ByzzerChangeEvent<string | undefined | null>) {
        if (e?.value) {
            const { values } = await getDemographicValues(e?.value);
            if (e?.value === 'ZIP_CODE' && !zipCodeOptionsRef.current) {
                zipCodeOptionsRef.current = values;
                setInternalValueOptions(['this is nothing on purpose to trigger the hook for internalValueOptions'])
            } else {
                setInternalValueOptions(values);
            }
        }
    };

    async function searchZipOptions(searchText: string) {
        const data = (zipCodeOptionsRef.current as any)?.filter((option) => {
            return (option?.display ?? option)?.trim()?.toLowerCase()?.includes(searchText?.trim()?.toLowerCase());
        });
        return data;
    }

    if (searchableFields?.includes(value?.demographic ?? '')) {
        return (
            <ByzzerSearchableConditionInput
                // {...props}
                {...labels}
                value={{
                    key: internalValue?.demographic,
                    operation: internalValue?.condition,
                    value: internalValue?.value,
                    data: internalValue?.data
                }}
                className={classnames(baseClassName, className)}
                keyOptions={demographicOptions}     
                operationOptions={['is', 'is not']}
                onKeyChange={handleKeyChange}
                disabledKeys={disabledKeys}
                search={searchZipOptions}
                actions={actions }
                name={name}
                onChange={handleChange}
            />
        )
    }

    return (
        <ByzzerConditionInput
            // {...props}
            {...labels}
            value={{
                key: internalValue?.demographic,
                operation: internalValue?.condition,
                value: internalValue?.value,
                data: internalValue?.data
            }}
            className={classnames(baseClassName, className)}
            keyOptions={demographicOptions}
            operationOptions={['is', 'is not']}
            allowMultipleValues={true}
            valueOptions={internalValueOptions}
            onKeyChange={handleKeyChange}
            disabledKeys={disabledKeys}
            actions={actions}
            name={name}
            onChange={handleChange}
            renderLargeList={renderLargeList}
        />
    )
}

export default DemographicConditionInput;

DemographicConditionInput.displayName = 'DemographicConditionInput';
