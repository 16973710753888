// this file is temporary solution,  I'm only creating this file to extract this logic from the report filter overlay
// do not extend this functionality please look for a better solution (contact carlos if you have questions)

export function whatFiltersShouldIInclude(config, isScorecard = false, reportSku, isOmniReport = false) {
    if (!config) {
        return {};
    }

    const isReport = !isScorecard;

    return {
        // show focus brand selector
        includeFocusBrands:
            config.focusProductSelections?.showFocusBrand ||
            (isScorecard && config.selectors?.brand) ||
            (isReport && config.focusMarketSelections?.focusMarket?.byMarketName?.marketGroup === 'cps'),

        // focus brand optional or not
        focusBrandOptional: isReport && config.focusMarketSelections?.focusMarket?.byMarketName?.marketGroup === 'cps',

        // show theme selector
        includeThemes: config.focusProductSelections?.themeSku,

        // show category selector
        includeCategories: isScorecard && config.selectors?.category,

        // show upc selector
        includeUpcs: isScorecard && config.selectors?.upc,

        // show single market selector
        includeSingleMarket: isScorecard && config.selectors?.markets,

        // show market selector
        includeMultiMarket: isScorecard && config.selectors?.markets.multi,

        // show ppg selector
        includePpgs: (isScorecard && config.ppgReport) || config.focusProductSelections?.ppgId,

        // show time period selector
        includeTimePeriod: isScorecard && config.selectors?.timePeriod,

        // require characteristic
        requireCharacteristic: config.keyCharacteristics,

        // show characteristics
        includeCharacteristics:
            !isOmniReport && ((isScorecard && config.selectors?.characteristicFilters) ||
            (isReport && !config.focusProductSelections?.themeSku && reportSku !== '39') || // include CPS except Shopper Leakage Tree (sku 39)
            config.focusMarketSelections?.focusMarket?.byMarketName?.marketGroup === 'rms'),

        // Subcategory optional or not
        isSubcategoryOptional:
            isReport && config.focusMarketSelections?.focusMarket?.byMarketName?.marketGroup === 'cps',

        // show Subcategories
        includeSubcategories:
            isReport && config.focusMarketSelections?.focusMarket?.byMarketName?.marketGroup === 'cps',

        // Omni Product Level and Omni Focus Product
        // To Do: Use these configs directly from Alby configs
        includeOmniProducts:
            isReport &&
            (config.focusProductSelections?.omniProductLevel || config.focusProductSelections?.omniFocusProduct),
    };
}
