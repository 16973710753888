import './RecipientsRunConfigFilters.scss';
import React, { forwardRef, ReactNode, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import classnames from 'classnames';
import { FilterGroup, FilterSection } from '@/components/ConfigurationEditors/FilterGroup';
import {
    useWizardStepContext,
} from '@byzzer/ui-components';

import { ByzzerBrandSearch } from '@/components/ByzzerBrandSearch';

import { AlertRunConfig } from '@/types/AlertRun';
import {useTenantApi} from '@/hooks/useTenantApi';
import {
    AlertRunConfigWizardContext,
    AlertRunWizardState,
} from '@/components/ConfigurationEditors/AlertConfigurationEditor/AlertRunConfigWizard/AlertRunConfigWizardContext';

import { useCharacteristicService } from '@/services/characteristic.service';
import {AlertRecipientSelector} from '@/components/AlertRecipientSelectorRefactored';

type RecipientsRunConfigFiltersValue = Partial<AlertRunConfig>;

export type RecipientsRunConfigFiltersProps = {
    name?: string;
    onChange?: (e: ByzzerChangeEvent<RecipientsRunConfigFiltersValue>) => void;
    onValidityChange?: (e: ByzzerValidityChangeEvent) => void;
    value?: RecipientsRunConfigFiltersValue;
    summary?: ReactNode;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'product-filters';

export type RecipientsRunConfigFiltersRef = {
    value: RecipientsRunConfigFiltersValue;
    isValid: boolean;
};

export const RecipientsRunConfigFilters = forwardRef<RecipientsRunConfigFiltersRef, RecipientsRunConfigFiltersProps>(
    (
        {
            className,
            value,
            onChange,
            onValidityChange,
            datatype,
            summary,
            name,
            ...props
        },
        ref
    ) => {
        const { getCategoriesByBrands, getCategoriesForTheme } = useTenantApi();
        const { value: contextValue, onChange: onContextChange, state: contextState, runType, sku } = useContext(
            AlertRunConfigWizardContext
        );

        const [internalValue, setInternalValue] = useState<RecipientsRunConfigFiltersValue>({});
        const wasValid = useRef<boolean>(false);

        useEffect(() => {
            const isValid = checkValidity();
    
            if (wasValid.current !== isValid) {
                onValidityChange?.({
                    name,
                    isValid
                });
    
                wasValid.current = isValid;
            }
        }, [internalValue]);

        useEffect(() => {
            setInternalValue(contextValue ?? {});
        }, [contextValue]);

        useEffect(() => {
            if (value) {
                setInternalValue(value);
            }
        }, [value]);

        function checkValidity(): boolean {   
            return Boolean(
                (internalValue.notification?.recipientEmails?.length)
            );
        }

        function handleRecipientChange(e: ByzzerChangeEvent<any>) {
            onContextChange?.('notification' as keyof AlertRunConfig, e.value, e.data);
        }


        return (
            <AlertRecipientSelector
                name={'notification'}
                value={internalValue?.notification}
                onChange={handleRecipientChange}
            />
        );
        
    }
);

RecipientsRunConfigFilters.displayName = 'RecipientsRunConfigFilters';
