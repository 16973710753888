import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useContext } from "react";
import classnames from 'classnames';
import './BrandCategoriesComboInput.scss';
import { ProductSelection } from "@/types/ReportRun";
import { ByzzerBrandSearch } from '@/components/ByzzerBrandSearch';
import { ByzzerCategorySelect } from '@/components/ByzzerCategorySelect';
import { ByzzerChangeEventHandler } from '@byzzer/ui-components';
import {useTenantApi} from '@/hooks/useTenantApi';
import { AlertRunConfigWizardContext } from "../ConfigurationEditors/AlertConfigurationEditor/AlertRunConfigWizard/AlertRunConfigWizardContext";
import { SelectorLabelInfo } from "../SelectorLabelInfo";

export type BrandCategoriesComboInputProps = {
    name: string;
    value: ProductSelection;
    onChange: ByzzerChangeEventHandler<ProductSelection>;
    className?: string;
    minFocusBrands?: number;
    maxFocusBrands?: number;
    minCategories?: number;
    maxCategories?: number;
    minProductSelections?: number;
    maxProductSelections?: number;
    requireFocusBrands?: boolean;
    requireFocusBrand?: boolean;
    requireCategories?: boolean;
    requireProductSelections?: boolean;
    includeProductSelections?: boolean;
    actions?: ReactNode;
}

export const BrandCategoriesComboInput = ({
    name,
    value,
    onChange,
    className,
    minFocusBrands,
    maxFocusBrands,
    minCategories,
    maxCategories,
    minProductSelections,
    maxProductSelections,
    requireProductSelections,
    includeProductSelections,
    requireFocusBrands,
    requireFocusBrand,
    requireCategories,
    actions,
    ...props
}: BrandCategoriesComboInputProps) => {
    const { getCategoriesByBrands } = useTenantApi();
    const baseClassName = 'byz-brand-category-combo-input';

    const [internalValue, setInternalValue] = useState<ProductSelection>();
    const [categoriesToCheckForIntersect, setCategoriesToCheckForIntersect] = useState<string[]>([]);
    const { sku } = useContext(AlertRunConfigWizardContext);

    useEffect(() => {
        setInternalValue(value);
        // console.log(`BrandCategoriesComboInput - useEffect / 'value' updated ===>> `, value);
    }, [value]);

    useEffect(() => {
        // console.log(`BrandCategoriesComboInput - useEffect / 'internalValue' updated ===>> `, internalValue);
    }, [internalValue]);

    useEffect(() => {
        (async () => {
            if (!internalValue?.focusBrands?.length) return;
            const { focusBrands } = internalValue;
            const categories = await Promise.all([
                focusBrands?.length ? await getCategoriesByBrands(focusBrands) : [],
            ]);
            setCategoriesToCheckForIntersect(categories.flat());
        })();
    }, [internalValue?.focusBrands]);

    function handleChange(e: ByzzerChangeEvent<any>) {
        const newValue = JSON.parse(JSON.stringify(value));

        if (e.name === 'categories') {
            newValue.categories = e.value;
        } else if (e.name === 'focusBrands') {
            newValue.focusBrands = e.value;
        }

        if (onChange) {
            onChange?.({
                value: newValue,
                name
            })
        } 
        else {
            setInternalValue(newValue);
        }
    }

    return (
        <div className={classnames(baseClassName)} {...props}>
            <div className={classnames(`${baseClassName}__inputs`)}>
                <ByzzerBrandSearch
                    onlyRenderIf={true}
                    name={'focusBrands'}
                    maxSelections={maxFocusBrands}
                    value={internalValue?.focusBrands}
                    onChange={handleChange}
                    label={
                        <SelectorLabelInfo
                            selectorCode={'focusBrands'}
                            max={maxFocusBrands}
                            sku={sku!}
                            required={requireProductSelections} // TODO: refine this to better integrate alby config if focusBrands is required.  This is a temporary solution.
                            includeSuffix={!requireProductSelections}
                        />
                    }
                />
                <ByzzerCategorySelect
                    name={'categories'}
                    onlyRenderIf={true}
                    value={internalValue?.categories}
                    onChange={handleChange}
                    placeholder={'Select from the list'}
                    allowClear={true}
                    categorySelectionAggregationLevel={'category'}
                    categoriesToCheckForIntersect={categoriesToCheckForIntersect}
                    label={
                        <SelectorLabelInfo
                            sku={sku!}
                            selectorCode={'categories'}
                            max={maxCategories}
                            required={requireProductSelections} // TODO: refine this to better integrate alby config if categories is required.  This is a temporary solution.
                            includeSuffix={!requireProductSelections}
                            showTipInfo={false}
                        />
                    } // todo: refine this to better integrate alby config if category(s) is required
                    shouldDisplayIntersectIndicators={
                        requireFocusBrand || Boolean(categoriesToCheckForIntersect?.length)
                    }
                    maxSelections={maxCategories}
                    brands={internalValue?.focusBrands}
                    groupOptionsByBrandCoverage={requireFocusBrand || Boolean(categoriesToCheckForIntersect?.length)}
                />
            </div>
            {actions ? <div className={classnames(`${baseClassName}__actions`)}>{actions}</div> : undefined}
        </div>
    );

};

export default BrandCategoriesComboInput;

BrandCategoriesComboInput.displayName = 'BrandCategoriesComboInput';