import {routes} from "@/routes/routes";

if (import.meta.env.REACT_APP_GENERATE_ROUTE_LIST) {
    function toRoute({path, children, element, redirect}: any, root = '') {
        path = root ? `${root}/${path}` : path;
        if (children) {
            return children.map((child) => toRoute(child, path)).flat();
        } else if (redirect) {
            return [
                {
                    path,
                    redirect,
                },
            ];
        } else {
            return [
                {
                    path,
                    component: element.type?.name ?? element,
                },
            ];
        }
    }

    global.generateRouteList = () => {
        console.log('debugRoutes = [');
        routes
            .map((route: any) => toRoute(route))
            .flat()
            .forEach(({path, component, redirect}: any) => {
                console.log(
                    `{path: "${path}" ${component ? `, component: <${component}/>` : ``} ${
                        redirect ? `, redirect: "${redirect}"` : ''
                    }},`
                );
            });
        console.log(']');
    };
}
