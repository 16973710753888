import { ByzzerButton, ByzzerInput, ByzzerRadio, ByzzerSelect, ByzzerModal } from '@/components/form';
import React, { useEffect, useState } from 'react';

import { CategorySelector } from '@/views/PPG/editor/CategorySelector';
import {useTenantApi} from '@/hooks/useTenantApi';
import {TrackClick} from "@/analytics";
import { useApp } from '@/contexts/UserContext';

export function ConfigureStep({
    includeCategorySelector,
    includeDefaultCharacteristics,
    instructions,
    title,
    categories,
    characteristics,
    useDefaultCharacteristics,
    onCategoriesChange,
    onUseDefaultCharacteristicsChange,
    onCharacteristicsChange,
    onTitleChange,
    onComplete,
    busy,
}) {
    const { getReferenceData } = useTenantApi();
    const [characteristicOptions, setCharacteristicOptions] = useState<any[]>([]);
    const [canComplete, setCanComplete] = useState(false);
    const [showCharacteristicSelector, setShowCharacteristicSelector] = useState<boolean>(false);
    const [removeDupChar, setRemoveDupChar] = useState(false);
    const [selectedChar, setselectedChar] = useState('');
    const CUSTOM_CHAR_EXTENSION = ' (Custom)';
    const { customCharacteristics } = useApp();    

    useEffect(() => {
        const hasCharacteristics = useDefaultCharacteristics || characteristics.filter(Boolean).length;
        setCanComplete(hasCharacteristics && categories.length && title.trim());
    }, [characteristics, categories, title]);

    useEffect(() => {
        setShowCharacteristicSelector(!useDefaultCharacteristics || !includeDefaultCharacteristics);
    }, [useDefaultCharacteristics, includeDefaultCharacteristics]);

    useEffect(() => {
        loadCharacteristicsForCategories(categories);
    }, [categories]);

    async function loadCharacteristicsForCategories(categories) {
        if (categories.length) {
            const characteristicsForCategories = await getReferenceData('subscription_characteristic_codes', {
                categories,
            });
            let charOptions = characteristicsForCategories;
            const customCharsForCategories = customCharacteristics
                .filter((item) => categories?.map((categ) => categ)?.some((i) => item?.categories?.includes(i)))
                .map((customChar) => ({
                    display: customChar.label + CUSTOM_CHAR_EXTENSION,
                    value: customChar.label,
                }));
            let fullCharacteristics = [
                ...customCharsForCategories.sort((a, b) => a.display.localeCompare(b.display)),
                ...charOptions.sort((a, b) => a.display.localeCompare(b.display)),
            ];
            setCharacteristicOptions(fullCharacteristics);
        } else {
            setCharacteristicOptions([]);
            onCharacteristicsChange([null]);
        }
    }

    function setCharacteristicAtIndex(characteristic, index) {
        setselectedChar(characteristic);        
        
        if( !characteristics.includes(characteristic) ){            
            characteristics[index] = characteristic;
            onCharacteristicsChange([...characteristics]); 
            setRemoveDupChar(false); //to prevent user typing on the background           
        }else{
            const mrktGroupSel = characteristicOptions?.find((item) => item.value === characteristic );
            setRemoveDupChar(true);
            setselectedChar(mrktGroupSel.display);            
        }  
    }

    function removeCharacteristicAtIndex(index) {
        characteristics.splice(index, 1);
        onCharacteristicsChange([...characteristics]);
    }

    function onAddCharacteristicClick() {
        // I don't feel this is right
        onCharacteristicsChange(characteristics.concat(null));
    }

    return (
        <div className="ppg-editor__mapping">
            <div className="ppg-editor__mapping-caption">{instructions}</div>
            {includeCategorySelector && (
                <CategorySelector onChange={onCategoriesChange} categories={categories} variant={'secondary'}/>
            )}
            <section className="ppg-editor__characteristic-selector">
                {includeDefaultCharacteristics && (
                    <>
                        <div className="ppg-editor__mapping-option">
                            {/* @ts-ignore */}
                            <ByzzerRadio
                                checked={useDefaultCharacteristics}
                                name="mapping-option"
                                label="Use Byzzer default (Brand/Size/Pack Size)"
                                onChange={() => onUseDefaultCharacteristicsChange(true)}
                            />
                        </div>
                        <div className="ppg-editor__mapping-option">
                            {/* @ts-ignore */}
                            <ByzzerRadio
                                checked={!useDefaultCharacteristics}
                                name="mapping-option"
                                label="Choose which characteristics to combine for remaining PPGs in the category"
                                onChange={() => onUseDefaultCharacteristicsChange(false)}
                            />
                        </div>
                    </>
                )}
                {showCharacteristicSelector && (
                    <>
                        <h2 className="ppg-editor__section-title">Select your Characteristics:</h2>
                        <div className="ppg-editor__characteristic-selector__options">
                            {characteristics.map((value, index) => (
                                <div className={'ppg-editor__characteristic-option'}>
                                    {/* @ts-ignore */}
                                    <ByzzerSelect
                                        key={`${characteristics.length}.${index}`}
                                        className="ppg-editor__characteristic-selector__selector"
                                        disabled={!categories.length}
                                        value={value}
                                        allowClear
                                        placeholder="Select from the list"
                                        options={characteristicOptions?.sort()}
                                        onChange={(value) => setCharacteristicAtIndex(value, index)}
                                        variant={'secondary'}
                                    />
                                    { removeDupChar && 
                                    /** TODO:to check if alert can be used instead; at present alert appears behind the charecteristic modal and hence I am using ByzzerModal */
                                          <ByzzerModal type='warning' 
                                                show={ removeDupChar }                         
                                                closeOnClickOutside = {true}
                                                onClose={() => setRemoveDupChar(false)} >
                                                    <p>You have chosen {selectedChar} which is already selected in the above list, please select a unique value in the dropdown.</p>
                                         </ByzzerModal>
                                        
                                    }
                                    {characteristics.length > 1 && (
                                        <i
                                            className={'ppg-editor__characteristic-remove'}
                                            onClick={() => removeCharacteristicAtIndex(index)}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="ppg-editor__char-dim-add" onClick={onAddCharacteristicClick}>
                            Add Another
                        </div>
                    </>
                )}
            </section>
            <section className="ppg-editor__naming">
                <h2 className="ppg-editor__section-title">Name your PPG list</h2>
                <div className="ppg-editor__naming-description">
                    When running PPG-level reports, you can choose from different saved lists. Try to name yours in a
                    way that will differentiate it from others on your team.
                </div>
                <div className="ppg-editor__naming-name">
                    {/* @ts-ignore */}
                    <ByzzerInput
                        className={'ppg-editor__naming-input'}
                        type={'text'}
                        placeholder={'Enter name here'}
                        value={title}
                        onChange={(e) => onTitleChange(e.target.value)}
                        variant={'secondary'}
                    />
                </div>
            </section>
            <div className={'ppg-editor__actions'}>
                <TrackClick name={'PPG Save List clicked'}>
                    <ByzzerButton disabled={!canComplete} onClick={onComplete} busy={busy}>
                        Save PPG List
                    </ByzzerButton>
                </TrackClick>    
            </div>
        </div>
    );
}
