import { useEffect } from 'react';
import { ByzzerInput } from '@/components/form';
import { LimitedLabel } from '@/components/LimitedLabel';
import { useStoriesContext } from '@/views/story/buildStory/StoriesContext';
import { OptionalRequired } from '@/components/OptionalRequired';
import { SubCategorySelect } from '@/components/SubCategorySelect';
import { ByzzerBrandSearch } from '@/components/ByzzerBrandSearch';
import { ByzzerCategorySelect } from '@/components/ByzzerCategorySelect';
import { ByzzerInlineSelect, ByzzerTipIcon } from '@byzzer/ui-components';
import ByzzerPPGSelect from '@/components/ByzzerPPGSelect/ByzzerPPGSelect';
import { useCharacteristicService } from '@/services/characteristic.service';
import { FilterGroup, FilterSection } from '@/components/ConfigurationEditors/FilterGroup';
import { CharacteristicCriteriaBuilder } from '@/components/CharacteristicCriteriaBuilder';
import { selectorStates, focusProductLevelLabelInfo } from '@/config/globalVars';
import StoryCharacteristicsDimensionSelect from '@/components/CharacteristicsDimensionSelect/StoryCharateristicsDimensionSelect';
import { StoryRunConifg, StorySelectorConfigOptions } from '@/types/StoriesTypes';
import {
    BRAND_AGGREGATION_LEVELS,
    CATEGORY_AGGREGATION_LEVELS,
    MESSAGE_NO_CATEGORY_MATCH,
    MESSAGE_NO_CATEGORY_MATCH_FOR_MANUFACTURER,
} from '@/constants/stories.constants';
import StoryManufacturerSearch from '@/components/StoryManufacturerSearch/StoryManufacturerSearch';
import { getSelectorDependents } from '@/utils/storiesUtil';
import { SelectorLabelInfo } from '@/components/SelectorLabelInfo/SelectorLabelInfo';

export const StoryProductSelector = () => {
    const {
        setDataSpecificationValues,
        dataSpecificationValues,
        updatePanelStatus,
        categoryToCheckForIntersect,
        storyType,
        updatePanelMessage,
        getSelectorConfiguration,
    } = useStoriesContext();
    const { validateCharacteristicSelections } = useCharacteristicService();
    let { selectorConfiguration, selectorIndex } = getSelectorConfiguration('product');
    let {
        includeKeyCharacteristicsAlias = false,
        includePPGs = false,
        maxPPGs,
        includeBrands = false,
        requireBrands = false,
        maxBrands,
        requirePPGSs = false,
        maxFocusBrands,
        minFocusBrands,
        includeCategories,
        maxCategories,
        includeProductSubcategory = false,
        requireCategories = false,
        maxKeyCharacteristics = 1,
        includeFocusBrands = false,
        maxCharacteristics,
        requireManufacturerAlias = false,
        includeManufacturerAlias = false,
        includeBrandAlias,
        requireFocusBrands = false,
        requireCategoryAlias = false,
        includeProductAlias = false,
        requireBrandAlias = false,
        includeCategoryAlias,
        includeCharacteristics = false,
        requireCharacteristics = false,
        includeKeyCharacteristics,
        requireKeyCharacteristics = false,
        requireProductAlias = false,
        maxCharacteristicDimensions,
        includeManufactures = false,
        requireManufactures = false,
        maxManufacturers,
        includeAggregationLevel,
        includeCharacteristicDimensions = false,
        requireKeyCharacteristicsAlias = false,
        requireCharacteristicDimensions = false,
        excludeCharacteristicIsNot,
        requireProductSubcategory,
    }: StorySelectorConfigOptions = selectorConfiguration;
    useEffect(() => {
        if (checkDataValidity()) {
            updatePanelStatus(selectorIndex, selectorStates.completed);
        } else {
            updatePanelStatus(selectorIndex, selectorStates.pending);
        }
    }, [dataSpecificationValues.runConfig]);
    useEffect(() => {
        if (includeManufactures) {
            if (
                (dataSpecificationValues.runConfig.brandAggregationLevel.toLowerCase() === 'brand' ||
                    dataSpecificationValues.runConfig.brandAggregationLevel.toLowerCase() === 'focus brand') &&
                dataSpecificationValues.runConfig.manufacturers.length
            ) {
                handleSelectionsWithAlias({ value: [], name: 'manufacturers' });
            }
            if (
                dataSpecificationValues.runConfig.brandAggregationLevel.toLowerCase() === 'manufacturer' &&
                dataSpecificationValues.runConfig.focusBrands.length
            ) {
                handleSelectionsWithAlias({ value: [], name: 'focusBrands' });
            }
        }
    }, [dataSpecificationValues.runConfig.brandAggregationLevel]);
    useEffect(() => {
        const { focusBrands = [], categories = [], manufacturers = [] } = { ...dataSpecificationValues.runConfig };
        const requiresCheck = Boolean(
            (focusBrands.length || manufacturers.length) && categories.length && includeFocusBrands && includeCategories
        );
        const missingIntersection =
            requiresCheck && !categories?.some((category) => categoryToCheckForIntersect.includes(category));
        if (missingIntersection) {
            updatePanelMessage(selectorIndex, {
                type: 'warning',
                content: manufacturers?.length ? MESSAGE_NO_CATEGORY_MATCH_FOR_MANUFACTURER : MESSAGE_NO_CATEGORY_MATCH,
            });
        } else {
            updatePanelMessage(selectorIndex, undefined);
        }
    }, [categoryToCheckForIntersect, dataSpecificationValues.runConfig.categories]);

    useEffect(() => {
        if (!dataSpecificationValues.runConfig.focusBrands.length) {
            updatePanelMessage(selectorIndex, undefined);
        }
    }, [dataSpecificationValues.runConfig.focusBrands]);

    useEffect(() => {
        const brands = dataSpecificationValues.runConfig.brands;
        const categories = dataSpecificationValues.runConfig.categories;
        const subcategories = dataSpecificationValues.runConfig.subcategories;
        const brandLength = brands.length;
        const categoryLength = categories.length;
        const subcategoryLength = subcategories.length;
        if (includeProductAlias) {
            if (brandLength) {
                if (brandLength > 1) {
                    onContextChange('productAlias', '');
                } else {
                    onContextChange('productAlias', brands[0]);
                }
            } else if (subcategoryLength) {
                if (subcategoryLength > 1) {
                    onContextChange('productAlias', '');
                } else {
                    onContextChange('productAlias', subcategories[0]);
                }
            } else {
                if (categoryLength > 1) {
                    onContextChange('productAlias', '');
                } else {
                    onContextChange('productAlias', categories[0]);
                }
            }
        }
    }, [
        dataSpecificationValues.runConfig.categories,
        dataSpecificationValues.runConfig.subcategories,
        dataSpecificationValues.runConfig.brands,
    ]);
    useEffect(() => {
        if (requirePPGSs && !dataSpecificationValues.runConfig.ppgData.value) {
            onContextChange('ppgData', {
                display: 'Default (Brand+Size+Pack Size)',
                value: -1,
            });
        }
    }, []);
    const checkCharacteristicValidity = (characteristics): boolean => {
        const characteristicsAndOrKeyCharacteristicsAreValid: any = validateCharacteristicSelections(characteristics, {
            requireCharacteristics,
            requireKeyCharacteristics,
        });
        return characteristicsAndOrKeyCharacteristicsAreValid;
    };
    //TODO : THIS CODE WILL BE MOVED TO storiesContext FILE
    const checkDataValidity = () => {
        return (
            (requireManufactures
                ? !requireFocusBrands ||
                  dataSpecificationValues.runConfig.focusBrands?.length ||
                  dataSpecificationValues.runConfig.manufacturers?.length
                : !requireFocusBrands || dataSpecificationValues.runConfig.focusBrands?.length) &&
            (!requireBrands || dataSpecificationValues.runConfig.brands?.length) &&
            (!requireCategories || dataSpecificationValues.runConfig.categories?.length) &&
            (!requireCharacteristicDimensions || dataSpecificationValues.runConfig.characteristicsDimension?.length) &&
            (!requireCategoryAlias || dataSpecificationValues.runConfig.categoryAlias?.length) &&
            (requireManufacturerAlias
                ? !requireBrandAlias ||
                  dataSpecificationValues.runConfig.brandAlias?.length ||
                  dataSpecificationValues.runConfig.manufacturerAlias?.length
                : !requireBrandAlias || dataSpecificationValues.runConfig.brandAlias?.length) &&
            (!requireCharacteristics || dataSpecificationValues.runConfig.characteristics?.length) &&
            (!requireKeyCharacteristics || dataSpecificationValues.runConfig.characteristics?.[0]?.value?.length) &&
            (!requireKeyCharacteristicsAlias || dataSpecificationValues.runConfig.keyCharacteristicsAlias?.length) &&
            (!requirePPGSs || dataSpecificationValues.runConfig.ppgData?.value) &&
            (!requireProductAlias || dataSpecificationValues.runConfig.productAlias)
        );
    };
    const onContextChange = (name: string, value: any) => {
        setDataSpecificationValues({
            ...dataSpecificationValues,
            runConfig: {
                ...dataSpecificationValues.runConfig,
                [name]: value,
            },
        });
    };
    const handleChange = (e: ByzzerChangeEvent<unknown>) => {
        onContextChange(e.name as keyof StoryRunConifg, e.value);
    };
    const handleAliasChange = (e: any) => {
        onContextChange(e.target.name as keyof StoryRunConifg, e.target.value);
    };

    const handleSelectionsWithAlias = async (e: ByzzerChangeEvent<any>) => {
        const allDependents = getSelectorDependents(e.name);
        const nonAliasDependents = allDependents?.dependent;
        let tempDataSpecValues = { ...dataSpecificationValues };
        if (e.value.length === 1) {
            tempDataSpecValues = {
                ...tempDataSpecValues,
                runConfig: {
                    ...tempDataSpecValues.runConfig,
                    [allDependents?.alias!]: e.value[0],
                    [e.name!]: e.value,
                },
            };
        } else if (e.value.length > 1) {
            tempDataSpecValues = {
                ...dataSpecificationValues,
                runConfig: {
                    ...dataSpecificationValues.runConfig,
                    [allDependents?.alias!]: '',
                    [e.name!]: e.value,
                },
            };
        } else {
            tempDataSpecValues = {
                ...tempDataSpecValues,
                runConfig: { ...tempDataSpecValues.runConfig, [allDependents?.alias!]: '', [e.name!]: e.value },
            };
        }
        nonAliasDependents?.forEach((dependent) => {
            tempDataSpecValues = {
                ...tempDataSpecValues,
                runConfig: { ...tempDataSpecValues.runConfig, [dependent.name]: dependent.value },
            };
        });
        setDataSpecificationValues({ ...tempDataSpecValues });
    };

    const handleCharacteristicsChange = (e: ByzzerChangeEvent<any>) => {
        handleChange(e);
        if (checkCharacteristicValidity(e.value)) {
            updatePanelStatus(selectorIndex, selectorStates.completed);
        } else updatePanelStatus(selectorIndex, selectorStates.pending);
    };
    const updatePPGID = (e: any) => {
        setDataSpecificationValues({
            ...dataSpecificationValues,
            runConfig: { ...dataSpecificationValues.runConfig, ppgData: e.data },
        });
    };
    //TODO Create a fallback for alby crash (Sprint 1.64)
    return (
        <FilterGroup key={selectorIndex}>
            <FilterSection
                onlyRenderIf={
                    includeFocusBrands ||
                    includeBrands ||
                    includeCategories ||
                    includeAggregationLevel ||
                    includeProductSubcategory
                }
            >
                <ByzzerBrandSearch
                    name={'focusBrands'}
                    maxSelections={maxFocusBrands}
                    onChange={handleSelectionsWithAlias}
                    // This OR condition for brandAggregationLevel has to be applied because we either get 'brand' / 'Focus Brand' from the backend(for any story its not specific to old or new story )
                    onlyRenderIf={
                        includeFocusBrands &&
                        (dataSpecificationValues.runConfig.brandAggregationLevel.toLowerCase() === 'brand' ||
                            dataSpecificationValues.runConfig.brandAggregationLevel.toLowerCase() === 'focus brand')
                    }
                    value={dataSpecificationValues.runConfig.focusBrands}
                    label={
                        storyType === 'BRAND_REVIEW' ? (
                            <>
                             Select Your 
                             <ByzzerInlineSelect
                                name={'brandAggregationLevel'}
                                value={dataSpecificationValues.runConfig.brandAggregationLevel.toLowerCase()}
                                options={BRAND_AGGREGATION_LEVELS}
                                onChange={handleChange}
                             />                           
                             <SelectorLabelInfo                                 
                                selectorCode = {'focusBrands'} 
                                required={requireFocusBrands}
                                includeSuffix={true}
                                showLabel={false}
                                sku={dataSpecificationValues.sku}                                                                                    
                             />                             
                          </>
                           
                        ) : (
                             <SelectorLabelInfo                                    
                                    selectorCode = {'focusBrands'}   
                                    max={maxFocusBrands}
                                    min={minFocusBrands}
                                    required={requireFocusBrands}
                                    includeSuffix={true} 
                                    sku={dataSpecificationValues.sku} 
                                />
                           )
                    }
                />
                <StoryManufacturerSearch
                    name={'manufacturers'}
                    maxSelections={maxManufacturers}
                    onChange={handleSelectionsWithAlias}
                    onlyRenderIf={
                        includeManufactures &&
                        dataSpecificationValues.runConfig.brandAggregationLevel?.toLowerCase() === 'manufacturer'
                    }
                    value={dataSpecificationValues.runConfig.manufacturers}
                    label={
                        storyType === 'BRAND_REVIEW' ? (
                            <> 
                                Select Your 
                                <ByzzerInlineSelect
                                        name={'brandAggregationLevel'}
                                        value={dataSpecificationValues.runConfig.brandAggregationLevel.toLowerCase()}
                                        options={BRAND_AGGREGATION_LEVELS}
                                        onChange={handleChange}                                        
                                  />                       
                                <SelectorLabelInfo 
                                        selectorCode = {'manufacturers'}                                         
                                        includeSuffix={true}
                                        sku={dataSpecificationValues.sku}
                                        required={requireManufactures}
                                        showLabel={false}
                                    />
                                
                            </>                           
                        ) : (                         
                               <SelectorLabelInfo 
                                    selectorCode = {'manufacturers'}
                                    includeSuffix={true}
                                    max={maxFocusBrands}
                                    min={minFocusBrands}
                                    sku={dataSpecificationValues.sku}
                                    required={requireFocusBrands} 
                                    
                                />                           
                            )
                        }
                />
                <ByzzerCategorySelect
                    name={'categories'}
                    maxSelections={maxCategories}
                    onChange={handleSelectionsWithAlias}
                    onlyRenderIf={includeCategories}
                    placeholder={'Select from the list'}
                    value={dataSpecificationValues.runConfig.categories}
                    categoriesToCheckForIntersect={categoryToCheckForIntersect}
                    groupOptionsByBrandCoverage={categoryToCheckForIntersect?.length > 0}
                    disabled={dataSpecificationValues.runConfig.categorySelectionAggregationLevel === 'total_store'}
                    shouldDisplayIntersectIndicators={requireFocusBrands || categoryToCheckForIntersect?.length > 0}
                    allowClear={dataSpecificationValues.runConfig.categorySelectionAggregationLevel !== 'total_store'}
                    categorySelectionAggregationLevel={
                        dataSpecificationValues.runConfig.categorySelectionAggregationLevel
                    }
                    label={
                        <> 
                           Select Your
                           <ByzzerInlineSelect
                                options={CATEGORY_AGGREGATION_LEVELS}
                                name={'categorySelectionAggregationLevel'}
                                onChange={handleChange}
                                value={dataSpecificationValues.runConfig.categorySelectionAggregationLevel}
                            />                             
                           <SelectorLabelInfo                                
                                includeSuffix={true}  
                                required = { requireCategories }                              
                                selectorCode= {'categories'} 
                                sku={dataSpecificationValues.sku} 
                                showLabel={false}                                                       
                                                                        
                            />                                                
                        </>
                        
                    }
                />
                <SubCategorySelect
                    disabled={false}
                    placeholder={'Select from the list'}
                    name={'subcategories'}
                    onChange={handleChange}
                    onlyRenderIf={includeProductSubcategory}
                    value={dataSpecificationValues.runConfig.subcategories}
                    categories={dataSpecificationValues.runConfig.categories}
                    label={                        
                        <SelectorLabelInfo 
                           selectorCode= {'productSubCategory'} 
                           includeSuffix={true}
                           sku={dataSpecificationValues.sku}
                           required={requireProductSubcategory} 
                         />                       
                                              
                      }
                />
                <ByzzerBrandSearch
                    name={'brands'}
                    maxSelections={maxBrands}
                    onChange={handleChange}
                    onlyRenderIf={includeBrands}
                    value={dataSpecificationValues.runConfig.brands}
                    categories={dataSpecificationValues.runConfig.categories}
                    label={
                        <SelectorLabelInfo 
                          selectorCode= {'brands'}
                          max={maxBrands}                          
                          includeSuffix={true}
                          sku={dataSpecificationValues.sku}
                          required={requireFocusBrands} 
                        />
                    }
                />
            </FilterSection>
            <FilterSection onlyRenderIf={includeCharacteristicDimensions}>
             
                <StoryCharacteristicsDimensionSelect
                    name={'characteristicsDimension'}
                    onChange={handleChange}
                    maxSelections={maxCharacteristicDimensions}
                    categories={dataSpecificationValues.runConfig.categories}
                    value={dataSpecificationValues.runConfig.characteristicsDimension}
                    requireCharacteristicDimensions={requireCharacteristicDimensions}
                    sku={dataSpecificationValues.sku}
                />
            </FilterSection>
            <FilterSection onlyRenderIf={includePPGs}>
                <ByzzerPPGSelect
                    name={'ppgData'}
                    onChange={updatePPGID}
                    value={dataSpecificationValues.runConfig.ppgData?.value}
                    categories={dataSpecificationValues.runConfig.categories}
                    disabled={!dataSpecificationValues.runConfig.categories?.length}
                    sku={dataSpecificationValues.sku}
                    required={requirePPGSs}
                    maxPPG={maxPPGs}
                />
            </FilterSection>
            <FilterSection onlyRenderIf={includeCharacteristics}>               
                
                <SelectorLabelInfo 
                        selectorCode = {'characteristicFilters'}  
                        required={requireCharacteristics}
                        includeSuffix={true}
                        sku={dataSpecificationValues.sku}
                        isLabelBold={true}
                      
                />               
                
                <CharacteristicCriteriaBuilder
                    name={'characteristics'}
                    maxConditions={maxCharacteristics}
                    onChange={handleCharacteristicsChange}
                    categories={dataSpecificationValues.runConfig.categories}
                    value={dataSpecificationValues.runConfig.characteristics}
                    excludeCharacteristicIsNot={excludeCharacteristicIsNot}
                    includeUpcOption={true} // should eventually be set in Alby
                />
            </FilterSection>
            <FilterSection onlyRenderIf={includeKeyCharacteristics}>               
                <SelectorLabelInfo 
                    selectorCode = {'keyCharecteristics'} 
                    required={requireKeyCharacteristics} 
                    includeSuffix={true}
                    max={maxKeyCharacteristics}
                    sku={dataSpecificationValues.sku} 
                    isLabelBold={true}
                />                
                
                <CharacteristicCriteriaBuilder
                    joinText={'or'}
                    name={'characteristics'}
                    maxConditions={maxKeyCharacteristics}
                    onChange={handleCharacteristicsChange}
                    value={dataSpecificationValues.runConfig.characteristics}
                    categories={dataSpecificationValues.runConfig.categories}
                    excludeCharacteristicIsNot={excludeCharacteristicIsNot}
                />
            </FilterSection>
            {includeCategoryAlias && (
                <>                    
                    <div className={`product_selector__submit-btn-container__alias-container`}>
                        <ByzzerInput
                            label={
                               <SelectorLabelInfo 
                                        selectorCode = {'categoryAlias'}
                                        includeSuffix={true}
                                        required={requireCategoryAlias}  
                                        sku={dataSpecificationValues.sku}                                       
                                    />                               
                                }
                            locked={undefined}
                            onBlur={undefined}
                            button={undefined}
                            sublabel
                            disabled={undefined}
                            onIconClick
                            onButtonClick
                            style={{ width: '20em' }}
                            placeholder={'Category Name'}
                            onChange={handleAliasChange}
                            className={`product_selector__alias-input`}
                            name={'categoryAlias'}
                            value={dataSpecificationValues.runConfig.categoryAlias}
                        />
                    </div>
                </>
            )}
            {includeKeyCharacteristicsAlias && (
                <>                    
                    <div className={`product_selector__submit-btn-container__alias-container`}>
                        <ByzzerInput
                            label={                             
                                <SelectorLabelInfo 
                                    selectorCode = {'keyCharecteristicsAlias'}
                                    includeSuffix={true}
                                    required={requireKeyCharacteristicsAlias}
                                    sku={dataSpecificationValues.sku} 
                                /> 
                            }
                            button={undefined}
                            onBlur={undefined}
                            locked={undefined}
                            required
                            sublabel
                            disabled={undefined}
                            onIconClick
                            onButtonClick
                            style={{ width: '20em' }}
                            placeholder={'Key Characteristic Name'}
                            onChange={handleAliasChange}
                            className={`product_selector__alias-input`}
                            name={'keyCharacteristicsAlias'}
                            value={dataSpecificationValues.runConfig.keyCharacteristicsAlias}
                        />
                    </div>
                </>
            )}
            {includeProductAlias && (
                <>
                    <div className={`product_selector__submit-btn-container__alias-container`}>
                        <ByzzerInput
                            label={                               
                                <SelectorLabelInfo 
                                    selectorCode = {'productAlias'}
                                    includeSuffix={requireProductAlias}
                                    sku={dataSpecificationValues.sku} 
                                    required={requireProductAlias}
                                />
                            }
                            button={undefined}
                            onBlur={undefined}
                            locked={undefined}
                            required
                            sublabel
                            disabled={undefined}
                            onIconClick
                            onButtonClick
                            style={{ width: '20em' }}
                            placeholder={'Product Name'}
                            onChange={handleAliasChange}
                            className={`product_selector__alias-input`}
                            name={'productAlias'}
                            value={dataSpecificationValues.runConfig.productAlias}
                        />
                    </div>
                </>
            )}
            {includeBrandAlias &&
                (dataSpecificationValues.runConfig.brandAggregationLevel.toLowerCase() === 'brand' ||
                    dataSpecificationValues.runConfig.brandAggregationLevel.toLowerCase() === 'focus brand') && (
                    <>
                        <div className={`product_selector__submit-btn-container__alias-container`}>
                            <ByzzerInput
                                label={
                                    <SelectorLabelInfo 
                                            selectorCode = {'brandAlias'} 
                                            includeSuffix={requireBrandAlias}
                                            sku={dataSpecificationValues.sku} 
                                            required={requireBrandAlias}
                                    />                                 
                                    
                                }
                                button={undefined}
                                onBlur={undefined}
                                locked={undefined}
                                required
                                sublabel
                                disabled={undefined}
                                onIconClick
                                onButtonClick
                                style={{ width: '20em' }}
                                placeholder={'Brand Name'}
                                onChange={handleAliasChange}
                                className={`product_selector__alias-input`}
                                name={'brandAlias'}
                                value={dataSpecificationValues.runConfig.brandAlias}
                            />
                        </div>
                    </>
                )}
            {includeManufacturerAlias &&
                dataSpecificationValues.runConfig.brandAggregationLevel.toLowerCase() === 'manufacturer' && (
                    <>
                       <div className={`product_selector__submit-btn-container__alias-container`}>
                            <ByzzerInput
                                label={
                                        <SelectorLabelInfo 
                                            selectorCode = {'manufacturerAlias'}
                                            includeSuffix={requireBrandAlias}
                                            sku={dataSpecificationValues.sku} 
                                            required={requireBrandAlias}
                                        />
                                                                    
                                }
                                button={undefined}
                                onBlur={undefined}
                                locked={undefined}
                                required
                                sublabel
                                disabled={undefined}
                                onIconClick
                                onButtonClick
                                style={{ width: '20em' }}
                                placeholder={'Manufacturer Name'}
                                onChange={handleAliasChange}
                                className={`product_selector__alias-input`}
                                name={'manufacturerAlias'}
                                value={dataSpecificationValues.runConfig.manufacturerAlias}
                            />
                        </div>
                    </>
                )}
        </FilterGroup>
    );
};
export default StoryProductSelector;
