export const growthThresholdOptions = [
    { display: "5%", value: ">0.05"},
    { display: "10%", value: ">0.10"},
    { display: "15%", value: ">0.15"},
    { display: "20%", value: ">0.20"},

    { display: "25%", value: ">0.25"},
    { display: "50%", value: ">0.50"},
    { display: "100%", value: ">1"},
    { display: "200%", value: ">2"},
]

// export const salesThresholdOptions = ['$5M - $50M', '$50M - $150M', '$150M - $300M'];
