import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './UserDefaultSelections.scss';
import { useUser } from "@/contexts/UserContext";
import { ByzzerChip } from "@/components/ByzzerChip";
import { nanoid } from "nanoid";
import { ByzzerDate } from "@byzzer/ui-components";
import { CustomTimePeriod, RunConfigMarket, TimePeriod, WeekEndingTimePeriod } from "@/types/ReportRun";
import { isMarketDisabled, useMarketService } from "@/services/market.service";
import { MarketPickerContext } from "@/components/MarketPicker";
import { focusKPIOptions } from "@/components/FocusKPISelector/focusKPI.constants";

export type UserDefaultSelectionsProps = {
    onEditModeClick: () => void;
}

type DefaultSelectionType = {
    type: ReactNode;
    selections: ReactNode;
    baseClassName?: string;
}

function isWeekEndingTimePeriod(obj: any): obj is WeekEndingTimePeriod {
  return  typeof obj?.period?.period?.weeks === 'number' &&
           obj?.period?.type==='week_ending'
}
const renderTimePeriodChip = (tp?: TimePeriod) => { // TODO: See if this is standardized somewhere.  I thought it was.. DRY
    let returnVal;
    if (!tp ) return '';
    if(isWeekEndingTimePeriod(tp)){
        tp.type='week_ending'
    }
    if (['week_ending', 'omni_custom'].includes(tp.type) && !(tp as CustomTimePeriod).period?.endDate) {
        return 'N/a';
    }

    if (tp.type === 'week_ending') {
        returnVal = 
        <>
            {`${tp.period?.weeks ?? 0} week${tp.period?.weeks! > 1 ? 's' : ''} ending `}
            <ByzzerDate date={tp.period?.endDate!} format={'yyyy-MM-dd'} timeZone={'America/New_York'} />
        </> 
    }else if( tp.type === 'omni_custom' ){               
        returnVal = 
        <>
            {`${tp.period?.months ?? 0} month${tp.period?.months! > 1 ? 's' : ''} ending `}
            <ByzzerDate date={tp.period?.endDate!} format={'yyyy-MM-dd'} timeZone={'America/New_York'} />
        </>
    }else {
        returnVal = tp?.period;
    }
    return <ByzzerChip>{returnVal}</ByzzerChip>;
}

function DefaultSelection({type, selections, baseClassName}: DefaultSelectionType) {
    return (
        <div className={classnames(`${baseClassName}__group`)}>
            <div className={classnames(`${baseClassName}__type`)}>
                <span>{type}</span>
            </div>
            <div className={classnames(`${baseClassName}__selections`)}>
                {selections}
            </div>
        </div>
    )
}

export const UserDefaultSelections = ({
    onEditModeClick,
    ...props
}: UserDefaultSelectionsProps) => {

    const {
        defaultRunConfig,
        features: { enableLimitedMarketSubscription },
        accessibleMasterCompanies,
        company,
    } = useUser();
    const { getCachedMarketNodeByName } = useMarketService();
    const { requiredMasterCompany, requiredMarketGroup, requireRemainingMarket } = useContext(MarketPickerContext);
    const {
        brands = [],
        timePeriod,
        markets = [],
        categories = [],
        characteristics = [],
        focusKPI
    } = defaultRunConfig!;
    const [enabledMarkets, setEnabledMarkets] = useState(markets);

    const baseClassName = 'byz-default-selections-section';

    useEffect(() => {
        if (markets.length) {
            handleEnabledMarkets();
        }
    }, [markets]);

    function handleEnabledMarkets() {
        const modifiedDefaultMarkets = markets?.reduce((acc: RunConfigMarket[], item) => {
            const disabledMarketNode = isMarketDisabled(getCachedMarketNodeByName(item.name, categories)!, {
                requiredMarketGroup,
                requiredMasterCompany,
                requireRemainingMarket,
                enableLimitedMarketSubscription,
                accessibleMasterCompanies,
                purchasedMarketKeys: company?.purchasedMarketKeys,
            });
            if (!disabledMarketNode) {
                acc.push({ ...item as RunConfigMarket });
            }
            return acc;
        }, []);
        setEnabledMarkets(modifiedDefaultMarkets);
    }

    function handleEditModeClick() {
        onEditModeClick();
    }

    const actions = useMemo(() => (
        <div className={classnames(`${baseClassName}__actions`)}>
            <div className={`${baseClassName}__edit-container`} onClick={handleEditModeClick}>
                <span className={classnames(`${baseClassName}__edit-btn`)} />
                <span className={classnames(`${baseClassName}__edit-text`)}>Edit</span>
            </div>
        </div>
    ), [])

    const DefaultSelections: DefaultSelectionType[] = useMemo(() => [
        {
            type: 'Focus Brand(s)',
            selections: Boolean(brands?.length) ? (
                <>{brands?.map(brand => <ByzzerChip key={brand} label={brand}/>)}</>
            ) : (
                <>(None)</>
            )
        },
        {
            type: 'Categories',
            selections: Boolean(categories?.length) ? (
                <>{categories?.map(category => <ByzzerChip key={category} label={category}/>)}</>
            ) : (
                <>(None)</>
            )
        },
        {
            type: 'Characteristics',
            selections: Boolean(characteristics?.length) ? (
                <>{characteristics?.map(characteristic => (
                    <ByzzerChip key={nanoid()}>
                        {characteristic?.characteristicDisplayValue} {characteristic?.condition} {characteristic?.value?.join(', ')}
                    </ByzzerChip>
                ))}</>
            ) : (
                <>(None)</>
            )
        },
        {
            type: 'Market',
            selections: Boolean(enabledMarkets?.length) ? (
                <>{enabledMarkets?.map(market => <ByzzerChip key={market.name} label={market.name}/>)}</>
            ) : (
                <>(None)</>
            )
        },
        {
            type: 'Time Period',
            selections: Boolean(timePeriod?.period) ? (
                <>{renderTimePeriodChip(timePeriod) ?? <>(None)</>}</>
            ) : (
                <>(None)</>
            )
        },
        {
            type: 'Focus KPI',
            selections: Boolean(focusKPI?.length) ? (
                <><ByzzerChip key={nanoid()} label={focusKPI} /></>
            ) : (
                <>(None)</> 
            )
        }
    ], [defaultRunConfig, enabledMarkets]);

    return (
        <div className={classnames(`${baseClassName}`)}>
            <header className={classnames(`${baseClassName}__header`)}>
                <span className={classnames(`${baseClassName}__name`)}>
                    Default Selections
                </span>
                {actions}
            </header>
            {/* <section className={classnames(`${baseClassName}__description`)}></section> For future use */}
            <div className={classnames(`${baseClassName}__groups`)}>
                {DefaultSelections.map(({type, selections}) => (
                    <DefaultSelection key={nanoid()} type={type} selections={selections} baseClassName={baseClassName}/>
                ))}
            </div>
        </div>
    );

};

export default UserDefaultSelections;

UserDefaultSelections.displayName = 'UserDefaultSelections';
