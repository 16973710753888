//TODO : To be deleted on the next sprint(1.64)
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import StoryCircularProgressLoader from './StoryCircularProgressLoader';
import './StoryCircularProgressLoader.scss';

//Constants
const INITIAL_COUNT_VALUE = 3;
const MAX_COUNT_VALUE = 96;
const TIME_INTERVAL = 3000;

type ShowProgressInputs = {
    inProgress: boolean;
    className?: string;
};

function StoryValidationProgress({ inProgress = false, className }: ShowProgressInputs) {
    const [count, setCount] = useState(0);

    useEffect(() => {
        //set default count for new run
        setCount(INITIAL_COUNT_VALUE);

        // calculate the progress percentage based on time
        if (inProgress) {
            let progress = INITIAL_COUNT_VALUE;
            let incrementValue;
            const interval = setInterval(() => {
                if (progress <= MAX_COUNT_VALUE) {
                    incrementValue = Math.round(Math.random() * 10);
                    if (progress + incrementValue > MAX_COUNT_VALUE) {
                        incrementValue = MAX_COUNT_VALUE - progress;
                    }

                    progress += incrementValue;
                    setCount((oldCount) => oldCount + incrementValue);
                } else {
                    return clearInterval(interval);
                }
            }, TIME_INTERVAL);

            return () => {
                clearInterval(interval);
            };
        }
    }, [inProgress]);

    if (inProgress) {
        const rootClasses = classNames('circular-loader', className);

        return (
            <div className={rootClasses}>
                <StoryCircularProgressLoader counter={count} />
                <div className={'circular-loader__message'}>Please wait while we validate your data.</div>
            </div>
        );
    }
    return <></>;
}

export default StoryValidationProgress;
