import { useEffect, useState } from 'react';
import { intersection, uniq } from 'lodash';
import { ByzzerSelectOption } from '@byzzer/ui-components';
import { filterHasExplicitValues, filterValuesToStrings } from '@/components/DodConfigEditor/common/utils';
import { useUser } from '@/contexts/UserContext';
import { DodFilter, DodFilters } from '@/types/DodRun';
import { ReportAggregationLevel } from '@/types/ReportRun';

export const CATEGORY_COVERED_BY_BRAND_TEXT = 'Covered by brand selection(s)';
const CATEGORY_NOT_COVERED_TEXT = 'Not covered by brand selection(s)';

export type CategoryInfoTree = {
    // TODO: Move this to server and retrieve via API
    categoriesInfo: {
        category: string;
        aggregationLevels: {
            department: string;
            supercategory: string;
        };
    }[];
    departmentList: string[];
    departmentCount?: number;
    supercategoryList: string[];
    supercategoryCount?: number;
    departmentInfo?: {
        name: string;
        categories: string[];
    }[];
    supercategoryInfo?: {
        name: string;
        categories: string[];
    }[];
};

export type DepartmentInfo = {
    superCategories: string[];
};
export type SuperCategoryInfo = {
    department: string;
    categories: string[];
};
export type CategoryInfo = {
    key: string;
    department: string;
    superCategory: string;
    subcategories: string[];
};
type DepartsIndex = Record<string, DepartmentInfo>;
type SuperCategoriesIndex = Record<string, SuperCategoryInfo>;
type CategoriesIndex = Record<string, CategoryInfo>;

export function useCategoryService() {
    const { hierarchyTree, categories: userCategories } = useUser();
    const [categoriesIndex, setCategoriesIndex] = useState<CategoriesIndex>(indexCategories(hierarchyTree));
    const [departmentsIndex, setDepartmentsIndex] = useState<DepartsIndex>(indexDepartments(hierarchyTree));
    const [superCategoriesIndex, setSuperCategoriesIndex] = useState<SuperCategoriesIndex>(
        indexSuperCategories(hierarchyTree)
    );

    useEffect(() => {
        setCategoriesIndex(indexCategories(hierarchyTree));
    }, [hierarchyTree]);

    function getCategoryByKey(key: string): string {
        let categoryName: string = '';
        Object.entries(categoriesIndex).forEach((category) => {
            const [name, value] = category;
            if ( Number(value.key) === Number(key)) {
                categoryName = name;
            }
        });
        return categoryName;
    }

    function indexCategories(tree: Record<string, any>): CategoriesIndex {
        return Object.entries(tree).reduce((index, [department, superCategories]) => {
            return Object.entries(superCategories as any).reduce((index, [superCategory, categories]) => {
                return Object.entries<any>(categories as any).reduce((index, [category, { key, subcategories }]) => {
                    index[category] = {
                        key,
                        department,
                        superCategory,
                        subcategories,
                    };

                    return index;
                }, index);
            }, index);
        }, {});
    }

    function indexSuperCategories(tree: Record<string, any>): SuperCategoriesIndex {
        return Object.entries(tree).reduce((index, [department, superCategories]) => {
            return Object.entries(superCategories).reduce((index, [superCategory, categories]) => {
                index[superCategory] = {
                    department,
                    categories: Object.keys(categories as any),
                };

                return index;
            }, index);
        }, {});
    }

    function indexDepartments(tree: Record<string, any>): DepartsIndex {
        return Object.entries(tree).reduce((index, [department, superCategories]) => {
            index[department] = {
                superCategories: Object.keys(superCategories),
            };

            return index;
        }, {});
    }

    function createExtendedCategoryInfoForSubscribedCategories(
        productTree: any,
        purchasedCategories?: string[]
    ): CategoryInfoTree {
        // TODO - move to server; new API to get product tree limited to subscribed categories
        let categoriesInfo: any = [];
        let departmentList: any[] = [];
        let supercategoryList: any[] = [];
        let unlicensedCategoriesInfo: any[] = [];

        if (!productTree || !purchasedCategories) {
            // todo - replace this with somename better. for now it prevent black screen on new accounts when signing in
            return {
                categoriesInfo,
                departmentList,
                supercategoryList,
            };
        }

        Object.keys(productTree)?.forEach((department) => {
            Object.keys(productTree[department])?.forEach((supercategory) => {
                Object.keys(productTree[department][supercategory])?.forEach((category) => {
                    if (purchasedCategories?.includes(category)) {
                        if (!departmentList?.includes(department)) departmentList?.push(department);
                        if (!supercategoryList?.includes(supercategory)) supercategoryList?.push(supercategory);
                        categoriesInfo?.push({
                            category,
                            aggregationLevels: {
                                department,
                                supercategory,
                            },
                        });
                    } else {
                        unlicensedCategoriesInfo?.push({
                            category,
                            aggregationLevels: {
                                department,
                                supercategory,
                            },
                        });
                    }
                });
            });
        });

        return {
            categoriesInfo,
            departmentList,
            departmentCount: departmentList.length,
            supercategoryList,
            supercategoryCount: supercategoryList.length,
            departmentInfo: departmentList
                ?.map((department) => ({
                    name: department,
                    categories: categoriesInfo
                        ?.filter((categoryInfo) => categoryInfo?.aggregationLevels?.department === department)
                        ?.map(returnCategoryName)
                        ?.sort(),
                    unlicensedCategoriesCount: unlicensedCategoriesInfo?.filter(
                        (categoryInfo) => categoryInfo?.aggregationLevels?.department === department
                    )?.length,
                }))
                .sort(sortAggregationInfos),
            supercategoryInfo: supercategoryList
                ?.map((supercategory) => ({
                    name: supercategory,
                    categories: categoriesInfo
                        ?.filter((categoryInfo) => categoryInfo?.aggregationLevels?.supercategory === supercategory)
                        ?.map(returnCategoryName)
                        ?.sort(),
                    unlicensedCategoriesCount: unlicensedCategoriesInfo?.filter(
                        (categoryInfo) => categoryInfo?.aggregationLevels?.supercategory === supercategory
                    )?.length,
                }))
                .sort(sortAggregationInfos),
        };
    }

    function sortAggregationInfos(a, b) {
        return a.name > b.name ? 1 : -1;
    }

    function returnCategoryName(categoryInfo) {
        return categoryInfo?.category;
    }

    function createExtendedCategoryInfoForAllCategories(productTree: any): CategoryInfoTree {
        let categoriesInfo: any = [];
        let departmentList: any[] = [];
        let supercategoryList: any[] = [];
        let unlicensedCategoriesInfo: any[] = [];

        Object.keys(productTree)?.forEach((department) => {
            Object.keys(productTree[department])?.forEach((supercategory) => {
                Object.keys(productTree[department][supercategory])?.forEach((category) => {
                    if (!departmentList?.includes(department)) departmentList?.push(department);
                    if (!supercategoryList?.includes(supercategory)) supercategoryList?.push(supercategory);
                    categoriesInfo?.push({
                        category,
                        aggregationLevels: {
                            department,
                            supercategory,
                        },
                    });
                });
            });
        });

        return {
            categoriesInfo,
            departmentList,
            departmentCount: departmentList.length,
            supercategoryList,
            supercategoryCount: supercategoryList.length,
            departmentInfo: departmentList
                ?.map((department) => ({
                    name: department,
                    categories: categoriesInfo
                        ?.filter((categoryInfo) => categoryInfo?.aggregationLevels?.department === department)
                        ?.map(returnCategoryName)
                        ?.sort(),
                    unlicensedCategoriesCount: unlicensedCategoriesInfo?.filter(
                        (categoryInfo) => categoryInfo?.aggregationLevels?.department === department
                    )?.length,
                }))
                .sort(sortAggregationInfos),
            supercategoryInfo: supercategoryList
                ?.map((supercategory) => ({
                    name: supercategory,
                    categories: categoriesInfo
                        ?.filter((categoryInfo) => categoryInfo?.aggregationLevels?.supercategory === supercategory)
                        ?.map(returnCategoryName)
                        ?.sort(),
                    unlicensedCategoriesCount: unlicensedCategoriesInfo?.filter(
                        (categoryInfo) => categoryInfo?.aggregationLevels?.supercategory === supercategory
                    )?.length,
                }))
                .sort(sortAggregationInfos),
        };
    }

    function createCategoryDisplayOptions(
        categoriesList: string[],
        intersectionList: string[],
        groupOptionsByBrandCoverage?: boolean
    ) {
        if (groupOptionsByBrandCoverage === true) {
            const coveredCategories = categoriesList?.filter((category) => intersectionList?.includes(category));
            const uncoveredCategories = categoriesList?.filter((category) => !intersectionList?.includes(category));

            const groupedCategoryOptionsByOverlap = [
                {
                    label: CATEGORY_COVERED_BY_BRAND_TEXT,
                    options: coveredCategories
                        ?.map((category) => ({
                            display: category,
                            value: category,
                            data: {
                                categories: [category],
                                categoryMatch: [category], // needs to be array to work
                                aggregationlevel: 'category',
                            },
                        }))
                        ?.sort(sortByDisplayName),
                },
                {
                    label: CATEGORY_NOT_COVERED_TEXT,
                    options: uncoveredCategories
                        ?.map((category) => ({
                            display: category,
                            value: category,
                            data: {
                                categories: [category],
                                categoryMatch: [], // uncoveredCategories only contains values with no coverage.
                                aggregationlevel: 'category',
                            },
                        }))
                        ?.sort(sortByDisplayName),
                },
            ];
            return groupedCategoryOptionsByOverlap;
        } else {
            return categoriesList
                ?.map((category) => ({
                    display: category,
                    value: category,
                    data: {
                        categories: [category],
                        categoryMatch: [intersectionList?.includes(category)]?.filter(Boolean),
                        aggregationlevel: 'category',
                    },
                }))
                ?.sort(sortByDisplayName);
        }
    }

    function createAggregationDisplayOptions(
        extendedCategoryInfo,
        intersectionList,
        groupOptionsByBrandCoverage?: boolean,
        aggregationlevel?: ReportAggregationLevel
    ) {
        const aggLevel = aggregationlevel === 'super_category' ? 'supercategory' : 'department'; // if not sc, should only ever be 'department'. total store doesnt use this function
        const allAggGroupsWithCategories = extendedCategoryInfo?.[`${aggLevel}Info`];

        if (groupOptionsByBrandCoverage === true) {
            const groupsWithCoveredCategories = allAggGroupsWithCategories?.filter((aggGroupInfo) => {
                return intersectionList?.filter((category) => aggGroupInfo?.categories?.includes(category)).length;
            });

            const groupsWithoutCoveredCategories = allAggGroupsWithCategories?.filter((aggGroupInfo) => {
                return (
                    aggGroupInfo?.categories?.filter((category) => !intersectionList?.includes(category)).length ===
                    aggGroupInfo?.categories?.length
                );
            });

            return [
                {
                    label: CATEGORY_COVERED_BY_BRAND_TEXT,
                    options: groupsWithCoveredCategories
                        ?.map((aggGroupInfo) => {
                            return {
                                display: aggGroupInfo.name,
                                value: aggGroupInfo.name,
                                data: {
                                    categories: aggGroupInfo?.categories,
                                    categoryMatch: intersectionList?.filter((category) =>
                                        aggGroupInfo?.categories?.includes(category)
                                    ),
                                    aggregationlevel,
                                    unlicensedCategoriesCount: aggGroupInfo?.unlicensedCategoriesCount,
                                },
                            };
                        })
                        ?.sort(sortByDisplayName),
                },
                {
                    label: CATEGORY_NOT_COVERED_TEXT,
                    options: groupsWithoutCoveredCategories
                        ?.map((aggGroupInfo) => {
                            return {
                                display: aggGroupInfo.name,
                                value: aggGroupInfo.name,
                                data: {
                                    categories: aggGroupInfo?.categories,
                                    categoryMatch: [], // groupsWithoutCoveredCategories only contains values with no matching categories.
                                    aggregationlevel,
                                },
                            };
                        })
                        ?.sort(sortByDisplayName),
                },
            ];
        } else {
            return extendedCategoryInfo?.[`${aggLevel}List`]
                ?.map((group) => {
                    let categoryMatch: any = [];
                    return {
                        display: group,
                        value: group,
                        data: {
                            categories: extendedCategoryInfo?.categoriesInfo
                                ?.map((categoryInfo) => {
                                    // todo: slim this down
                                    if (categoryInfo?.aggregationLevels?.[`${aggLevel}`] === group) {
                                        if (intersectionList?.includes(categoryInfo?.category)) {
                                            categoryMatch?.push(categoryInfo?.category);
                                        }
                                        return categoryInfo?.category;
                                    }
                                })
                                .filter(Boolean)
                                ?.sort(),
                            categoryMatch: categoryMatch?.filter(Boolean),
                            aggregationlevel,
                        },
                    };
                })
                ?.sort(sortByDisplayName);
        }
    }

    function createGroupingsFromCategoryValues(grouping, value) {
        const newGroupingsFromCategoryValues = grouping?.filter((group) => {
            let found = true;
            group?.categories?.forEach((category) => (value?.includes(category) ? (found = true) : (found = false)));
            return found;
        });
        return newGroupingsFromCategoryValues;
    }

    function createNewGroupValue(
        groupValues: any,
        aggregationlevel: ReportAggregationLevel,
        categoriesToCheckForIntersect?: string[]
    ): ByzzerSelectOption[] {
        const newGroupValue = groupValues.map((groupItem) => {
            return {
                display: groupItem.name,
                value: groupItem.name,
                data: {
                    categories: groupItem?.categories,
                    categoryMatch: categoriesToCheckForIntersect
                        ?.filter((category) => groupItem?.categories?.includes(category))
                        ?.filter(Boolean),
                    aggregationlevel,
                },
            };
        });
        return newGroupValue;
    }

    function createListOfAllCategories(fullProductHierarchyInfo): string[] {
        return fullProductHierarchyInfo?.categoriesInfo?.map(returnCategoryName);
    }

    function sortByDisplayName(a, b) {
        return a?.display?.localeCompare(b?.display, 'en', { sensitivity: 'base' });
    }

    function sortByMatch(a, b) {
        return Number(Boolean(b?.data?.categoryMatch?.length)) - Number(Boolean(a?.data?.categoryMatch?.length));
    }

    function getAllCategories(): string[] {
        return Object.keys(categoriesIndex);
    }

    function getAllDepartments(): string[] {
        return Object.keys(categoriesIndex);
    }

    function getAllSubCategories(): string[] {
        return getSubCategoriesForCategories(getAllCategories());
    }

    function getAllSuperCategories(): string[] {
        return Object.keys(categoriesIndex);
    }

    // todo: update this method once categories have consist keys added
    function getDepartsForCategories(categories: string[]): string[] {
        return uniq(categories.map((category) => categoriesIndex[category]?.department).filter(Boolean));
    }

    // todo: update this method once categories have consist keys added
    function getSuperCategoriesForCategories(categories: string[]): string[] {
        return uniq(categories.map((category) => categoriesIndex[category]?.superCategory).filter(Boolean));
    }

    // todo: update this method once categories have consist keys added
    function getSubCategoriesForCategories(categories: string[]): string[] {
        return uniq(
            categories
                .map((category) => categoriesIndex[category]?.subcategories)
                .filter(Boolean)
                .flat()
        );
    }

    function getDepartmentsForSuperCategories(superCategories: string[]): string[] {
        return uniq(superCategories.map((sup) => superCategoriesIndex[sup]?.department).filter(Boolean));
    }

    function getCategoriesForSuperCategories(superCategories: string[]): string[] {
        return uniq(
            superCategories
                .map((sup) => superCategoriesIndex[sup]?.categories)
                .filter(Boolean)
                .flat()
        );
    }

    function getAllCategoriesByDepartmentsAndSuperCategories(
        departments: DodFilter<string[] | 'all'>,
        superCategories: DodFilter<string[] | 'all'>
    ): string[] {
        let allCategories: string[] = [];
        if (filterHasExplicitValues(superCategories)) {
            allCategories = getCategoriesForSuperCategories(
                filterValuesToStrings(superCategories)
            ).filter((category) => userCategories?.includes(category));
        } else if (filterHasExplicitValues(departments)) {
            allCategories = getCategoriesForDepartments(filterValuesToStrings(departments)).filter((category) =>
                userCategories?.includes(category)
            );
        } else {
            allCategories = userCategories;
        }
        return allCategories;
    }

    function getSubCategoriesForSuperCategories(superCategories: string[], filterBySubscribedCategories = false): string[] {
        let categories = getCategoriesForSuperCategories(superCategories)
        if(filterBySubscribedCategories) {
          categories = categories.filter(item => userCategories.includes(item));
        }
        return getSubCategoriesForCategories(categories);
    }

    function getSuperCategoriesForDepartments(departments: string[]): string[] {
        return uniq(
            departments
                .map((department) => departmentsIndex[department]?.superCategories)
                .filter(Boolean)
                .flat()
        );
    }

    function getCategoriesForDepartments(departments: string[]): string[] {
        const superCategories = getSuperCategoriesForDepartments(departments);
        return getCategoriesForSuperCategories(superCategories);
    }

    function getSubCategoriesForDepartments(departments: string[], filterBySubscribedCategories = false): string[] {
        let categories = getCategoriesForDepartments(departments);
        if(filterBySubscribedCategories) {
          categories = categories.filter(item => userCategories.includes(item));
        }
        return getSubCategoriesForCategories(categories);
    }

    function getCategoriesForSubCategories(subcategories: string[]): string[] {
        return Object.entries(categoriesIndex as object)
            .filter(([, info]) => intersection(subcategories, info.subcategories))
            .map(([category]) => category);
    }

    function getDepartmentsForSubCategories(subcategories: string[]): string[] {
        const categories = getCategoriesForSubCategories(subcategories);
        return getDepartsForCategories(categories);
    }

    function getSuperCategoriesForSubCategories(superCategories: string[]): string[] {
        const categories = getCategoriesForSuperCategories(superCategories);
        return getDepartsForCategories(categories);
    }

    function getCategoriesBasedOnFilter(filters: DodFilters, defaultCategories: string[]): string[] {
      // This function returns value of "All" selected categories using super categories and departments
      let categories: string[] = [];

      if (filterHasExplicitValues(filters.categories)) {
          categories = filterValuesToStrings(filters.categories);
      } else if (filterHasExplicitValues(filters.superCategories)) {
          const categoriesFromSuperCategories = getCategoriesForSuperCategories(filterValuesToStrings(filters.superCategories))
          categories = categoriesFromSuperCategories.filter(item => userCategories.includes(item));
      } else if (filterHasExplicitValues(filters.departments)) {
          const categoriesFromDepartments = getCategoriesForDepartments(filterValuesToStrings(filters.departments));
          categories = categoriesFromDepartments.filter(item => userCategories.includes(item));
      } else {
          categories = defaultCategories
      }

      return categories;
    }

    function filterSubscribedCategories(
        categories: string[] | 'all',
        wantAllSubscribedCategories = false
    ): string[] | 'all' {
        if (categories === 'all') {
            return wantAllSubscribedCategories ? userCategories : categories;
        }
        return categories.filter((item) => userCategories.includes(item));
    }

    return {
        getAllDepartments,
        getSuperCategoriesForDepartments,
        getCategoriesForDepartments,
        getSubCategoriesForDepartments,

        getAllSuperCategories,
        getDepartmentsForSuperCategories,
        getCategoriesForSuperCategories,
        getSubCategoriesForSuperCategories,

        getAllCategories,
        getSubCategoriesForCategories,
        getSuperCategoriesForCategories,
        getDepartsForCategories,
        getCategoryByKey,

        getAllSubCategories,
        getDepartmentsForSubCategories,
        getSuperCategoriesForSubCategories,
        getCategoriesForSubCategories,

        sortByDisplayName,
        sortByMatch,
        createNewGroupValue,
        createGroupingsFromCategoryValues,
        createCategoryDisplayOptions,
        createAggregationDisplayOptions,
        createExtendedCategoryInfoForAllCategories,
        createExtendedCategoryInfoForSubscribedCategories,
        createListOfAllCategories,
        getCategoriesBasedOnFilter,
        getAllCategoriesByDepartmentsAndSuperCategories,
        filterSubscribedCategories
    };
}
