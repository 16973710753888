export function caseInsensitiveSort(a, b) {
    return a?.localeCompare(b, 'en', {sensitivity: 'base'})
}

export function caseInsensitiveReverseSort(a, b) {
    return b?.localeCompare(a, 'en', {sensitivity: 'base'})
}

// export function caseInsensitiveDirectionalCompare(direction: 'asc' | 'desc') {
//     const directionalMultiplier
//     return (a, b) => a?.localeCompare(b, 'en', {sensitivity: 'base'}) *
// }

export function datesSort(a, b) {
    var dateA = new Date(a).getTime();
    var dateB = new Date(b).getTime();
    return dateA > dateB ? 1 : -1;
    // return (new Date(b).getTime() - new Date(a).getTime())
}
