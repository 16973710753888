import { useState } from "react";
import './DefaultsEditor.scss';
import { DefaultsConfigWizard } from "./DefaultsConfigWizard";
import { useTenantApi } from "@/hooks";
import { useUser } from "@/contexts/UserContext";
import { ByzzerErrorModal } from "@/components/form";
import { DefaultRunConfig } from "@/types/ApiTypes";
import { triggerToast } from "@/notifications/ByzzerToast";
import { MarketRunConfigOptions, ProductRunConfigOptions, TimeRunPeriodConfigOptions, FocusKpiRunConfigOptions } from "@/types/RunConfigOptions";

export type DefaultsEditorProps = {
    loading?: boolean;
    onCancel?: () => void;
    setLoading: (loading: boolean) => void;
}

export const defaultConfigOptions = [
    {
        type: 'product' as any,
        title: 'Focus Product(s)',
        includeFocusBrands: true,
        includeCategories: true,
        includeCharacteristics: true,
        includeMarkets: true,
        maxCharacteristics: 5,
    } as Partial<ProductRunConfigOptions>,
    {
        type: 'market' as any,
        maxMarkets: 1,
        title: 'Focus Market',
        includeTotalUSMarkets: true,
        includeFmcgRetailers: true,
        includeSpecialityRetailers: true,
        includeGeographyMarkets: true,
    } as Partial<MarketRunConfigOptions>,
    {
        type: 'time_period' as any,
        title: 'Time Period',
    } as Partial<TimeRunPeriodConfigOptions>,
    {
        type: 'focuskpi' as any,
        title: 'Focus KPI',
    } as Partial<FocusKpiRunConfigOptions>
];

export const DefaultsEditor = ({
    onCancel,
    setLoading,
    ...props
}: DefaultsEditorProps) => {

    const baseClassName = 'byz-defaults-editor';

    const [showWarning, setShowWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [contentType, setContentType] = useState('warning');

    const { saveUserDefaultRunConfig, fetchReportlet, resetReportlet } = useTenantApi();
    const { refreshDefaultRunConfig } = useUser();

    async function handleComplete(value: DefaultRunConfig) {
        setLoading(true);
        try {
            const saveResult = await saveUserDefaultRunConfig(value);
            refreshDefaultRunConfig?.(value);
            const reportletDataValues = await fetchReportlet();

            if (reportletDataValues) {
                await resetReportlet(reportletDataValues.id);
            } else {
                setWarningMessage('Your default selections have not configured. Please re-configure.');
            }
            setLoading(false);
            triggerToast({
                content: 'Default selections saved successfully',
            });
            onCancel?.();
        } catch (err) {
            // debugger
            setShowWarning(true);
            setContentType('error');
            setWarningMessage('Unable to save the preferences. Something went wrong.');
        } finally {
            setLoading(false);
        }
    }

    return (
        <> 
            <DefaultsConfigWizard 
                onComplete={handleComplete} 
                onCancel={onCancel} 
                // @ts-ignore 
                defaultConfigOptions={defaultConfigOptions}
            />
            {showWarning && (
                <ByzzerErrorModal
                    showWarning={showWarning}
                    setShowWarning={setShowWarning}
                    headerType={'none'}
                    contentType={contentType}
                    warningMessage={warningMessage}
                    size={'small'}
                />
            )}
        </>
    );

};

export default DefaultsEditor;

DefaultsEditor.displayName = 'DefaultsEditor';