import './DodMarketAdvancedSearch.scss';
import React, { useState } from 'react';
import classnames from 'classnames';
import {
    ByzzerChangeEventHandler,
    ByzzerSearchableConditionInput,
    ByzzerSelect,
    ByzzerSelectOption,
    ByzzerSelectValueTypes,
} from '@byzzer/ui-components';
import { AdvancedSearchFilters } from '../DodMarketFilterBuilder';
import { useMarketService } from '@/services/market.service';


const baseClassName = 'dod-market-advanced-search';

export type DodMarketAdvancedSearchProps = {
    value: AdvancedSearchFilters;
    onChange: ByzzerChangeEventHandler<AdvancedSearchFilters>;
};

type SearchFilterOption = {
    label: string;
    value: string;
    isMultiSelect: boolean;
    options: ByzzerSelectOption[];
};

export function DodMarketAdvancedSearch({ value, onChange }: DodMarketAdvancedSearchProps) {

    const {getMarketTypes, getMarketChannels,getMarketParentCompanies, getChildMarketTypes} = useMarketService()
    const [filterOptions, setFilterOptions] = useState<SearchFilterOption[]>([
        {
            label: 'Market Type',
            value: 'marketType',
            isMultiSelect: false,
            options: getMarketTypes().map(type => ({display: type, value: type}))
        },
        {
            label: 'Market Level',
            value: 'marketLevel',
            isMultiSelect: false,
            options: [
                {
                    display: 'Parent Market',
                    value: 'parent',
                },
                {
                    display: 'Child Market',
                    value: 'child',
                },
            ],
        },
        {
            label: 'National Vs Regional',
            value: 'marketTier',
            isMultiSelect: false,
            options: [
                {
                    display: 'National',
                    value: 'national',
                },
                {
                    display: 'Regional',
                    value: 'regional',
                },
            ],
        },
        {
            label: 'Parent Company',
            value: 'parentCompany',
            isMultiSelect: true,
            options: getMarketParentCompanies().map(parentCompany => ({display: parentCompany, value: parentCompany}))
        },
        {
            label: 'Channel',
            value: 'channel',
            isMultiSelect: false,
            options: getMarketChannels().map(channel => ({display: channel, value: channel}))
        },
        {
            label: 'Child Market Type',
            value: 'childMarketType',
            isMultiSelect: false,
            options: getChildMarketTypes().map(childMarketType => ({display: childMarketType, value: childMarketType}))
        },
    ]);

    const handleChange = (
        e: ByzzerChangeEvent<ByzzerSelectValueTypes | undefined>,
        targetFilter: SearchFilterOption
    ) => {
        if (targetFilter.value === 'parentCompany' && e.data?.length === 0) {
            onChange({
                value: {
                    ...value,
                    [targetFilter.value]: [],
                },
            });
        } else {
            onChange({
                value: {
                    ...value,
                    [targetFilter.value]: e.data ?? {},
                },
            });
        }
    };

    return (
        <div className={`${baseClassName}`}>
            {filterOptions.map((option, key) => (
                <li key={`${option.label}-${key}`} className={`${baseClassName}__list`}>
                    <label className={`${baseClassName}__list--label`}>{option.label}</label>
                    <ByzzerSelect
                        options={option.options}
                        placeholder="Select from the list"
                        allowMultiple={option.isMultiSelect}
                        value={value[option.value]}
                        onChange={(e) => handleChange(e, option)}
                    />
                </li>
            ))}
        </div>
    );
}

export default DodMarketAdvancedSearch;
