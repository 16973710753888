import './PPGReportViewer.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { openErrorModal } from '@/components/form/ByzzerModal';
import {useTenantApi} from '@/hooks/useTenantApi';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { useUser } from '@/contexts/UserContext';

export function PPGReportViewer() {
    const { getPPGReportLinkById } = useTenantApi();
    const baseClassName = 'ppg-report-viewer';
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [embedUrl, setEmbedUrl] = useState<string | undefined>('');
    const { ppgId } = useParams();
    const { ppgs } = useUser();

    useEffect(() => {
        if (canViewPPGReport()) {
            getPPGReport(ppgId);
        } else {
            redirectToDashboard();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ppgId]);

    const canViewPPGReport = () => {
        return ppgs.filter(item => Number(item.id) === Number(ppgId))?.length > 0
    }

    const redirectToDashboard = () => {
        navigate('/dashboard', { replace: true });
    }

    const getPPGReport = async (ppgId: string | undefined) => {
        try {
            setLoading(true);
            setEmbedUrl('');
            const url = await getPPGReportLinkById(ppgId);
            //Sanity check.  Success message but empty url
            if (!url?.length) {
                await openErrorModal({
                    title: `Report Error`,
                    content: (
                        <>
                            <p>We were unable to generate the product list at this time.</p>
                            <p>Please try again later or contact customer support.</p>
                        </>
                    ),
                });
                navigate(-1);
                return;
            }
            setEmbedUrl(url);

            // Adding delay to reset loader to handle the gap of looker embed iframe render
            setTimeout(() => setLoading(false), 2500);
        } catch (err) {
            // @ts-ignore
            alert(err?.message);
            navigate(-1);
            setLoading(false);
        }
    };

    return (
        <div className={`${baseClassName}__page-container`}>
            <ByzzerMask show={loading} loading={loading} />
            {embedUrl && (
                <iframe
                    src={embedUrl}
                    className={`${baseClassName}__looker-report`}
                    title="Product View Embed"
                ></iframe>
            )}
        </div>
    );
}

export default PPGReportViewer;