export const sampleQuote = {
    id: 729000,
    nsCompanyId: 1168447,
    transactionId: 'QT1049',
    quoteDetails: {
        taxAmount: 0,
        status: 'Proposal',
        expiryDate: '9/18/2023',
        items: [
            {
                id: 729000,
                company_id: 1168447,
                quote_number: 'QT1049',
                expiry_date: '9/18/2023',
                sku: '248.A',
                transaction_number: 'ESTIMATE1049',
                transaction_line_unique_key: 10622469,
                entity: 'C41389 secondqacompany',
                entity_status: 'Proposal',
                itemtype: 'Non-inventory Item',
                quantity: 1,
                rate: 12,
                item: 'Trial Additional Subscription Category (+1)',
                item_description: 'Include another category in your subscription to run reports.',
                purchase_type: 'Add-on',
                term_in_months: 12,
                start_date: '8/18/2023',
                end_date: '8/17/2024',
                sub_type: 'Sale',
                amount: 12,
                billtoaddressee: 'secondqacompany',
                billtoaddress1: 'add1',
                billtoaddress2: 'add2',
                billtoaddress3: null,
                billtocity: 'bbbb',
                billtostate: 'OH',
                billtozip: '45454',
                billtocountry: 'US',
                firstname: 'Byzzer',
                lastname: 'Sales',
                issalesrep: 'T',
            },
            {
                id: 710791,
                companyid: 300,
                quotenumber: 'QT1038',
                expirydate: '8/25/2023',
                sku: '816',
                transactionnumber: 'ESTIMATE1038',
                transactionlineuniquekey: 10599848,
                entity: 'C1009 test1.com',
                entitystatus: 'Proposal',
                itemtype: 'Non-inventory Item',
                quantity: -10,
                rate: 300,
                item: 'Financial Services Activate Monthly Updates',
                itemdescription: 'Bundle2',
                purchasetype: 'Subscription',
                terminmonths: 12,
                startdate: '4/22/2021',
                enddate: '4/21/2022',
                subtype: 'Sale',
                amount: 3000,
            },
            {
                id: 710791,
                companyid: 300,
                quotenumber: 'QT1038',
                expirydate: '8/25/2023',
                sku: '574',
                transactionnumber: 'ESTIMATE1038',
                transactionlineuniquekey: 10591141,
                entity: 'C1009 test1.com',
                entitystatus: 'Proposal',
                itemtype: 'Non-inventory Item',
                quantity: -20,
                rate: 180,
                item: 'Alert Bundle',
                itemdescription: 'Bundle1',
                purchasetype: 'Add-on',
                terminmonths: 12,
                startdate: '4/22/2021',
                enddate: '4/21/2022',
                subtype: 'Sale',
                amount: 3600,
            },
        ],
        billingAddress: {
            billToAddressee: 'secondqacompany',
            addr1: 'add1',
            addr2: 'add2',
            addr3: null,
            city: 'bbbb',
            state: 'OH',
            zip: '45454',
            country: 'US',
        },
        employee: {
            firstName: 'Byzzer',
            lastName: 'Sales',
            isSalesRep: 'T',
        },
    },
    acceptedDtm: null,
    createdDtm: '2023-08-18T17:38:14.972Z',
    updatedDtm: '2023-08-18T17:38:14.972Z',
};

export const paymentMethod = 'Invoice';
