import './FormattedValue.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import { rawValueToDisplay } from '@/views/simulators/utils';
import { SimulatorDatatype } from '@/types/SimulatorTypes';

export type FormattedValueProps = {
    type?: SimulatorDatatype | undefined;
    value: number | undefined;
} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'formatted-value';

export function FormattedValue({className, type = 'decimal', value, ...props}: FormattedValueProps) {

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            {rawValueToDisplay(type, value)}
        </div>
    )
}

export default FormattedValue;