import './Authentication.scss';
import React from 'react';
import OutletWithProps from "@/components/OutletWithProps";
import classnames from "classnames";
import { ByzzerLogo } from '@/components/ByzzerLogo';

const baseClassName = 'authentication'
export const Authentication = ({className, ...props}) => {
    return <div className={classnames(baseClassName, className)} {...props}>
        <ByzzerLogo />
        <OutletWithProps/>
    </div>
};
