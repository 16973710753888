export const formatDateMMDDYYYY = (str) => {
    if (str === null || str === undefined) {
        return '';
    }
    function pad(s) {
        return s < 10 ? '0' + s : s;
    }
    let d = new Date(str);
    return [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join('/');
};

export const formatPercent = (num) => {
    if (num === null || num === undefined) {
        return '';
    }
    let str = (num * 100).toFixed(1);
    let newStr = addComma(str.split('.')[0]) + '.' + str.split('.')[1];
    return newStr.includes('.') ? newStr : newStr + '.0';
};

export const formatPercentWithoutSign = (num) => {
    if (num === null || num === undefined) {
        return num;
    }
    let str = (Math.abs(num) * 100).toFixed(1);
    let newStr = addComma(str.split('.')[0]) + '.' + str.split('.')[1];
    return newStr.includes('.') ? newStr : newStr + '.0';
};

export const formatPercentWithSign = (num) => {
    if (num === null || num === undefined) {
        return '';
    }
    let addPositive = '';
    if (num > 0) addPositive = '+';
    let str = addPositive + (num * 100).toFixed(1);
    let newStr = addComma(str.split('.')[0]) + '.' + str.split('.')[1];
    return newStr.includes('.') ? newStr : newStr + '.0';
};

export const numberWith2Decimal = (num) => {
    // Nine Zeroes for Billions
    return Math.abs(num) >= 1.0e9
        ? Math.abs(num / 1.0e9).toFixed(2) + 'B'
        : // Six Zeroes for Millions
        Math.abs(num) >= 1.0e6
            ? Math.abs(num / 1.0e6).toFixed(2) + 'M'
            : // Three Zeroes for Thousands
            Math.abs(num) >= 1.0e3
                ? Math.abs(num / 1.0e3).toFixed(2) + 'K'
                : Math.abs(num).toFixed(2);
};

// ---------------------------------color and formatting changes ------------------------------

export const flatColor = '#ffbf3f';
export const greenColor = '#0fbc61';
export const orangeColor = '#ed4350'; //'#ff7b4d'
export const byzzerPinkColor = '#ef426f';
export const blackColor = '#000000';
export const redColor = '#ff0000';
export const blueColor = '#0000ff';
export const ByzzerPink = '#ef426f';
export const ByzzerReportLinkColor = '#000000';

export const getDirection = (num, positive, negative, flat) => {
    if (num >= 0.0005) {
        return positive;
    } else if (num < 0.0005 && num > -0.0005 && num !== null) return flat;
    else if (num <= -0.0005) return negative;
    else return num;
};

// Helper functions for HBS 
export const getDirectionWithNullCheck = (num, params = {}, val = 0.0005, delistedValue = -1) => {
    const negValue = parseFloat(`-${val}`);
    if (num >= val && params.positive) return params.positive;
    else if (num < val && num > negValue && num !== null && params.flat) return params.flat;
    else if (num <= negValue && !(num <= delistedValue) && params.negative) return params.negative;
    else if (num <= delistedValue && params.hasOwnProperty('delisted')) return params.delisted;
    else if (num === null && params.ly === 0 && params.ty > 0 && params.hasOwnProperty('defValue'))
        return params.defValue;
    else if ((num === null || params.ly === 0) && params.ty > 0 && params.hasOwnProperty('defValue'))
        return params.defValue;
    else if (num === null && params.ly === 0 && params.ty === 0) return params.flat;
    else return num;
};

export const coloredSpan = (colorName, text) => {
    let classState = 'alert_blurb_span__' + `${colorName}`;
    let htmlStr = `<span class=${classState}> 
                        ${text}
                    </span>`;
    return htmlStr;
}

export const returnColorNameByDirection = (direction, reverse = false) => {
    if (
        (typeof direction === 'string' &&
            (direction === 'up' ||
                direction.includes('increase') ||
                direction === 'over' ||
                direction.includes('gain') ||
                direction === 'grew')) ||
        (typeof direction === 'number' && direction >= 0.0005)
    )
        return reverse ? 'orangeColor' : 'greenColor';
    else if (
        (typeof direction === 'string' &&
            (direction === 'down' ||
                direction.includes('decrease') ||
                direction === 'under' ||
                direction.includes('lost') ||
                direction.includes('decline'))) ||
        (typeof direction === 'number' && direction <= -0.0005)
    )
        return reverse ? 'greenColor' : 'orangeColor';
    else if (
        (typeof direction === 'number' && direction < 0.0005 && direction > -0.0005 && direction !== null) ||
        (typeof direction === 'string' && (direction.includes('change') || direction.includes('flat')))
    )
        return 'flatColor';
    else return 'blackColor';
};

// txt = original txt, newtxt is expected txt, value decides the logic
export const returnNewtext = (txt, value) => {
    // if value is null, return empty string, else original text
    let newtxt = value ? txt : '';
    return newtxt;
};

export const toTitleCase = (str, forComma = false) => {
    try{
          if (str === null || str === undefined) {
                return '';
            }
        
          return str.replace(/\w\S*/g, function (txt) {
                let formattedText = '';
                if (txt.indexOf('/') > -1) {
                    formattedText = SplitUpperCase(txt, '/');
                } else if (txt.indexOf(',') > -1 && forComma === true) {
                    formattedText = SplitUpperCase(txt, ',');
                } else {
                    formattedText = txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
                formattedText = formattedText.replace(/Fmcg/g, 'FMCG');
                return formattedText;
            });
    }catch(exception){
            return str;
    }
};

// text is raw html, value decides the color
export const txtColor = (txt, direction, reverse = false) => {
    let classState = '';

    if (
        (typeof direction === 'string' &&
            (direction === 'up' ||
                direction.includes('increase') ||
                direction === 'over' ||
                direction.includes('gain') ||
                direction === 'grew')) ||
        (typeof direction === 'number' && direction >= 0.0005)
    ) {
        classState = reverse ? 'negative-value' : 'positive-value';
    }
    else if (
        (typeof direction === 'string' &&
            (direction === 'down' ||
                direction.includes('decrease') ||
                direction === 'under' ||
                direction.includes('lost') ||
                direction.includes('decline'))) ||
        (typeof direction === 'number' && direction <= -0.0005)
    ) {
        classState = reverse ? 'positive-value' : 'negative-value';
    }

    else if (
        (typeof direction === 'number' && direction < 0.0005 && direction > -0.0005 && direction !== null) ||
        (typeof direction === 'string' && (direction.includes('change') || direction.includes('flat')))
    ) {
        classState = 'flat-value';
    }
    else {
        classState = 'regular-value';
    }
    let htmlStr = `<span class=${classState}>${txt}</span>`;
    return htmlStr;
};

export const valueCheck = (direction) => {
    if (
        typeof direction === 'string' &&
        (direction.toLowerCase().includes('delisted') ||
            direction.toLowerCase().includes('new') ||
            direction.toLowerCase().includes('eliminated'))
    )
        return false;
    return true;
};

function SplitUpperCase(txt, splChar) {
    let first = txt.split(splChar)[0];
    let splitTxt = txt.split(splChar);
    let txtRes = '';
    if (first) {
        txtRes = first.charAt(0).toUpperCase() + first.substr(1).toLowerCase();
    }

    if(splChar === ',')
    splChar += ' ';
    splitTxt.shift();
    if (splitTxt) {
        splitTxt.forEach((element) => {
            txtRes += splChar + element.charAt(0).toUpperCase() + element.substr(1).toLowerCase();
        });
    }
    return txtRes;
}

function addComma(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

