import React, {forwardRef, ReactNode, useCallback, useEffect, useState } from 'react';
import {ByzzerChangeEventHandler, ByzzerSearch, ByzzerSelectOption, WithValue} from '@byzzer/ui-components';
import {useTenantApi} from '@/hooks/useTenantApi';
import {caseInsensitiveSort} from "@/utils/Sort";
import classnames from "classnames";
import { useApp } from '@/contexts/UserContext';

const baseClassName = 'brand-search';

export type ByzzerBrandSearchProps = {
    value?: string[];
    className?: string;
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<string[]>;
    placeholder?: string;
    maxSelections?: number; //decides how many brands we can add in dropdown
    disabled?: boolean;
    categories?: string[];
    // searchType?:  string;      if report is subscription or ad-hoc, based on that returns 'brandSearchMultiSelect'
    // or 'searchBrandForProductSetMultiSelect'
    // productType?: string;
    searchOptionsHeader?: React.FC;
    searchOptionsFooter?: React.FC;
    onlyShowSearchOptionsHeaderWithSearchResults?: boolean;
    onlyShowSearchOptionsFooterWithSearchResults?: boolean;
    allowSearchOfAllBrands?: boolean;
} & OnlyRenderableIf;

type ByzzerBrandSearchRef = { // implement imperative handle to set ref equal to value & categories
    value: string[];
    categories?: string[];
} 

export const ByzzerBrandSearch = forwardRef<ByzzerBrandSearchRef, ByzzerBrandSearchProps>((
        {
            className,
            label,
            name,
            onChange,
            value,
            placeholder = 'Search Brands',
            maxSelections,
            disabled,
            onlyRenderIf = true,
            categories,
            searchOptionsHeader,
            searchOptionsFooter,
            onlyShowSearchOptionsHeaderWithSearchResults,
            onlyShowSearchOptionsFooterWithSearchResults,
            allowSearchOfAllBrands,
            ...props
        }, ref) => {
            const {brandSearch, findBrandsForCategories} = useTenantApi();
        const { categories: categoriesFromSubscription}  = useApp();
        const [internalValue, setInternalValue] = useState<string[]>([]);

        useEffect(() => {
            // TODO: byzzer-server -> 'toDefaultRunConfig' should handle legacy string format for focusBrand default.  workaround below, but research toDefaultRunConfig to see if it is not working as intended.
            if (value && Array.isArray(value)) {
                setInternalValue(value)
            } else if (value) {
                setInternalValue([value])
            }
        }, [value])

        const searchBrandsForCategories = useCallback(async (searchText: string) => {
            const brands = await findBrandsForCategories(searchText, categories ?? categoriesFromSubscription);
            return brands.sort(caseInsensitiveSort);
        }, [categories, categoriesFromSubscription]);

        const searchAllBrands = async (searchText: string) => {
            const {brands} = await brandSearch(searchText, { returnAllHits: true });
            return brands?.sort(caseInsensitiveSort);
        };

        const handleChange = (e: ByzzerChangeEvent<ByzzerSelectOption[]>) => {
            onChange?.({
                value: e?.value?.map((brandItem) => brandItem.value),
                name,
                data: e?.data
            })
        }

        if(onlyRenderIf === false) return <></>

        return <ByzzerSearch
            ref={ref}
            name={name}
            className={classnames(baseClassName, className)}
            search={allowSearchOfAllBrands ? searchAllBrands : searchBrandsForCategories}
            onChange={handleChange}
            value={internalValue}
            label={label as any}
            placeholder={placeholder}
            maxSelections={maxSelections}
            searchOptionsHeader={searchOptionsHeader}
            searchOptionsFooter={searchOptionsFooter}
            onlyShowSearchOptionsHeaderWithSearchResults={onlyShowSearchOptionsHeaderWithSearchResults}
            onlyShowSearchOptionsFooterWithSearchResults={onlyShowSearchOptionsFooterWithSearchResults}
            disabled={disabled}
        />
    }
);
export default ByzzerBrandSearch;

