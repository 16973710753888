import './DodProductFilterPreview.scss';
import React, {memo, useMemo} from 'react';
import {DodFilters} from '@/types/DodRun';
import {ByzzerChangeEventHandler} from '@byzzer/ui-components';
import {DodFilterPreview, DodFilterPreviewProps, DodSelectionPreviewNode} from '@/components/DodConfigEditor/common';
import classnames from 'classnames';
import { DodProductFilterTypes, DodProductParentFilterTypes, DodSavedSelectionTypes } from '@/components/DodConfigEditor/types';

const baseClassName = 'dod-product-filter-preview';

export type DodProductFilterPreviewProps = {
    value: DodFilters;
    onChange?: ByzzerChangeEventHandler<DodFilters>;
} & Partial<DodFilterPreviewProps>;

const savedSelectionDescription = <>
    <p>This will save the full set of selections currently in your Selection panel so that you can easily reuse them in future extracts.</p>
    <p>Your saved selections will be available in the "My Saved Products" folder on the left.</p>
</>;

export const productsInitialGroups: DodSelectionPreviewNode[] = [
    {
        label: 'Hierarchy',
        filterType: DodProductParentFilterTypes.hierarchy,
        children: [
            { label: 'Department', filterType: DodProductFilterTypes.DEPARTMENTS },
            { label: 'Super Category', filterType: DodProductFilterTypes.SUPER_CATEGORIES },
            { label: 'Category', filterType: DodProductFilterTypes.CATEGORIES },
            { label: 'Subcategory', filterType: DodProductFilterTypes.SUBCATEGORIES },
            { label: 'UPC', filterType: DodProductFilterTypes.UPC },
            { label: 'Product Description', filterType: DodProductFilterTypes.PRODUCT_DESCRIPTIONS },
        ],
    },
    {
        label: 'Brand & Manufacturer',
        filterType: DodProductParentFilterTypes.brandAndManufacturer,
        children: [
            { label: 'Parent Company', filterType: DodProductFilterTypes.PARENT_COMPANIES },
            { label: 'Manufacturer', filterType: DodProductFilterTypes.MANUFACTURERS },
            { label: 'Brand', filterType: DodProductFilterTypes.BRANDS },
        ],
    },
    {
        label: 'Characteristics',
        filterType: DodProductParentFilterTypes.characteristics,
        loadChildren(filters) {
            return Object.entries(filters.characteristics).map(([characteristicDisplayValue, filter]) => ({
                label: filter.displayName,
                filterType: {
                    type: DodProductFilterTypes.CHARACTERISTICS,
                    data: {id:characteristicDisplayValue, displayName:filter.displayName}
                },
                values: filter.values,
                summedSelections: filter.summedSelections
            }));
        },
    },
    {
        label: 'My Custom Characteristics',
        filterType: DodProductParentFilterTypes.customCharacteristics,
        loadChildren(filters) {
          return Object.entries(filters.customCharacteristics).map(([characteristicDisplayValue, filter]) => ({
              label: filter.displayName,
              filterType: {
                  type: DodProductFilterTypes.CUSTOM_CHARACTERISTICS,
                  data: {id:characteristicDisplayValue, displayName:filter.displayName}
              },
              values: filter.values,
              summedSelections: filter.summedSelections
          }));
      },
    },
    {
        label: 'My PPGs',
        filterType: DodProductParentFilterTypes.ppgs,
        loadChildren(filters) {
          return Object.entries(filters.ppgs).map(([ppgDisplayValue, filter]) => ({
              label: filter.displayName,
              filterType: {
                  type: DodProductFilterTypes.PPGS,
                  data: {id:ppgDisplayValue, displayName:filter.displayName}
              },
              values: filter.values,
              summedSelections: filter.summedSelections
          }));
      },
    }
];

export const DodProductFilterPreview = memo(({ className, ...props }: DodProductFilterPreviewProps) => {

    const count = useMemo<number>(() => {
        const { value } = props;
        let count = 0;

        for (let key in value) {
            if (key === 'markets' || key === 'timePeriods') continue;

            if (key === 'characteristics' || key === 'customCharacteristics' || key === 'ppgs') {
                const length = Object.keys(value[key]).length;
                if (length > 0) {
                    count += length;
                }
            } else {
                const { values, summedSelections } = value[key];
                if (values.length > 0 || summedSelections.length > 0) {
                    count++;
                }
            }
        }
        return count;
    }, [props.value]);

    return (
        <DodFilterPreview
            {...props}
            className={classnames(baseClassName, className)}
            savedSelectionType={DodSavedSelectionTypes.PRODUCT}
            initialGroups={productsInitialGroups}
            emptyStateContent={'No Products Selected'}
            title={count > 0 ? `${count} Selected` : ''}
            selectionCount={count}
            savedSelectionDescription={savedSelectionDescription}
        />
    );
});

export default DodProductFilterPreview;
