import React, { useEffect, useState } from 'react';
import { ByzzerCheckbox, ByzzerRadio } from '@/components/form';
import './MultiSelectTotalUSMarkets.scss';
import classnames from 'classnames';
import { Tooltip } from 'antd';
import { MrktEntitlementType } from '@/utils';

const MultiSelectTotalUSMarket = (props) => {
    const { toolTipMessage, showTooltip, selectAll } = props;
    const baseClassName = 'multi-select-total-us-markets';
    useEffect(() => {
        let item =
            props.from === 'reportSelector'
                ? props.totalUSMarket?.find((val) => val.name === props.selectorState.focusMarket)
                : props.totalUSMarket?.find((val) => val.name === props?.userPrefState?.primaryMarket);
        if (
            item &&
            props?.saveBtnType !== 'alertSelector' &&
            props?.productType !== 'story' &&
            !props?.marketConfigs?.requiredRemMarketForTotalMarkets
        ) {
            setSingleSelectMarketData(item);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectorState?.focusMarket]);

    const setSingleSelectMarketData = (marketObj) => {
        if (props.from === 'reportSelector') {
            props.setSelectorsData({ focusMarket: marketObj.name });
        } else {
            props.setPreferenceState({ primaryMarket: marketObj.name, marketSelType: marketObj.mrkt_sel_typ });
        }
        props.setSelectedItems([
            {
                name: marketObj.name,
                marketKey: marketObj.mrkt_key,
            },
        ]);
        props?.setShowUpdateButton();
    };

    const showTotalUSMarketCheckboxOnly = (item) => {
        if (props?.productType === 'story' && props.multiSelect && props.marketType === 'comparisonMarket') {
            return (
                !disableTotalUSMarket(item) &&
                !props?.filterComparisonMarket(item?.name) &&
                props.isMarketLevelSelectable(item)
            );
        } else if (props?.productType === 'story' && props.multiSelect && props.marketType === 'focusMarket') {
            return !disableTotalUSMarket(item) && props.isMarketLevelSelectable(item);
        } else return true;
    };

    const showComparativeCheckbox = (item) => { 
        if (
            (props?.productType === 'story' && props.marketType === 'focusMarket') ||
            props?.alertWithAllMarketData
        ) {
            return !(props.selectedItems.length >= props.maxLimit && !item?.selectedComparitive);
        } else return true;
    };

    const enableMultiMarketSelection = (mrktItem, forDisabling = false) => {
        let enableFlag = true;
        if (props.marketGroup === 'cps') {
            const checkChannelMrktSel = props.selectedItems.filter(
                (selItem) => selItem.marketSelType === 'Panel By Channel'
            );
            const allOutletsSel =
                mrktItem.name !== 'All Outlets' &&
                props.selectedItems.map((selItem) => selItem.name).includes('All Outlets');
            const othersExceptAllOutletsSel =
                mrktItem.name === 'All Outlets' &&
                props.selectedItems.filter(
                    (selItem) => selItem.marketSelType === 'Panel Total US Markets' && selItem.name !== 'All Outlets'
                );
            if (
                (checkChannelMrktSel && checkChannelMrktSel.length > 0) ||
                allOutletsSel ||
                (othersExceptAllOutletsSel && othersExceptAllOutletsSel.length > 0)
            ) {
                enableFlag = false;
            }
        }
        if (props.selectedItems.length >= props.maxLimit && !((mrktItem?.selectedComparitive || mrktItem?.selected) )) {
            enableFlag = false;
            // if(forDisabling){
            //     if(!(mrktItem?.selected)){
            //         enableFlag = false;
            //     }
            // }
        }
        return enableFlag;
    };

    const disableTotalUSMarket = (item) => {
        if (props?.productType === 'story' || props?.alertWithAllMarketData) {
            return props.selectedItems.length >= props.maxLimit && !item?.selected;
        } else {
            // for reports/alerts
            return (
                !enableMultiMarketSelection(item, true) ||
                (props.selectedItems.length >= props.maxLimit && !item.selected)
            );
        }
    };

    const wrapperClasses = classnames('multi-select-total-us-markets');
    const [ isHidePromoData, setHideNonPromoData] = useState(false); //TODO post Suresh code commit BYZ-7230
    return props.totalUSMarket && props.totalUSMarket.length > 0 ? (
        <div className={wrapperClasses}>
            <div
                className={`multi-select-total-us-markets__heading ${props?.marketConfigs?.requiredRemMarketForTotalMarkets &&
                    'multi-select-total-us-markets__comparative-heading'
                    }`}
            >
                <span>Total US Markets:</span>
                {props?.marketConfigs?.requiredRemMarketForTotalMarkets && <span>Comparative Market</span>}
            </div>
            <div className="multi-select-total-us-markets__list">
                {props.totalUSMarket.length > 0 &&
                    props.totalUSMarket.map((item, index) => {                        
                        return (
                            ( ( props?.checkIfMarketCanBeLoaded(item) ) &&  (((props?.productType !== 'story' && item.name !== 'Total FMCG Retailers')||
                            (props?.productType === 'story' && !props?.marketConfigs?.showAllMarkets ) &&
                            props?.marketConfigs?.requiredRemMarketForTotalMarkets  
                        )||
                            !props?.marketConfigs?.requiredRemMarketForTotalMarkets ||
                            (props?.productType === 'story' &&
                                props?.marketConfigs?.showAllMarkets &&
                                props?.marketConfigs?.requiredRemMarketForTotalMarkets))) ? (
                                <div
                                    className={classnames(
                                        `${baseClassName}__list-item`, {
                                        [`${baseClassName}__list-item--disabled`]: showTotalUSMarketCheckboxOnly(item) && props.multiSelect && !enableMultiMarketSelection(item),
                                        [`${baseClassName}__list-item__disabledFlag`]: !item.is_releasable_mrkt
                                    }
                                    )}
                                    key={index + item?.name}
                                >
                                    {(item?.mrkt_entitlements === undefined ||
                                        item?.is_releasable_mrkt) ? (
                                            <>
                                                {showTotalUSMarketCheckboxOnly(item) ? (

                                                    <div
                                                        key={'total-us-' + index}
                                                        className="multi-select-total-us-markets__list-market show-total-checkbox"
                                                    >
                                                        {props.multiSelect === true ? (
                                                            <>
                                                                {enableMultiMarketSelection(item) && (
                                                                    <ByzzerCheckbox
                                                                        label={item.name}
                                                                        disabled={disableTotalUSMarket(item) || !enableMultiMarketSelection(item)}
                                                                        checked={item.selected || selectAll}
                                                                        onChange={(e) => {
                                                                            props.totalUSMarketSelected(index);
                                                                            props.checkLimit(
                                                                                {
                                                                                    ...item,
                                                                                    uniqueMarketName: item.name,
                                                                                    mrkt_key_selected: item.mrkt_key,
                                                                                    marketType: 'regular'
                                                                                },
                                                                                e.target.checked,
                                                                                item.mrkt_sel_typ
                                                                            );
                                                                        }}
                                                                        key={`total-us-${Date.now()}`}
                                                                    />
                                                                )}
                                                            </>
                                                        ) : (
                                                            <ByzzerRadio
                                                                label={item.name}
                                                                checked={
                                                                    props.from === 'reportSelector'
                                                                        ? props.selectorState.focusMarket === item.name
                                                                        : props?.userPrefState?.primaryMarket ===
                                                                        item.name
                                                                }
                                                                onChange={() => setSingleSelectMarketData(item)}
                                                            />
                                                        )}
                                                        <div className='hover-icons-wrapper'>
                                                            {showTooltip(item)}
                                                        </div>
                                                    </div>
                                                ) : null}

                                                {!showTotalUSMarketCheckboxOnly(item) && (
                                                    <div
                                                        key={'total-market-' + index}
                                                        className="multi-select-total-us-markets__list-market not-show-total"
                                                    >
                                                        <span>{item.name}</span>
                                                        <div className='hover-icons-wrapper'>
                                                            {showTooltip(item)}
                                                        </div>
                                                    </div>
                                                )}
                                                {props?.marketConfigs?.requiredRemMarketForTotalMarkets ? (
                                                    <div
                                                        key={'total-remaining-' + index}
                                                        className="multi-select-total-us-markets__list-comparative"
                                                    >
                                                        {showComparativeCheckbox(item) &&
                                                            !(
                                                                props.selectedItems.length >= props.maxLimit &&
                                                                !(item?.selectedComparitive || item.selected)
                                                            ) &&
                                                            props?.marketConfigs?.requiredRemMarketForTotalMarkets && (
                                                                <div className="multi-select-markets-by-geography__lists-folders-container-list-item2">
                                                                    <ByzzerCheckbox
                                                                        disabled={
                                                                            !item.comparative_mkt_disp_name ||
                                                                            (props.selectedItems.length >= props.maxLimit &&
                                                                                !item?.selectedComparitive)
                                                                        }
                                                                        style={{
                                                                            display: 'flex',
                                                                            paddingRight: '3.4px',
                                                                            alignItems: 'center',
                                                                        }}
                                                                        key={`comparitive-total-${Date.now()}`}
                                                                        onChange={(e) => {
                                                                            props.totalUSMarketSelected(
                                                                                index,
                                                                                'comparative'
                                                                            );
                                                                            props.checkLimit(
                                                                                {
                                                                                    ...item,
                                                                                    uniqueMarketName: `${item.comparative_mkt_disp_name}$$${item.comparative_mrkt_key}$$${item.mrkt_key}`,
                                                                                    mrkt_key_selected:
                                                                                        item.comparative_mrkt_key,
                                                                                    marketType: 'comparative',
                                                                                },
                                                                                e.target.checked,
                                                                                item.mrkt_sel_typ
                                                                            );
                                                                        }}
                                                                        checked={item?.selectedComparitive}
                                                                    />
                                                                </div>
                                                            )}
                                                    </div>
                                                ) : null}
                                            </>
                                        ) : null}
                                    {(item?.mrkt_entitlements?.includes( MrktEntitlementType.NOT_PRIOR_APPROVAL ) ||
                                      item?.mrkt_entitlements?.includes( MrktEntitlementType.NOT_REV_SHARE ) ||
                                      item?.mrkt_entitlements?.includes( MrktEntitlementType.CATEGORY_NOT_COVERED ) ) ? (

                                            <div key={'total-us-tooltip' + index} className={`multi-select-total-us-markets__list-market tooltip-item ${!props.multiSelect ? 'single-select' : ''}`}>
                                                <span
                                                    className={
                                                        item?.mrkt_entitlements?.includes( MrktEntitlementType.CATEGORY_NOT_COVERED ) 
                                                            ? 'multi-select-total-us-markets__list-market-uncovered'
                                                            : 'multi-select-total-us-markets__list-market-covered'
                                                    }
                                                >
                                                    {item.name}
                                                </span>
                                                <div className='hover-icons-wrapper'>
                                                    {showTooltip(item)}
                                                </div>
                                            </div>
                                        ) : null}
                                </div>
                            ) : null
                        );
                    })}
            </div>
        </div>
    ) : null;
};
export default MultiSelectTotalUSMarket;