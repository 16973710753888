import React from 'react';
import classnames from 'classnames';
import './ReportCardBadge.scss';

const baseClassName = 'report-card-badge';

export type BadgeType = 'Wellness' | 'Smart' | 'Core Advanced' | string;

export type ReportCardBadgeProps = {
    className?: string;
    badgeText: BadgeType;
};
export function ReportCardBadge({ className, badgeText }: ReportCardBadgeProps) {

    if (!badgeText || badgeText === 'Core Base') return <></>;

    return (
        <div className={classnames(baseClassName, className)}>
            {badgeText}
        </div>
    );
}
