import { ByzzerLink } from '@/components/form';
import React from 'react';
import moment from 'moment';
import {isEmpty} from "lodash";


function SplitUpperCase(txt, splChar) {
    let first = txt.split(splChar)[0];
    let splitTxt = txt.split(splChar);
    let txtRes = '';
    if (first) {
        txtRes = first.charAt(0).toUpperCase() + first.substr(1).toLowerCase();
    }

    if (splChar === ',') splChar += ' ';
    splitTxt.shift();
    if (splitTxt) {
        splitTxt.forEach((element) => {
            txtRes += splChar + element.charAt(0).toUpperCase() + element.substr(1).toLowerCase();
        });
    }
    return txtRes;
}
export const toTitleCase = (str, forComma = false) => {
    try {
        if (str === null || str === undefined) {
            return '';
        }

        return str.replace(/\w\S*/g, function (txt) {
            let formattedText = '';
            if (txt.indexOf('/') > -1) {
                formattedText = SplitUpperCase(txt, '/');
            } else if (txt.indexOf(',') > -1 && forComma === true) {
                formattedText = SplitUpperCase(txt, ',');
            } else {
                formattedText = txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
            formattedText = formattedText.replace(/Fmcg/g, 'FMCG');
            return formattedText;
        });
    } catch (exception) {
        return str;
    }
};
export const getCharacteristicsTxt = (characteristicsFilters) => {
    if (characteristicsFilters.length)
        return characteristicsFilters.reduce((acc, memo, index) => {
            if (!acc) acc = '';
            let value =
                typeof memo.value === 'string'
                    ? toTitleCase(memo.value)
                    : memo.value.map((e) => toTitleCase(e)).join(', ');
            // let condition = memo.condition === 'is not' ? 'not' + ' ' : '';
            let charValue=memo?.characteristicDisplayValue ? memo?.characteristicDisplayValue : memo.chr_display_name
            acc +=
                index === characteristicsFilters.length - 2
                    ? `${charValue} ${memo.condition} ${value} and `
                    : `${charValue} ${memo.condition} ${value}${index === characteristicsFilters.length - 1 || characteristicsFilters.length === 1 ? '' : ', '
                    }`;
            return acc;
        }, '');
    else return '';
};
export const splitLink = (link) => {
    if (link.split('#').length > 1) return link.split('#')[1].toString();
    else return link;
};

export const formatCategories = (primaryCategory = [], companyCategory = [], navigate, extraInfo, extraInfoHandler) => {
    let category = {
        key: 'Category(s)', value: ''
    };
    let categoryWithLink = ''
    if (primaryCategory?.length) {
        categoryWithLink = individualDescription({
            type: 'info',
            key: `+${primaryCategory?.length > 4 ? primaryCategory?.length - 2 : 1} more`,
            value: multipleParamsTxt(primaryCategory, 6),
            text: primaryCategory.toString()
        }, navigate, extraInfo, extraInfoHandler);
    }
    else {
        if (companyCategory?.length) {
            categoryWithLink = individualDescription({
                type: 'info',
                key: `+${companyCategory?.length > 4 ? companyCategory?.length - 2 : 1} more`,
                value: multipleParamsTxt(companyCategory, 6),
                text: companyCategory.toString()    
            }, navigate, extraInfo, extraInfoHandler)
        }
    }
    category.value = categoryWithLink
    return category;

}
export const formatFocusBrands = (focusBrands = [], navigate, extraInfo, extraInfoHandler) => {
    let focusBrand = {
        key: 'FocusBrand(s)', 
        value: '',
    };
    let focusBrandWithLink = '';
    if (focusBrands.length) {
        focusBrandWithLink = individualDescription({
            type: 'info',
            key: `+${focusBrands?.length > 2 ? focusBrands?.length - 2 : 1} more`,
            value: multipleParamsTxt(focusBrands, 3),
            text: focusBrands.toString()
        }, navigate, extraInfo, extraInfoHandler);
    }    
    focusBrand.value = focusBrandWithLink;
    return focusBrand;
}

export const formatDataContext = (tile, navigate, extraInfo, extraInfoHandler) => {
    const context = [];
    const data = tile?.dataContext || {};
    const endDate = tile?.endDate || '';
    const tileType = tile?.vizConfig?.type || '';
    if (!isEmpty(data?.focusBrand))        
        context.push( formatFocusBrands(data?.focusBrand, navigate, extraInfo, extraInfoHandler));

    if (data?.primaryCategory?.length || data?.companyCategory?.length)
        context.push(formatCategories(data?.primaryCategory, data?.companyCategory, navigate, extraInfo, extraInfoHandler));

    if (tileType !== 'brandScore') {
        if (!isEmpty(data?.characteristicFilters))
            context.push({
                key: 'Characteristics',
                value: getCharacteristicsTxt(data?.characteristicFilters),
            });
    }
    context.push({
        key: 'Market',
        value: !isEmpty(data?.primaryMarket) ? data?.primaryMarket : 'Total FMCG Retailers' ,
    });

    if (tileType === 'brandScore') {
        context.push({
            key: 'Time Period',
            value: `Latest 52 Weeks${endDate ? ' ending ' + moment(endDate).format('MM/DD/YYYY').toString() : ''}`,
        });
    } else {


        const timePeriod = data?.defaultTimePeriod;
        let timePeriodValue;
        if (timePeriod?.type === 'week_ending' && typeof timePeriod?.period?.endDate === 'string') {
            timePeriodValue = timePeriod?.period.weeks+' weeks ending ' + moment(timePeriod?.period?.endDate).format('MM/DD/YYYY').toString();
        }else {
            timePeriodValue = !isEmpty(data?.defaultTimePeriod)
        ? data?.defaultTimePeriod + `${endDate ? ' ending ' + moment(endDate).format('MM/DD/YYYY').toString() : ''}` : `Latest 52 Weeks${endDate ? ' ending ' + moment(endDate).format('MM/DD/YYYY').toString() : ''}`
        }
        context.push({
            key: 'Time Period',
            value: timePeriodValue
        });
    }
    return context;
};

export const truncateString = (str, num) => {
    if (!isEmpty(str) && str.length > num) return str.slice(0, num) + '...';
    else return str;
};

export const getURLParam = (hyperLink, queryParam) => {
    queryParam = queryParam.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + queryParam + "=([^&#]*)"),
        results = regex.exec(hyperLink);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export const multipleParamsTxt = (params, maxLimit, alias = '') => {
    if (alias !== '') return alias
    else if (params.length){
        if (typeof params === 'string') {
            params = [params];
        }
        return params.reduce((acc, memo, index) => {
            if (!acc) acc = '';
            if (params.length < maxLimit)
                acc += `${memo}${index === params.length - 1 || params.length === 1 ? '' : ', '}`;
            else acc += index == 0 ? `${memo}, ${params[1]}, +${params.length - 2} more` : '';
            return acc;
        }, '');
    }
    else return '';
};
export const individualDescription = (description, navigate, extraInfo = false, extraInfoHandler = {}) => {
    if (description.type === 'link') {
        if (description?.value.includes(description?.key))
            return AttachLink(description?.value, description?.key, splitLink(description?.link), navigate);
        else return description?.value
    }
    else if (description.type === 'info' || description.type === 'warning') {
        if (description?.value.includes(description?.key))
            return AttachExtraInfo(description?.text, description?.value, description?.key, extraInfo, extraInfoHandler);
        else return description?.value
    }
    else return description?.value;
}
function AttachExtraInfo(text, value, match, extraInfo, extraInfoHandler) {
    let parts = value.split(match); // re is a matching regular expression
    for (let i = 1; i < parts.length; i += 2) {
        parts[i + 1] = parts[i];
        parts[i] = extraInfo ? text.split(",").slice(2).join(", ") : (<a className="__extra-info" key={i} onClick={() => extraInfoHandler()}>{match}</a>);
    }
    return parts;
}

function AttachLink(text, match, link, navigate) {
    let parts = text.split(match); // re is a matching regular expression
    for (let i = 1; i < parts.length; i += 2) {
        parts[i + 1] = parts[i];
        parts[i] = <ByzzerLink key={i} label={match} onClick={() => navigate(link)} />;
    }
    return parts;
}

export const formatWkEndingDate = (tile) => {
    if (tile?.vizConfig?.type === 'salesOverTime' && tile.data.content !== null && tile.data.content?.series?.length) {
        tile.data.content.series = tile.data.content.series?.map((eachSeries,index) => {            
            eachSeries.values = eachSeries.values?.map((eachValue) => {
                if (typeof (eachValue[0]) === 'string')
                    return [Date.parse(moment.utc(eachValue[0]).format('MM/DD/YYYY')), eachValue[1]]
                else
                    return [eachValue[0], eachValue[1]]

            })
            if (index===0){
                return {
                    ...(eachSeries),
                    ...({
                        'line-color': '#675895',
                        'legend-marker': {
                            type: 'line',
                            'line-style': 'dashed',
                            'line-color': '#675895',
                        },
                    })
                }
            }
            else if (index===1){
                return {
                    ...(eachSeries),
                    ...({
                        'line-color': '#4697E2',
                        'legend-marker': {
                            type: 'line',
                            'line-color': '#4697E2',
                        },
                    })
                }
            }
            else{
                return {
                    ...(eachSeries),
                    ...({
                        'line-color': '#CB4B7A',
                        'legend-marker': {
                            type: 'line',
                            'line-color': '#CB4B7A',
                        },
                    })
                }
            }

            return eachSeries
        })
    }

    return tile?.data?.content?.series
}

export const formGraphData = (tile) => {

    const graphConfig = {
        series: formatWkEndingDate(tile),
        ...tile?.vizConfig?.graph,
    };
    const configWithGUI = {
        gui: {
            contextMenu: {
                empty: true,
                button: {
                    visible: false,
                },
            },
            watermark: {
                visible: false,
            },
        },
        graphset: [graphConfig],
    };
    return configWithGUI;
};
