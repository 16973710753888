import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ByzzerButton from './ByzzerButton';
import clsx from 'clsx';
import StepConnector from '@material-ui/core/StepConnector';
// import CheckIcon from '@material-ui/icons/Check';
/** Component style setup */
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-block',
        width: '100%',
        background: '#FFFFFF',
        padding: '26px',
    },
    footer: {
        float: 'right',
    },
    backButton: {
        display: 'inline-flex',
        margin: '5px',
    },
    nextButton: {
        display: 'inline-flex',
        margin: '5px',
    },
}));
/** Step connector style setup */
const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 23,
    },
    active: {
        '& $line': {
            background: '#eaeaf0',
        },
    },
    completed: {
        '& $line': {
            background: '#eaeaf0',
        },
    },
    line: {
        height: 2,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
        margin: '0 30px',
    },
})(StepConnector);
/** Step style setup */
const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 48,
        height: 48,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage: 'linear-gradient( 136deg, #000000 0%, #000000 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage: 'linear-gradient( 136deg, #000000 0%, #000000 100%)',
    },
});
/** Step UI arrangement */
function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed, // classes?, ::after tag, backgroundimge = niq-check-blue, size matches existing icon
            })}
        >
            {completed ? <CheckIcon /> : String(props.icon)}
        </div>
    );
}
/** Default Value for control */
const getStepContent = (step) => {
    switch (step) {
        default:
            return 'Add Valid step';
    }
};

/** function prop type default value */
ByzzerStepper.defaultProps = {
    showFooter: true,
    steps: ['Dummy Step'],
    stepsContent: getStepContent,
    activateStep: 0,
};
/**
 * Stepper UI Derived from Material-UI Core
 * @param {{showFooter: boolean,steps: string[], stepsContent:(stepID: number)=>{}, activateStep:number }} props input props
 * @return Stepper base UI elelment
 */
function ByzzerStepper({ showFooter, steps, stepsContent, activateStep }) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(activateStep);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                {steps.map((label, index) => {
                    return (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {stepsContent(activeStep)}
            {showFooter ? (
                <div className={classes.footer}>
                    <ByzzerButton disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
                        Back
                    </ByzzerButton>
                    <ByzzerButton
                        className={classes.nextButton}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                    >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </ByzzerButton>
                </div>
            ) : (
                ''
            )}
        </div>
    );
}

export default ByzzerStepper;
