import { SimulatorConfig, SimulatorValues } from '../types/SimulatorTypes';
import * as fjs from '@formulajs/formulajs';
import {tokenize} from "excel-formula-tokenizer";


// todo: add support for identifying formulas in the data values and recursively parse them,
//  otherwise there are lots of edge cases that won't work
export function evaluateFormula<TResult extends string | number = number>(
    formula: string | undefined,
    values: Record<string, string | number | undefined>,
    explicitValues: Record<string, number | undefined> = {}): TResult | undefined {

    const MAX_LOOPS = 10;
    if (!formula) return;

    try {
        const f = {
            ...fjs,
            ISNULL(value: any, ifNull: any, ifNotNull: any): any {
                return value === null ? ifNull : ifNotNull;
            },
            ISBLANK(value: any, ifBlank: any, ifNotBlank: any): any {
                return value === null || value === undefined || value === '' ? ifBlank : ifNotBlank;
            }
        }
        let transformedFormula = formula;
        let loopCnt = 0;

        // keep applying replacing all $ prefixed values until there are none left
        // this is necessary because variables can contain reference to formulas that reference other formulas
        while (/\$\w/.test(transformedFormula) && loopCnt < MAX_LOOPS) {

            transformedFormula = transformedFormula.replace(/\$\$\w+/g, (key: string): string => {
                const explicitValue = String(explicitValues[key.replace('$$', '')] ?? null);
                return explicitValue;
            }).replace(/\$!?(\w+)/g, (_: string, key: string) => {
                const value = values[key];
                if (value !== undefined) {
                    return JSON.stringify(value).replace(/^"/, '').replace(/"$/, '');
                }
                return '';
            });
            loopCnt++;
        }

        if (loopCnt >= MAX_LOOPS) {
            console.error('max formula parse loops reached', {
                formula
            });
            return;
        }

        // console.log(transformedFormula)

        const finalFormula = tokenize(transformedFormula).map(({value, type, subtype}) => {
            if (subtype === 'start' && type === 'function') {
                return `f.${value.toUpperCase()}(`;
            }

            if (subtype === 'start' && type === 'subexpression') {
                return '(';
            }

            if (subtype === 'stop' && type === 'subexpression') {
                return ')'
            }

            if (subtype === 'stop' && type === 'function') {
                return ')';
            }
            return value;
        }).join('');


        // CAUTION: eval is dangerous especially when it can be execute on arbitrary code.
        // todo: add a sanitizer and/or validator to ensure this only runs a trusted excel style formula
        const result = eval(finalFormula);

        // console.log({result})

        // it's possible for eval to return a function or object which we don't want, this protects against that
        if (!['string', 'number'].includes(typeof result)) return;

        return result as TResult;
    } catch (err) {
        return;
    }
}