import React, { useEffect } from 'react';
import useState from "react-usestateref";
import './WeeklyAlerts.scss';
import {useTenantApi} from '@/hooks/useTenantApi';
import { useUser } from '@/contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import {TrackClick} from "@/analytics";
import { ByzzerButton, ByzzerLink } from '@/components/form';
import MyAlertView from '@/components/MyAlert/MyAlertView';
import classNames from 'classnames';
import { AlertRun } from '@/types/ApiTypes';
import { AlertLimits, MyAlertsProps } from '@/pages/MyAlertsPage';

const baseClassName = 'weekly-alerts';

export const WeeklyAlerts = ({
    alertLimits: { totalConfiguredAlerts },
    alertHistory: alertRuns,
    ...props
}: MyAlertsProps) => {
    const { getUserAlertHistory, getConfiguredAlerts } = useTenantApi();
    const [loading, setLoading] = useState<boolean>(false);
    const [showCurrentAlert, setShowCurrentAlert] = useState<boolean>(false);
    const [alertVersion, setAlertVersion] = useState<string>('1.0');

    const { user } = useUser();
    const navigate = useNavigate();

    function configureAlerts() {
        navigate('/dashboard/my_alerts/available');
    };

    return (
        <div className={classNames(`${baseClassName}`)}>
            {!showCurrentAlert && (
                <header className={classNames(`${baseClassName}__header`)}>
                    <div className={classNames(`${baseClassName}__salutation`)}>
                        <p>Hi {user?.firstName}! You have {alertRuns?.length} Alert(s) this week.</p>
                    </div>
                    <div className={classNames(`${baseClassName}__sub-heading`)}>
                        <p>Monitor your business threats with Byzzer alerts, so you can easily address high-value opportunities to drive more revenue.</p>
                    </div>
                </header>
            )}
            <main className={classNames(`${baseClassName}__alert-content`)}>
                <section className={classNames(`${baseClassName}__alert-cards`)}>
                    {Boolean(alertRuns?.length) && (
                        <MyAlertView
                            alertRuns={alertRuns}
                            showAlert={() => setShowCurrentAlert(true)} 
                            hideAlert={() => setShowCurrentAlert(false)}
                        />
                    )}
                </section>
                <section className={classNames(`${baseClassName}__no-alerts`)}>
                    {!totalConfiguredAlerts && !alertRuns?.length && !loading && ( // TODO: Switch this to isLoading from props (from MyAlertsPage)
                        <div className="configure-alert-msg">
                            You have no alerts setup at this time.{' '}
                            <b>
                                <TrackClick name={`My Alerts - Configure Your Alerts Link clicked`}>
                                    <ByzzerLink onClick={configureAlerts}>
                                        Configure your alerts
                                    </ByzzerLink>
                                </TrackClick>
                            </b>{' '}
                            to receive weekly updates on Tuesdays.
                        </div>
                    )}
                </section>
            </main>
        </div>
    );
}
 
export default WeeklyAlerts;

WeeklyAlerts.displayName = 'WeeklyAlerts';