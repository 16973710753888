import './AlertRunConfigEditor.scss';
import React, { useEffect, useState, useCallback } from 'react';
import { getProductBySku, getRunConfigOptionsBySku } from '@/services/product.service';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {useUser} from "@/contexts/UserContext";
import {AlertRunConfigWizard} from "@/components/ConfigurationEditors/AlertConfigurationEditor/AlertRunConfigWizard";
import {useAlertRunService} from "@/services/alertRun.service";
import { SubscriptionInfo } from "@/constants/subscription.constants"
import { AlertRunConfigOptions } from '@/types/AlertRunConfigOptions';
import { UserAlertSummary, AlertRunConfig } from '@/types/AlertRun';
import DashboardContent from '@/components/dashboard/DashboardContent';
import {ByzzerButton} from "@byzzer/ui-components";
import {useTenantApi} from '@/hooks/useTenantApi';
import { intersection } from 'lodash';
import { CharacteristicCondition, ProductSelection } from '@/types/ReportRun';
import { upcCharacteristicOption } from '@/config/globalVars';
import { alert } from '@/components/form';
import { MarketsToWatch, MarketTypeOption } from '@/types/RunConfigOptions';
import { MARKET_TYPES } from '@/components/MarketSelection/MarketTypeSelector/MarketTypeSelectorConstants';

export type AlertRunConfigEditorProps = React.HTMLAttributes<HTMLDivElement> & {}

export type StateType = { // todo: rename this
    userAlert: UserAlertSummary;
};

const baseClassName = 'alert-run-config-editor';

export function categoryAppearsInGivenList(selectedCategory: string, categoriesToFilterWith: string[]): boolean {
    return categoriesToFilterWith?.includes(selectedCategory)
}


export function handleProductSelectionMapToRemoveUnsubscribedCats(productSelection: ProductSelection, subscriptionCats: string[]): ProductSelection {
    return {
        ...productSelection,
        categories: productSelection?.categories?.filter((selectedCategory) => categoryAppearsInGivenList(selectedCategory, subscriptionCats)) ?? []
    }
}

export function AlertRunConfigEditor({className, ...props}: AlertRunConfigEditorProps) {
    const { createAlerts, updateAlerts, getCharacteristicsForCategories } = useTenantApi();

    // console.log("AlertRunConfigEditor - stuff is happening?");
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { getDefaultAlertRunConfigBySku } = useAlertRunService();
    const [loadedAlert, setLoadedAlert] = useState<any>();
    const { categories: subscriptionCategories, customCharacteristics: subscriptionCustomCharacteristics, refreshConfiguredAlerts } = useUser();
    const {subscribedSkus, subscription} = useUser();
    const [runConfigOptions, setRunConfigOptions] = useState<AlertRunConfigOptions[]>()
    const [defaultRunConfig, setDefaultRunConfig] = useState<Partial<AlertRunConfig>>({});
    const [alertType, setAlertType] = useState<any>('');
    const [generating, setGenerating] = useState<boolean>(false);
    const [ editingExistingAlert, setEditingExistingAlert] = useState<boolean>(false);
    const location = useLocation();    

    const sku = searchParams.get('sku');
    const alertConfigId = searchParams.get('alertConfigId');
    const locationState = location?.state as StateType; // todo: rename this

    useEffect(() => {
        ;(async () => {
            // if (await validateOrExit()) {
                await loadConfig();
            // }
        })();
    }, [sku]);

    const removeCharsWithUnsubcribedCats = useCallback(async (
        charsToFilter: CharacteristicCondition[], 
        subscriptionCustomChars: CustomCharacteristic[], 
        categoriesToFilterWith: string[], 
    ): Promise<CharacteristicCondition[]> => {
    
        const regularCharsForSelectedCategories = [
            ...(await getCharacteristicsForCategories(categoriesToFilterWith)), 
            upcCharacteristicOption // should be handled by Alby at some point
        ];
        const customCharsForSelectedCategories = subscriptionCustomChars?.filter((contextCustomChar) => Boolean(intersection(contextCustomChar.categories, categoriesToFilterWith).length))
    
        return charsToFilter?.filter((selectedChar) => (
            (selectedChar?.isCustom && customCharsForSelectedCategories?.some((permittedCustChar) => permittedCustChar?.id === +selectedChar?.characteristic!)) || // selected customchar will need to be appear in customCharsForSelectedCategories as this is the allowed chars per current subscription cats.  
            regularCharsForSelectedCategories?.some((permittedRegChar) => permittedRegChar?.code === selectedChar?.characteristic)
        ));
    }, [])

    async function loadConfig() {
        try {

            const alertForSku = getProductBySku(sku!);
            // console.log("AlertRunConfigEditor - loadConfig fired - 'alertForSku' ===>> ", alertForSku)
            // todo: show message if there is no matching config for he sku
            if (!alertForSku) {
                await alert({
                    // @ts-ignore
                    title: 'Product Not Found',
                    content: `We were unable to find the product you are trying to configure.`,
                });

                return navigate(-1);
            }

            // todo: add support normalizing reports so this can just be check of single type instead of all this
            // setAlertType(report.metadata.reportOptions?.reportType.startsWith('smart') ? 'smart' : 'core');
            setLoadedAlert(alertForSku);

            const alertRunConfigOptions = getRunConfigOptionsBySku(sku!);

            if (locationState?.userAlert?.refactoredConfig) {

                setEditingExistingAlert(true);

                const { categories = [], productSelections = [], characteristics = [], ...alertConfigToEdit } = locationState?.userAlert?.refactoredConfig;

                const selectedCategoriesWithUnsubscribedCatsRemoved = categories?.filter((selectedCategory) => categoryAppearsInGivenList(selectedCategory, subscriptionCategories)) ?? []; // removing categories from the selection if they arent found in their subscription

                const unsubscribedCatsWereRemoved = selectedCategoriesWithUnsubscribedCatsRemoved?.length !== categories?.length;
                if (alertConfigToEdit.marketsToWatch) {
                    const marketsToWatchConfig: MarketsToWatch = alertRunConfigOptions.filter(
                        (options) => options.type === 'markets_to_watch'
                    )?.[0];
                    const marketsToWatchOptions: MarketTypeOption[] =
                        marketsToWatchConfig?.marketTypeOptions ?? MARKET_TYPES;
                    if (
                        !(
                            marketsToWatchOptions.filter(
                                (option) =>
                                    option.marketType === alertConfigToEdit.marketsToWatch?.marketType &&
                                    option.children.includes(alertConfigToEdit.marketsToWatch?.channel)
                            )?.length > 0
                        )
                    ) {
                        alertConfigToEdit.marketsToWatch = undefined;
                    }
                }

                const alertConfigFilteredForSubscribedCategories: AlertRunConfig = {
                    ...alertConfigToEdit,
                    categories: selectedCategoriesWithUnsubscribedCatsRemoved,
                    productSelections: productSelections?.map((productSelection) => handleProductSelectionMapToRemoveUnsubscribedCats(productSelection, subscriptionCategories)),
                    characteristics: characteristics?.length ? await removeCharsWithUnsubcribedCats(characteristics, subscriptionCustomCharacteristics, selectedCategoriesWithUnsubscribedCatsRemoved) : [],
                    ...(unsubscribedCatsWereRemoved ? {
                        markets: [],
                        marketsToWatch: undefined
                    } : {}),
                };

                setDefaultRunConfig(alertConfigFilteredForSubscribedCategories!)
                // todo: get the config from public.user_alerts table
            } else {
                setEditingExistingAlert(false);

                const defaults = await getDefaultAlertRunConfigBySku(sku);

                setDefaultRunConfig(defaults);  // should be from alertRun.service
            }
            
            setRunConfigOptions(alertRunConfigOptions as AlertRunConfigOptions[]); // should be from product.service
        } finally {
            // setLoading(false);
        }

        
    }

    async function completeAlert(runConfig: AlertRunConfig): Promise<void> {
        setGenerating(true)
        try {
            if (editingExistingAlert) {
                await updateAlerts(locationState?.userAlert?.id, sku!, runConfig);
            } else {
                await createAlerts(sku!, runConfig);
            }
            await refreshConfiguredAlerts();
            navigate(-1);
            setGenerating(false)
        } catch (err) {
            setGenerating(false)
        }
    }

    return ( 
        // @ts-ignore
        <DashboardContent
            title={<>{editingExistingAlert ? 'Edit' : 'Design'} Your {loadedAlert?.title.toLowerCase().slice(-5) === 'alert' ? loadedAlert?.title : `${loadedAlert?.title} Alert`}</>} // defensive, just to ensure the word 'Alert' isn't doubled
            className={`${baseClassName}__container`}
            loading={!runConfigOptions}
            extras={(
                <div className={`${baseClassName}__header-actions`}>
                    <ByzzerButton type={'negative'} label={'Cancel'} onClick={() => navigate(-1)}/>
                </div>
            )}
        >
            {runConfigOptions && (
                <AlertRunConfigWizard 
                    sku={sku!}
                    onComplete={completeAlert}
                    defaultValues={defaultRunConfig}
                    busy={generating}
                    runConfigOptions={runConfigOptions}
                    editingExistingAlert={editingExistingAlert}
                />
            )}
        </DashboardContent>

     );
}
 
// export default AlertRunConfigEditor;

AlertRunConfigEditor.displayName = 'AlertRunConfigEditor';
