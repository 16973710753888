import './DodMarketFilterTypePicker.scss';
import { useState } from 'react';
import {
    DodFilterTypeOptionGroup,
    DodFilterTypePicker,
    DodFilterTypePickerProps
} from '@/components/DodConfigEditor/common';
import { DodFilters } from '@/types/DodRun';
import {DodMarketFilterType} from '@/components/DodConfigEditor/types';
import { ByzzerChangeEventHandler, ByzzerTabs } from '@byzzer/ui-components';
import classnames from 'classnames';
import { SAVED_MARKET_INFO } from '@/components/DodConfigEditor/builders/DodMarketFilterBuilder/MarketConstants';
import { DodPreset } from '@/types/ApiTypes';
import DodMarketAdvancedSearch from '../DodMarketAdvancedSearch/DodMarketAdvancedSearch';
import { AdvancedSearchFilters, MarketTab, MarketTabList } from '../DodMarketFilterBuilder';
import { useUser } from '@/contexts/UserContext';

const baseClassName = 'dod-market-filter-type-picker';

export type DodMarketFilterTypeValue = {
    type: DodMarketFilterType;
    value?: string;
};

export type DodMarketFilterTypePickerProps = {
    name?: string;
    value: DodMarketFilterType;
    onChange: ByzzerChangeEventHandler<DodMarketFilterType>;
    filters: DodFilters;
    tipDelay?: number | [number | null, number | null];
    onPresetSelect: ByzzerChangeEventHandler<DodPreset>;
    advancedSearchFilters: AdvancedSearchFilters;
    onAdvancedSearchFiltersChange: ByzzerChangeEventHandler<AdvancedSearchFilters>;
    activeMarketFolderTab: MarketTab;
    tabList: MarketTabList;
    onTabChange: (activeKey: string) => void;
    accessibleMarketFolders: any
} & Partial<Omit<DodFilterTypePickerProps<DodFilters, DodMarketFilterType>, 'initialOptionGroups'>>;

export function DodMarketFilterTypePicker({
    className,
    onChange,
    filters,
    advancedSearchFilters,
    onAdvancedSearchFiltersChange,
    activeMarketFolderTab,
    tabList,
    onTabChange,
    accessibleMarketFolders,
    ...props
}: DodMarketFilterTypePickerProps) {
    const { features } = useUser();
    const [optionGroups] = useState<DodFilterTypeOptionGroup<DodFilters, DodMarketFilterType>[]>([
        {
            label: 'Market Type',
            options: features?.enableLimitedMarketSubscription ? accessibleMarketFolders : [
            {
                key: 'all',
                label: 'All',
                value: 'all',
            },
            {
                key: 'channels',
                label: 'Channels',
                value: 'channels',
            },
            {
                key: 'accounts',
                label: 'Accounts',
                value: 'accounts',
            },
            {
                key: 'stateLines',
                label: 'Statelines',
                value: 'stateLines',
            },
            {
                key: 'majorMarkets',
                label: 'Major Markets',
                value: 'majorMarkets',
            },
            {
                key: 'regions',
                label: 'Regions',
                    value: 'regions',
            },
            {
                key: 'divisions',
                label: 'Divisions',
                value: 'divisions',
            },
            ],
            },
        ]);

    function handleChange(e) {
        onChange(e);
    }

    return (
        <div className={classnames(baseClassName, className)}>
            <ByzzerTabs<MarketTab>
                tabsList={tabList}
                onClick={onTabChange}
                activeTab={activeMarketFolderTab}
                type='default'
            />
            {activeMarketFolderTab === 'markets' && (
                <DodFilterTypePicker
                    className={`${baseClassName}__market-type-picker`}
                    filters={filters}
                    initialOptionGroups={optionGroups}
                    onChange={handleChange}
                    filterPlaceholder={'Search Market Folders'}
                    presetType={'market'}
                    presetGroupLabel={'My Saved Markets'}
                    presetGroupTip={SAVED_MARKET_INFO}
                    {...props}
                />
            )}
            {activeMarketFolderTab === 'search' && (
                <DodMarketAdvancedSearch value={advancedSearchFilters} onChange={onAdvancedSearchFiltersChange} />
            )}
        </div>
    );
}

export default DodMarketFilterTypePicker;
