import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './FilterSummary.scss';
import { ScenarioSimulatorContext } from "../../ScenarioSimulatorContext";
import { MarketRunConfigOptions, ProductRunConfigOptions, TimeRunPeriodConfigOptions } from "@/types/RunConfigOptions";
import { ByzzerButton, ByzzerDate } from "@byzzer/ui-components";
import { getPPGDisplayValue } from "@/services/ppg.service";
import { TimePeriod } from "@/types/ReportRun";
import { formatInTimeZone } from "date-fns-tz";
import { createPpgGroupNameOption, getLabelAndTextValueForSimRunConfigFilter } from "@/views/simulators/utils";

export type FilterSummaryProps = {
    onModifyFilters?: () => void;
    formattedLatestDate?: string;
}

export function convertTimePeriodToText(tp?: TimePeriod): string { // TODO: Centralize this somewhere, as it's a similar function as used in the Reportrunconfig summary screen
    if( !tp || (( tp.type === 'omni_custom' || tp.type === 'week_ending') && ( !tp.period?.endDate ))) return '';

    let timePeriodText;

    if (tp.type === 'week_ending') {
        timePeriodText = `${tp.period?.weeks ?? 0} week${tp.period?.weeks! > 1 ? 's' : ''} ending ${formatInTimeZone(tp.period?.endDate!, 'America/New_York', 'yyyy-MM-dd')}`
    } else if( tp.type === 'omni_custom' ){               
        timePeriodText = `${tp.period?.months ?? 0} month${tp.period?.months! > 1 ? 's' : ''} ending ${formatInTimeZone(tp.period?.endDate!, 'America/New_York', 'yyyy-MM-dd')}`
    } else {
        timePeriodText = tp?.period
    }

    return timePeriodText;
}

export const FilterSummary = ({
    onModifyFilters,
    formattedLatestDate,
    ...props
}: FilterSummaryProps) => {

    const baseClassName = 'byz-filter-summary';

    const { 
        value: { 
            filterEditor: { 
                filterConfigOptions,
                filterRunConfig
            }
        }, 
        onChange: onContextChange 
    } = useContext(ScenarioSimulatorContext);

    const productConfigOptions: Partial<Omit<ProductRunConfigOptions, 'type' | 'title'>> = filterConfigOptions.find((configOptionSet) => configOptionSet.type === 'product') ?? {};
    const marketConfigOptions: Partial<Omit<MarketRunConfigOptions, 'title' | 'type'>> = filterConfigOptions.find((configOptionSet) => configOptionSet.type === 'market') ?? {};
    const timeConfigOptions: Partial<Omit<TimeRunPeriodConfigOptions, 'title' | 'type'>> = filterConfigOptions.find((configOptionSet) => configOptionSet.type === 'time_period') ?? {};

    const isRms = marketConfigOptions.datatype === 'rms';
    const isCps = marketConfigOptions.datatype === 'cps';

    const showMarketTree = (isCps || isRms);
    const showMarketSearch = showMarketTree ;

    const handleClick = async () => { // TODO: Will this function provide all of the selections from this component up to the parent?
        onModifyFilters?.();
    }

    // TODO: vvv -- DRY this section, incl the return -- vvv
    const {
        categories, brands, ppgId, markets, timePeriod
    } = filterRunConfig;

    const ppgGroupNames = [createPpgGroupNameOption(filterRunConfig.ppgGroupNames?.[0]!, ppgId!).display]; // to show the PPG group with the category on the end of the string

    return (
        <section className={classnames(`${baseClassName}__selection-summary`)}>
            <div className={classnames(`${baseClassName}__selections`)}>
                {Boolean(categories?.length && productConfigOptions.includeCategories) && <>Category(s): {getLabelAndTextValueForSimRunConfigFilter({categories}).textValue}; </> }
                {Boolean(brands?.length && productConfigOptions.includeBrands) && <>Brand(s): {getLabelAndTextValueForSimRunConfigFilter({brands}).textValue}; </> }
                {Boolean(ppgId && productConfigOptions.includePPGs) && <>PPG Definition: {getLabelAndTextValueForSimRunConfigFilter({ppgId}).textValue}; </> }
                {Boolean(ppgGroupNames?.length && productConfigOptions.includePPGDefinition) && <>PPG: {getLabelAndTextValueForSimRunConfigFilter({ppgGroupNames}).textValue}; </> }
                {Boolean(markets?.length && showMarketSearch) && <>Market: {getLabelAndTextValueForSimRunConfigFilter({markets}).textValue}; </> }
                {Boolean(timePeriod) && <>Time Period: {getLabelAndTextValueForSimRunConfigFilter({timePeriod}).textValue}; </> }
            </div>
            <div className={classnames(`${baseClassName}__toggler`)}>
                <ByzzerButton 
                    onClick={handleClick}
                    label={"Modify Filters"}
                    type={"negative"}
                />
            </div>
        </section>
    );

};

export default FilterSummary;

FilterSummary.displayName = 'FilterSummary';