import './MarketsToWatchRunConfigFiltersStep.scss';
import React, {ReactNode, useState} from "react";
import classnames from "classnames";
import {WithUid, WizardActions, WizardContent, WizardHeader, WizardStep} from "@byzzer/ui-components";
import {MarketsToWatchRunConfigFilters, MarketsToWatchRunConfigFiltersProps} from "./MarketsToWatchRunConfigFilters";

export type MarketsToWatchRunConfigFiltersStepProps = {
    title?: ReactNode;
} & MarketsToWatchRunConfigFiltersProps;

const baseClassName = 'marketsToWatch-run-config-filters-step';

export const MarketsToWatchRunConfigFiltersStep = React.forwardRef<WithUid, MarketsToWatchRunConfigFiltersStepProps>(
    ({className, title, ...props}, ref) => {

        const [filtersValid, setFiltersValid] = useState<boolean>(false);

        function handleValidationChange(e: ByzzerValidityChangeEvent) {
            setFiltersValid(e.isValid)
        }

        return (
            <WizardStep
                className={classnames(baseClassName, className)} 
                ref={ref}
                title={title}
                // forceActive={true}
            >
                <WizardHeader className={classnames({
                    'alert-run-config-wizard-header--valid': filtersValid
                })}>
                    <h1 className={'alert-run-config-wizard__step-title'}>
                        {title}
                    </h1>
                </WizardHeader>

                <WizardContent>
                    <MarketsToWatchRunConfigFilters 
                        {...props}
                        onValidityChange={handleValidationChange}
                    />
                </WizardContent>

                <WizardActions 
                    disableNext={!filtersValid}
                    nextDisabledTip={'You must choose all required values to continue.'}
                />
            </WizardStep>
        )
});


