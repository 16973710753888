import xlsx from "xlsx";
import * as _ from 'lodash';
import papa from "papaparse";
import classNames from "classnames";
import ByzzerUploadButton from "@/components/form/ByzzerUploadButton";
import React, { useDebugValue, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ByzzerButton, ByzzerLink, ByzzerSwitch } from "@/components/form";
import { ProductHierarchySelector } from "@/views/CustomCharacteristics/editor/ProductHierarchySelector";

export function UploadStep({
    onActionClick,
    categories,
    upcs,
    onCategoriesChange,
    onUpcsChange,
    onComplete,
    busy,
    isComma,
    setLoading,
    setProductHierarchy,
}) {

    const [canComplete, setCanComplete] = useState(false);
    const [importedUpcs, setImportedUpcs] = useState<any[]>([]);
    const [file, setFile] = useState<File>();
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        accept: ['.xls', '.xlsx', '.csv', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    });
    const [canRemoveCheckDigits, setCanRemoveCheckDigits] = useState(false);
    const [removeCheckDigits, setRemoveCheckDigits] = useState(false);
    const [containsComma, setContainsComma] = useState(false);

    useDebugValue(importedUpcs);
    useDebugValue(removeCheckDigits);
    useDebugValue(canRemoveCheckDigits);

    useEffect(() => {
        setCanComplete(Boolean(categories.length && importedUpcs.filter(hasValidUpc).length));
        checkComma(upcs);
    }, [categories, upcs]);

    useEffect(() => {
        if (removeCheckDigits) {
            onUpcsChange(stripCheckDigits(importedUpcs));
        } else {
            onUpcsChange(importedUpcs);
        }
    }, [removeCheckDigits, importedUpcs])

    useEffect(() => {
        const [file] = acceptedFiles;
        if (file) {
            processFile(file);
        }
        setFile(file);
    }, [acceptedFiles]);

    const onFilesSelected = (e) => {
        const [file] = e.target?.files;
        if (file) {
            processFile(file);
        }
        setFile(file);
    }

    async function processFile(file) {

        let upcs;
        const extension = file.name.split('.').pop();
        switch (file.type || extension) {
            case 'text/csv':
            case 'csv':
            case 'txt':
                upcs = await parseCsv(file);
                break;
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.ms-excel':
            case 'xlsx':
            case 'xls':
                upcs = await parseSheet(file);
                break;
            default:
                break;
        }

        if (upcs?.length) {
            // pad all upcs with leading 0s
            upcs = upcs.map(({ upc, group }) => ({
                upc: `${upc}`.padStart(12, '0'),
                group
            }));
            setCanRemoveCheckDigits(doAnyContainCheckDigits(upcs))
            setImportedUpcs(upcs);
        }
    }

    async function parseCsv(file) {
        return new Promise(resolve => {

            papa.parse(file, {
                worker: true,
                complete(results) {
                    // @ts-ignore
                    resolve(results.data.filter(([upc]) => upc?.trim()).map(([upc, group]) => ({
                        upc,
                        group
                    })));
                }
            });
        });
    }

    async function parseSheet(file) {

        return new Promise(resolve => {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                                            // @ts-ignore
                const data = new Uint8Array(reader.result);
                const workbook = xlsx.read(data, { type: 'buffer' });
                resolve(xlsx.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
                    header: ['upc', 'group']
                }))
            })
            reader.readAsArrayBuffer(file);
        });
    }

    function doAnyContainCheckDigits(upcs) {
        return upcs.some(({ upc }) => containsCheckDigit(upc));
    }

    function checkComma(upcs) {
        Object.keys(upcs).forEach((i) => {
            if (upcs[i].upc.includes(',')) {
                setContainsComma(true);
                isComma(true);
            } else
                isComma(false)
        })
    }

    function hasValidUpc({ upc }) {
        return /^\d+$/.test(upc);
    }

    function stripCheckDigits(upcs) {
        return _.cloneDeep(upcs)
            .map(entry => {
                const upc = entry.upc;
                // strip the check digit if it's included
                if (hasValidUpc(entry) && containsCheckDigit(upc)) {
                    entry.upc = upc.substr(0, 11).padStart(12, '0')
                }
                return entry;
            });
    }

    function containsCheckDigit(upc) {
        const lastDigit = +upc.substr(-1, 1);
        const leadingDigits = upc.substr(0, 11).split('');
        // multiplying every digit in an odd numbered position by 3, then sum all of the values up
        const sum = leadingDigits.reduce((total, value, i) => total + value * (i % 2 ? 1 : 3), 0)
        // mod the result by 10, if it's 0 use 0, otherwise subtract the value from 10
        const checkDigit = sum % 10 ? 10 - sum % 10 : 0;
        return checkDigit === lastDigit;
    }

    function onGuidelinesClick() {
        onActionClick('showGuidelines')
    }

    function onRemoveCheckDigitsChange({ target }) {
        setRemoveCheckDigits(target.checked);
    }

    return <div className="char-editor__do">
        <div className="char-editor__do-caption">
            You can upload a characteristic definition for the entire category or for a subset of UPCs. UPCs not in the
            list will be categorized as “NOT APPLICABLE”.
        </div>
        <div className={'char-editor__info-link'}>
            <ByzzerLink label={'View Upload Guidelines'} onClick={onGuidelinesClick} />
        </div>
        <ProductHierarchySelector onChange={onCategoriesChange}
            categories={categories}  setLoading={setLoading} setProductHierarchy={setProductHierarchy} />
        <div className="char-editor__file">
            <div {...getRootProps({
                className: classNames(
                    'char-editor__target', {
                    'char-editor__target--has-file': file
                }
                )
            })}>
                <input {...getInputProps()} />
                <span>{file ? file.name : 'Drag and drop files here to upload'}</span>
            </div>
            {/* @ts-ignore */}
            <ByzzerUploadButton type={'file'}
                maxFiles={1}
                accept={'.xls, .xlsx, .csv, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                label={'Or Select Files to Upload'}
                onChange={onFilesSelected}
                busy={busy} />

            {canRemoveCheckDigits && (
                <div className={'char-editor__check-digit-warning'}>
                    <p>Some of your UPCs appear to contain check digits, which is not supported but enabling the option
                        below will remove them for you.</p>
                    <p>It is possible to detect false check-digits, so If you are sure you did not include check digits,
                        do not enable this option.</p>
                    <ByzzerSwitch value={removeCheckDigits} onChange={onRemoveCheckDigitsChange} />
                    <span className={'char-editor__check-digit-warning-label'}>Remove Check Digits</span>
                </div>
            )}
        </div>

        <div className="char-editor__actions">
            <ByzzerButton label={'Upload'} disabled={!canComplete && !containsComma} onClick={onComplete} busy={busy} />
        </div>
    </div>
}