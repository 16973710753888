import './AlertRunConfigSummaryStep.scss';
import React, {ReactNode, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import classnames from "classnames";
import {WithUid, WizardContext, WizardStepMessageOptions} from "@byzzer/ui-components";
import AlertRunConfigSummary from "@/components/ConfigurationEditors/AlertConfigurationEditor/AlertRunConfigSummaryStep/AlertRunConfigSummary";
import {AlertRunConfig} from "@/types/AlertRun";
import {useTenantApi} from '@/hooks/useTenantApi';
import {AlertRunConfigWizardContext} from '../AlertRunConfigWizard/AlertRunConfigWizardContext';
import {WizardActions, WizardContent, WizardHeader, WizardStep} from "@byzzer/ui-components";
import {AlertRunConfigOptions} from "@/types/AlertRunConfigOptions";
import {useAlertRunService} from "@/services/alertRun.service";
import { AlertRunValidationResult } from '@/types/ApiTypes';

export type RunConfigSummaryStepProps = {
    title?: ReactNode;
    className?: string;
    onNext?: () => void;
    enabled?: boolean;
    sku?: string;
    busy?: boolean;
    busyNextText?: string;
    busyNextTooltip?: ReactNode;
    runConfigOptions?: AlertRunConfigOptions[];
    editingExistingAlert?: boolean;
}


const baseClassName = 'run-config-summary-step';

export const AlertRunConfigSummaryStep = React.forwardRef<WithUid, RunConfigSummaryStepProps>((
    {
        className,
        onNext,
        title = 'Review Alert Selections',
        sku,
        enabled,
        runConfigOptions,
        busy,
        busyNextText,
        busyNextTooltip,
        editingExistingAlert,
        ...props
    }, ref) => {
        const { validateRunConfig} = useTenantApi();
    const stepRef = useRef<any>();
    const {activeUid} = useContext(WizardContext)
    const {value: runConfig} = useContext(AlertRunConfigWizardContext);
    const {validateRunConfigForSku} = useAlertRunService();
    const [validationResult, setValidationResult] = useState<AlertRunValidationResult | null>(null);
    const [validationMessage, setValidationMessage] = useState<WizardStepMessageOptions | undefined>()
    let disableNext = !validationResult?.canRun || busy;
    const nextText = !busy ? (editingExistingAlert ? 'Save Changes' : 'Add Alert') : busyNextText ?? "Saving...";
    const lastValidatedConfig = useRef<string>('');
    let disabledTip;

    if (validationResult?.canRun === false) {
        disabledTip = 'See errors above.';
    } else if (busy) {
        disabledTip = busyNextTooltip ?? 'Saving Alert...';
    }

    let nextIconType: string | undefined;

    if (validationResult?.canRun === false) {
        nextIconType = 'error';
    } else if (busy) {
        nextIconType = 'busy';
    } else {
        nextIconType = 'ok';
    }


    useImperativeHandle(ref, () => stepRef.current, []);
    /**
     * api call to fetch if data set is present or not
     * returns boolean
     */
    const checkIfAlertDataIsValid = async () => {

        if (!runConfig) return;
        const comparableRunConfig = JSON.stringify(runConfig);

        // don't bother revalidating the config if it hasn't changed
        if (lastValidatedConfig.current === comparableRunConfig) return;

        lastValidatedConfig.current = comparableRunConfig;

        // before saving, making sure all of the required values are set.
        if (validateRunConfigForSku(sku, runConfig)) {
            setValidationMessage(undefined);
            setValidationResult({canRun: true});

        } else {
            setValidationMessage({
                type: 'error',
                content: "You cannot save this Alert because you have not selected all of the required values."
            });
            setValidationResult({canRun: false})
        }
    }

    useEffect(() => {
        if (activeUid === stepRef.current?.uid) {
            checkIfAlertDataIsValid();
        }
    }, [activeUid]);

    function handleNext(): boolean {
        onNext?.();
        return true;
    }

    return (
        <WizardStep 
            className={classnames(baseClassName, className)}
            ref={stepRef}
            enabled={enabled}
            message={validationMessage}
            title={title}
        >
            <WizardHeader>
                <h1 className={'alert-run-config-wizard__step-title'}>
                    {title}
                </h1>
            </WizardHeader>

            <WizardContent>
                <AlertRunConfigSummary runConfig={runConfig}/>
            </WizardContent>

            <WizardActions
                disableNext={disableNext}
                className={'alert-run-config-wizard__step-actions'}
                nextDisabledTip={disabledTip}
                nextIconType={nextIconType}
                nextText={nextText}
                beforeNext={handleNext}
            />
        </WizardStep>
    )
});

export default AlertRunConfigSummaryStep;

AlertRunConfigSummaryStep.displayName = 'AlertRunConfigSummaryStep';
