
import './SwitchTo.scss';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Hyper Link
 * @param {{className:string}} props Class Name prop
 * @return Hyper Link JSX
 */
export function SwitchToSignIn({ className }) {
    return (
        <div className={`${className} auth-switch-to`}>
            Already have an account?{' '}
            <Link data-test="sign-in-alt_sing_in" className={'byzzer-link'} replace to={`/auth/sign_in`}>
                Sign In
            </Link>
        </div>
    );
}
export function SwitchToSignUp(props) {
    return (
        <div className={`auth-switch-to`}>
            New to Byzzer?{' '}
            <Link data-test="sign-in-alt_sing_up" className={'byzzer-link'} replace to={`/auth/sign_up`}>
                Sign Up
            </Link>
        </div>
    );
}
