export const CASE_STUDY_SLIDES = [
    {
        case: 'case-study-1',
        slides: [
            {
                heading: 'How Serenity Kids convinced a key retailer to increase distributions',
                thumbnail: 'https://nielseniq.com/wp-content/uploads/sites/4/2022/08/Serenity.jpeg',
                link: 'https://nielseniq.com/global/en/landing-page/smb-us-serenity-kids-case-study/',
            },
            {
                heading:
                    'Shameless Pets knows that CPG data doesn’t just win you a spot on the shelf – it keeps you there.',
                thumbnail:
                    'https://nielseniq.com/wp-content/uploads/sites/4/2022/08/shameless-pets-photo-1200x800-1.jpeg',
                link: 'https://nielseniq.com/global/en/landing-page/smb-us-shameless-pets/',
            },
            {
                heading: 'The CPG data package that’s just right for emerging sandwich brand Raybern’s',
                thumbnail:
                    'https://nielseniq.com/wp-content/uploads/sites/4/2022/08/Case-Study-SRayberns-1200x800-1.jpg',
                link: 'https://nielseniq.com/global/en/landing-page/smb-us-rayberns/',
            },
        ],
    },
    {
        case: 'case-study-2',
        slides: [
            {
                heading: 'How Rebel Green found the perfect retail insights tool to gain new distribution',
                thumbnail:
                    'https://nielseniq.com/wp-content/uploads/sites/4/2022/08/Case-Study-Rebelw-Green-1200x600-1.jpg',
                link: 'https://nielseniq.com/global/en/landing-page/smb-us-rebel-green-case-study/',
            },
            {
                heading: 'How BPG International is using Byzzer™ to stay ahead of shifting market trends',
                thumbnail:
                    'https://nielseniq.com/wp-content/uploads/sites/4/2024/03/priscilla-du-preez-xcCXCRi9Xg-unsplash-e1711464354215.jpg?w=1024',
                link: 'https://nielseniq.com/global/en/landing-page/bpg-international-byzzer/',
            },
            {
                heading: 'Why Soylent Ditched Another Data Provider for Byzzer™ by NIQ',
                thumbnail: 'https://nielseniq.com/wp-content/uploads/sites/4/2023/01/Soylent-Shipping-Box.png',
                link: 'https://nielseniq.com/global/en/landing-page/us-smb-soylent/',
            },
        ],
    },
    {
        case: 'case-study-3',
        slides: [
            {
                heading: 'How Pomi USA is using Byzzer™ to get the Full View of the market',
                thumbnail:
                    'https://nielseniq.com/wp-content/uploads/sites/4/2023/07/Pomi-USA-e1689341830953.jpeg?w=1024',
                link: 'https://nielseniq.com/global/en/landing-page/how-pomi-usa-is-using-byzzer/',
            },
            {
                heading: 'How TOAST-IT Switched to Byzzer™ by NIQ and Built Better Strategies',
                thumbnail:
                    'https://nielseniq.com/wp-content/uploads/sites/4/2023/03/TOAST-IT-1-e1678127378190.jpeg?w=1024',
                link:
                    'https://nielseniq.com/global/en/landing-page/toast-it-switch-to-byzzer-by-niq-and-built-better-strategies/',
            },
            {
                heading: 'How The Fremont Company used Byzzer™ to build a stronger private label assortment',
                thumbnail: 'https://nielseniq.com/wp-content/uploads/sites/4/2023/03/Fremont-Co-Beans.jpeg?w=1024',
                link:
                    'https://nielseniq.com/global/en/landing-page/how-the-fremont-company-used-byzzer-to-build-a-stronger-private-label-assortment/',
            },
        ],
    },
    {
        case: 'case-study-4',
        slides: [
            {
                heading:
                    'How Dan-O’s Seasoning turned Byzzer™ insights into new distribution, shelf space, and innovative product development',
                thumbnail:
                    'https://nielseniq.com/wp-content/uploads/sites/4/2023/03/Dan-Os-Hero-e1679937409420.jpeg?w=1024',
                link: 'https://nielseniq.com/global/en/landing-page/dan-os-case-study/',
            },
            {
                heading: 'How Bar Keepers Friend is using Byzzer™ to unlock their assortment’s potential.',
                thumbnail: 'https://nielseniq.com/wp-content/uploads/sites/4/2024/04/BFK-History.jpeg',
                link: 'https://nielseniq.com/global/en/landing-page/bar-keepers-friend/',
            },
            {
                heading: 'Why Lafayette Imports relies on Byzzer™ data to grow their brands',
                thumbnail:
                    'https://nielseniq.com/wp-content/uploads/sites/4/2024/03/Shankys_Whip_Evergreen_5.jpg?resize=1536,864',
                link: 'https://nielseniq.com/global/en/landing-page/lafayette-imports-relies-on-byzzer/',
            },
        ],
    },
    {
        case: 'case-study-5',
        slides: [
            {
                heading: 'How Mom Bomb is using Byzzer™ to meet consumer preferences and optimize market positioning.',
                thumbnail:
                    'https://nielseniq.com/wp-content/uploads/sites/4/2024/06/Mom-Bomb-Cover-e1718282587266.jpeg',
                link: 'https://nielseniq.com/global/en/landing-page/mom-bomb-byzzer/',
            },
            {
                heading: 'Cerebelly has transformed their retailer strategy with Byzzer™.',
                thumbnail:
                    'https://nielseniq.com/wp-content/uploads/sites/4/2024/07/Cerebelly-Banner-e1720460124183.jpeg',
                link: 'https://nielseniq.com/global/en/landing-page/cerebelly-byzzer/',
            },
            {
                heading: 'How Kekoa Foods is using Byzzer™ to convince retailers and gain shelf space.',
                thumbnail: 'https://nielseniq.com/wp-content/uploads/sites/4/2024/07/Kekoa-Banner-e1720460076347.jpeg',
                link: 'https://nielseniq.com/global/en/landing-page/kekoa-foods-byzzer/',
            },
        ],
    },
];
