import React from 'react';
import { ByzzerButton } from '@/components/form';

export function ChooseEditActionStep({ onComplete, ppg }) {
    let actions;

    if (ppg?.type === 'UPC') {
        actions = (
            <>
                <ByzzerButton onClick={() => onComplete('upload-upcs')}>Upload New UPC-PPG List</ByzzerButton>
                <ByzzerButton onClick={() => onComplete('change-rules')}>Change Rules For Remaining UPCs</ByzzerButton>
                <ByzzerButton onClick={() => onComplete('remove-upcs')}>
                    Change To Characteristic-Based PPGs
                </ByzzerButton>
            </>
        );
    } else {
        actions = (
            <>
                <ByzzerButton onClick={() => onComplete('change-rules')}>Change Characteristic Rules</ByzzerButton>
                <ByzzerButton onClick={() => onComplete('upload-upcs')}>Change To UPC Upload</ByzzerButton>
            </>
        );
    }

    return (
        <div className={'ppg-editor__content'}>
            <div className="ppg-editor__actions">{actions}</div>
        </div>
    );
}
