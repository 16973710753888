import './MarketRunConfigFilters.scss';
import React, {forwardRef, ReactNode, useContext, useEffect, useImperativeHandle, useRef, useState, ChangeEvent} from "react";
import classnames from "classnames";
import ByzzerMarketSearch from "@/components/MarketSelection/ByzzerMarketSearch/ByzzerMarketSearch";
import {AlertRunConfig} from "@/types/AlertRun";
import {
    AlertRunConfigWizardContext,
    AlertRunWizardState
} from "@/components/ConfigurationEditors/AlertConfigurationEditor/AlertRunConfigWizard/AlertRunConfigWizardContext";
import {MarketRunConfigOptions} from "@/types/RunConfigOptions";
import {FilterGroup, FilterSection} from '@/components/ConfigurationEditors/FilterGroup';
import {MarketPicker} from "@/components/MarketPicker";
import { ByzzerTipIcon} from "@byzzer/ui-components";
import {LimitedLabel} from "@/components/LimitedLabel";
import {useUser} from "@/contexts/UserContext";
import {ByzzerSwitch} from "@/components/form";
import { SelectorLabelInfo } from '@/components/SelectorLabelInfo';

export type MarketRunConfigFiltersValue = Partial<AlertRunConfig>;

export type MarketRunConfigFiltersProps =
    {
        name?: string;
        onValidityChange?: (e: ByzzerValidityChangeEvent) => void;
        /**
         * Used when MarketRunConfigFilters is used as controlled component.  Should be used along with the value prop.
         * This prop should be omitted when used within a AlertRunConfigWizardContext because the value in the context will
         * always take precedence.
         */
        onChange?: (e: ByzzerChangeEvent<MarketRunConfigFiltersValue>) => void;
        /**
         * Used when MarketRunConfigFilters is used as controlled component.  Should be used along with the onChange prop.
         */
        value?: MarketRunConfigFiltersValue;
        summary?: ReactNode;
        datatype?: any;
    }
    & Partial<Omit<MarketRunConfigOptions, 'title' | 'type'>>
    & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export type MarketRunConfigFiltersRef = {
    value: MarketRunConfigFiltersValue;
    isValid: boolean;
};

const baseClassName = 'market-alert-run-filters';

export const MarketRunConfigFilters = forwardRef<MarketRunConfigFiltersRef, MarketRunConfigFiltersProps>((
    {
        className,
        value,
        name,
        onChange,
        onValidityChange,
        datatype,
        maxMarkets = Infinity,
        limitMarketsToParentCompany,
        includeTotalUSMarkets = false,
        includeFmcgRetailers = false,
        includeSpecialityRetailers = false,
        includeGeographyMarkets = false,
        excludeRemainingMarkets = false,
        requireRemainingMarket = false,
        /* BYZ-8104- we have added "hideTotalUSMarkets" flag to alby to hide and show the total US markets but at the same time we can use them as comparitive market if main market has US market as remaning or comparitive market.
         the same flag has been added in market.services to restrict the user from Total US markets search and market picker to wrap total US market tree with "hideTotalUSMarkets" flag. */
        hideTotalUSMarkets = false,

        requireTotalUSMarkets = false,
        requireFmcgRetailers = false,
        requireSpecialityRetailers = false,
        requireGeographyMarkets = false,

        ...props
    }, ref) => {

    const {company, features: userFeatures = {}} = useUser();
    const {value: contextValue, onChange: onContextChange, sku, runType} = useContext(AlertRunConfigWizardContext);
    const [internalValue, setInternalValue] = useState<MarketRunConfigFiltersValue>({});
    const [state, setState] = useState<AlertRunWizardState>({
        showRemainingMarkets: false
    });
    const categoriesRef = useRef<string[]>([]);
    const wasValid = useRef<boolean>(false);
    const isOmni = datatype === 'omni';
    const isRms = datatype === 'rms';
    const isCps = datatype === 'cps';

    // todo: maybe this should be flag defined in Alby (but then again, maybe not)
    const limitMarketsToSameGroup = isCps;
    const showMarketTree = true;
    const showMarketSearch = true;
    // const categoriesRefreshKey = `${contextValue.productSelections?.map(({categories}) => categories).flat()?.join()}` && `${contextValue?.categories?.join()}`;
    const categoriesRefreshKey = `${contextValue.categories?.sort().join()}`


    useEffect(() => {

        const isValid = checkValidity();

        if (wasValid.current !== isValid) {
            onValidityChange?.({
                name,
                isValid
            });

            wasValid.current = isValid;
        }

    }, [internalValue.markets, internalValue.marketsToWatch]);

    // this could be problematic and lead to issues where the context and explicit value collide.
    // todo: find a clear way to choose which takes precedence context or value
    useEffect(() => {
        setInternalValue(contextValue ?? {})
    }, [contextValue]);

    useEffect(() => {
        if (value) {
            setInternalValue(value ?? {});
        }
    }, [value]);

    useEffect(() => {
        categoriesRef.current = [...new Set([
            ...(contextValue.productSelections?.flatMap(({categories}) => categories) ?? []), 
            ...(contextValue.categories ?? [])
        ])].filter(Boolean);
    }, [contextValue.productSelections, contextValue.categories]);

    useEffect(() => {

        const firstMarket = contextValue.markets?.[0];
        const requiredMasterCompany = limitMarketsToParentCompany ? firstMarket?.masterCompany : undefined;
        const requiredMarketGroup = limitMarketsToSameGroup ? firstMarket?.marketGroup : undefined;

        setState(state => ({
            ...state,
            requiredMasterCompany,
            requiredMarketGroup,
        }));
    }, [contextValue.markets]);

    function handleChange(e: ByzzerChangeEvent<any>) {
        onContextChange?.(e.name as keyof AlertRunConfig, e.value, e.data);
    }

    function checkValidity(): boolean {
        const hasMarkets = Boolean(internalValue.markets?.filter(item=>!item.disabledMarketNode)?.length);
        const requiresStandardMarkets = (isRms);
        const requiresMarkets = requiresStandardMarkets;

        // Remaining markets are now paired/bundled with their base market.  Alerts from history don't need to be checked.

        // @ts-ignore
        return (
            (!requiresMarkets || hasMarkets)
        );
    }

    function handleIncludeRemainingChange(e: ChangeEvent<HTMLInputElement>) {
        setState(state => ({
            ...state,
            showRemainingMarkets: e.target.checked
        }))
    }

    useImperativeHandle(ref, () => ({
        get value() {
            return internalValue;
        },
        get isValid() {
            return checkValidity();
        },
    }));


    return (
        <FilterGroup className={classnames(baseClassName, className)}>
            <FilterSection onlyRenderIf={isCps || isRms}>
                {!requireRemainingMarket && (
                    <SelectorLabelInfo selectorCode={'focusMarkets'} max={maxMarkets} sku={sku!} isLabelBold={true} />
                )}

                {requireRemainingMarket && (
                    <SelectorLabelInfo selectorCode={'remainingMarkets'} max={maxMarkets} sku={sku!} isLabelBold={true}/>
                )}

                {showMarketTree && !excludeRemainingMarkets && !requireRemainingMarket && (
                    <ByzzerSwitch
                        className={`${baseClassName}__remaining-market-toggle`}
                        onChange={handleIncludeRemainingChange}
                    >
                        <SelectorLabelInfo sku={sku!} selectorCode={'remainingMarketToggle'} tipDelay={[150, 0]} max={maxMarkets} isLabelBold={true} />
                    </ByzzerSwitch>
                )}

                {showMarketSearch && (
                    <ByzzerMarketSearch
                        name={'markets'}
                        productSku={sku}
                        categories={categoriesRef.current}
                        requiredMasterCompany={state.requiredMasterCompany}
                        requiredMarketGroup={state.requiredMarketGroup}
                        requireRemainingMarket={requireRemainingMarket}
                        showRemainingMarkets={state.showRemainingMarkets}
                        value={contextValue.markets}
                        maxSelections={maxMarkets}
                        onChange={handleChange}
                    />
                )}

                {showMarketTree && (
                    <MarketPicker
                        key={categoriesRefreshKey}
                        value={contextValue.markets}
                        onChange={handleChange}
                        maxSelections={maxMarkets}
                        categories={categoriesRef.current}
                        requiredMasterCompany={state.requiredMasterCompany}
                        requiredMarketGroup={state.requiredMarketGroup}
                        requireRemainingMarket={requireRemainingMarket}
                        name={'markets'}
                        productSku={sku}
                        runType={runType}
                        hideTotalUSMarkets={hideTotalUSMarkets}
                        showRemainingMarkets={state.showRemainingMarkets}
                        displayMarketGroup={datatype}
                        purchasedMarketKeys={company?.purchasedMarketKeys}
                        filterType='all'
                    />
                )}
            </FilterSection>
        </FilterGroup>
    );
})

MarketRunConfigFilters.displayName = 'MarketRunConfigFilters'
