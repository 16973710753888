import React, { useState, useEffect } from 'react';
import ReportInformation from '@/views/ReportInformation';
import { reportDescriptionUrl } from '@/config/globalVars';
import './ReportViewer/ReportFilterOverlay.scss';
import classnames from "classnames";
import upArrow from '@images/icons/up-arrow.svg';

const baseClassName = 'report-filters-overlay';

const ReportInsights = ({
    reportConfig,
    sku,
    lookerReportUrl,
    show = false,
    ...props
}) => {
    return (
        <div className={classnames(`${baseClassName}`, {
            [`${baseClassName}--open`]: show
        })}>
            <div
                onClick={() => props.toggleReportInsightPanel()}
                title="Report Info"
                className={`${baseClassName}--close`}>
                <div className={`${baseClassName}--close-label`}>Close </div>
                <img src={upArrow} />
            </div>
            <div></div>
            <div></div>
            <div>
                <ReportInformation
                    sku={sku}
                    reportWrapper={true}
                    // readonly={!canRunReports}
                    url={
                        `${reportDescriptionUrl}/${sku}.html`
                    }
                // runReport={() => onReportClick(selectedReport)}
                /></div>
        </div>
    );
};
export default ReportInsights;
