import './SystemMaintenance.scss';
import { PropsWithChildren, useEffect, useState } from "react";
import {useApp} from "@/contexts/UserContext";
import { useLocation } from "react-router-dom";
import Maintenance from "@/pages/Maintenace";
import firebase from "firebase/compat/app";
import DataRefreshMaintenance from "@/pages/DataRefreshMaintenance";

const baseClassName = 'system-maintenance'

type DataRefreshSkuItem = {
    sku: string;
    eta: firebase.firestore.Timestamp;
}
type DataRefreshInfo = {
    sku: string;
    eta?: Date;
}
type MaintenanceInfo = {
    status: boolean;
    eta?: Date;
}

export function SystemMaintenance({children}: PropsWithChildren) {

    const {isCsrObo, subscription, company, user} = useApp();
    const {pathname} = useLocation();
    const oboLoginPagePaths = ['/auth/csr_sign_in', '/csronbehalfof'];
    const [dataRefreshSkuList, setDataRefreshSkuList] = useState<DataRefreshSkuItem[]>([]);
    const [isInMaintenanceMode, setIsInMaintenanceMode] = useState<boolean>(false);
    const [dataRefreshInfo, setDataRefreshInfo] = useState<DataRefreshInfo | undefined>()
    const [maintenanceInfo, setMaintenanceInfo] = useState<MaintenanceInfo | undefined>();

    useEffect(() => {

        try {
            const maintenanceDoc = firebase.firestore().collection('systemEvents')
                .withConverter<MaintenanceInfo>({
                    toFirestore(data): firebase.firestore.DocumentData {
                        return data;
                    },
                    fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot) {
                        const info = snapshot.data();
                        let eta: Date | undefined
                        if (info.eta) {
                            eta = new Date(0) // epoch date
                            eta.setSeconds(info.eta.seconds)
                        }
                        info.eta = eta;
                        return info as MaintenanceInfo;
                    }
                }).doc('maintenance');
            maintenanceDoc.onSnapshot((doc) => {
                const maintenanceInfo = doc.data()!;
                if (maintenanceInfo?.status) {
                    setMaintenanceInfo(maintenanceInfo);
                    return;
                }
                setMaintenanceInfo(undefined);
            });
        } catch (err) {
            console.error('maintenance event listener failed');
        }

        try {

            const dataRefreshMaintenanceDoc = firebase
                .firestore()
                .collection('systemEvents')
                .doc('data_refresh_maintenance');
            dataRefreshMaintenanceDoc.onSnapshot((doc) => {
                const skuList = doc.data()?.skuList ?? [];
                setDataRefreshSkuList(skuList);
            });
        } catch (err) {
            console.error('data refresh maintenance event listener failed');
        }
    }, []);

    useEffect(() => {
        const goInToMaintenanceMode = Boolean(maintenanceInfo?.status) && !isCsrObo && !oboLoginPagePaths.includes(pathname)
        if (isInMaintenanceMode && !goInToMaintenanceMode) {
            // this indicates that app is going out of maintenance mode now
            // hence reload the page to make sure any missing data due to maintenance is fetched
            window.location.reload()
        }
        setIsInMaintenanceMode(goInToMaintenanceMode)
    }, [isCsrObo, pathname, maintenanceInfo])
    useEffect(() => {
        if (company && subscription && dataRefreshSkuList.length) {
            checkDataRefreshMaintenanceStatus();
        } else {
            setDataRefreshInfo(undefined)
        }
    }, [user, subscription, dataRefreshSkuList]);

    function checkDataRefreshMaintenanceStatus() {

        const refreshInfo = dataRefreshSkuList.find(({sku}) => sku === subscription?.sku);
        if (refreshInfo && company?.accessLevel === 'banks_finance') {
            let eta: Date | undefined
            if (refreshInfo.eta) {
                eta = new Date(0) // epoch date
                eta.setSeconds(refreshInfo.eta.seconds)
            }
            setDataRefreshInfo({sku: refreshInfo.sku, eta});
        } else {
            setDataRefreshInfo(undefined);
        }
    }

    if (dataRefreshInfo) {
        return <DataRefreshMaintenance eta={dataRefreshInfo?.eta}/>
    }

    if(isInMaintenanceMode) {
        return <Maintenance eta={maintenanceInfo?.eta}/>
    }

    return <>{children}</>;
}

export default SystemMaintenance;
