import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { useBetterNavigate, useSetState } from '@/utils/utils';
import className from 'classnames';
import { ByzzerButton } from '@/components/form';
import { useLocation } from 'react-router-dom';
import './BuildStory.scss';
import ByzzerStep from '@/components/form/ByzzerStep';
import ByzzerStepper from '@/components/form/ByzzerStepper';
import back from '@images/icons/back-icon.svg';
import {useTenantApi} from '@/hooks/useTenantApi';
import DashboardContent from '@/components/dashboard/DashboardContent';
import OutletWithProps from '@/components/OutletWithProps';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import classnames from 'classnames';
import { useUser, useEvents } from '@/contexts/UserContext';
import { filterPanelsByObjectives, clearPanelsData } from '@/utils/storiesUtil';
import { confirm } from '@/components/form/ByzzerModal';
import {
    objectivesModifyPanelSkus,
    storySkus,
    selectorLabels,
    selectorStates,
    storySkusForInsightValidation
} from '@/config/globalVars';
import StoryValidationProgress from '@/components/story/storyLoader/StoryValidationProgress';

const Steps = {
    ObjectiveSelection: { nextStep: 'ObjectivePreview', path: 'objective_selection', stepNo: 1 },
    ObjectivePreview: {
        nextStep: 'DataSpecification',
        prevStep: 'ObjectiveSelection',
        path: 'objective_preview',
        stepNo: 2,
    },
    DataSpecification: {
        nextStep: 'RunStory',
        prevStep: 'ObjectivePreview',
        action: 'validation',
        path: 'data_specification',
        stepNo: 3,
    },
    RunStory: { prevStep: 'DataSpecification', path: 'run_story', stepNo: 4 },
};

const BuildStory = () => {
    const { getMySubscriptionUsage, getUserPreferences } = useTenantApi();
    const { state } = useLocation();
    const { user, company } = useUser();
    const userDataRef = useRef();
    userDataRef.current = state?.storyConfig;
    const selectorConfigsData = userDataRef.current?.storyConfig?.metadata?.env?.reportSelectors ?? userDataRef.current?.storyConfig?.metadata?.reportSelectors;
    const reportRunsRef = useRef();
    reportRunsRef.current = state?.remainingReportRuns;
    const runValueRef = useRef();
    runValueRef.current =
        userDataRef.current?.storyConfig?.reportDetails?.reportRunValue ??
        userDataRef.current?.storyConfig?.reportRunValue;
    const stepInfo = state?.from === 'editStory' || state?.from === 'viewStories' ? 'RunStory' : '';
    const [currentStep, setCurrentStep] = useState(stepInfo);
    const [activeStep, setActiveStep] = useState(JSON.parse(localStorage['activeStep']));
    const [remainingCoreRuns, setRemainingCoreRuns] = useState();
    const [objectivesHasChanged, setObjectivesHasChanged] = useState(true);
    const headerText = useRef();
    headerText.current = userDataRef.current?.storyConfig?.title ?? state?.storyTitle ?? 'Story';
    const navigate = useBetterNavigate();
    const wrapperClass = className('my-story');
    const steps = ['ObjectiveSelection', 'ObjectivePreview', 'DataSpecification', /*'dataSneakPeak',*/ 'BuildStory'];
    const [loading, setLoading] = useState(false);
    const [storyProcessingFlag, setStoryProcessingFlag] = useState(false);
    const [marketGroup, setMarketGroup] = useState('');
    let extras;
    const sku = localStorage.getItem('storySku');
    // followign object holds all user selections for the step 3 in stories 
    const [selectorStateInitial, setSelectorStateInitial] = useSetState({
        saveDraftEnabled: false,
        activeCollapsePanel: [],
        focusMarket: '',
        focusMarketsSelectedData: [],
        focusMarketsPerformanceSelectedData: [],
        comparisonMarketsSelectedData: [],
        timePeriodWeeks: 0,
        timePeriodEndDate: '',
        selectorsStatus: [],
        dimension: '',
        focusProductSelections: {},
        focusMarketSelections: {},
        marketPerformanceSelections: {},
        marketsToWatchSelection: {},
        marketSelectionsSummary: {},
        timePeriodSelections: {},
        refreshMarkets: false,
        refreshCharacteristicFeature: false,
        refreshComparisonMarkets: false,
        characteristicSelections: '',
        characteristicOptions: [],
        comparisonMarketSelections: {},
        panelTimePeriodSelections: {},
        selectedMarketItemLvlData: {},
        selectedMarketPerformanceItemLvlData: {},
        marketOption: '',
        totalMarket: {},
        subMarket: {},
        shopperSegmentSelections: {},
    });
    const [userPrefData, setUserPrefData] = useState(null);
    let fromBack = JSON.parse(localStorage.getItem('fromBack'));
    let dataSpec = JSON.parse(localStorage['dataSpecification'] || '[]');
    let selectorStateVal = state?.from === 'editStory' || state?.from === 'viewStories' || fromBack ? dataSpec : {};
    const [selectorStateData, setSelectorStateData] = useState(selectorStateVal);
    const [dataSpecificationValues, setDataSpecificationValues] = useState(fromBack ? dataSpec : []);
    const dataSpecificationRef = useRef();
    dataSpecificationRef.current = dataSpecificationValues;
    const storycountDetail =
        state?.from === 'editStory' || state?.from === 'viewStories'
            ? { userData: state?.storyConfig?.storyConfig }
            : state?.storyConfig ?? {};
    const [storyCountDetails, setstoryCountDetails] = useState(storycountDetail);
    let selectors = JSON.parse(localStorage['storySelectors'] || '[]');
    const [storySelectors, setStorySelectors] = useState(
        state?.from === 'editStory' || state?.from === 'viewStories' ? selectors : []
    );
    const storySelectorsRef = useRef();
    storySelectorsRef.current = storySelectors;
    let pannelStatus = state?.from === 'editStory' || state?.from === 'viewStories' ? true : false;
    const [panelStatusCompleted, setPanelStatusCompleted] = useState(pannelStatus);
    const [disableNextBtn, setDisableNextBtn] = useState(false);

    const events = useEvents();
    const objectivesListFromConfig = userDataRef.current?.storyConfig?.metadata?.env?.objective_lists ?? userDataRef.current?.storyConfig?.metadata?.objective_lists;

    const [validationInProgress, setValidationInProgress] = useState(false);
    /*Commented this logic because default/preference are not meant to be retained for stories 
    (thats the expected functionality because of the distinct nature of selectors in every story )  */
    // useEffect(() => {
    // if (!fromBack) getAndSetUserPrefs();
    // }, [])
    useEffect(() => {
        initialize();
    }, [events]);

    useEffect(() => {
        const userSelectorData = {
            timePeriodSelections: {
                ...selectorStateInitial?.timePeriodSelections,
                selectionType: 'pre-defined',
                timePeriod: userPrefData?.defaultTimePeriod,
                endDate: '',
                weeks: '',
            },
            focusProductSelections: {
                ...selectorStateInitial?.focusProductSelections,
                focusBrand: userPrefData?.focusBrand ? userPrefData?.focusBrand : [],
                competitiveProductSet: {
                    ...selectorStateInitial?.focusProductSelections?.competitiveProductSet,
                    categories: userPrefData?.primaryCategory,
                },
                customCharacteristicFilters: userPrefData?.characteristicFilters,
            },
            focusMarket: ''
        };
        if (!fromBack && userPrefData) setSelectorsData(userSelectorData);
    }, [userPrefData]);
    const initialize = async () => {
        try {
            setLoading(true);
            const { basicReports } = await getMySubscriptionUsage();
            setRemainingCoreRuns(basicReports ? basicReports.limit - basicReports.used : 0);
            reportRunsRef.current = basicReports ? basicReports.limit - basicReports.used : 0;
        } finally {
            setLoading(false);
        }
    };

    const saveStoryProcessingFlag = (flag = false) => {
        setStoryProcessingFlag(flag);
    };

    const validateReportCountAndShow = async (storyConfig) => {
        let details;
        details = {
            from: 'stories',
            storyType: storyConfig.reportType,
            userData: {
                ...storyConfig,
            },
        };
        if (details) {
            setstoryCountDetails(details);
            loadInitialDataSpecification(details);
        }
    };

    const subTitle = useMemo(() => {
        return (
            <>
                {state?.from !== 'viewStories' && (
                    <p>
                        This story will deduct {runValueRef.current} core report{' '}
                        {runValueRef.current > 1 ? `runs` : `run`} from your team, after which your team will have{' '}
                        {reportRunsRef.current - runValueRef.current} report{' '}
                        {reportRunsRef.current - runValueRef.current > 1 ? `runs` : `run`}.
                    </p>
                )}

                <div className="story-stepper">
                    <div className="story-step">
                        <ByzzerStepper>
                            {steps.map((label, index) => {
                                const indexVal = index + 1;
                                return (
                                    <ByzzerStep
                                        key={index}
                                        stepNumber={indexVal}
                                        active={activeStep === indexVal}
                                        done={activeStep > indexVal}
                                    ></ByzzerStep>
                                );
                            })}
                        </ByzzerStepper>
                    </div>
                </div>
            </>
        );
    }, [userDataRef.current]);

    const goBack = async () => {
        if (activeStep !== 1) {
            let prevStep = Steps[currentStep].prevStep;
            const stepNo = activeStep - 1;
            setActiveStep(stepNo);
            localStorage['activeStep'] = JSON.stringify(stepNo);
            navigate(
                '/dashboard/story_builder/' + Steps[prevStep].path,
                {
                    state: {
                        from: 'stories',
                        storyConfig: userDataRef.current,
                        remainingReportRuns: reportRunsRef.current,
                    },
                },
                { replace: true }
            );
        } else {
            navigate('/dashboard/stories/library');
        }
    };

    if (!storyProcessingFlag) {
        extras = (
            <div className="build-story-back-button">
                <div className="story-run-selector___back">
                    <div className="story-run-selector___back-container" onClick={() => goBack()}>
                        <div className="story-run-selector___back-image">
                            <img src={back} alt="back" />
                        </div>
                        <div className="report-story-selector___back-text">Back</div>
                    </div>
                </div>
            </div>
        );
    }

    const onStepLoad = useCallback((step) => {
        setCurrentStep(step);
    }, []);

    const getAndSetUserPrefs = async () => {
        const userPrefResp = await getUserPreferences();
        setUserPrefData(userPrefResp);
    };
    async function next() {
        let nextStep = Steps[currentStep].nextStep;
        if (Steps[nextStep].action === 'validation') {
            let config = userDataRef.current?.storyConfig?.reportDetails ?? userDataRef.current?.storyConfig;
            await validateReportCountAndShow(config);
            if (objectivesModifyPanelSkus.includes(sku)) await filterDataSpecificationPanels();

        }
        if (Steps[nextStep].path === 'run_story') {
            localStorage['storiesTraining'] = JSON.stringify(
                userDataRef.current?.storyConfig?.metadata?.env?.storiesTraining ??
                userDataRef.current?.storyConfig?.metadata?.storiesTraining
            );
        }

        if (Steps[currentStep].path === 'data_specification') {
            await storyInsightsValidation();
            setObjectivesHasChanged(false);
        }
        if (Steps[currentStep].path === 'objective_selection') {
            changeSteps();
        }
    }

    const changeSteps = async () => {
        let nextStep = Steps[currentStep].nextStep;
        const stepNo = activeStep + 1;
        setActiveStep(stepNo);
        localStorage['activeStep'] = JSON.stringify(stepNo);

        navigate(
            '/dashboard/story_builder/' + Steps[nextStep].path,
            {
                state: {
                    from: 'stories',
                    storyConfig: userDataRef.current,
                    remainingReportRuns: reportRunsRef.current,
                },
            },
            { replace: true }
        );
    };

    const storyInsightsValidation = async () => {
        setValidationInProgress(true);
        let selectedObjectives = JSON.parse(localStorage['selectedObjectives'] || '[]');
        let storyType =
            userDataRef.current?.storyConfig?.metadata?.env?.storyType ??
            userDataRef.current?.storyConfig?.metadata?.storyType;
        let warningMsg = '';
        // check for stories that require insight validation 
        if (dataSpec !== undefined && dataSpec.length !== 0 && storySkusForInsightValidation.includes(sku)) {
            // [warningMsg] = await Promise.all([getInsightsWarningMsg(dataSpec, selectedObjectives, sku, storyType)]);
        }
        setValidationInProgress(false);

        if (warningMsg !== '') {
            if (
                await confirm({
                    closeButton: false,
                    className: 'my-story__warning-container',
                    content: <div>{warningMsg}</div>,
                    yesLabel: 'Yes, run the story',
                    noLabel: 'No, I will change selections',
                })
            ) {
                changeSteps();
            }
        } else {
            changeSteps();
        }
    };

    const filterDataSpecificationPanels = async () => {
        let editStory = JSON.parse(localStorage.getItem('editStory') || 'false');
        let viewStory = JSON.parse(localStorage.getItem('viewStory') || 'false');
        const selectedObjectives = JSON.parse(localStorage['selectedObjectives'] || '[]');
        let selectorStateInitialTemp =
            editStory || (viewStory && dataSpecificationValues?.from !== 'subscription')
                ? { ...selectorStateData }
                : { ...selectorStateInitial };
        let storySelectorsTemp = storySelectorsRef.current;
        let selectorStateDataTemp = { ...selectorStateData };
        let dataSpecificationValuesTemp = { ...dataSpecificationValues };

        // Category review story 
        if (sku === storySkus.categoryReview) {
            let filter1 = selectedObjectives.filter((elem) => elem.slideName === 'objective_3'); // remove characteristic Sel
            let filter2 = selectedObjectives.filter((elem) => elem.slideName === 'objective_6'); // remove comparison Market
            let filter3 = selectedObjectives.filter((elem) => elem.slideName === 'objective_9'); // remove panel time period

            let data = {
                storySelectorsTemp,
                selectorStateInitialTemp,
                selectorStateDataTemp,
                dataSpecificationValuesTemp,
                editStory,
                viewStory,
            };
            data.previousSelectorLabelname = selectorLabels.product;
            data = filterPanels(filter1, selectorLabels.characteristic, data);
            data.previousSelectorLabelname = selectorLabels.focusMarket;
            data = filterPanels(filter2, selectorLabels.comparisonMarket, data);
            data.previousSelectorLabelname = selectorLabels.timePeriod;
            data = filterPanels(filter3, selectorLabels.panelTimePeriod, data);
            setStorySelectors(data?.storySelectorsTemp);
        }
        if (sku === storySkus.categoryTrendAnalysis) {
            let filter1 = selectedObjectives.filter((elem) => elem.slideName === 'objective_2'); // remove characteristic Sel


            if (filter1.length === 0) {
                selectorStateInitialTemp.selectorsStatus = filterPanelsByObjectives(sku, storySelectorsTemp, selectorLabels.shopperSegment);
                storySelectorsTemp = filterPanelsByObjectives(sku, storySelectorsTemp, selectorLabels.shopperSegment);
                setSelectorStateInitial(selectorStateInitialTemp);
                setStorySelectors(storySelectorsTemp);
            }

        }
        // Brand review story 
        if (sku === storySkus.brandReview) {
            let filter1 = selectedObjectives.filter((elem) => elem.slideName === 'objective_4'); // remove characteristic Sel
            // if objective 4 is not present, remove market 2A,B Panels
            if (filter1.length === 0) {
                storySelectorsTemp = filterPanelsByObjectives(sku, storySelectorsTemp);
                // before data retention
                if (!fromBack) {
                    selectorStateInitialTemp.selectorsStatus = filterPanelsByObjectives(sku, selectorStateInitialTemp?.selectorsStatus);
                    clearPanelsData(selectorStateInitialTemp, 'marketPerformance');
                    setSelectorStateInitial(selectorStateInitialTemp);
                }
                // after data retention
                if (fromBack) {
                    let temp = filterPanelsByObjectives(sku, selectorStateData.selectorsStatus);
                    // if market panel is present, remove panel
                    if (temp.length !== 0) {
                        selectorStateDataTemp.selectorsStatus = filterPanelsByObjectives(sku, selectorStateDataTemp.selectorsStatus)
                        clearPanelsData(selectorStateDataTemp, 'marketPerformance');
                        setSelectorStateData(selectorStateDataTemp);
                        localStorage['dataSpecification'] = JSON.stringify(selectorStateDataTemp);  // data for payload
                        if (!(editStory || (viewStory && dataSpecificationValues?.from !== 'subscription'))) {
                            dataSpecificationValuesTemp.userData.reportParams = selectorStateDataTemp;
                            setDataSpecificationValues(dataSpecificationValuesTemp); // data sent to page 5
                        }
                    }
                }
            }
            // if objective 4 is present
            else {
                if (fromBack) {
                    let temp = filterPanelsByObjectives(sku, selectorStateData.selectorsStatus, '', true);
                    // check if panel does not exist already and data is retained
                    if (temp.length === 0 && !selectorStateDataTemp.selectorsStatus.some(e => e.selectorName === selectorLabels.marketPerformanceObjective)) {
                        let selectorsStatusObj = {
                            selectorName: selectorLabels.marketPerformanceObjective,
                            status: selectorStates.pending,
                        };
                        let index = selectorStateDataTemp.selectorsStatus.findIndex(
                            (elem) => elem.selectorName === selectorLabels.focusMarket
                        );
                        selectorStateDataTemp.selectorsStatus.splice(index + 1, 0, selectorsStatusObj);
                        clearPanelsData(selectorStateDataTemp, 'marketPerformance');
                        selectorStateDataTemp.activeCollapsePanel = [];
                        if (!(editStory || (viewStory && dataSpecificationValues?.from !== 'subscription'))) {
                            dataSpecificationValuesTemp.userData.reportParams = selectorStateDataTemp;
                            setDataSpecificationValues(dataSpecificationValuesTemp); // data sent to page 5
                        }
                        setSelectorStateData(selectorStateDataTemp);
                        setPanelStatusCompleted(false);
                        setDisableNextBtn(true);
                    }
                }
            }
            setStorySelectors(storySelectorsTemp);
        }
    };

    const filterPanels = (filter, selectorLabelName, data) => {
        let {
            storySelectorsTemp,
            selectorStateInitialTemp,
            selectorStateDataTemp,
            dataSpecificationValuesTemp,
            editStory,
            viewStory,
            previousSelectorLabelname
        } = data;
        // if objective 3 is not present, remove characteristicFeature
        if (filter.length === 0) {
            storySelectorsTemp = storySelectorsTemp.filter(
                (elem) => elem.selectorLabelName !== selectorLabelName
            );
            // before data retention
            if (!fromBack) {
                selectorStateInitialTemp.selectorsStatus = filterPanelsByObjectives(sku, selectorStateInitialTemp.selectorsStatus, selectorLabelName);
                clearPanelsData(selectorStateInitialTemp, selectorLabelName)
                setSelectorStateInitial(selectorStateInitialTemp);
            }
            // after data retention
            if (fromBack) {
                let temp = filterPanelsByObjectives(sku, selectorStateData.selectorsStatus, selectorLabelName, true)
                // if charsel panel is present, remove panel
                if (temp.length !== 0) {
                    selectorStateDataTemp.selectorsStatus = filterPanelsByObjectives(sku, selectorStateInitialTemp?.selectorsStatus, selectorLabelName);
                    clearPanelsData(selectorStateDataTemp, selectorLabelName);
                    setSelectorStateData(selectorStateDataTemp);
                    if (!(editStory || (viewStory && dataSpecificationValues?.from !== 'subscription'))) {
                        dataSpecificationValuesTemp.userData.reportParams = selectorStateDataTemp;
                        setDataSpecificationValues(dataSpecificationValuesTemp); // data sent to page 5
                    }
                }
            }
        }
        // if objective 3 is present
        else {
            if (fromBack) {
                let temp = filterPanelsByObjectives(sku, selectorStateData.selectorsStatus, selectorLabelName, true);
                // check if panel does not exist already and data is retained
                if (temp.length === 0) {
                    let selectorsStatusObj = {
                        selectorName: selectorLabelName,
                        status: selectorStates.pending,
                    };
                    let index = selectorStateDataTemp.selectorsStatus.findIndex(
                        (elem) => elem.selectorName === previousSelectorLabelname
                    )
                    // if (selectorLabelName !== selectorLabels.panelTimePeriod) {
                    //     selectorStateDataTemp.selectorsStatus[index].status = selectorStates.pending; // turn product sel as pending too
                    // }
                    selectorStateDataTemp.selectorsStatus.splice(index + 1, 0, selectorsStatusObj);
                    clearPanelsData(selectorStateDataTemp, selectorLabelName);
                    selectorStateDataTemp.activeCollapsePanel = [];
                    if (!(editStory || (viewStory && dataSpecificationValues?.from !== 'subscription'))) {
                        dataSpecificationValuesTemp.userData.reportParams = selectorStateDataTemp;
                        setDataSpecificationValues(dataSpecificationValuesTemp); // data sent to page 5
                    }
                    setSelectorStateData(selectorStateDataTemp);
                    setPanelStatusCompleted(false);
                    setDisableNextBtn(true);
                }
            }
        }
        return {
            storySelectorsTemp,
            selectorStateInitialTemp,
            selectorStateDataTemp,
            dataSpecificationValuesTemp,
            editStory,
            viewStory
        }
    }

    function loadInitialDataSpecification(details) {
        let storySelProps = details?.userData?.reportSelectors;
        if (storySelProps) {
            const mrktGroupSel = storySelProps.find((item) => item.selectorType === 'market');
            const marketGroup = mrktGroupSel?.subSelectors?.markets?.marketGroup;
            setMarketGroup(marketGroup);
        }

        if (['retailers', 'retailer'].indexOf(company?.accessLevel.toLowerCase()) >= 0) {
            storySelProps = storySelProps.filter((item) => item.selectorType !== 'market');
        }
        setStorySelectors(storySelProps); // initial selectors from library page

        if (!fromBack) {
            setDisableNextBtn(true);
        }
    }

    const getRemaningReportRunsCount = async () => {
        /**
         * Craig Harshaw - moved userPrefs to parent component/state...
         */
        // const userPrefResp = await getUserPreferences();
        // if (userPrefResp) {
        //     setSelectorsData({ focusMarket: userPrefResp?.primaryMarket });
        // }
    };

    const setSelectorsData = (dataObj) => {
        setSelectorStateInitial(dataObj);
        setSelectorStateData(dataObj);
    };

    const setSelectorStatusStates = (storySelectors) => {
        let selectorsStatusObj = [];
        let currStatusObj = selectorStateInitial?.selectorsStatus || [];
        if (currStatusObj && currStatusObj.length === storySelectorsRef.current.length) {
            selectorsStatusObj = currStatusObj;
        } else {
            storySelectors.forEach((selector) => {
                selectorsStatusObj.push({
                    selectorName: selector.selectorLabelName,
                    status: selectorStates.pending,
                });
            });
        }
        setSelectorsData({ selectorsStatus: selectorsStatusObj });
    };

    const setNextButtonStatus = useCallback((flag) => {
        setDisableNextBtn(flag);
    }, []);

    const setDataSpecificationValue = useCallback((selectionsValues, selectorNo) => {
        if (selectorNo === userDataRef.current?.storyConfig?.reportDetails.reportSelectors.length - 1) {
            let panelStatusCompleted = selectionsValues?.userData?.reportParams.selectorsStatus.every(
                (item) => item.status === selectorStates.completed
            ); // checks panel status on each submition
            setPanelStatusCompleted(panelStatusCompleted);
        }
        setDataSpecificationValues(selectionsValues);
        dataSpecificationRef.current = selectionsValues;
        setSelectorStateData(selectionsValues?.userData?.reportParams); //setting for retaining values
        let panelStatusCompleted = selectionsValues?.userData?.reportParams.selectorsStatus.every(
            (item) => item.status === selectorStates.completed
        ); // checks panel status on each submition
        setPanelStatusCompleted(panelStatusCompleted); // if all panels === completed
        if (panelStatusCompleted) {
            selectionsValues.userData.reportParams.activeCollapsePanel = [];
            localStorage['dataSpecification'] = JSON.stringify(selectionsValues);
            localStorage['SelectorStateData'] = JSON.stringify(selectionsValues?.userData?.reportParams);
            setDisableNextBtn(false);
        } else {
            setDisableNextBtn(true);
        }
        localStorage.removeItem('storyName');
    }, []);

    useEffect(() => {
        if (storySelectors.length)
            setSelectorStatusStates(storySelectors);
    }, [storySelectors])

    useEffect(() => {
        if ((selectorStateInitial.selectorsStatus.length === storySelectors.length) && Steps[currentStep]?.path === "objective_preview")
            changeSteps()
    }, [selectorStateInitial])


    return (
        <DashboardContent
            title={headerText.current}
            subTitle={subTitle}
            extras={extras}
            className={classnames(wrapperClass)}
        >
            <ByzzerMask loading={loading} />
            <StoryValidationProgress inProgress={validationInProgress} />

            <div className="story-content">
                <OutletWithProps
                    onStepLoad={onStepLoad}
                    userDataRef={userDataRef.current}
                    props={storyCountDetails}
                    fromBack={fromBack}
                    selectorStateDataObj={selectorStateInitial}
                    setSelectorsData={setSelectorsData}
                    storySelectorVal={storySelectors}
                    marketGroup={marketGroup}
                    selectorConfigsData={selectorConfigsData}
                    setDataSpecificationValue={setDataSpecificationValue}
                    saveStoryProcessingFlag={saveStoryProcessingFlag}
                    setNextButtonStatus={setNextButtonStatus}
                    disableNextBtn={disableNextBtn}
                    objectivesListFromConfig={objectivesListFromConfig}
                    objectivesHasChanged={objectivesHasChanged}
                    setObjectivesHasChanged={setObjectivesHasChanged}
                    setSelectorStateInitial={setSelectorStateInitial}
                />
            </div>
            <div className="story-nextButton">
                {activeStep < 4 && <ByzzerButton label={'Next step'} onClick={next} disabled={disableNextBtn} />}
            </div>
        </DashboardContent>
    );
};

export default BuildStory;