import React from 'react';
import { useState } from 'react';
import './ByzzerExpandableTable.scss';
import ExpandableTableRow from './ByzzerExpandableTableRow';
import { Column } from './ByzzerExpandableTableColumn';
import classNames from 'classnames';
import { Spinner } from '@/components/Spinner/Spinner';

export interface ByzzerCollapseEvent {
    timestamp: number;
    collapse: boolean | undefined;
}

interface ByzzerTableProps {
    columns: Column[];
    data: any; //{ [key: string]: any }[];
    childDataKey?: string; //default is "child"
    rowKey?: string;
    className?: string;
    rowColor?: (rowData: any) => string | undefined;
    visibleOnInit?: (rowData: any) => boolean;
    hideCollapseExpandButtons?: boolean;
    isLoading?: boolean;
}

const baseClassName = 'byzzer-expandable-table';

const ByzzerExpandableTable: React.FC<ByzzerTableProps> = ({
    columns,
    className,
    data,
    childDataKey,
    rowKey,
    rowColor,
    visibleOnInit,
    hideCollapseExpandButtons,
    isLoading,
}) => {
    /** An "event" used to collapse or expand all rows in the table */
    const [collapseAllEvent, setCollapseAllEvent] = useState<ByzzerCollapseEvent>({
        timestamp: 0,
        collapse: undefined,
    });

    const tableRows = () => {
        if (data && data.length > 0) {
            let childLevel = 0;
            return data.map((value) => {
                const rowKeyValue = () => {
                    if (rowKey) {
                        return childLevel++ + value[rowKey];
                    } else {
                        return '0' + Object.values(value).join();
                    }
                };
                return (
                    <ExpandableTableRow
                        key={rowKeyValue()}
                        collapseAllEvent={collapseAllEvent}
                        hideChildren={{
                            timestamp: 0,
                            collapse: undefined,
                        }}
                        data={value}
                        columns={columns}
                        childDataKey={childDataKey || 'child'}
                        childLevel={0}
                        className={classNames(`${baseClassName}__item`)}
                        rowKey={rowKey}
                        rowColor={rowColor}
                        visibleOnInit={visibleOnInit}
                        expandParent={() => {}}
                    ></ExpandableTableRow>
                );
            });
        }
        return (
            <>
                {!isLoading && (
                    <tr className={classNames(`${baseClassName}__item--empty`)}>
                        <td colSpan={2}>No Record Found</td>
                    </tr>
                )}
                {isLoading && (
                    <span className={`${className}--spinner`}>
                        <Spinner />
                    </span>
                )}
            </>
        );
    };

    const renderHeaders = () => {
        return columns?.map((column) => {
            return <th key={column.title}>{column.title}</th>;
        });
    };

    let buttons;
    if (!hideCollapseExpandButtons) {
        buttons = (
            <div className={'expandCollapseAllRow'}>
                <button
                    className={'expandCollapseAll'}
                    onClick={(e) => {
                        setCollapseAllEvent({ timestamp: e.timeStamp, collapse: false });
                    }}
                >
                    Expand All
                </button>
                <button
                    className={'expandCollapseAll'}
                    onClick={(e) => {
                        setCollapseAllEvent({ timestamp: e.timeStamp, collapse: true });
                    }}
                >
                    Collapse All
                </button>
            </div>
        );
    }

    return (
        <>
            {buttons}
            <table className={baseClassName}>
                <thead className={classNames(`${baseClassName}__head`)}>
                    <tr className={classNames(`${baseClassName}__item-head`)}>{renderHeaders()}</tr>
                </thead>
                <tbody className={classNames(`${baseClassName}__body`)}>{tableRows()}</tbody>
            </table>
        </>
    );
};

export default ByzzerExpandableTable;
