import './VisualizationTile.scss';
import React, { useCallback, useContext } from 'react';
import { Tooltip } from 'antd';
import { ByzzerButton } from '@/components/form';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@/components/Spinner/Spinner';
import { TileFooter } from '@/pages/HomePage/TileFooter';
import { fetchColorsForGrade } from '@/views/scorecard/fetchColorsForGrade';
import { truncateString, individualDescription, formGraphData } from '@/utils/homePageUtil';
import { zingChartConfig } from '@/config';
import classNames from 'classnames';
import ByzzerOverallScoreDial from '@/components/ByzzerCharts/ByzzerOverallScoreDial';
import trendUpArrow from '@images/icons/homePage/trendUpArrow.svg';
import trendFlat from '@images/icons/homePage/trendFlat.svg';
import trendDownArrow from '@images/icons/homePage/trendDownArrow.svg';
import trendDownYellowArrow from '@images/icons/homePage/trendDownYellowArrow.svg';
import trendUpYellowArrow from '@images/icons/homePage/trendUpYellowArrow.svg';
import trendUpDownYellowArrow from '@images/icons/homePage/trendUpDownYellowArrow.svg';

// zingchart
import zingchart from 'zingchart/es6';
import ZingChart from 'zingchart-react';
// EXPLICITLY IMPORT MODULE
import 'zingchart/modules-es6/zingchart-depth.min.js';
import 'zingchart/modules-es6/zingchart-waterfall.min.js';
import { HomepageContext, useHomePageContext } from '@/contexts/HomepageContext';
import { isEmpty } from 'lodash';
import useIsMobile from '@/hooks/useIsMobile';

zingchart.LICENSE = [zingChartConfig.appLicense];

const baseClassName = 'viz-tile';

interface VisualizationTileProps {
    tile: any;
    className?: string;
    refreshTile?: number;
    handleRefresh?: () => void;
}

export const VisualizationTile = React.memo(({ tile, className, refreshTile }: VisualizationTileProps) => {
    const navigate = useNavigate();
    const { tilesLoadingState } = useContext(HomepageContext);
    const isMobile = useIsMobile();

    const goToScoreCard = useCallback(() => {
        navigate('/dashboard/my_scorecard');
    }, [navigate]);

    
    // Todo: Check the View Tile content after changing the backend changes
    const hasDescription = tile?.data?.description?.length ? true : false;
    const viewTileContent = hasDescription ? tile?.data?.description?.[0]?.isShow : false;

    const dataContent = tile?.data?.content;
    const isErrorData = tile?.data?.isError || false;
    const isLoading = tile?.isLoading || false;
    const isDataExpired = tile?.dataExpired || false;

    const grayedChart = (tile) => (
        <div className={`${baseClassName}__default_content`}>
            <div className={`${baseClassName}__default_content__message`}></div>
        </div>
    );

    const spinnerChart = (tile) => (
        <div key={tile} className={`${baseClassName}__tile_center`}>
            <span className={`${baseClassName}__tile__spinner`}>
                &nbsp;
                <Spinner />
            </span>
        </div>
    );

    const tileDescription = useCallback(() => {
        if (tile?.id in tilesLoadingState && tilesLoadingState[tile?.id]) {
            return null;
        }
        return <div className={`${baseClassName}__description`}>{descriptions(tile?.data?.description)}</div>;
    }, [tile, tilesLoadingState]);

    const descriptions = useCallback(
        (descriptions) => {
            const isDescriptionsArray = Array.isArray(descriptions);
            if (isDescriptionsArray) {
                return descriptions?.map((description, index) => {
                    if (description?.isShow) {
                        return (
                            <div key={index + 'descriptions'} className={`${baseClassName}__description_text`}>
                                {individualDescription(description, navigate)}
                            </div>
                        );
                    }
                    return null;
                });
            } else if (descriptions?.isShow) {
                return (
                    <div key={'descriptions_object'} className={`${baseClassName}__description_text`}>
                        {individualDescription(descriptions, navigate)}
                    </div>
                );
            }
            return null;
        },
        [navigate]
    );

    const getBrandScoreTableTooltip = useCallback(
        (overlayClassName, title, value, position) => (
            <Tooltip
                align={{ offset: [0, -12] }}
                overlayClassName={`${overlayClassName}--tooltip`}
                placement={position}
                title={title}
            >
                <td>{value}</td>
            </Tooltip>
        ),
        []
    );

    const getTrendIcon = useCallback((item) => {
        if (item.symbol === 'up' && item.color === 'green') return trendUpArrow;
        else if (item.symbol === 'up' && item.color === 'yellow') return trendUpYellowArrow;
        else if (item.symbol === 'down' && item.color === 'red') return trendDownArrow;
        else if (item.symbol === 'down' && item.color === 'yellow') return trendDownYellowArrow;
        else if (item.symbol === 'updown' && item.color === 'yellow') return trendUpDownYellowArrow;
        else if (item.symbol === 'flat') return trendFlat;
        return null;
    }, []);

    const showLearnMoreButton = !isMobile;

    const scoreTile = useCallback(() => {
        const subClasses = classNames('score-tile');
        if (tile?.data?.isDefaultBrandPresent) {
            return (
                <div className={`${subClasses}-content`}>
                    {/* {homePageBrandScore(subClasses, tile?.data?.overallScore?.value, tile?.data?.overallScore?.grade)} */}
                    <ByzzerOverallScoreDial
                        className={subClasses}
                        value={dataContent?.overallScore?.value}
                        grade={dataContent?.overallScore?.grade}
                        unfilledStyle='dark'
                    />
                    <div className={`${subClasses}-content__stats`}>
                        <span className={`${subClasses}-content__stats-message`}>Change since last month:</span>
                        <div className={`${subClasses}-content__stats-value`}>
                            <img
                                className={`${subClasses}_card-icon`}
                                src={
                                    dataContent?.overallScore?.text &&
                                    Number(dataContent?.overallScore?.text.split(' ')[0]) >= 0
                                        ? trendUpArrow
                                        : trendDownArrow
                                }
                                alt={'up'}
                            />
                            <span>{dataContent?.overallScore?.text}</span>
                        </div>
                        {showLearnMoreButton && (
                            <ByzzerButton
                                label={'Learn More'}
                                onClick={() => goToScoreCard()}
                                style={{ width: '20px' }}
                            />
                        )}
                    </div>
                </div>
            );
        } else if (!tile?.data?.isDefaultBrandPresent && dataContent?.topGains.length > 0) {
            return (
                <table className={`${subClasses}-table`}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Brand</th>
                            <th>Category</th>
                            <th>Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataContent?.topGains?.map((row, index) => (
                            <tr key={index}>
                                <td className={`score ${row?.change_in_score ? 'green' : ''}`}>
                                    {row?.change_in_score ? '+' + row?.change_in_score : '-'}
                                </td>
                                { row?.brand !== null && getBrandScoreTableTooltip(
                                    subClasses,
                                    truncateString(row?.brand, 26)?.includes('...') ? row?.brand : undefined,
                                    truncateString(row?.brand, 26),
                                    'bottomLeft'
                                )}
                                { row?.category !== null && getBrandScoreTableTooltip(
                                    subClasses,
                                    truncateString((row?.category || '').split(',').join(', '), 11)?.includes('...')
                                        ? (row?.category || '').split(',').join(', ')
                                        : undefined,
                                    truncateString((row?.category || '').split(',').join(', '), 11),
                                    'bottomRight'
                                )}
                                <td className={`score ${fetchColorsForGrade(row?.score?.grade)}`}>
                                    {row?.score?.grade ? row?.score?.grade : '-'} (
                                    {row?.score?.value ? row?.score?.value : '-'})
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        } else if (!tile?.data?.isDefaultBrandPresent && dataContent?.topGains.length == 0) {
            return (
                <div className={`${subClasses}-content-nodata-message`}>
                    <p>
                        No brands in your subscription category(s) have improved their NIQ Brand Score in the last
                        month.
                    </p>
                </div>
            );
        }
        return grayedChart(tile);
    }, [tile, getBrandScoreTableTooltip, getTrendIcon]);

    const trendTile = useCallback(() => {
        return (
            <div className={`${baseClassName}__trend_items`}>
                <div className={`${baseClassName}__trend`}>
                    {dataContent?.map((item, index) => (
                        <div className={`${baseClassName}__trend_item`} key={index + item?.value}>
                            <b className={`${baseClassName}__trend_title`}>{item.key}</b>
                            <div className={`${baseClassName}__trend_value`}>
                                <img
                                    className={`${baseClassName}__trend_card-icon${(item.symbol === 'updown' ? '-2x': '')}`}
                                    src={getTrendIcon(item)}
                                    alt={item.symbol}
                                />
                                <span>{item.value}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }, [tile]);

    const renderChartTile = useCallback(
        (tileContent) => {
            const chartConfig = formGraphData(tileContent);
            if (isEmpty(chartConfig)) {
                return grayedChart(tileContent);
            }
            return (
                <div className={`${baseClassName}__chart__trend`}>
                    <ZingChart data={chartConfig} height={tile?.vizConfig?.height ?? 250} />
                </div>
            );
        },
        [tile]
    );

    const contentTile = useCallback(() => {
        if (tile?.id in tilesLoadingState && tilesLoadingState[tile?.id]) {
            return spinnerChart('refreshTile');
        }
        if (isErrorData || isDataExpired || isEmpty(dataContent)) {
            return grayedChart(tile);
        }

        // if (viewTileContent) {

        // if (isLoading) {
        //     return spinnerChart(tile);
        // }

        switch (tile?.vizType) {
            case 'score':
                return scoreTile();
            case 'trend':
                return trendTile();
            case 'chart':
            case 'pie':
            case 'line':
            case 'bar':
            case 'waterfall':
                return renderChartTile(tile);
            default:
                return grayedChart(tile);
        }
        // } else {
        //     return grayedChart(tile);
        // }
    }, [tile, tilesLoadingState]);

    const tileFooterContent = useCallback(() => {
        if (tile?.id in tilesLoadingState && tilesLoadingState[tile?.id] || isMobile) {
            return <></>;
        }
        return (
            <TileFooter
                className={baseClassName}
                tile={tile}
                // userUpdatedFilterValues={filterValues}
                goToScoreCard={goToScoreCard}
            />
        );
    }, [tile, tilesLoadingState, goToScoreCard, isMobile]);

    return (
        <>
            <div className={`${baseClassName} ${className}`} key={refreshTile}>
                <h2 className={`${baseClassName}__title`}>{tile?.vizConfig?.title}</h2>
                {tileDescription()}
                {contentTile()}
                {tileFooterContent()}
            </div>
        </>
    );
});

export default VisualizationTile;
