import './TimePeriodRunConfigFilters.scss';
import React, {ReactNode, useContext, useEffect, useRef, useState} from "react";
import classnames from "classnames";
import {AlertTimePeriod, ALERT_RELATIVE_TIME_PERIODS} from "@/types/AlertRun";
import { AlertRunConfigWizardContext } from '../../AlertRunConfigWizard/AlertRunConfigWizardContext';
import {TimePeriodPicker} from "@/components/TimePeriodPicker";

export type TimePeriodRunConfigFiltersProps = {
    name?: string;
    onChange?: (e: ByzzerChangeEvent<AlertTimePeriod>) => void;
    onValidityChange?: (e: ByzzerValidityChangeEvent) => void;
    value?: AlertTimePeriod;
    summary?: ReactNode;
    datatype?: 'omni' | 'rms' | 'cps';
    includeChartData?: boolean;    
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'time-period-run-config-filters';

export function TimePeriodRunConfigFilters(
    {
        className,
        onChange,
        onValidityChange,
        name,
        datatype,
        value,
        includeChartData,        
        ...props
    }: TimePeriodRunConfigFiltersProps) {

    const {value: contextValue, onChange: onContextChange, sku} = useContext(AlertRunConfigWizardContext);
    const [timePeriod, setTimePeriod] = useState<AlertTimePeriod>({
        type: 'relative',
    })
    const wasValid = useRef<boolean>(false);   

    useEffect(() => {
        let isValid = false;
        if(timePeriod && timePeriod.type.includes('relative')) {
            isValid = Boolean(timePeriod.period);
        }

        if (wasValid.current !== isValid) {
            onValidityChange?.({
                name,
                isValid
            });

            wasValid.current = isValid;
        }

    }, [timePeriod]);

    useEffect(() => {
        setTimePeriod(contextValue?.timePeriod ?? value ?? {
            type: 'relative'
        })
    }, [contextValue, value])


    function handleChange(e: ByzzerChangeEvent<AlertTimePeriod>) {
        onChange?.({
            name,
            value: e.value
        });
        onContextChange?.('timePeriod', e.value);
        setTimePeriod(e.value);
    }

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <TimePeriodPicker 
                name={'timePeriod'} 
                value={timePeriod} 
                onChange={handleChange} 
                datatype={datatype} 
                sku={sku} 
                hideLatestDate={true} 
                hideCustomeDate={true} 
                timePeriodOptions={ALERT_RELATIVE_TIME_PERIODS} 
            />
        </div>
    )
}

export default TimePeriodRunConfigFilters;
