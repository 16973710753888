import React, { useState, useEffect } from 'react';
import { useUser } from "@/contexts/UserContext";
import { ByzzerCheckableChangeEvent, ByzzerCheckbox } from '@byzzer/ui-components';
import { nanoid } from 'nanoid';
import classnames from 'classnames';
import { TeamMember } from '@/types/InvitationTypes';


export type AlertTeamMemberTableProps = {
    everyoneFromMySubscription: TeamMember[];
    handleChange: (e: ByzzerCheckableChangeEvent<string>, selectedMember: TeamMember) => void;
    currentRecipientEmails: string[];
}

export const AlertTeamMemberTable = ({
    everyoneFromMySubscription,
    handleChange,
    currentRecipientEmails,
    ...props
}: AlertTeamMemberTableProps) => {
    const { user } = useUser();
    const baseClassName = 'email-recipient-selector-member-table';

    return ( 
        <table className={classnames(`${baseClassName}`)}>
            <thead>
                <tr>
                    <th>Receive Alert?</th>
                    <th>Team</th>
                    <th>Name</th>
                    <th>Email</th>
                </tr>
            </thead>
            {Boolean(everyoneFromMySubscription?.length) && (
                <tbody>
                    {everyoneFromMySubscription.map(member => {
                        const { id, teamName, firstName, lastName, email, multiTenantEnabled, primaryCompanyId } = member
                        const fullName = `${firstName} ${lastName}`;
                        const isMultiTenantTeamMember = multiTenantEnabled && user?.primaryCompanyId !== primaryCompanyId;
                        return (
                            <tr key={nanoid()}>
                                <td>
                                    <ByzzerCheckbox
                                        onChange={(e) => handleChange(e, member)}
                                        disabled={(user?.id === id) || isMultiTenantTeamMember}
                                        checked={currentRecipientEmails.includes(email)}
                                        key={id}
                                    />
                                </td>
                                <td>{teamName}</td>
                                <td>{fullName}</td>
                                <td>{email}</td>
                            </tr>
                        )
                    })}
                </tbody>
            )}
        </table>
     );
}
 
export default AlertTeamMemberTable;

AlertTeamMemberTable.displayName = 'AlertTeamMemberTable';