import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ByzzerButton } from '@byzzer/ui-components';
import { confirm, openErrorModal } from '@/components/form/ByzzerModal';
import {useTenantApi} from '@/hooks/useTenantApi';
import { QuoteDetail } from '@/types/QuoteTypes';
import './QuoteFooter.scss';
import { useUser } from '@/contexts/UserContext';

type QuoteFooterProps = {
    baseClassName?: string;
    orderLater?: () => void;
    acceptOrder: () => void;
    disableAcceptBtn?: boolean;
    additionalContent?: JSX.Element;
    quote?: QuoteDetail;
    orderLaterData?: any;
    setLoadingMessage: (loadingMessage: string) => void;
};

const QuoteFooter = ({
    baseClassName,
    orderLater,
    acceptOrder,
    disableAcceptBtn,
    additionalContent,
    orderLaterData,
    quote,
    setLoadingMessage,
}: QuoteFooterProps) => {
    const { skipQuote } = useTenantApi();
    const {refreshUserQuote} = useUser();
    const navigate = useNavigate();
    const expiryDate = quote?.quoteDetails?.expiryDate;
    const handleTriggerOrderLater = async () => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const _expiryDate = expiryDate ? new Date(expiryDate).toLocaleString() + ' ' + timezone : null;
        const result = await confirm({
            title: 'Are you sure you want to delay accepting your Quote?',
            content: (
                <div className="order-later-warning-msg">
                    <p>
                        This Quote expires on <strong>{_expiryDate}.</strong>
                    </p>
                    <p> You can accept this quote anytime in the subscription page under the Quote tab.</p>
                </div>
            ),
            yesLabel: 'Order Later',
            noLabel: 'Cancel',
            className: 'order-later-warning',
        });
        setLoadingMessage('Processing your request...')
        try {
            // Note: Might required to store the decision somewhere in table, so that we can prompt everytime when user log-in
            if (result && quote?.id) {
                const orderLaterResponse = await skipQuote({ quoteId: quote?.id });
                if (orderLaterResponse) {
                    refreshUserQuote(orderLaterResponse);
                    navigate('/');
                }
            } else {
                // To Do: Handle OrderLater error response
            }
        } catch (err: any) {
            return openErrorModal({
                title: `Error while processing your request.`,
                content: <p>Fear not our engineering team is on the job.</p>,
                errorId: err.id
            });
        } finally {
            setLoadingMessage('')
        }
    };
    const footerClassName = additionalContent ? 'multiple' : 'end';

    return (
        <div className={`quote-footer-${footerClassName}`}>
            {additionalContent ? <div className="additonal_div">{additionalContent}</div> : null}
            <div className="action_btns">
                <ByzzerButton
                    label="Order later"
                    type="default"
                    onClick={orderLater ?? handleTriggerOrderLater}
                    disabled={!quote}
                />
                <ByzzerButton
                    label="Place Order"
                    type="default"
                    onClick={acceptOrder}
                    disabled={disableAcceptBtn || !quote}
                />
            </div>
        </div>
    );
};

export default QuoteFooter;
