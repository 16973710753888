import Arrow from '~@images/Arrow.svg';
import './CaseStudy.scss';
import { TrackClick } from '@/analytics';

type CaseStudy = {
    baseClassName?: string;
    caseStudies?: any[];
};

const CaseStudy = ({ baseClassName, caseStudies }: CaseStudy) => {

    return (
        <>
            <div className={`${baseClassName}__case-studies`}>
                {caseStudies?.map(({ heading, thumbnail, link }, index) => {
                    return (
                        <TrackClick name={`(${heading}) case study was clicked`} key={`${heading}${link}`}>
                            <div className={`${baseClassName}__case-studies__study`}>
                                <div
                                    className={`${baseClassName}__case-studies__study__thumbnail${
                                        index % 2 === 0 ? '__right' : '__left'
                                    }`}
                                >
                                    <img
                                        src={thumbnail}
                                        alt="heading"
                                        className={`${baseClassName}__case-studies__study__thumbnail__image`}
                                    />
                                </div>
                                <div
                                    className={`${baseClassName}__case-studies__study__call_to_action${
                                        index % 2 === 0 ? '__left' : '__right'
                                    }`}
                                >
                                    <div className={`${baseClassName}__case-studies__study__call_to_action__text`}>
                                        <p>{heading}</p>
                                    </div>
                                    <div className={`${baseClassName}__case-studies__study__call_to_action__button`}>
                                        <a href={link} target="_blank">
                                            <img src={Arrow} alt="Learn More" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </TrackClick>
                    );
                })}
            </div>
        </>
    );
};

export default CaseStudy;
