import './MarketPickerNode.scss';
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import {
    ByzzerCheckableChangeEvent,
    ByzzerCheckbox,
    ByzzerInlineSelect,
    ByzzerRadio,
    ByzzerTipIcon,
} from '@byzzer/ui-components';
import classnames from 'classnames';
import Tippy from '@tippyjs/react';
import { MarketPickerContext } from '@/components/MarketPicker/MarketPickerContext';
import { MrktEntitlementTypeMessages } from '@/utils/MarketEntitlementMessages';
import { isMarketDisabled, isMarketHidden } from '@/services/market.service';
import { useUser } from '@/contexts/UserContext';

export type MarketPickerNodeProps = {
    marketNode: MarketNode;
    depth?: number;
    expandable?: boolean;
    includeSelector?: boolean;
    includeToggle?: boolean;
    onShiftOnClick?: (node: MarketNode, hasShift: boolean) => void;
    displayMarketGroup?:string;
};

const baseClassName = 'market-picker-node';

export function MarketPickerNode({
    marketNode,
    depth = 1,
    expandable,
    includeSelector,
    includeToggle,
    displayMarketGroup
}: MarketPickerNodeProps) {
    const {
        getChildMarketNodes,
        selectedMarkets = [],
        addMarket,
        removeMarket,
        maxSelections,
        showRemainingMarkets,
        hideDisabledMarkets,
        hideNonPromoMarkets,
        requiredMasterCompany,
        requiredMarketGroup,
        includePseudoRemainingMarkets,
        requireRemainingMarket,
        setLastClickedPath,
        lastClickedPath,
        setSelectableMarkets,
        onShiftSelect,
    } = useContext(MarketPickerContext);
    const {
        features: { enableLimitedMarketSubscription },
        accessibleMasterCompanies,
        company,
    } = useUser();
    const [expanded, setExpanded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [childMarkets, setChildMarkets] = useState<MarketNode[]>([]);
    const [triggerRef, setTriggerRef] = useState<any>();
    const [labelRef, setLabelRef] = useState<any>();
    const {
        hasChildren = false,
        selectable = false,
        hasLimitedData = false,
        showLimitedDataTip = false,
        hasInfoIconForFmcgMarkets = false,
        info,
        infoMessage,
        limitedDataMessage = MrktEntitlementTypeMessages.TIP_LIMITED_PROMOTION,
        isRemaining = false,
        hasApproval = false,
        hasPartialApproval = false,
        hasNoApproval = false,
        purchased = false,
        unPurchased = false,
        categoriesNotCovered = false,
        masterCompany,
        marketGroup,
        path,
        remainingMarketNames,
    } = marketNode;
    const matchingSelectedMarket = selectedMarkets.find((market) => market.path === path);
    const [selectedRemainingMarket, setSelectedRemainingMarket] = useState<string | undefined>(
        matchingSelectedMarket?.remainingMarketRunConfig?.name ?? remainingMarketNames?.[0]
    );

    const multiSelect = Number(maxSelections) !== 1;
    const includeCheckbox = multiSelect;
    const includeRadio = !multiSelect;
    const hasExtraInfo = Boolean(info);
    const shiftActive = useRef<boolean>(false);
    const hasRemainingMarkets = Boolean(remainingMarketNames?.length);
    const hasSingleRemainingMarket = Number(remainingMarketNames?.length) === 1;
    let marketNameSuffix: ReactNode;
    // const unapproved = requiresApproval && !hasApproval && !hasPartialApproval;
    const unapproved = hasNoApproval;
    const unpurchased = unPurchased;
    // TODO: This isnt necessary with the new structure.  Since the comparitive/remaining market is attached to the main market, if it's selected, a comparitive/remaning market is present.  Will remove after next release but keeping in case we need to revert changes during testing.
    // const missingRemainingMarket = selected && requireRemainingMarket && hasRemainingMarkets
    //     && !Boolean(selectedMarkets.find((selectedMarket) => {
    //         return remainingMarketNames?.includes(selectedMarket.name) || remainingMarketNames?.includes(selectedMarket?.remainingMarketRunConfig?.name)
    //     }))
    const includeRemaining = showRemainingMarkets;
    const hasCategoryMismatch = selectable && categoriesNotCovered;
    const noAvailableRemainingMarkets = selectable && requireRemainingMarket && !remainingMarketNames?.length;
    const wrongMarketGroup = Boolean(selectable && requiredMarketGroup && marketGroup !== requiredMarketGroup);
    const wrongMasterCompany = Boolean(selectable && requiredMasterCompany && masterCompany !== requiredMasterCompany);
    const disabled = isMarketDisabled(marketNode, {
        requiredMarketGroup,
        requiredMasterCompany,
        requireRemainingMarket,
        enableLimitedMarketSubscription,
        accessibleMasterCompanies,
        reportType:displayMarketGroup,
        purchasedMarketKeys:company?.purchasedMarketKeys,
        selectedMarkets
    });
    const selected = Boolean(matchingSelectedMarket) && !disabled;

    const selectionLimitReached = multiSelect && Number(maxSelections) <= selectedMarkets.length;
    const hidden = isMarketHidden(marketNode, {
        includePseudoRemainingMarkets,
        requiredMarketGroup,
        requiredMasterCompany,
        requireRemainingMarket,
        showRemainingMarkets,
        hideDisabledMarkets,
        hideNonPromoMarkets,
        enableLimitedMarketSubscription,
        accessibleMasterCompanies,
        purchasedMarketKeys: company?.purchasedMarketKeys,
    });
    const canExpand = expandable !== false && hasChildren;

    const hasSelectableChildren = childMarkets.some((childMarket) => childMarket.selectable);
    const hasExpandableChildren = childMarkets.some((childMarket) => childMarket.hasChildren);
    const childDepth = depth + calculateChildIdentation();
    let disabledTip: ReactNode;

    //TODO: Put the negetive scenarioes and respective messages in an array instead
    if (selectionLimitReached) {
        disabledTip = <>You have reached your limit of {maxSelections} markets.</>;
    } else if (wrongMasterCompany) {
        disabledTip = MrktEntitlementTypeMessages.TIP_WRONG_MASTER_COMPANY;
    } else if (wrongMarketGroup) {
        disabledTip = MrktEntitlementTypeMessages.TIP_WRONG_MARKET_GROUP;
    } else if (noAvailableRemainingMarkets) {
        disabledTip = MrktEntitlementTypeMessages.TIP_NO_AVAILABLE_REMAINING_MARKETS;
    } else if (disabled) {
        disabledTip = MrktEntitlementTypeMessages.TIP_DISABLED_DEFAULT;
    }

    if (noAvailableRemainingMarkets) {
        // this is kind pointless but it saves an addition check in each clause below
        marketNameSuffix = '';
    } else if (
        selectable &&
        hasSingleRemainingMarket &&
        requireRemainingMarket &&
        remainingMarketNames![0] !== marketNode.name
    ) {
        // append the only available remaining market as long as it's not the same the main market.
        // a market being it's own remaining market can happen
        marketNameSuffix = <span className={`${baseClassName}__remaining-market`}> vs {remainingMarketNames![0]}</span>;
    } else if (selectable && requireRemainingMarket && !hasSingleRemainingMarket) {
        marketNameSuffix = (
            <span className={`${baseClassName}__remaining-market`}>
                {' '}
                vs{' '}
                <ByzzerInlineSelect
                    className={`${baseClassName}__remaining-market-selector`}
                    value={selectedRemainingMarket}
                    options={remainingMarketNames!}
                    onChange={handleRemainingMarketChange}
                />
            </span>
        );
    }

    useEffect(() => {
        const newMatchingSelectedMarket = selectedMarkets.find((market) => market.path === path);
        if (newMatchingSelectedMarket) {
            // without the IF, upon deselect, it will revert to the default, no need to do that.
            const newSelectedRemainingMarket = newMatchingSelectedMarket?.remainingMarketRunConfig?.name;
            setSelectedRemainingMarket(newSelectedRemainingMarket ?? remainingMarketNames?.[0]);
        }
    }, [selectedMarkets]);

    function handleRemainingMarketChange(e: ByzzerChangeEvent<string>) {
        if (selected && requireRemainingMarket) {
            addMarket(marketNode, e.value);
        } else {
            setSelectedRemainingMarket(e.value);
        }
    }

    function toggleExpanded() {
        if (!hasChildren) return;

        setExpanded((expanded) => !expanded);
    }

    async function handleToggleClick() {
        if (loading) return;

        toggleExpanded();

        if (childMarkets.length > 0 || loading) {
            if (expanded) {
                // removing the markets no longer needed
                setSelectableMarkets?.((prev) => {
                    return prev.filter(
                        (nodeData) =>
                            !childMarkets.some(
                                (childMarket) =>
                                    childMarket.path.replace(/^\\/, '') === nodeData.path.replace(/^\\/, '')
                            )
                    );
                });
            } else {
                // Adding markets which are selectable
                setSelectableMarkets?.((prevSelectableMarkets: MarketNode[]) => {
                    const findIndex = prevSelectableMarkets.findIndex(
                        (market) =>
                            market.path.replace(/^\\/, '') ===
                            childMarkets[0].path?.replace(/^\\/, '').split('\\').slice(0, -1).join('\\')
                    );
                    if (findIndex !== -1) prevSelectableMarkets.splice(findIndex + 1, 0, ...childMarkets);
                    return prevSelectableMarkets;
                });
            }
            return;
        }

        try {
            setLoading(true);
            const childNodes = await getChildMarketNodes(marketNode);
            setChildMarkets(childNodes);
            // Adding markets which are selectable
            setSelectableMarkets?.((prevSelectableMarkets: MarketNode[]) => {
                const findIndex = prevSelectableMarkets.findIndex(
                    (market) =>
                        market.path.replace(/^\\/, '') ===
                        childNodes[0].path?.replace(/^\\/, '').split('\\').slice(0, -1).join('\\')
                );
                if (findIndex !== -1) prevSelectableMarkets.splice(findIndex + 1, 0, ...childNodes);
                return prevSelectableMarkets;
            });
        } finally {
            setLoading(false);
        }
    }

    function handleSelectChange(e: ByzzerCheckableChangeEvent<string>) {
        if (disabled) {
            return;
        }
        if (e.checked) {
            if (shiftActive.current) {
                // this means shift select
                // which will be handled by handleCheckboxClick hence do nothing
                shiftActive.current = false;
                return;
            }
            if (requireRemainingMarket) {
                addMarket(marketNode, selectedRemainingMarket);
            } else {
                addMarket(marketNode);
            }
        } else {
            removeMarket(marketNode, requireRemainingMarket);
        }
    }

    function handleCheckboxClick(e: React.MouseEvent<HTMLElement>): void {
        if (disabled) {
            return;
        }
        // This function is responsible for handle shift select
        // if (!(e.target as HTMLElement).matches('input[type="checkbox"]')) return;
        // // if(limit != Infinity) {
        // //     // In this case the shift select functionality will be disabled. hence do nothing
        // //     return;
        // // }
        shiftActive.current = e.shiftKey;
        if (e.shiftKey && lastClickedPath && lastClickedPath !== null) {
            onShiftSelect?.(path);
        } else {
            setLastClickedPath?.(path);
        }
    }

    function calculateChildIdentation() {
        // Include indentation for expander and/or radio/checkbox selectors
        return includeSelector && includeToggle ? 2 : 1;
    }

    if (hidden) return null;

    // @ts-ignore
    return (
        <>
            <div
                className={classnames(baseClassName, {
                    [`${baseClassName}--depth${depth}`]: depth,
                    [`${baseClassName}--disabled`]: disabled || (!selected && selectionLimitReached),
                    [`${baseClassName}--expandable`]: hasChildren,
                    [`${baseClassName}--expanded`]: expanded,
                    [`${baseClassName}--loading`]: loading,
                    [`${baseClassName}--is-remaining`]: isRemaining,
                    // TODO: Remove after the next release if passes QA wihtout issue.  Keeping temporarily if needing to revert.
                    // [`${baseClassName}--missing-remaining-market`]: missingRemainingMarket
                })}
            >
                {includeToggle && (
                    <div
                        className={classnames(`${baseClassName}__expander`, {
                            [`${baseClassName}__expander--hidden`]: !canExpand,
                            [`${baseClassName}__expander--expanded`]: expanded,
                            [`${baseClassName}__expander--loading`]: loading,
                        })}
                        onClick={handleToggleClick}
                    />
                )}
                {includeSelector && (
                    // this wrapper element only exists b/c I don't have time convert ByzzerCheckbox and ByzzerRadio to
                    // support ForwardRef
                    <div className={classnames(`${baseClassName}__selector`)} ref={setTriggerRef}>
                        {includeRadio && selectable && (
                            <ByzzerRadio
                                className={`${baseClassName}__selector`}
                                onChange={handleSelectChange}
                                disabled={disabled}
                                checked={selected}
                            />
                        )}
                        {includeCheckbox && selectable && (
                            <ByzzerCheckbox
                                onChange={handleSelectChange}
                                disabled={disabled}
                                checked={selected}
                                onClick={handleCheckboxClick}
                            />
                        )}
                        {!selectable && (
                            <span
                                className={classnames(`${baseClassName}__selector-spacer`, {
                                    [`${baseClassName}__selector-spacer--round`]: includeRadio,
                                    [`${baseClassName}__selector-spacer--square`]: includeCheckbox,
                                })}
                            />
                        )}
                    </div>
                )}
                <div className={`${baseClassName}__content`} ref={setLabelRef}>
                    {marketNode.name} {marketNameSuffix}
                </div>
                <div className={`${baseClassName}__icons`}>
                    {hasExtraInfo && <ByzzerTipIcon tipDelay={[300, 0]} type={'market-info'} tip={info} />}
                    {unpurchased && (
                        <ByzzerTipIcon
                            tipDelay={[300, 0]}
                            type={'unpurchased'}
                            tip={MrktEntitlementTypeMessages.TIP_PURCHASE_REQUIRED}
                        />
                    )}
                    {purchased && (
                        <ByzzerTipIcon
                            tipDelay={[300, 0]}
                            type={'purchased'}
                            tip={MrktEntitlementTypeMessages.TIP_HAS_PURCHASED}
                        />
                    )}
                    {unapproved &&
                        marketNode.masterCompany !== 'Walmart' &&
                        marketNode.masterCompany !== 'Sams' &&
                        marketNode.masterCompany !== 'Thorntons' && (
                            <ByzzerTipIcon
                                tipDelay={[300, 0]}
                                type={'unapproved'}
                                tip={MrktEntitlementTypeMessages.TIP_PRIOR_APPROVAL_REQUIRED}
                            />
                        )}
                    {unapproved &&
                        (marketNode.masterCompany === 'Walmart' ||
                            marketNode.masterCompany === 'Sams' ||
                            marketNode.masterCompany === 'Thorntons') && (
                            <ByzzerTipIcon
                                tipDelay={[300, 0]}
                                type={'unapproved'}
                                tip={MrktEntitlementTypeMessages.TIP_PRIOR_APPROVAL_REQUIRED_FOR_SPECIFIC_MARKETS}
                            />
                        )}

                    {hasApproval && (
                        <ByzzerTipIcon
                            tipDelay={[300, 0]}
                            type={'approved'}
                            tip={MrktEntitlementTypeMessages.TIP_HAS_PRIOR_APPROVAL}
                        />
                    )}
                    {hasPartialApproval && (
                        <ByzzerTipIcon
                            tipDelay={[300, 0]}
                            type={'mix-approved'}
                            tip={MrktEntitlementTypeMessages.TIP_HAS_PARTIAL_PRIOR_APPROVAL}
                        />
                    )}
                    {(hasLimitedData || showLimitedDataTip) && (
                        <ByzzerTipIcon tipDelay={[300, 0]} type={'limited-promotion-data'} tip={limitedDataMessage} />
                    )}
                    {hasCategoryMismatch && (
                        <ByzzerTipIcon
                            tipDelay={[300, 0]}
                            type={'categories-not-covered'}
                            tip={MrktEntitlementTypeMessages.TIP_NO_COVERAGE}
                        />
                    )}
                    {hasInfoIconForFmcgMarkets && (
                        <ByzzerTipIcon tipDelay={[300, 0]} type={'market-info'} tip={infoMessage} />
                    )}
                </div>
            </div>
            {Boolean(disabledTip) && (
                // @ts-ignore
                <Tippy
                    reference={labelRef}
                    triggerTarget={[triggerRef, labelRef].filter(Boolean)}
                    content={disabledTip}
                    placement={'right'}
                    delay={[750, 0]}
                ></Tippy>
            )}
            {expanded &&
                childMarkets
                    ?.filter(({ isRemaining }) => includeRemaining || !isRemaining)
                    .map((market) => (
                        <React.Fragment key={market.path}>
                            <MarketPickerNode
                                marketNode={market}
                                includeSelector={hasSelectableChildren}
                                includeToggle={hasExpandableChildren}
                                // indent children extra depth if the current is node selectable to account for the checkbox/radio
                                depth={childDepth}
                            />
                        </React.Fragment>
                    ))}
        </>
    );
}

MarketPickerNode.displayName = 'MarketPickerNode';
