import './LeverInput.scss';
import React, {ChangeEvent, useEffect, useLayoutEffect, useRef, useState, KeyboardEvent} from "react";
import classnames from "classnames";
import { inputToRawValue, isNumeric, rawValueToInput } from '@/views/simulators/utils';
import { SimulatorDatatype } from '@/types/SimulatorTypes';
import {ByzzerChangeEventHandler} from "@byzzer/ui-components";
import { ControlledInput } from '../ControlledInput';

export type LeverInputProps = {
    type?: SimulatorDatatype;
    name?: string;
    value: number | undefined;
    onChange?: ByzzerChangeEventHandler<number | undefined>;
    readOnly?: boolean;
    disabled?: boolean;
    totalValue?: number;
    maxValue?: number;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'lever-input';

export function LeverInput({
                               className,
                               type = 'decimal',
                               readOnly = false,
                               disabled = readOnly,
                               name,
                               value = !readOnly ? 0 : undefined,
                               onChange,
                               placeholder,
                               totalValue,
                               maxValue,
                               ...props
                           }: LeverInputProps) {

    const [internalValue, setInternalValue] = useState<string>('');
    const [inputExceededMax, setInputExceededMax] = useState<boolean>(false);
    const oldInternalValue = useRef<string>('');
    const oldValue = useRef<number>();
    const oldTotalValue = useRef<number>();

    useEffect(() => {
        setInternalValue(rawValueToInput(type, value).replace(/[^\d.-]/g, ''));
    }, [value])

    function handleChange(e: ChangeEvent<HTMLInputElement>): void {

        if (!isNumeric(e.target.value)) return;

        setInternalValue(e.target.value);
    }

    function handleKeyDown({target, key}: KeyboardEvent<HTMLInputElement>): void {

        if(key === 'Enter') {
            // onChange?.({
            //     name,
            //     value: inputToRawValue(type, internalValue)
            // });
            (target as HTMLInputElement).blur();
        }
    }

    function handleBlur(): void {
        if (oldInternalValue.current === internalValue) return; // don't do anything if the value didnt change

        const newValue = Number(internalValue);
        const newTotalValue = newValue - Number(oldValue.current) + Number(totalValue);

        if (maxValue && Math.max(Number(newValue), Number(newTotalValue)) > Number(maxValue)) {
            setInputExceededMax(true);

            setTimeout(() => {
                setInputExceededMax(false)
            }, 1250)
            onChange?.({ // if we want to set the previous value for the input
                name,
                value: Number(oldValue.current)
            });
            setInternalValue(oldInternalValue.current);

            // const maxValueForSingleInput = Number(maxValue) - Number(oldTotalValue.current) + Number(oldValue.current);
            // onChange?.({ // if we want to set the max allowed value for the input
            //     name,
            //     value: maxValueForSingleInput
            // });
            // setInternalValue(rawValueToInput(type, maxValueForSingleInput))
        } else {
            onChange?.({
                name,
                value: inputToRawValue(type, internalValue)
            });
        }
    }

    function handleFocus(e) {
        oldValue.current = value;
        oldTotalValue.current = totalValue;
        oldInternalValue.current = e.target.value;

        if (inputExceededMax) {
            setInputExceededMax(false)
        }
    }

    return (
        <div className={classnames(baseClassName, className, `${baseClassName}--${type}`, {
            [`${baseClassName}__read-only`]: readOnly,
            [`${baseClassName}__exceeded-max`]: inputExceededMax,
        })} {...props}>
            <ControlledInput
                className={classnames(`${baseClassName}__input`, {
                    [`${baseClassName}__input--disabled`]: disabled,
                    [`${baseClassName}__input--read-only`]: readOnly
                })}
                name={name}
                placeholder={placeholder}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                value={internalValue}
                readOnly={readOnly}
                disabled={disabled}
                onChange={handleChange}
                onFocus={handleFocus}
            />
        </div>
    )
}


export default LeverInput;