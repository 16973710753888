import React, { useEffect, useState } from 'react'
import { Popover } from 'antd';
import InlineSVG from 'react-inlinesvg/esm';
import { ByzzerCheckbox } from '@byzzer/ui-components';
import hamburgerIcon from '@images/icons/hamburgerIcon.svg';
export enum ViewEnums {
    ListView = 'List View',
    TreeView = 'Tree View'
}

export enum VisibilityEnums {
    HideNonSelectorMarkets = 'Hide Non-Selectable Markets',
    ShowRemainingMarkets = 'Show Remaining Markets',
    HideNonPromoDataAccounts = 'Hide Non-Promo Data Accounts'
}

type MarketSelectionLayoutOptionProps = {
    layout?: { view: ViewEnums, marketOptions: string[] },
    setMarketLayout: (state) => void,
    disableView?: ViewEnums[],
    removeMarketOptions?: string[]
}

const layoutTypes = Object.values(ViewEnums);

const visibilityOptions = Object.values(VisibilityEnums)

const MarketSelectionLayoutOption =
    ({ layout, setMarketLayout, disableView, removeMarketOptions }: MarketSelectionLayoutOptionProps) => {
        const handleLayoutChange = (e: ByzzerChangeEvent<any>) => {
            setMarketLayout(e);
        }

        // Handler for Market visibility checkboxes
        const handleMarketChange = (e: ByzzerChangeEvent<any>) => {
            setMarketLayout(e)
        }

        const checkMarketSelection = (option) => {
            return layout?.marketOptions?.includes(option)
        }

        // const handleShowMarket = () => { }

        const popOverContent = (
            <div className='advance-market-selections__popover'>
                {/* Commented this because of Emma comment, we will uncomment it once this feature is done */}
                {/* {
                    layoutTypes.map((view, i) => (
                        <ByzzerRadio
                            name={view}
                            label={view}
                            checked={view === layout?.view}
                            value={view}
                            onChange={handleLayoutChange}
                            disabled={disableView && disableView.includes(view)}
                            key={`market-layout-${i}-${Date.now()}`}
                        />
                    ))
                }
                <Divider /> */}
                {
                    visibilityOptions.filter((option) => !removeMarketOptions?.includes(option)).map((item, i) => (
                        <div className='advance-market-selections__popover-selection' key={`market-visibility-${i}-${Date.now()}`}>
                            <ByzzerCheckbox
                                label={item}
                                name={item}
                                value={item}
                                checked={checkMarketSelection(item)}
                                disabled={false}
                                onChange={handleMarketChange}
                                key={`market-visibility-checkbox-${i}-${Date.now()}`}
                            />
                            {/* Commented this - once get the info description, then uncomment it and handleshowMarket method */}
                            {/* {
                                item && <span className='advance-market-selections__popover-selection-info'>
                                    <i>
                                        <img
                                            src={infoIcon}
                                            onClick={handleShowMarket}
                                        />
                                    </i>
                                </span>
                            } */}
                        </div>
                    ))
                }
            </div>
        )

        return (
            <Popover
                className='advance-market-selections__container__header-hamburger'
                placement="leftTop"
                trigger="click"
                content={popOverContent}
            >
                <i>
                    <InlineSVG src={hamburgerIcon} />
                </i>
            </Popover>
        )
    }

export default MarketSelectionLayoutOption;