import { useEffect} from "react";
import useState from 'react-usestateref';
import classnames from 'classnames';
import './TenantSelector.scss';
import { ByzzerButton, ByzzerSelect, ByzzerSelectOption } from "@byzzer/ui-components";
import TenantSelectorOption from './TenantSelectorOption';
import { useUser } from "@/contexts/UserContext";
import { TenantType } from "@/types/ApiTypes";
import {useNavigate} from 'react-router-dom';

export type TenantSelectorProps = {
    showCancelButton?: boolean;
};

function toTenantOption(tenant: TenantType): ByzzerSelectOption {
    return {
        display: tenant.defaultCompanyDisplayName,
        value: tenant.id,
    }
}


const baseClassName = 'byz-tenant-selector';

export const TenantSelector = ({
    showCancelButton
}: TenantSelectorProps) => { 

    const { availableTenants, changeTenant, tenantId, isMultiTenant } = useUser()
    const navigate = useNavigate();
    const [internalValue, setInternalValue] = useState<string | undefined>(tenantId); // TODO: the currently logged-into company
    const [pinnedOptions, setPinnedOptions] = useState<string[]>(JSON.parse(localStorage.getItem('pinnedOptions') ?? '[]'));
    const [tenantOptions, setTenantOptions, tenantOptionsRef] = useState<ByzzerSelectOption[]>([]);
    const [sortedOptions, setSortedOptions] = useState<typeof tenantOptions>([]);

    useEffect(() => {
        if (isMultiTenant) {
            setTenantOptions(sortTenantOptionsAlphabetically(availableTenants!.map(toTenantOption)))
        }
    }, [availableTenants]);

    useEffect(() => { // Only done once; tenantOptions shouldn't change after this
        if (Boolean(tenantOptions?.length)) {
            setSortedOptions(sortTenantOptionsOnPinned(tenantOptions, pinnedOptions));
        };
    }, [tenantOptions]);

    useEffect(() => {
        if (tenantOptions?.length) {
            setSortedOptions(sortTenantOptionsOnPinned(tenantOptionsRef.current, pinnedOptions));
        }
    }, [pinnedOptions]);

    const handlePinClick = (tenantId: string) => {
        let newPinnedOptions: typeof pinnedOptions;
        if (pinnedOptions?.includes(tenantId)) {
            newPinnedOptions = pinnedOptions.filter((pinnedOption) => pinnedOption !== tenantId);
        } else {
            newPinnedOptions = [...pinnedOptions, tenantId];
        }
        localStorage.setItem('pinnedOptions', JSON.stringify(newPinnedOptions))
        setPinnedOptions(newPinnedOptions);
    }

    const handleTenantChange = (e: ByzzerChangeEvent<string | undefined>): void => {
        setInternalValue(e.value)
    }

    const handleSubmit = () => {
        changeTenant?.(internalValue)
    }

    function handleCancel() {
        navigate(-1)
    }

    const sortTenantOptionsAlphabetically = (options: ByzzerSelectOption[]) => {
        const newOptions: typeof options = JSON.parse(JSON.stringify([...options]));

        return newOptions.sort((a, b) => {
            return a?.display?.localeCompare(b?.display, 'en', {sensitivity: 'base'})
        });
    }

    const sortTenantOptionsOnPinned = (options: ByzzerSelectOption[], pinnedOptions: string[]) => {
        const newOptions: typeof options = JSON.parse(JSON.stringify([...options]));

        return newOptions.sort((a, b) => {
            if (pinnedOptions.includes(a.value)) {
                return -1;
            }
    
            return 0;
        });
    }

    const submitDisabled = !internalValue || internalValue === tenantId;

    return (
        <div className={classnames(`${baseClassName}`)}>
            <div className={classnames(`${baseClassName}__selector`)}>
                <ByzzerSelect
                    options={sortedOptions}
                    value={internalValue}
                    // @ts-ignore
                    onChange={handleTenantChange}
                    optionComponent={(props: any) => TenantSelectorOption({ // todo: check type of props
                        isPinned: pinnedOptions?.includes(props?.value),
                        onPinClick: handlePinClick,
                        ...props
                    })}
                    placeholder='Select from the list'
                    // defaultMenuIsOpen={true}
                />
            </div>
            <div className={`${baseClassName}__actions`}>
                <ByzzerButton
                    label={'Submit'}
                    disabled={submitDisabled}
                    onClick={handleSubmit}
                />
            {showCancelButton && (
                <ByzzerButton
                    type={"negative"}
                    label={'Cancel'}
                    onClick={handleCancel}
                />
            )}
            </div>
        </div>
    );

};

export default TenantSelector;

TenantSelector.displayName = 'TenantSelector';
