import { MarketTypeOption } from '@/types/RunConfigOptions';

export const MARKET_TYPES: MarketTypeOption[] = [
    {
        title: 'across different retailers',
        children: [
            'All',
            'Military',
            'Liquor',
            'Grocery',
            'Ecom',
            'Convenience',
            'Club',
            'Drug',
            'Dollar',
            'Mass',
            'Pet',
        ],
        excludedAccessLevels: ['all alse', 'unknown', 'all_else', 'undefined'],
        marketType: 'Account',
        marketLevel: 'Level 1',
        labelInfoCode: 'Account',
    },
    {
        title: 'across major markets (cities & their surrounding areas)',
        children: ['All FMCG', 'Drug', 'Grocery', 'Convenience', 'Liquor'],
        excludedAccessLevels: [],
        marketType: 'Major Market',
        marketLevel: '',
        labelInfoCode: 'MajorMarket',
    },
    {
        title: 'across state lines',
        children: ['All FMCG', 'Grocery', 'Convenience', 'Liquor'],
        excludedAccessLevels: [],
        marketType: 'Stateline',
        marketLevel: '',
        labelInfoCode: 'Stateline',
    },
    {
        title: 'across US regions',
        children: ['All FMCG', 'Drug', 'Grocery', 'Convenience'],
        excludedAccessLevels: [],
        marketType: 'Region',
        marketLevel: '',
        labelInfoCode: 'Region',
    },
];
