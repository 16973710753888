import './SubscriptionReports.scss';
import React, {Fragment, useEffect, useState} from 'react';
import {useTenantApi} from '@/hooks/useTenantApi';
import ReportInformation from '@/views/ReportInformation';
import {alert, ByzzerLink, ByzzerModal, openModal, useModals} from '@/components/form';
import {orderedSectionHeaders, reportDescriptionUrl} from '@/config/globalVars';
import {useApp, useEvents, useUser} from '@/contexts/UserContext';
import {toRunsText, useBetterNavigate, snakeCaseToTitleCase} from '@/utils';
import {ByzzerMask} from '@/components/ByzzerMask/ByzzerMask';
import {TrackClick} from '@/analytics';
import {TipIcon} from '@/components/icons';
import {useSuiteCommerceLink} from '@/components/SuiteCommerceSSO';
import FavoriteIcon from '@/components/icons/FavoriteIcon';
import {getAllReports} from '@/services/product.service';
import {useParams} from 'react-router-dom';
import classnames from 'classnames';
import {ReportCardBadge} from '@/views/MyReports/ReportCardBadge';
import StackedReportFilters from '@/views/MyReports/StackedReportFilters';
import {SubscriptionInfo} from '@/constants/subscription.constants'
import { ByzzerButton } from '@byzzer/ui-components';
import { ByzzerCard } from "@/components/ByzzerCard";

const FREE_GROUP_TITLE = 'Included in Free Subscription';
const baseClassName = 'subscription-reports';

function SubscriptionReport(
    {
        changeReport = false,
        handleChangeReport = (reportType, sku) => {
        }
    }) {

    const {openInNewTab} = useSuiteCommerceLink();
    const {createUserFavorite, deleteUserFavorite, getMySubscriptionUsage, getUserFavorite,} = useTenantApi();
    const events = useEvents();
    const {user, subscription, reloadSubscription, subscribedSkus, admins} = useUser();
    const {allProducts} = useApp();
    const scaLink = useSuiteCommerceLink();
    const [isFree, setIsFree] = useState(subscription?.metadata?.isFree === true);
    const [reports, setReports] = useState([]);
    const [allReports, setAllReports] = useState([]);
    // const [subscriptionReports, setSubscriptionReports] = useState([]);
    // maybe this isn't needed
    const [allSkus, setAllSkus] = useState([]);
    const [favoriteSkus, setFavoriteSkus] = useState([]);
    const [remainingCoreRuns, setRemainingCoreRuns] = useState();
    const [remainingSmartRuns, setRemainingSmartRuns] = useState();

    const [reportInformationView, setReportInformationView] = useState(false);
    const [selectedReport, setSelectedReport] = useState();
    const navigate = useBetterNavigate();
    const [loading, setLoading] = useState(false);
    const [sectionHeaders, setSectionHeaders] = useState([]);
    const [filterType, setFilterType] = useState(0);
    const [showAllReports, setShowAllReports] = useState(isFree);
    const [filteredReports, setFilteredReports] = useState([]);
    const [skuFilter, setSkuFilter] = useState([]);
    const [canRunReports, setCanRunReports] = useState(false);
    const [showReportFilters, setShowReportFilters] = useState(true);
    const {openErrorModal} = useModals();
    const {id} = useParams();

    const wrapperClass = classnames('subscription-reports', {
        'ad-hoc-reports--readonly': !canRunReports,
        'ad-hoc-reports--runnable': canRunReports,
    });

    //it is no more referenced anywhere as the toggle-switch is replaced by byzzer-buttons,can be deleted
    const reportTypes = [
        {value: 'all', display: 'All Reports'},
        {value: 'subscription', display: 'Subscribed Reports'},
    ];

    useEffect(() => {
        loadSubscription();
        getFavorites();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (allProducts) {
            const tempReports = getAllReports();
            const allSku = tempReports.map(({sku}) => sku);
            setAllReports(tempReports.sort((a, b) => a.title.localeCompare(b.title, 'en', {sensitivity: 'base'})));
            setAllSkus(allSku);
            if (skuFilter) {
                setSkuFilter(allSku);
            }
            if (id) {
                const filteredReport = tempReports.find(({sku}) => id === sku);
                setReportInformationView(!reportInformationView);
                setSelectedReport(filteredReport);
            }
        }
    }, [allProducts]);

    useEffect(() => {
        if (subscription) {
            // const subscriptionReportList = allProducts.filter(({type, sku}) => type === 'report' && subscribedSkus.includes(sku));
            // setSubscriptionReports(subscriptionReportList.sort((a, b) => a.title.localeCompare(b.title)));
            // setSubscribedSkus(subscriptionReportList.map(({sku}) => sku));
            setIsFree(subscription?.metadata?.isFree === true);
        }
    }, [subscription]);

    useEffect(() => {
        if (user?.role) {
            setCanRunReports(['user', 'admin'].includes(user?.role));
        }
    }, [user]);

    useEffect(() => {
        if (showAllReports) {
            setReports(allReports);
        } else {
            setReports(allReports.filter(({sku}) => subscribedSkus.includes(sku)));
        }
    }, [showAllReports, subscribedSkus, allReports]);

    useEffect(() => {
        if (isFree) {
            setSectionHeaders([FREE_GROUP_TITLE, ...orderedSectionHeaders]);
        } else {
            setSectionHeaders(orderedSectionHeaders);
        }
    }, [filteredReports]);

    useEffect(() => {
        const [event] = events;
        if (['contract_items_changed', 'subscription_report_run'].includes(event?.type)) {
            reloadSubscription();
            loadSubscription(false);
        }
    }, [events]);
    // apply filters when the list of reports or the skuFilter changes
    useEffect(() => {
        // if (skuFilter) {
        filterBySkus(skuFilter);
        // }
    }, [reports, skuFilter]);

    async function loadSubscription(showLoadingIndicator = true) {
        try {
            // if (showLoadingIndicator) setLoading(true);

            let [{basicReports, premiumReports}, _] = await Promise.all([getMySubscriptionUsage()]);

            setRemainingCoreRuns(basicReports ? basicReports.limit - basicReports.used : 0);
            setRemainingSmartRuns(premiumReports ? premiumReports.limit - premiumReports.used : 0);
        } catch (err) {
            if (err.code === 'subscription_not_found') {
                return openErrorModal({
                    title: `Subscription Not Found`,
                    content: (<>
                        <p>
                            Currently you don't have any subscription. Please reach out to your Byzzer sales representative to purchase.
                        </p>
                    </>),
                    errorId: err.id
                });
            } else {
                return openErrorModal({
                    title: `Something Unexpected Happened`,
                    content: (<>
                        <p>Fear not our engineering team is on the job.</p>
                    </>),
                    errorId: err.id
                });
            }
        } finally {
            setLoading(false);
        }
    }

    function filterBySkus(skus) {
        const filteredReportsList = reports.filter(({sku}) => skus?.includes(sku));
        setFilteredReports(filteredReportsList);
    }

    async function getFavorites() {
        let skus = await getUserFavorite('subscription');
        setFavoriteSkus(skus);
    }

    function onReportClick(report) {
        if (subscribedSkus.includes(report.sku)) {
            validateReportCountAndShow(report);
        } else {
            alert({
                onResolve: () => {
                },
                content: (
                    <>
                        <p>Please reach out to your Byzzer sales representative</p>
                    </>
                ),
            });
        }
    }

    async function onFavoriteClick(productSku) {
        /** Delete If Available */
        if (favoriteSkus.includes(productSku)) {
            favoriteSkus.splice(favoriteSkus.indexOf(productSku), 1);
            // expanding the array before setting is necessary to change the reference and force react to update
            setFavoriteSkus([...favoriteSkus]);
            // we only need to this check when removing a favorite because it wouldn't be visible to toggle otherwise
            if (filterType === 4) {
                setSkuFilter([...favoriteSkus]);
            }
            await deleteUserFavorite('subscription', productSku);
        } else {
            /** Add If not available */
            favoriteSkus.push(productSku);
            setFavoriteSkus([...favoriteSkus]);
            await createUserFavorite('subscription', productSku);
        }
    }

    const validateReportCountAndShow = async ({reportType, sku}) => {
        if (!canRunReports) {
            if (user?.role === 'viewer') {
                openModal({
                    closeOnClickOutside: true,
                    title: "Sorry! You don't have access to do that.",
                    content: (
                        <>
                            <p>As a viewer, you don't have access to run a new report. </p>
                            <p>Reach out to your account admin
                                <div className={`${baseClassName}__admins-view`}>
                                    {admins.map((admin, index) => (
                                        <p key={admin}>
                                            <span>{` ${admin.firstName} `}</span>
                                            <span>{` ${admin.lastName} `}</span>
                                            <span><a className='byzzer-link'
                                                     href={`mailto:${admin.email}`}>{` ( ${admin.email} ) `}</a></span>
                                        </p>

                                    ))}
                                </div>


                                to upgrade your member type to user.
                            </p>
                        </>
                    ),
                    className: `${baseClassName}__viewer-access-modal`,
                    headerType: 'normal'
                });
            }
            return;
        }
        if (changeReport) {
            handleChangeReport('subscription', sku);
        } else {
            setLoading(true);
            try {
                const {premiumReports, basicReports} = await getMySubscriptionUsage();

                if (reportType === 'smart') {
                    if (premiumReports.limit === 0) {
                        setLoading(false);
                        alert({
                            onResolve: () => {
                            },
                            content: (
                                <>
                                    <p>
                                        Your team does not have any run counts left for this type of report. Ask your
                                        admin to allocate runs in order to use this report.
                                    </p>
                                </>
                            ),
                        });
                    } else if (premiumReports.limit - premiumReports.used <= 0) {
                        setLoading(false);
                        alert({
                            onResolve: () => {
                            },
                            content: (
                                <>
                                    <p>You have used all remaining purchased credits for the selected report..</p>
                                </>
                            ),
                        });
                    } else {
                        navigate(`/dashboard/configure_report`, {
                            params: {
                                runType: 'subscription',
                                sku,
                            },
                        });
                    }
                } else {
                    if (basicReports && basicReports.limit - basicReports.used < 1) {
                        setLoading(false);
                        alert({
                            content: (
                                <p>
                                    Your team does not have any run counts left for this type of report. Ask your admin
                                    to allocate runs in order to use this report.
                                </p>
                            ),
                        });
                    } else if (basicReports && basicReports.limit - basicReports.used <= 0) {
                        setLoading(false);
                        alert({
                            content: (
                                <>
                                    <p>You have used all remaining purchased credits for the selected report.</p>
                                </>
                            ),
                        });
                    } else {
                        navigate(`/dashboard/configure_report`, {
                            params: {
                                runType: 'subscription',
                                sku,
                            },
                        });
                    }
                }
            } finally {
                setLoading(false);
            }
        }
    };

    function reportFiltersShowHide() {
        setShowReportFilters((current) => !current);
    }

    const allReportsBtnType = !showAllReports ? 'negative' : '';
    const subscribedReportsBtnType = showAllReports ? 'negative' : '';

    return (
        <div className={wrapperClass}>
            <ByzzerMask show={loading} loading={loading}/>
            <div className={`${baseClassName}__header-wrapper`}>
                <div className={`${baseClassName}__usage`}>
                    {loading && <>Calculating</>}
                    {!loading
                        &&
                        (!(subscription?.active) ? (
                                <p>
                                    {SubscriptionInfo.SUBSCRITPION_EXPIRED}
                                </p>
                            ) : (
                                <p>
                                    You have{' '}
                                    <span>
                                        {toRunsText(remainingCoreRuns)} core report
                                        run{remainingCoreRuns === 1 ? '' : 's'} 
                                    </span>
                                        {' '}and{' '}
                                    <span>
                                        {toRunsText(remainingSmartRuns)} smart report
                                        run{remainingSmartRuns === 1 ? '' : 's'}{' '}
                                        left{' '}
                                    </span>{' '}
                                    in your subscription.
                                    {remainingCoreRuns === 0 &&
                                        ' Reach out to your sales associate or contact us on chat to purchase more runs.'}
                                </p>
                            )
                        )}
                </div>
                {/* <ToggleGroup options={reportTypes} value={showAllReports} onChange={setShowAllReports}  ></ToggleGroup> */}
                <div className={'inclusion-toggle'}>
                    <div className={'inclusion-toggle__control'}>
                        <ByzzerButton
                            className={classnames('inclusion-toggle__control-label')}
                            onClick={() => setShowAllReports(true)}
                            type={allReportsBtnType}
                        >
                            All Reports
                        </ByzzerButton>
                        <ByzzerButton
                            className={classnames('inclusion-toggle__control-label')}
                            onClick={() => setShowAllReports(false)}
                            type={subscribedReportsBtnType}
                        >
                            Subscribed Reports
                        </ByzzerButton>
                    </div>
                </div>
            </div>
            <div className={`${baseClassName}__content`}>
                <div className="report-groups">
                    {reports.length > 0 &&
                        sectionHeaders.map((group, index) => {
                            const sectionReports = filteredReports.filter((report) => isReportInGroup(report, group));
                            if (!sectionReports.length) {
                                return <Fragment key={group}/>;
                            }

                            return (
                                <div className={'report-group'} key={group}>
                                    <div className="report-group__title">{group}</div>
                                    <div className="report-group__cards">
                                        {sectionReports.map((report) => {
                                            const {sku, title, description, thumbnailUrl, reportType, sectionHeader} = report;
                                            // Todo: Create badge/badgetype in alby
                                            const reportTypeBadge = sectionHeader?.trim()?.toLowerCase() === 'wellness reports' ? 'Wellness' : snakeCaseToTitleCase(reportType);
                                            return (
                                                <ByzzerCard
                                                    key={sku}
                                                    onClick={() => onReportClick(report)}
                                                    shouldTrackClick={true}
                                                    trackClickName={`My Reports Report (${report.title}) clicked`}
                                                    title={title}
                                                    description={description}
                                                    thumbnailUrl={thumbnailUrl}
                                                    thumbnailSize={'lg'}
                                                    isLocked={!subscribedSkus.includes(sku)}
                                                    actions={
                                                        <>
                                                            <FavoriteIcon
                                                                trackClick={`Favorited Report clicked`}
                                                                selected={favoriteSkus.includes(sku)}
                                                                onClick={() => onFavoriteClick(sku)}
                                                            />
                                                            <TipIcon
                                                                trackClick={`Report Info (${title}) clicked`}
                                                                onClick={() => {
                                                                    setReportInformationView(!reportInformationView);
                                                                    setSelectedReport(report);
                                                                }}
                                                            />
                                                        </>
                                                    }
                                                    badges={[reportTypeBadge]}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    {reports.length === 0 && !loading && (
                        <div className="ad-hoc-reports__label">No Subscription reports found.</div>
                    )}
                </div>

                        <StackedReportFilters
                            reportHistory={allReports}
                            favoriteSkus={favoriteSkus}
                            onChange={setFilterType}
                            onFilter={setSkuFilter}
                            from="subscription-reports"
                            changeReport={changeReport}
                        />
                </div>
            {selectedReport?.title && (
                <ByzzerModal
                    show={reportInformationView}
                    onClose={() => setReportInformationView(!reportInformationView)}
                    heading={selectedReport?.title}
                    size={'large'}
                    headerType={'normal'}
                    extraIcon={
                        canRunReports &&
                        subscribedSkus.includes(selectedReport.sku) && (
                            <ByzzerButton label={'Run Report'} onClick={() => onReportClick(selectedReport)}/>
                        )
                    }
                >
                    <ReportInformation
                        reportData={selectedReport}
                        sku={selectedReport.sku}
                        disabled={!canRunReports || !subscribedSkus.includes(selectedReport.sku)}
                        url={
                            selectedReport?.metadata?.detailsUrl ||
                            `${reportDescriptionUrl}/${selectedReport?.sku}.html`
                        }
                        runReport={() => onReportClick(selectedReport)}
                    />
                </ByzzerModal>
            )}
        </div>
    );

    function isReportInGroup({sku, metadata}, group) {
        const isInFree = isFree && subscribedSkus.includes(sku);
        // this will group free reports as well as exclude free reports from the normal grouping
        return (
            (isInFree && group === FREE_GROUP_TITLE) || (!isInFree && group === metadata.reportOptions?.sectionHeader)
        );
    }
}

export default SubscriptionReport;
