export const alertConfigs = [
    {
        title: 'Pricing Alert',
        description: '',
        sectionHeader: '',
        alertType: 'Core',
        productSku: '21',
        productSelectors: {
            brand: {
                required: true,
            },
            categories: {
                required: true,
                limit: -1,
                multi: true,
            },
        },
        marketSelectors: {
            required: true,
            limit: 1,
            multi: false,
        },
    },
    {
        title: 'Promotion Alert',
        description: '',
        sectionHeader: '',
        alertType: 'Core',
        productSku: '22',
        productSelectors: {
            brand: {
                required: true,
            },
            categories: {
                required: true,
                limit: -1,
                multi: true,
            },
        },
        marketSelectors: {
            required: true,
            limit: 1,
            multi: false,
        },
    },
    {
        title: 'Market Share Alert',
        description: '',
        sectionHeader: '',
        alertType: 'Core',
        productSku: '19',
        productSelectors: {
            brand: {
                required: true,
            },
            categories: {
                required: true,
                limit: -1,
                multi: true,
            },
        },
        marketSelectors: {
            required: true,
            limit: 1,
            multi: false,
        },
    },
    {
        title: 'Distribution Alert',
        description: '',
        sectionHeader: '',
        alertType: 'Core',
        productSku: '20',
        productSelectors: {
            brand: {
                required: true,
            },
            categories: {
                required: true,
                limit: -1,
                multi: true,
            },
        },
        marketSelectors: {
            required: true,
            limit: 1,
            multi: false,
        },
    },
    {
        title: 'Growth Alert',
        description: '',
        sectionHeader: '',
        alertType: '',
        productSku: '450',
        productSelectors: {
            brand: {
                required: true,
            },
            categories: {
                required: true,
                limit: -1,
                multi: true,
            },
        },
        marketSelectors: {
            required: true,
            limit: 1,
            multi: false,
        },
    },
    {
        title: 'Category Performance',
        description: '',
        sectionHeader: '',
        alertType: 'Core',
        productSku: '237',
        productSelectors: {
            brand: {
                required: false,
            },
            categories: {
                required: true,
                limit: -1,
                multi: true,
            },
        },
        marketSelectors: {
            required: true,
            limit: 1,
            multi: false,
        },
    },
    {
        title: 'Brand Trend',
        description: '',
        sectionHeader: '',
        alertType: 'Smart',
        productSku: '451',
        productSelectors: {
            brand: {
                required: true,
            },
            categories: {
                required: true,
                limit: 0,
                multi: false,
            },
            multi: true,
            limit: 10,
        },
        marketSelectors: {
            required: true,
            limit: 1,
            multi: false,
        },
    },
    {
        title: 'Market Trend',
        description: '',
        sectionHeader: '',
        alertType: 'Smart',
        productSku: '452',
        productSelectors: {
            brand: {
                required: true,
            },
            categories: {
                required: true,
                limit: -1,
                multi: true,
            },
        },
        marketSelectors: {
            required: true,
            limit: 10,
            multi: true,
        },
    },
    {
        title: 'Category Trends (targeting ecos)',
        description: '',
        sectionHeader: '',
        alertType: 'Smart',
        productSku: '453',
        productSelectors: {
            brand: {
                required: false,
            },
            categories: {
                required: true,
                limit: -1,
                multi: true,
            },
            characteristicFilter: {
                limit: 5,
            },
        },
        marketSelectors: {
            required: true,
            limit: 1,
            multi: false,
        },
    },
    {
        title: 'Distribution Opportunity',
        description: '',
        sectionHeader: '',
        alertType: 'Smart',
        productSku: '454',
        productSelectors: {
            brand: {
                required: true,
            },
            categories: {
                required: true,
                limit: -1,
                multi: true,
            },
        },
        marketSelectors: {
            required: true,
            limit: 20,
            multi: true,
            requiredRemMarketForEachRetailer: true,
        },
    },
    {
        title: 'Pricing Opportunity',
        description: '',
        sectionHeader: '',
        alertType: 'Smart',
        productSku: '455',
        productSelectors: {
            brand: {
                required: true,
            },
            categories: {
                required: true,
                limit: -1,
                multi: true,
            },
        },
        marketSelectors: {
            required: true,
            limit: 10,
            multi: true,
        },
    },
    {
        title: 'Smart Assortment Actions',
        description:
            'The Assortment Smart Action report shows CPG brands the impact of adding or expanding their presence in retailers assortments and recommends action',
        sectionHeader: '',
        alertType: 'Smart',
        productSku: '456',
        productSelectors: {
            brand: {
                required: true,
            },
            categories: {
                required: true,
                limit: -1,
                multi: true,
            },
        },
        marketSelectors: {
            required: true,
            limit: 1,
            multi: false,
        },
    },
    {
        title: 'Smart Pricing Actions',
        description:
            'The Pricing Smart Action report helps CPG brands determine their products’ ideal pricing with competitive analysis and recommendations.',
        sectionHeader: '',
        alertType: 'Smart',
        productSku: '457',
        productSelectors: {
            brand: {
                required: true,
            },
            categories: {
                required: true,
                limit: -1,
                multi: true,
            },
        },
        marketSelectors: {
            required: true,
            limit: 1,
            multi: false,
        },
    },
    {
        title: 'Smart Promotion Actions',
        description:
            'The Promotion Smart Action report provides CPG brands with the insights and recommendations they need to deploy effective promotions at the right time',
        sectionHeader: '',
        alertType: 'Smart',
        productSku: '458',
        productSelectors: {
            brand: {
                required: true,
            },
            categories: {
                required: true,
                limit: -1,
                multi: true,
            },
        },
        marketSelectors: {
            required: true,
            limit: 1,
            multi: false,
        },
    },
    {
        title: 'Smart New Market Actions',
        description:
            'The New Market Smart Action report helps CPG brands see how their products align with the market based on category performance and competitive landscape',
        sectionHeader: '',
        alertType: 'Smart',
        productSku: '459',
        productSelectors: {
            brand: {
                required: true,
            },
            categories: {
                required: true,
                limit: -1,
                multi: true,
            },
        },
        marketSelectors: {
            required: true,
            limit: 1,
            multi: false,
        },
    },
];
