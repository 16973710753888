import './PasswordValidations.scss';
import React, {useEffect, useState} from 'react';
import classnames from 'classnames';

const baseClassName = 'password-validations';

export default function PasswordValidations({className, password = '', ...props}) {

    const [hasValue, setHasValue] = useState(false);
    const [hasRightLength, setHasRightLength] = useState(false);
    const [hasUpperCase, setHasUpperCase] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);

    useEffect(() => {
        setHasValue(Boolean(password));
        setHasRightLength(password.length >= 12 && password.length <= 21);
        setHasUpperCase(/[A-Z]/.test(password));
        setHasNumber(/\d/.test(password));
        // eslint-disable-next-line no-useless-escape
        setHasSpecialCharacter(/[\-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(password));
    }, [password]);

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <div className={classnames(`${baseClassName}__validation`, {
                [`${baseClassName}__validation--${hasRightLength}`]: hasValue
            })}>
                Between 12 and 21 Characters
            </div>
            <div className={classnames(`${baseClassName}__validation`, {
                [`${baseClassName}__validation--${hasUpperCase}`]: hasValue
            })}>
                Has Uppercase
            </div>
            <div className={classnames(`${baseClassName}__validation`, {
                [`${baseClassName}__validation--${hasNumber}`]: hasValue
            })}>
                Has Number
            </div>
            <div className={classnames(`${baseClassName}__validation`, {
                [`${baseClassName}__validation--${hasSpecialCharacter}`]: hasValue
            })}>
                Has Special Character
            </div>
        </div>
    );
}

export function findPasswordErrors(password) {

    password = password?.trim() ?? '';
    const hasRightLength = password.length >= 12 && password.length <= 21;
    const hasCapitalLetter = /[A-Z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialCharacter = /[-!@#$%^&*()_+|~=`{}[\]:";'<>?,./]/.test(password);

    if (!(hasRightLength && hasCapitalLetter && hasDigit && hasSpecialCharacter)) {
        return {
            hasRightLength,
            hasCapitalLetter,
            hasDigit,
            hasSpecialCharacter
        }
    }
}
