import React from 'react';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { ByzzerButton } from '../form';
import './DataContextConfirmation.scss';
// import CloseIcon from '@material-ui/icons/Close';
/**
 *
 * @param {{openModel: boolean, onSelectMore: ()=>{}, onClickSkip: ()=>{}, onClickClose:()=>{}}} props input properties from parent control
 * @return Dialog Modal for confirmation
 */
function DataContextConfirmation({
    openModel,
    catAvailableCount,
    catSelectedCount,
    onSelectMore,
    onClickSkip,
    onClickClose,
}) {
    const baseClass = 'signup-model';
    return (
        <Modal open={openModel} onClose={onClickClose}>
            <Fade in={openModel}>
                <div className={baseClass}>
                    <div className={`${baseClass}_data-context-close`} onClick={onSelectMore} />
                    {catSelectedCount < catAvailableCount && (
                        <h1 className={`${baseClass}_data-context-header`}>Setup your data context</h1>
                    )}
                    {catSelectedCount < catAvailableCount && (
                        <div className={`${baseClass}_data-context-content`}>
                            <p className={`${baseClass}_data-context-content-p1`}>
                                You have not picked any cateories but your subscription includes {catAvailableCount}. Do
                                you want to add more now? You have access to update your categories for 72 hours. After
                                that, you will need to reach out to customer support to change your selections or add
                                more.
                            </p>
                            <div className={`${baseClass}_data-context-footer`}>
                                <ByzzerButton label={'Yes, select more categories'} onClick={onSelectMore} />
                            </div>
                            <p className={`${baseClass}_data-context-content-p2`} onClick={onClickSkip}>
                                Skip for now
                            </p>
                        </div>
                    )}
                    {catSelectedCount === catAvailableCount && (
                        <h1 className={`${baseClass}_data-context-header`}>Limit Reached</h1>
                    )}
                    {catSelectedCount === catAvailableCount && (
                        <div className={`${baseClass}_data-context-content`}>
                            <p className={`${baseClass}_data-context-content-p1`}>
                                Purchase a Subscription to choose more categories or unselect the previously selected
                                category.
                            </p>
                        </div>
                    )}
                </div>
            </Fade>
        </Modal>
    );
}

export default DataContextConfirmation;
