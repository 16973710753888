import React, {useEffect, useRef} from 'react';
import {ByzzerModal} from '@/components/form';
import {driverList} from '@/config/globalVars';
import {Grade} from "@/components/Grade/Grade";
import {ScorecardTableHeader} from "@/views/scorecard/ScorecardTableHeader";
import useState from "react-usestateref";

const TITLE_KEY_BY_TYPE = {
    Markets: 'market',
    Categories: 'category',
    Brands: 'brand'

}

function OpportunityTable({
                              type: dataType,
                              tableData,
                              filterTableData: onSort,
                              ...props
                          }) {
    const [statusInfo, setStatusInfo] = useState(false);
    const [sort, setSort, sortRef] = useState({
        by: 'sales',
        direction: -1
    })
    const [infoItem, setInfoItem] = useState(0);
    const [scores, setScores] = useState([])
    const [sortedScores, setSortedScores] = useState([]);

    useEffect(() => {
        let titleKey = TITLE_KEY_BY_TYPE[dataType];
        setScores(tableData
            .filter(({sales}) => sales !== 0)
            .map(score => ({
                ...score,
                title: score[titleKey]
            }))
        );
    }, [tableData, dataType]);

    useEffect(() => {

        if (sort) {
            let comparator;
            const {by, direction = 1} = sortRef.current;
            switch (by) {
                case 'sales':
                    comparator = (a, b) => (a.sales - b.sales) * direction;
                    break;
                case 'title':
                    comparator = (a, b) => a.title.localeCompare(b.title) * direction;
                    break;
                default:
                    comparator = (a, b) => (a[by] - b[by]) * direction;
            }
            setSortedScores([...scores.sort(comparator)]);
        } else {
            setSortedScores([...scores]);
        }
    }, [scores,sort])

    const updateSort = (by, direction) => {
        if (direction) {
            // explicitly set sort direction
            setSort({
                by,
                direction
            })
        } else if (sort.by === by) {
            // toggle sort direction
            setSort(prevState => ({
                by,
                direction: prevState.direction * -1
            }))
        } else {
            // use default asc order
            setSort({
                by,
                direction: 1
            })
        }
    }

    // console.log(scores.filter(item => item.sales !== "No Data").sort((a,b)=> b.sales - a.sales));
    // const [TableData, setTableData] = useState(scores);

    const onScoreClick = (type, item) => {
        props.onScoreClick(type, item, dataType);
    };

    function colorCode(score) {
        let classname = 'my-scorecard-opportunity__subclass-value-table-score-';
        if (score >= 500) classname = classname + 'green';
        else if (score >= 400 && score < 500) classname = classname + 'yellow';
        else if (score >= 300 && score < 400) classname = classname + 'orange';
        else if (score >= 200 && score < 300) classname = classname + 'red';
        else if (score < 200) classname = classname + 'dark-red';
        return classname;
    }
    const lastScrollTop = useRef(0);
    const handleScroll = (e) => {
        if(props?.handleScroll){
            const st = e.currentTarget.scrollTop;
            const sh = e.currentTarget.scrollHeight;
            const ch = e.currentTarget.clientHeight;
            const scrollPos = st / (sh - ch);
            if(st > lastScrollTop.current) {
                if(scrollPos >= 0.95 && scrollPos < 1 ){
                    props?.handleScroll(e)
                }
            }
            lastScrollTop.current = st;
        }
    }
    return (
        // <div className="my-scorecard-opportunity__subclass-value">
        <>
            <div className="my-scorecard-opportunity__subclass-value-info">
                To learn more about a score, click on the number in the scorecard.
            </div>
            <div className="my-scorecard-opportunity__subclass-value-container-insights" onScroll={(e) => handleScroll(e)}>
                <table className="my-scorecard-opportunity__subclass-value-table">
                    <thead>
                        <tr>
                            <ScorecardTableHeader
                                sortActive={'title' === sort.by}
                                sortDirection={sort.direction}
                                onTitleClick={() => updateSort('title')}
                                onSortClick={direction => updateSort('title', direction)}
                            >{dataType}</ScorecardTableHeader>
                            {driverList.map((driver, index) => (
                                <ScorecardTableHeader key={driver.key}
                                                    sortActive={driver.key === sort.by}
                                                    sortDirection={sort.direction}
                                                    onTitleClick={() => updateSort(driver.key)}
                                                    onSortClick={direction => updateSort(driver.key, direction)}
                                                    tipTitle={driver.name}
                                                    tip={driver.popupDescription}>
                                    {driver.name}
                                </ScorecardTableHeader>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                    {sortedScores.map((scores, index) => (
                            <tr key={`scores-${index}`}>
                                <td className="my-scorecard-opportunity__subclass-value-table-name">{scores.title}</td>
                                <td>{'$' + new Intl.NumberFormat('en-US').format(scores.sales.toFixed(0))}</td>
                                <td><Grade grade={scores.grade}/></td>
                                {scores.promotion && !scores.promotionNoData ? (
                                    <td
                                        className={colorCode(scores.promotion)}
                                        onClick={() => {
                                            onScoreClick(driverList[2], scores);
                                        }}
                                    >
                                        {scores.promotion}
                                    </td>
                                ) : (
                                    <td className="my-scorecard-opportunity__subclass-value-table-null">
                                        --
                                    </td>
                                )}
                                {scores.pricing && !scores.pricingNoData ? (
                                    <td
                                        className={colorCode(scores.pricing)}
                                        onClick={() => {
                                            onScoreClick(driverList[3], scores);
                                        }}
                                    >
                                        {scores.pricing}
                                    </td>
                                ) : (
                                    <td className="my-scorecard-opportunity__subclass-value-table-null">
                                        --
                                    </td>
                                )}
                                {scores.assortment && !scores.assortmentNoData ? (
                                    <td
                                        className={colorCode(scores.assortment)}
                                        onClick={() => {
                                            onScoreClick(driverList[4], scores);
                                        }}
                                    >
                                        {scores.assortment}
                                    </td>
                                ) : (
                                    <td className="my-scorecard-opportunity__subclass-value-table-null">
                                        --
                                    </td>
                                )}
                                {scores.product && !scores.newProductNoData ? (
                                    <td
                                        className={colorCode(scores.product)}
                                        onClick={() => {
                                            onScoreClick(driverList[5], scores);
                                        }}
                                    >
                                        {scores.product}
                                    </td>
                                ) : (
                                    <td className="my-scorecard-opportunity__subclass-value-table-null">
                                        --
                                    </td>
                                )}
                            </tr>
                        )
                    )}
                    </tbody>
                </table>
            </div>
            {/* <ByzzerModal
                show={statusInfo}
                onClose={() => setStatusInfo(!statusInfo)}
                closeOnClickOutside={true}
                size={'extra-large'}
                heading={driverList[infoItem].name}
                type={'info'}
                headerType={'none'}
            >
                <div className={'my-scorecard-opportunity__subclass-value-table-info'}>
                    {driverList[infoItem].popupDescription}
                </div>
            </ByzzerModal> */}
        {/* </div> */}
        </>
    );
}

export default OpportunityTable;
