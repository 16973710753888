import './DodProductFiltersStep.scss';
import React, {ReactNode, useMemo, useState} from "react";
import classnames from "classnames";
import {StepRef, WizardContent, WizardStep} from "@byzzer/ui-components";
import {DodProductFilterBuilder, DodProductFilterBuilderProps} from "@/components/DodConfigEditor/builders";
import {DodWizardActions} from '../DodWizardActions';
import { DodResourceLink } from '../../common/DodResourceLink';

export type DodProductFiltersStepProps = {
    title?: ReactNode;
    enabled?: boolean;
    exclude?: boolean;
} & DodProductFilterBuilderProps;

const baseClassName = 'dod-product-filters-step';

const tip = <>
    <p>Select which products to include in your run.</p>
    <p>Clicking the field name (such as Department, Category, or Brand) will allow you to choose certain values from the
        field in the center panel.</p>
    <p>This will filter the run to those selections, as well as include a column in the output for that field.</p>
    <DodResourceLink />
</>

export const DodProductFiltersStep = React.forwardRef<StepRef, DodProductFiltersStepProps>((
    {
        className,
        enabled,
        exclude,
        title = 'Products',
        onValidityChange,
        ...props
    }, ref) => {

    const [filtersValid, setFiltersValid] = useState<boolean>(false);
    const count = useMemo(() => {
        const { value } = props;
        let count = 0;

        for (let key in value) {
            if (key === 'markets' || key === 'timePeriods') continue;

            if (key === 'characteristics' || key === 'customCharacteristics' || key === 'ppgs') {
                const length = Object.keys(value[key]).length;
                if (length > 0) {
                    count += length;
                }
            } else {
                const { values, summedSelections } = value[key];
                if (values.length > 0 || summedSelections.length > 0) {
                    count++;
                }
            }
        }
        return count ? `(${count})` : '';
    }, [props.value]);

    function handleValidationChange(e: ByzzerValidityChangeEvent) {
        setFiltersValid(e.isValid)
        onValidityChange?.(e);
    }

    return <WizardStep className={classnames(baseClassName, className)} ref={ref}
                       id={'products'} exclude={exclude}
                       title={`${title} ${count}`} enabled={enabled}>
        <WizardContent>
            <DodProductFilterBuilder {...props} onValidityChange={handleValidationChange} tip={tip}/>
        </WizardContent>
        <DodWizardActions disableNext={!filtersValid}
                          helpTip={tip}
                          nextDisabledTip={'Please choose at least one category to continue.'}/>
    </WizardStep>
});
