import {createContext, ReactNode, useState} from "react";
import {AlertRunConfig} from "@/types/AlertRun";

export type AlertRunWizardState = {
    enableDemographics?: boolean;
    enableMarketTypePicker?: boolean;
    requiredMasterCompany?: string;
    requiredMarketGroup?: string;
    showRemainingMarkets?: boolean;
}

export type AlertRunConfigWizardContextValue = {
    value: AlertRunConfig;
    state?: AlertRunWizardState;
    onChange?: (name: keyof AlertRunConfig, value: any, data?: any) => void;
    sku?: string;
    runType?: RunType;
}

export const AlertRunConfigWizardContext = createContext<AlertRunConfigWizardContextValue>({
    value: {} as any
});
AlertRunConfigWizardContext.displayName = 'AlertRunConfigWizardContext'
