import ReactGA, {EventArgs} from "react-ga";
import React, {useEffect} from "react";
import {useUser} from "@/contexts/UserContext";
import {useAnalyticsEvent} from "@/analytics/AnalyticsContext";
import {googleAnalyticsConfig, gtmConfig} from "@/config";
import TagManager from 'react-gtm-module'

if (gtmConfig.gtmId) {
    TagManager.initialize({
        gtmId: gtmConfig.gtmId
    });
}

export type GoogleAnalyticsProps = {
    appId?: string;
    name?: string;
}

export function useGoogleAnalytics() {

    const {user} = useUser();
    const event = useAnalyticsEvent();

    useEffect(() => {
        const {appId} = googleAnalyticsConfig;
        if(!appId) return;

        ReactGA.initialize(appId);
    }, [])

    useEffect(() => {

        ReactGA.set({
            user_id: user?.id,
            dimension1: user?.id,
            dimension2: user?.companyId,
        });
    }, [user]);

    useEffect(() => {

        if (!event) return;

        if (event.type === 'navigation') {
            ReactGA.pageview(String(event.data!.path));
        } else {
            const gaEvent: EventArgs = {
                category: event.type,
                action: event.name!,
                ...eventDataToDimensions(event.data)
            };
            ReactGA.event(gaEvent);
        }
    }, [event]);
}

function eventDataToDimensions(data: Record<string, string | number> = {}): {
    dimension3?: string;
    dimension4?: string;
    dimension5?: string;
    dimension6?: string;
    dimension7?: string;
    dimension8?: string;
    dimension9?: string;
    dimension10?: string;
    dimension11?: string;
    dimension12?: string;
    dimension13?: string;
    dimension14?: string;
    dimension15?: string;
    dimension16?: string;
    dimension17?: string;
    dimension18?: string;
    dimension19?: string;
    dimension20?: string;
} {

    // we support a maximum 20 dimensions, 2 are consumed by the default properties for user and company id
    // we sort the keys to ensure the same properties are mapped to the same dimensions every time
    // we slice the sorted list so we only try to send the max of 20 dimensions
    return Object.keys(data).sort().slice(0, 18).reduce((dimensions, dimension, i) => ({
        ...dimensions,
        [`dimension${i + 3}`]: String(data[dimension])
    }), {});
}
