import React, { useEffect, useState } from 'react';
import { ColDef, ColumnState, IHeaderParams, SortDirection } from 'ag-grid-community';
import classnames from 'classnames';
import './CustomHeader.scss'

interface CustomHeaderParams extends IHeaderParams {
    setHiddenColumns: React.Dispatch<React.SetStateAction<{ colId: string; headerName: string }[]>>;
    triggerLayoutChange: (source?: string) => void;
    headerClassName?: string;
}

interface CustomColDef extends ColDef {
    isHidden?: boolean;
}

const baseClassName = 'byz-custom-header';

type ActiveStatus = 'active' | 'inactive';

export const CustomHeader = ({
    displayName,
    enableSorting,
    progressSort,
    column,
    setSort,
    columnApi,
    setHiddenColumns,
    triggerLayoutChange,
    headerClassName,
    ...props
}: CustomHeaderParams) => {
    const colDef = column.getColDef() as CustomColDef;
    const [ascSort, setAscSort] = useState<ActiveStatus>('inactive');
    const [descSort, setDescSort] = useState<ActiveStatus>('inactive');

    const sortOrder = ascSort === 'active' ? 'asc' : descSort === 'active' ? 'desc' : null;

    const onSortChanged = () => {
        const sort = column.getSort();
        setAscSort(sort === 'asc' ? 'active' : 'inactive');
        setDescSort(sort === 'desc' ? 'active' : 'inactive');
    };

    const handleExplicitSort = (order: SortDirection, event: any) => {
        event.stopPropagation();
        setSort(order, event.shiftKey);
    };

    useEffect(() => {
        column.addEventListener('sortChanged', onSortChanged);
        onSortChanged();
    }, []);

    const onSortRequested = (event: React.MouseEvent<HTMLSpanElement>) => {
        if (enableSorting) {
            progressSort(event.shiftKey);
        }
    };

    const handleHideClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.stopPropagation();

        if (!colDef) return;

        columnApi.setColumnVisible(column, false);

        setHiddenColumns((prev) => [
            ...prev,
            { colId: column.getColId(), headerName: column.getColDef().headerName ?? '???' },
        ]);
        triggerLayoutChange();
    };

    const sortArrow = () => {
        if (sortOrder === 'desc') {
            return <span className={classnames(`${baseClassName}__sort--desc`)}/>;
        } else if (sortOrder === 'asc') {
            return <span className={classnames(`${baseClassName}__sort--asc`)}/>;
        }
    }

    return (
        <div className={classnames(`${baseClassName}`)}>
            <span
                className={classnames(`${baseClassName}__label`, headerClassName)}
                onClick={onSortRequested}
                style={{ cursor: enableSorting ? 'pointer' : 'default' }}
            >
                {displayName}
                {sortArrow()}
            </span>
            <div className={classnames(`${baseClassName}__actions`)}>
                <span className={classnames(`${baseClassName}__hide`)} onClick={handleHideClick}/>
            </div>
        </div>
    );
};

export default CustomHeader;
