import React from 'react';
import {ByzzerModal} from '@/components/form/ByzzerModal';
import warningIcon from '@/images/icons/warningIcon.svg';
import './ByzzerErrorModal.scss';

export function ByzzerErrorModal({ showWarning, setShowWarning, headerType, contentType='error', warningMessage, size, messageType = 'Tip' }) {
    return (
        <>
            <ByzzerModal
                show={showWarning}
                onClick={() => setShowWarning(false)}
                size={size}
                headerType={headerType}
                contentType={contentType}
            >
                <div className={'byzzer-error-modal__container'}>
                    {contentType!=='warning' && <img
                        className={'byzzer-error-modal__container-icon'}
                        src={warningIcon}
                        alt={'warning'}
                    />}
                    <span className={'byzzer-error-modal__container-text'}>
                    {contentType==='warning' && <b>{messageType}:&nbsp;</b>}
                        <label className="byzzer-label">{warningMessage}</label>
                    </span>
                </div>
            </ByzzerModal>
        </>
    );
}

export default ByzzerErrorModal;
