import './DODLoader.scss';

export const ZoneLoader = () => {
    const scssClass = 'zone-loader';
    return (
        <>
            <div className={scssClass}>
                <div className="spinner" />
            </div>
        </>
    );
};
