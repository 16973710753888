import {createContext, ReactNode, useState} from "react";
import {ReportRunConfig} from "@/types/ReportRun";

export type ReportRunWizardState = {
    enableDemographics?: boolean;
    enableMarketTypePicker?: boolean;
    requiredMasterCompany?: string;
    requiredMarketGroup?: string;
    showRemainingMarkets?: boolean;
}

export type ReportRunConfigWizardContextValue = {
    value: ReportRunConfig;
    state?: ReportRunWizardState;
    onChange?: (name: keyof ReportRunConfig, value: any, data?: any) => void;
    sku?: string;
    runType?: RunType;
}

export const ReportRunConfigWizardContext = createContext<ReportRunConfigWizardContextValue>({
    value: {} as any
});
ReportRunConfigWizardContext.displayName = 'ReportRunConfigWizardContext'
