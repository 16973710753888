import './DodDesignYourLayout.scss';
import React, {useEffect, useCallback, useMemo, useRef, useState} from 'react';
import classnames from 'classnames';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import {useSearchParams} from 'react-router-dom';
import {DragDropContext, Droppable, DropResult, ResponderProvided} from 'react-beautiful-dnd';
import {useDodWizard} from "@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext";
import {RowColConfig} from "@/components/DodConfigEditor/types";
import {UndoState} from '@/components/DodConfigEditor/builders/DodLayoutBuilder/DodLayoutEditor/DodLayoutEditor';
import {DraggableDimension} from "./DraggableDimension";
import {DraggableProductDimensions} from "./DraggableProductDimensions";
import {DraggableDimensionValues} from "./DraggableDimensionValues";
import {DodDimensionType, DodFilters, DodLayoutConfig, DodRunConfig} from '@/types/DodRun';

const baseClassName = 'dod-design-your-layout';

export type DodDesignYourLayoutProps = {
    className?: string;
    name?: string;
    runConfig: DodRunConfig;
    value: DodLayoutConfig;
    onChange?(): void;
    handleUndoStates(config: UndoState): void;
};

const groupDisplayMapping: Record<DodDimensionType, string> = {
    products: 'Products',
    time_periods: 'Time Periods',
    markets: 'Markets',
    facts: 'Facts',
    date: 'Date'
};
const columnSelectionExclusionaryFilters: (keyof DodFilters)[] = [
    'departments',
    'superCategories',
    'categories',
    'subcategories',
    "manufacturers",
    'parentCompanies',
    'brands',
    'upcs',
    'productDescriptions'
]

export function DodDesignYourLayout({
                                        className,
                                        name,
                                        runConfig,
                                        value,
                                        onChange,
                                        handleUndoStates,
                                        ...props
                                    }: DodDesignYourLayoutProps) {

    const {rowColConfigs, timePeriodsReordered, setRowColConfigs, setRunConfig, setTimePeriodsReordered} = useDodWizard();
    const setRunConfigRef = useRef<React.Dispatch<React.SetStateAction<DodRunConfig>>>(setRunConfig);
    setRunConfigRef.current = setRunConfig;
    const [disableProductColumnSelection] = useState<boolean>(() => {
        return Object.entries(runConfig.filters)
            .filter(([type]) => columnSelectionExclusionaryFilters.includes(type as keyof DodFilters))
            .some(([, filter]) => filter.values === 'all');
    });

    const [searchParams] = useSearchParams();
    const mode = useMemo<string | null>(() => {
        return searchParams.get('mode');
    }, [searchParams]);

    const disableDueToApplyLayoutMode = mode === 'layout' && runConfig.layout.includeSubTotals;

    const productDimensions = useMemo<RowColConfig[]>(() => {
        return rowColConfigs.filter(v => v.type === 'products');
    }, [rowColConfigs]);

    const topLevelDimensions = useMemo<RowColConfig[]>(() => {
        // this will give us the main groups, it doesn't matter which of the "product" sub-dimensions we use because the
        // only value we care about is the axis and that will be same for all sub-dimensions
        return uniqBy(rowColConfigs, 'type');
    }, [rowColConfigs]);

    function handleTopLevelDimensionDragEnd(result: DropResult, provided: ResponderProvided) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        setRowColConfigs(rowColConfigs => {
            const clonedRowColConfigs = cloneDeep(rowColConfigs);
            // this is just reorder the top level dimensions which we will use to reorder the rowColConfigs later
            let from = result.source.index;
            let to = result.destination?.index;
            const [moved] = topLevelDimensions.splice(from, 1);
            topLevelDimensions.splice(to!, 0, moved);

            const updatedRowColConfigs: RowColConfig[] = [
                ...rowColConfigs.filter(v => v.type === topLevelDimensions[0].type),
                ...rowColConfigs.filter(v => v.type === topLevelDimensions[1].type),
                ...rowColConfigs.filter(v => v.type === topLevelDimensions[2].type),
                ...rowColConfigs.filter(v => v.type === topLevelDimensions[3].type),
            ];

            if(!isEqual(clonedRowColConfigs, updatedRowColConfigs)) {
                handleUndoStates({ rowColConfig: clonedRowColConfigs, layout: runConfig.layout });
            }
            return updatedRowColConfigs;
        });
    }

    const handleTopLevelDimensionChange = useCallback((tld: RowColConfig) => {

        let isTimePeriodsReordered: boolean | undefined = undefined;
        if(tld.type === 'time_periods' && tld.sortType !== 'default') {
            isTimePeriodsReordered = timePeriodsReordered
            setTimePeriodsReordered(true);
        }

        setRowColConfigs(rowColConfigs => {
            const clonedRowColConfigs = cloneDeep(rowColConfigs);
            handleUndoStates({
                rowColConfig: clonedRowColConfigs,
                layout: runConfig.layout,
                ...(isTimePeriodsReordered !== undefined && { timePeriodsReordered: isTimePeriodsReordered }),
            });

            const updatedRowColConfigs = rowColConfigs.map(config => config.dim === tld.dim ? tld : config);
            // ensure that axis change for products is applied to all of them
            if(tld.type === 'products') {
                updatedRowColConfigs.forEach(config => {
                    if(config.type === 'products') {
                        config.axis = tld.axis
                    }
                });
            }
            return updatedRowColConfigs;
        });
    }, [rowColConfigs, runConfig]);

    function handleProductDimensionsChange(dimensions: RowColConfig[]) {

        setRowColConfigs(rowColConfigs => {
            const clonedRowColConfigs = cloneDeep(rowColConfigs);

            const updatedRowColConfigs = [...rowColConfigs];
            const firstProductIndex = updatedRowColConfigs.findIndex(v => v.type === 'products');
            updatedRowColConfigs.splice(firstProductIndex, dimensions.length, ...dimensions);

            if(!isEqual(clonedRowColConfigs, updatedRowColConfigs)) {
                handleUndoStates({ rowColConfig: clonedRowColConfigs, layout: runConfig.layout });
            }
            return updatedRowColConfigs;
        });
    }

    function handleDimensionValueChange(e: ByzzerChangeEvent<string[]>): void {
        let isTimePeriodsReordered: boolean | undefined = undefined;
        if(e.name === 'timePeriods') {
            isTimePeriodsReordered = true;
            setTimePeriodsReordered(true);
        }
        setRowColConfigs(rowColConfigs => {
            const clonedRowColConfigs = cloneDeep(rowColConfigs);

            const updatedRowColConfigs: RowColConfig[] = rowColConfigs.map(config => config.dim === e.name ? {
                ...config,
                sortType: 'manual',
                values: e.value
            } : config);

            if(!isEqual(clonedRowColConfigs, updatedRowColConfigs)) {
                handleUndoStates({
                    rowColConfig: clonedRowColConfigs,
                    layout: runConfig.layout,
                    ...(isTimePeriodsReordered !== undefined && { timePeriodsReordered: isTimePeriodsReordered }),
                });
            }
            return updatedRowColConfigs;
        });
    }

    function getDisabledColumnToolTip() {
        if(disableDueToApplyLayoutMode) {
            return `This layout change requires your subtotals to be recalculated. Please modify your run
            instead of using the apply new layout option.`
        }

        if(disableProductColumnSelection) {
            return `Your selected values exceed the column limit.`
        }
    }

    const isDimensionPagedByWithSubtotals = (dimension: DodDimensionType) => {
        return (
            runConfig.layout.includeSubTotals &&
            [...runConfig.layout.rows, ...runConfig.layout.columns].some(
                (item) => item.type === dimension && item.pageBy
            )
        );
    };

    function getDisabledRowToolTip(dimension: DodDimensionType) {
        if(isDimensionPagedByWithSubtotals(dimension)) return "Subtotals are only compatible with columns when also placed in the page by."
        if(disableDueToApplyLayoutMode) {
            return `This layout change requires your subtotals to be recalculated. Please modify your run
            instead of using the apply new layout option.`
        }
    }

    function isColumnDisabled(dimension: DodDimensionType) {
        switch (dimension) {
            case 'products':
                const isProductsInColumn = runConfig.layout.columns.find((col) => col.type === dimension);
                return (!isProductsInColumn && disableDueToApplyLayoutMode) || disableProductColumnSelection;
            case 'markets':
                const isMarketsInColumn = runConfig.layout.columns.find((col) => col.type === dimension);
                return !isMarketsInColumn && disableDueToApplyLayoutMode;
            case 'time_periods':
                const isTimePeriodsInColumn = runConfig.layout.columns.find((col) => col.type === dimension);
                return !isTimePeriodsInColumn && disableDueToApplyLayoutMode;
            case 'facts':
                const isFactsInColumn = runConfig.layout.columns.find((col) => col.type === dimension);
                return !isFactsInColumn && disableDueToApplyLayoutMode;
        }
    }

    function isRowDisabled(dimension: DodDimensionType) {
        switch (dimension) {
            case 'products':
                const isProductsInRow = runConfig.layout.rows.find((col) => col.type === dimension);
                return !isProductsInRow && disableDueToApplyLayoutMode;
            case 'markets':
                const isMarketsInRow = runConfig.layout.rows.find((col) => col.type === dimension);
                return (!isMarketsInRow && disableDueToApplyLayoutMode) || isDimensionPagedByWithSubtotals(dimension);
            case 'time_periods':
                const isTimePeriodsInRow = runConfig.layout.rows.find((col) => col.type === dimension);
                return (!isTimePeriodsInRow && disableDueToApplyLayoutMode) || isDimensionPagedByWithSubtotals(dimension);
            case 'facts':
                const isFactsInRow = runConfig.layout.rows.find((col) => col.type === dimension);
                return !isFactsInRow && disableDueToApplyLayoutMode;
        }
    }

    useEffect(() => {
        if (!runConfig.layout.includeSubTotals) return;

        const pageByDimensions = new Set(
            [...runConfig.layout.rows, ...runConfig.layout.columns]
                .filter((item) => item.pageBy && ['markets', 'timePeriods'].includes(item.dim))
                .map((item) => item.dim)
        );

        const shouldUpdateConfig = runConfig.layout.rows.some((row) => pageByDimensions.has(row.dim));

        if (shouldUpdateConfig) {
            setRowColConfigs((prevConfigs) => {
                const updatedConfigs = prevConfigs.map((config) => {
                    if (
                        config.pageBy &&
                        config.axis === 'row' &&
                        (config.dim === 'markets' || config.dim === 'timePeriods')
                    ) {
                        return { ...config, axis: 'col' as 'row' | 'col' };
                    }
                    return config;
                });
                return updatedConfigs;
            });
        }
    }, [runConfig.layout.includeSubTotals, runConfig.layout.rows, runConfig.layout.columns]);

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <span className={`${baseClassName}__title`}>Design Your Layout:</span>
            <div className={`${baseClassName}__design-template`}>
                <DragDropContext onDragEnd={handleTopLevelDimensionDragEnd}>
                    <Droppable droppableId="droppable-master">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={`${baseClassName}__dimensions`}
                            >
                                {topLevelDimensions.map((config, index) => {
                                    const isProducts = config.type === 'products';
                                    const isFacts = config.type === 'facts';
                                    const disableColumn = isColumnDisabled(config.type);
                                    const disableRow = isRowDisabled(config.type);
                                    const disabledColumnToolTip = getDisabledColumnToolTip();
                                    const disabledRowToolTip = getDisabledRowToolTip(config.type);

                                    return (
                                        <DraggableDimension
                                            config={config}
                                            index={index}
                                            key={config.type}
                                            sortable={!isProducts && !isFacts}
                                            pageable={!isProducts}
                                            onConfigChange={handleTopLevelDimensionChange}
                                            label={groupDisplayMapping[config.type]}
                                            disableColumnSelection={disableColumn}
                                            disableRowSelection={disableRow}
                                            disabledColumnTip={disabledColumnToolTip}
                                            disabledRowTip={disabledRowToolTip}
                                        >
                                            {isProducts ? (
                                                <DraggableProductDimensions
                                                    dimensions={productDimensions}
                                                    onChange={handleProductDimensionsChange}
                                                />
                                            ) : (
                                                <DraggableDimensionValues
                                                    name={config.dim}
                                                    values={config.values}
                                                    onChange={handleDimensionValueChange}
                                                />
                                            )}
                                        </DraggableDimension>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    );
}

export default DodDesignYourLayout;
