import { TrackClick } from "@/analytics";

type OptionalTrackClickHOCProps = {
    shouldTrackClick?: boolean;
    children: JSX.Element;
    trackClickName?: string;
    trackClickData?: any;
};
// This is a higher order component that wraps the children in a TrackClick component when tracking is indicated.  Otherwise, it just returns the children.  
// Only use in a commonly used component where tracking requirements vary.  Otherwise, just use the TrackClick component directly.

export function OptionalTrackClickHOC({
    shouldTrackClick, 
    trackClickName, 
    trackClickData, 
    children
}: OptionalTrackClickHOCProps) {
    
    return shouldTrackClick && Boolean(trackClickName) ? (
        <TrackClick name={trackClickName!} data={trackClickData}>
            {children}
        </TrackClick>
    ) : children;
};

export default OptionalTrackClickHOC;

OptionalTrackClickHOC.displayName = 'OptionalTrackClickHOC';