import { UserAlertSummary } from "@/types/AlertRun";

const filterAlertsByStatus = (alerts: UserAlertSummary[]) =>{
    // Step 1: Aggregate status information by ID
    const statusMap = alerts.reduce((acc, obj) => {
        if (!acc[obj.id]) {
            acc[obj.id] = { hasFailed: false, hasOtherStatus: false };
        }
        if (obj.lastExecutionStatus === 'failed') {
            acc[obj.id].hasFailed = true;
        } else {
            acc[obj.id].hasOtherStatus = true;
        }
        return acc;
    }, {});

    // Step 2: Filter alerts based on aggregated information
    const result = alerts.filter((obj) => {
        const status = statusMap[obj.id];
        // Include object if no failed status exists or there are no other statuses
        return !(status.hasFailed && status.hasOtherStatus && obj.lastExecutionStatus === 'failed');
    });

    return result;
}

export default filterAlertsByStatus;