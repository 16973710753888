import './MarketRunConfigFiltersStep.scss';
import React, {ReactNode, useRef, useState} from "react";
import classnames from "classnames";
import {
    MarketRunConfigFilters,
    MarketRunConfigFiltersProps, MESSAGE_NO_CATEGORY_MATCH,
    ProductRunConfigFiltersRef
} from "@/components/ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigFilters";
import {AccordionWizard, StepRef} from "@byzzer/ui-components";

export type MarketRunConfigFiltersStepProps = {
    title?: ReactNode;
} & MarketRunConfigFiltersProps;

const baseClassName = 'market-run-config-filters-step';

export const MarketRunConfigFiltersStep = React.forwardRef<StepRef, MarketRunConfigFiltersStepProps>(
    ({className, title, ...props}, ref) => {


        const [filtersValid, setFiltersValid] = useState<boolean>(false);

        function handleValidationChange(e: ByzzerValidityChangeEvent) {
            setFiltersValid(e.isValid);
            if(e.errors?.includes('missing_remaining_markets')) {

            } else {

            }
        }

        return <AccordionWizard.Step className={classnames(baseClassName, className)} ref={ref} title={title}>
            <AccordionWizard.Header className={classnames({
                'report-run-config-wizard-header--valid': filtersValid
            })}>
                <h1 className={'report-run-config-wizard__step-title'}>{title}</h1>
            </AccordionWizard.Header>
            <AccordionWizard.Content>
                <MarketRunConfigFilters {...props} onValidityChange={handleValidationChange}/>
            </AccordionWizard.Content>
            <AccordionWizard.Actions disableNext={!filtersValid}


                                     nextDisabledTip={'You must choose all required values to continue.'}/>
        </AccordionWizard.Step>
    });
