import './ByzzerMenu.scss';
import React, {ReactNode, useRef} from "react";
import classnames from "classnames";
import Tippy, {TippyProps} from '@tippyjs/react';
import {useNavigate} from "react-router-dom";
import {Instance} from "tippy.js";
import {trackEvent} from "@/components/SiteAnalytics";
import {nanoid} from 'nanoid';

export type ByzzerMenuItem = {
    content: ReactNode;
    showIf?: boolean;
    onClick?: (item: ByzzerMenuItem) => void;
    to?: string;
    replace?: boolean;
    iconType?: string;
    disabled?: boolean;
    toolTipHelpText?: string;
    trackClick?:any
}

export type ByzzerMenuProps = {
    items: ByzzerMenuItem[];
    header?: ReactNode;
    footer?: ReactNode;
    trigger?: 'click' | 'hover';
    highlightItemOnHover?: boolean;
} & Partial<Omit<TippyProps, 'interactive'>>

const baseClassName = 'byzzer-menu';

export function ByzzerMenu(
    {
        className,
        offset = [0, 0],
        trigger = 'click',
        items,
        header,
        footer,
        children,
        highlightItemOnHover,
        ...props
    }: ByzzerMenuProps) {

    const navigate = useNavigate();
    const tippy = useRef<Instance | null>(null);

    function handleClick(item: ByzzerMenuItem) {
        item.onClick?.(item);
        if (item.to) {
            navigate(item.to, {replace: item.replace});
        }

        if(item.trackClick){
            trackEvent({
                type: 'click',
                data: ( item as ByzzerMenuItem ).trackClick,
                // name: _.isString(item.trackClick) ? item.trackClick : item.trackClick.name,
            })
        }
        tippy.current?.hide();
    }

    const content = <div className={`${baseClassName}__content`}>
        {header}
        {children ? children : items.map(item => {

            if (item.showIf === false) return null;

            return (
                <div
                    key={nanoid()}
                    className={classnames(
                        `${baseClassName}__item`, {
                            [`${baseClassName}__item--disabled`]: item?.disabled,
                            [`${baseClassName}__item--highlight`]: highlightItemOnHover
                        })}
                    onClick={() => handleClick(item)}
                    title={item?.toolTipHelpText ?? ''}
                >
                    {item.content}
                </div>
            );
        })}
        {footer}
    </div>

    return <Tippy className={classnames(baseClassName, className)}
                  trigger={trigger}
                  content={content}
                  offset={offset}
                  onCreate={instance => tippy.current = instance}
                  arrow={false}
                  interactive={true}
                  placement='bottom'
                  {...props}/>

}

export default ByzzerMenu;
