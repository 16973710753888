import { skipTimeGap } from "@/config/onBoarding.config";
import { differenceInHours, differenceInMinutes } from 'date-fns';
import { GuardArgs, RedirectExpiredUserArgs, RedirectToOnboardingArgs, adminUserHasNewQuote } from "./routes";
import { useLocation } from "react-router-dom";

export function authentication({ user, userQuote, subscription, categories, nsCompany, company, preSales, isMultiTenant, tenantId, loadingTenantData }: GuardArgs) {
    if (user && !company?.isDisabled) {


        // allows newly signed up users to proceed with verify/approval flow, otherwise stopped by the subscription check above
        if (user?.emailVerified === true && user?.isPendingApproval === true) {
            return '/approval_pending';
        }

        if (userShouldRedirectToOnboarding({ user,categories, nsCompany, company, preSales, isMultiTenant, tenantId, loadingTenantData, subscription })) {
            // Redirect admin/setup admin users upon login to category/brand selection page if no categories has been set
            return '/onboarding';
        }
       
        // allows users that are active, or expired but not yet past four weeks from exp dt, and not disabled, to proceed
        if (tenantId && !company?.isDisabled && (subscription?.active === true || shouldExpiredUserBeAllowedAccess({user, userQuote, subscription, tenantId}) )) {
            return '/dashboard';
        }
    }
}

export function chooseCompany({ user, tenantId, isMultiTenant }: GuardArgs) {
    if ((user && tenantId)) { // || isMultiTenant
        // return '/dashboard'
    }
}

export function switchCompany({ user, tenantId, isMultiTenant }: GuardArgs) {
    if (user && tenantId && !isMultiTenant) {
        return '/dashboard'
    }
}

export function subscriptionInactiveCheck({ subscription }: GuardArgs) {
    if (!subscription?.active) {
        return '/';
    }
}

export function configureAlert({ user }: GuardArgs) {
    if (user?.role === 'viewer') {
        return '/dashboard';
    }
}

export function demoEnvCheck() {
    // @ts-ignore
    if (import.meta.env.REACT_APP_BYZZER_ENV !== 'DEMO') {
        return '/dashboard';
    }
}

export function onboarding({ user, subscription, categories, nsCompany, company, preSales, loadingTenantData, userQuote }: GuardArgs) {
    // Added for free user experience, For free Group B user we need to hide onboarding steps
    const skipOnboarding = preSales && company?.features?.freeAccountGroup === 'B';

    if (skipOnboarding || loadingTenantData) {
        return '/dashboard';
    }


    if (user?.isPendingApproval) {
        return '/approval_pending';
    }

    // todo: decide whether the existence of the contract or the actual subscription should determine this, using the
    //       subscription could give access beyond the expiration but using the contract could make it look like the
    //       user doesn't have a subscription when they actually do because the contract may have failed to sync
    if (!subscription) {
        return '/choose_package';
    }

    if (user?.settings.showOffer) {
        return `/offer/${user?.settings?.showOffer}`;
    }
    // I am intentionally not including a check for user?.settings.onboardingComplete because I want to be to explicitly
    // navigate to these screens

    if (user?.settings.onboardingCompleted === 1 && !(user?.role === 'admin' && !categories?.length)) {
        return "/";
    }
}

export function onboardingCategories({ user, categories }: GuardArgs) {
    const userRole = user?.role?.toLowerCase()!;
    if (userRole === 'admin') {
        if (categories?.length) { // if admin and categories already selected, go to next step in process, currently invitations
            return 'invitations';
        }
    } else if (['viewer', 'user'].includes(userRole)) {
        if (categories?.length) {  // if non-admin, go to report defaults if categories have already been set up
            return 'report_defaults';
        }
    }
}

export function onboardingInvitations({ user, subscription }) {
        
        /**
        * Free users to get only category page while onbarding BYZ-12067 ( no invitation or reports page is needed)
        */
        const isFreeUserOnBoarded =!subscription?.active || (user?.role!=='admin' && Boolean(subscription?.metadata?.isFree)) || (Boolean(subscription?.metadata?.isFree)  && (user?.settings?.categoriesSelected===1 || user?.settings?.onboardingCompleted === 1))

        if(isFreeUserOnBoarded){
            return '/'
        } else if (user?.settings?.invitationsSent === -1) {
        if (user?.role === 'admin' && user?.settings?.reportDefaultsSelected === -1) { // Set up admins can again skip category selection and bypass report defaults too
            return '/';
        } else {
            return 'report_defaults';
        }
        // return 'report_defaults'; // logic has moved & been updated to evaluate user role and company categories prefs, in onboarding
    }
}
export function onboardingReportDefaults({ user, subscription }) {
        /**
    * Free users to get only category page while onbarding BYZ-12067 ( no invitation or reports page is needed)
    */
        const isFreeUserOnBoarded =!subscription?.active || (user?.role!=='admin' && subscription?.metadata?.isFree) || (subscription?.metadata?.isFree  && (user?.settings?.categoriesSelected===1 || user?.settings?.onboardingCompleted === 1))

        if(isFreeUserOnBoarded){
            return '/'
        }
    if (user?.settings?.reportDefaultsSelected === -1) {
    }
 }

export function onboardingAlerts() { }

export function viewReport({ user }) {
    if (!user) {
        return '/login';
    }
}

export function requirePasswordReset({ user }) {
    if (!user?.requirePasswordReset) {
        return '/dashboard';
    }
}

export function termsAndConditions({ user }) {
    if (user?.tcsAccepted) {
        return '/dashboard'
    }
}

export function dashboard({ user, categories, nsCompany, company, preSales, isMultiTenant, loadingTenantData, tenantId, subscription  }: GuardArgs) {

    if (user?.isPendingApproval) {
        return '/approval_pending';
    }

    if (!tenantId) {
        // tbd
    }

    if (loadingTenantData) {
        // currently not rerouted; ByzzerMask in App.tsx and/or Dashboard.tsx shows until loading is done
    }
    const isNotAdminUser = (user?.role !== 'admin' && subscription?.metadata?.isFree)
    const isFreeUserOnBoarded = subscription?.active||  (
          subscription?.metadata?.isFree && (
            (user?.settings?.onboardingCompleted === undefined && user?.role === 'admin') ||
            (
              user?.settings?.onboardingCompleted === 1 &&
              !categories?.length &&
              user?.role === 'admin' &&
              (!user?.settings?.onBoardingLastSkippedTime || 
                differenceInHours(new Date(), new Date(user?.settings?.onBoardingLastSkippedTime)) > skipTimeGap
              )
            )
          )
        
      );
    // Admin/Setup admin without subscription set up are to be navigated
    // to Category/Brand selection page if no categories have been set yet
    //Free users to get only category page while onbarding BYZ-12067.
    if (!isNotAdminUser && isFreeUserOnBoarded && userShouldRedirectToOnboarding({ user, categories, nsCompany, company, preSales, isMultiTenant, tenantId, loadingTenantData, subscription })) {
        return '/onboarding';
    }

    if (user?.settings?.showOffer && !loadingTenantData) {
        return `/offer/${user?.settings?.showOffer}`;
    }
}

export function verifyEmail({ user, subscription, tenantId }: GuardArgs) {

    if (user?.emailVerified && tenantId) {
        return '/dashboard';
    }
}

export function getApproved({ user, subscription }) {

    if (user?.isPendingApproval === false) {
        return '/dashboard';
    }
}

export function offerViewer({ user, subscription }: GuardArgs) {
}

export function choosePackage({ user, subscription, nsCompany }) {
    if (subscription) {
        return '/dashboard';
    }

    if (nsCompany?.orderPending) {
        return '/subscription_pending';
    }
}

export function subscriptionPending({ user, subscription, nsCompany, loadingTenantData }) {
    if (subscription || loadingTenantData) {
        return '/dashboard';
    }
    if (!nsCompany.orderPending) {
        return '/choose_package';
    }
}

export function quoteGuard({ user, userQuote, subscription }: GuardArgs) {
    // NOTE: We might required to validate the user, whether they accepted the quote or not during every login and redirect them, if they didn't accepted the quote
    if (!Boolean(userQuote?.quoteData)) {
        // return '/dashboard'
    }
}


function userShouldRedirectToOnboarding({ user, categories, nsCompany, company, preSales, isMultiTenant, tenantId, loadingTenantData, subscription }: RedirectToOnboardingArgs): boolean {
    return (
        Boolean(tenantId) && 
        !loadingTenantData && 
        Boolean(subscription?.active) &&
        Boolean(
            (!user?.settings?.onboardingCompleted && !(
                // this logic accounts for Setup admin created via CSR and has its categories selected, for BYZ-10649 and BYZ-9965.  Ideally CSR will flag setup admin with categories selected as having completed onboarding.
                user?.role === 'admin' &&
                categories?.length &&
                user?.settings?.invitationsSent === -1 &&
                user?.settings?.reportDefaultsSelected === -1
            )) ||
            (user?.settings?.onboardingCompleted === 1 && 
                user?.role === 'admin' &&
                !categories?.length && user?.settings?.categoriesSelected !== 1 && 
                (!user?.settings?.onBoardingLastSkippedTime || differenceInHours(new Date(), new Date(user?.settings?.onBoardingLastSkippedTime)) > skipTimeGap))
        ) && 
        !(preSales && company?.features?.freeAccountGroup === 'B') && // Added for free user experience
        // allow multi tenant users to access switch_company if they are in the onboarding flow
        // TODO: see if there's a better way to accomplish switch_company access for multi tenant users
        !(isMultiTenant && useLocation().pathname === '/dashboard/switch_company')
    )
}

function shouldExpiredUserBeAllowedAccess({user, userQuote, subscription, tenantId}: RedirectExpiredUserArgs) {
    return Boolean(
        tenantId &&
        subscription?.active === false &&
        (subscription?.pastExpirationGracePeriod === false ||
            // If the company has an open quote in Netsuite, the admin of the company should be allowed to login
            (subscription?.pastExpirationGracePeriod === true && adminUserHasNewQuote({userQuote, user})))
    );
}