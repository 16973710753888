
// TODO: Replace/refactor this asap.  This is mostly just a copy of the invitation editor.  We shouldnt need two files for this.  We should be able to use the same file and just pass in different props


import React, { useEffect, useState } from "react";
import { TipIcon } from "@/components/icons";
import {useTenantApi} from "@/hooks/useTenantApi";
import { validate as isValidEmail } from "email-validator";
import { openErrorModal } from "@/components/form/ByzzerModal";
import { create as createModal } from "react-modal-promise";
import classNames from "classnames";
import ByzzerModal2 from "@/components/modals/ByzzerModal2";
import { openTeamRoleInfo } from "@/components/TeamManager/TeamRoleInfo";
import { openTeamInfo } from "@/components/TeamManager/TeamInfo";
import { TrackClick } from '@/analytics';
import { useUser } from "@/contexts/UserContext";
import { ExternalUser } from "@/types/InvitationTypes";
import { ByzzerButton, ByzzerSelect, ByzzerTextInput } from "@byzzer/ui-components";
import { emailDomainFoundInDomainList } from "@/utils";


const teamRoleOptions = [
    { value: 'admin', display: 'Admin' },
    { value: 'user', display: 'User' },
    { value: 'viewer', display: 'Viewer' }
];

const baseClassName = 'subscription-users';

const onlyViewerRole = [{ value: 'viewer', display: 'Viewer' }]


// NOTE: We dont actually 'invite' consultants; we just add them to the company.  Invite should be renamed to 'add' or something similar, where possible

export function ConsultantInvitationEditor({ teamOptions, availableUserCredits, onResolve, members, preset, ...props }) {
    const { addExternalUserToCompany, getCompanyMultiTenancyForUserEmail } = useTenantApi();
    const { nsCompany : { domains }, isMultiTenant } = useUser();
    const [showingErrorModal, setShowingErrorModal] = useState(false);
    const [busy, setBusy] = useState(false)
    const [invitee, setInvitee] = useState<ExternalUser>({
        email: preset?.email ?? '',
        role: preset?.role ?? 'user',
        teamId: preset?.teamId
    });
    const [userAlreadyAdded, setUserAlreadyAdded] = useState(false);
    const [inviteeEmailValidity, setInviteeEmailValidity] = useState<{ // define this type; it should be the same one as the return type of getCompanyMultiTenancyForUserEmail
        userFound: boolean;
        multiTenantEnabled?: boolean;
    } | undefined>(preset ? { userFound: true, multiTenantEnabled: true } : undefined);

    async function onTeamRoleInfo() {
        openTeamRoleInfo()
    }

    async function onTeamInfo() {
        const result = await openTeamInfo()
        if (result.function === "redirect") {
            onCloseClick();
        }
    }

    const sendConsultantInvitation = async () => {
        try {
            setBusy(true);
            const invitationResult = await addExternalUserToCompany(invitee);
            onResolve(true);
        } catch (err: any) {
            setShowingErrorModal(true);
            await openErrorModal({
                title: 'Uh Oh',
                content: <>
                    <p>Something unexpected happened while sending your invitation.</p>
                </>,
                errorId: err.id
            });
            setShowingErrorModal(false);
        } finally {
            setBusy(false);
        }
    }

     const handleConfirmUserClick = async () => {
        // Todo: See if this can/should be done as part of the Grant Access step.  Doesnt necessarily need to be done separately
        setBusy(true);
        // 1. Email address is valid
        // 2. Domain isnt the same
        // 4. User is not already invited
        // 4. Has multi-tenancy enabled
        try {
            const emailAddressIsValid = isValidEmail(invitee?.email);
            const domainIsntTheSame = !emailDomainFoundInDomainList(invitee?.email, domains);
            if (emailAddressIsValid && domainIsntTheSame && !userAlreadyAdded) {
                const userHasMultiCompanyAccess = await getCompanyMultiTenancyForUserEmail(invitee?.email);
                setInviteeEmailValidity(userHasMultiCompanyAccess);
            } else {
                await openErrorModal({
                    title: 'Uh Oh',
                    content: <>
                        <p>Something unexpected happened while confirming this user.</p>
                    </>
                });
            }
        } catch (err: any) {
            await openErrorModal({
                title: 'Uh Oh',
                content: <>
                    <p>Something unexpected happened while confirming this user.</p>
                </>,
                errorId: err.id
            });
        } finally {
            setBusy(false);
        }
    }

    const handleSendClick = async () => {
        setBusy(true);
        try {
            await sendConsultantInvitation();
        } catch (err: any) {
            await openErrorModal({
                title: 'Uh Oh',
                content: <>
                    <p>Something unexpected happened while sending your invitation.</p>
                </>,
                errorId: err.id
            });
        } finally {
            setBusy(false);
        }
    }

    const missingInviteeInfo = Boolean(!invitee.email || !invitee.role || !invitee?.teamId);

    const showSendButton = Boolean(
        !missingInviteeInfo && 
        isValidEmail(invitee?.email) && 
        inviteeEmailValidity?.multiTenantEnabled
    );

    const showConfirmUserButton = !showSendButton;

    const disableConfirmUserButton = Boolean(
        !isValidEmail(invitee?.email) || 
        userAlreadyAdded ||
        inviteeEmailValidity?.userFound === false || 
        inviteeEmailValidity?.multiTenantEnabled === false
    );

    const disableSendInviteButton = Boolean(missingInviteeInfo || disableConfirmUserButton);

    function setTeamRole(role) {
        setInvitee(current => ({
            ...current,
            role
        }));
    }

    function setTeamId(teamId) {
        setInvitee(current => ({
            ...current,
            teamId
        }));
    }

    function onCloseClick() {
        onResolve(false);
    }

    useEffect(() => {
        setUserAlreadyAdded(members?.map((mbr) => mbr?.email ?? '')?.includes(invitee?.email));
    }, [invitee?.email]);

    function handleEmailChange(event: ByzzerChangeEvent<string>) {
        setInvitee(current => ({
            ...current,
            [event.name!]: event.value
        }));
        setInviteeEmailValidity(undefined);
    }
    
    function handleTeamChange(event: ByzzerChangeEvent<string>) {
        setInvitee(current => ({
            ...current,
            [event.name!]: event.value
        }));
    }

    function handleRoleChange(event: ByzzerChangeEvent<string>) {
        setInvitee(current => ({
            ...current,
            [event.name!]: event.value
        }));
    }

    const CannotAddUserMessage = () => {
        let message: string = '';
        if (inviteeEmailValidity?.userFound === false) {
            message = 'Attention: The user you are trying to add is not a Byzzer approved third-party advisor. Please use the "Add A Member" option to invite them to Byzzer.' // 'Email not found';
        } else if (inviteeEmailValidity?.multiTenantEnabled === false) {
            message = 'User is not permitted to join your company.';
        } else if (userAlreadyAdded) {
            message = 'User already added';
        }
        return <span className={classNames(`${baseClassName}__cannot-add-user`)}>{message}</span>;
    }

    function handleEmailBlur() {
        // if (invitee?.email && isValidEmail(invitee?.email)) {
            // tbd
        // }
    }

    return (
        // @ts-ignore
        <ByzzerModal2 className={classNames(`${baseClassName}__member-editor`)}>
            <ByzzerModal2.Header className={classNames(`${baseClassName}__modal-title`)} onClose={onCloseClick}>
                Add A Third Party
            </ByzzerModal2.Header>
            {/* @ts-ignore */}
            <ByzzerModal2.Content>
                <ByzzerTextInput
                    label={<span>Email </span>}
                    className={classNames(`${baseClassName}__modal-add-item-input`)}
                    name="email"
                    value={invitee.email}
                    onChange={handleEmailChange}
                    placeholder={'first.last@email.com'}
                    onBlur={handleEmailBlur}
                />
                <div className="member-editor__form-group">
                    <ByzzerSelect
                    className={classNames(`${baseClassName}__modal-add-item-input`)}
                        name={"role"}
                        value={invitee.role}
                        label={<h2>Member Role <TipIcon onClick={onTeamRoleInfo}/></h2>}
                        onChange={handleRoleChange}
                        placeholder={"Select a member role"}
                        options={availableUserCredits < 1 ? onlyViewerRole : teamRoleOptions}
                    />
                </div>
                <div className="member-editor__form-group">                    
                    <ByzzerSelect
                        className={classNames(`${baseClassName}__modal-add-item-input`)}
                        name={'teamId'}
                        value={invitee?.teamId}
                        onChange={handleTeamChange}
                        placeholder={'Choose a Team'}
                        options={teamOptions} 
                        label={<h2>Choose a Team <TipIcon onClick={onTeamInfo}/></h2>}
                    />
                </div>
                <div className="member-editor__form-warning"><CannotAddUserMessage /></div>
            </ByzzerModal2.Content>
            <ByzzerModal2.Footer className={classNames(`${baseClassName}__modal-footer`)}>
                <TrackClick name={'Sub Members Add Members Invite clicked'}>
                    <ByzzerButton 
                        className={classNames(`${baseClassName}__modal-footer-cancel`)} 
                        type="negative"
                        onClick={() => onResolve(false)} 
                        label="Cancel" 
                    />
                </TrackClick>
                {showConfirmUserButton && (
                    <ByzzerButton 
                        label={busy ? 'Loading...' : 'Confirm User'}
                        disabled={disableConfirmUserButton || busy}
                        className={classNames(`${baseClassName}__modal-footer-invite`)}
                        onClick={handleConfirmUserClick}
                    />
                )}
                {showSendButton && (
                    <ByzzerButton 
                        label={busy ? 'Loading...' : "Grant Access"}
                        disabled={disableSendInviteButton || busy}
                        className={classNames(`${baseClassName}__modal-footer-invite`)}
                        onClick={handleSendClick}
                    />
                )}
            </ByzzerModal2.Footer>
        </ByzzerModal2>
    );
}
// @ts-ignore
export const openConsultantInvitationEditor = createModal(ConsultantInvitationEditor);