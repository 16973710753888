import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './TimePeriodStep.scss';
import { ByzzerButton, StepRef, WizardActions, WizardContent, WizardHeader, WizardStep } from "@byzzer/ui-components";
import TimePeriodFilters, { TimePeriodFiltersProps } from "./TimePeriodFilters";

export type TimePeriodStepProps = {
    data?: any;
    className?: string;
    title?: ReactNode;
    onComplete?: () => void;
    onCancel?: () => void;
    enabled?: boolean;
} & TimePeriodFiltersProps

export const TimePeriodStep = forwardRef<StepRef, TimePeriodStepProps>((
    {
        className,
        title,
        onCancel,
        onComplete,
        enabled,
        ...props
    },
    ref
) => {

    const baseClassName = 'byz-time-period-step';

    function handleCancelClick() {
        onCancel?.();
    }

    function handleComplete(): boolean {
        onComplete?.();
        return false;
    }

    return (
        <WizardStep 
            className={classnames(baseClassName, className)} 
            ref={ref}
            title={title}
            enabled
        >
            <WizardHeader>
                <h1 className={'report-run-config-wizard__step-title'}>{title}</h1>
            </WizardHeader>
            <WizardContent>
                <TimePeriodFilters 
                    {...props}
                />
            </WizardContent>
            <WizardActions 
                nextText="Save"
                beforeNext={handleComplete}
            >
                <ByzzerButton onClick={handleCancelClick} type={'negative'}>Cancel</ByzzerButton>
            </WizardActions>
        </WizardStep>
    )

});

export default TimePeriodStep;

TimePeriodStep.displayName = 'TimePeriodStep';