import { useState, useEffect } from "react";
import {useUser} from "@/contexts/UserContext";
import {useTenantApi} from "@/hooks/useTenantApi";

function getStorageValue(key, defaultValue) {
    // getting stored value
    return JSON.parse(localStorage.getItem(key)) ?? defaultValue;
}

export const useLocalStorageState = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
        return getStorageValue(key, defaultValue);
    });

    useEffect(() => {
        // storing input name
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};

export const useUserSettingsState = (key, defaultValue) => {

    const {user} = useUser();

     const {updateMySettings} = useTenantApi();

    const [value, setValue] = useState(() => {
        return user?.settings[key] ?? defaultValue
    });

    function saveAndSet(value) {
        setValue(value);
        user.settings[key] = value;
        updateMySettings({
            [key]: value
        })
    }

    useEffect(() => {
        setValue(user?.settings[key] ?? defaultValue);
    }, [user])

    return [value, saveAndSet];
}
