import React from 'react';
import classNames from 'classnames';
import './ByzzerModal2.scss';
import { OptionalTrackClickHOC } from "@/components/OptionalTrackClickHOC";

const ByzzerModal2 = ({className, wrapperClassName, children, type, show = true, ...props}) => {
    
    const wrapperClassNames = classNames(wrapperClassName, 'byzzer-modal2-wrapper', {
        'byzzer-modal2-wrapper--visible': show,
        [`byzzer-modal2-wrapper--${type}`]: type
    });

    const modalClassNames = classNames('byzzer-modal2', className, {
        [`byzzer-modal2--${type}`]: type
    });

    return (
        <div className={wrapperClassNames}>
            <div className={`byzzer-modal2 ${modalClassNames}`}  {...props}>
                {children}
            </div>
        </div>
    );
};

ByzzerModal2.Mask = ({className, show, children, ...props}) => {

    if (show) {
        return (
            <div className={classNames('byzzer-modal2__loading-mask', className)} {...props}>
                <svg className={'byzzer-modal2__loading-spinner'} viewBox="0 0 100 100"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle pathLength={1} cx="50" cy="50" r="45"/>
                </svg>
                {children && <div className={'byzzer-modal2__loading-text'}>{children}</div>}
            </div>
        );
    }

    return <></>
}

ByzzerModal2.Header = ({className, children, shouldTrackClose, trackCloseName, trackCloseData, closable = true, onClose, ...props}) => {
    return (
        <header className={classNames('byzzer-modal2-header', className)} {...props}>
            <h1 className={'byzzer-modal2-header__title'}>{children}</h1>
            {closable && (
                <OptionalTrackClickHOC shouldTrackClick={shouldTrackClose} trackClickName={trackCloseName} trackClickData={trackCloseData}>
                    <span className={'byzzer-modal2-header__close'} onClick={onClose}/>
                </OptionalTrackClickHOC>
            )}
        </header>
    )
}

ByzzerModal2.Content = ({className, children, ...props}) => {
    return (
        <main className={classNames('byzzer-modal2-content', className)} {...props}>
            {children}
        </main>
    )
}

ByzzerModal2.Footer = ({className, children, ...props}) => {
    return (
        <footer className={classNames('byzzer-modal2-footer', className)} {...props}>
            {children}
        </footer>
    )
}

export default ByzzerModal2;
