import './DodFactTypePicker.scss';
import React, {ReactNode, useState} from "react";
import classnames from "classnames";
import {DodFactFilterType, DodFilterType, DodProductFilterType} from '@/components/DodConfigEditor/types';
import { ByzzerChangeEventHandler } from '@byzzer/ui-components';
import { useApp } from '@/contexts/UserContext';
import {
    DodFilterTypeOptionGroup,
    DodFilterTypePicker,
    DodFilterTypePickerProps
} from '@/components/DodConfigEditor/common';
import { DodFilters } from '@/types/DodRun';
import { DodPreset } from '@/types/ApiTypes';


const baseClassName = 'dod-fact-type-picker';

export type DodFactTypePickerProps = {
    className?: string;
    value: DodFilterType<DodFactFilterType>;
    onChange: ByzzerChangeEventHandler<DodFilterType<DodFactFilterType>>;
    onPresetSelect: ByzzerChangeEventHandler<DodPreset>;
} & Partial<Omit<DodFilterTypePickerProps<DodFilters, DodFilterType<DodProductFilterType>>, 'initialOptionGroups'>>

function valueMatcher(a: DodFilterType<any>, b: DodFilterType<any>): boolean {
    return a?.type === b?.type && a?.data?.type === b?.data?.type;
}

export function DodFactTypePicker({className, onChange, ...props}: DodFactTypePickerProps) {

    const {factConfig} = useApp();

    const [optionGroups] = useState<DodFilterTypeOptionGroup<DodFilters, DodFactFilterType>[]>([
        {
            label: 'Core Facts',
            options: factConfig?.core?.map((val) => {
                if(val.factLists?.length > 0) {
                    return {
                        key: val.type,
                        label: val.value,
                        value: {
                            type: val.value,
                            data: {
                                type: "core"
                            }
                        }
                    }
                }
            }).filter(Boolean)
        },
        {
            label: 'Share Facts',
            options: factConfig?.share?.map((val) => {
                if(val.factLists?.length > 0) {
                    return {
                        key: val.type,
                        label: val.value,
                        value: {
                            type: val.value,
                            data: {
                                type: "share"
                            }
                        }
                    }
                }
            }).filter(Boolean)
        },
    ]);

    function handleChange(e) {
        onChange(e);
    }

    return (
        <DodFilterTypePicker
            className={classnames(baseClassName, className)}
            initialOptionGroups={optionGroups}
            onChange={handleChange}
            filterPlaceholder={'Search Fact Folders'}
            presetType={'fact'}
            presetGroupLabel={'My Saved Facts'}
            valueMatcher={valueMatcher}
            {...props}        />
    );
}

export default DodFactTypePicker;
