import { useUser } from "@/contexts/UserContext";
import { StoryRunConifg } from "@/types/StoriesTypes";
import { getFlattenedRunConfigOptionsBySku, getRunConfigOptionsBySku } from "./product.service";
import { MarketRunConfigOptions, ProductRunConfigOptions } from "@/types/RunConfigOptions";

export const useStoryRunService = () => {
    const { defaultRunConfig } = useUser();
    return {
        
        getStoryDefaultRunConfigBySku(sku?: string | null): Partial<StoryRunConifg> {
            const includedRunConfigValues: Partial<StoryRunConifg> = {};

            if (!sku) return includedRunConfigValues;

            const runConfigOptions = getRunConfigOptionsBySku(sku);
            const flattenedConfigOptions = getFlattenedRunConfigOptionsBySku(sku);

            const includeTimePeriod =
                flattenedConfigOptions.datatype !== 'omni' &&
                Boolean(runConfigOptions.find(({ type }) => type === 'time_period'));
            const hasMarketRunConfigOptions = Boolean(runConfigOptions?.find(({ type }) => type === 'market'));
            const defaultBrandsLength = defaultRunConfig?.brands?.length ?? 0;

            includedRunConfigValues.datatype = flattenedConfigOptions.datatype; // todo: check to see if this actually needed. keeping for now

            if ((flattenedConfigOptions as ProductRunConfigOptions).includeBrands) {
                const maxBrands = (flattenedConfigOptions as ProductRunConfigOptions)?.maxBrands ?? 0;
                includedRunConfigValues.brands =
                    ((flattenedConfigOptions as ProductRunConfigOptions).maxBrands && maxBrands < defaultBrandsLength)
                        ? []
                        : defaultRunConfig?.brands;
            }
            if ((flattenedConfigOptions as ProductRunConfigOptions).includeCategories) {
                includedRunConfigValues.categories =  Array.isArray(defaultRunConfig?.categories)? defaultRunConfig?.categories: [];
            }
            if ((flattenedConfigOptions as ProductRunConfigOptions).includeCharacteristics) {
                includedRunConfigValues.characteristics = defaultRunConfig?.characteristics;
            }
            if ((flattenedConfigOptions as ProductRunConfigOptions).includeFocusBrands) {
                const maxFocusBrands = (flattenedConfigOptions as ProductRunConfigOptions)?.maxFocusBrands ?? 0;
                includedRunConfigValues.focusBrands =
                   ((flattenedConfigOptions as ProductRunConfigOptions).maxFocusBrands &&
                    maxFocusBrands < defaultBrandsLength)
                        ? []
                        : defaultRunConfig?.brands;
            }

            if ((flattenedConfigOptions as ProductRunConfigOptions).includeDemographicDimensions) {
                includedRunConfigValues.demographicDimensions = [
                    { display: 'Age of Head of Household', value: 'HEAD_OF_HOUSEHOLD' },
                    { display: 'Household Size', value: 'HH_SIZE' },
                    { display: 'Income', value: 'INCOME' },
                    { display: 'Race', value: 'RACE' },
                ];
            }

            // markets only apply to rms reports for now.
            // todo: consider adding support different market types
            if (
                hasMarketRunConfigOptions &&
                flattenedConfigOptions.datatype === 'rms' &&
                !(flattenedConfigOptions as MarketRunConfigOptions).requireRemainingMarket
            ) {                
                includedRunConfigValues.markets = defaultRunConfig?.markets;
            }

            // time periods don't apply to omni reports b/c they use a different format
            // todo: consider adding support multiple time period types
            if (includeTimePeriod && defaultRunConfig?.timePeriod) {
                includedRunConfigValues.timePeriod = defaultRunConfig?.timePeriod;
            }

            return includedRunConfigValues;
        },
    }
}