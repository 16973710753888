import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './SimulatorChart.scss';
import { ByzChartConfig, ByzChartData, ByzChartType, SimulatorChartConfig, SimulatorChartValueConfig, SimulatorConfig, SimulatorValues, SimulatorDataset, SimulatorDatatype, } from "@/types/SimulatorTypes";
import {ByzzerChart} from "@/components/ByzzerChart";
import { getNextColor, rawValueToInput, rawValueToDisplay } from "@/views/simulators/utils";
import {merge} from 'lodash';
import { ScenarioSimulatorContext } from "../../../ScenarioSimulatorContext";

export type SimulatorChartProps = {
    name?: string;
    value?: Record<string, number | undefined>;
    datatypeMap: Record<string, SimulatorDatatype>;
    chartConfig: SimulatorChartConfig;
    className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const SimulatorChart = ({
    name,
    value,
    chartConfig,
    datatypeMap,
    className,
    ...props
}: SimulatorChartProps) => {

    const baseClassName = 'byz-simulator-chart';

    // const [internalValue, setInternalValue] = useState<SimulatorValues>(); // ByzChartData
    const [chartData, setChartData] = useState<ByzChartData>();
    const [type, setType] = useState<ByzChartType>(chartConfig?.type ?? 'bar');
    const [layout, setLayout] = useState<ByzChartConfig>(chartConfig?.layout ?? {});

    const {
        state: {
            collapsed
        }
    } = useContext(ScenarioSimulatorContext);

    useEffect(() => {
        if (value && Boolean(chartConfig?.values)) {
            setChartData(toChartData(
                // This is a temporary workaround and should be replaced asap (for 2023.11 or 2023.12 release) via a SimStudio configuration-related update.  A flag will be needed in the config somewhere
                // This will allow certain portions of the simulator to hide when 'Financials' is collapsed
                chartConfig?.values,
                value, 
                datatypeMap
            ));
        }
    }, [value, collapsed, chartConfig?.values]);

    useEffect(() => {
        if (chartData) {
        }
    }, [chartData]);

    useEffect(() => {
        setType(chartConfig?.type);
        setLayout(merge({
            plot: {
                barWidth: 'auto',
                valueBox: {
                    rules: [
                        {rule: '%v < 0', placement: 'bottom', text: '%data-display-value'},
                        {rule: '%v >= 0', placement: 'top', text: '+%data-display-value'}
                    ],
                    thousandsSeparator: ","
                }
            }
        }, chartConfig?.layout))
    }, [chartConfig])

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <ByzzerChart 
                className={`${baseClassName}__chart`}
                type={type}
                config={layout}
                data={chartData}
            />
        </div>
    );

};

function toChartData(configs: SimulatorChartValueConfig[] | undefined, values: Record<string, number | undefined>, datatypeMap: Record<string, SimulatorDatatype>): ByzChartData | undefined {

    const determineYAxisFormat = (datatypeMap: Record<string, SimulatorDatatype>, configs: SimulatorChartValueConfig[] | undefined): "%v" | "$%v" | "%v%" => {
        if (!configs) {
            return "%v"
        }
        const code = configs?.[0]?.code ? datatypeMap[configs[0].code] : 'integer';
        if (code === 'currency') {
            return "$%v"
        } else if (code ==='percent') {
            return "%v%"
        } else {
            return "%v"
        }
    }
    // dividing by a large number to make labels small to preserve chart width. need to hide tooltip as it would show this contrived number
    const chartValues = configs?.map((config) => Number(rawValueToInput(datatypeMap[config.code], values[config.code]).replace(/[^\d.-]/g, ''))/999999999) ?? []; 

    const absMin = Math.abs(Math.min(...chartValues));
    const absMax = Math.abs(Math.max(...chartValues));
    const yAxisSpacingFactor = 1.125; // basis for spacing the min y and max y and the tallest/shortest bars

    // to center values vertically along y axis, setting min and max to inverse of each other, with a buffer
    const yAxisMinValue = Math.max(absMin, absMax) * yAxisSpacingFactor * -1;
    const yAxisMaxValue = Math.max(absMin, absMax) * yAxisSpacingFactor;

    const xAxisItemRules ={
        fontSize: 10.5,
        wrapText: true,
        width: 150,
        overlap: true
    }

    const yAxisItemRules = {
        fontColor: 'white'
    }

    return {
        series: [{
            values: chartValues,
            styles: configs?.map((v, i) => ({
                backgroundColor: v.color || getNextColor(i)
            })),
            'data-display-value': configs?.map(v => rawValueToDisplay(datatypeMap[v.code], values[v.code])) ?? [],
        }],
        scaleX: {
            visible: true,
            labels: configs?.map(v => v.label),
            itemsOverlap: true,
            maxItems: 99, // labels will disappear if this isn't set to something. Doesnt need to be 99, but probably should be >= 5
            item: {
                rules: [
                  { rule: '%i%2 == 1', ...xAxisItemRules },
                  { rule: '%i%2 == 0', ...xAxisItemRules }
                ]
            },
            label: { // need this to keep the x-axis labels fully visible
                text: " ",
                fontSize: 20,
                offsetY: 10
            },
        },
        scaleY: {
            visible: true,
            // format: determineYAxisFormat(datatypeMap, configs), // disabling since we're not currently showing labels; it keeps width minimal
            // thousandsSeparator: "",
            values: [yAxisMinValue, 0, yAxisMaxValue], // having these min and max values will scale the chart equally from top to bottom.  there may be a better way to do this with zingchart
            decimals: 0, // vals with many decimal places will be wide and will squeeze chart horizontally
            item: {
                rules: [
                    { rule: '%v !== 0', ...yAxisItemRules }, // hiding Y axis labels
                    { rule: '%v === 0', ...yAxisItemRules }  // hiding Y axis labels
                ]
            },
            tick: {
                lineColor: 'white',
                lineWidth: 0,
                size: 0
            },
        },
        tooltip: { visible: false },
    } as ByzChartData;
}

export default SimulatorChart;

SimulatorChart.displayName = 'SimulatorChart';
