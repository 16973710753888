import React, {forwardRef, ReactNode, useEffect, useState} from "react";
import classNames from "classnames";
import {ByzzerChangeEventHandler, ByzzerSearch, ByzzerSelectOption, WithValue} from '@byzzer/ui-components';
import {RunConfigMarket} from "@/types/ReportRun";
import {useMarketService} from "@/services/market.service";
import './ByzzerMarketSearch.scss';
import { useUser } from '@/contexts/UserContext';

const baseClassName = 'byzzer-market-search';

export type ByzzerMarketSearchProps = {
    // searchTerm: string;
    name?: string;
    className?: string;
    placeholder?: string;
    maxSelections?: number;
    label?: ReactNode;
    value?: RunConfigMarket[];
    runType?: RunType;
    categories?: string[];
    /**
     * @deprecated use 'productSku' instead
     */
    reportSku?: string;
    productSku?: string;
    subMarketType?: string;
    disabled?: boolean;
    onlyRenderIf?: boolean;
    requiredMasterCompany?: string;
    requiredMarketGroup?: string;
    requireRemainingMarket?: boolean;
    showRemainingMarkets?: boolean;
    onChange?: ByzzerChangeEventHandler<RunConfigMarket[]>
}

export const ByzzerMarketSearch = forwardRef<WithValue<string | null>, ByzzerMarketSearchProps>((
    {
        name,
        className,
        label,
        value,
        placeholder,
        runType = 'subscription',
        categories,
        disabled,
        onChange,
        requiredMasterCompany,
        requiredMarketGroup,
        reportSku,
        productSku = reportSku,
        subMarketType,
        onlyRenderIf,
        maxSelections,
        requireRemainingMarket,
        showRemainingMarkets,
        ...props
    }, ref) => {

    if(onlyRenderIf === false) return null;
    const { features: { enableLimitedMarketSubscription = false } } = useUser();
    const {findMatchingMarketOptions, getCachedMarketNodeByName} = useMarketService();
    const [internalValue, setInternalValue] = useState<ByzzerSelectOption[]>([]);
    const [excludedMarketPaths, setExcludedMarketPaths] = useState<string[]>();

    async function searchMarkets(searchText: string): Promise<any[]> {

        const markets = await findMatchingMarketOptions({
            searchText,
            runType,
            categories,
            sku: productSku!,
            subMarketType,
        }).then((mrkts) => {
            const hasParentCompany = value?.some(item => item.isParentCompany === true);
            
            /**
             * This logic is for BYZ-10500 and bug BYZ-12992.
             * While searching the market manipulating it on the basis of hasParentCompany.
             * Returning the appropriate markets only.
             */
            return mrkts.reduce((acc: MarketOption[], option) => {
                if (!hasParentCompany) {
                    if (option.data?.parentCompany !== option.data?.name) {
                        // If parentCompany is not selected and the outlet is looking a child of custom parent
                        // i.e SAMS is child of custom parent Walmart Corp (Q117)
                        acc.push({ ...option, data: { ...option.data, isOutletOfParentCompany: true } });
                    } else {
                        // If parentCompany is not selected and the outlet is normal or independent
                        // i.e COSTCO, BJ S are the normal outlets
                        acc.push(option);
                    }
                } else {
                    // If parentCompany is selected then populating outlets only
                    if (option.data?.parentCompany === option.data?.name) {
                        acc.push(option);
                    }
                }
                return acc;
            }, []);
        });

        if(requiredMasterCompany) {
            return markets.filter(option => option.data?.masterCompany === requiredMasterCompany);
        }

        if(requiredMarketGroup) {
            return markets.filter(option => option.data?.marketGroup === requiredMarketGroup);
        }

        if (showRemainingMarkets) {
            return markets;
        }

        const baseMarkets = markets.filter((market) => !market?.data?.isRemaining)
        
        const searchResultOptions = baseMarkets?.map((baseMarket) => {
            if (requireRemainingMarket && baseMarket.data?.remainingMarketNames?.length) {
                return baseMarket.data?.remainingMarketNames?.map((remainingMarketName) => {
                    const cachedMarketNode = getCachedMarketNodeByName(remainingMarketName);
                    return { 
                        display: remainingMarketName ? `${baseMarket.display} vs ${remainingMarketName}` : baseMarket.display,
                        value: remainingMarketName ? `${baseMarket.value} vs ${remainingMarketName}` : baseMarket.value,
                        data: {
                            marketNode: baseMarket.data,
                            remainingMarketRunConfig: { // 
                                isRemaining: true, // this isn't required after this ticket goes in since the check isnt necessary if remaininig markets are always bundled with the primary market
                                name: remainingMarketName,
                                path: cachedMarketNode?.path,
                                key: cachedMarketNode?.key,
                                masterCompany: cachedMarketNode?.masterCompany,
                                marketGroup: cachedMarketNode?.marketGroup
                            },                           
                        }
                    }
                })
            }
            return {
                ...baseMarket,
                data: {
                    marketNode: baseMarket.data
                }
            }
            
        }).flat();

        return searchResultOptions;
    }
    
    const handleChange = (e: ByzzerChangeEvent<ByzzerSelectOption[]>) => {

        const newSelectedValues = e.value.map((marketItem): RunConfigMarket => {

                const {
                    name, 
                    path, 
                    key, 
                    masterCompany, 
                    marketGroup, 
                    isRemaining,
                    isParentCompany,
                    isOutletOfParentCompany,
                } = marketItem?.data?.marketNode ?? marketItem?.data;

                const {
                   remainingMarketRunConfig
                } = marketItem?.data;

                return {
                    name,
                    path,
                    key,
                    masterCompany,
                    marketGroup,
                    isParentCompany,
                    isOutletOfParentCompany,
                    ...(remainingMarketRunConfig ? {remainingMarketRunConfig} : {isRemaining})
                }
        
        });

        onChange?.({
            value: newSelectedValues,
            data: e?.data,
            name
        })
    }

    useEffect(() => {        
        const newInternalValue = value?.filter((item)=>!item?.disabledMarketNode)?.map((market: any) => {
            if (!requireRemainingMarket) {
                return {
                    display: market?.name,
                    value: market.path,
                    data: market
                };
            }
            return {
                display: market?.remainingMarketRunConfig?.name ? `${market?.name} vs ${market?.remainingMarketRunConfig?.name}` : market?.name,
                value: market?.remainingMarketRunConfig?.name ? `${market.path} vs ${market?.remainingMarketRunConfig?.name}` : market.path,
                data: market
            };
        }).flat()
        
        setInternalValue(newInternalValue ?? [])
        setExcludedMarketPaths(newInternalValue?.map((newSelectedValue) => newSelectedValue?.data.path) ?? []);
    }, [value])

    function handleExcludeMarketPaths(marketOptionToCheck, excludedMarketPaths) {
        return !excludedMarketPaths?.includes(marketOptionToCheck?.data?.marketNode?.path);
    }

    return (
        <ByzzerSearch
            ref={ref}
            name={name}
            className={classNames(baseClassName, className)}
            search={searchMarkets}
            onChange={handleChange}
            value={internalValue}
            label={label}
            placeholder={placeholder}
            maxSelections={Number(maxSelections) > 0 ? maxSelections : undefined}
            disabled={disabled}
            excludedOptions={excludedMarketPaths}
            exclusionFilter={handleExcludeMarketPaths}
        />
    );
});

export default ByzzerMarketSearch;

ByzzerMarketSearch.displayName = 'ByzzerMarketSearch';