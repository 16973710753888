import React, { useState } from 'react';
import EmailRecipientSelector from '@/components/EmailRecipient/EmailRecipientSelector';

const AlertRecipientSelector = (props) => {
    const [recipientType, setRecipientType] = useState(props?.selectorState?.notificationType);
    const [recipientList, setRecipientList] = useState(props?.selectorState?.notificationEmail);

    const changeRecipientType = (type) => {
        setRecipientType(type);
        updateSelectorsData('notificationType', type);
    };

    const setRecipients = (emailList, isUser) => {
        setRecipientList(emailList);
        if (isUser) {
            updateSelectorsData('userEmail', emailList);
        } else {
            updateSelectorsData('notificationEmail', emailList);
        }
    };

    const updateSelectorsData = (type, value) => {
        const dataObj = props.selectorState;

        if (type === 'notificationType') {
            dataObj.notificationType = value;
        } else if (type === 'notificationEmail') {
            dataObj.notificationEmail = value;
        } else {
            dataObj.notificationEmail = value;
            dataObj.userEmail = value;
        }
        props.setSelectorData(dataObj);
    };

    return (
        <>
            <EmailRecipientSelector
                title="Who do you want to receive this alert?"
                recipientType={recipientType}
                recipientList={recipientList}
                setRecipients={(list, isUser) => setRecipients(list, isUser)}
                changeRecipientType={(type) => changeRecipientType(type)}
                retainVal={props?.selectorState?.edit||props.fromBack}
            />
        </>
    );
};

export default AlertRecipientSelector;
