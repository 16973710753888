import { customCharacteristicsTemplateUrl } from '@/config/globalVars';
import {ByzzerButton} from '@byzzer/ui-components';
import React, { useState } from 'react';

export function PreUploadStep({ onComplete, busy, enableUploadStep, uploadHeading }) {
    const [heading, setHeading] = useState(uploadHeading);

    function onDownloadTemplateClick() {
        window.open(customCharacteristicsTemplateUrl);
    }

    async function onUploadClick() {
        enableUploadStep?.(true);
        setHeading('Upload Custom Characteristic');
    }

    return (
        <>
            {heading !== 'Upload Custom Characteristic' && (
                <div className="char-editor__info">
                    <>
                        <div className="char-editor__info-section">
                            Creating a definition with a UPC upload will lock your definition to that set of UPCs. So,
                            if new items come into the category, they will get automatically sorted into “NOT
                            APPLICABLE”, including new items in characteristic segments will require routine maintenance
                            by yourself & your team. You should use this option only if you are focused on a specific
                            set of UPCs that you do not expect to change over time.
                        </div>
                        <br />
                        <div className="char-editor__info-section">
                            If you want a dynamic custom characteristic definition, consider using the “Characteristics”
                            option.
                        </div>
                    </>
                    <div className={'char-editor__actions'}>
                        {/* @ts-ignore */}
                        <ByzzerButton
                            className={'char-editor__button'}
                            label={'Yes, I want to use a UPC upload'}
                            onClick={onUploadClick}
                        />
                    </div>
                </div>
            )}

            {heading == 'Upload Custom Characteristic' && (
                <div className="char-editor__info">
                    <div className="char-editor__info-section">
                        <u>Pro Tip: Use the upload option when you are creating segments that cannot be based off any existing characteristics.</u> You can upload a characteristic definition for the entire category or for a subset of UPCs. UPCs
                        not in the list will be categorized as “NOT APPLICABLE”. This characteristic will only apply to the uploaded UPC list. Any changes in the category UPC&apos;s will not be captured unless you upload a new list.
                    </div>
                    <div className="char-editor__info-section">
                        <h2>Your file format must be:</h2>
                        <ul>
                            <li>UPCs in Column A. Confirm that they are 12-digits with no check-digit at the end.</li>
                            <li>
                                Characteristic value in Column B. Confirm there are no special characters, especially
                                commas.
                            </li>
                        </ul>
                    </div>
                    <div className="char-editor__info-section">
                        <h2>Some tips/requirements for your mapping file:</h2>
                        <ul>
                            <li>Do not include headers</li>
                            <li>Upload in a .csv file type</li>
                            <li>
                                Segments must be mutually exclusive, so the same UPC can not be present in multiple
                                segments.
                            </li>
                        </ul>
                    </div>
                    <div className="char-editor__actions">
                        <ByzzerButton label={'Upload'} onClick={onComplete} busy={busy} />
                        <ByzzerButton type={'negative'} onClick={onDownloadTemplateClick}>
                            Download Template
                        </ByzzerButton>
                    </div>
                </div>
            )}
        </>
    );
}
