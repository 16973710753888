import './ReportFilterOverlay.scss';
import React, {useEffect} from 'react';
import useState from 'react-usestateref';
import {useTenantApi} from '@/hooks/useTenantApi';
import {
    alert,
    ByzzerInput,
    ByzzerMultiSelect,
    ByzzerRadio,
    ByzzerSearch,
    ByzzerSelect,
} from '@/components/form';
import { ByzzerButton } from '@byzzer/ui-components';
import {ByzzerMask} from '@/components/ByzzerMask/ByzzerMask';
import DeleteIcon from '@images/icons/DeleteIcon.svg';
import moment from 'moment';
import classnames from 'classnames';
import ByzzerSearchableSelect from '@/components/form/ByzzerSearchableSelect';
import {DatePicker, Tooltip} from 'antd';
import {TipIcon} from '@/components/icons';
import upArrow from '@images/icons/up-arrow.svg';
import {ByzzerSelect as ByzzerSelectComponent,} from '@byzzer/ui-components';
import {OptionalRequired} from '@/components/OptionalRequired/OptionalRequired';
import {defaultPpgId, defaultPpgName, timePeriodOptions, upcLevelPpgId, upcLevelPpgName} from '@/config/globalVars';
import calendarIcon from '@images/icons/calendar.svg';
import {cloneDeep, uniq} from 'lodash';
import {whatFiltersShouldIInclude} from '@/utils/overlayFilterUtils';
import {SCORECARDS_BY_ID} from '@/config/scorecard.config';
import {OmniBrandSearch} from '@/components/OmniBrandSearch';
import {OmniManufacturerSearch} from '@/components/OmniManufacturerSearch';
import {LimitedLabel} from '@/components/LimitedLabel';
import {capitalize} from '@/utils';
import { useUser } from '@/contexts/UserContext';

const baseClassName = 'report-filters-overlay';

// todo: split this overlay into scorecard filters and report filters
export function ReportFiltersOverlay({
                                         show = false,
                                         isScorecard,
                                         reportConfig = {},
                                         focusBrands,
                                         categories,
                                         // markets,
                                         timePeriod,
                                         characteristics,
                                         showAll,
                                         onApply,
                                         onCancel,
                                         scorecardId,
                                         sku,
                                         maxDataDate,
                                         title,
                                         subtitle,
                                         additionalCategories = [],
                                         isOmniReport,
                                         ...props
                                     }) {
    const {
        getCategoriesForBrands,
        getCategoryByBrand,
        getCompanyPreferences,
        getCustomChar,
        getMyPPGs,
        retailerHierarchies,
        getProductChars,
        getCharacteristicValues,
        getCustomCharacteristics,
        getCustomCharWithNASegment,
    } = useTenantApi();

    const {categories: subscriptionCategories} = useUser();

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedFocusBrands, setSelectedFocusBrands] = useState([]);
    const [upcCode, setUpcCode] = useState([]);
    const [numberOfWeeks, setNumberOfWeeks] = useState('');
    const [endDate, setEndDate] = useState('');
    const [timePeriodType, setTimePeriodType] = useState('');
    const [predefinedPeriod, setPredefinedPeriod] = useState('');
    const [hwThemeAttributeOptions, setHwThemeAttributeOptions] = useState([]);
    const [hwThemeAttributes, setHwThemeAttributes] = useState([]);
    const [categInFocusBrand, setCategInFocusBrand] = useState([]);
    const [customChars, setCustomChars] = useState([]);
    const [enableSubmit, setEnableSubmit] = useState(true);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [reportSelectorCharFilters, setreportSelectorCharFilters] = useState([]);
    const [productSet, setProductSet] = useState([]);
    const [markets, setMarkets] = useState([]);
    const [charFilters, setCharFilters, charFiltersRef] = useState([{}]);
    const [loading, setLoading] = useState(true);
    const [warningMessage, setWarningMessage] = useState('');
    const [contentType, setContentType] = useState('warning');
    const [showWarning, setShowWarning] = useState(false);
    const [includeProductFilters, setIncludeProductFilters] = useState(false);
    const [scorecardConfig, setScorecardConfig] = useState();
    const [characteristicOptions, setCharacteristicOptions] = useState([]);
    const [characteristicToValuesMap, setCharacteristicToValuesMap] = useState({});
    const [focusBrandCategories, setFocusBrandCategories] = useState([]);
    const [companyCategories, setCompanyCategories] = useState([]);
    const [includeFocusBrands, setIncludeFocusBrands] = useState(false);
    const [includeMultiMarket, setIncludeMultiMarket] = useState(false);
    const [includeSingleMarket, setIncludeSingleMarket] = useState(false);
    const [includeThemes, setIncludeThemes] = useState(false);
    const [includeUpcs, setIncludeUpcs] = useState(false);
    const [includePpgs, setIncludePpgs] = useState(false);
    const [includeTimePeriod, setIncludeTimePeriod] = useState(false);
    const [includeCategories, setIncludeCategories] = useState(false);
    const [includeCharacteristics, setIncludeCharacteristics] = useState(false);
    const [requireCharacteristic, setRequireCharacteristic] = useState(false);
    const [ppgs, setPpgs] = useState([]);
    const [ppgOptions, setPpgOptions] = useState([]);
    const [selectedPpg, setSelectedPpg] = useState();
    const [characteristicsBySegmentId, setCharacteristicsBySegmentId] = useState({});
    const [includeSubcategories, setIncludeSubcategories] = useState(false);
    const [focusBrandOptional, setFocusBrandOptional] = useState(false);
    const [isSubcategoryOptional, setIsSubcategoryOptional] = useState(false);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);
    const [charCodesData, setCharCodesData] = useState([]);
    const [includeOmniProducts, setIncludeOmniProducts] = useState(false);
    const [omniProductLevel, setOmniProductLevel] = useState('');
    const [omniFocusProduct, setOmniFocusProduct] = useState([]);

    const CUSTOM_CHAR_EXTENSION = ' (Custom)';
    const themePurchaseType = showAll
        ? 'product_addon_characteristic_theme'
        : 'subscription_addon_characteristic_theme';
    const DEFAULT_PPG_OPTION = {
        display: defaultPpgName,
        value: defaultPpgId,
    };

    const UPC_PPG_OPTION = {
        value: upcLevelPpgId,
        display: upcLevelPpgName,
    };
    const omniProductLevelOptions = [
        {display: 'Brand', value: 'brand'},
        {display: 'Manufacturer', value: 'manufacturer'}
    ];
    const maxOmniProducts = 10; // ToDo: This to be fetched from Alby config

    useEffect(() => {
        if (scorecardId) {
            const config = SCORECARDS_BY_ID[scorecardId];
            setScorecardConfig(config);
        }
    }, [scorecardId]);

    useEffect(() => {
        setSelectedPpg(reportConfig?.focusProductSelections?.ppgId);
        filterPPGsByCategory(reportConfig.focusProductSelections?.competitiveProductSet?.categories);
    }, [reportConfig.focusProductSelections?.competitiveProductSet?.categories, ppgs, isScorecard]);

    useEffect(() => {
        const isScorecard = Boolean(scorecardId);
        const {
            includeFocusBrands,
            includeMultiMarket,
            includeSingleMarket,
            includeThemes,
            includeUpcs,
            includePpgs,
            includeTimePeriod,
            includeCategories,
            includeCharacteristics,
            requireCharacteristic,
            includeSubcategories,
            focusBrandOptional,
            isSubcategoryOptional,
            includeOmniProducts,
        } = whatFiltersShouldIInclude(
            {
                ...reportConfig,
                ...scorecardConfig,
            },
            isScorecard,
            sku,
            isOmniReport
        );

        setIncludeFocusBrands(includeFocusBrands);
        setIncludeMultiMarket(includeMultiMarket);
        setIncludeSingleMarket(includeSingleMarket);
        setIncludeThemes(includeThemes);
        setIncludeUpcs(includeUpcs);
        setIncludePpgs(includePpgs);
        setIncludeTimePeriod(includeTimePeriod);
        setIncludeCategories(includeCategories);
        setIncludeCharacteristics(includeCharacteristics);
        setRequireCharacteristic(requireCharacteristic);
        setIncludeSubcategories(includeSubcategories);
        setFocusBrandOptional(focusBrandOptional);
        setIsSubcategoryOptional(isSubcategoryOptional);
        setIncludeOmniProducts(includeOmniProducts);

        setIncludeProductFilters(
            includeFocusBrands ||
            includeCategories ||
            includeThemes ||
            includeUpcs ||
            includeSingleMarket ||
            includeMultiMarket ||
            includeSubcategories ||
            includeOmniProducts
        );

        loadFilterPanelData();
    }, [reportConfig, scorecardId]);

    useEffect(() => {
        checkSubmitEnabled();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedCategories,
        markets,
        selectedFocusBrands,
        upcCode,
        charFilters,
        timePeriodType,
        predefinedPeriod,
        numberOfWeeks,
        endDate,
    ]);

    const loadPpgs = async () => {
        if (reportConfig.focusProductSelections?.ppgId || reportConfig.uncharged) {
            const ppgList = await getMyPPGs();
            if (ppgList) {
                setPpgs(ppgList);
                // setSelectedPpg(reportConfig?.ppgId ?? null);
            }
        }
    };

    const filterPPGsByCategory = (categories) => {
        if (ppgs?.length) {
            const filteredPpgs = ppgs
                .filter((ppg) => ppg.categories.some((category) => categories.includes(category)))
                .map(({id, title}) => ({
                    display: title,
                    value: id,
                }));

            setPpgOptions(
                [DEFAULT_PPG_OPTION, UPC_PPG_OPTION, ...filteredPpgs].sort((a, b) => {
                    return a.display.localeCompare(b.display, 'en', {sensitivity: 'base'});
                })
            );
        }
    };

    async function updateAvailableCategories() {
        const categories = await getCategoriesForBrands(selectedFocusBrands);
        setFocusBrandCategories(categories);
    }

    const loadFilterPanelData = async () => {
        try {
            setLoading(true);
            await loadPpgs();
            setReportViewData();
            await Promise.all([getChars()]);
            await loadSubcategoriesOptions();
            resetCharacteristicFilters();
            if (scorecardId) {
                await loadOtherFilterWrapperData();
            }
        } catch (err) {
            console.log(err)
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    function addCharFilter() {
        setCharFilters((state) => [
            ...state,
            {
                characteristic: '',
                condition: 'is',
                value: [],
            },
        ]);
    }

    const removeCharFilter = (index) => {
        if (index > 0) {
            let currentCharconditionsData = cloneDeep(charFilters);
            currentCharconditionsData.splice(index, 1);
            setCharFilters(currentCharconditionsData);
        }
    };

    const loadAllProductCharacteristics = async (productSet) => {
        // todo: refactor this so the logic is simpler, it feels like it's doing more than it needs to to accomplish its goal

        const [charsResp, customCharacteristics] = await Promise.all([getProductChars(productSet), getCustomCharacteristics()]);
        if (charsResp) {
            setCharCodesData(charsResp?.characteristics);
        }

        setCustomChars(customCharacteristics);
        const customCharsForCategories = customCharacteristics.filter((item) =>
            productSet?.categories?.map((categ) => categ.name)?.some((i) => item.categories.includes(i))
        );
        const customCharMap = {};
        const characteristicsBySegmentId = {};

        customCharacteristics?.forEach(({label, charValueGroups}) => {
            const key = label + CUSTOM_CHAR_EXTENSION;
            const values = [];
            charValueGroups?.forEach(({id, value}) => {
                values.push(value);
                characteristicsBySegmentId[id] = key;
            });
            customCharMap[key] = values;
        });
        const characteristics = [
            ...customCharsForCategories?.map((item) => item?.label + CUSTOM_CHAR_EXTENSION)?.sort((a, b) => a?.trim()?.toLowerCase()?.localeCompare(b?.trim()?.toLowerCase())),
            ...charsResp?.characteristics?.map((item) => item?.chr_display_name)?.sort((a, b) => a?.trim()?.toLowerCase()?.localeCompare(b?.trim()?.toLowerCase()))
        ];
        setCharacteristicToValuesMap(characteristics);
        setCharacteristicOptions(characteristics?.sort((a, b) => a?.localeCompare(b, 'en', {sensitivity: 'base'})));
        setCharacteristicsBySegmentId(characteristicsBySegmentId);
    };

    // todo: see the comment on extractCustomSegmentIds
    async function createCustomCharFromSegmentId({charId, segmentIds}) {
        let segmentNames = [];
        // const characteristic = customChars.find(({ charValueGroups }) =>
        //     charValueGroups.find((segment) => segment.id === id)
        // );
        const charByIdResp = await getCustomChar(charId);
        let segments = (charByIdResp?.type === 'UPC' ? charByIdResp?.groups : charByIdResp?.segments)?.filter((x) =>
            segmentIds.includes(x.id?.toString())
        );
        if (segments) {
            segmentNames.push(...segments.map((x) => String(x.name)));
        }

        // const segment = characteristic?.charValueGroups.find((segment) => segment.id === id);
        return {
            characteristic: charByIdResp?.label + CUSTOM_CHAR_EXTENSION,
            condition: 'is',
            value: segmentNames,
            valueOptions: (charByIdResp?.type === 'UPC' ? charByIdResp?.groups : charByIdResp?.segments)
        };
    }

    // todo: fix this, this should really just be using a display/value entry in the select where display is the name of
    // of the custom segment and the value is the id, all this these search are totally unnecessary.  But for some reason
    // the multiselect control doesn't properly support  display/value options and I don't have the mental capacity to
    // fix it right now
    const extractCustomSegmentIds = async (charFiltersData) => {
        let charFiltersTemp = JSON.parse(JSON.stringify(charFiltersData));
        let customCharsAndSegments = charFiltersTemp
            .filter((x) => x.characteristic?.includes(CUSTOM_CHAR_EXTENSION))
            .map((x) => ({
                characteristic: x.characteristic.substring(0, x.characteristic.length - CUSTOM_CHAR_EXTENSION.length),
                segments: x.value,
            }));
        let segmentIds = [];
        let segmentData = [];
        for (let i = 0; i < customCharsAndSegments.length; i++) {
            let customChar = customChars.find((x) => x.label === customCharsAndSegments[i].characteristic);
            const charByIdResp = await getCustomChar(customChar?.id,customCharsAndSegments[i].segments.includes('NOT APPLICABLE'));
            let segments = (charByIdResp?.type === 'UPC' ? charByIdResp?.groups : charByIdResp?.segments)?.filter((x) =>
                customCharsAndSegments[i].segments.includes(x.name)
            );
            if (segments) {
                segmentIds.push(...segments.map((x) => String(x.id)));
                segmentData.push({
                    charId: customChar?.id,
                    segmentIds: segments.map((x) => String(x.id))
                })
            }
        }
        return {segmentIds, segmentData};
    };

    const getChars = async (
        categories = reportConfig?.focusProductSelections?.competitiveProductSet.categories,
        row = 0
    ) => {
        setLoading(true);

        const productSetObj = {
            categories: (categories ?? []).map((name) => ({
                hierarchy: 'CATEGORY',
                name,
            })),
            themePurchaseType,
        };
        await loadAllProductCharacteristics(productSetObj);
        // let charfilters;
        // if (characteristicFilters.filter(x => x.characteristic !== undefined)?.length > 0) {
        //     charfilters = extractCharFiltersSelection(characteristicFilters)
        // } else {
        //     charfilters = characteristicFilters
        // }
        setLoading(false);
    };

    async function resetCharacteristicFilters() {
        const characteristicFilters = reportConfig?.focusProductSelections?.characteristicFilters ?? [];
        const customCharacteristicFilters = reportConfig?.focusProductSelections?.customCharacteristicFilters ?? [];
        const customSegmentIds = reportConfig?.focusProductSelections?.customSegmentIds ?? [];
        const customSegmentData = reportConfig?.focusProductSelections?.customSegmentData ?? [];
        const allCharacteristicFilters = [...characteristicFilters, ...customCharacteristicFilters];
        for (let index = 0; index < customSegmentData.length; index++) {
            const element = customSegmentData[index];
            allCharacteristicFilters.push(await createCustomCharFromSegmentId(element));
        }
        // customSegmentData.forEach(id => {
        // })

        if (allCharacteristicFilters.length) {
            let charFiltersData = [];
            setLoading(true);
            await Promise.all([
                allCharacteristicFilters.map(async (filter) => {
                    let filterItem = {...filter};
                    filterItem.valueOptions = await fetchCharsValues(
                        filter.characteristic,
                        selectedCategories?.length > 0
                            ? selectedCategories
                            : reportConfig.focusProductSelections?.competitiveProductSet?.categories
                    );
                    charFiltersData.push(filterItem);
                    setCharFilters([...charFiltersData]);
                }),
            ]);
            setLoading(false);
        } else {
            setCharFilters([{}]);
        }
    }

    function onPpgChange(value) {
        setSelectedPpg(value);
    }

    const unselectBrand = (value) => {
        changeMultipleBrand(value, false);
    };

    const changeMultipleBrand = (brand, status) => {
        let oldBrands = selectedFocusBrands;
        if (status) {
            oldBrands.push(brand);
        } else {
            var index = oldBrands.indexOf(brand);
            if (index !== -1) {
                oldBrands.splice(index, 1);
            }
        }
        setSelectedFocusBrands([...oldBrands]);
        // if (scorecardConfig?.selectors?.brand) {
        //     setSelectedCategories([]);
        //     fetchCompProdSetList(JSON.parse(JSON.stringify(oldBrands)));
        // }
        if (!scorecardId) {
            getChars();
        }
    };

    const fetchCompProdSetList = async (prefBrand = '') => {
        let categList = [];
        let companyPrefCateg = [];
        setLoading(true);

        companyPrefCateg = subscriptionCategories;

        const brandName = prefBrand;
        if (brandName) {
            const res = await getCategoryByBrand(brandName);
            let responseCategList = res?.categories || [];
            if (responseCategList) {
                categList = responseCategList.map((item) => item.name);
                if (!props.showAll) {
                    categList = categList.filter((item) => companyPrefCateg.includes(item));
                }
                setCategInFocusBrand(categList);
                categList = [...new Set([...categList, ...companyPrefCateg])]; // Update as per #1006
            }
        } else {
            categList = companyPrefCateg;
        }
        setCategoryOptions(categList);
        setLoading(false);
    };

    const checkFocusBrandIn = (categsSelected) => {
        if (
            scorecardConfig?.selectors?.brand &&
            categsSelected.length > 0 &&
            !categsSelected.some((v) => categInFocusBrand.includes(v))
        ) {
            setShowWarning(true);
            setContentType('warning');
            setWarningMessage(
                'You did not select any categories that contain your focus brand. This may result in no data in your report.'
            );
        }
    };

    async function onCharFilterChange(value, fieldName, index) {
        const charFilters = charFiltersRef.current;
        const filter = charFilters[index];
        const tmpFilter = cloneDeep(filter);

        switch (fieldName) {
            case 'characteristic':
                tmpFilter.characteristic = value;
                tmpFilter.condition = 'is';
                tmpFilter.value = [];
                setLoading(true);
                tmpFilter.valueOptions = value
                    ? await fetchCharsValues(
                        value,
                        selectedCategories?.length > 0
                            ? selectedCategories
                            : reportConfig.focusProductSelections?.competitiveProductSet?.categories
                    )
                    : [];
                setLoading(false);
                if (value === 'Manufacturer' || value === 'Brand') {
                    setShowWarning(true);
                    setContentType('warning');
                    setWarningMessage(
                        'When applying a brand or manufacturer filter, make sure to include Focus Brand/Manufacturer in your selections.'
                    );
                }
                break;
            case 'condition':
                tmpFilter.condition = value;
                break;
            case 'value':
                tmpFilter.value = value;
                break;
            default:
                break;
        }

        const isDuplicate = ({characteristic}, i) =>
            i !== index && characteristic && characteristic === tmpFilter.characteristic;

        if (charFilters.some(isDuplicate)) {
            // tmpFilter.characteristic = filter.characteristic;
            alert({
                title: 'Duplicate Characteristic',
                content: (
                    <>
                        <p>
                            <b>{tmpFilter.characteristic}</b> is already included as a filter.
                        </p>
                        <p>To choose multiple values, select them from the value dropdown instead.</p>
                    </>
                ),
            });
        } else {
            setCharFilters((state) => {
                state.splice(index, 1, tmpFilter);
                return [...state];
            });
        }
    }

    const extractCharFiltersSelection = (characteristics) => {
        return characteristics
            .filter((x) => !x.characteristic?.includes(CUSTOM_CHAR_EXTENSION))
            .map(({characteristic, condition, value}) => ({
                characteristic,
                condition,
                value,
            }));
    };

    async function onApplyClick() {
        const completeCharacteristics = charFilters.filter((item) => item.characteristic);
        const characteristics = extractCharFiltersSelection(completeCharacteristics);
        const updatedReportParams = cloneDeep(reportConfig);
        // updatedReportParams.inlineFilter = true;

        //If multiple focusbrands were passed in and the focus product selector
        //is configured to not be shown, continue to pass them through to the next report.
        updatedReportParams.focusProductSelections.focusBrand = reportConfig?.focusProductSelections?.showFocusBrand
            ? selectedFocusBrands
            : Array.isArray(reportConfig?.focusProductSelections?.focusBrand) &&
            reportConfig?.focusProductSelections?.focusBrand?.length > 0
                ? reportConfig?.focusProductSelections?.focusBrand
                : [];

        const characteristicFilters = characteristics.filter((item) => item.characteristic !== undefined);
        updatedReportParams.focusProductSelections.characteristicFilters = characteristicFilters;
        const {segmentIds, segmentData} = await extractCustomSegmentIds(completeCharacteristics)
        updatedReportParams.focusProductSelections.customSegmentIds = segmentIds;
        updatedReportParams.focusProductSelections.customSegmentData = segmentData;
        if (scorecardId) {
            updatedReportParams.focusProductSelections.competitiveProductSet.categories = selectedCategories;
            updatedReportParams.focusMarketSelections.focusMarket.byMarketName.markets = markets.map(
                (item) => item.name
            );
            updatedReportParams.focusProductSelections.focusUPC = upcCode && upcCode !== '000000000000' ? upcCode : '';
            if (scorecardConfig?.selectors?.timePeriod) {
                updatedReportParams.timePeriodSelections.selectionType = timePeriodType;
                updatedReportParams.timePeriodSelections.timePeriod =
                    timePeriodType === 'pre-defined' ? predefinedPeriod : '';
                updatedReportParams.timePeriodSelections.weeks = timePeriodType === 'custom' ? numberOfWeeks : '';
                updatedReportParams.timePeriodSelections.endDate = timePeriodType === 'custom' && toDate(endDate);
            } else {
                updatedReportParams.timePeriodSelections = {};
            }
        }
        if (reportConfig?.focusProductSelections?.themeSku) {
            updatedReportParams.focusProductSelections.themeSku = reportConfig?.focusProductSelections?.themeSku;
            updatedReportParams.focusProductSelections.themeAttribute = hwThemeAttributes;
        }
        if (includePpgs) {
            updatedReportParams.ppgId = selectedPpg ?? defaultPpgId; //For backward compatibility
            updatedReportParams.focusProductSelections.ppgId = selectedPpg ?? defaultPpgId;
        }
        if (includeSubcategories) {
            updatedReportParams.focusProductSelections.competitiveProductSet.subcategories = selectedSubCategories;
        }
        if (
            includeFocusBrands &&
            reportConfig?.focusMarketSelections?.focusMarket?.byMarketName?.marketGroup === 'cps'
        ) {
            updatedReportParams.focusProductSelections.brands = selectedFocusBrands;
            updatedReportParams.focusProductSelections.focusBrand = [];
        }
        if (includeOmniProducts) {
            updatedReportParams.focusProductSelections.omniProductLevel = capitalize(omniProductLevel);
            updatedReportParams.focusProductSelections.omniFocusProduct = omniFocusProduct;
        }

        // there should always be at least one characteristic filter visible
        setCharFilters(completeCharacteristics.length ? completeCharacteristics : [{}]);
        onApply?.(updatedReportParams);
    }

    function onCancelClick() {
        setReportViewData();
        resetCharacteristicFilters();
        onCancel?.();
    }

    function checkSubmitEnabled() {
        let [
            formValid,
            focusBrandValid,
            compProdSetValid,
            charFiltersValid,
            upcValid,
            marketsValid,
            timePeriodValid,
        ] = [true, true, true, true, true, true, true];
        if (scorecardId) {
            if (scorecardConfig?.selectors?.brand?.required && !selectedFocusBrands) {
                if (scorecardConfig?.selectors?.upc?.required) {
                    if (!upcCode || upcCode === '' || String(upcCode) === '000000000000') {
                        focusBrandValid = false;
                    } else {
                        focusBrandValid = true;
                    }
                } else {
                    focusBrandValid = false;
                }
            }
            if (scorecardConfig?.selectors?.category?.required && selectedCategories?.length === 0) {
                compProdSetValid = false;
            }
            if (scorecardConfig?.selectors?.characteristicFilters?.required || scorecardConfig?.keyCharacteristics) {
                const charFiltersObj = extractCharFiltersSelection(
                    charFilters.filter((item) => item.characteristic !== '')
                );
                const charFiltersChoosen = charFiltersObj.filter(
                    (item) => item?.value?.length !== 0 && item?.value !== undefined
                );
                if (charFiltersChoosen.length < 1) {
                    charFiltersValid = false;
                }
            }
            if (
                scorecardConfig?.selectors?.upc?.required &&
                (!upcCode || upcCode === '' || String(upcCode) === '000000000000')
            ) {
                upcValid = selectedFocusBrands ? true : false;
            }
            if (scorecardConfig?.selectors?.markets?.required && markets.length === 0) {
                marketsValid = false;
            }
            if (scorecardConfig?.selectors?.timePeriod) {
                if (timePeriodType === 'pre-defined') {
                    if (!predefinedPeriod) {
                        timePeriodValid = false;
                    }
                } else {
                    if (!numberOfWeeks || !endDate) {
                        timePeriodValid = false;
                    }
                }
            }
            formValid =
                focusBrandValid && compProdSetValid && charFiltersValid && upcValid && marketsValid && timePeriodValid;
        } else {
            formValid = true;
        }
        setEnableSubmit(formValid);
    }

    const loadOtherFilterWrapperData = async () => {
        setLoading(true);
        const allCategories = uniq(additionalCategories.concat(subscriptionCategories));
        const productSet = allCategories.map((name) => ({
            hierarchy: 'CATEGORY',
            name,
        }));
        setCategoryOptions(allCategories);
        setProductSet(productSet);
        setSelectedCategories(reportConfig.focusProductSelections?.competitiveProductSet?.categories ?? []);
        setTimePeriodType(reportConfig?.timePeriodSelections?.selectionType || '');
        setPredefinedPeriod(reportConfig?.timePeriodSelections?.timePeriod || '');
        setNumberOfWeeks(reportConfig?.timePeriodSelections?.weeks || '');
        setEndDate(
            reportConfig?.timePeriodSelections?.endDate ? moment(reportConfig?.timePeriodSelections?.endDate) : ''
        );
        setLoading(false);
    };
    function onSelectedCategoriesChange(categories) {
        setSelectedCategories([...categories]);
        if (scorecardConfig?.selectors?.brand) {
            checkFocusBrandIn(categories);
        }
        if (
            (!scorecardId || scorecardConfig?.selectors?.characteristicFilters) &&
            !reportConfig?.focusProductSelections?.themeSku &&
            reportConfig?.focusMarketSelections?.focusMarket?.byMarketName?.marketGroup !== 'cps'
        ) {
            getChars(categories);
        }
    }

    const onMarketChange = (e, type) => {
        if (type === 'single') {
            setMarkets(e !== '' ? [e] : []);
        } else {
            onMarketSearchChange(e, false);
        }
    };

    const onMarketSearchChange = async (market, status) => {
        let oldMarkets = markets;
        if (status) {
            oldMarkets.push(market);
        } else {
            var index = oldMarkets.indexOf(market);
            if (index !== -1) {
                oldMarkets.splice(index, 1);
            }
        }
        setMarkets([...oldMarkets]);
    };

    // const handleUpcChange = (e) => {
    //     let search = e;
    //     if (search?.length > 12) {
    //         search = search.slice(search?.length - 12);
    //     } else {
    //         search = search.padStart(12, '0');
    //     }
    //     setUpcCode(search);
    // };

    const unselectUPC = (value) => {
        handleUpcChange(value, false);
    };
    const handleUpcChange = async (value, status) => {
        let oldUPCs = upcCode;
        if (status) {
            oldUPCs.push(value);
        } else {
            var i = oldUPCs.indexOf(value);
            if (i !== -1) {
                oldUPCs.splice(i, 1);
            }
        }
        setUpcCode(JSON.parse(JSON.stringify(oldUPCs)));
    };

    const onPeriodChange = (e) => {
        if (e.target.value === 'custom') {
            setPredefinedPeriod('');
        } else {
            setEndDate('');
            setNumberOfWeeks('');
        }
        setTimePeriodType(e.target.value);
    };

    const onPredefinedPeriodChange = (e) => {
        setPredefinedPeriod(e);
    };

    const validateNumberOfWeeks = (weeks) => {
        let weekValue = weeks;
        if (weeks >= 1 && weeks <= 52) {
            setNumberOfWeeks(weeks);
        } else if (weeks > 52) {
            weekValue = 52;
            setNumberOfWeeks(52);
        } else {
            weekValue = '';
            setNumberOfWeeks('');
        }
    };

    function handleDateChange(date) {
        setEndDate(date);
    }

    const upcInfoMessage = () => {
        return 'If you do not submit specific UPCs, all products in your focus brand that started selling in the time period of the report will be analyzed as New Products. If you want to analyze specific new products, use this filter. Be sure to input UPCs without the check-digit at the end.';
    };

    const changeThemeAttribute = (e) => {
        setHwThemeAttributes(e);
    };

    const onOmniProductLevelChange = (e) => {
        setOmniFocusProduct([]);
        setOmniProductLevel(e.value);
    };

    const onOmniFocusProductChange = (e) => {
        setOmniFocusProduct(e.value);
    };

    const setReportViewData = () => {
        setSelectedFocusBrands(
            reportConfig?.focusProductSelections?.focusBrand ? reportConfig?.focusProductSelections?.focusBrand : []
        );
        setSelectedSubCategories(
            reportConfig?.focusProductSelections?.competitiveProductSet?.subcategories
                ? reportConfig?.focusProductSelections?.competitiveProductSet?.subcategories
                : []
        );
        // if (reportConfig?.focusProductSelections?.characteristicFilters.length > 0) {
        //     setCharacteristicFilters(
        //         reportConfig?.focusProductSelections?.characteristicFilters || []
        //     );
        // }
        const productSetObj = {
            categories: reportConfig?.focusProductSelections?.competitiveProductSet.categories.map((item) => {
                return {
                    hierarchy: 'CATEGORY',
                    name: item,
                };
            }),
        };
        setProductSet(productSetObj?.categories);
        if (
            reportConfig?.focusProductSelections?.brand?.length > 0 &&
            reportConfig?.focusMarketSelections?.focusMarket?.byMarketName?.marketGroup === 'cps'
        ) {
            setSelectedFocusBrands(reportConfig?.focusProductSelections?.brand);
        }
        if (scorecardId) {
            // setScoreCardConfigData(scorecardConfig);
        }
        if (reportConfig?.focusProductSelections?.themeSku) {
            let passedThemeAttr = [];
            if (reportConfig?.focusProductSelections?.allThemeAttributes?.length > 0) {
                passedThemeAttr = JSON.parse(JSON.stringify(reportConfig?.focusProductSelections?.allThemeAttributes));
            } else if (reportConfig?.focusProductSelections?.themeAttribute?.length > 0) {
                passedThemeAttr = JSON.parse(JSON.stringify(reportConfig?.focusProductSelections?.themeAttribute));
                setHwThemeAttributes(passedThemeAttr);
            }
            let passedOptions = JSON.parse(JSON.stringify(passedThemeAttr));
            setHwThemeAttributeOptions(passedOptions);
        }
        if (scorecardId) {
            const marketsSent = reportConfig?.focusMarketSelections?.focusMarket?.byMarketName?.markets || [];
            if (marketsSent?.length > 0) {
                let preloadMarkets = [];
                marketsSent.forEach((market) => {
                    preloadMarkets.push({
                        name: market,
                        remainingCompStores: '',
                        remainingCompRetailers: '',
                    });
                });
                setMarkets(preloadMarkets);
            }
        }
        if (isOmniReport) {
            setSelectedCategories(reportConfig.focusProductSelections?.competitiveProductSet?.categories ?? []);
            setOmniProductLevel(reportConfig.focusProductSelections?.omniProductLevel?.toLowerCase());
            setOmniFocusProduct(reportConfig.focusProductSelections?.omniFocusProduct);
        }
    };

    function toDate(date) {
        if (date) {
            return moment(date).format('YYYY-MM-DD');
        }
        return '';
    }

    function onSubCategoryChange(subCategs) {
        setSelectedSubCategories([...subCategs]);
    }

    async function loadSubcategoriesOptions() {
        const hierarchyPostData = {
            hierarchyType: 'subcategory',
            categories: reportConfig.focusProductSelections?.competitiveProductSet?.categories || [],
        };
        const prodLevelResp = await retailerHierarchies(hierarchyPostData);
        if (prodLevelResp?.subcategories) {
            setSubCategoryOptions(prodLevelResp?.subcategories);
        }
    }

    async function fetchCharsValues(charItem, categoriesList = []) {
        // setLoading(true);
        let charsValueOptions = [];
        let customCharacteristics = [];
        if (customChars?.length === 0) {
            customCharacteristics = await getCustomCharacteristics();
            setCustomChars(customCharacteristics);
        }
        if (charItem.includes(CUSTOM_CHAR_EXTENSION)) {
            // Custom Chars
            customCharacteristics = customChars?.length > 0 ? customChars : customCharacteristics;
            const customCharId = customCharacteristics
                ?.find((item) => item.label + CUSTOM_CHAR_EXTENSION === charItem)
                ?.id;
            charsValueOptions = await getCustomCharWithNASegment(customCharId);
        } else {
            const productSet = (categoriesList?.length ? categoriesList : selectedCategories)?.map((item) => {
                return {
                    hierarchy: 'CATEGORY',
                    name: item,
                };
            });
            const productSetObj = {
                categories: productSet,
                themePurchaseType,
            };
            let charsResp = [];
            let charsAndCodes = [];
            if (charCodesData?.length === 0) {
                charsResp = await getProductChars(productSetObj);
                if (charsResp) {
                    charsAndCodes = [...charsResp?.characteristics];
                    setCharCodesData(charsResp?.characteristics);
                }
            } else {
                charsAndCodes = [...charCodesData];
            }
            const charValsResp = await getCharacteristicValues({
                categories: productSet,
                code: charsAndCodes?.find((item) => item.chr_display_name === charItem)?.chr_code,
            });
            charsValueOptions = [...charValsResp];
        }
        // setLoading(false);
        return charsValueOptions;
    }

    return (
        <div
            className={classnames(`${baseClassName}`, {
                [`${baseClassName}--open`]: show,
            })}
        >
            <div onClick={() => props.toggleFilterPanel()} title="Filter Report" className={`${baseClassName}--close`}>
                <div className={`${baseClassName}--close-label`}>Close</div>
                <img src={upArrow}/>
            </div>
            <header className={`${baseClassName}__header`}>
                {title && <div className={`${baseClassName}__title`}>{title}</div>}
                {subtitle && <div className={`${baseClassName}__subtitle`}>{subtitle}</div>}
            </header>
            <main className={`${baseClassName}__content`}>
                <ByzzerMask loading={loading}/>
                {includeProductFilters && (
                    <div className={classnames(`${baseClassName}__section`, `${baseClassName}__product-filters`, {
                        [`${baseClassName}__product-filters--omni`]: isOmniReport,
                    })}>
                        {includeFocusBrands && (
                            <ByzzerSearchableSelect
                                label="Focus Brands:"
                                options={selectedFocusBrands.length > 0 ? selectedFocusBrands : []}
                                placeholder={
                                    selectedFocusBrands.length > 0 ? selectedFocusBrands : 'Select focus brands'
                                }
                                searchPlaceholder={'Search for brand'}
                                onChange={(e) => {
                                    unselectBrand(e);
                                }}
                                productSet={productSet}
                                searchOnChange={(value, status) => changeMultipleBrand(value, status)}
                                searchValue={selectedFocusBrands.length > 0 ? selectedFocusBrands : ''}
                                searchType={
                                    props.showAll ? 'brandSearchMultiSelect' : 'searchBrandForProductSetMultiSelect'
                                }
                                optionalLabel={focusBrandOptional}
                                variant='secondary'
                            />
                        )}
                        {includeCategories && (
                            <ByzzerMultiSelect
                                label="Categories"
                                selectedOptions={selectedCategories}
                                name={'category-list'}
                                multiSelectWithCheckBox
                                onChange={onSelectedCategoriesChange}
                                options={categoryOptions}
                                placeholder="Select from the list"
                                seperator={'OR'}
                                variant='secondary'
                            />
                        )}
                        {includeOmniProducts && (
                            <ByzzerSelectComponent
                                label={
                                    <OptionalRequired required={true}>
                                        Product Level
                                    </OptionalRequired>
                                }
                                name={"omniProductLevel"}
                                options={omniProductLevelOptions}
                                value={omniProductLevel ?? ''}
                                onChange={onOmniProductLevelChange}
                                placeholder={"Select a product level"}
                                disabled={false}
                                allowClear={false}
                                variant='secondary'
                            />
                        )}
                        {includeOmniProducts && (
                            <OmniBrandSearch
                                name={"omniFocusProducts"}
                                onlyRenderIf={includeOmniProducts && (omniProductLevel === 'brand' || !omniProductLevel)}
                                value={omniFocusProduct}
                                onChange={onOmniFocusProductChange}
                                label={<LimitedLabel required={false}
                                                     max={maxOmniProducts}
                                                     single={'Select A Brand'}
                                                     limited={<>Select Focus Product (Up
                                                         To {maxOmniProducts} Brands)</>}
                                                     unlimited={'Select Your Brands'}
                                />}
                                maxBrands={maxOmniProducts}
                                placeholder={"Search for brands"}
                                disabled={!omniProductLevel || !selectedCategories?.length}
                                omniCategories={selectedCategories}
                            />)}
                        {includeOmniProducts && (
                            <OmniManufacturerSearch
                                name={"omniFocusProducts"}
                                onlyRenderIf={includeOmniProducts && omniProductLevel === 'manufacturer'}
                                value={omniFocusProduct}
                                onChange={onOmniFocusProductChange}
                                label={<LimitedLabel required={false}
                                                     max={maxOmniProducts}
                                                     single={'Select A Brand'}
                                                     limited={<>Select Focus Product (Up
                                                         To {maxOmniProducts} Manufacturers)</>}
                                                     unlimited={'Select Your Manufacturers'}
                                />}
                                maxSelections={maxOmniProducts}
                                disabled={false}
                                placeholder={"Search for manufacturers"}
                                omniCategories={selectedCategories}
                            />)}
                        {includeThemes && (
                            <ByzzerMultiSelect
                                label="Choose Attributes"
                                selectedOptions={hwThemeAttributes}
                                disabled={false}
                                name={'charsfor-theme'}
                                placeholder={'All'}
                                multiSelectWithCheckBox
                                onChange={(e) => changeThemeAttribute(e)}
                                options={hwThemeAttributeOptions}
                                seperator={'OR'}
                                variant='secondary'
                            />
                        )}
                        {/* {includeUpcs && (
                        <ByzzerSearch
                            label={<>
                                Focus UPC:
                                <Tooltip placement="bottomLeft" title={upcInfoMessage()}>
                                    <TipIcon theme={'dark'}/>
                                </Tooltip>
                            </>}
                            placeholder={'Enter 12-digit UPC'}
                            onChange={handleUpcChange}
                            value={upcCode}
                            brands={selectedFocusBrands[0]}
                            categories={selectedCategories}
                            type={'upcCode'}
                        />
                    )} */}
                        {includeUpcs && (
                            <ByzzerSearchableSelect
                                label={
                                    <>
                                        Focus UPC:
                                        <Tooltip placement="bottomLeft" title={upcInfoMessage()}>
                                            <TipIcon theme={'dark'}/>
                                        </Tooltip>
                                    </>
                                }
                                seperator={','}
                                enableToolTip={upcCode.length > 0 ? true : false}
                                options={upcCode.length > 0 ? upcCode : []}
                                placeholder={upcCode.length > 0 ? upcCode : 'Enter 12-digit UPC'}
                                searchPlaceholder={'Search for UPC'}
                                onChange={(e) => {
                                    unselectUPC(e);
                                }}
                                brands={selectedFocusBrands}
                                categories={selectedCategories}
                                searchOnChange={(value, status) => handleUpcChange(value, status)}
                                searchValue={upcCode.length > 0 ? upcCode : ''}
                                searchType={'upcCodeMultiSelect'}
                                variant='secondary'
                            />
                        )}
                        {includeSingleMarket && (
                            <ByzzerSearch
                                label="Market"
                                onChange={(e) => onMarketChange(e, 'single')}
                                type={'marketSingleSearch'}
                                remainingMarkets
                                value={markets?.length > 0 ? markets[0]?.name : ''}
                                placeholder="Search for Market"
                                allowClear
                                variant='secondary'
                            />
                        )}
                        {includeMultiMarket && (
                            <ByzzerSearchableSelect
                                label="Markets"
                                disabled={false}
                                options={markets?.length > 0 ? markets : []}
                                placeholder={'Please Select Market'}
                                searchPlaceholder={'Search for Market'}
                                onChange={(e) => {
                                    onMarketChange(e, 'multi');
                                }}
                                searchOnChange={(value, status) => {
                                    onMarketSearchChange(value, status);
                                }}
                                searchType={'marketSearch'}
                                remainingMarkets
                                searchValue={markets?.length > 0 ? markets : ''}
                                variant='secondary'
                            />
                        )}
                    </div>
                )}
                {includePpgs && (
                    <div className={`${baseClassName}__section ${baseClassName}__ppg`}>
                        <div className={`${baseClassName}__section-title`}>PPG Definition</div>
                        <div className={`${baseClassName}__section-subtitle`}>
                            This report leverages PPG to provide recommendations at an actionable level.
                        </div>
                        <div className="product-selector__ppg-description">
                            {/*{selectedCompProdSet.length > 0 && ppgForCategory.length < 1 ? (*/}
                            {/*    <span>*/}
                            {/*                    Your Team has not created any PPG list for the selected categories. Head to{' '}*/}
                            {/*        <ByzzerLink label={'PPGs'} onClick={(label) => redirectToPPG(label)}/>{' '}*/}
                            {/*        to define your own.*/}
                            {/*                </span>*/}
                            {/*) : (*/}
                            {/*    <span>Choose which PPG definition to use.</span>*/}
                            {/*)}*/}
                        </div>
                        <ByzzerSelect
                            value={selectedPpg}
                            placeholder={defaultPpgName}
                            options={ppgOptions}
                            onChange={onPpgChange}
                            disabled={
                                !selectedCategories.length &&
                                !reportConfig.focusProductSelections?.competitiveProductSet?.categories.length
                            }
                            allowClear={true}
                            variant='secondary'
                        />
                    </div>
                )}
                {includeTimePeriod && (
                    <div className={`${baseClassName}__section ${baseClassName}__time-periods`}>
                        <div className={`${baseClassName}__section-title`}>Choose Time Period</div>
                        <div className={`${baseClassName}__time-period-filter`}>
                            <div className={`${baseClassName}__time-period`}>
                                <div className="component-content-header">
                                    <ByzzerRadio
                                        label={`Use Preset Time Period`}
                                        value={'pre-defined'}
                                        checked={timePeriodType === 'pre-defined'}
                                        onChange={onPeriodChange}
                                        name="time_period_type"
                                    />
                                </div>
                                <ByzzerSelect
                                    label={'Choose Time Period'}
                                    key={predefinedPeriod}
                                    options={timePeriodOptions}
                                    value={predefinedPeriod}
                                    disabled={timePeriodType === '' || timePeriodType === 'custom'}
                                    onChange={onPredefinedPeriodChange}
                                    placeholder={'Select time period'}
                                    variant='secondary'
                                />
                                <div className={`${baseClassName}-latest-data`}>{`Latest date: ${toDate(
                                    maxDataDate
                                )}`}</div>
                            </div>
                            <div className={`${baseClassName}__seperator`}>OR</div>
                            <div className={`${baseClassName}__time-period`}>
                                <div className="component-content-header">
                                    <ByzzerRadio
                                        label={'Use Custom Time Period'}
                                        checked={timePeriodType === 'custom'}
                                        value={'custom'}
                                        onChange={onPeriodChange}
                                        name="time_period_type"
                                    />
                                </div>
                                <div className={`${baseClassName}__custom-period`}>
                                    <ByzzerInput
                                        label={'Number Of Weeks'}
                                        className={`${baseClassName}__weeks`}
                                        disabled={timePeriodType === '' || timePeriodType === 'pre-defined'}
                                        type="number"
                                        onInput={(e) => (e.target.value = e.target.value.slice(0, 2))}
                                        onChange={(e) => validateNumberOfWeeks(parseInt(e.target.value))}
                                        value={numberOfWeeks}
                                        variant='secondary'
                                    />
                                    <div className={`${baseClassName}__end-date`}>
                                        <div className={`${baseClassName}__label`}>End Date</div>
                                        <DatePicker
                                            className={`${baseClassName}__calendar`}
                                            placeholder="Select An End Date"
                                            disabled={timePeriodType === '' || timePeriodType === 'pre-defined'}
                                            format="YYYY-MM-DD"
                                            suffixIcon={<img src={calendarIcon} alt="icon"/>}
                                            allowClear={false}
                                            onChange={(date) => handleDateChange(date)}
                                            value={endDate}
                                            defaultPickerValue={moment(maxDataDate)}
                                            inputReadOnly
                                            disabledDate={(current) => {
                                                return moment(maxDataDate).add(1, 'days').isAfter(current)
                                                    ? current && current.weekday() !== 6
                                                    : true; //needs moment data date plus one
                                            }}
                                            dateRender={(current) => (
                                                <div
                                                    className={classnames('ant-picker-cell-inner', {
                                                        'date--weekday': current.weekday() === 6,
                                                        'date--end-date': current.isSame(endDate),
                                                    })}
                                                >
                                                    {current.date()}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {includeCharacteristics && (
                    <div className={`${baseClassName}__section ${baseClassName}__characteristics`}>
                        <div className={`${baseClassName}__section-title`}>Apply Characteristic Filters</div>
                        <div className={`${baseClassName}__characteristic-filters`}>
                            {charFilters.map((filter, index) => (
                                <div key={index} className="product-selector__characteristics">
                                    <ByzzerSelect
                                        label={'Characteristic'}
                                        className="product-selector__characteristics-item"
                                        value={filter.characteristic}
                                        placeholder="Select A Characteristic"
                                        options={characteristicOptions}
                                        onChange={(e) => onCharFilterChange(e, 'characteristic', index)}
                                        allowClear={true}
                                        variant='secondary'
                                    />
                                    <ByzzerSelect
                                        label={'Condition'}
                                        className="product-selector__characteristics-condition"
                                        value={filter.condition}
                                        options={['is', ...(scorecardId !== 'product_market_fit' ? ['is not'] : [])]}
                                        onChange={(e) => onCharFilterChange(e, 'condition', index)}
                                        variant='secondary'
                                    />
                                    <ByzzerMultiSelect
                                        label={'Value'}
                                        className="product-selector__characteristics-value"
                                        selectedOptions={filter.value}
                                        name={'char-value'}
                                        value={filter.value}
                                        multiSelectWithCheckBox
                                        onChange={(e, selectedVal) =>
                                            onCharFilterChange(e, 'value', index, selectedVal)
                                        }
                                        options={filter.valueOptions ?? []}
                                        seperator={'OR'}
                                        variant='secondary'
                                    />
                                    <div className="product-selector__characteristics-action">
                                        {/* <label>1</label> */}
                                        {index > 0 && (
                                            <img
                                                alt="delete"
                                                src={DeleteIcon}
                                                onClick={() => removeCharFilter(index)}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div className={`${baseClassName}__add-cta`} onClick={addCharFilter}>
                                + Add Characteristic Filter
                            </div>
                        </div>
                    </div>
                )}
                {includeSubcategories && (
                    <ByzzerMultiSelect
                        label="Subcategory:"
                        selectedOptions={selectedSubCategories}
                        name={'sub-category'}
                        placeholder={'All'}
                        multiSelectWithCheckBox
                        onChange={(e) => onSubCategoryChange(e)}
                        options={subCategoryOptions || []}
                        seperator={'OR'}
                        allowClear={true}
                        disabled={
                            reportConfig.focusProductSelections?.competitiveProductSet?.categories?.length === 0
                                ? true
                                : false
                        }
                        productType={props.productType}
                        optionalLabel={isSubcategoryOptional}
                    />
                )}
                {/*{!scorecardId &&*/}
                {/*    reportConfig?.focusProductSelections?.showFocusBrand && (*/}
                {/*        <div className="inline-product-selector__focus-brand">*/}
                {/*            <div className="inline-product-selector__focus-brand-label">*/}
                {/*                Select Your Focus Brand:*/}
                {/*            </div>*/}
                {/*            <div className="inline-product-selector__focus-brand-searchable">*/}

                {/*                <ByzzerSearchableSelect*/}
                {/*                    options={focusBrand.length > 0 ? focusBrand : []}*/}
                {/*                    placeholder={focusBrand.length > 0 ? focusBrand : 'Select focus brands'}*/}
                {/*                    searchPlaceholder={'Search for brand'}*/}
                {/*                    onChange={(e) => {*/}
                {/*                        unselectBrand(e);*/}
                {/*                    }}*/}
                {/*                    productSet={productSet}*/}
                {/*                    searchOnChange={(value, status) => changeMultipleBrand(value, status)}*/}
                {/*                    searchValue={focusBrand.length > 0 ? focusBrand : ''}*/}
                {/*                    searchType={props.showAll ? 'brandSearchMultiSelect' : 'searchBrandForProductSetMultiSelect'}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*{reportConfig?.focusProductSelections?.themeSku && (*/}
                {/*    <div className="inline-product-selector__charsfor-theme-select">*/}
                {/*        <div className="inline-product-selector__charsfor-theme-select-item">*/}
                {/*            <label className="inline-product-selector__charsfor-theme-select-item-label">*/}
                {/*                Choose attributes:*/}
                {/*            </label>*/}
                {/*        </div>*/}
                {/*        <div className="inline-product-selector__charsfor-theme-select-options">*/}
                {/*            <ByzzerMultiSelect*/}
                {/*                label="Choose Attributes"*/}
                {/*                selectedOptions={hwThemeAttributes}*/}
                {/*                disabled={false}*/}
                {/*                name={'charsfor-theme'}*/}
                {/*                placeholder={'All'}*/}
                {/*                multiSelectWithCheckBox*/}
                {/*                onChange={(e) => changeThemeAttribute(e)}*/}
                {/*                options={hwThemeAttributeOptions}*/}
                {/*                seperator={'OR'}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}
                {/*{scorecardId && (*/}
                {/*    <div className="inline-product-selector__product">*/}
                {/*        {scorecardConfig?.selectors?.brand && (*/}
                {/*            <div className="inline-product-selector__product-select">*/}
                {/*                <ByzzerSearchableSelect*/}
                {/*                    label="Focus Brand"*/}
                {/*                    seperator={','}*/}
                {/*                    enableToolTip={focusBrand.length > 0 ? true : false}*/}
                {/*                    options={focusBrand.length > 0 ? focusBrand : []}*/}
                {/*                    placeholder={*/}
                {/*                        focusBrand.length > 0*/}
                {/*                            ? focusBrand*/}
                {/*                            : 'Select your focus brands'*/}
                {/*                    }*/}
                {/*                    searchPlaceholder={'Search for brand'}*/}
                {/*                    onChange={(e) => unselectBrand(e)}*/}
                {/*                    productSet={productSet}*/}
                {/*                    searchOnChange={(value, status) => changeMultipleBrand(value, status)}*/}
                {/*                    searchValue={*/}
                {/*                        focusBrand.length > 0 ? focusBrand : ''*/}
                {/*                    }*/}
                {/*                    searchType={*/}
                {/*                        props.showAll ? 'brandSearchMultiSelect' : 'searchBrandForProductSetMultiSelect'*/}
                {/*                    }*/}
                {/*                    //productType={props.productType}*/}
                {/*                    // multiBrandSelect={true}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        )}*/}
                {/*        {scorecardConfig?.selectors?.category && (*/}
                {/*            <div className="inline-product-selector__product-select">*/}
                {/*                <ByzzerMultiSelect*/}
                {/*                    label="Categories"*/}
                {/*                    selectedOptions={selectedCompProdSet}*/}
                {/*                    name={'category-list'}*/}
                {/*                    multiSelectWithCheckBox*/}
                {/*                    onChange={(e) => changeCategory(e)}*/}
                {/*                    options={competitiveProductSetList}*/}
                {/*                    placeholder={'Select from the list'}*/}
                {/*                    seperator={'OR'}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        )}*/}
                {/*        {scorecardConfig?.selectors?.upc && (*/}
                {/*            <div className="inline-product-selector__product-select">*/}
                {/*                <label className="inline-product-selector__product-select-label">*/}
                {/*                    Focus UPC:*/}
                {/*                    <Tooltip placement="bottomLeft" title={upcInfoMessage()}>*/}
                {/*                        <TipIcon theme={'dark'}/>*/}
                {/*                    </Tooltip>*/}
                {/*                </label>*/}
                {/*                <ByzzerSearch*/}
                {/*                    placeholder={'Enter 12-digit UPC'}*/}
                {/*                    onChange={handleUpcChange}*/}
                {/*                    value={upcCode}*/}
                {/*                    brands={focusBrand[0]}*/}
                {/*                    categories={selectedCompProdSet}*/}
                {/*                    type={'upcCode'}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        )}*/}
                {/*        {scorecardConfig?.selectors?.markets && (*/}
                {/*            <div className="inline-product-selector__product-select">*/}
                {/*                {scorecardConfig?.selectors?.markets.multi ? (*/}
                {/*                    <ByzzerSearchableSelect*/}
                {/*                        label="Markets"*/}
                {/*                        disabled={false}*/}
                {/*                        options={markets?.length > 0 ? markets : []}*/}
                {/*                        placeholder={'Please Select Market'}*/}
                {/*                        searchPlaceholder={'Search for Market'}*/}
                {/*                        onChange={(e) => {*/}
                {/*                            onMarketChange(e, 'multi');*/}
                {/*                        }}*/}
                {/*                        searchOnChange={(value, status) => {*/}
                {/*                            onMarketSearchChange(value, status);*/}
                {/*                        }}*/}
                {/*                        searchType={'marketSearch'}*/}
                {/*                        remainingMarkets*/}
                {/*                        searchValue={markets?.length > 0 ? markets : ''}*/}
                {/*                    />*/}
                {/*                ) : (*/}
                {/*                    <ByzzerSearch*/}
                {/*                        label="Market"*/}
                {/*                        onChange={(e) => onMarketChange(e, 'single')}*/}
                {/*                        type={'marketSingleSearch'}*/}
                {/*                        remainingMarkets*/}
                {/*                        value={markets?.length > 0 ? markets[0]?.name : ''}*/}
                {/*                        placeholder="Search for Market"*/}
                {/*                    />*/}
                {/*                )}*/}
                {/*            </div>*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*)}*/}
                {/*{scorecardId && scorecardConfig?.selectors?.timePeriod && (*/}
                {/*    <div className="inline-product-selector__timeperiod-title">Time Period:</div>*/}
                {/*)}*/}
                {/*{scorecardId && scorecardConfig?.selectors?.timePeriod && (*/}
                {/*    <div className="inline-product-selector__timeperiod">*/}
                {/*        <div className="inline-product-selector__timeperiod-predefined">*/}
                {/*            <div className="inline-product-selector__timeperiod-predefined-radio">*/}
                {/*                <ByzzerRadio*/}
                {/*                    label={'Select time period for the report:'}*/}
                {/*                    value={'pre-defined'}*/}
                {/*                    checked={timePeriodType === 'pre-defined'}*/}
                {/*                    onChange={onPeriodChange}*/}
                {/*                    name="time_period_type"*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*            <div className="inline-product-selector__timeperiod-predefined-options">*/}
                {/*                <ByzzerSelect*/}
                {/*                    key={predefinedPeriod}*/}
                {/*                    options={timePeriodOptions}*/}
                {/*                    value={predefinedPeriod}*/}
                {/*                    disabled={timePeriodType === '' || timePeriodType === 'custom'}*/}
                {/*                    onChange={onPredefinedPeriodChange}*/}
                {/*                    placeholder={'Select time period'}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*            <div*/}
                {/*                className="inline-product-selector__timeperiod-latest-data">{`Latest date: ${maxDataDate}`}</div>*/}
                {/*        </div>*/}
                {/*        <div className="inline-product-selector__timeperiod-or">*/}
                {/*            <label className="inline-product-selector__timeperiod-or-label">OR</label>*/}
                {/*        </div>*/}
                {/*        <div className="inline-product-selector__timeperiod-custom">*/}
                {/*            <div className="inline-product-selector__timeperiod-custom-radio">*/}
                {/*                <ByzzerRadio*/}
                {/*                    label={'Create custom time period:'}*/}
                {/*                    checked={timePeriodType === 'custom'}*/}
                {/*                    value={'custom'}*/}
                {/*                    onChange={onPeriodChange}*/}
                {/*                    name="time_period_type"*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*            <div className="inline-product-selector__timeperiod-custom-options">*/}
                {/*                <div className="inline-product-selector__timeperiod-custom-options-week">*/}
                {/*                    <span>Number of weeks :</span>*/}
                {/*                    <input*/}
                {/*                        disabled={timePeriodType === '' || timePeriodType === 'pre-defined'}*/}
                {/*                        type="number"*/}
                {/*                        onInput={(e) => (e.target.value = e.target.value.slice(0, 2))}*/}
                {/*                        onChange={(e) => validateNumberOfWeeks(parseInt(e.target.value))}*/}
                {/*                        value={numberOfWeeks}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div className="inline-product-selector__timeperiod-custom-options-enddate">*/}
                {/*                    <span>End Date :</span>*/}
                {/*                    <DatePicker*/}
                {/*                        placeholder="select date"*/}
                {/*                        disabled={timePeriodType === '' || timePeriodType === 'pre-defined'}*/}
                {/*                        className="inline-product-selector__timeperiod-custom-options-datepicker"*/}
                {/*                        format="YYYY-MM-DD"*/}
                {/*                        suffixIcon={<img src={calendarIcon} alt="icon"/>}*/}
                {/*                        allowClear={false}*/}
                {/*                        onChange={(date) => handleDateChange(date)}*/}
                {/*                        value={endDate}*/}
                {/*                        defaultPickerValue={moment(maxDataDate)}*/}
                {/*                        inputReadOnly*/}
                {/*                        disabledDate={(current) => {*/}
                {/*                            return moment(maxDataDate).add(1, 'days').isAfter(current)*/}
                {/*                                ? current && current.weekday() !== 6*/}
                {/*                                : true; //needs moment data date plus one*/}
                {/*                        }}*/}
                {/*                        dateRender={(current) => {*/}
                {/*                            const style = {};*/}
                {/*                            if (moment(maxDataDate).add(1, 'days').isAfter(current))*/}
                {/*                                if (current < moment().endOf('month')) {*/}
                {/*                                    //Data date needs to add one more to show properly.  The real date is the 1/16/21 as of 1/29/21*/}
                {/*                                    if (current.weekday() === 6) {*/}
                {/*                                        style.color = '#000000';*/}
                {/*                                        style.fontWeight = '500';*/}
                {/*                                    }*/}
                {/*                                    if (current.isSame(endDate)) {*/}
                {/*                                        style.width = '27.5px';*/}
                {/*                                        style.height = '27px';*/}
                {/*                                        style.color = 'white';*/}
                {/*                                        style.backgroundColor = '#000000';*/}
                {/*                                        style.fontWeight = '500';*/}
                {/*                                        style.fontSize = '14px';*/}
                {/*                                        style.display = 'flex';*/}
                {/*                                        style.textAlign = 'center';*/}
                {/*                                        style.alignItems = 'center';*/}
                {/*                                        style.justifyContent = 'center';*/}
                {/*                                        style.marginLeft = '3px';*/}
                {/*                                        style.textDecoration = 'underline';*/}
                {/*                                    }*/}
                {/*                                } else {*/}
                {/*                                    if (current.weekday() === 6) {*/}
                {/*                                        style.color = '#2C3038';*/}
                {/*                                        style.fontWeight = '500';*/}
                {/*                                    }*/}
                {/*                                }*/}

                {/*                            return (*/}
                {/*                                <div className="ant-picker-cell-inner" style={style}>*/}
                {/*                                    {current.date()}*/}
                {/*                                </div>*/}
                {/*                            );*/}
                {/*                        }}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}
                {/*/!* {(!scorecardId || scorecardConfig?.selectors?.characteristicFilters) && (*/}
                {/*                <div*/}
                {/*                    className={`inline-product-selector__separator-hr ${reportConfig?.focusProductSelections?.themeSku ? ' theme' : ''*/}
                {/*                    }`}*/}
                {/*                ></div>*/}
                {/*            )} *!/*/}
                {/*{scorecardId && (*/}
                {/*    <div className="inline-product-selector__title">*/}
                {/*        {scorecardConfig?.keyCharacteristics &&*/}
                {/*            `Select Key Characteristics (at least 1 is required)`*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*)}*/}
                {/*{(!scorecardId || scorecardConfig?.selectors?.characteristicFilters) &&*/}
                {/*    !reportConfig?.focusProductSelections?.themeSku &&*/}
                {/*    reportConfig?.focusMarketSelections?.focusMarket?.byMarketName*/}
                {/*        ?.marketGroup !== 'cps' && (*/}
                {/*        <div className="inline-product-selector__characteristics">*/}
                {/*            {!scorecardConfig?.keyCharacteristics &&*/}
                {/*                <div className="inline-product-selector__title">*/}
                {/*                    Apply Characteristic Filters*/}
                {/*                </div>*/}
                {/*            }*/}
                {/*            {renderCharFiltersOptions()}*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*<div*/}
                {/*    className={`inline-product-selector__submit-btn ${reportConfig?.focusProductSelections?.themeSku ? ' theme' : ''*/}
                {/*    }`}*/}
                {/*>*/}
                {/*</div>*/}
            </main>
            <footer className={`${baseClassName}__actions`}>
                <ByzzerButton onClick={onCancelClick} type={'negative'}>
                    Cancel
                </ByzzerButton>

                <ByzzerButton onClick={onApplyClick} disabled={!enableSubmit}>
                    Apply
                </ByzzerButton>
            </footer>
        </div>
    );
}

export default ReportFiltersOverlay;
