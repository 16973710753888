export type OpportunityRunConfig = {
    categories?: string[];
    brands?: string[];
    ppgId?: number | undefined;
    markets?: string[];
    marketTypes?: string;
};

export const OpportunityTypeMenuItems = [
    {
        name: 'Promotion',
        key: 'promotion',
    },
    {
        name: 'Pricing',
        key: 'pricing',
    },
    {
        name: 'Assortment',
        key: 'assortment',
    },
    {
        name: 'New Products',
        key: 'product',
    },
];