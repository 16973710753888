import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ByzzerLink} from '@/components/form';
import './MyPortfolio.scss';
import classnames from 'classnames';
import Standings from '@/views/MyPortfolio/Standings';
import PanelReleasability from '@/views/MyPortfolio/PanelReleasability';
import DashboardContent from "@/components/dashboard/DashboardContent";

export function MyPortfolio() {
    const mainClasses = classnames('my-portfolio');
    const subClasses = classnames('my-portfolio__subclass');

    function openChat() {
        // @ts-ignore
        if (window.HubSpotConversations) {
            // window.HubSpotConversations.widget.load();
            // @ts-ignore
            window.HubSpotConversations.widget.open();
        } else {
            console.log('Please enable Chatflow in Hubspot and try again');
        }
    }

    const subTitle = <>
        <p>Use this page to understand your items in the data, which categories they fall into,and their assigned
            characteristics.</p>
        <p>In the standings, you will find helpful ranks such as your top 10 markets, to guide you in what to choose
            when running reports.</p>
    </>

    return (
        // @ts-ignore
        <DashboardContent title={'My Portfolio'} subTitle={subTitle} className={mainClasses} >
            <>
                <div className={subClasses}>
                    <div className='my-portfolio__subclass-chat-section'>
                        Can't find what you're looking for?{' '} Use the Chat to talk to customer service.
                        {/* <ByzzerLink
                            className={'my-portfolio__subclass-chat-link'}
                            label={'Click here to chat with customer service'}
                            onClick={openChat}
                        /> */}
                    </div>
                </div>

                <div className={classnames(subClasses, 'my-portfolio__standings')}>
                    <Standings />
                </div>

                <div className={subClasses}>
                    <PanelReleasability />
                </div>
            </>
        </DashboardContent>
    );
}

export default MyPortfolio;
