import './ByzzerModal.scss';
import React, {Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState} from 'react';
import {create as createModal, hexGen, InstanceProps} from 'react-modal-promise';
import {CustomerSupportLink} from '@/components/CustomerSupportLink';
import {
    ByzzerButton,
    ByzzerButtonProps,
    ByzzerCheckableChangeEvent,
    ByzzerCheckbox,
    ByzzerTextInput
} from '@byzzer/ui-components';
import classnames from 'classnames';
import CloseIcon from '@/images/icons/niq-close-dark.svg';
import warningIcon from '@images/icons/warningIcon.svg';
import {isFunction} from 'lodash';
import {nanoid} from "nanoid";

type AlertModalFunctionParams = {
    type?: 'error' | 'info' | 'default' | 'warning' | string;
    title?: ReactNode;
    content?: ReactNode;
    okLabel?: string;
    className?: string;
}
type AlertModalFunction<T = any> = (args: AlertModalFunctionParams) => Promise<void>
export const alert: AlertModalFunction = createModal(
    ({onResolve, type, title, content, okLabel = 'Ok', className, ...props}: any) => {
        const wrapperClassNames = classnames('byzzer-modal-wrapper', 'byzzer-modal-wrapper--visible');

        const modalClasses = classnames(className, `byzzer-alert`, {
            [`byzzer-alert--${type}`]: Boolean(type),
        });
        return (
            <div className={wrapperClassNames}>
                <div className={modalClasses}>
                    {Boolean(title) && (
                        <header className={`byzzer-alert__header`}>
                            <h1>{title}</h1>
                        </header>
                    )}
                    <main className={'byzzer-alert__content'}>{content}</main>
                    <footer className={'byzzer-alert__footer'}>
                        <ByzzerButton onClick={() => onResolve(true)}>{okLabel}</ByzzerButton>
                    </footer>
                </div>
            </div>
        );
    });

/**
 *
 * @param headerType string
 * default: error
 * acceptedValues: ["error", "normal"]
 * what if we set header Type as error ?
 * If we set the headerType as error it will display the modal exclusively for errors UI
 * what if we set header Type as normal ?
 * If we set the headerType as normal it will display the modal layout as clean
 *
 * @param show boolean
 * default: true
 * if set to true, modal will pop up
 * if set to false, modal will hide
 *
 * @param size string
 * default: small
 * values: small and large
 * small defines pop up width and height for smaller content
 * large defines popup width and height for larger content
 */

type ByzzerModalType = 'default' | 'error' | 'warning' | 'info' | string;
type ByzzerModalProps = {
    onClick?: () => void;
    heading?: ReactNode;
    show?: boolean;
    headerType?: ByzzerModalType
    contentType?: ByzzerModalType
    children?: ReactNode;
    type?: ByzzerModalType;
    onClose?: () => void;
    closeOnClickOutside?: boolean;
    closeButtonText?: string;
    size?: 'size' | string; // todo figure out the standard sizes
    extraIcon?: ReactNode;
    className?: string;
    showCloseOption?: boolean;
    footerContent?: ReactNode;
}

export function ByzzerModal({
                                onClick,
                                heading,
                                show = false,
                                type = 'default',
                                headerType = type,
                                contentType = 'default', // this was set to equal 'type' but it was breaking the CSS of the modal. 'default' was pre-refactor value. research
                                children,
                                onClose = onClick,
                                closeOnClickOutside = false,
                                closeButtonText = 'close',
                                size = 'small',
                                extraIcon,
                                className = '',
                                showCloseOption = true,
                                footerContent,
                                ...props
                            }: ByzzerModalProps) {

    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (show === true) document.body.style.position = 'absolute';
        else document.body.style.position = 'unset';
    }, [show]);

    const wrapperClassNames = classnames(className, {
        'byzzer-modal-wrapper': true,
        'byzzer-modal-wrapper--visible': show,
        [`byzzer-modal-wrapper--${type}`]: true,
    });

    const headerClassNames = classnames({
        [`byzzer-modal-header__${headerType}`]: true,
        [`byzzer-modal-header-br`]: Boolean(heading),
    });

    const modalClassNames = classnames({
        [`byzzer-modal--${size}`]: true,
    });

    const onClickOutside = (event) => {
        if (!ref.current?.contains(event.target)) {
            return onClose?.();
        }
    };

    return (
        <div className={wrapperClassNames} {...props} onClick={closeOnClickOutside ? onClickOutside : undefined}>
            <div className={`byzzer-modal ${modalClassNames}`} ref={ref}>
                <header className={`byzzer-modal-header ${headerClassNames}`}>
                    <h1>{heading}</h1>
                    {extraIcon && <div className={'byzzer-modal-header__extraIcon'}>{extraIcon}</div>}
                    {showCloseOption && <span className={classnames(`byzzer-modal-close-icon`)} onClick={onClose}/>}
                </header>
                <main
                    className={classnames({
                        ['byzzer-modal-content']: !contentType || contentType === 'default',
                        ['byzzer-modal-error-content']: contentType === 'error',
                        ['byzzer-modal-warning-content']: contentType === 'warning',
                    })}
                >
                    {children}
                </main>
                {footerContent && (
                    <>
                        <footer className={'byzzer-modal-footer'}>
                            {footerContent}
                        </footer>
                    </>
                )}
            </div>
        </div>
    );
}

export function ByzzerModalMask({className, show, children, ...props}) {
    if (show) {
        return (
            <div className={classnames('byzzer-modal__loading-mask', className)} {...props}>
                <svg
                    className={'byzzer-modal__loading-spinner'}
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle pathLength={1} cx="50" cy="50" r="45"/>
                </svg>
                {children && <div className={'byzzer-modal__loading-text'}>{children}</div>}
            </div>
        );
    }

    return <></>;
}

export type ModalContentParams<T = unknown> = {
    /**
     * The id passed to openModal or the value that was auto generated
     */
    id: string;
    /**
     * Causes the modal to close and its promise to resolve with passed value.
     * @param value
     */
    resolve(value?: unknown): void;
    /**
     * Causes the modal to close and its promise to reject with passed value.
     * @param value
     */
    reject(value?: unknown): void;
    /**
     * Causes the modal to close and the promise to resolve with the value false.
     */
    close(): void;
    /**
     * Indicates the modal is actively performing an action
     */
    busy: boolean;
    /**
     * Puts the modal into it's busy state
     */
    setBusy: Dispatch<SetStateAction<boolean>>;
    /**
     * Holds any state that needs to be maintained by the modal.  This allows a component to update it's state.
     */
    state: T;
    /**
     * React Dispatch function that will update the state of the modal's content.
     */
    setState: Dispatch<SetStateAction<T>>;
}

export type ModalActionParams<T = unknown> = {
    /**
     * Causes the modal to close and its promise to resolve with passed value.
     * @param value
     */
    resolve(value?: unknown): void;
    /**
     * Causes the modal to close and its promise to reject with passed value.
     * @param value
     */
    reject(value?: unknown): void;
    /**
     * Causes the modal to close and the promise to resolve with the value false.
     */
    close(): void;
    /**
     * Indicates the modal is actively performing an action
     */
    busy: boolean;
    /**
     * Puts the modal into it's busy state
     */
    setBusy(isBusy: boolean): void;
    /**
     * Holds any state that needs to be maintained by the modal.  This allows a component to update it's state.
     */
    state: T;
}

export type ModalStateParams<T = unknown> = {
    /**
     * Indicates the modal is actively performing an action
     */
    busy: boolean;
    /**
     * Holds any state that needs to be maintained by the modal.  This allows a component to update it's state.
     */
    state: T;
}

export type ModalAction<T = unknown> = ByzzerButtonProps & {
    key: string;
    disableIf?(params: ModalStateParams<T>): boolean;
    getDisableTip?(params: ModalStateParams<T>): string;
    action(params: ModalActionParams<T>): void | Promise<void>;
}
export type OpenModalOptions<T = unknown> = {
    /**
     * Optional unique id for the modal. This can be used to explicitly close the popup later.  A nonoid will automatically
     * be generated if no id is specified
     */
    id?: string;
    content: ((args: ModalContentParams<T>) => ReactNode) | ReactNode;
    title?: ReactNode;
    actions?: ModalAction<T>[];
    initialState?: T;
} & Partial<Omit<ByzzerModalProps, 'children'>>;

export function openModal<T = unknown>({id = nanoid(), ...props}: OpenModalOptions<T>): Promise<any> & {
    id: string;
    reject(): void;
    resolve(): void;
} {
    const promise = createModal<InstanceProps<T> & OpenModalOptions<T>>(
        ({
             isOpen,
             onResolve,
             onReject,
             close,
             instanceId,
             title,
             content,
             className,
             actions,
             initialState,
             size,
             ...props
         }) => {

            const [busy, setBusy] = useState<boolean>(false);
            const [state, setState] = useState<T>(initialState as T);

            return (
                <ByzzerModal {...props} className={className} heading={title} show={isOpen} onClose={onResolve}
                             size={size ?? 'default'}
                             footerContent={actions?.map(({action, disableIf, getDisableTip, disabledTip, ...props}) => (
                                 <ByzzerButton {...props}
                                               disabled={disableIf?.({busy, state})}
                                               disabledTip={getDisableTip?.({busy, state}) || disabledTip}
                                               onClick={() => action({
                                                   close: onReject,
                                                   resolve: onResolve,
                                                   reject: onReject,
                                                   busy,
                                                   setBusy,
                                                   state,
                                               })}/>
                             ))}>
                    {isFunction(content) ? content({
                        close,
                        busy,
                        setBusy,
                        state,
                        setState,
                        resolve: onResolve,
                        reject: onReject,
                        id: instanceId,
                    }) : content}
                </ByzzerModal>
            );
        }
    )({
        instanceId: id,
        ...props,
    });

    // @ts-ignore
    promise.id = id;
    // @ts-ignore
    promise.resolve = () => {
        // @ts-ignore
        window.byzzerModals.resolve(id);
    };
    // @ts-ignore
    promise.reject = () => {
        // @ts-ignore
        window.byzzerModals.reject(id);
    };
    return promise as any;
}

type ConfirmParams = {
    title?: String;
    content?: ReactNode;
    yesLabel?: string;
    noLabel?: string;
    closeButton?: boolean;
    className?: string;
    suppressed?: boolean;
    onSuppress?(): void;
    suppressable?: boolean;
    suppressMessage?: ReactNode;
}

export async function confirm(params: ConfirmParams): Promise<boolean> {

    if(params.suppressed) return true;

    return createModal<ConfirmParams & InstanceProps<boolean>, boolean>(
        ({onResolve, title, content, yesLabel = 'Yes', noLabel = 'No', closeButton = false, className,
             suppressable, suppressed = false, onSuppress, suppressMessage = 'Do not show again.'
         }) => {

            const baseClassName = 'byzzer-confirm';
            const [suppress, setSuppress] = useState<boolean>(suppressed);

            function handleSuppressChange(e: ByzzerCheckableChangeEvent<any>): void {
                setSuppress(e.checked);
            }

            function handleYes(): void {

                if(suppress) {
                    onSuppress?.();
                }
                onResolve(true)
            }

            return (
                <div className={classnames('byzzer-modal-wrapper', 'byzzer-modal-wrapper--visible')}>
                    <div className={classnames(baseClassName, className)}>
                        {Boolean(title || closeButton) && (
                            <header className={classnames(`byzzer-confirm__header`, {
                                [`${baseClassName}__header--with-close`]: closeButton
                            })}>
                                <h1>{title}</h1>
                                {closeButton && (
                                    <div className={`${baseClassName}__header-close`}>
                                        <img src={CloseIcon} alt={'close'} onClick={() => onResolve(false)}/>
                                    </div>
                                )}
                            </header>
                        )}
                        <main className={`${baseClassName}__content`}>
                            {content}
                            {suppressable && <div className={`${baseClassName}__suppress`}>
                                <ByzzerCheckbox label={suppressMessage} onChange={handleSuppressChange}/>
                            </div>}
                        </main>
                        <footer className={`${baseClassName}__footer`}>
                            <ByzzerButton onClick={() => onResolve(false)} type="negative">{noLabel}</ByzzerButton>
                            <ByzzerButton onClick={handleYes}>{yesLabel}</ByzzerButton>
                        </footer>
                    </div>
                </div>
            );
        }
    )(params);
}


export function warn(message, {isTip = true, id = hexGen(), ...props} = {}) {
    const promise = createModal(
        ({isOpen, onResolve, title, content, className, message, size = 'small', isTip = true, ...props}: any) => {
            return (
                <ByzzerModal show={isOpen} onClose={onResolve} size={size} headerType={'none'} contentType={'warning'}>
                    <div className={'byzzer-error-modal__container'}>
                        <img className={'byzzer-error-modal__container-icon'} src={warningIcon} alt={'warning'}/>
                        <span className={'byzzer-error-modal__container-text'}>
                            {isTip && <b>Tip:&nbsp;</b>}
                            <div className="byzzer-label">{message}</div>
                        </span>
                    </div>
                </ByzzerModal>
            );
        }
    )({
        instanceId: id,
        message,
        isTip,
        ...props,
    });
}

export type PromptParams = {
    /**
     * Optional id used to close and reference the modal.
     */
    id?: string;
    /**
     * Optional title for the modal.
     */
    title?: ReactNode;
    /**
     * Content to display above the input.
     */
    content: ReactNode;
    /**
     Optionally overrides the text of the submit button.  Defaults to "Cancel".
     */
    cancelLabel?: string;
    /**
     * Optionally overrides the text of the submit button.  Defaults to "Ok".
     */
    submitLabel?: string;
    /**
     * Input's placeholder text
     */
    placeholder?: string;
}

export function prompt({id = nanoid(), ...props}: PromptParams): Promise<any> & {
    id: string;
    reject(): void;
    resolve(): void;
} {
    const promise = createModal<InstanceProps<string> & PromptParams>(
        ({isOpen, onResolve, onReject, cancelLabel, submitLabel, title, content}) => {

            const [value, setValue] = useState<string>('');

            function handleSubmit() {
                onResolve(value);
            }

            function handleValueChange(e: ByzzerChangeEvent<string>) {
                setValue(e.value);
            }

            return (
                <ByzzerModal show={isOpen} heading={title} footerContent={<>
                    <ByzzerButton onClick={() => onReject} type={'negative'} label={cancelLabel}/>
                    <ByzzerButton onClick={handleSubmit} label={submitLabel}/>
                </>}>
                    {content}
                    <ByzzerTextInput value={value} onChange={handleValueChange}/>
                </ByzzerModal>
            );
        }
    )({
        instanceId: id,
        ...props,
    });

    // @ts-ignore
    promise.id = id;

    // @ts-ignore
    promise.resolve = () => {
        // @ts-ignore
        window.byzzerModals.resolve(id);
    };
    // @ts-ignore
    promise.reject = () => {
        // @ts-ignore
        window.byzzerModals.reject(id);
    };
    return promise as any;
}

type OpenErrorModalParams = {
    title?: ReactNode;
    content?: ReactNode | ((args?: any) => ReactNode);
    type?: string;
    displayErrorId?: boolean;
    showErrorId?: boolean;
    errorId?: string;
    actions?: ModalAction[];
}

export function openErrorModal(
    {
        title,
        content,
        type = '',
        showErrorId = true,
        displayErrorId = showErrorId,
        errorId,
        actions
    }: OpenErrorModalParams) {
    let errorInfo;

    if (displayErrorId && errorId) {
        // add a dash and convert to uppercase
        const formattedErrorId = errorId.replace(/^(....)/, '$1-').toUpperCase();

        errorInfo = (
            <div className={'byzzer-modal-content__reference-code'}>
                Your reference code is <em className={'error-id'}>{formattedErrorId}</em>.
            </div>
        );
    }

    function openChat() {
        // window.HubSpotConversations.widget.load();
        // @ts-ignore
        window.HubSpotConversations?.widget.open();
    }

    return openModal({
        title,
        className: classnames({
            [`byzzer-error-modal--${type}`]: type,
        }),
        content: (args) => (
            <>
                {isFunction(content) ? content(args) : content}
                {errorInfo}
                <div className={'byzzer-modal__contact'}>
                    <span onClick={openChat} className={'byzzer-modal__open-chat'}>
                        Chat With Us
                    </span>
                    |<CustomerSupportLink className={'byzzer-modal__email-us'}>Email Us</CustomerSupportLink>
                </div>
            </>
        ),
        actions: actions
    });
}

export const useModals = () => ({
    openModal,
    openErrorModal,
    confirm,
    alert,
});
