import React from 'react';
import { create as createModal } from 'react-modal-promise';
import ByzzerModal2 from '@/components/modals/ByzzerModal2';
import { ByzzerLink } from '@/components/form';
import { emailConfig } from '@/config';
import './TipsModal.scss';

export function TipModal({ onResolve, ...props }) {
    function onCloseClick() {
        onResolve({ function: 'close' });
    }

    return (
        <ByzzerModal2 className={'tips-modal'}>
            <ByzzerModal2.Header onClose={onCloseClick}>Tips:</ByzzerModal2.Header>
            <ByzzerModal2.Content className={'tips-modal__subclass-content'}>
                <div className={'tips-modal__subclass-description'}>
                    Custom characteristics are a great way to view your reports specifically for your business. Some
                    helpful use cases would be:
                </div>

                <ul className={'tips-modal__subclass-list'}>
                    <li className={'tips-modal__subclass-list-item'}>
                        If you have a unique way of segmenting your category, you can create those segments either by
                        uploading them or using existing characteristics to group them. For example, if you have defined
                        Premium vs. Regular products in a way that is unique to your team, you can create that
                        characteristic and use it throughout the product, even though it isn't a universal
                        classification.
                    </li>
                    <li className={'tips-modal__subclass-list-item'}>
                        If you want to create your own competitive set, you can upload the specific UPCs you consider to
                        be in your competitive set to run any report on that group, rather than the syndicated
                        categories. For example, if you have a list of UPCs which you consider your competitors, it
                        could be easier to upload that list and save it as one characteristic instead of applying
                        several filters when running a report.
                    </li>
                    <li className={'tips-modal__subclass-list-item'}>
                        If you wish to group values across characteristics to create segments based on correlated
                        features, you can use either OR or AND logic in the characteristic groupings. For example, in
                        some categories you may want to group items that are either Natural or Organic together, since
                        many consumers will look for either. Use characteristic groups to create a “Natural or Organic”
                        field.
                    </li>
                </ul>

                <div className={'tips-modal__subclass-footer'}>
                    If you are using this feature to create characteristics that you feel should exist but don’t
                    already, please reach out to{' '}
                    <ByzzerLink href={`mailto:${emailConfig.supportEmail}`}>
                        Byzzer support
                    </ByzzerLink>{' '}
                    so we can look into creating a syndicated field.
                </div>
            </ByzzerModal2.Content>
        </ByzzerModal2>
    );
}

export const openTips = createModal(TipModal);
