import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './SwitchCompany.scss';
import DashboardContent from '@/components/dashboard/DashboardContent';
import { TenantSelector } from "@/components/TenantSelector";

export type SwitchCompanyProps = {
    data?: any;
}

export const SwitchCompany = ({
 ...props
}: SwitchCompanyProps) => {

    const baseClassName = 'byz-base-class-placeholder';


    return (
        // @ts-ignore
        <DashboardContent
            title={'Switch Company'}
            // subTitle={subTitle}
            // extras={extras}
            // className={classnames(wrapperClass)}
        >
            <TenantSelector showCancelButton={true}/>
        </DashboardContent>
    );

};

export default SwitchCompany;

SwitchCompany.displayName = 'ChooseCompany';