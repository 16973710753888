import React, { forwardRef, ReactNode, useEffect, useRef, useState } from 'react';
// import classnames from 'classnames';
import {ByzzerChangeEventHandler, ByzzerSelectOption, WithValue, ByzzerSelect} from '@byzzer/ui-components';
import classnames from "classnames";
import { useApp } from '@/contexts/UserContext';
import { DemographicDimension } from '@/types/ReportRun';
import { SelectorLabelInfo } from '../SelectorLabelInfo/SelectorLabelInfo';


const baseClassName = 'byz-demographics-dimension-select';

export type DemographicDimensionSelectProps = {
    value?: (string | DemographicDimension)[]; // as part of BYZ-7998, value has changed to display/value format.  allowing the old format but convertng it below.  in time, we should be able to drop support for string
    className?: string;
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<DemographicDimension[]>;
    placeholder?: string;
    maxSelections?: number; 
    disabled?: boolean;
    disabledOptions?: string[] | ByzzerSelectOption[];
    sku?:string;
    required?:boolean;
} & OnlyRenderableIf;

export const DemographicDimensionSelect = forwardRef<WithValue<DemographicDimension[]>, DemographicDimensionSelectProps>(({
    className,
    label,
    name,
    onChange,
    value,
    placeholder,
    maxSelections,
    disabled,
    onlyRenderIf = true,
    disabledOptions,
    sku,
    required,
    ...props
}, ref) => {
    if(onlyRenderIf === false) return <></>

    const { demographicOptions } = useApp();
    const [internalValue, setInternalValue] = useState<DemographicDimension[]>([]);
    const reshapedAndLoadedLegacyDimensions = useRef<boolean>(false);    
    
    const DEMOGRAPHIC_DIM_LABEL_AND_TIPINFO = <> <p><b>Choose Demographics to report.</b></p>
    <span>This report includes an analysis on share of shoppers within different demographic segments. {maxSelections ? `Choose up to ${maxSelections} demographics broken out by segment in this report.` : undefined}</span></>
      
    useEffect(() => {
        if (!reshapedAndLoadedLegacyDimensions.current && value?.some(demoDim => typeof demoDim === 'string')) {
            // DemographicDimensions value format has been changed from string[] to DemographicDimension[] for BYZ-7998.  This converts values from report history to the new format, only running once.
            const reshapedValue = demographicOptions.filter((option) => value.includes(option.value))
            onChange?.({
                name,
                value: reshapedValue
            })
            reshapedAndLoadedLegacyDimensions.current = true;
        } else {
            // changes after the initial load would be handled here
            setInternalValue((value as DemographicDimension[]) ?? [])
        }
        setInternalValue((value as DemographicDimension[]) ?? []);
    }, [value])

    const handleChange = (e: any) => {
        if (onChange) {
            onChange?.({
                name,
                value: e.data, // e.data contains an array of display/value pairs so just using that for value
                data: e.data 
            })
        } else {
            setInternalValue(e)
        }
    }

    return (
        <> 
           { Number(sku) ? <SelectorLabelInfo sku={sku as string} selectorCode={'demographicDimensions'} max={ maxSelections } isLabelBold={true} required={required} /> : DEMOGRAPHIC_DIM_LABEL_AND_TIPINFO }
            <ByzzerSelect
                ref={ref}
                name={name}
                className={classnames(baseClassName, className)}
                options={demographicOptions}
                disabled={disabled}
                placeholder={placeholder}
                label={label}
                value={internalValue}
                disabledOptions={disabledOptions}
                onChange={handleChange}
                allowMultiple={true}
                maxSelections={maxSelections}
            />
        </>
    )
});

export default DemographicDimensionSelect;

DemographicDimensionSelect.displayName = 'DemographicDimensionSelect';

