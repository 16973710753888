import { DefaultRunConfig } from "@/types/ApiTypes";
import {createContext, ReactNode, useState} from "react";
// import {Onboarding} from "@/types/Onboarding";


export type OnboardingContextValue = {
    personas?: number[];
    companyPortfolio?: {
        brandNotRetailingYet?: boolean;
        categories?: string[];
        brands?: string[];
    };
    userDefaults?: DefaultRunConfig;
    invitations?: string[]; // email addresses?
};

export type OnboardingStep = keyof OnboardingContextValue;

export type OnboardingAction = 'personasSelected' | 'categoriesSelected' | 'reportDefaultsSelected' | 'invitationsSent';


export type OnboardingWizardContextValue = {
    value: OnboardingContextValue;
    onChange?: ({name, value}: {name: OnboardingStep, value: OnboardingContextValue[OnboardingStep]}) => void;
}

export const OnboardingWizardContext = createContext<OnboardingWizardContextValue>({
    value: {} as any
});
OnboardingWizardContext.displayName = 'OnboardingWizardContext'
