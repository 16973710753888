import './DodMarketFilterPreview.scss';
import React, {useMemo} from 'react';
import classnames from 'classnames';
import {DodFilters, SummedSelection} from '@/types/DodRun';
import {ByzzerChangeEventHandler} from '@byzzer/ui-components';
import {DodFilterPreview, DodFilterPreviewProps, DodSelectionPreviewNode} from '@/components/DodConfigEditor/common';
import {uniqBy} from "lodash";
import { DodSavedSelectionTypes } from '@/components/DodConfigEditor/types';

const baseClassName = 'dod-market-filter-preview';

export type DodMarketFilterPreviewProps = {
    value: DodFilters;
    onChange?: ByzzerChangeEventHandler<DodFilters>;
} & Partial<DodFilterPreviewProps>;

const savedSelectionDescription = <>
    <p>This will save the full set of selections currently in your Selection panel so that you can easily reuse them in
        future extracts.</p>
    <p>Your saved selections will be available in the "My Saved Markets" folder on the left.</p>
</>;

export const marketsInitialGroups: DodSelectionPreviewNode[] = [
    {
        label: 'Markets',
        filterType: "marketTypes",
        loadChildren(filters) {
            let totalUsMarkets = filters.markets.values.filter((mrkt) => mrkt.selectionType === 'Total US');
            let fmcgMarkets = filters.markets.values.filter((mrkt) => mrkt.selectionType === 'By Channel FMCG');
            let specialityMarkets = filters.markets.values.filter(
                (mrkt) => mrkt.selectionType === 'By Channel Speciality'
            );
            let geographyMarkets = filters.markets.values.filter((mrkt) => mrkt.selectionType === 'By Geography');
            let summedMarkets = filters.markets.summedSelections;

            return [
                {
                    label: 'Total US',
                    values: totalUsMarkets.map((mrkt) => mrkt.name),
                    summedSelections: [],
                    filterType: {
                        type: 'markets',
                        data: 'Total US'
                    }
                },
                {
                    label: 'By Channel FMCG',
                    values: fmcgMarkets.map((mrkt) => mrkt.name),
                    summedSelections: [],
                    filterType: {
                        type: 'markets',
                        data: 'By Channel FMCG'
                    }
                },
                {
                    label: 'By Channel Speciality',
                    values: specialityMarkets.map((mrkt) => mrkt.name),
                    summedSelections: [],
                    filterType: {
                        type: 'markets',
                        data: 'By Channel Speciality'
                    }
                },
                {
                    label: 'By Geography',
                    values: geographyMarkets.map((mrkt) => mrkt.name),
                    summedSelections: [],
                    filterType: {
                        type: 'markets',
                        data: 'By Geography'
                    }
                },
                {
                    label: 'Summed Markets',
                    values: [],
                    summedSelections: summedMarkets.map((sum) => {
                        return {
                            name: sum.name,
                            values: sum.values.map(value => value.name),
                        } as SummedSelection<string[]>;
                    }),
                    filterType: {
                        type: 'markets',
                        data: 'summed'
                    }
                },
            ] as DodSelectionPreviewNode[];
        },
    },
];

export function DodMarketFilterPreview({ className, ...props }: DodMarketFilterPreviewProps) {

    const count = useMemo<number>(() => {
        const {values, summedSelections} = props.value.markets;
        const allValues = [...values, ...summedSelections.map(ss => ss.values)].flat();
        return uniqBy(allValues, 'key').length;
    }, [props.value])

    return (
        <DodFilterPreview
            {...props}
            className={classnames(baseClassName, className)}
            savedSelectionType={DodSavedSelectionTypes.MARKET}
            emptyStateContent={'No Markets Selected'}
            initialGroups={marketsInitialGroups}
            title={count > 0 ? `${count} Selected` : ''}
            selectionCount={count}
            savedSelectionDescription={savedSelectionDescription}
        />
    );
}

export default DodMarketFilterPreview;
