import React, { useState, useEffect } from 'react';
import reportIcon from '@images/reportIcon.svg';
import {
    generateTable,
    formatPercent,
    toTitleCase,
    formatDateMMDDYYYY,
    numberWithCommas,
    formatPrice,
    formatPercentWithSign,
    generateOrderedList,
    generateBasicOrderedList,
    generateOrderedListWithBrandAndGrowth,
    textBold,
} from '../utils/alertUtil1_0';
import { ByzzerButton } from '@byzzer/ui-components';



function AlertCardView1_0(props) {
    let alert = props.CardData;
    const alertMap = props.alertMap;

    let results = alert.results;
    let alertResults = JSON.parse(JSON.stringify(results).replace(/Fmcg/g, 'FMCG'));
    let alertConfig = alert.actionConfig.insightParams ? alert.actionConfig.insightParams : alert.actionConfig;
    alertConfig.focus_brand = toTitleCase(alertConfig.focus_brand);
    alertConfig.category = toTitleCase(alertConfig.category);

    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'MARKET_SHARE') {
        alert.title = 'Market Share';
        alert.blurb = `${alertConfig.focus_brand}'s share of ${alertConfig.category} is ${formatPercent(
            alertResults.share_of_category
        )}%, a ${alertResults.share_of_category_change_gain_or_decline} of ${formatPercent(
            alertResults.share_of_category_change
        )} points.`;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'COMPETITIVE_PRICING') {
        alert.title = 'Pricing';
        alert.blurb = `${alertConfig.focus_brand}'s pricing position vs. competitors is ${formatPercentWithSign(
            alertResults.ratio_change
        )}%`;
        //alert.blurb = `Total category average price is $${alertResults.data.category_average_price}, ${alertResults.data.category_price_change_direction} ${formatPercent(alertResults.data.category_average_price_ya_change_percent)}% vs. prior year.`
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'DISTRIBUTION_GAIN_AND_LOSSES') {
        alert.title = 'Distribution';
        //alert.blurb = `Category distributions are ${alertResults.data.category_tdp_direction} ${formatPercent(alertResults.data.category_tdps_change_ya)}% vs. prior year`;
        alert.blurb = `${alertConfig.focus_brand}'s share of distribution is ${alertResults.share_of_tdp_diff_direction
            } by ${Math.abs(formatPercent(alertResults.share_of_tdp_diff))} share points.`;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'PROMOTION_EFFICIENCY') {
        alert.title = 'Promotion Efficiency';
        alert.blurb = `${alertConfig.focus_brand}'s promotion efficiency is $${alertResults.promotion_efficiency}, ${alertResults.change_promotion_efficiency_direction
            } ${formatPercent(Math.abs(alertResults.change_promotion_efficiency))}%`;
        //alert.blurb = `Your promotion efficiency is ${alertResults.data.promotion_efficiency}( ${alertResults.data.change_promotion_efficiency_direction} ${alertResults.data.change_promotion_efficiency}% vs. prior year).`
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'ECOSYSTEM_ALERT') {
        alert.title = 'Market Performance';
        alert.blurb = `${toTitleCase(alertResults.category)} sales are $${numberWithCommas(
            alertResults.category_sales
        )}, ${alertResults.category_sales_change_direction} ${formatPercent(
            alertResults.category_sales_change
        )}% vs. the prior year.`;
    }
    return (
        <div className="card">
            <div className="card_image">
                <img src={reportIcon} alt={alert.title} />
            </div>
            <h2 className="card_heading_report">{alert.title}</h2>
            <p className="card_blurb">{alert.blurb}</p>

            <ByzzerButton
                label={'See More'}
                onClick={() => {
                    props.handleClick(alert);
                }}
            />
        </div>
    );
}

export default AlertCardView1_0
