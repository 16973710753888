import './DraggableDimension.scss';
import React, {useState,useMemo} from 'react';
import classnames from 'classnames';
import {RowColConfig} from '@/components/DodConfigEditor/types';
import {Draggable} from 'react-beautiful-dnd';
import {SortType} from '@/types/DodRun';
import Tippy from '@tippyjs/react';
import {ByzzerRadioGroup} from './ByzzerRadioGroup';
import {ByzzerRadio} from './ByzzerRadio';
import {ByzzerCheckableChangeEvent, ByzzerCheckbox} from '@byzzer/ui-components';
import {SortToggle} from '@/components/DodConfigEditor/builders/DodLayoutBuilder/DodDesignYourLayout/SortToggle';
import { useDodWizard } from '@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext';
import {useEventDataWithUserInfo, useTrackEvent} from "@/analytics/AnalyticsContext";
import { TimePeriodRange } from '@/utils/timePeriod/TimePeriodRange';
import { useApp } from '@/contexts/UserContext';
import { format as formatDate } from 'date-fns';
import { caseInsensitiveReverseSort, caseInsensitiveSort } from '@/utils/Sort';

export interface DraggableDimensionProps extends React.HTMLAttributes<HTMLDivElement> {
    label: string;
    config: RowColConfig;
    sortable?: boolean;
    pageable?: boolean;
    index: number;
    disableColumnSelection?: boolean;
    disableRowSelection?: boolean;
    disabledColumnTip?: string;
    disabledRowTip?: string;

    onExpand?(): void;

    onConfigChange?(config: RowColConfig): void;
}

const baseClassName = 'draggable-dimension';

interface AxisToggleProps {
    value: 'row' | 'col';
    name: string;
    disableColumn?: boolean;
    disableRow?: boolean;
    disableColumnToolTip?: string;
    disableRowToolTip?: string;

    onChange?(axis: 'row' | 'col'): void;
}

function AxisToggle({
                        value,
                        name,
                        disableColumn,
                        disableRow,
                        disableColumnToolTip,
                        disableRowToolTip,
                        onChange,
                    }: AxisToggleProps) {
    function handleChange(e: ByzzerChangeEvent<'row' | 'col' | undefined>): void {
        onChange?.(e.value!);
    }

    return (
        <ByzzerRadioGroup value={value} name={name} onChange={handleChange}>
            {disableRow ? (
                <Tippy content={disableRowToolTip} placement={'right'} delay={[500, 0]}>
                    {/* this wrapping div is necessary because ByzzerRadio doesn't implement forward and Tippy needs one*/}
                    <div>
                        <ByzzerRadio
                            value={'row'}
                            disabled={true}
                            label={'Rows'}
                            className={`${baseClassName}__axis-toggle`}
                        />
                    </div>
                </Tippy>
            ) : (
                <ByzzerRadio value={'row'} label={'Rows'} className={`${baseClassName}__axis-toggle`}/>
            )}
            {disableColumn ? (
                <Tippy content={disableColumnToolTip} placement={'right'} delay={[500, 0]}>
                    {/* this wrapping div is necessary because ByzzerRadio doesn't implement forward and Tippy needs one*/}
                    <div>
                        <ByzzerRadio
                            value={'col'}
                            disabled={true}
                            label={'Columns'}
                            className={`${baseClassName}__axis-toggle`}
                        />
                    </div>
                </Tippy>
            ) : (
                <ByzzerRadio value={'col'} label={'Columns'} className={`${baseClassName}__axis-toggle`}/>
            )}
        </ByzzerRadioGroup>
    );
}

export function DraggableDimension({
                                       className,
                                       label,
                                       children,
                                       config,
                                       sortable = true,
                                       pageable = true,
                                       disableColumnSelection = false,
                                       disableRowSelection = false,
                                       disabledColumnTip,
                                       disabledRowTip,
                                       index,
                                       onExpand,
                                       onConfigChange,
                                       ...props
                                   }: DraggableDimensionProps) {
    const [expanded, setExpanded] = useState<boolean>(false);
    const trackEvent = useTrackEvent();
    const getEventData = useEventDataWithUserInfo();
    const { runConfig } = useDodWizard();
    const { maxDataDates } = useApp();
    const maxDate = useMemo<string>(() => {
        return formatDate(maxDataDates.rms!, 'yyyyMMdd');
    }, [maxDataDates]);

    function handleSortChange(sortType: SortType): void {
        if (config.type === 'time_periods' && (sortType === 'asc' || sortType === 'desc')) {
            // BYZ-12159 maintain default asc/desc sort for timeperiods
            const { values, summedSelections } = runConfig.filters.timePeriods;
            let sortedTimePeriods: string[] = [];
            if (sortType === 'asc') {
                sortedTimePeriods = values
                    .flat()
                    .map((tp) => new TimePeriodRange(tp, maxDate))
                    .sort(TimePeriodRange.compareAsc)
                    .map((tp) => tp.toLegacyDodString());
            }

            if (sortType === 'desc') {
                sortedTimePeriods = values
                    .flat()
                    .map((tp) => new TimePeriodRange(tp, maxDate))
                    .sort(TimePeriodRange.compareDesc)
                    .map((tp) => tp.toLegacyDodString());
            }
            onConfigChange?.({
                ...config,
                sortType,
                values: [...sortedTimePeriods, ...summedSelections.map((ss) => ss.name)],
            });
        } else if (config.type === 'markets') {
            // BYZ-9320 maintain default asc/desc sort for markets
            const { values, summedSelections } = runConfig.filters.markets;
            let sortedMarkets: string[] = [];
            if (sortType !== 'desc') { // if sortType is either 'asc' or 'manual', then sort markets alphabetically
                sortedMarkets = values.map(market => market.name).sort(caseInsensitiveSort);
            }

            if (sortType === 'desc') {
                sortedMarkets = values.map(market => market.name).sort(caseInsensitiveReverseSort);
            }
            onConfigChange?.({
                ...config,
                sortType,
                values: [...sortedMarkets, ...summedSelections.map((ss) => ss.name)],
            });
        } else {
            onConfigChange?.({
                ...config,
                sortType,
            });
        }

        trackEvent({
            type: 'click',
            name: 'dod_layout_sort_click',
            data: getEventData({ dodWizardStep: 'layout', filter: config?.type, order: sortType }),
        });
    }

    function handleAxisChange(axis: 'row' | 'col'): void {
        onConfigChange?.({
            ...config,
            axis,
        });

        trackEvent({
            type: 'click',
            name: 'dod_layout_axis_toggle',
            data: getEventData({ dodWizardStep: 'layout', filter: config?.type, axis: axis }),
        });
    }

    function handlePageByChange(e: ByzzerCheckableChangeEvent<string>): void {
        onConfigChange?.({
            ...config,
            pageBy: e.checked,
        });

        trackEvent({
            type: 'click',
            name: e.checked ? 'dod_layout_page_by_checked' : 'dod_layout_page_by_unchecked',
            data: getEventData({ dodWizardStep: 'layout', filter: config?.type }),
        });
    }

    function handleExpand() {
        setExpanded((expanded) => !expanded);
    }

    return (
        <Draggable isDragDisabled={false} draggableId={config.type} index={index}>
            {(provided, snapshot) => (
                <>
                    <div
                        className={classnames(baseClassName, className)}
                        {...props}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <i className={`${baseClassName}__dnd-handle`}/>
                        <SortToggle value={config.sortType} sortable={sortable} onChange={handleSortChange}/>
                        <h1 className={`${baseClassName}__label`}>{label}</h1>
                        <div className={`${baseClassName}__actions`}>
                            <AxisToggle
                                value={config.axis}
                                name={config.dim}
                                onChange={handleAxisChange}
                                disableColumn={disableColumnSelection}
                                disableRow={disableRowSelection}
                                disableColumnToolTip={disabledColumnTip}
                                disableRowToolTip={disabledRowTip}
                            />
                            <ByzzerCheckbox
                                checked={config.pageBy}
                                onChange={handlePageByChange}
                                label={'Page By'}
                                className={classnames(`${baseClassName}__page-by`, {
                                    [`${baseClassName}__page-by--hidden`]: !pageable,
                                })}
                            />
                            <span className={classnames(`${baseClassName}__manage-selections-icon`)} onClick={handleExpand}/>
                            {/* <div className={`${baseClassName}__expansion-toggle`} onClick={handleExpand}>
                                Manage Selectionsdffsfdsfsd
                            </div> */}
                        </div>
                    </div>
                    {expanded && children}
                </>
            )}
        </Draggable>
    );
}

export default DraggableDimension;
