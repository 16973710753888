import './TimePeriodRunConfigFiltersStep.scss';
import React, {ReactNode, useRef, useState} from "react";
import classnames from "classnames";
import {
    TimePeriodRunConfigFilters,
    TimePeriodRunConfigFiltersProps
} from "./TimePeriodRunConfigFilters";
import {AccordionWizard, StepRef} from "@byzzer/ui-components";

export type TimePeriodRunConfigFiltersStepProps = {
    title?: ReactNode;
} & TimePeriodRunConfigFiltersProps;

const baseClassName = 'time-period-run-config-filters-step';

export const TimePeriodRunConfigFiltersStep = React.forwardRef<StepRef, TimePeriodRunConfigFiltersStepProps>(
    ({className, title, ...props}, ref) => {

        const [filtersValid, setFiltersValid] = useState<boolean>(false);

        function handleValidationChange(e: ByzzerValidityChangeEvent) {
            setFiltersValid(e.isValid)
        }

        return <AccordionWizard.Step className={classnames(baseClassName, className)} ref={ref} title={title}>
            <AccordionWizard.Header className={classnames({
                'report-run-config-wizard-header--valid': filtersValid
            })}>
                <h1 className={'report-run-config-wizard__step-title'}>{title}</h1>
            </AccordionWizard.Header>
            <AccordionWizard.Content>
                <TimePeriodRunConfigFilters {...props} onValidityChange={handleValidationChange}/>
            </AccordionWizard.Content>
            <AccordionWizard.Actions disableNext={!filtersValid}
                                     nextDisabledTip={'You must choose all required values to continue.'}/>
        </AccordionWizard.Step>
    });
