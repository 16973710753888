import React, {useEffect, useMemo, useRef} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const ssoHost = import.meta.env.REACT_APP_SSO_HOST;
const scaUrl = import.meta.env.REACT_APP_SCA_URL;
const clientId = import.meta.env.REACT_APP_SSO_CLIENT_ID;
const scaLogoutUrl = import.meta.env.REACT_APP_SCA_LOGOUT_URL;
const autoLogoutDefault = import.meta.env.REACT_APP_SCA_AUTO_LOGOUT === 'true';
const env = import.meta.env.REACT_APP_SSO_ENV;
const redirectUrl = `${ssoHost}/login_notifier`;
const ssoLoginUrl = `${ssoHost}/auth?client_id=${clientId}&env=${env}&redirect_uri=${redirectUrl}&scope=openid+email&response_type=code`;
const ssoLogoutUrl = `${ssoHost}/session/end`;
let currentIdToken;
let loggedIn;

function SuiteCommerceSSO({ autoLogoutSca = autoLogoutDefault }) {
    const iframe = useRef(null);

    useEffect(() => {
        loggedIn = false;

        function ssoMessageHandler({ data, origin }) {
            if (origin !== ssoHost) return;

            if (data.isLoggedIn === false && loggedIn && autoLogoutSca) {
                // the target "_sca" is important, this is the same target used to open to sca for the shop and it will keep the window from flashing if it's already open
                const logoutWindow = window.open(scaLogoutUrl, '_sca');
                loggedIn = false;
                if (logoutWindow) {
                    logoutWindow.blur();
                    setTimeout(() => {
                        logoutWindow.close();
                    }, 1500);
                }
            }
        }

        const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                currentIdToken = await user.getIdToken();
                signIn(currentIdToken);
                loggedIn = true;
            } else {
                if (loggedIn) {
                    currentIdToken = null;
                    signOut();
                }
            }
        });

        window.addEventListener('message', ssoMessageHandler);

        return () => {
            window.removeEventListener('message', ssoMessageHandler);
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function signIn(idToken) {
        iframe.current.src = `${ssoLoginUrl}&fbIdToken=${idToken}`;
    }

    function signOut() {
        iframe.current.src = ssoLogoutUrl;
    }

    return <iframe title="byzzer" hidden={true} ref={iframe} style={{ display: 'none', position: 'fixed' }} />;
}

export function useSuiteCommerceLink() {

    async function getLoginUrl() {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
            const currentIdToken = await currentUser.getIdToken();
            return `${ssoHost}/auth?client_id=${clientId}&env=${env}&redirect_uri=${scaUrl}&scope=openid+email&response_type=code&fbIdToken=${currentIdToken}`;
        }
    }

    return useMemo(() => ({
        async openInNewTab() {
            const url = await getLoginUrl();
            if (url) {
                window.open(url, '_sca');
            }
        },
        async openInCurrentTab() {
            const url = await getLoginUrl();
            if (url) {
                window.location.assign(url);
            }
        },
        async openPathInNewTab(path) {
            const urlSuffix = path ? encodeURI(path) : '';
            window.open(`${scaUrl}/${urlSuffix}`, '_sca');
        },
        async openPathInCurrentTab(path) {
            const urlSuffix = path ? encodeURI(path) : '';
            window.location.assign(`${scaUrl}/${urlSuffix}`);
        },
        logout() {
            const logoutWindow = window.open(scaLogoutUrl, '_sca');
            loggedIn = false;
            if (logoutWindow) {
                logoutWindow.blur();
                setTimeout(() => {
                    logoutWindow.close();
                }, 1500);
            }
        },
    }), []);
}

export default SuiteCommerceSSO;
