import { useEffect } from 'react';
import classnames from 'classnames';
import { ByzzerButton, ByzzerTipIcon } from '@byzzer/ui-components';
import { StoriesProvider } from '@/views/story/buildStory/StoriesContext';
import { useBetterNavigate } from '@/utils';
import { useLocation } from 'react-router-dom';
import DashboardContent from '@/components/dashboard/DashboardContent';
import { StoryRunConifgWizard } from '../../views/story/buildStory/StoryRunConfigWizard';
export const StoryRunConfigEditor = () => {
    const wrapperClass = classnames('my-story');
    const navigate = useBetterNavigate();
    const { state }: any = useLocation();
    let runValue = (state?.storyConfig.reportDetails || state?.storyConfig.metadata)?.reportRunValue;
    useEffect(() => {
        if (!state) {
            navigate('/dashboard/stories/library');
        }
    }, []);
    return (
        <StoriesProvider>
            <DashboardContent
                title={state?.storyConfig.title}
                numberTag={<NumberTagReportRuns value={runValue} reportRuns={state?.remainingReportRuns}/>}
                extras={
                    <div className="byzzer-btn">
                        <ByzzerButton
                            label={'Cancel'}
                            onClick={() => navigate('/dashboard/stories/library')}
                            disabled={undefined}
                        />
                    </div>
                }
                className={classnames(wrapperClass)}
                key={localStorage['storySku']}
                links = {undefined} // have to assign a default value, otherwise it will be 'true'
                exact = {undefined}
                replace = {undefined}
                loading={!state}
            >
                <StoryRunConifgWizard state={state} />
            </DashboardContent>
        </StoriesProvider>
    );
};

function NumberTagReportRuns({ value, reportRuns }) {

    if(reportRuns === Infinity) return <></>;

    return (
        <div className={`report-run-selector__tag`}>
            {value > 1 ? `-${value} runs` : `-${value} run`}
            <ByzzerTipIcon
                tip={`This will deduct ${
                    value > 1 ? `${value} core run credits` : `${value} core run credit`
                } from your subscription`}
                tipLocation="right"
            />
        </div>
    );
}
