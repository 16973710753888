import React, { useState, useEffect, ReactNode, useRef, useMemo } from "react";
import classnames from 'classnames';
import './LinksGroup.scss';
import { NavLink } from "react-router-dom";

export type LinksGroupProps = {
    /**
     * EXAMPLE OF 'links':
     * 
     *     const links = [
                {url: "/dashboard/my_subscription/subscription", text: 'Subscription'},
                {url: "/dashboard/my_subscription/members",      text: 'Members'}
            ];
     *       
     */
    links: {
        url: string;
        text: string;
        disabled?: boolean;
    }[];
    /**
     * 'replace' and 'exact' are used in the react-router-dom NavLink component.  Not sure what they do at the moment...
     */
    replace?: boolean;
    /**
     * 'replace' and 'exact' are used in the react-router-dom NavLink component.  Not sure what they do at the moment...
     */
    exact?: boolean;
    /**
     * Flexbox flex-direction
     */
    orientation?: 'row' | 'column';
    /**
     * Passing a 'baseClass' prop would override the default baseclass with your own.  Tread carefully...
     */
    baseClass?: string;
    /**
     * Rendering the children components for each links
     */
}

export const LinksGroup = ({
    links,
    replace,
    exact,
    orientation = 'row',
    baseClass,
    ...props
}: LinksGroupProps) => {
    const baseClassName = baseClass ?? 'byz-links-group';

    if (!links) return <></>;

    const renderLinks = useMemo(() => {return links}, [links])


    return (
        <>
            <div className={classnames(`${baseClassName}`, {
                [`${baseClassName}__column`]: orientation === 'column',
                [`${baseClassName}__row`]: orientation === 'row',
            })}>
                {renderLinks.map(({ url, text, disabled }) => {
                    if (disabled) {
                        return (
                            <div
                                key={url}
                                className={classnames(`${baseClassName}__link`, `${baseClassName}__link--disabled`)}
                            >
                                <span className={`${baseClassName}__link-text`}>{text}</span>
                            </div>
                        )

                    } else {
                        return (
                            <NavLink
                                className={({ isActive }) => classnames(
                                    `${baseClassName}__link`,
                                    {
                                        [`${baseClassName}__link--active`]: isActive,
                                        [`${baseClassName}__link--disabled`]: disabled
                                    }
                                )}
                                replace={replace}
                                end={exact}
                                to={url}
                                key={url}
                            >
                                <span className={`${baseClassName}__link-text`}>{text}</span>
                            </NavLink>
                        )
                    }
                })}
            </div>
        </>
    )

};

export default LinksGroup;

LinksGroup.displayName = 'LinksGroup';