/**
 * information for each report product sku
 * @name sku report product sku
 * @name businessQuestions business question ids source: globalVars.js
 * @name roles role ids source: globalVars.js
 * @name type report types source: globalVars.js
 * @name features features for product sku
 * @name section section to which the report belongs to sourse: globalVars.js
 */
 export const productSkuInfo = [
    {
        sku: '57',
        businessQuestions: [1,4],
        roles: [1, 3],
        type: 1,
       section: 3,
        features: ['Brand Rank'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '43',
        businessQuestions: [2],
        roles: [1, 2, 3, 4, 5, 6],
        type: 1,
       section: 3,
        features: ['Driver $ Value'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '242',
        businessQuestions: [1, 3],
        roles: [1, 5],
        type: 1,
       section: 3,
        features: ['Weekly and 4-week view'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 3,
    },
    {
        sku: '25',
        businessQuestions: [1, 3],
        roles: [1, 3],
        type: 2,
        section: 2,
        features: [
            'Fair Share',
            'Hurdle Rates',
            'UPC Level',
            'Brand Rank',
            'TDP or Distribution',
            'Velocity',
            '% of Stores Selling',
        ],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '244',
        businessQuestions: [1, 8],
        roles: [4, 5],
        type: 2,
       section: 3,
        features: ['Filtered to Independent Brands', 'TDP or Distribution'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '33',
        businessQuestions: [1,4],
        roles: [1, 3, 4, 6],
        type: 1,
       section: 3,
        features: ['Item Rank', 'UPC Level'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '34',
        businessQuestions: [3],
        roles: [1, 3],
        type: 1,
        section: 2,
        features: ['$ Opportunity for Fair Share'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '241',
        businessQuestions: [3],
        roles: [1, 3],
        type: 1,
       section: 3,
        features: [],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '28',
        businessQuestions: [4,5],
        roles: [1, 5],
        type: 2,
        section: 4,
        features: ['Promotion Efficiency', 'Weeks of Support'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '35',
        businessQuestions: [4],
        roles: [5],
        type: 1,
        section: 4,
        features: ['Promoted Price', '% on Deal', 'Run by Market or Brand'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '36',
        businessQuestions: [4],
        roles: [1, 5],
        type: 1,
        section: 4,
        features: ['Promoted Price', '% on Deal', 'Depth of Discount'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '238',
        businessQuestions: [1],
        roles: [4, 6],
        type: 1,
        section: 1,
        features: ['Private Label Segmentation'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '46',
        businessQuestions: [2,8],
        roles: [4, 6],
        type: 1,
       section: 3,
        features: ['Driver $ Value', 'Characteristic of your choice'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '31',
        businessQuestions: [],
        roles: [1, 4, 5],
        type: 1,
       section: 3,
        features: ['Characteristic of your choice'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '45',
        businessQuestions: [1, 8],
        roles: [4, 6],
        type: 1,
        section: 1,
        features: ['Characteristic of your choice'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 3,
    },
    {
        sku: '30',
        businessQuestions: [2],
        roles: [1, 2, 5],
        type: 1,
        section: 5,
        features: ['Decomposition view', 'Promotional Tactic Details'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '29',
        businessQuestions: [5],
        roles: [2],
        type: 1,
        section: 5,
        features: ['Promotion Efficiency'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '37',
        businessQuestions: [5],
        roles: [1, 2],
        type: 1,
        section: 5,
        features: ['12 Month View', 'Weeks of Support', 'Lift', 'Promotion Efficiency'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '44',
        businessQuestions: [5],
        roles: [2],
        type: 1,
        section: 5,
        features: ['Weeks of Support', 'Lift by Promotional Tactic'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '38',
        businessQuestions: [6],
        roles: [2, 4],
        type: 1,
        section: 6,
        features: ['Shopper Demographics'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '239',
        businessQuestions: [6,8],
        roles: [2, 4],
        type: 1,
        section: 6,
        features: ['Shopper Demographics'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '42',
        businessQuestions: [7],
        roles: [2],
        type: 2,
        section: 6,
        features: ['Shopper Demographics'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '39',
        businessQuestions: [],
        roles: [2, 5],
        type: 2,
        section: 6,
        features: ['Shopper Leakage'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '240',
        businessQuestions: [6, 7, 8],
        roles: [1, 2],
        type: 2,
        section: 6,
        features: ['Shopper Loyalty'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '41',
        businessQuestions: [6, 7],
        roles: [1, 2],
        type: 2,
        section: 6,
        features: ['$ Value by Shopper Change'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '40',
        businessQuestions: [2, 6, 7, 8],
        roles: [2, 5],
        type: 1,
        section: 6,
        features: ['Decomposition View', 'Value of a Shopper', 'Shopper Penetration'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '243',
        businessQuestions: [1, 8],
        roles: [4, 5, 6],
        type: 2,
        section: 3,
        features: ['Drill Down Format', 'Subcategory and Brand'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 3,
    },
    {
        sku: '245',
        businessQuestions: [1, 8],
        roles: [],
        type: 2,
       section: 3,
        features: [],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '19',
        businessQuestions: [1, 2],
        roles: [1,3,6],
        type: 1,
        features: ["4 week performance","Markets with greatest share increase and decrease for your brand"],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 3,
    },
    {
        sku: '20',
        businessQuestions: [1, 2, 3],
        roles: [1,3,6],
        type: 1,
        features: ["Brands with biggest distribution gains and lossess", "Markets with the biggest distribution gains and losses for your brand"],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 3,
    },
    {
        sku: '21',
        businessQuestions: [4],
        roles: [5],
        type: 1,
        features: ["4 week category price trends", "Brands with the biggest increases and decreases in average price "],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 3,
    },
    {
        sku: '22',
        businessQuestions: [5],
        roles: [2],
        type: 1,
        features: ["Promotion efficiency", "Biggest changes in your promotion efficiency by market"],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 3,
    },
    {
        sku: '237',
        businessQuestions: [8],
        roles: [1,4],
        type: 1,
        section: 3,
        features: ["4 week performance including largest, quickest growing and biggest declining markets and brands"],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 3,
    },
    {
        sku: '450',
        businessQuestions: [1,2],
        roles: [1,2,3,4,5,6],
        type: 1,
        section: 3,
        features: ["4 week performance", "Sales growth and Distribution growth", "top growing markets"],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 3,
    },
    {
        sku: '451',
        businessQuestions: [1,2],
        roles: [3, 6],
        type: 3,
        section: 3,
        features: ["This week vs. 7 week trend", "TDP", "Category Share", "Price Position to Category"],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 3,
    },
    {
        sku: '452',
        businessQuestions: [1,3],
        roles: [1,4],
        type: 3,
        section: 3,
        features: ["This week vs. 7 week trend","TDP","Category Share","Price Position to Category"],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 3,
    },
    {
        sku: '453',
        businessQuestions: [1,2,8],
        roles: [3,4,6],
        type: 3,
        section: 3,
        features: ["Characteristic for category and by Brand","This week vs. 7 week trend","TDP","Category Share"],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 3,
    },
    {
        sku: '454',
        businessQuestions: [2,3],
        roles: [1],
        type: 3,
        section: 2,
        features: ["Items Selling","Sales per Item", "Expand and at Risk Value"],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 3,
    },
    {
        sku: '455',
        businessQuestions: [4],
        roles: [1,3,5,6],
        type: 3,
        section: 4,
        features: ["Price Position", "This week vs. 7 week trend"],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 3,
    },
    {
        sku: '456',
        businessQuestions: [1],
        roles: [1],
        type: 3,
        section: 2,
        features: ["Item Level Distribution Actions","Growth, Velocity and Price benchmarked to category"],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '457',
        businessQuestions: [4],
        roles: [2,5],
        type: 3,
        section: 4,
        features: ["Item Level Pricing Actions","Price Percentile","Price Ladders","Elasticity Segment","Price Thresholds"],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '458',
        businessQuestions: [5],
        roles: [2],
        type: 3,
        section: 5,
        features: ["Product Group Promotion Recommendations","Best Weeks to promote","Recommended price discount","Price thresholds"],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '459',
        businessQuestions: [3,8],
        roles: [1],
        type: 3,
        section: 2,
        features: ["Market Fit","Market Ease of Entry","Shopper & POS"],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '246',
        businessQuestions: [1,8],
        roles: [4,5],
        type: 2,
        section: 3,
        features: ["Independent Brands Flag"],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '449',
        businessQuestions: [1,8],
        roles: [3,6],
        type: 1,
       section: 1,
        features: ["Total Category Sales"],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 3,
    },
    {
        sku: '485',
        businessQuestions: [1, 3],
        roles: [],
        type: 2,
        section: 3,
        features: [],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '514',
        businessQuestions: [1, 2, 3],
        roles: [4, 6],
        type: 1,
        section: 7,
        features: ['Attribute Performance'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '515',
        businessQuestions: [4],
        roles: [4, 6],
        type: 1,
        section: 7,
        features: ['Attribute Performance'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '516',
        businessQuestions: [1, 2, 3],
        roles: [4, 6],
        type: 1,
        section: 7,
        features: ['Attribute Performance'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '517',
        businessQuestions: [1, 2, 3, 4],
        roles: [4, 6],
        type: 1,
        section: 7,
        features: ['Attribute Performance'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '518',
        businessQuestions: [1, 2, 3],
        roles: [4, 6],
        type: 1,
        section: 7,
        features: ['Attribute Performance'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '489',
        businessQuestions: [5],
        roles: [],
        type: 2,
        section: 5,
        features: [],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '490',
        businessQuestions: [4],
        roles: [],
        type: 1,
        section: 4,
        features: [],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '491',
        businessQuestions: [3,8],
        roles: [1,6],
        type: 2,
        section: 1,
        features: ['Data across parent retailer and banners','Shopper & POS'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '483',
        businessQuestions: [1],
        roles: [],
        type: 1,
        section: 3,
        features: [],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '492',
        businessQuestions: [8],
        roles: [],
        type: 1,
        section: 3,
        features: [],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '484',
        businessQuestions: [1],
        roles: [],
        type: 1,
        section: 3,
        features: [],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
   
    {
        sku: '788',
        businessQuestions: [1,8],
        roles: [3, 5],
        type: 2,
        section: 3,
        features: ['Growth Rank','Sales Threshold','Growth Threshold'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '789',
        businessQuestions: [1,8],
        roles: [3,5],
        type: 2,
        section: 3,
        features: ['Subcategory Growth','Subcategory Ease of Entry','Shopper & POS'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '790',
        businessQuestions: [2,6],
        roles: [4,6],
        type: 2,
        section: 6,
        features: ['Brand level shopper data'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '792',
        businessQuestions: [5],
        roles: [1,2],
        type: 2,
        section: 5,
        features: ['Weeks of Support',
            'Lift by Promotional Tactic',
            'Promotion Efficiency'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '794',
        businessQuestions: [2,5,8],
        roles: [5,6],
        type: 3,
        section: 4,
        features: ['Elasticities',
            'Promotional Multipliers',
            'Price Thresholds'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '850',
        businessQuestions: [1,2,8],
        roles: [3,5],
        type: 3,
        section: 4,
        features: ['Volume Drivers Tree', 'Channel Mix'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '851',
        businessQuestions: [1,4],
        roles: [1, 3],
        type: 1,
       section: 3,
        features: ['Brand Rank'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },
    {
        sku: '852',
        businessQuestions: [1, 3],
        roles: [1, 5],
        type: 1,
       section: 3,
        features: ['Weekly and 4-week view'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 3,
    },
    {
        sku: '853',
        businessQuestions: [1],
        roles: [],
        type: 1,
        section: 3,
        features: [],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '854',
        businessQuestions: [1,8],
        roles: [3,5],
        type: 2,
        section: 3,
        features: ['Subcategory Growth','Subcategory Ease of Entry','Shopper & POS'],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 2,
    },
    {
        sku: '855',
        businessQuestions: [1],
        roles: [],
        type: 1,
        section: 3,
        features: [],
        overview: '',
        imageUrl: '',
        measures: '',
        recommendedCadence: 1,
    },

];
