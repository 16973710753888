import React, { useState } from "react";
import classnames from 'classnames';
import './UserDefaultsAndPreferences.scss';
import { UserDefaultSelections } from "./UserDefaultSelections";
import { DefaultsEditor } from "./DefaultsEditor";
import { ByzzerMask } from "../ByzzerMask/ByzzerMask";
import {NotificationPreferences} from "./NotificationPreferences";

export type NewUserPreferencesProps = {}

const baseClassName = 'byz-user-preferences';

export const UserDefaultsAndPreferences = ({}: NewUserPreferencesProps) => {

    const [showDefaultsEditor, setShowDefaultsEditor] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    function handleLoading(loading: boolean) {
        setLoading(loading);
    }

    function handleCancel() {
        setShowDefaultsEditor(false);
    }

    function handleEditModeClick() {
        setShowDefaultsEditor(true);
    }


    return (
        <div className={classnames(`${baseClassName}`)}>
            <ByzzerMask show={loading} loading={loading} isFullScreen={true}>Saving Preferences...</ByzzerMask>
            {showDefaultsEditor ? (
                <DefaultsEditor onCancel={handleCancel} setLoading={handleLoading} />
            ) : (
                <div className={classnames(`${baseClassName}__display`)}>
                    <UserDefaultSelections onEditModeClick={handleEditModeClick}/>
                    <NotificationPreferences setLoading={handleLoading} />
                </div>
            )}
 
        </div>
    );

};

export default UserDefaultsAndPreferences;

UserDefaultsAndPreferences.displayName = 'UserDefaultsAndPreferences';