import React from 'react';
import './UserProfile.scss';
import {withNaming} from '@bem-react/classname';
import {Outlet} from 'react-router-dom';
import DashboardContent from "@/components/dashboard/DashboardContent";
import { useUser } from '@/contexts/UserContext';
import useIsMobile from '@/hooks/useIsMobile';

const clsName = withNaming({n: 'user-profile__', e: '-', m: '-'});

function UserProfile({className}) {
    const { isCsrObo } = useUser();
    const isMobile = useIsMobile();

    let links = [];

    if (!isMobile) {
        links.push(
            {url: "/dashboard/user_profile/preferences", text: 'Preferences'},
            {url: "/dashboard/user_profile/personal", text: 'Personal Info'}
        );
    }
    if (!isMobile && !isCsrObo) {
        links.push({ url: '/dashboard/user_profile/password', text: 'Password' });
    }
    return <DashboardContent title={'My Profile'} className={'user-profile'} links={links}>
        <Outlet/>
    </DashboardContent>
}

export default UserProfile;
