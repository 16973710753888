import React from 'react';
import './DodConditionBuilderModal.scss';
import {openModal} from '@/components/form';
import {DodFactSet, DodRunConfig, FactCondition} from "@/types/DodRun";
import {cloneDeep} from "lodash";
import {
    DodConditionEditor
} from "@/components/DodConfigEditor/builders/DodLayoutBuilder/DodLayoutConditionBuilder/DodConditionBuilder";

const baseClassName = 'dod-condition-builder-modal';


interface DodConditionBuilderModalParams {
    runConfig: DodRunConfig,
}

interface DodConditionBuilderValue {
    conditions: FactCondition[];
    facts: DodFactSet[];
}

export async function openDodConditionBuilderModal({runConfig}: DodConditionBuilderModalParams): Promise<FactCondition[]> {
    return openModal<DodConditionBuilderValue>({
        className: baseClassName,
        title: 'Data On Demand Conditions',
        initialState: {
            conditions: cloneDeep(runConfig.conditions),
            facts: runConfig.facts
        },
        showCloseOption: false,
        content: ({state, setState}) => {

            function handleChange(e: ByzzerChangeEvent<FactCondition[]>): void {

                setState(state => ({
                    ...state,
                    conditions: e.value
                }))
            }

            return <>
                <DodConditionEditor value={state.conditions} facts={state.facts} onChange={handleChange}/>
            </>
        },
        actions: [{
            key: 'cancel',
            type: 'negative',
            label: 'Cancel',
            action({resolve}) {
                resolve();
            }
        }, {
            key: 'clear',
            type: 'negative',
            label: 'Remove All Conditions',
            action({resolve}) {
                resolve([]);
            }
        }, {
            key: 'create',
            label: 'Apply',
            disableIf({state}) {
                return state.conditions.length === 0 || state.conditions.some(v => !v.fact || !v.comparator || !v.value)
            },
            action({resolve, state}) {
                resolve(state.conditions);
            }
        }]
    });
}
