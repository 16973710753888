import { ByzzerMultiSelect, ByzzerSelect } from '@/components/form';
import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import './ProductHierarchySelector.scss';
import infoIcon from '@images/icons/InformationIcon.svg';
import warningRed from '@images/extracts/dod_warning_r.svg';
import { focusProductLevels, focusProductLevelLabelInfo } from '@/config/globalVars';
import {useTenantApi} from '@/hooks/useTenantApi';
import { useUser } from '@/contexts/UserContext';

const baseClassName = 'product-hierarchy';

export function ProductHierarchySelector({ onChange, categories, setLoading, setProductHierarchy }) {
    const { retailerHierarchies, getCategoryCount } = useTenantApi();
    const {categories: subscriptionCategories} = useUser();
    const [productLevel, setProductLevel] = useState('Category');
    const [productSet, setProductSet] = useState<any[]>([]);
    const [coverageWarning, setCoverageWarning] = useState<string>('');
    const [coverageWarningSymbol, setCoverageWarningSymbol] = useState(false);
    const [coverageInfoMessage, setCoverageInfoMessage] = useState<string>('');
    const [wholeCategoriesCount, setWholeCategoriesCount] = useState<string | number>('');
    const [competitiveProductSetList, setCompetitiveProductSetList] = useState<any[]>(['Test']);
    const [companyPrefCategories, setCompanyPrefCategories] = useState<any[]>([]);

    useEffect(() => {
        renderData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        renderProductLevelOptions(productLevel);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productLevel]);

    async function renderData() {
        setCompanyPrefCategories(subscriptionCategories);
        setCompetitiveProductSetList(subscriptionCategories);
    }

    const displayProductLevel = () => {
        return productLevel !== 'Total Store' ? productLevel + '(s)' : productLevel;
    };

    const setProductLevelOnChange = (productLevel) => {
        setProductLevel(productLevel);
    };

    const disableProductSetSelection = (productLevelField = false) => {
        let disable = false;
        if (productLevel === 'Total Store' && !productLevelField) {
            disable = true;
        }
        return disable;
    };

    const selectedProductSetData = () => {
        return productLevel === 'Category' ? categories : productSet;
    };

    const fetchSpecificProductLevelData = async (levelRequested, selectedLevel, selectedLevelData) => {
        let categAttribute;
        if (selectedLevel === 'Category') {
            categAttribute = selectedLevelData;
        } else if (selectedLevel === 'Total Store') {
            categAttribute = companyPrefCategories;
        }
        const hierarchyPostData = {
            hierarchyType: levelRequested?.toLowerCase(),
            supercategories: selectedLevel === 'Supercategory' ? selectedLevelData : undefined,
            categories: categAttribute || undefined,
            departments: selectedLevel === 'Department' ? selectedLevelData : undefined,
            subcategories: selectedLevel === 'Subcategory' ? selectedLevelData : undefined,
        };
        const prodLevelResp = await retailerHierarchies(hierarchyPostData);
        let productLevelDataResp = [];
        switch (levelRequested) {
            case 'Department':
                productLevelDataResp = prodLevelResp.departments;
                break;
            case 'Supercategory':
                productLevelDataResp = prodLevelResp.supercategories;
                break;
            case 'Category':
                productLevelDataResp = prodLevelResp.categories;
                break;
            case 'Subcategory':
                productLevelDataResp = prodLevelResp.subcategories;
                break;
            default:
                productLevelDataResp = prodLevelResp.categories;
                break;
        }
        return productLevelDataResp;
    };

    const selectProductSet = async (productSet) => {
        if (productSet.length) {
            let totalCategCountInSelectedProductLevel;
            let subscrCategsCountInProdLevelSelections;
            let categories = [...productSet];
            if (productLevel !== 'Category') {
                setLoading(true);
                categories = await fetchSpecificProductLevelData('Category', productLevel, categories);
                const categRequestPost = {
                    supercategories: productLevel === 'Supercategory' ? productSet : undefined,
                    departments: productLevel === 'Department' ? productSet : undefined,
                };
                const totalCategCountResp = await getCategoryCount(categRequestPost);
                totalCategCountInSelectedProductLevel = totalCategCountResp?.categoriesCount;
                categories = categories.filter((item) => companyPrefCategories.includes(item));
                subscrCategsCountInProdLevelSelections = categories?.length;
                setLoading(false);
            }
            setProductSet(productSet);
            setCoverageWarning('');
            setCoverageInfoMessage('');
            if (productLevel !== 'Category' && productLevel !== 'Subcategory' && categories?.length > 0) {
                setCoverageWarning(
                    `${subscrCategsCountInProdLevelSelections} of ${
                        productLevel === 'Total Store' ? wholeCategoriesCount : totalCategCountInSelectedProductLevel
                    } categories`
                );
                setCoverageWarningSymbol(
                    subscrCategsCountInProdLevelSelections /
                        (productLevel === 'Total Store'
                            ? wholeCategoriesCount
                            : totalCategCountInSelectedProductLevel) <
                        1
                );
                setCoverageInfoMessage(`Your ${productLevel} view is limited to the categories in your subscription.
            You have access to ${subscrCategsCountInProdLevelSelections}/${totalCategCountInSelectedProductLevel} categories in the selected ${productLevel}`);
            } else {
                setCoverageWarning('');
                setCoverageInfoMessage('');
            }
            onChange(categories);
            setProductHierarchy({
                departments: productLevel === 'Department' ? productSet : [],
                supercategories: productLevel === 'Supercategory' ? productSet : [],
                categories: productLevel === 'Category' ? productSet : [],
                totalStore: productLevel === 'Total Store' ? productSet : [],
            });
        } else {
            setCoverageWarning('');
            setCoverageInfoMessage('');
            onChange([]);
        }
    };

    async function fetchWholeCategoriesCount() {
        const categRequestPost = {
            requireTotalCount: true,
        };
        const totalCategCountResp = await getCategoryCount(categRequestPost);
        setWholeCategoriesCount(totalCategCountResp?.totalCategoriesCount ?? '');
    }

    const renderProductLevelOptions = async (productLevel) => {
        try {
            setLoading(true);
            let optionsList: any[] = [];
            setCompetitiveProductSetList([]);
            setProductSet([]);
            setProductHierarchy({
                departments: [],
                supercategories: [],
                categories: [],
                totalStore: [],
            });
            setCoverageWarning('');
            setCoverageInfoMessage('');
            if (wholeCategoriesCount === '') {
                await fetchWholeCategoriesCount();
            }
            switch (productLevel) {
                case 'Total Store':
                    optionsList = ['Total Store'];
                    setCompetitiveProductSetList(optionsList);
                    setProductSet(optionsList);
                    setProductHierarchy({
                        departments: [],
                        supercategories: [],
                        categories: [],
                        totalStore: optionsList,
                    });
                    setCoverageWarning(`${companyPrefCategories.length} of ${wholeCategoriesCount} categories`);
                    setCoverageWarningSymbol(companyPrefCategories.length / Number(wholeCategoriesCount) < 1);
                    setCoverageInfoMessage(`Your ${productLevel} view is limited to the categories in your subscription.
                You have access to ${companyPrefCategories.length}/${wholeCategoriesCount} categories in the selected ${productLevel}`);
                    break;
                case 'Department':
                    optionsList = await fetchSpecificProductLevelData('Department', 'Category', companyPrefCategories);
                    optionsList = optionsList?.sort((a, b) => a?.localeCompare(b, 'en', { sensitivity: 'base' }));
                    setCompetitiveProductSetList(optionsList);
                    break;
                case 'Supercategory':
                    optionsList = await fetchSpecificProductLevelData(
                        'Supercategory',
                        'Category',
                        companyPrefCategories
                    );
                    optionsList = optionsList?.sort((a, b) => a?.localeCompare(b, 'en', { sensitivity: 'base' }));
                    setCompetitiveProductSetList(optionsList);
                    break;
                case 'Category':
                    setCompetitiveProductSetList(subscriptionCategories);
                    break;
                case 'Subcategory':
                    optionsList = await fetchSpecificProductLevelData('Subcategory', 'Category', companyPrefCategories);
                    optionsList = optionsList?.sort((a, b) => a?.localeCompare(b, 'en', { sensitivity: 'base' }));
                    setCompetitiveProductSetList(optionsList);
                    break;
                default:
                    setCompetitiveProductSetList(companyPrefCategories);
                    break;
            }
        } catch (err) {
            // todo: need to display something to the user
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const label = (
        <div className={baseClassName}>
            <label className={`${baseClassName}__product-level-label`}>Select your</label>
            <div className={`${baseClassName}__product-level-dropdown`}>
                {/* @ts-ignore */}
                <ByzzerSelect
                    value={displayProductLevel()}
                    placeholder={displayProductLevel()}
                    options={focusProductLevels}
                    onChange={(e) => setProductLevelOnChange(e)}
                    disabled={disableProductSetSelection(true)}
                    linkedDropdown={true}
                />
                <Tooltip
                    placement="bottom"
                    overlayClassName={`${baseClassName}__product-level-tooltip`}
                    title={focusProductLevelLabelInfo}
                >
                    <img className={`${baseClassName}__product-level-tooltip-icon`} alt="info" src={infoIcon} />
                </Tooltip>
            </div>
        </div>
    );
    return (
        <section className={`${baseClassName}__product-set-section`}>
            {label}
            <div className={`${baseClassName}__product-set`}>
                {/* @ts-ignore */}
                <ByzzerMultiSelect
                    selectedOptions={selectedProductSetData()}
                    value={selectedProductSetData()}
                    disabled={disableProductSetSelection()}
                    className={`${baseClassName}__product-set-selector`}
                    name={'competitive-productset'}
                    placeholder={'Select from the list'}
                    multiSelectWithCheckBox
                    onChange={(e) => selectProductSet(e)}
                    options={competitiveProductSetList || []}
                    seperator={','}
                    allowClear={productLevel !== 'Total Store'}
                    variant={'secondary'}
                />

                {coverageWarning && (
                    <div className={`${baseClassName}__product-set-warning`}>
                        <img
                            className={`${baseClassName}__product-set-warning-img ${
                                !coverageWarningSymbol ? `hide` : ``
                            }`}
                            src={warningRed}
                            alt="product-set-warning-img"
                        />
                        <label>{coverageWarning}</label>
                        {coverageWarning && (
                            <Tooltip
                                placement="bottom"
                                overlayClassName={`${baseClassName}__product-set-warning-tooltip`}
                                title={coverageInfoMessage}
                            >
                                <img
                                    className={`${baseClassName}__product-set-warning-tooltip-icon`}
                                    alt="info"
                                    src={infoIcon}
                                />
                            </Tooltip>
                        )}
                    </div>
                )}
            </div>
        </section>
    );
}
