import React from 'react';
import './ProTips.scss'

const baseClassName = 'protips';

type ProTipsProps = {
    title?: string;
    closeProTips?: any;
};

export function ProTipsWindow({ title, closeProTips }: ProTipsProps) {
    return (
        <>
            <div className={`${baseClassName}__title`}>
                <span className={`${baseClassName}__title-text`}>{title}</span>
                {/* <i className={`${baseClassName}__close-icon`} alt={'close'} onClick={closeProTips} /> */}
            </div>
            <div className={`${baseClassName}__content`}>
                <p className={`${baseClassName}__group`}>
                    <span className={`${baseClassName}__header`}>
                        What time period are the $ Sales and Growth Thresholds based on?
                    </span>
                    <br />
                    <span className={`${baseClassName}__verbiage`}>
                        The thresholds are based on the Latest 52 Weeks of data.
                    </span>
                </p>
                <p className={`${baseClassName}__group`}>
                    <span className={`${baseClassName}__header`}>
                        What markets are the $ Sales and Growth Thresholds based on?
                    </span>
                    <br />
                    <span className={`${baseClassName}__verbiage`}>
                        The thresholds are based on an aggregation of the Total FMCG Retailers, Total Convenience
                        Stores, Total Pet Stores, and Total Liquor Plus markets. This allows us to create a total US
                        market view.
                    </span>
                </p>
                <p className={`${baseClassName}__group`}>
                    <span className={`${baseClassName}__header`}>
                        What categories are the $ Sales and Growth Thresholds based on?
                    </span>
                    <br />
                    <span className={`${baseClassName}__verbiage`}>
                        The thresholds are based on a total store view, so all the categories the manufacturer sells in.
                    </span>
                </p>
                <p className={`${baseClassName}__group`}>
                    <span className={`${baseClassName}__header`}>
                        Why don't my thresholds appear to be applied in the report?
                    </span>
                    <br />
                    <span className={`${baseClassName}__verbiage`}>
                        Don't worry - the thresholds are applied! What you are likely noticing is the difference between
                        the categories, markets, and time periods used to calculate the thresholds and the categories,
                        market, and time period you have selected for that specific report run. We use all categories,
                        Latest 52 Weeks, and a Total US market aggregation to help you filter the manufacturers based on
                        the big picture sales and YOY growth. Then, you can run the report for the categories, time
                        period, and market that you would like to zone in on.
                    </span>
                </p>
            </div>
        </>
    );
}
