import React, { createContext, useContext, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import { format } from 'date-fns-tz';
import { useTenantApi } from '@/hooks';
import { useUser } from './UserContext';
import { mergeCategories } from '@/utils/extractUtil';
import { ExtractRun as Extract } from '@/types/DataOnDemand';

interface ExtractsContextType {
    extracts: Extract[];
    loading: boolean;
    error: Error | null;
    fetchExtracts: () => Promise<void>;
    setExtracts: React.Dispatch<React.SetStateAction<Extract[]>>;
}

const defaultDateFormat = 'yyyy-MM-dd';

const ExtractsContext = createContext<ExtractsContextType | undefined>(undefined);

export const useExtracts = () => {
    const context = useContext(ExtractsContext);
    if (context === undefined) {
        throw new Error('useExtracts must be used within an ExtractsProvider');
    }
    return context;
};

export const ExtractsProvider = ({ children }) => {
    const { categories: userCategories } = useUser();
    const { getExtracts, getUserFavorite } = useTenantApi();

    const [extracts, setExtracts] = useState<Extract[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const abortControllerRef = useRef<AbortController | null>(null);
    const favoritesRef = useRef<string[]>([]);
    const fetchExtracts = useCallback(async () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        abortControllerRef.current = new AbortController();
        const { signal } = abortControllerRef.current;

        try {
            setLoading(true);

            const favoriteResult = await getUserFavorite('data_on_demand', signal);
            favoritesRef.current = favoriteResult;

            const dodExtractRecords = await getExtracts(signal);
            const sortedDodExtractRecords = dodExtractRecords.sort((a, b) => (b.id > a.id ? 1 : -1));
            const sortedResult = sortedDodExtractRecords.map((item) => {
                const summedCategories = item?.ExtractSelection?.products?.categories?.summedSelections?.map((sc) =>
                    // @ts-ignore: summedSelections is not typed
                    mergeCategories([], sc?.selections, userCategories)
                );
                const categories = mergeCategories(
                    item?.ExtractSelection?.products?.categories?.selections ?? item?.ExtractSelection?.products?.categories,
                    summedCategories,
                    userCategories
                );
                return {
                    ...item,
                    isFavorite: favoritesRef.current.includes(item.id.toString()),
                    timeStamp:
                        item?.endDtm && moment(item?.endDtm).isValid()
                            ? new Date(item?.endDtm).toLocaleTimeString()
                            : 'NA',
                    endDtm:
                        item?.endDtm && moment(item?.endDtm).isValid()
                            ? format(new Date(item?.endDtm), defaultDateFormat)
                            : null,
                    categories,
                };
            });

            setExtracts(sortedResult);
            setError(null);
        } catch (err) {
            if (err instanceof Error && err.name !== 'AbortError') {
                setError(err instanceof Error ? err : new Error('Error fetching extracts'));
            }
        } finally {
            setLoading(false);
        }
    }, []);

    const value = { extracts, loading, error, fetchExtracts, setExtracts };

    return <ExtractsContext.Provider value={value}>{children}</ExtractsContext.Provider>;
};
