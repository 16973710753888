// todo: replace this with a proper notification library
import {registerNotification} from "@/notifications/NotificationHandlers";
import { eventTypes } from '@/config/globalVars';

export type StoryNotificationBody = {
    contentType: string;
    message: string;
    referenceKey: string;
    storyName: string;
};

registerNotification<StoryNotificationBody>(eventTypes.storyGenerated, ({ body }, tenantApi) => {
    // const {downloadStory} = useTenantApi();
    const storyName = body?.storyName ?? 'Story';
    const message = body?.message || `${storyName} generated successfully!`;
    let responseType:any = body?.contentType === 'ZIP' ? 'blob' : 'json';

    if(body?.referenceKey != undefined){
        return {
            title: `Story Successfully Generated`,
            content: <p>{message}</p>,
            actions: [{
                showIf: Boolean(body?.referenceKey),
                label: 'Download Story',
                action() { // async
                    tenantApi?.downloadStory(body?.referenceKey, responseType); // await
                }
            }]
        }
    }else {
        return {
            title: 'Story Generation Failed',
            content: 'Something went wrong'
        }
    }
});

registerNotification<StoryNotificationBody>(eventTypes.storyFailed, ({ body }) => {
  const {
      storyName = 'the story',
      message = `Something went wrong. Unable to generate ${storyName}. Please reconfigure.`
  } = body || {};

  return {
      title: 'Story Generation Failed',
      content: message
  }
});
