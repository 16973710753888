import React from 'react';
import {emailConfig} from '@/config';
import {ByzzerLink} from "@/components/form/ByzzerLink";

export function CustomerSupportLink({className, children = emailConfig.supportEmail, ...props}) {
    return (
        <ByzzerLink {...props} className={className} href={`mailto:${emailConfig.supportEmail}`}>
            {children}
        </ByzzerLink>
    );
}
