export const formatPercent = (num) => {
    if (num === null || num === undefined) {
        return '';
    }
    let str = (num * 100).toFixed(1);
    let newStr = addComma(str.split('.')[0]) + '.' + str.split('.')[1];
    return newStr.includes('.') ? newStr : newStr + '.0';
};
export const formatPrice = (num) => {
    try {
        if (num === null || num === undefined) {
            return '';
        }
        num = parseFloat(num);
        let decimals = num.toString().split('.')[1];
        if (!decimals) return num + '.00';
        else if (decimals.length === 1) return num + '0';
        else return num.toFixed(2);
    } catch (e) {
        throw e;
    }
};
export const formatPercentWithSign = (num) => {
    if (num === null || num === undefined) {
        return '';
    }
    let addPositive = '';
    if (num > 0) addPositive = '+';
    let str = addPositive + (num * 100).toFixed(1);
    let newStr = addComma(str.split('.')[0]) + '.' + str.split('.')[1];
    return newStr.includes('.') ? newStr : newStr + '.0';
};
export const toTitleCase = (str) => {
    if (str === null || str === undefined) {
        return '';
    }
    return str.replace(/\w\S*/g, function (txt) {
        if (txt.indexOf('/') > -1) {
            let first = txt.split('/')[0];
            let second = txt.split('/')[1];
            return (
                first.charAt(0).toUpperCase() +
                first.substr(1).toLowerCase() +
                '/' +
                second.charAt(0).toUpperCase() +
                second.substr(1).toLowerCase()
            );
        } else {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    });
};
export const formatDateDDMMYYYY = (str) => {
    if (str === null || str === undefined) {
        return '';
    }

    function pad(s) {
        return s < 10 ? '0' + s : s;
    }

    let d = new Date(str);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
};
export const formatDateMMDDYYYY = (str) => {
    if (str === null || str === undefined) {
        return '';
    }

    function pad(s) {
        return s < 10 ? '0' + s : s;
    }

    const d = new Date(str);
    return [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join('/');
};
export const numberWithCommas = (num) => {
    if (num > 999 && num < 1000000) {
        return addComma((num / 1000).toFixed(0)) + 'K'; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
        return addComma((num / 1000000).toFixed(0)) + 'M'; // convert to M for number from > 1 million
    } else {
        return num;
    }
};

export const generateTable = (arr, config = {}) => {
    let htmlStr = '<table border="1" style="border-collapse: collapse;padding:1em;">';
    htmlStr += '<tr>';
    Object.keys(config.columnMapping).forEach((key) => {
        htmlStr += `<th style='padding:0.8em'>${config.columnMapping[key]}</th>`;
    });
    htmlStr += `</tr>`;
    let style = `style="text-align: center;padding:0.8em"`;
    arr.forEach((item) => {
        htmlStr += '<tr>';
        Object.keys(config.columnMapping).forEach((key) => {
            if (item[key] === null && config.defaultValue[key]) {
                htmlStr += `<td ${style}> ${config.defaultValue[key]}</td>`;
                return;
            }
            if (config.percentKeys.includes(key) && typeof item[key] === 'number') {
                let val = formatPercent(item[key]);
                htmlStr += `<td ${style}> ${val}%</td>`;
            } else if (config.titleCaseColumns.includes(key)) htmlStr += `<td ${style}> ${toTitleCase(item[key])}</td>`;
            else if (config.dollarColumns.includes(key) && typeof item[key] === 'number')
                htmlStr += `<td ${style}> $${formatPrice(item[key])}</td>`;
            else htmlStr += `<td ${style}> ${item[key]}</td>`;
        });
        htmlStr += '</tr>';
    });
    htmlStr += '</table>';
    return htmlStr;
};
export const generateOrderedList = (arr, olType, value) => {
    let htmlStr = '<ol style="margin-left:2em">';
    if (olType === 'growing_market') {
        arr.forEach((item) => {
            htmlStr += `<li>${toTitleCase(item.market)} (${formatPercent(item.of_category)}% of total US FMCG, `;
            if (!item.retailer_growth) htmlStr += `New market) </li>`;
            else if (item.retailer_growth <= -100) htmlStr += `Delisted) </li>`;
            if (value == 'pos') {
                htmlStr += `sales are ${returnColorSpan(
                    `${formatPercentWithSign(item.retailer_growth)}%`,
                    `#0fbc61`
                )} ) </li>`; // green
            } else if (value == 'neg') {
                htmlStr += `sales are ${returnColorSpan(
                    `${formatPercentWithSign(item.retailer_growth)}%`,
                    `#ff7b4d`
                )} ) </li>`; // orange
            }
            // else htmlStr += `sales are ${txtColor(`${formatPercentWithSign(item.retailer_growth)}%`, item.retailer_growth)} ) </li>`;
        });
    } else if (olType === 'top_market_sales') {
        arr.forEach((item) => {
            htmlStr += `<li>${toTitleCase(item.market)} (${formatPercent(
                item.of_sales_from_retailer
            )}% of total US FMCG, `;
            if (!item.change_of_sales_from_retailer) htmlStr += ` New market)</li>`;
            else if (item.change_of_sales_from_retailer <= -100) htmlStr += ` Delisted)</li>`;
            else
                htmlStr += `sales are ${txtColor(
                    `${formatPercentWithSign(item.change_of_sales_from_retailer)}%`,
                    item.change_of_sales_from_retailer
                )}) </li>`;
        });
    } else if (olType === 'largest_brand_sales') {
        arr.forEach((item) => {
            htmlStr += `<li>${toTitleCase(item.brand)} (${formatPercent(item.of_sales_from_brand)}% of category, `;
            if (!item.change) htmlStr += ` New market)</li>`;
            else if (item.change <= -100) htmlStr += ` Delisted)</li>`;
            else htmlStr += `sales are ${txtColor(`${formatPercentWithSign(item.change)}%`, item.change)}) </li>`;
        });
    }
    htmlStr += '</ol>';
    htmlStr = htmlStr.replace(/Fmcg/g, 'FMCG');
    return htmlStr;
};
export const generateOrderedListWithBrandAndGrowth = (arr) => {
    let htmlStr = '<ol style="margin-left:2em">';
    arr.forEach((item) => {
        htmlStr += `<li>${toTitleCase(item.brand)} (sales are ${numberWithCommas(item.total_dollars)}, `;
        htmlStr += item.brand_growth
            ? `${returnColorSpan(`${item.brand_growth}%`, `#0fbc61`)}) </li>`
            : `New brand)</li>`;
    });
    htmlStr += '</ol>';
    return htmlStr;
};
export const generateBasicOrderedList = (arr) => {
    let htmlStr = '<ol style="margin-left:2em">';
    arr.forEach((item) => {
        htmlStr += `<li>${toTitleCase(item)} </li>`;
    });
    htmlStr += '</ol>';
    return htmlStr;
};
export const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const textBold = (str) => {
    return str.bold();
};

// ---------------------------------color and formatting changes ------------------------------

export const getDirection = (num, positive, negative, flat) => {
    if (num >= 0.0005) {
        return positive;
    } else if (num < 0.0005 && num > -0.0005) return flat;
    else if (num <= -0.0005) return negative;
    else if (num <= -1) return 'Delisted';
    else return;
};

export const returnColorSpan = (txt, color) => {
    return `<span style="color: ${color}">${txt}</span>`;
};

export const promoEffColor = (txt, value) => {
    if (value >= 2) return `<span style="color: #0fbc61">${txt}</span>`;
    else if (value < 2 && value >= 1) return `<span style="color: #ff7b4d">${txt}</span>`;
    else if (value < 1) return `<span style="color: #ffbf3f">${txt}</span>`;
};

// text is raw html, value decides the color
export const txtColor = (txt, direction, reverse = false) => {
    let classState = '';

    if (
        direction === 'up' ||
        direction === 'increased' ||
        direction === 'over' ||
        direction === 'gained' ||
        direction === 'gain' ||
        direction === 'grew' ||
        direction >= 0.0005
    ) {
        classState = reverse ? 'negative-value' : 'positive-value';
    } else if (
        direction === 'down' ||
        direction === 'decreased' ||
        direction === 'under' ||
        direction === 'lost' ||
        direction === 'decline' ||
        direction === 'declined' ||
        direction <= -0.0005
    ) {
        classState = reverse ? 'positive-value' : 'negative-value';
    } else {
        classState = 'flat-value';
    }

    let htmlStr = `<span class=${classState}>${txt}</span>`;
    return htmlStr;
};
