import { useState, useEffect, ChangeEvent } from "react";
import classnames from 'classnames';
import './NotificationPreferences.scss';
import { ByzzerCheckableChangeEvent, ByzzerCheckbox, ByzzerRadio, ByzzerRadioGroup, ByzzerSelectOption, ByzzerTipIcon } from "@byzzer/ui-components";
import { useTenantApi } from "@/hooks";
import { useUser } from "@/contexts/UserContext";
import { DataRefreshNotificationFrequency, PlatformNotificationEmails } from "@/types/ApiTypes";
import { triggerToast } from "@/notifications/ByzzerToast";
import { DATA_REFRESH_NOTIFICATION_TIP, PLATFORM_NOTIFICATION_TIP } from "@/constants/userPreferences.constants";

export type NotificationPreferencesProps = {
    setLoading: (loading: boolean) => void;
}

export const dataRefreshNotificationOptions: ByzzerSelectOption<any, DataRefreshNotificationFrequency>[] = [
    { value: 'weekly', display: 'Weekly' },
    { value: 'bi-weekly', display: 'Bi-Weekly' },
    { value: 'monthly', display: 'Monthly' },
    { value: 'quarterly', display: 'Quarterly' },
    { value: 'yearly', display: 'Yearly' },
    { value: 'never', display: 'Never' }
];

export const platformNotificationOptions: ByzzerSelectOption<any, keyof PlatformNotificationEmails>[] = [
    { value: 'receiveWarnings', display: 'Warning Notifications' },
    { value: 'receiveRecommendations', display: 'Recommendation Notifications' },
    { value: 'receiveAnnouncements', display: 'Announcement Notifications' },
    { value: 'receiveProductUpdates', display: 'Product Update Notifications' },
];

const baseClassName = 'byz-notification-preferences';

export const NotificationPreferences = ({
    setLoading,
    ...props
}: NotificationPreferencesProps) => {

    const {user, refreshUser, company, subscription} = useUser();
    const {updateDataRefreshNoticationFrequency, updatePlatformNotificationPreference} = useTenantApi();

    const [dataRefreshNotificationFrequencyValue, setDataRefreshNotificationFrequencyValue] = useState<DataRefreshNotificationFrequency>(user?.dataRefreshNotificationFrequency ?? 'never');
    const [platformNotificationsValue, setPlatformNotificationsValue] = useState<PlatformNotificationEmails>(user?.settings?.platformNotificationEmails ?? {});

    useEffect(() => {
        if (user?.dataRefreshNotificationFrequency) {
           setDataRefreshNotificationFrequencyValue(user?.dataRefreshNotificationFrequency);
        }
    }, [user?.dataRefreshNotificationFrequency]);

    async function handleDataRefreshChange({value}: ByzzerChangeEvent<DataRefreshNotificationFrequency | undefined>) {
        setLoading(true);
        try {
            if (value) {
                await updateDataRefreshNoticationFrequency(value);
                refreshUser({
                    ...user, 
                    dataRefreshNotificationFrequency: value
                })
                triggerToast({
                    content: 'Preferences saved successfully',
                });
            }
        } catch (err) {
            console.error(`NotificationPreferences - handleDataRefreshChange error ===>>`, err);
            // todo - handle error
        } finally {
            setLoading(false);
        }
    }

    async function handlePlatformNotificationChange({value, checked}: ByzzerCheckableChangeEvent<keyof PlatformNotificationEmails>) {
        setLoading(true);
        
        try {
            if (value) {
                await updatePlatformNotificationPreference(value as keyof PlatformNotificationEmails, checked);
                refreshUser({
                    ...user,
                    settings: {
                        ...user?.settings,
                        platformNotificationEmails: {
                            ...user?.settings?.platformNotificationEmails,
                            [value]: checked
                        }
                    }
                })
                setPlatformNotificationsValue(state => ({
                    ...state,
                    [value]: checked
                }))
                triggerToast({
                    content: 'Preferences saved successfully',
                });
            }
        } catch (err) {
            console.error(`NotificationPreferences - handlePlatformNotificationChange error ===>>`, err);
            // todo - handle error
        } finally {
            setLoading(false);
        }
    }

    function handleDataRefreshOptionFilter({ value }: ArrayElement<typeof dataRefreshNotificationOptions>): boolean {
        const isFinanceCompany = company?.accessLevel === 'banks_finance';
        const hasMetadata = subscription?.metadata;
        const maxDateType = hasMetadata ? subscription.metadata.maxDateType : null;
    
        if (!isFinanceCompany) {
            return value !== 'bi-weekly';
        }
    
        if (maxDateType === 'monthly') {
            return value !== 'bi-weekly' && value !== 'weekly';
        } else {
            return value !== 'weekly';
        }
    }
    

    const filteredDataRefreshNotificationOptions = dataRefreshNotificationOptions.filter(handleDataRefreshOptionFilter);
    const sortedPlatformNotifications = [...platformNotificationOptions].sort((a,b) => a.display.localeCompare(b.display)); // Sorting platform notification options alphabetically

    return (
        <div className={classnames(`${baseClassName}`)}>
            <header className={classnames(`${baseClassName}__header`)}>
                <span className={classnames(`${baseClassName}__name`)}>
                    Notification Preferences
                </span>
            </header>
            {/* <section className={classnames(`${baseClassName}__description`)}></section> For future use */}
            <main className={classnames(`${baseClassName}__groups`)}>
                <div className={classnames(`${baseClassName}__group`)}>
                    <p className={classnames(`${baseClassName}__group-name`)}>
                        <span>Data Refresh Email Notifications</span>
                        <ByzzerTipIcon tip={DATA_REFRESH_NOTIFICATION_TIP}/>
                    </p>
                    <div className={classnames(`${baseClassName}__selections`)}>
                        <ByzzerRadioGroup 
                            name={'dataRefreshNotificationFrequency'} 
                            value={dataRefreshNotificationFrequencyValue} 
                            onChange={handleDataRefreshChange}
                        >
                            {filteredDataRefreshNotificationOptions.map(({ value, display }) => (
                                <ByzzerRadio key={value} value={value}>{display}</ByzzerRadio>
                            ))}
                        </ByzzerRadioGroup>
                    </div>
                </div>
                <div className={classnames(`${baseClassName}__group`)}>
                    <p className={classnames(`${baseClassName}__group-name`)}>
                        <span>Platform Notifications</span>
                        <ByzzerTipIcon tip={PLATFORM_NOTIFICATION_TIP}/>
                    </p>
                    <div className={classnames(`${baseClassName}__selections`)}>
                        {sortedPlatformNotifications.map(({value, display}) => (
                            <ByzzerCheckbox<keyof PlatformNotificationEmails>
                                onChange={({value, ...rest}) => handlePlatformNotificationChange({value: value as keyof PlatformNotificationEmails, ...rest})}
                                disabled={false}
                                checked={platformNotificationsValue?.[value]}
                                label={display}
                                value={value}
                            />
                        ))}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default NotificationPreferences;

NotificationPreferences.displayName = 'NotificationPreferences';