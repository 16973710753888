import './StackedReportFilters.scss';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import classnames from 'classnames';
import { orderBy } from 'lodash';
import { getAllAlerts, getAllReports } from '@/services/product.service';
import { ExpandableFilter } from '@/components/ExpandableFilter/ExpandableFilter';
import { Spinner } from '@/components/Spinner/Spinner';
import { useApp } from '@/contexts/UserContext';
import FavoriteIcon from '@/components/icons/FavoriteIcon';
import { ProductType, ProductFilterOption } from '@/types/ApiTypes';

const baseClassName = 'stacked-filter-panel';
const IS_FAVORITE = 'ísFavorite';

type FilterID = (number | string);

type FilterSelection = {
    matchType: ProductFilterOption['matchOn'];
    ids: FilterID[]
};

type ReportFilters = {
    reportHistory?: any[];
    favoriteSkus?: string[];
    onFilter: any;
    onChange?: any;
    from?: string;
    className: string;
    changeReport: boolean;
    appliesTo: ProductType;
};

const StackedReportFilters=({ 
    onFilter,
    favoriteSkus = [],
    className,
    appliesTo = 'report',
    ...props
}: ReportFilters)=> {
    const [searchParams, setSearchParams] = useSearchParams();
    const { reportFilterOptions } = useApp();
    const [selectedFilterGroups, setSelectedFilterGroups] = useState<FilterSelection[]>([]);
    const [filterFavorites, setFilterFavorites] = useState<boolean>(false);
    const [filterOptions, setFilterOptions] = useState<ProductFilterOption[]>([]);
    const [showFilterPanel, setShowFilterPanel] = useState(true);
    const [products, setProducts] = useState<ProductWithMeta[]>([]);
    const isAlert = appliesTo === 'alert';

    useEffect(() => {
        loadFilterOptions();
        loadProducts();
    }, []);

    useEffect(() => {
        removeEmptyQueryParams();
    }, [searchParams]);

    useEffect(() => {
        removeEmptyQueryParams();
        handleFiltersFromQueryParams();
    }, [filterOptions]);

    useEffect(() => {
        if (products.length > 0) {
            let updatedFilteresProducts = filterByFilterOptions(products);
            updatedFilteresProducts = filterByFavorites(updatedFilteresProducts);
            const skus = updatedFilteresProducts.map(({ sku }) => sku);
            onFilter?.(skus);
        }
    }, [selectedFilterGroups, filterFavorites, products, favoriteSkus]);

    const loadFilterOptions = () => {
        if (reportFilterOptions) {
            let filtersObjects: ProductFilterOption[] = reportFilterOptions
                .filter((filter) => filter.appliesTo.includes(appliesTo))
                .map((filter) => ({
                    ...filter,
                    values: filter.values.map(({ id, display }) => ({ display, value: id })),
                }));
            filtersObjects = orderBy(filtersObjects, ['display'], 'asc'); // Sorting report filters alphabetically
            setFilterOptions(filtersObjects);
        }
    };

    const loadProducts = () => {
        let filteredProducts = isAlert ? getAllAlerts() : getAllReports();
        setProducts(filteredProducts);
    }

    const filterByFavorites = (products: ProductWithMeta[]) => {
        if (filterFavorites) {
            return products.filter(({ sku }) => favoriteSkus.includes(sku));
        }
        return products;
    }

    const filterByFilterOptions = (products: ProductWithMeta[]) => {
        if (selectedFilterGroups.length) {
            const filteredData = products.filter(({ filterValueIds }) => {
                return selectedFilterGroups
                    .filter((filter) => filter?.ids.length) //#Added this line to check the array of selected filter `ids` contains value or not
                    .every((filter) => {
                        if (filter.matchType === 'all') {
                            return filter.ids.every((id) => filterValueIds?.includes(+id));
                        } else if (filter.matchType === 'any') {
                            return filter.ids.some((id) => filterValueIds?.includes(+id));
                        }
                    });
            });
            return filteredData;
        }
        return products;
    }

    const handleFavorites = () => {
        setFilterFavorites(!filterFavorites);
        // filterFavorites is a boolean and has to be converted to string to avoid typescript error
        setSearchParams({ ...extractExistingParams(searchParams), [IS_FAVORITE]: (!filterFavorites).toString() });
    };

    const handleFilterChange = (index: number, value: FilterID[]) => {
        const filterChange = [...selectedFilterGroups];
        filterChange[index] = { matchType: filterOptions[index].matchOn, ids: value };
        setSearchParams({ ...extractExistingParams(searchParams), [filterOptions[index].code]: value.join(',') });
        setSelectedFilterGroups(filterChange);
    };

    const toggleFilterPanel = () => {
        setShowFilterPanel((current) => !current);
    }

    // Function to handle filters from query params
    const handleFiltersFromQueryParams = (): void => {        
        const newFilters: FilterSelection[] = [];
        let queryParams = extractExistingParams(searchParams);

        for (let queryParam in queryParams) {
            let filterIds: FilterID = queryParams[queryParam];

            if (filterIds) {
                let updatedFilterIds: FilterID[] = filterIds
                    .split(',')
                    .map((item) => (isNaN(Number(item)) ? item : Number(item)));

                for (let filterIndex = 0; filterIndex < filterOptions.length; filterIndex++) {
                    if (filterOptions[filterIndex]?.code === queryParam) {
                        newFilters[filterIndex] = {
                            matchType: filterOptions[filterIndex].matchOn,
                            ids: updatedFilterIds,
                        };
                        break;
                    }
                }
            }
        }

        // This is to chcek if favorite was clicked or not
        if (queryParams[IS_FAVORITE] == 'true') {
            setFilterFavorites(true);
        }

        setSelectedFilterGroups(newFilters);
    };

    // Function to get existing query params and convert URLSearchParams to an object
    const extractExistingParams = (searchParams: URLSearchParams): Record<string, string> => {
        return Array.from(searchParams.entries()).reduce<Record<string, string>>((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {});
    };

    /**
     * Remove an empty query params from URL to avoid messy URL
     */
    const removeEmptyQueryParams = () => {
        // Extract existing parameters
        const existingParams = extractExistingParams(searchParams);

        // Iterate over parameters and remove empty ones from existingParams
        Object.keys(existingParams).forEach((key) => {
            if (!existingParams[key]) {
                delete existingParams[key];
            }
        });

        // Update the URL with the modified parameters
        setSearchParams(existingParams);
    };

    return (
        <div
            className={classnames(`${baseClassName}__container`, {
                [`${baseClassName}__container--expanded`]: showFilterPanel,
            })}
        >
            <div
                className={classnames(`${baseClassName}__container-toggle`, {
                    [`${baseClassName}__container-toggle-expanded`]: showFilterPanel,
                })}
                onClick={toggleFilterPanel}
            />
            {showFilterPanel ? (
                <div className={`${baseClassName}__filter-options-wrapper`}>
                    <div className={classnames(baseClassName, className)}>
                        {!filterOptions.length && (
                            <div className={baseClassName}>
                                <Spinner />
                            </div>
                        )}
                        {!isAlert && (
                            <div className={baseClassName + '__favorites'}>
                                <FavoriteIcon
                                    className={baseClassName + '__favorites--icon'}
                                    trackClick={`Filter by favorites`}
                                    selected={filterFavorites}
                                    onClick={handleFavorites}
                                />
                                <h1 className={baseClassName + '__favorites--title'}>Filter by favorites</h1>
                            </div>
                        )}
                        {filterOptions.map((filter, index) => {
                            const filterValue = selectedFilterGroups[index]?.ids || [];
                            return (
                                <ExpandableFilter
                                    title={filter.display}
                                    onChange={({ value }) => handleFilterChange(index, value)}
                                    value={filterValue}
                                    key={`product-filter-${index}`}
                                    options={filter.values}
                                />
                            );
                        })}
                    </div>
                </div>
            ) : (
                <div className={`${baseClassName}__new-filters-after-hidden`} onClick={toggleFilterPanel} />
            )}
        </div>
    );
}

export default React.memo(StackedReportFilters);
