import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import {RunConfigMarket} from "@/types/ReportRun";
import { noop } from "lodash";

export type MarketPickerContextValue = {
    getChildMarketNodes: (node: MarketNode) => Promise<MarketNode[]>,
    addMarket: (node: MarketNode, remainingMarketPath?: string,) => void;
    removeMarket: (node: MarketNode, removeRemaining?: boolean) => void;
    maxSelections?: number;
    selectedMarkets: RunConfigMarket[];
    showRemainingMarkets?: boolean;
    hideDisabledMarkets?: boolean;
    hideNonPromoMarkets?: boolean;
    selectedMasterCompanies: string[];
    requiredMasterCompany?: string;
    requiredMarketGroup?: string;
    requireRemainingMarket?: boolean;
    requireRemainingMarketSuffix?: string;
    exclusiveMarketPath?: string;
    includePseudoRemainingMarkets?: boolean;
    setLastClickedPath?: (path: string) => void;
    lastClickedPath?: string | null;
    setSelectableMarkets?: (markets: MarketNode[]) => void;
    selectableMarkets?: MarketNode[] | null;
    onShiftSelect?: (selectedNode: string) => void;
    enableParentMarket?: boolean;
}
export const MarketPickerContext = createContext<MarketPickerContextValue>({} as any);
MarketPickerContext.displayName = 'MarketPickerContext';

type MarketPickerProviderProps = {
    children: ReactNode;
    showRemainingMarkets?: boolean;
    hideDisabledMarkets?: boolean;
    hideNonPromoMarkets?: boolean;
}

export const useMarketPickerProvider = () => useContext<MarketPickerContextValue>(MarketPickerContext);

export function MarketPickerProvider({children, showRemainingMarkets = false, hideDisabledMarkets = false, hideNonPromoMarkets = false}: MarketPickerProviderProps) {
    const [value, setValue] = useState<MarketPickerContextValue>({
        addMarket: noop,
        removeMarket: noop,
        // todo: actually implement this, it will be confusing if this is a noop and used outside the DOD usecase
        getChildMarketNodes: () => [] as any,
        selectedMarkets: [],
        selectedMasterCompanies: [],
        maxSelections: Infinity,
        showRemainingMarkets: false,
        hideDisabledMarkets: false,
        hideNonPromoMarkets: false,
        requireRemainingMarket: false,
        requireRemainingMarketSuffix: '',
        requiredMasterCompany: '',
        requiredMarketGroup: '',
        enableParentMarket: false
    })

    useEffect(() => {
        setValue((prev) => ({ ...prev, showRemainingMarkets, hideDisabledMarkets, hideNonPromoMarkets}))
    }, [showRemainingMarkets, hideDisabledMarkets, hideNonPromoMarkets])

    return <MarketPickerContext.Provider value={value}>
        {children}
    </MarketPickerContext.Provider>
}
