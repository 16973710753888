import React from 'react'
import "./NameThumbnail.scss";

type NameTileProps = {
    name?: string;
}

const NameThumbnail = ({name = '@'}: NameTileProps) => {
  return (
    <div className='thumbanil'>{name}</div>
  )
}

export default NameThumbnail;