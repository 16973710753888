import './ByzzerChart.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
// @ts-ignore
import ZingChart from 'zingchart-react';
import { merge } from 'lodash';

export type ByzzerChartType =
    'area'
    | 'area3d'
    | 'bar'
    | 'bar3d'
    | 'boxplot'
    | 'bubble'
    | 'bubble-pack'
    | 'bubble-pie'
    | 'bullet'
    | 'calendar'
    | 'chord'
    | 'depth'
    | 'flame'
    | 'funnel'
    | 'gauge'
    | 'grid'
    | 'hbar'
    | 'hbar3d'
    | 'hboxplot'
    | 'hbubble'
    | 'hbullet'
    | 'heatmap'
    | 'hfunnel'
    | 'hmixed'
    | 'hscatter'
    | 'hwaterfall'
    | 'hwaterfall3d'
    | 'line'
    | 'line3d'
    | 'mixed'
    | 'mixed3d'
    | 'navpie'
    | 'nestedpie'
    | 'null'
    | 'pareto'
    | 'piano'
    | 'pie'
    | 'pie3d'
    | 'pop-pyramid'
    | 'radar'
    | 'rankflow'
    | 'range'
    | 'ring'
    | 'ring3d'
    | 'scatter'
    | 'scorecard'
    | 'stock'
    | 'stream'
    | 'sunburst'
    | 'tilemap'
    | 'tree'
    | 'treemap'
    | 'varea'
    | 'variwide'
    | 'vbar'
    | 'vbar3d'
    | 'vbullet'
    | 'vectorplot'
    | 'venn'
    | 'vfunnel'
    | 'vline'
    | 'vrange'
    | 'vstream'
    | 'waterfall'
    | 'waterfall3d'
    | 'wordcloud'
    | 'zingchart.maps';

export type ByzzerChartText = {
    text: string;
    fontSize?: number;
}

export type ByzzerChartConfig = {
    title?: ByzzerChartText;
    legend?: {
        draggable?: boolean;
    },
    scaleX?: {
        // Set scale label
        label?: ByzzerChartText;
        // Convert text on scale indices
        labels?: string[];
        visible?: boolean;
    },
    scaleY?: {
        // Set scale label
        label?: ByzzerChartText;
        // Convert text on scale indices
        labels?: string[];
    },
    plot?: {
        'value-box'?: boolean | object;
        // Animation docs here:
        // https://www.zingchart.com/docs/tutorials/styling/animation#effect
        animation?: {
            effect?: 'ANIMATION_EXPAND_BOTTOM'; // todo: create union type
            method?: 'ANIMATION_STRONG_EASE_OUT'; // todo: create union type
            sequence?: 'ANIMATION_BY_NODE'; // todo: create union type
            speed?: number;
        }
        // this allows us to gradually expand the type
        [key: string]: any;
    }
}

export type ByzzerChartSeriesValue = {
    values: number[];
    text?: string;
    [key: string]: any;
}

export type ByzzerChartData = {
    series: ByzzerChartSeriesValue[];
    [key: string]: any;
}

export type ByzzerChartProps = {
    type?: ByzzerChartType;
    config?: ByzzerChartConfig;
    data?: ByzzerChartData;
} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'byz-chart';

export function ByzzerChart({
                             className,
                             type = 'bar',
                             config,
                             data,
                             ...props
                         }: ByzzerChartProps) {

    const [chartData, setChartData] = useState<Record<string, any>>({
        globals: {
            fontColor: 'black' // todo: Make this a variable that's tied into our themeing
        },
        ...config,
        ...data,
        type,
        plotarea: {
            margin: 'dynamic',
            offsetY: '200px',
            offsetX: '200px'
        },
    });

    useEffect(() => {
        setChartData(merge({
            plotarea: {
                margin: 'dynamic',
            }
        }, {
            ...config,
            ...data,
            type
        }, {        
            gui: { // don't display the hamburger menu
                "context-menu": {
                    button: {
                        visible: false
                    },
                    empty: true,
                }
            },
        }))
    }, [type, config, data]);

     // TODO, make formats dynamic and/or accept sim studio config
    return <div className={classnames(baseClassName, className)} {...props}>
        <ZingChart width={'100%'} height={'100%'} data={chartData}
            // { // TODO, make formats dynamic and/or accept sim studio config
            //     ...chartData,
            //     // scaleY: {
            //     //     "visible": true,
            //     //     format: "$%v",
            //     //     negation: "currency",
            //     // },
            //     // plot: {
            //     //     'value-box': {
            //     //         text: "$%v",
            //     //         negation: "currency",
            //     //         'thousands-separator': ", "
            //     //     }
            //     // },
            // }
        />
    </div>
}

export default ByzzerChart;