import { TimePeriod } from "./TimePeriod";
import {TimePeriodRange} from "./TimePeriodRange";

export class TimePeriodRangeArray {

    /**
     * The type is currently unused internally, it exists so the format con stay consistent with TimePeriodRange's
     * and so external code can use it differentiate a range string from an range array string
     * @private
     */
    private readonly type: string;
    private readonly duration: number;
    private readonly repeat: number;
    public readonly ranges: string[] = [];

    constructor(value: string, endPeriod?: TimePeriod | number | string) {
        const [type, duration, repeat] = value.split(':');

        this.type = type;
        this.duration = Number(duration);
        this.repeat = Number(repeat);


        if(type === 'cl') {
            for (let i = 0; i < this.repeat; i++) {
                this.ranges.push(`cpe:${this.duration}:${i * this.duration}`)
            }
        } else if(endPeriod) {
            const endTp = new TimePeriod(endPeriod!);
            for (let i = 0; i < this.repeat; i++) {
                this.ranges.push(`cw:${this.duration}:${endTp.valueOf() - i * this.duration}`)
            }
        } else {
            // an end period is required if the range isn't relative to the latest available date
            throw new Error('Invalid end period');
        }
    }

    * [Symbol.iterator](): Generator<TimePeriodRange> {
        for (let range of this.ranges) {
            yield new TimePeriodRange(range);
        }
    }
}
