import { productSkuInfo } from '@/config/productSkuInfo.config';

const checkType = (filter) => {
    if(!filter){
        return false
    }
    if(typeof filter === 'string' || typeof filter === 'number'){
        return filter !== null;
    }
    else{
        return filter.length > 0
    }
}

const formatFilterKeys = (filter) => {
    if(!filter){
        return null
    }
    if(typeof filter === 'string' || typeof filter === 'number'){
        return [filter];
    }
    else{
        return filter
    }
}
/**
 * to filter report by business question and report type
 * @param {*} quest business question
 * @param {*} rType  report type
 */
export const filterReport = (quest = null, rType = null, section = null) => {
    let filteredReportSkus = productSkuInfo;
    quest = formatFilterKeys(quest);
    rType = formatFilterKeys(rType);
    section = formatFilterKeys(section);
    if (checkType(quest) || checkType(rType) || checkType(section)) {
        if (
            (checkType(quest)) &&
            (checkType(rType)) &&
            (checkType(section))
        ) {
            filteredReportSkus = FindSkusByBusinessQuestionIdAndReportTypeIdAndSection(quest, rType, section);
        } else if (checkType(quest) && checkType(rType)) {
            filteredReportSkus = FindSkusByBusinessQuestionIdAndReportTypeId(quest, rType);
        } else if (checkType(quest) && checkType(section)) {
            filteredReportSkus = FindSkusByBusinessQuestionIdAndSectionId(quest, section);
        } else if (checkType(rType) && checkType(section)) {
            filteredReportSkus = FindSkusByReportTypeIdAndSectionId(rType, section);
        } else if (checkType(quest)) {
            filteredReportSkus = FindSkusByBusinessQuestionId(quest);
        } else if (checkType(rType)) {
            filteredReportSkus = FindSkusByReportTypeId(rType);
        } else if (checkType(section)) {
            filteredReportSkus = FindSkusByReportSection(section);
        } else {
            return filteredReportSkus;
        }
        return filteredReportSkus;
    } else {
        return filteredReportSkus;
    }
};

/**
 * findimg skus for business question id
 * @param  id business question id
 */
export const FindSkusByBusinessQuestionId = (id) => {
    return productSkuInfo.filter((val) => {
        return val.businessQuestions.some((bId) => id.includes(bId));
    });
};
/**
 * findimg skus for report type id
 * @param  id report type
 */
export const FindSkusByReportTypeId = (id) => {
    return productSkuInfo.filter((val) => {
        return id?.length > 0 ? id.includes(val.type) : val.type === id
    });
};
/**
 * findimg skus for report section id
 * @param  id report section header
 */
export const FindSkusByReportSection = (id) => {
    return productSkuInfo.filter((val) => {
        return id?.length > 0 ? id.includes(val.section) : val.section === id
    });
};
/**
 * finding skus for business question and report type id's
 * @param  quest business question id
 * @param  type  report type id
 */
export const FindSkusByBusinessQuestionIdAndReportTypeId = (quest, type) => {
    if (quest === 0 && type === 0) {
        return productSkuInfo;
    }
    if (type?.length > 0 && quest === 0) {
        //report history filter
        let temp = [];
        Object.keys(type).forEach((key) => {
            temp = productSkuInfo.filter((val) => {
                return val.type === type[key].value;
            });
        });
        return temp;
    } else {
        return productSkuInfo.filter((val) => {
            if (quest.length > 0 && type.length > 0) {
                return val.businessQuestions.some((bId) => quest.includes(bId)) && type.includes(val.type);
            } else if (quest.length > 0) {
                return val.businessQuestions.some((bId) => quest.includes(bId));
            } else {
                return type.includes(val.type);
            }
        });
    }
};

export const FindSkusByBusinessQuestionIdAndSectionId = (quest, section) => {
    if (quest === 0 && section === 0) {
        return productSkuInfo;
    }
    return productSkuInfo.filter((val) => {
        if (quest.length > 0 && section.length > 0) {
            return val.businessQuestions.some((bId) => quest.includes(bId)) && section.includes(val.section);
        } else if (quest.length > 0) {
            return val.businessQuestions.some((bId) => quest.includes(bId));
        } else {
            return section.includes(val.section);
        }
    });
};

export const FindSkusByReportTypeIdAndSectionId = (rType, section) => {
    if (rType === 0 && section === 0) {
        return productSkuInfo;
    }
    return productSkuInfo.filter((val) => {
        if (rType.length > 0 && section.length > 0) {
            return rType.includes(val.type) && section.includes(val.section);
        } else if (rType.length > 0) {
            return rType.includes(val.type);
        } else {
            return section.includes(val.section);
        }
    });
};

/**
 * finding skus for business question , report section and report type id's
 * @param  quest business question id
 * @param  type  report type id
 * @param section report section header
 */
export const FindSkusByBusinessQuestionIdAndReportTypeIdAndSection = (quest, type, section) => {
    if (quest === 0 && type === 0 && section === 0) {
        return productSkuInfo;
    }
    return productSkuInfo.filter((val) => {
        if (quest.length > 0 && type.length > 0 && section.length > 0) {
            return (
                val.businessQuestions.some((bId) => quest.includes(bId)) &&
                type.includes(val.type) &&
                section.includes(val.section)
            );
        } else if (quest.length > 0 && type.length > 0) {
            return val.businessQuestions.some((bId) => quest.includes(bId)) && type.includes(val.type);
        } else if (type.length > 0 && section.length > 0) {
            return type.includes(val.type) && section.includes(val.section);
        } else if (quest.length > 0 && section.length > 0) {
            return val.businessQuestions.some((bId) => quest.includes(bId)) && section.includes(val.section);
        } else if (quest.length > 0) {
            return val.businessQuestions.some((bId) => quest.includes(bId));
        } else if (section.length > 0) {
            return section.includes(val.section);
        } else if (type.length > 0){
            return type.includes(val.type);
        } else if (type !== null){
            return val.type;
        }
    });
};