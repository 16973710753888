import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './TimePeriodFilters.scss';
import { FilterGroup, FilterSection } from "@/components/ConfigurationEditors/FilterGroup";
import { ByzzerBrandSearch } from "@/components/ByzzerBrandSearch";
import { ByzzerCategorySelect } from "@/components/ByzzerCategorySelect";
import { SelectorLabelInfo } from "@/components/SelectorLabelInfo";
import { CharacteristicCriteriaBuilder } from "@/components/CharacteristicCriteriaBuilder";
import { DefaultConfigWizardContextValue, DefaultConfigWizardContext, DefaultConfigWizardState } from "../../DefaultsConfigWizard/DefaultsConfigWizardContext";
import { useUser } from "@/contexts/UserContext";
import { useTenantApi } from "@/hooks";
import { ByzzerSelect, StepRef } from "@byzzer/ui-components";
import { TimePeriodPicker } from "@/components/TimePeriodPicker";
import { MonthEndingTimePeriod, TimePeriod, WeekEndingTimePeriod } from "@/types/ReportRun";
import { DefaultConfigLabels } from "@/pages/Onboarding/OnboardingSteps";

export type TimePeriodFiltersRef = {
    value?: DefaultConfigWizardContextValue['value'];
}

export type TimePeriodFiltersProps = {
    name?: string;
    onChange?: (e: ByzzerChangeEvent<TimePeriod>) => void;
    value?: TimePeriod;
    summary?: ReactNode;
    labelsAndTips?: DefaultConfigLabels;
 } & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export const TimePeriodFilters = forwardRef<TimePeriodFiltersRef, TimePeriodFiltersProps>((
    {
        className,
        name,
        value,
        onChange,
        labelsAndTips,
        summary = labelsAndTips?.time_period_header,
        ...props
    }, ref) => {

    const baseClassName = 'byz-time-period-filters';

    const { value: contextValue, onChange: onContextChange, state: contextState } = useContext(
        DefaultConfigWizardContext
    );

    const [timePeriod, setTimePeriod] = useState<TimePeriod>({
        type: 'relative',
    })

    const { company, features: userFeatures = {}, defaultRunConfig } = useUser();

    function handleChange(e: ByzzerChangeEvent<unknown>) {
        onContextChange?.(e.name as keyof typeof defaultRunConfig, e.value, e.data);
    }


    useEffect(() => {
        setTimePeriod(contextValue?.timePeriod ?? value ?? {
            type: 'relative',
        })
    }, [contextValue, value])
    
    return (
        <div className={classnames(baseClassName, className)} {...props}>
            {summary && <div className={`${baseClassName}__summary`}>{summary}</div>}
            <br/>
            <TimePeriodPicker name={'timePeriod'} value={timePeriod} onChange={handleChange} datatype={'rms'} sku={'57'} hideLatestDate={true} />
        </div>
    )
});

export default TimePeriodFilters;

TimePeriodFilters.displayName = 'TimePeriodFilters';