import './CustomCharacteristicReportViewer.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { openErrorModal } from '@/components/form/ByzzerModal';
import {useTenantApi} from '@/hooks/useTenantApi';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { useUser } from '@/contexts/UserContext';

export function CustomCharacteristicReportViewer() {
    const { getCustomCharReportLinkById } = useTenantApi();
    const baseClassName = 'custom-characteristic-report-viewer';
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [embedUrl, setEmbedUrl] = useState<string | undefined>('');
    const { customCharacteristicId } = useParams();
    const { customCharacteristics } = useUser();

    useEffect(() => {        
        if (canViewCustomCharReport()) {
            getProductReport(customCharacteristicId);
        } else {
            redirectToDashboard();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customCharacteristicId]);

    const canViewCustomCharReport = () => {
        return customCharacteristics.filter(item => Number(item.id) === Number(customCharacteristicId))?.length > 0
    }

    const redirectToDashboard = () => {
        navigate('/dashboard', { replace: true });
    }

    const getProductReport = async (customCharacteristicId: string | undefined) => {
        try {
            setLoading(true);
            setEmbedUrl('');
            const url = await getCustomCharReportLinkById(customCharacteristicId);
            //Sanity check.  Success message but empty url
            if (!url?.length) {
                await openErrorModal({
                    title: `Report Error`,
                    content: (
                        <>
                            <p>We were unable to generate the product list at this time.</p>
                            <p>Please try again later or contact customer support.</p>
                        </>
                    ),
                });
                navigate(-1);
                return;
            }
            setEmbedUrl(url);

            // Adding delay to reset loader to handle the gap of looker embed iframe render
            setTimeout(() => setLoading(false), 2500);
        } catch (err) {
            // @ts-ignore
            alert(err?.message);
            navigate(-1);
            setLoading(false);
        }
    };

    return (
        <div className={`${baseClassName}__page-container`}>
            <ByzzerMask show={loading} loading={loading} />
            {embedUrl && (
                <iframe
                    src={embedUrl}
                    className={`${baseClassName}__looker-report`}
                    title="Product View Embed"
                ></iframe>
            )}
        </div>
    );
}

export default CustomCharacteristicReportViewer;