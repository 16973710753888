import { ByzzerMultiSelect, ByzzerSearch, ByzzerSelect, ByzzerSwitch } from '@/components/form';
import React, { useState } from 'react';
import closeIcon from '@/images/icons/Niq-close.svg';
import ByzzerSearchableSelect from '@/components/form/ByzzerSearchableSelect';
import ByzzerToggle from '@/components/form/ByzzerToggle';
import ByzzerButton from '@/components/form/ByzzerButton';
import { CloseOutlined } from '@ant-design/icons';
import classnames from 'classnames';

const baseClassName = 'segment-selector';
const andOption = {
    display: 'And',
    value: 'and',
};
const orOption = {
    display: 'Or',
    value: 'or',
};

export function SegmentSelector({
    index,
    removable,
    includeToggle,
    char,
    onRemoveSelector,
    onCharacteristicChange,
    onConditionChange,
    onValueChange,
    onToggleChange,
    characteristicsForCategories,
    characteristicOptions,
    selectedCategories,
    variant = 'default'
}) {
    const [upcCode, setUpcCode] = useState('');
    // const handleUpcChange = (e) => {
    //     let search = e;
    //     if (search?.length > 12) {
    //         search = search.slice(search?.length - 12);
    //     } else {
    //         search = search.padStart(12, '0');
    //     }
    //     setUpcCode(search);
    //     onValueChange(index, [search])
    // };
    const unselectUPC = (index, value) => {
        handleUpcChange(index, value, false);
    };
    const handleUpcChange = async (index, value, status) => {
        let oldUPCs = char.value;
        if (status) {
            oldUPCs.push(value);
        } else {
            const i = oldUPCs.indexOf(value);
            if (i !== -1) {
                oldUPCs.splice(i, 1);
            }
        }
        onValueChange(index, JSON.parse(JSON.stringify(oldUPCs)));
    };

    return (
        <>
            {includeToggle && (
                <ByzzerToggle
                    className={`${baseClassName}__and-or`}
                    leftOption={andOption}
                    rightOption={orOption}
                    value={char.and}
                    onChange={onToggleChange}
                />
            )}
            <div
                className={classnames(baseClassName, {
                    [`${baseClassName}--removable`]: removable,
                })}
            >
                <ByzzerSelect
                    className={`${baseClassName}__characteristic`}
                    label="Characteristic"
                    options={characteristicOptions.sort((a, b) => a?.localeCompare(b, 'en', { sensitivity: 'base' }))}
                    placeholder={'Characteristic'}
                    onChange={(e) => onCharacteristicChange(index, e)}
                    value={char.characteristic}
                    variant={variant}
                />
                <ByzzerSelect
                    className={`${baseClassName}__operator`}
                    label="Condition"
                    options={['is', 'is not']}
                    placeholder={'is'}
                    onChange={(e) => onConditionChange(index, e)}
                    value={char.condition}
                    variant={variant}
                />
                {
                    char.characteristic !== 'UPC' ? (
                        <ByzzerMultiSelect
                            label="Value"
                            className={`${baseClassName}__value`}
                            options={char.characteristic !== '' ? char.valueOptions : []}
                            placeholder={'Value'}
                            onChange={(e) => onValueChange(index, e)}
                            value={char.value}
                            seperator={','}
                            disabled={char.characteristic === ''}
                            multiSelectWithCheckBox
                            selectedOptions={char.value}
                            variant={variant}
                        />
                    ) : (
                        <ByzzerSearchableSelect
                            label="Value"
                            className={`${baseClassName}__value`}
                            seperator={','}
                            enableToolTip={char.value.length > 0 ? true : false}
                            options={char.valueOptions?.length > 0 ? char.valueOptions : []}
                            placeholder={char.value.length > 0 ? char.value : 'Select UPC'}
                            searchPlaceholder={'Search for UPC'}
                            onChange={(e) => {
                                unselectUPC(index, e);
                            }}
                            categories={selectedCategories}
                            searchOnChange={(value, status) => handleUpcChange(index, value, status)}
                            searchValue={char.value.length > 0 ? char.value : ''}
                            searchType={'upcCodeMultiSelect'}
                            variant={variant}
                        />
                    )
                    // <ByzzerSearch
                    //     className="char-editor__use-char-section1-segment-component-selector-input"
                    //     placeholder={"Search for UPC"}
                    //     onChange={handleUpcChange}
                    //     value={char.value.length > 0 ? char.value : upcCode}
                    //     //brands={focusBrand[0]}
                    //     categories={selectedCategories}
                    //     type={'upcCode'}
                    // />
                }
                <ByzzerButton className={`${baseClassName}__remove`} onClick={() => onRemoveSelector(index, char)}>
                    <CloseOutlined />
                </ByzzerButton>
            </div>
        </>
    );
}
