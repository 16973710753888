import firebase from 'firebase/compat/app';
import axios, { AxiosRequestConfig } from 'axios';
import { ByzzerSelectOption } from '@byzzer/ui-components';
import { SimulatorDataset} from '@/types/SimulatorTypes';
import { PartnerDetails, ProductFilterOption, PersonaDetails, ProductWithMetaAndCredits } from '@/types/ApiTypes';
import { CompanyOption } from '@/types/InvitationTypes';

const http = axios.create({
    // @ts-ignore - todo - figure out why import.meta has type error
    baseURL: import.meta.env.REACT_APP_BASEURL,
});

export const useSharedApi = () => {
    async function getAllProducts(): Promise<ProductWithMetaAndCredits[]> {
        return httpGet('/products');
    }

    async function getAllPersonas(): Promise<PersonaDetails[]> { // todo- see if this can be moved to /me.  it's only needed for onboarding
        return httpGet('/reference_data/personas');
    }

    async function getAllMarkets(): Promise<MarketNode[]> {
        return httpGet(`/markets`);
    }

    async function getAllDatasets(): Promise<SimulatorDataset[]> {
        return httpGet('/datasets');
    }

    async function getAllDemographicOptions(): Promise<ByzzerSelectOption[]> {
        const demographics = await httpGet(`/reference_data/demographics`);
        return demographics.map((demographic) => ({
            value: demographic.code,
            display: demographic.label,
        }));
    }

    async function getAllFilterOptions(): Promise<ProductFilterOption[]> {
        return httpGet(`products/getAllProductFilters`);
    }

    async function getAllTimePeriodEndDates(): Promise<TimePeriodEndDate[]> {
        return httpGet('/reference_data/time_period_end_dates')
    }

    async function validateEmail(email) {
        return httpPost('/actions/validate_email', {
            email,
        });
    }

    async function signUp(data: {
        firstName: string;
        lastName: string;
        password: string;
        email: string;
        phone: string;
        companyId: number;
        companyName: string;
        companyType: number;
        acceptedTcs: boolean;
        // domain?: string;
        isRetailer: boolean;
        utm_campaign: string,
        utm_source: string,
        utm_medium: string,
        utm_content: string,
        utm_term: string,
        previous_page_url: string,
        page_url: string;
        marketingEmailConsent: boolean;
    }): Promise<{
        companyId: number; // nsCompanyId
        userId: number;
    }> {
        return httpPost('/actions/sign_up', data);
    }

    async function findCompaniesByEmail(email): Promise<CompanyOption[]> {
        return httpGet('/companies', {
            params: {
                email,
            },
        });
    }

    async function getPartnerships(): Promise<PartnerDetails[]> {
        return httpGet('/reference_data/partnerships');
    }

    return {
        getAllTimePeriodEndDates,
        getAllProducts,
        getAllMarkets,
        getAllDatasets,
        getAllDemographicOptions,
        getAllFilterOptions,
        validateEmail,
        signUp,
        findCompaniesByEmail,
        getAllPersonas,
        getPartnerships
    }

};
// ******************************************************************************************************************
// PRIVATE UTILITY FUNCTIONS DO NOT ADD ANY NEW CODE BELOW THIS POINT
// ******************************************************************************************************************

/**
 * A wrapper around axios.httpGet that adds our custom error handling
 * @param url
 * @param config
 * @returns {Promise<any>}
 */
async function httpGet(url: string, config?: AxiosRequestConfig) {
    try {
        const { data } = await http.get(url, config);
        return data;
    } catch (err) {
        handleError(err);
    }
}

/**
 * A wrapper around axios.httpPost that adds our custom error handling
 * @param url
 * @param body
 * @param config
 * @returns {Promise<any>}
 */
async function httpPost(url: string, body: any, config?: AxiosRequestConfig) {
    try {
        const { data } = await http.post(url, body, config);
        return data;
    } catch (err) {
        handleError(err);
    }
}

/**
 * A wrapper around axios.httpPost that adds our custom error handling and config
 * @param url
 * @param payload{any}
 * @param config{AxiosRequestConfig}
 * @returns {Promise<any>}
 */
async function httpPostConfig(url: string, payload: any, config?: AxiosRequestConfig) {
    try {
        const { data } = await http.post(url, { ...payload }, config);
        return data;
    } catch (err) {
        handleError(err);
    }
}

/**
 * A wrapper around axios.httpPatch that adds our custom error handling
 * @param url
 * @param body
 * @param config
 * @returns {Promise<any>}
 */
async function httpPatch(url: string, body: any, config?: AxiosRequestConfig) {
    try {
        const { data } = await http.patch(url, body, config);
        return data;
    } catch (err) {
        handleError(err);
    }
}

/**
 * A wrapper around axios.httpPatch that adds our custom error handling
 * @param url
 * @param body
 * @param config
 * @returns {Promise<any>}
 */
async function httpPut(url: string, body?: any, config?: AxiosRequestConfig) {
    try {
        const { data } = await http.put(url, body, config);
        return data;
    } catch (err) {
        handleError(err);
    }
}

/**
 * A wrapper around axios.delete that adds our custom error handling
 * @param url
 * @param options
 * @returns {Promise<any>}
 */
async function httpDelete(url, ...options) {
    try {
        const { data } = await http.delete(url, ...options);
        return data;
    } catch (err) {
        handleError(err);
    }
}

/**
 *
 * common error handle that should be used by all functions
 * @param err
 */
function handleError(err) {
    // todo: add common error handling and event triggering for connectivity issues
    if(err?.isAxiosError || err?.code === 'ERR_NETWORK') {
        throw {
            status: -1,
            code: 'network_error',
        };
    }

    if (err?.response) {
        const { status, data } = err.response;
        throw {
            status,
            code: data?.code,
            id: data?.id,
        };
    }

    return err;
}

