import React, { useEffect, forwardRef, ReactNode, HTMLAttributes, useRef, createContext } from 'react';
import useState from 'react-usestateref';
import classnames from 'classnames';
import './DemographicCriteriaBuilder.scss';
import {
    ByzzerSelectOption,
    ByzzerSelectOptionGroup,
    ByzzerChangeEvent,
    ByzzerButton
} from '@byzzer/ui-components';
import { DemographicCondition } from '@/types/ReportRun';
import { clone } from 'lodash';
import { DemographicConditionInput, DemographicOption} from './DemographicConditionInput';
import { WithUid } from '@/types/WithUid';
import { nanoid } from 'nanoid';


const BLANK_DEMOGRAPHIC_CONDITION: DemographicCondition = {
    demographic: '',
    condition: 'is',
    value: [],
    data: {}
};

type DemographicCriteria = DemographicCondition[];

type DemographicCriteriaContextValue = {
    demographics: DemographicOption[] | string[];
    selectedDemographics: string[];
};


export const DemographicCriteriaContext = createContext<DemographicCriteriaContextValue>({} as any);

export type DemographicCriteriaBuilderProps<T = any> = {
    value?: DemographicCriteria;
    actions?: ReactNode;
    label?: ReactNode;
    keyLabel?: ReactNode;
    keyPlaceholder?: string,
    keyOptions?: (ByzzerSelectOption | string | ByzzerSelectOptionGroup)[];
    // disabledKeys?: (ByzzerSelectOption | string | ByzzerSelectOptionGroup)[];
    onKeyChange?: (e: ByzzerChangeEvent<string | undefined | null>) => void; // JS Doc

    operationLabel?: ReactNode;
    operationPlaceholder?: string;
    operationOptions?: (ByzzerSelectOption | string | ByzzerSelectOptionGroup)[];    
    disabledOperations?: (ByzzerSelectOption | string | ByzzerSelectOptionGroup)[];
    onOperationChange?: (e: ByzzerChangeEvent<string>) => void;

    valueLabel?: ReactNode;
    valuePlaceholder?: string;
    valueOptions?: (ByzzerSelectOption | string | ByzzerSelectOptionGroup)[];
    disabledValues?: (ByzzerSelectOption | string | ByzzerSelectOptionGroup)[];
    maxAllowedValues?: number;
    onValueChange?: (e: ByzzerChangeEvent<string | string[]>) => void;

    onChange?: (e: ByzzerChangeEvent<DemographicCriteria>) => void;
    name?: string;
    search?: (searchText: string) => Promise<T[]>;
    converter?: (value: T) => ByzzerSelectOption;
    searchableFields?: string[];
    onlyRenderIf?: boolean;
    className?: string;
    showLabelsOnAllRows?: boolean;
    joinText?: 'and' | 'or' | string;
    maxConditions?: number;
    minConditions?: number;
    fieldsToRenderAsLargeList?: string[];
} & Partial<Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>>

const baseClassName = 'byz-demographic-criteria-builder';

export const DemographicCriteriaBuilder = forwardRef<{value: DemographicCriteria[]}, DemographicCriteriaBuilderProps>(({
    value,
    actions,
    showLabelsOnAllRows = false,
    joinText,
    label,
    keyLabel = 'Demographic',
    keyPlaceholder = 'Select from the list',
    keyOptions,
    // disabledKeys,
    onKeyChange,
    operationLabel = 'Condition',
    operationPlaceholder,
    operationOptions = ['is', 'is not'],
    disabledOperations,
    onOperationChange,
    valueLabel = 'Segment',
    valuePlaceholder,
    valueOptions,
    disabledValues,
    maxAllowedValues,
    onValueChange,
    onChange,
    search,
    converter,
    onlyRenderIf,
    name = 'DemographicCriteriaBuilder',
    searchableFields,
    className,
    maxConditions,
    minConditions = 1, // added default value so 1 row is always available
    fieldsToRenderAsLargeList,
    ...props
}, ref) => {
    if (onlyRenderIf === false) return <></>; // needs to stay at top of component to successfully execute useeffect hook on 'value' below.

    const [conditions, setConditions] = useState<WithUid<DemographicCondition, string>[]>([]);
    const disabledKeys = useRef<string[]>([]);
    const initialValueLoaded = useRef<Boolean>(false);
    
    useEffect(() => { 
        // TODO - create logic to map given value to conditions.  probably only needed when defaults are given or rendering for first time
        // only do this on load for given value list.  otherwise, changes will be handled in onchange to prevent rebuidling list every time.

        if (!initialValueLoaded.current ) {
            if (value?.[0]?.demographic?.length) { 
                // some preset value(s) to load, either from a prior report run, user defaults, or coming back to this after unmounting
                // console.log("(SHOULD I FIRE? LOADING DEFAULT OR RE-MOUNTING...?) DemographicCriteriaBuilder - value ===>> ", value)
                const conditionsWithUids = value?.map((condition) => ({ 
                    value: condition, 
                    uid: nanoid() 
                }));
                setConditions(conditionsWithUids);
            } else { // after mounting and after clicking radio button and no prior value to load, create a new default condition
                // console.log("(I THINK I SHOULD FIRE TO START THIS THING OFF?!) DemographicCriteriaBuilder - value ===>> ", value)
                setConditions([createDefaultCondition()]);
            }
            initialValueLoaded.current = true; // finished initial load script, set to true to prevent above from reoccuring
        }
    }, [value]);

    useEffect(() => {
        disabledKeys.current = conditions?.map((condition) => condition.value.demographic ?? '');
    }, [conditions]);

    function updateStates(
        conditionsWithUids: WithUid<DemographicCondition, string>[], 
        onChange?: (e: ByzzerChangeEvent<DemographicCriteria>) => void
    ) {
        if (onChange) { // onChange should fire together with setConditions
            onChange({
                name,
                value: conditionsWithUids.map((condition) => condition.value), // send only condition value without uid
            });
        }
        // triggers render of conditions, which kicks off updates in children conditions elements
        setConditions(conditionsWithUids); // setConditions should fire together with onChange
    }

    function handleChange(e: ByzzerChangeEvent<DemographicCondition>, index) {
        // console.log("(1) DemographicCriteriaBuilder - handleChange fired - 'e' ===>> ", e)
        const conditionsWithUids = conditions.map((condition, i) => {
            if (condition.uid === index) {
                return { value: e.value, uid: condition.uid }
            } else {
                return condition
            }
        });
        // since this is currently not 100% a controlled component, for performance/UX reasons, state update functions are combined as one function and should fire together and be treated as one until better solution is implemented
        updateStates(conditionsWithUids, onChange) 
    }

    function add() {
        setConditions((currentConditions) => [...currentConditions, createDefaultCondition()]);
        // https://stackoverflow.com/questions/39549424/how-to-create-unique-keys-for-react-elements
    }

    function remove(demographic: string) {
        const filteredConditions = conditions.filter((condition, i) => demographic !== condition.uid);
        updateStates(filteredConditions, onChange)
    }

    function createDefaultCondition() {
        return { 
            value: clone(BLANK_DEMOGRAPHIC_CONDITION), 
            uid: nanoid() 
        }
    }


    return (
        <div className={classnames(baseClassName, className)} {...props}>
                {conditions.map(({ value, uid }, index) => (
                    <React.Fragment key={uid}>
                        {joinText && index > 0 && (
                            <div className={classnames(`${baseClassName}__label-text`)}>{joinText.toUpperCase()}</div>
                        )}
                        <DemographicConditionInput
                            value={value}
                            includeLabels={showLabelsOnAllRows ? true : index === 0}
                            onChange={(e) => handleChange(e, uid)}
                            actions={
                                <>
                                    <ByzzerButton
                                        onClick={() => add()}
                                        disabled={(maxConditions ?? Infinity) <= conditions.length}
                                    >
                                        +
                                    </ByzzerButton>
                                    <ByzzerButton
                                        onClick={() => remove(uid)}
                                        disabled={minConditions >= conditions.length}
                                    >
                                        -
                                    </ByzzerButton>
                                </>
                            }
                            disabledKeys={disabledKeys.current}
                            searchableFields={searchableFields}
                            name={name}
                            renderLargeList={fieldsToRenderAsLargeList?.includes(value?.demographic)}
                        />
                    </React.Fragment>
                ))}
        </div>
    );
})

export default DemographicCriteriaBuilder;

DemographicCriteriaBuilder.displayName = 'DemographicCriteriaBuilder';
