import './DodSchedulePreview.scss'
import {DodSchedule, DodScheduleDeliveryDay} from "@/types/DodRun";
import classnames from "classnames";
import {useMemo} from "react";
import {TimePeriod} from "@/utils/timePeriod/TimePeriod";
import {useDodTimePeriodService} from "@/services/dodTimePeriod.service";
import {formatInTimeZone} from "date-fns-tz";
import {addDays} from "date-fns";

export interface DodSchedulePreviewProps {
    schedule: DodSchedule;
    className?: string;
};

const baseClassName='dod-schedule-preview';

interface DeliveryDateSet {
    endDate: string;
    deliveryDate: string;
}

const deliveryDays: DodScheduleDeliveryDay[] = ['tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'monday']

export function DodSchedulePreview({ schedule, className }: DodSchedulePreviewProps) {

    const {
        futureQuarterlyOptions,
        futureWeeklyOptions,
        future444Options,
        future445Options,
        maxFutureWeeks
    } = useDodTimePeriodService();
    const rows = useMemo<DeliveryDateSet[]>(() => {

        const {duration = 1, frequency, timePeriods, startingTimePeriod, deliveryDay} = schedule;
        if(!frequency || (!startingTimePeriod && !timePeriods) || !deliveryDay) {
            return [];
        }
        let availablePeriods: string[] = [];
        switch(frequency) {
            case "one_time":
                availablePeriods = [futureWeeklyOptions.find(v => v.value === new TimePeriod(startingTimePeriod!).toString())?.value as string];
                break;
            case "weekly":
                availablePeriods = futureWeeklyOptions.map(v => v.value);
                break;
            case "quarterly":
                availablePeriods = futureQuarterlyOptions.map(v => v.value);
                break;
            case "monthly_444":
                availablePeriods = future444Options.map(v => v.value);
                break;
            case "monthly_445":
                availablePeriods = future445Options.map(v => v.value);
                break;
        }

        const deliveryOffset = deliveryDays.indexOf(deliveryDay) + 10;
        let deliveryTimePeriods: TimePeriod[];
        if(timePeriods) {
            deliveryTimePeriods = timePeriods.sort().map(v => new TimePeriod(v));
        } else {
            const firstTimePeriod = new TimePeriod(startingTimePeriod!);
            const startIndex = availablePeriods.findIndex(v => v === firstTimePeriod.toString());
            if(startIndex === -1){
                deliveryTimePeriods = []
            }else {
                const endIndex = startIndex + duration;
                deliveryTimePeriods = availablePeriods.slice(startIndex, endIndex).map(v => new TimePeriod(v))
            }
        }

        return deliveryTimePeriods.map(tp => ({
            endDate: formatInTimeZone(tp.toGmtDate(), 'GMT', 'MM-dd-yyyy'),
            deliveryDate: formatInTimeZone(addDays(tp.toGmtDate(), deliveryOffset), 'GMT', 'MM-dd-yyyy'),
        }))

    }, [schedule])

    return (
        <div className={classnames(baseClassName, className)}>
            <table className={`${baseClassName}__preview-table`}>
                <tbody>
                    <tr>
                        <th>
                            <div className={`${baseClassName}__preview-table-header-content`}>Week Ending</div>
                        </th>
                        <th>
                            <div className={`${baseClassName}__preview-table-header-content`}>Deliver On</div>
                        </th>
                    </tr>
                    {rows?.map(row => (
                        <tr>
                            <td>{row.endDate}</td>
                            <td>{row.deliveryDate}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
