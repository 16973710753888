import * as Guards from './guards';
import PendingSubscription from '@/views/onboarding/PendingSubscription';
import OfferViewer from '@/pages/Offer/OfferViewer';
import DownloadableProducts from '@/views/MyReports/DownloadableProducts';
import GetApproved from '@/views/GetApproved/GetApproved';
import UserProfileAlerts from '@/components/UserProfileAlerts/UserProfileAlerts';
import ScorecardViewer from '@/pages/ReportViewer/ScorecardViewer';
import BuildStory from '@/pages/StoryViewer/BuildStory';
import MyReportsPage from '@/pages/MyReportsPage';
import {MyAlertsPage} from '@/pages/MyAlertsPage';
import {AvailableAlerts, MyConfiguredAlerts, WeeklyAlerts} from '@/views/MyAlerts';
import News from '@/views/News';
import Dashboard from '@/pages/Dashboard';
import Onboarding from '@/pages/Onboarding';
import ReportViewer from '@/pages/ReportViewer/ReportViewer';
import Logout from '@/views/Logout';
import SubscriptionReport from '@/views/MyReports/SubscriptionReports';
import AdHocReports from '@/views/MyReports/AdHocReports';
import ReportHistory from '@/views/MyReports/ReportHistory';
import ProductCodingRequest from '@/views/ProductCodingRequest/ProductCodingRequest';
import PriorApprovedForm from '@/views/PriorApprovedForm/PriorApprovedForm';
import MySubscription from '@/views/MySubscription';
import CategoryEditor from '@/views/CategoryEditor';
import UserProfile from '@/views/UserProfile';
import BusinessDrivers from '@/views/BusinessDrivers';
import StoriesPage from '@/pages/StoryViewer/StoriesPage';
import MyScorecardPage from '@/pages/MyScorecardPage';
import CustomCharacteristics from '@/views/CustomCharacteristics/CustomCharacteristics';
import MyPortfolio from '@/views/MyPortfolio/MyPortfolio';
import MySubscriptionDetails from '@/components/MySubscriptionDetails';
import TeamManager from '@/components/TeamManager/TeamManager';
import UserPreferences from '@/components/UserPreferences';
import UserPersonalInfo from '@/components/UserPersonalInfo';
import UserPassword from '@/components/UserPassword';
import OverallScorecard from '@/views/scorecard/OverallScorecardTrend';
import FunctionInsightScores from '@/views/scorecard/FunctionInsightScores';
import AssessLevers from '@/views/scorecard/AssessLevers';
import DownloadReportPage from '@/pages/DownloadReportPage';
import ForgotPassword from '@/views/ForgotPassword';
import StoriesLibrary from '@/views/story/library/StoriesLibrary';
import StoriesHistoryPage from '@/views/story/history/StoriesHistoryPage';
import ProductCodingRequestSuccess from '@/views/ProductCodingRequest/ProductCodingRequestSuccess';
import React, {Children, forwardRef, ReactElement, useImperativeHandle} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {Authentication} from '@/pages/Authentication';
import {CSRSignIn, OBOWarning, InvitationSignUp, ReferralSignUp, SignIn, SignUp} from '@/views/auth';
import {PPGs} from '@/views/PPG/PPGs';
import {
    ChoosePackage,
    OnboardingAlerts,
    OnboardingCategories,
    OnboardingInvitations,
    OnboardingReportDefaults,
} from '@/views/onboarding';
import {ExtractHistory} from '@/views/Extract/ExtractHistory/ExtractHistory';
import {AppContextValue, useUser} from '@/contexts/UserContext';
import {ProductCodingInfo} from '@/views/ProductCodingRequest/NewProductCodingForm';
import {ProductLookup} from '@/components/ProductLookup';
import {StaticReportViewer} from '@/components/StaticReportViewer';
import {AodHierarchyLookup} from '@/components/AodHierarchyLookup';
import {CustomCharacteristicReportViewer} from '@/views/CustomCharacteristics/CustomCharacteristicReportViewer';
import {PPGReportViewer} from '@/views/PPG/PPGReportViewer';
import {GetVerified} from "@/views/GeVerified/GetVerified";
import {ReportRunConfigEditor} from "@/pages/ReportRunConfigEditor";
import {AlertRunConfigEditor} from "@/pages/AlertRunConfigEditor";
import {ReportRunOverlayConfigEditor} from "@/pages/ReportRunOverlayConfigEditor";
import MarketMaps from '@/views/MarketMaps/MarketMaps';
import {StoryRunConfigEditor} from '@/pages/StoryRunConfigEditor/StoryRunConfigEditor';
import MarketMapsContent from '@/components/MarketMaps/MarketMapsContent';
import DODWrapper from '@/views/DataOnDemand/Overall/DODWrapper';
import {DodRunConfigEditor} from "@/pages/DodRunConfigEditor";
import BrandInfo from '@/components/BrandInfo/BrandInfo';
import {SubscriptionSimulators} from '@/views/simulators/SubscriptionSimulators';
import {SimulatorConfigEditor} from '@/pages/SimulatorConfigEditor';
import {MySimulators} from '@/pages/MySimulators';
import {SavedScenarios} from '@/views/simulators';
import HomePageConfigEditor from '@/pages/HomePageConfigEditor/HomePageConfigEditor';
import {ResetPassword} from '@/pages/ResetPassword';
import {LegalTermsAndConditions} from '@/pages/LegalTermsAndConditions';
import {TenantPicker} from "@/views/auth/TenantPicker";
import { SwitchCompany } from '@/pages/SwitchCompany';
import firebase from 'firebase/compat/app';
import { UserDefaultsAndPreferences } from '@/components/UserDefaultsAndPreferences';
import { Opportunities } from '@/views/scorecard/Opportunities';
import DoDHistoryPage from '@/pages/DoDHistoryPage/DoDHistoryPage';
import DODTemplates from '@/views/DataOnDemand/History/DODTemplates';
import DodHistory from '@/views/DataOnDemand/History/DodHistory';
import DodSchedule from '@/views/DataOnDemand/History/DodSchedule';
import { QuotePage, QuoteContent } from '@/pages/Quote';
import { QuoteResponseType } from '@/types/QuoteTypes';
import { TenantUser } from '@/types/ApiTypes';
import {OnboardingPage} from '@/pages/Onboarding/OnboardingPage';
import { Partnerships } from '@/pages/Partnerships';
// todo: move this to build variable so it can vary by environment
const DEFAULT_PAGE_TITLE = 'Byzzer';

type ByzzerRouteMeta = {
    requireAuth?: boolean;
    requireTenant?: boolean;
    [key: string]: any
};

type Guard = (args: GuardArgs) => string | void;

type ElementRoute = {
    path: string;
    element: JSX.Element;
    alias?: string;
    aliases?: string[];
    guard?: Guard;
    children?: ByzzerRoute[]
    default?: true;
    meta?: ByzzerRouteMeta;
    title?: string;
    redirect?: never;
}

type RedirectRoute = {
    path: string;
    redirect: string;
    element?: never;
    guard?: Guard;
}

type RouteGuardArgs = {
    guard?: Guard;
    element: ReactElement;
    [key: string]: any;
}

type ByzzerRoute = ElementRoute | RedirectRoute;
// type ByzzerRoute<T extends 'redirect' | 'element' = 'element'> = T extends 'element' ? ElementRoute : RedirectRoute;

export type GuardArgs = {
    meta: ByzzerRouteMeta;
    user: AppContextValue['user'];
    subscription?: AppContextValue['subscription'];
    /**
     * @deprecated: do not reference any netsuite values
     */
    nsCompany?: AppContextValue['nsCompany']; // shouldnt be used
    categories?: AppContextValue['categories'];
    userQuote?: AppContextValue['userQuote'];
    unacceptedCompanyQuoteId?: number;
    company?: AppContextValue['company'];
    isMultiTenant?: AppContextValue['isMultiTenant'];
    tenantId?: AppContextValue['tenantId'];
    preSales?: AppContextValue['preSales'];
    loadingTenantData?: AppContextValue['loadingTenantData'];
}
type RedirectToSignInArgs = Pick<GuardArgs, 'user' | 'subscription' | 'company'> & {
    hasFirebaseUser?: boolean;
    subscriptionPastGracePeriod?: boolean;
    meta?: Record<string, string | boolean | number>;
}

export type RedirectToOnboardingArgs = Pick<GuardArgs, 'user' | 'company' | 'nsCompany' | 'categories' | 'preSales' | 'isMultiTenant' | 'tenantId' | 'loadingTenantData' | 'subscription'> & {
    subscriptionPastGracePeriod?: boolean;
}

export type RedirectExpiredUserArgs = Pick<GuardArgs, 'user' | 'userQuote' | 'subscription' | 'tenantId'>

export const routes: ByzzerRoute[] = [
    {
        path: '/auth',
        // @ts-ignore
        element: <Authentication />,
        guard: Guards.authentication,
        meta: { requireAuth: false },
        children: [
            { path: 'sign_up', element: <SignUp />, default: true },// @ts-ignore
            { path: 'sign_in', element: <SignIn /> },
            { path: 'csr_sign_in', element: <CSRSignIn /> },// @ts-ignore
            { path: 'forgot_password', element: <ForgotPassword /> },
            { path: 'sign_up_by_invitation', element: <InvitationSignUp /> },
            { path: 'invitation/:invitationId', element: <InvitationSignUp /> },
            { path: 'choose_company', element: <TenantPicker /> },
        ],
    },
    // @ts-ignore
    { path: 'referral/:referralId', element: <ReferralSignUp /> },
    // @ts-ignore
    { path: 'referral/:referralId/accept', element: <ReferralSignUp /> },
    // @ts-ignore
    { path: 'referral/:referralId/offer', element: <ReferralSignUp /> },
    // @ts-ignore
    { path: '/offer/:offerId', guard: Guards.offerViewer, element: <OfferViewer /> },
    { path: '/verify_email', guard: Guards.verifyEmail, element: <GetVerified /> },
    // @ts-ignore
    { path: '/approval_pending', guard: Guards.getApproved, element: <GetApproved /> },
    // @ts-ignore
    { path: '/choose_package', guard: Guards.choosePackage, element: <ChoosePackage /> },
    // @ts-ignore
    { path: '/subscription_pending', guard: Guards.subscriptionPending, element: <PendingSubscription /> },
    {
        path: 'quote',
        element: <QuotePage />,
        guard: Guards.quoteGuard,
    },
    { path: '/onboarding', element: <OnboardingPage />, aliases: ['new-onboarding'] },
    { path: '/report/:reportId', element: <ReportViewer />, guard: Guards.viewReport },
    { path: '/download-report', element: <DownloadReportPage />, guard: Guards.viewReport },
    { path: '/scorecard/:scorecardId', element: <ScorecardViewer />, guard: Guards.viewReport },
    { path: '/reset_password', element: <ResetPassword /> , guard: Guards.requirePasswordReset },
    {
        path: '/dashboard',
        element: <Dashboard />,
        guard: Guards.dashboard,
        title: 'Byzzer Dashboard',
        default: true,
        children: [
            {
                path: 'my_simulators',
                element: <MySimulators />,
                children: [
                    { path: 'subscription_simulators', element: <SubscriptionSimulators />, title: 'My Simulators', default: true },
                    { path: 'saved_scenarios', element: <SavedScenarios />, title: 'Saved Scenarios' }
                ]
            },
            // @ts-ignore
            { path: 'configure_simulator', title: 'Simulate', element: <SimulatorConfigEditor />, aliases: [] },
            {
                path: 'home',
                element: <HomePageConfigEditor />,
                title: 'Homepage',
                alias: 'dashboard_home',
                default: true,
            },
            // {
            //     path: 'extracts/:tab',
            //     element: <ExtractHistory />,
            //     title: 'Data On Demand - History',
            //     aliases: ['data_on_demand'],
            // },
            {
                path: 'extracts',
                element: <DoDHistoryPage />,
                title: "Data On Demand",
                aliases: ['data_on_demand'],
                children: [
                    // @ts-ignore
                    { path: 'history', title: 'Data On Demand - History', element: <DodHistory/>, default: true },
                    { path: 'scheduled', title: 'Data On Demand - Scheduled', element: <DodSchedule/> },
                    { path: 'templates', title: 'Data On Demand - Templates', element: <DODTemplates/> }
                ],
            },
            {
                path: 'extract_editor',
                element: <DodRunConfigEditor />,
                title: 'Data On Demand - Create',
                aliases: ['data_on_demand/data-selector'],
            },
            {
                path: 'extract_editor/:runId',
                element: <DodRunConfigEditor />,
                title: 'Data On Demand - Edit',
                aliases: ['data_on_demand/data-selector/:id'],
            },
            { path: 'configure_alert', element: <AlertRunConfigEditor />, guard: Guards.configureAlert, aliases: ['alert-run-selector'] },
            { path: 'configure_report', element: <ReportRunConfigEditor />, aliases: ['report-run-selector'] },
            // @ts-ignore
            { path: 'configure_reports', element: <ReportRunOverlayConfigEditor /> },
            { path: 'story', element: <BuildStory /> },
            {
                path: 'my_reports',
                element: <MyReportsPage />,
                children: [
                    {
                        path: 'subscription',
                        title: 'Subscription Reports',
                        element: <SubscriptionReport />,
                        default: true,
                    },
                    {
                        path: 'views/:id',
                        title: 'View Subscription Reports',
                        element: <SubscriptionReport />,
                    },
                    { path: 'ad_hoc', title: 'Adhoc Reports', element: <AdHocReports /> },
                    // @ts-ignore
                    { path: 'downloads', element: <DownloadableProducts /> },
                    { path: 'history', title: 'Report History', element: <ReportHistory /> },
                    { path: 'download-report', element: <DownloadReportPage /> },
                ],
            },
            { path: 'news', element: <News /> },
            {
                path: 'product_coding_request',
                aliases: ['product-coding-request'],
                element: <ProductCodingRequest />,
                title: 'Product Coding Form',
                children: [
                    // @ts-ignore
                    { path: '', element: <ProductCodingInfo /> },
                    // @ts-ignore
                    { path: 'success', element: <ProductCodingRequestSuccess /> },
                ],
            },
            {
                path: 'product_lookup',
                element: <ProductLookup />,
                title: 'Product Lookup',
                guard: Guards.subscriptionInactiveCheck,
            },
            {
                path: '/dashboard/static_report/:reportName',
                element: <StaticReportViewer />,
                title: 'Static Report Viewer',
                guard: Guards.subscriptionInactiveCheck,
            },
            {
                path: '/dashboard/custom_characteristics_report/:customCharacteristicId',
                element: <CustomCharacteristicReportViewer />,
                title: 'Custom Characteristic Report Viewer',
                guard: Guards.subscriptionInactiveCheck,
            },
            {
                path: '/dashboard/ppg_report/:ppgId',
                element: <PPGReportViewer />,
                title: 'PPG Report Viewer',
                guard: Guards.subscriptionInactiveCheck,
            },
            {
                path: 'my_items/aod_hierarchy_lookup',
                element: <AodHierarchyLookup />,
                title: 'AOD Hierarchy Lookup',
                guard: Guards.subscriptionInactiveCheck,
            }, //guard: Guards.demoEnvCheck, },
            {
                path: 'my_portfolio',
                element: <MyPortfolio />,
                title: 'Portfolio',
                guard: Guards.subscriptionInactiveCheck,
            },
            {
                path: 'ppgs',
                element: <PPGs />,
                title: 'PPGs',
                aliases: ['promoted-product-groups'],
                guard: Guards.subscriptionInactiveCheck,
            },
            {
                path: 'custom_characteristics',
                element: <CustomCharacteristics />,
                title: 'Custom Characteristics',
                aliases: ['custom-characteristics'],
                guard: Guards.subscriptionInactiveCheck,
            },
            { path: 'news', element: <News /> },
            {
                path: 'my_alerts',
                element: <MyAlertsPage />,
                title: "Alerts",
                children: [
                    // @ts-ignore
                    { path: 'weekly', title: 'Weekly Alerts', element: <WeeklyAlerts/>, default: true },
                    { path: 'configured', title: 'My Configured Alerts', element: <MyConfiguredAlerts/> },
                    { path: 'available', title: 'Available Alerts', element: <AvailableAlerts/> },

                    // { path: 'alerts/edit', element: <UserProfileAlerts />, title: "Alert Configuration" },
                    // { path: 'alerts/edit/:id', element: <UserProfileAlerts />, title: "Alert Configuration" },
                    // { path: 'alerts', element: <AlertConfiguration />, title: "Alert Configuration" },
                ],
            },
            {
                path: 'my_scorecard',
                element: <MyScorecardPage />,
                guard: Guards.subscriptionInactiveCheck,
                children: [
                    {
                        title: 'Overall Scores',
                        path: 'overall_scorecard',
                        // @ts-ignore
                        element: <OverallScorecard />,
                        default: true,
                    },
                    {
                        title: 'Function Insights',
                        path: 'identify_opportunity',
                        // @ts-ignore
                        element: <FunctionInsightScores />,
                    },
                    {
                        title: 'Scorecards',
                        path: 'assess_levers',
                        // @ts-ignore
                        element: <AssessLevers />,
                    },
                    {
                        title: 'Scorecards',
                        path: 'assess_levers/:lever',
                        // @ts-ignore
                        element: <AssessLevers />,
                    },
                    {
                        title: 'Opportunities',
                        path: 'opportunities',
                        // @ts-ignore
                        element: <Opportunities />,
                    },
                ],
            },
            {
                path: 'stories',
                element: <StoriesPage />,
                title: 'My Stories',
                children: [
                    { path: '/dashboard/stories/library', element: <StoriesLibrary />, default: true },
                    { path: '/dashboard/stories/history', element: <StoriesHistoryPage /> },
                ],
            },
            {
                path: 'story_builder',
                element: <StoryRunConfigEditor />,
                title: 'Story Builder',
            },
            { path: 'drivers', element: <BusinessDrivers /> },
            { path: 'my_categories', element: <CategoryEditor />, title: 'Edit Categories' },
            { path: 'prior_approved', element: <PriorApprovedForm />, title: 'Prior Approval' },
            { path: 'partnerships', element: <Partnerships />, title: 'Partnerships' },
            {
                path: 'market_maps/:marketMap',
                element: <MarketMaps />,
                title: 'Market Maps',
                children: [
                    // @ts-ignore
                    { path: 'retailer', element: <MarketMapsContent />, default: true },
                    // @ts-ignore
                    { path: 'syndicated', element: <MarketMapsContent />, title: 'Syndicate' },
                ],
            },
            {
                path: 'switch_company',
                element: <SwitchCompany />,
                guard: Guards.switchCompany,
            },
            {
                path: 'my_subscription',
                element: <MySubscription />,
                title: 'Subscription',
                children: [
                    // @ts-ignore
                    { path: 'subscription', element: <MySubscriptionDetails />, default: true },
                    { path: 'members', element: <TeamManager />, title: 'Members' },
                    { path: 'brands', element: <BrandInfo />, title: 'Brand Info' },
                    {
                        path: 'quote', element: <QuoteContent />,
                        title: "Quote",
                        guard: Guards.quoteGuard,
                    }
                ],
            },
            {
                path: 'user_profile',
                // @ts-ignore
                element: <UserProfile />,
                title: 'Profile',
                children: [
                    // @ts-ignore
                    { path: 'alerts/edit', element: <UserProfileAlerts />, title: 'Alert Configuration' },
                    // @ts-ignore
                    { path: 'alerts/edit/:id', element: <UserProfileAlerts />, title: 'Alert Configuration' },
                    { path: 'preferences', element: <UserDefaultsAndPreferences />, default: true, title: 'Preferences' },
                    // { path: 'alerts', element: <AlertConfiguration />, title: 'Alert Configuration' },
                    { path: 'personal', element: <UserPersonalInfo />, title: 'Personal Info' },
                    // @ts-ignore
                    { path: 'password', element: <UserPassword />, title: 'Password' },
                ],
            },
        ],
    },
    { path: '/logout', element: <Logout /> },

    // redirects for old routes
    { path: '/csronbehalfof', redirect: '/auth/csr_sign_in' },
    { path: '/sign_in', redirect: '/auth/sign_in' },
    { path: 'terms_and_conditions', element: <LegalTermsAndConditions />, guard: Guards.termsAndConditions },
    { path: 'obo_warning', element: <OBOWarning /> },// @ts-ignore
];

// @ts-ignore
export const AppRoute = forwardRef(({ children, route, ...props }, ref) => {
    document.title = route.title;
    useImperativeHandle(ref, () => ({
        route,
        component: route.element.type,
    }));

    return Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, props);
        } else {
            return child;
        }
    });
});

export function toRoute(config, parent) {
    let { path, element, aliases = [], children, meta = {}, guard, redirect, ...options } = config;

    config.title = config.title ?? parent?.title ?? DEFAULT_PAGE_TITLE;

    const paths: any[] = [];

    if (redirect) {
        return [{ path: path, element: <Navigate to={redirect} replace /> }];
    }

    // if this a default path, make "*" and "" aliases
    if (options.default) {
        aliases.push('*', '');
    }

    // create a redirect for each alias
    // todo: make this work for non leaf paths
    aliases.forEach((alias) => paths.push({ path: alias, element: <Navigate to={path} replace /> }));

    if (guard || meta?.requireAuth) {
        element = <RouteGuard guard={guard} element={element} meta={meta} />;
    }

    // @ts-ignore
    element = <AppRoute route={config}>{element}</AppRoute>;

    return [...paths, { path, element, children: children?.map((child) => toRoute(child, config)).flat() }];
}


export function RouteGuard({ guard, element, meta = {}, ...props }: RouteGuardArgs) {
    const { user, subscription, nsCompany, categories, userQuote, company, tenantId, isMultiTenant, isCsrObo, preSales, loadingTenantData, csrOboAccessRoles } = useUser();
    const location = useLocation();
    const firebaseUser = firebase.auth().currentUser;
    const hasFirebaseUser = Boolean(firebaseUser);
    const subscriptionPastGracePeriod = Boolean(!subscription?.active && subscription?.pastExpirationGracePeriod && !adminUserHasNewQuote({userQuote, user})); // todo: Rename subscriptionPastGracePeriod to something better
    const isDemo = company?.features.isDemo;

    if (userShouldRedirectToSignIn({ user, subscription, company, hasFirebaseUser, subscriptionPastGracePeriod, meta }) && location.pathname !== '/auth/sign_in') {
        return <Navigate to={'/auth/sign_in'} replace/>;
    }

    if (
        !(user && isCsrObo) && 
        (
            (hasFirebaseUser && firebaseUser?.emailVerified === false && !(user && user?.emailVerified === true)) || 
            (isDemo && hasFirebaseUser && user?.emailVerified === false)
        ) && 
        location.pathname !== '/verify_email'
    ) {
        return <Navigate to={'/verify_email'} replace/>;
    }

    if (hasFirebaseUser && !user && !tenantId && isMultiTenant && location.pathname !== '/auth/choose_company') {
        return <Navigate to={'/auth/choose_company'} replace/>;
    }

    if (user?.requirePasswordReset && user?.emailVerified && location.pathname !== '/reset_password' && location.pathname !== '/terms_and_conditions' && location.pathname !== '/verify_email') {
        return <Navigate to={'/reset_password'} replace/>;
    }

    if (adminUserHasNewQuote({userQuote, user}) && location.pathname !== '/quote') {
        return <Navigate to={'/quote'} replace/>;
    }

    if (hasFirebaseUser && user?.tcsAccepted === false && !subscriptionPastGracePeriod && location.pathname !== '/terms_and_conditions'
        && !isLoggedInOBOCsrSuperUser({ user, isCsrObo, csrOboAccessRoles, location})) {
        if (isCsrObo) { // If OBO login, for csr super/non-super user
            return <Navigate to={'/obo_warning'} replace/>;
        } else {
            return <Navigate to={'/terms_and_conditions'} replace/>;
        }
    }

    const redirect = guard?.({ meta, user, subscription, nsCompany, categories, userQuote, company, isMultiTenant, preSales, tenantId, loadingTenantData });
    if (redirect) {
        return <Navigate to={redirect} replace />;
    }

    return React.cloneElement(element, props);
}

function userShouldRedirectToSignIn({ user, hasFirebaseUser, company, subscriptionPastGracePeriod, meta }: RedirectToSignInArgs) {
    const hasContextUser = Boolean(user);
    const accountDisabled = Boolean(company?.isDisabled === true);
    return meta?.requireAuth !== false && (!hasFirebaseUser || (hasContextUser && (subscriptionPastGracePeriod || accountDisabled)));
}

function isLoggedInOBOCsrSuperUser({ user, isCsrObo, csrOboAccessRoles, location }) { // Checks whether the user is already logged In OBO CSR Super user
    const isCsrSuperUser = Object.values<string>(csrOboAccessRoles).includes('superuser');
    const { oboAllowAccessWhenTcsPending } = useUser();
    return (oboAllowAccessWhenTcsPending && user && isCsrObo && isCsrSuperUser);
}

export function adminUserHasNewQuote({userQuote, user}: {userQuote?: QuoteResponseType; user?: TenantUser | null}): Boolean {
    return (
        user?.role === 'admin' &&
        userQuote?.status === 'new'
    );
}

export default routes.map(toRoute).flat();
