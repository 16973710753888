import './GetApproved.scss';
import classnames from 'classnames';
import { useUser } from "@/contexts/UserContext";
import {useTenantApi} from '@/hooks/useTenantApi';
import { useEffect, useState } from 'react';
import { useBetterNavigate } from "@/utils";
import { CustomerSupportLink } from "@/components/CustomerSupportLink";

const baseClassName = 'get-approved';

export default function GetApproved({ className, ...props }) {
    const { getAdmins } = useTenantApi();
    const [admins, setAdmins] = useState('');

    useEffect(() => {
        getAdminDetails();
    }, []);

    const getAdminDetails = async () => {
        const members = await getAdmins();
        setAdmins(members.map(item => `${item.firstName} ${item.lastName} (${item.email})`).join(';'));
    };

    return <div className={classnames(baseClassName, className)} {...props}>
        <div className={`${baseClassName}__logo`}>
            <div className={`${baseClassName}__logo-image`} />
            <div className={`${baseClassName}__logo-text`}>Byzzer</div>
        </div>
        <div className={`${baseClassName}__content`}>
            <h1 className={`${baseClassName}__title`}>
                Waiting For Approval
            </h1>
            <p>
                Your account needs to be approved by your company's admin.
            </p>
            {admins !== '' ? <>
                <p>
                    Admin - {admins}
                </p>
            </> : <></>}
            <p>If you need additional help please chat or contact support <CustomerSupportLink />.</p>
        </div>
    </div>
}