import {openErrorModal} from "@/components/form";
import React from "react";

export function showErrorModal(err) {
    return openErrorModal({
        title: `Something Unexpected Happened`,
        content: (
            <>
                <p>Fear not our engineering team is on the job.</p>
            </>
        ),
        errorId: err.id
    });
}
