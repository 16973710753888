import React, {useEffect} from 'react';
import useState from "react-usestateref";
import {Outlet} from 'react-router-dom';
import './MyAlertsPage.scss';
import DashboardContent from "@/components/dashboard/DashboardContent";
import { UserAlertSummary } from '@/types/AlertRun';
import {useTenantApi} from '@/hooks/useTenantApi';
import { useApp } from '@/contexts/UserContext';
import OutletWithProps from '@/components/OutletWithProps';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { AlertRun } from '@/types/ApiTypes';
import filterAlertsByStatus from '@/utils/filterAlertsByStatus';
import useIsMobile from '@/hooks/useIsMobile';

export type PageLinkType = 'weekly_alerts' | 'configured_alerts' | 'available_alerts';

export type PageLink = {
    text: string,
    url: string,
    type: PageLinkType
}

export type AlertLimits = {
    totalConfiguredAlerts: number;
    maxTotalAlerts: number;
    maxBasicAlerts: number;
    maxPremiumAlerts: number;
    canAddAlerts: boolean;
}

export type MyAlertsProps = {
    savedConfiguredAlerts: UserAlertSummary[];
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    getAndSetConfiguredAlerts: () => Promise<void>;
    alertLimits: AlertLimits;
    alertHistory: AlertRun[];
};

const hiddenFeaturesOnMobile: PageLinkType[] = ['configured_alerts', 'available_alerts']; // per BYZ-8812

const linksList: PageLink[] = [
    {
        text: 'Weekly Alerts',
        url: '/dashboard/my_alerts/weekly',
        type: 'weekly_alerts',
    },
    {
        text: 'Configured Alerts',
        type: 'configured_alerts',
        url: '/dashboard/my_alerts/configured',
    },
    {
        text: 'Available Alerts',
        url: '/dashboard/my_alerts/available',
        type: 'available_alerts',
    },
];

export function MyAlertsPage() {
    const { getConfiguredAlerts, getMyLegacySubscription, getUserAlertHistory } = useTenantApi();
    const isMobile = useIsMobile();

    const [links, setLinks] = useState<PageLink[]>([]);
    const [savedConfiguredAlerts, setSavedConfiguredAlerts] = useState<UserAlertSummary[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [alertUsageLimits, setAlertUsageLimits] = useState<AlertLimits>({
        totalConfiguredAlerts: 0,
        maxTotalAlerts: 0,
        maxBasicAlerts: 0,
        maxPremiumAlerts: 0,
        canAddAlerts: true
    })
    const [alertHistory, setAlertHistory] = useState<AlertRun[]>([]);
    const { subscribedSkus, configuredAlerts} = useApp();

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                await getAndSetConfiguredAlerts();
            } finally {
                setIsLoading(false);
            }
        })();
    }, [configuredAlerts]); // Dependency added to ensure alerts are updated when configuredAlerts changes

    async function getAndSetConfiguredAlerts() {
        const [
            { usage: { basicAlerts, premiumAlerts } },
            alertHistoryResponse
        ] = await Promise.all([
            getMyLegacySubscription(),
            getUserAlertHistory()
        ]);

        const filteredConfigureAlerts = configuredAlerts && filterAlertsByStatus(configuredAlerts);

        const subscribedConfiguredAlerts = filteredConfigureAlerts?.filter((configuredAlert) => subscribedSkus?.includes((configuredAlert?.alertProduct?.sku ?? configuredAlert?.productSku)!));

        const maxBasicAlerts = Number(basicAlerts?.limit ?? 0);
        const maxPremiumAlerts = Number(premiumAlerts?.limit ?? 0);
        const maxTotalAlerts = Number(maxBasicAlerts + maxPremiumAlerts);
        const totalConfiguredAlerts = Number(filteredConfigureAlerts?.length ?? 0);
        const canAddAlerts = Boolean(totalConfiguredAlerts < maxTotalAlerts) && Boolean(maxTotalAlerts > 0);

        setSavedConfiguredAlerts(subscribedConfiguredAlerts.sort((a, b) =>  b.id - a.id));
        setAlertUsageLimits({
            maxBasicAlerts,
            maxPremiumAlerts,
            maxTotalAlerts,
            totalConfiguredAlerts,
            canAddAlerts
        });
        if (alertHistoryResponse?.status === 'success') {
            setAlertHistory(alertHistoryResponse?.alerts)
        } else {
            setAlertHistory([])
        }
    }

    useEffect(() => {
        setLinks(linksList.filter(({ type }) => !isMobile || !hiddenFeaturesOnMobile.includes(type)));
    }, [linksList, isMobile, hiddenFeaturesOnMobile])

    return (
        //@ts-ignore
        <DashboardContent title={'My Alerts'} links={links} className={'my-alerts'}>
            <ByzzerMask show={isLoading} loading={isLoading}>Loading your Alerts...</ByzzerMask>
            {!isLoading && (
                <OutletWithProps // @ts-ignore
                    getAndSetConfiguredAlerts={getAndSetConfiguredAlerts} 
                    savedConfiguredAlerts={savedConfiguredAlerts} 
                    setIsLoading={setIsLoading} 
                    alertLimits={alertUsageLimits} 
                    alertHistory={alertHistory}
                />
            )}
        </DashboardContent>
    );
};

export default MyAlertsPage;

MyAlertsPage.displayName = 'MyAlertsPage';