import {ByzzerTextInput} from '@byzzer/ui-components';
import React, {ReactNode} from 'react';
import './DodSavedSelectionModal.scss';
import {DodFactSet, DodLayoutPreset} from '@/types/DodRun';
import {DodPreset, DodPresetSummary} from '@/types/ApiTypes';
import {ModalAction, openModal} from "@/components/form";
import {createDodPreset, updateDodPreset} from "@/api";

const baseClassName = 'dod-saved-selection-modal';

export interface CreateDodPresetParams {
    description: ReactNode,
    preset: Partial<DodPreset | DodPreset<DodFactSet[]> | DodPreset<DodLayoutPreset>>,
    currentPreset?: DodPresetSummary
    dodPresetsMap: DodPresetSummary[];
    title?: string;
    duplicateNameErrorTip?: string;
}

export async function openCreateDodPresetModal({
                                                   description,
                                                   preset,
                                                   currentPreset,
                                                   dodPresetsMap,
                                                   title = 'Save Your Selections',
                                                   duplicateNameErrorTip = 'There is already a Saved Selection with this name. Please rename your selection.'
                                               }: CreateDodPresetParams): Promise<DodPreset | undefined> {

    
    let action = 'create';
    if (currentPreset?.id === -1) return;

    const isDuplicatePresetName = (state: DodPreset) => {
        return dodPresetsMap
            ?.map((presetData) => presetData.displayName?.toLowerCase())
            .includes(state.displayName?.trim()?.toLowerCase());
    };
    if (currentPreset) {
        action = await openModal({
            title: 'Update Your Saved Selections',
            showCloseOption: false,
            content: <>
                <p>You made changes to your Saved Selection, <span
                    className={`${baseClassName}__preset-name`}>{currentPreset?.displayName}</span>.</p>
                <p>Do you want to update this Saved Selection or create a new one?</p>
            </>,
            actions: [{
                key: 'cancel',
                label: 'Cancel',
                type: 'negative',
                action({resolve}) {
                    resolve('cancel')
                }
            }, {
                key: 'create',
                label: 'Create New',
                action({resolve}) {
                    resolve('create')
                }
            }, {
                key: 'update',
                label: 'Update',
                action({resolve}) {
                    resolve('update')
                }
            },]
        });
    }

    switch (action) {
        case 'update':
            return updateDodPreset(currentPreset!.id, preset.values);
        case 'create':
            return openModal<Partial<DodPreset | DodPreset<DodFactSet[]>>>({
                className: baseClassName,
                title: title,
                initialState: preset,
                showCloseOption: false,
                content: ({close, state, setState}) => {

                    function handleDisplayNameChange(e: ByzzerChangeEvent<string>) {
                        setState(state => ({
                            ...state,
                            displayName: e.value
                        }))
                    }

                    return <>
                        {description}
                        <ByzzerTextInput name={'displayName'} placeholder={'Enter a title.'} value={state.displayName}
                                         onChange={handleDisplayNameChange}/>
                    </>
                },
                actions: [{
                    key: 'cancel',
                    type: 'negative',
                    label: 'Cancel',
                    action({resolve}) {
                        resolve();
                    }
                }, {
                    key: 'save',
                    label: 'Save',
                    getDisableTip({state}) {
                        if(!state.displayName){
                            return 'Please enter a title for your Saved Selection.'
                        }
                        if(isDuplicatePresetName(state)){
                            return duplicateNameErrorTip;
                        }
                        return "";
                    },
                    disableIf({state}) {
                        return !state.displayName || isDuplicatePresetName(state)
                    },
                    async action({resolve, setBusy, state}) {

                        try {
                            setBusy(true);
                            const preset = await createDodPreset({
                                displayName: state.displayName,
                                type: state.type,
                                values: state.values,
                            });
                            resolve(preset);
                        } finally {
                            setBusy(false)
                        }
                    }
                }] as ModalAction<DodPreset>[]
            });
    }
}

