import './DashboardContent2.scss';
import classnames from "classnames";
import {NavLink} from "react-router-dom";
import React, {ReactNode, useEffect, useState} from "react";
import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";

const baseClassName = 'dashboard-content2';

export type DashboardContent2Props = {
    title?: ReactNode;
    subTitle?: ReactNode,
    links?: {
        url: string;
        text: string;
        disabled?: boolean;
    }[],
    className?: string;
    extras?: ReactNode;
    exact?: boolean;
    replace?: boolean;
    children,
    loading?: boolean;
    staticContentBody?: boolean;
    rootContent?: ReactNode;
    numberTag?: ReactNode
}

export function DashboardContent2({
                                      title,
                                      subTitle,
                                      links,
                                      className,
                                      extras,
                                      exact,
                                      replace,
                                      children,
                                      loading,
                                      rootContent,
                                      staticContentBody = false,
                                      numberTag,
                                      ...props
                                  }: DashboardContent2Props) {

    const [requiresHeader, setRequiresHeader] = useState(true);

    useEffect(() => {
        setRequiresHeader(Boolean(title || subTitle || links?.length || extras));
    }, [title, subTitle, links, extras])

    useEffect(() => {

    }, [requiresHeader])

    function renderLinks() {
        if (links?.length) {
            return <div className={`${baseClassName}__links`}>
                {links.map(({url, text, disabled}) => {
                    if (disabled) {
                        return <div key={url}
                                    className={classnames(`${baseClassName}__link`, `${baseClassName}__link--disabled`)}>
                            <span className={`${baseClassName}__link-text`}>{text}</span>
                        </div>

                    } else {
                        return <NavLink
                            className={({isActive}) => classnames(
                                `${baseClassName}__link`,
                                {
                                    [`${baseClassName}__link--active`]: isActive,
                                    [`${baseClassName}__link--disabled`]: disabled
                                }
                            )}
                            replace={replace}
                            end={exact}
                            to={url}
                            key={url}
                        >
                            <span className={`${baseClassName}__link-text`}>{text}</span>
                        </NavLink>
                    }
                })}
            </div>
        }
    }

    return (
        <div className={classnames(baseClassName, className)}>
            <div className={classnames(`${baseClassName}-body`, {
                [`${baseClassName}-body--static`]: staticContentBody
            })} {...props}>
                {requiresHeader && (
                    <header className={`${baseClassName}__heading`}>
                        <div className={`${baseClassName}__titles`}>
                            <div className={`${baseClassName}__title-wrapper`}>
                                <div className={`${baseClassName}__title`}>{title}</div>
                                {numberTag}
                            </div>
                            {subTitle && <div className={`${baseClassName}__subtitle`}>{subTitle}</div>}
                        </div>
                        {renderLinks()}
                        {extras}
                    </header>
                )}
                <div className={classnames(`${baseClassName}__view`)}>
                    <ByzzerMask show={loading} loading={loading}/>
                    {children}
                </div>
            </div>
            {rootContent}
        </div>
    );
}

export default DashboardContent2;
DashboardContent2.displayName = 'DashboardContent2';
