import './DodProductFiltersStep.scss';
import React, {ReactNode, useMemo, useState} from 'react';
import classnames from 'classnames';
import {StepRef, WizardContent, WizardStep} from '@byzzer/ui-components';
import {DodTimePeriodFilterBuilder, DodTimePeriodFilterBuilderProps} from '@/components/DodConfigEditor/builders';
import {DodWizardActions} from '../DodWizardActions';
import { DodResourceLink } from '../../common/DodResourceLink';

export type DodTimePeriodFiltersStepProps = {
    title?: ReactNode;
    enabled?: boolean;
    exclude?: boolean;
} & DodTimePeriodFilterBuilderProps;

const baseClassName = 'dod-run-config-filters-step';

const tip = <>
    <p>Select which time periods to include in your run.</p>
    <p>Syndicated Time Periods will provide common selections, such as Latest X Weeks or set calendar time frames like months.</p>
    <p>Use the Sum Selections button to create custom time period aggregations.</p>
    <DodResourceLink />
</>

export const DodTimePeriodFiltersStep = React.forwardRef<StepRef, DodTimePeriodFiltersStepProps>(
    ({ className, enabled, title = 'Time Periods', exclude, onValidityChange, ...props }, ref) => {

        const [filtersValid, setFiltersValid] = useState<boolean>(false);
        const count = useMemo<string>(() => {
            const {values, summedSelections} = props.value.timePeriods;
            const count =  values.length + summedSelections.length;
            return count ? `(${count})` : '';
        }, [props.value]);

        function handleValidationChange(e: ByzzerValidityChangeEvent) {
            setFiltersValid(e.isValid);
            onValidityChange?.(e);
        }

        return (
            <WizardStep className={classnames(baseClassName, className)} ref={ref} enabled={enabled}
                        id={'timePeriods'}
                        exclude={exclude}
                        title={`${title} ${count}`}>
                <WizardContent>
                    <DodTimePeriodFilterBuilder {...props} tip={tip} onValidityChange={handleValidationChange} />
                </WizardContent>
                <DodWizardActions
                    disableNext={!filtersValid}
                    helpTip={tip}
                    nextDisabledTip={'Please choose at least one time period to continue.'}
                />
            </WizardStep>
        );
    }
);
