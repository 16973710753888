import React from "react";

function eachCharGenerator(char) {
    let eachCharResult = [];
    char.forEach((item, index) => {
        let result = '';
        if (item.characteristic !== '')
            result = result + item.characteristic + ' ' + item.condition + ' ';
        else
            result = result + '{Select a characteristic} ' + item.condition + ' ';

        if (item.value.length === 1) {
            result = result + item.value
        }
        else if (item.value.length > 1) {
            result = result + '('
            item.value.forEach((element, index) => {
                if (index !== item.value.length - 1) {
                    result = result + element + ' or ';
                }
                else {
                    result = result + element + ')';
                }
            })
        }
        else {
            result = result + '{Select a value}';
        }

        if (item?.and === 'and' || item?.and === 'or') {
            eachCharResult.push({ 'result': result, 'combination': item.and })
        }
        else if (item.and === undefined) {
            eachCharResult.push({ 'result': result, 'combination': 'none' })
        }
    })
    return eachCharResult;
}

function previewGenerator(results) {
    if (results.length === 1) {
        return results[0].result
    }
    else {
        let orStatement = [];
        let andStatement = '';
        results.forEach((result, index) => {
            if (index !== results.length - 1) {
                if (result.combination === 'none') {
                    if (results[index + 1].combination === 'or') {
                        andStatement = result.result;
                        orStatement.push({ result: andStatement });
                        andStatement = '';
                    }
                    else if (results[index + 1].combination === 'and') {
                        andStatement = '(' + result.result;
                    }
                }
                else if (result.combination === 'and') {
                    if (results[index + 1].combination === 'and') {
                        andStatement = andStatement + ' AND ' + result.result;
                    }
                    else if (results[index + 1].combination === 'or') {
                        andStatement = andStatement + ' AND ' + result.result + ')';
                        orStatement.push({ result: andStatement });
                        andStatement = '';
                    }
                }
                else if (result.combination === 'or') {
                    if (results[index + 1].combination === 'and') {
                        andStatement = '(' + result.result;
                    }
                    else if (results[index + 1].combination === 'or') {
                        andStatement = result.result;
                        orStatement.push({ result: andStatement });
                        andStatement = '';
                    }
                }

            }
            else {
                if (result.combination === 'or') {
                    andStatement = result.result;
                }
                else if (result.combination === 'and') {
                    andStatement = andStatement + ' AND ' + result.result + ')';
                }
                orStatement.push({ result: andStatement });
            }
        })
        return orStatement;
    }

}
export function SegmentPreview({ char }) {
    let isSingle = true;
    let singlePreview;
    let multiPreview;
    const results = eachCharGenerator(char);
    const preview = previewGenerator(results)
    if (Array.isArray(preview)) {
        multiPreview = preview;
        isSingle = false;
    }
    else {
        singlePreview = preview;
        isSingle = true;
    }

    return <>
        {isSingle ?
            <div className={"char-editor__use-char-section1-segment-component-preview"}>{singlePreview}</div>
            :
            <div className={"char-editor__use-char-section1-segment-component-preview"}>
                {multiPreview.map((option, index) => {
                    if (index !== multiPreview.length - 1) {
                        return <>
                            <span>{option.result}</span><br />
                            <span>OR</span><br />
                        </>
                    }
                    else {
                        return <>
                            <span>{option.result}</span>
                        </>
                    }
                })}
            </div>

        }

    </>
}