import './App.scss';
import SuiteCommerceSSO from '@/components/SuiteCommerceSSO';
import SiteAnalytics from '@/components/SiteAnalytics';
import {AppInitializing} from '@/components/AppInitializing';
import {useApp} from '@/contexts/UserContext';
import ByzzerNotifications from '@/notifications/ByzzerNotifications';
import routes from '@/routes/routes';
import {useRoutes} from "react-router-dom";
import {useGoogleAnalytics, useIntercomAnalytics, useLogRocket} from "@/analytics";
import {useMixPanel} from "@/analytics/Mixpanel";
import { ByzzerMask } from './components/ByzzerMask/ByzzerMask';

const appInitializing = <AppInitializing />;

function App() {

    const { initialized, loadingTenantData, isMultiTenant } = useApp();

    useGoogleAnalytics();
    useIntercomAnalytics();
    // useLogRocket();
    useMixPanel();
    const element = useRoutes(routes);

    if(!initialized) {
        return appInitializing;
    }

    const showMask = loadingTenantData && isMultiTenant;

    return (
        <>
            <ByzzerMask show={showMask} loading={showMask} isFullScreen={true} />
            {element}
            <SuiteCommerceSSO />
            <SiteAnalytics />
            {/* @ts-ignore */}
            <ByzzerNotifications />
        </>
    );
}

export default App;
