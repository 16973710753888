import './DodRunConfigEditor.scss';
import {useEffect, useMemo, useRef, useState, useCallback} from 'react';
import {DashboardContent2} from '@/components/dashboard';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {DodRunConfigWizard} from '@/components/DodConfigEditor/DodRunConfigWizard';
import { DodRunConfigWizardMode } from '@/components/DodConfigEditor/types';
import classnames from 'classnames';
import DataPointCalculator, {
    DataPointCalculatorRef,
} from '@/components/DodConfigEditor/DodRunConfigWizard/DataPointCalculator/DataPointCalculator';
import {DodFilters, DodRunConfig} from '@/types/DodRun';
import {useTenantApi} from "@/hooks";
import {useMarketService} from "@/services/market.service";
import {ExpandablePanelProvider} from "@/components/ExpandablePanel";
import ExpandablePanelContainer from "@/components/ExpandablePanel/ExpandablePanelContainer";
import {isValidArray} from '@/utils';
import {
    DEFAULT_ROW_COL_CONFIGS,
    DEFAULT_RUN_CONFIG,
    DodWizardProvider
} from "@/components/DodConfigEditor/DodRunConfigWizard/DodWizardProvider";
import {RowColConfig} from "@/components/DodConfigEditor/types";
import cloneDeep from "lodash/cloneDeep";
import {useDodWizard} from "@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext";
import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";
import { getDodSeriesNames } from '@/api';
import {PresetMap} from "@/types/ApiTypes";
import {useDodService} from "@/services/dodPresets.service";
import { ByzzerTipIcon } from '@byzzer/ui-components';
import { defaultPpgName, defaultPpgNameForDod } from '@/config/globalVars';

const baseClassName = 'dod-run-config-editor';

export type DodRunConfigEditorProps = {
    className?: string;
};

function Editor({className, ...props}: DodRunConfigEditorProps) {

    const {applyValues} = useDodWizard();
    const {getExtractById} = useTenantApi();
    const {getRunConfigMarketsByKeys} = useMarketService();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {runId} = useParams();
    const {dodPresetsMap} = useDodService();
    const [initializing, setInitializing] = useState<boolean>(Boolean(runId));
    const [initialRunConfig, setInitialRunConfig] = useState<DodRunConfig>();
    const calculatorRef = useRef<DataPointCalculatorRef>(null);
    const prefix = runId ? 'Edit Your' : 'Create A';
    const mode = useMemo<DodRunConfigWizardMode>(() => {

        let mode = searchParams.get('mode') as DodRunConfigWizardMode;
        if(mode) {
            return mode;
        }

        if(runId) {
            return 'edit';
        }

        return 'create';

    }, [searchParams, runId])

    function normalizeCharForRunConfig(charValues) {
        let getFormattedCharValues = {}
        if (Array.isArray(charValues)) {
            charValues.forEach((item) => {
                return (
                    getFormattedCharValues[item.characteristic] = item
                )
            })
            return getFormattedCharValues
        } else {
            return charValues
        }
    }

    function normalizePpgForRunConfig(ppgValues) {
        let getFormattedPpgValues = {}
        if (Array.isArray(ppgValues)) {
            ppgValues
                .map((ppg) => ({
                    ...ppg,
                    displayName: ppg.displayName === defaultPpgNameForDod ? defaultPpgName : ppg.displayName,
                }))
                .forEach((item) => {
                    return (getFormattedPpgValues[item.ppg] = item);
                });
            return getFormattedPpgValues
        } else {
            return ppgValues
        }
    }

    function getFiltersWithExcludeValues(filters: DodFilters): string[] {
        let excludedFilters: string[] = [];
        for (const [key, values] of Object.entries(filters)) {
            if (key === 'characteristics' || key === 'customCharacteristics' || key === 'ppgs') {
                for (const [_, value] of Object.entries(values)) {
                    // TODO: enable All Except feature back in NOV-II release
                    // if (value?.isExcluded) {
                    //     const filter =
                    //         key === 'characteristics'
                    //             ? value.characteristic
                    //             : key === 'customCharacteristics'
                    //             ? value.characteristic
                    //             : key === 'ppgs'
                    //             ? value.ppg
                    //             : '';
                    //     excludedFilters.push(filter);
                    // }
                    if (value?.isExcluded !== undefined) {
                        delete value.isExcluded;
                    }
                }
            }
            // TODO: enable All Except feature back in NOV-II release
            // if (values?.isExcluded) {
            //     excludedFilters.push(key);
            // }
            if (values?.isExcluded !== undefined) {
                delete values.isExcluded;
            }
        }
        return excludedFilters;
    }

    useEffect(() => {
        if (runId) {
            ;(async () => {
                try {
                    const extract = await getExtractById(parseInt(runId));
                    // const allExceptSelectionFields = getFiltersWithExcludeValues(extract.filters);
                    getFiltersWithExcludeValues(extract.filters);
                    // the market values are initially be returned as market key strings so we have to convert them
                    // this logic needs to be moved into a service but due to circular references it's being done here
                    // for now
                    const orderedMarketValues = [...extract.layout.rows, ...extract.layout.columns].find(item => item.type === 'markets')?.order || [];
                    const cachedMarketNodes = getRunConfigMarketsByKeys(
                        extract.filters.markets.values as any,
                        {
                            isDodMarket: true,
                            categories: extract.filters.categories.values as string[],
                            isCategoriesSummedMode: extract.filters.categories.summedSelections.length ? true : false,
                            summedCategories: extract.filters.categories.summedSelections as any,
                            nonSummedCategories: extract.filters.categories.values as string[]
                        }
                    );

                    const marketValues = cachedMarketNodes.sort((a, b) => orderedMarketValues.indexOf(a.name) - orderedMarketValues.indexOf(b.name))
                    extract.filters.markets.values = marketValues;
                    extract.filters.characteristics = normalizeCharForRunConfig(extract.filters.characteristics) ?? {}
                    extract.filters.customCharacteristics = normalizeCharForRunConfig(extract.filters.customCharacteristics) ?? {}
                    extract.filters.ppgs = normalizePpgForRunConfig(extract.filters.ppgs) ?? {}
                    //modifying characteristics with normalizeCharForRunConfig function to convert it into the format we are using to display characteristics in DODProductFilterBuilder
                    extract.filters.markets.summedSelections = extract.filters.markets.summedSelections.map(
                        ({name, values}) => ({
                            name,
                            values: getRunConfigMarketsByKeys(
                                values as any,
                                {
                                    isDodMarket: true,
                                    categories: extract.filters.categories.values as string[],
                                    isCategoriesSummedMode: extract.filters.categories.summedSelections.length ? true : false,
                                    summedCategories: extract.filters.categories.summedSelections as any,
                                    nonSummedCategories: extract.filters.categories.values as string[]
                                }
                            ),
                        })
                    );
                    const rowColConfigs: RowColConfig[] = [];
                    const {
                        rows,
                        columns,
                        overallUiOrder,
                    } = extract.layout;
                    // merge rows and columns and apply the
                    // @ts-ignore
                    const rowsAndColumns: RowColConfig[] = [...rows.map(row => ({
                        ...row,
                        axis: 'row',
                        values: row.order ?? [],
                    })), ...columns.map(col => ({
                        ...col,
                        axis: 'col',
                        values: col.order ?? [],
                    }))];

                    // use the overall order to arrange the row/col configs
                    overallUiOrder.map(type => {
                        rowColConfigs.push(...rowsAndColumns.filter(v => v.type === type))
                    })

                    const isQuickLayout = extract.layout.quickLayoutCode?.startsWith('ql')

                    let presets: PresetMap = {};
                    if (extract.appliedPresets) {
                        const appliedPresets = extract.appliedPresets;
                        const { product, market, time_period, fact } = dodPresetsMap;
                        presets = {
                            ...(appliedPresets.product && {
                                product: product.find((p) => p.id === appliedPresets.product),
                            }),
                            ...(appliedPresets.market && {
                                market: market.find((p) => p.id === appliedPresets.market),
                            }),
                            ...(appliedPresets.time_period && {
                                time_period: time_period.find((p) => p.id === appliedPresets.time_period),
                            }),
                            ...(appliedPresets.fact && { fact: fact.find((p) => p.id === appliedPresets.fact) }),
                        };
                    }

                    applyValues({
                        runConfig: extract,
                        rowColConfigs,
                        timePeriodsReordered: !isQuickLayout,
                        presets,
                        // TODO: enable All Except feature back in NOV-II release
                        // allExceptSelectionFields: allExceptSelectionFields
                    });
                    setInitialRunConfig(extract);

                } finally {
                    setInitializing(false);
                }
            })();
        } else {
            setInitializing(false);
            applyValues({
                runConfig: cloneDeep(DEFAULT_RUN_CONFIG),
                rowColConfigs: cloneDeep(DEFAULT_ROW_COL_CONFIGS),
            });
            setInitialRunConfig(undefined);
        }
    }, [runId]);

    const fetchSeriesNames = useCallback(async () => {
        try {
            const response = await getDodSeriesNames();
            const individual = isValidArray(response.individualSeriesNames)
                ? new Set(response.individualSeriesNames.map((name) => name.trim().toLowerCase()))
                : new Set([]);
            const scheduled = isValidArray(response.scheduledSeriesNames)
                ? new Set(response.scheduledSeriesNames.map((name) => name.trim().toLowerCase()))
                : new Set([]);
                applyValues({
                    seriesNames: { individual, scheduled },
                    individualRunSeriesNames: isValidArray(response.individualSeriesNames)
                        ? response.individualSeriesNames
                        : [],
                });
        } catch (error) {
            console.error('Error fetching DOD series names:', error);
        }
    }, [getDodSeriesNames, applyValues]);

    useEffect(() => {
        fetchSeriesNames();
    }, []);

    function handleComplete(redirectToSchedule = false) {
        navigate(`/dashboard/extracts/${redirectToSchedule ? 'scheduled' : 'history'}`, {replace: true});
    }

    return (
        <ExpandablePanelProvider>
            <DashboardContent2
                title={<>{prefix} Data On Demand Run</>}
                className={classnames(baseClassName, className)}
                loading={initializing}
                extras={
                    // <div className={`${baseClassName}__header-actions`}>
                    /* doubt: if this need to be removed from here. placement is needed to move this */
                    <DataPointCalculator byzRef={calculatorRef}/>
                    // </div>
                }
                numberTag={["layout"].includes(mode) ? <></> : <NumberTagReportRuns />}
                rootContent={<ExpandablePanelContainer className={`${baseClassName}__panel-container`}/>}
            >
                <ByzzerMask loading={initializing}/>
                {!initializing && (
                    <DodRunConfigWizard
                        initialRunConfig={initialRunConfig}
                        onComplete={handleComplete}
                        mode={mode}
                        extractId={Number(runId)}
                        // previousRunConfig={(state as { previousRunConfig: DodRunConfig })?.previousRunConfig}
                    />
                )}
            </DashboardContent2>
        </ExpandablePanelProvider>
    );
}

// this basically a HOC to wrap the true component in DodWizardProvider
export function DodRunConfigEditor(props: DodRunConfigEditorProps) {
    return (
        <DodWizardProvider>
            <Editor {...props} />
        </DodWizardProvider>
    );
}

function NumberTagReportRuns() {

    const {getMySubscriptionUsage} = useTenantApi();
    const [remainingCoreRuns, setRemainingCoreRuns] = useState<number>(0);

    useEffect(() => {
        ;(async () => {
            try {
                await loadSubscription();
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    async function loadSubscription() {
        const {basicReports} = await getMySubscriptionUsage();
        setRemainingCoreRuns(basicReports ? basicReports?.limit - basicReports.used : 0);
    }

    if(remainingCoreRuns === Infinity || remainingCoreRuns === 0) return <></>;

    return (
        <div className={`report-run-selector__tag`}>
            {'-1 run'}
            <ByzzerTipIcon
                tip={`This will deduct 1 core run credit from your subscription`}
                tipLocation="right"
            />
        </div>
    );
}

export default DodRunConfigEditor;
