import React,{ useRef } from 'react';
import { ByzzerButton } from '../form';
import MultiSelectMarkets from '../MultiSelectMarkets';
import './AlertMarketSelector.scss';
import { TrackClick } from '@/analytics';
import SelectMarketType from '@/components/SelectMarketType';
import { selectorStates, alertConstants } from '@/config/globalVars';

const AlertMarketSelector = (props) => {
    const marketSelectorsConfig = props.selectorState.alertConfigurations.marketSelectors;

    const isMultiselect = () => {
        if (marketSelectorsConfig.required && marketSelectorsConfig.multi) {
            return true;
        } else {
            return false;
        }
    };
    const renderForm = () => {
        // const preferenceData = props?.preferenceData; // TODO: Commenting this code, should be fixed in alerts refactoring
        if (!getAlertConfigs()?.required && !getAlertConfigs()?.multi) {
            return (
                <>
                    <div className={'alert-market-selector-market-0'}>
                        Core alerts are run on the Total FMCG Retailers market, to deliver insights across the US.
                    </div>
                    {/* <TrackClick name={`My Alerts - "Add Alert" button clicked`}>
                        <ByzzerButton
                            label={`${props.selectorState.edit ? 'Update Alert' : 'Add Alert'}`}
                            onClick={() => props.createAlert()}
                        />
                    </TrackClick> */}
                </>
            );
        }
        const marketData = JSON.parse(JSON.stringify(props.selectorState));
        // marketData.focusMarket = !isMultiselect() && marketData?.focusMarketSelections.length > 0 ? marketData?.focusMarketSelections[0] : '';
        marketData.focusMarketSelections = {
            focusMarket: {
                byMarketName: {
                    markets: props.selectorState.focusMarketSelections,
                },
            },
        };
        const getSubSelectorProps = () => {
            return marketSelectorsConfig.requiredRemMarketForEachRetailer
                ? {
                      markets: {
                          requiredRemMarketForEachRetailer: marketSelectorsConfig.requiredRemMarketForEachRetailer
                              ? true
                              : false,
                          smartAccount: true,
                          marketSections: {
                                embodyTotalMarkets: false,
                                embodyFmcgRetailers: true,
                                embodySpecialityRetailers: true,
                                embodyGeographyMarkets: false,
                            },
                          comparativeMarketsRequired: marketSelectorsConfig.comparativeMarketsRequired ?? false,
                          requireComparisonMarketType: marketSelectorsConfig.requireComparisonMarketType ?? false,
                          storeAllMarketData: marketSelectorsConfig.storeAllMarketData ?? false    // store allMarketData during market selections
                      },
                      subSelectorType: 'smartReportMarkets',
                  }
                : {
                      markets: {
                          marketSections: {
                              embodyTotalMarkets: true,
                              embodyFmcgRetailers: true,
                              embodySpecialityRetailers: true,
                              embodyGeographyMarkets: true,
                          },
                          requireComparisonMarketType: marketSelectorsConfig.requireComparisonMarketType ?? false,
                      },
                  };
        };
        // focusMarket?.byMarketName?.markets
        return (
            <>
                {props.type === alertConstants.comparisonMarket && (
                    <SelectMarketType
                        from="reportSelector"
                        submitComparisonTypeSelections = {(obj, panelStatusChanged) => submitComparisonTypeSelections(obj,panelStatusChanged)}
                        reportSelectors={marketData}
                        subSelectors={getSubSelectorProps()}
                        saveBtnType={'alertSelector'}
                        stepNo={2}
                        fromBack={props.fromBack}
                    />
                )}
                {props.type === alertConstants.market && (

                <MultiSelectMarkets
                    reportType="subscription"
                    marketGroup="rms"
                    // userPrefState={preferenceData}
                    setSelectorsData={(obj) => submitMarketSelector(obj)}
                    from="reportSelector"
                    selectorState={marketData}
                    multiSelect={isMultiselect()}
                    maxLimit={marketSelectorsConfig.limit}
                    saveBtnType={'alertSelector'}
                    editButton={props.selectorState.edit}
                    actionConfig={marketData}
                    subSelectors={getSubSelectorProps()}
                    canGoBack={false}
                    refreshMarkets={props.selectorState.refreshMarkets}
                    fromBack={props.fromBack}
                    stepNo={1}
                />)}
            </>
        );
    };
    // const fetchMarketSelectorData = (dataObj) => {
    //     setMarketSelectorData(dataObj?.focusMarketSelections);
    // }

    const selectorsDataRef = useRef();
    selectorsDataRef.current = JSON.parse(JSON.stringify(props.selectorState));
    const submitMarketSelector = (obj) => {
        // alert();
        if (obj?.focusMarket) {
            // obj = {marketSelections: [obj?.focusMarket]}
            obj.marketSelections = [obj?.focusMarket];
        }

        props.setSelectorData(obj);
        if (obj?.status === selectorStates.completed) {
            let selectorsData = JSON.parse(JSON.stringify(props.selectorState));
            selectorsData.selectorsStatus[1].status = selectorStates.completed;
            selectorsData.activeCollapsePanel = [2];
            if (obj?.focusMarketSelections) {
                selectorsData.focusMarketSelections = obj.focusMarketSelections;
                selectorsData.selectedItems = obj.selectedItems;
                selectorsData.focusMarketsSelectedData = obj.focusMarketsData;
                selectorsData.isMarketValidFormItems = obj.isMarketValidFormItems;                
            }
            // Added a reference because state was not getting updated without render
            selectorsDataRef.current = selectorsData;
            props.setSelectorData(selectorsData);
        }
        // props.setSelectorData(obj);
    };

    const submitComparisonTypeSelections = (obj, panelStatusChanged = false) => {
        if (panelStatusChanged) {
          
            let selectionsToSelState = JSON.parse(JSON.stringify(selectorsDataRef.current));
            selectionsToSelState.selectorsStatus[2].status = selectorStates.pending;
            selectionsToSelState.activeCollapsePanel = [2];
            props.setSelectorData(selectionsToSelState);
        } else {
            obj.focusMarketSelections = obj.focusMarket
                ? [obj.focusMarket]
                : obj.selectedItems.map((val) => val.name.split('$$')[0]) || [];

            props.setSelectorData(obj);
        }
    };

    return (
        <>
            {Boolean(marketSelectorsConfig) && renderForm()}
        </>
    );
};
export default AlertMarketSelector;