// To Do: Refactor this file based on latest coding standards
import {useTenantApi} from '@/hooks/useTenantApi';
import back from '@images/icons/back-icon.svg';
import { isSaturday } from 'date-fns';
import React, { useEffect, useState } from 'react';
import '../../../pages/MyAlertsPage/MyAlertsPage.scss';
import {
    formatDateMMDDYYYY
} from '../utils/alertUtil2_0';
import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";
import {AlertViewActions}  from '../utils/helpers';
import useIsMobile from '@/hooks/useIsMobile';

function MyAlertView2_0({
    alertData: {
        results: {
            html_for_ui: { string: htmlTemplate },
            filter_duration,
            title
        }, 
        id,
        createdBy,
    },
    goBack,
    ...props
}) {
    const { getHandlebarsTemplate } = useTenantApi();
    const isMobile = useIsMobile();

    const [alertTemplate, setAlertTemplate] = useState(htmlTemplate);
    const [loading, setLoading] = useState(false);
    
    let dt = new Date(filter_duration.split('to')[1].trim());
    let duration;
    if (!isSaturday(dt)) {
        duration = formatDateMMDDYYYY(dt.setDate(dt.getDate() - 1));
    } else {
        duration = formatDateMMDDYYYY(dt);
    }

    useEffect(() => {
        if (!htmlTemplate) {
            getAlertData()
        }
    }, []);

    async function getAlertData() {
        setLoading(true);
        let alertTemplate = await getHandlebarsTemplate(id); // to fetch this alert ID's alert
        setAlertTemplate(alertTemplate?.alert?.string);
        setLoading(false);
    }

    const showCreatedBy = Boolean(createdBy?.fullName && createdBy?.email);

    const showPrintButton = !isMobile;

    return (
        <>
            <ByzzerMask loading={loading}/>
            <div className="my-alerts detailed-my-alerts">

                <div className="my-alerts__header">
                    <div className="my-alerts__heading">
                        {title}
                    </div>

                    <AlertViewActions goBack={goBack}/>
                </div>

                <div className="my-alerts__sub-heading">
                    <span>For the latest week, ending {duration}</span>
                    {showCreatedBy && <span>Created by {createdBy?.fullName} ({createdBy?.email})</span>}
                </div>
            </div>

            <div className="detailed_card detailed_card_scroll detailed_card-view">
                {/* RENDERING ALERT TEMPLATE ------------- */}
                {<div dangerouslySetInnerHTML={{ __html: alertTemplate}} />}
            </div>
        </>
    )
}

export default MyAlertView2_0