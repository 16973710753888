import { useState, useEffect } from "react";
import classnames from 'classnames';
import './VersionInfo.scss';
import {buildInfo as dashboardBuildInfo, versionInfo} from '@/config';
import { ByzzerMask } from "../ByzzerMask/ByzzerMask";
import { getAppVersions } from "@/services/version.service";

const baseClassName = 'byz-version-info';

export type BuildInfo = {
    sha?: string;
    tag?: string;
    branch?: string;
    uncommitted?: boolean;
    buildDtm?: Date;
    name?: string;
    version?: string;
}

export type BuildInfoError = {
    config?: object;
    response?: object;
    code?: string;
}

export type VersionInfoType = {
    url?: string;
    buildInfo?: BuildInfo | BuildInfoError; 
}

const VersionInfo = ({
    url = '',
    buildInfo
}: VersionInfoType) => {

    const {
        sha,
        tag,
        branch,
        uncommitted,
        name,
        version,
    } = buildInfo as BuildInfo;

    const showFullVersionInfo = versionInfo?.showFullVersionInfo !== false;
    const shortenedUrl = url
        .replace('/version', '')

    return (
        <div className={classnames(`${baseClassName}__service`)}>
            <span className={classnames(`${baseClassName}__header`)}>
                {name ?? '[Unknown Service]'}:
            </span>
            <ul className={classnames(`${baseClassName}__info`)}>
                {/* Todo: button to show advanced info- branch, sha */}
                {showFullVersionInfo && (<>
                    <li><span>URL:</span> {shortenedUrl}</li>
                </>)}
                <li><span>Version:</span> {version ?? 'N/a'}</li>
                <li><span>SHA:</span> {sha}{uncommitted ? '-dirty' : ''}</li>
                {showFullVersionInfo && (<>
                    <li><span>Tag:</span> {tag}</li>
                    <li><span>Branch:</span> {branch}</li>
                </>)}
            </ul>
        </div>
    )
}
VersionInfo.displayName = 'VersionInfo';



export const VersionInfosDisplay = () => {

    const [appVersions, setAppVersions] = useState<VersionInfoType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        version,
        git: {
            sha,
            tag,
            branch,
            dirty
        }
    } = dashboardBuildInfo;

    useEffect(() => {
        ;(async () => {
            if (!isLoading) {
                setIsLoading(true)
                try {
                    const {buildInfos} = await getAppVersions();
                    setAppVersions([
                        {
                            url: 'N/a',
                            buildInfo: {
                                sha,
                                tag,
                                branch,
                                uncommitted: dirty,
                                name: 'byzzer-dashboard',
                                version
                            }
                        },
                        ...buildInfos
                    ])
                } catch (err) {
                    // tbd
                    console.error(err)
                } finally {
                    setIsLoading(false)
                }
            }
        })()
    }, []);

    return (
        isLoading ? (
            <ByzzerMask show={isLoading} loading={isLoading}>Loading App Versions...</ByzzerMask>
        ) : (
            <div className={classnames(`${baseClassName}__service-list`)}>
                {appVersions?.map(({url, buildInfo}, index) => <VersionInfo key={url ?? index} url={url} buildInfo={buildInfo}/>)}
            </div>
        )
    )

};

export default VersionInfosDisplay;

VersionInfosDisplay.displayName = 'VersionInfosDisplay';