import React, { useEffect } from 'react';
import { useBetterNavigate } from '@/utils';
import { useUser } from '@/contexts/UserContext';
import { useAccountService } from '@/hooks/useAccountService';

export function Logout() {
    const navigate = useBetterNavigate();
    const {logout} = useAccountService();

    const { isCsrObo } = useUser();
    useEffect(() => {
        logout();
        if (isCsrObo) {
            navigate('/auth/csr_sign_in');
        } else {
            navigate('/auth');
        }
    }, []);
    return <p>logout...</p>;
}

export default Logout;
