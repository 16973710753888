import {createContext, ReactNode, useState} from "react";
import { DefaultRunConfig } from "@/types/ApiTypes";

export type DefaultConfigValue = NonNullable<DefaultRunConfig>;

export type DefaultConfigWizardState = {
    enableDemographics?: boolean;
    enableMarketTypePicker?: boolean;
    requiredMasterCompany?: string;
    requiredMarketGroup?: string;
    showRemainingMarkets?: boolean;
}

export type DefaultConfigWizardContextValue = {
    value: NonNullable<DefaultConfigValue>;
    state?: DefaultConfigWizardState;
    onChange?: (name: keyof DefaultConfigValue, value: any, data?: any) => void;
}

export const DefaultConfigWizardContext = createContext<DefaultConfigWizardContextValue>({
    value: {} as any
});
DefaultConfigWizardContext.displayName = 'DefaultConfigWizardContext'
