import classnames from 'classnames';
import './TenantSelectorOption.scss';

type TenantSelectorOptionProps = {
    children?: string;
    value?: string;
    isPinned?: boolean;
    onClick?: (e: any) => void;
    onChange?: (e: any) => void;
    onPinClick?: (e: any) => void;
}
const baseClassName = 'byz-tenant-selector-option';

export const TenantSelectorOption = ({
    children,
    value,
    isPinned,
    onClick,
    onChange,
    onPinClick,
    ...props
}: TenantSelectorOptionProps) => {

    function handleClick(e) {
        e.stopPropagation();
        onPinClick?.(value);
    }

    return (
        <div className={classnames(`${baseClassName}`)}>
            <div className={classnames(`${baseClassName}__children`)}>
                {children}
            </div>
            <span className={classnames(
                `${baseClassName}__pinning`,
                {
                    [`${baseClassName}__pinning--pinned`]: isPinned,
                    [`${baseClassName}__pinning--unpinned`]: !isPinned
                }
            )} onClick={handleClick}/>
        </div>
    );

};

export default TenantSelectorOption;

TenantSelectorOption.displayName = 'TenantSelectorOption';
