// ToDo: Incorporate other text data contents of existing sign up form also to this file
export const BENEFITS = [
    {
        icon: 'https://cdn.brandfolder.io/XFXPVPU8/at/9wttgw35bjh3g66spf6s3bvb/NIQ_Symbols_LightBlue_1_Global.png',
        // icon: 'https://cdn.brandfolder.io/XFXPVPU8/at/v5m66xr9f4326scfbq5f45/NIQ_Symbols_DarkBlue_1_Global.png', // Uncomment this if dark icons are required
        title: 'Unlock a 360-degree Data View',
        description: 'Harness the combined power of point-of-sale data, shopper data & omni data with more than 1000 product attributes'
    },
    {
        icon: 'https://cdn.brandfolder.io/XFXPVPU8/at/g9p86pcmqgbjkzt9g3q7cr/NIQ_Symbols_LightBlue_1_Charts.png',
        // icon: 'https://cdn.brandfolder.io/XFXPVPU8/at/2xjrfg23rvwtp7wxbbwgrtb/NIQ_Symbols_DarkBlue_1_Charts.png', // Uncomment this if dark icons are required
        title: 'Flexibility Meets Simplicity',
        description: 'Ingest & analyze data 24/7 in 5 different formats - templated reports, on demand data pulls, weekly alerts, automated presentations or data simulators'
    },
    {
        icon: 'https://cdn.brandfolder.io/XFXPVPU8/at/4t8hbmfg4t498bfwxggwf97c/NIQ_Symbols_LightBlue_1_Book_-_History.png',
        // icon: 'https://cdn.brandfolder.io/XFXPVPU8/at/78x36wppb4twfv3h6kvxh8g/NIQ_Symbols_DarkBlue_1_Book_-_History.png', // Uncomment this if dark icons are required
        title: 'Dedicated Live Support & Learning Resources',
        description: 'Utilize the numerous articles, resources, trainings, videos & a live support team that is ready to help you'
    },
    {
        icon: 'https://cdn.brandfolder.io/XFXPVPU8/at/mmc6tv9vp95grtqgqc7rt6j/NIQ_Symbols_LightBlue_1_Calendar.png',
        // icon: 'https://cdn.brandfolder.io/XFXPVPU8/at/fn25bsrgp8ttxb8hf8srk5/NIQ_Symbols_DarkBlue_1_Calendar.png', // Uncomment this if dark icons are required
        title: 'Schedule Your Reports',
        description: 'Don\'t want to go through the hassle of setting up your reports every week? Set up your report once and we\'ll run it for you every time the data refreshes'
    }
    
];
