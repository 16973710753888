
export const fetchColorsForGrade = (grade) => {
    switch (grade) {
        case 'A':
            return 'green';
        case 'B':
            return 'yellow';
        case 'C':
            return 'orange';
        case 'D':
            return 'red';
        case 'F':
            return 'darkRed';
        default:
            return 'black';
    }
};
