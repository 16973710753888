import { ByzzerChangeEventHandler, ByzzerSelect, ByzzerSelectOption, WithValue } from '@byzzer/ui-components';
import { ReactNode, forwardRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import { FocusKPI } from '@/types/ApiTypes';

export type ByzzerFocusKPISelectorProps = {
    label?: ReactNode;
    name?: string;
    options?: ByzzerSelectOption<any, FocusKPI>[];
    onChange?: ByzzerChangeEventHandler<string[]>;
    value?: FocusKPI;
    placeholder?: string;
    className?: string;
} & OnlyRenderableIf;

export const FocusKPISelector = forwardRef<WithValue<string[]>, ByzzerFocusKPISelectorProps>(
    ({ label, name, options, onChange, value, className, placeholder, onlyRenderIf }, ref) => {
        if (onlyRenderIf === false) return <></>;

        const [internalValue, setInternalValue] = useState<FocusKPI>();

        useEffect(() => {
            setInternalValue(value);
        }, [value]);

        function handleChange(e: ByzzerChangeEvent<any>) { // todo: Provide a type
            onChange?.({
                value: e?.value,
                name,
                data: e?.data,
            });
            setInternalValue(e.value);
        }

        return (
            <ByzzerSelect
                ref={ref}
                name={name}
                className={classnames(className)}
                options={options}
                placeholder={placeholder}
                label={label}
                value={internalValue}
                onChange={handleChange}
            />
        );
    }
);
export default FocusKPISelector;
FocusKPISelector.displayName = 'FocusKPISelector';
