import {createContext, ReactNode, useState} from "react";
import { ScenarioSimulatorConfig } from "../../../types/SimulatorTypes";

export type ScenarioSimulatorState = {
    visibleSection?: 'filterEditor' | 'simulator';
    filtersEdited?: boolean;
    collapsed?: boolean;
    // keyof ScenarioSimulatorConfig;
    // types for basic ui state(s) maybe
}

export type ScenarioSimulatorContextValue = {
    value: ScenarioSimulatorConfig;
    onChange?: (
        name: keyof ScenarioSimulatorConfig, 
        value: any, 
        data?: any
    ) => void;
    state: ScenarioSimulatorState;
    simulatorCode?: string; // rename to 'sku' in future?
}

export const ScenarioSimulatorContext = createContext<ScenarioSimulatorContextValue>({
    value: {} as any,
    state: {} as any
});
ScenarioSimulatorContext.displayName = 'ScenarioSimulatorContext'
