import React, {useEffect, useState} from 'react';
import {ByzzerLink, ByzzerMultiSelect} from '@/components/form';
import ByzzerSearchableSelect from '@/components/form/ByzzerSearchableSelect';
import classNames from 'classnames';
import classnames from 'classnames';
import {ByzzerModal} from '@/components/form';
import InlineSVG from 'react-inlinesvg';
import ScoreInfoImage from '@images/ScoreInfoImage.svg';
import {useTenantApi} from '@/hooks/useTenantApi';
import {message} from 'antd';
import ByzzerOverallScoreDial from '@/components/ByzzerCharts/ByzzerOverallScoreDial';
import ByzzerOverallScoreTrendChart from '@/components/ByzzerCharts/ByzzerOverallScoreTrendChart';
import informationIcon from '@images/icons/info-black-rebrand-new.svg'; //TODO: define image src in css instead
import {driverList} from '@/config/globalVars';
import './OverallScorecardTrend.scss';
import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";
import {useBetterNavigate} from "@/utils";
import {useSuiteCommerceLink} from "@/components/SuiteCommerceSSO";
import {fetchColorsForGrade} from "./fetchColorsForGrade";


const allMarketsTipText = 'The All market selection includes all retailer total markets. The overall score is then calculated using a weighted average with these markets as the inputs.';

function OverallScorecard({
                              filters = {},
                              onFiltersChange,
                              hasScorecardAccess,
                              ...props
                          }) {
    const {calculateScores, overallScoreTrends} = useTenantApi();
    const sca = useSuiteCommerceLink();
    const navigate = useBetterNavigate();
    const mainClasses = classNames('overall-scorecard');
    const subClasses = classNames('overall-scorecard__subclass');
    const [loading, setLoading] = useState(false);
    const [scoreInfoModal, setScoreInfoModal] = useState(false);
    const [resultData, setResultData] = useState({});
    const [trendChartData, setTrendChartData] = useState({});
    const [statusInfo, setStatusInfo] = useState(false);
    const [infoItem, setInfoItem] = useState({});

    useEffect(() => {
        if (!filters.markets?.length) {
            onFiltersChange?.({markets: []});
        }
    }, []);

    useEffect(() => {
        if (validateFilters() && props.activeKey === '1') {
            loadScores();
        } else {
            setResultData({});
            setTrendChartData({})
        }
    }, [filters, props.activeKey]);

    const validateFilters = () => {
        return Boolean(filters.brands?.length && filters.categories?.length);
    };

    const loadScores = async () => {
        const formData = JSON.parse(JSON.stringify(filters));
        delete formData['marketTypes'];
        if (formData.markets?.length >= 1) {
            formData.markets = [formData.markets[0]];
        }else if(!formData.markets?.length) {
            formData.markets = ['Total US NielsenIQ Brand Score'];
        }
        formData['aggregated'] = true;
        if (formData.brands.length > 0 && formData.categories.length > 0) {
            setLoading(true);
            const result = await calculateScores('overall', formData);
            const chartData = await overallScoreTrends(
                {
                    "categories": formData.categories,
                    "brands": formData.brands,
                    "markets": formData.markets
                });
            if (result !== null && result !== undefined) {
                setResultData(Array.isArray(result) ? (result.length > 0 ? result[0] : {}) : result);
            } else setResultData({});

            if (chartData !== null && chartData !== undefined) {
                setTrendChartData(chartData);
            } else setTrendChartData({});

            setLoading(false);
        } else {
            message.info('Please select brand and category.');
        }
    };

    const renderEmptyResult = () => {
        return (
            <div className={'overall-scorecard__empty'}>
                Select a Brand, Category and Market in order to view your scores.
            </div>
        );
    };

    const navigateToScoreCard = ({key}) => {
        navigate(`/dashboard/my_scorecard/assess_levers/${key}`, {
            replace: true
        })
    };

    const renderFilters = () => {
        return (
            <div className={'my-scorecard__selections__container'}>
                <div>
                    <div className="my-scorecard__selections__container-label">Brand:</div>
                    <ByzzerSearchableSelect
                        seperator={','}
                        enableToolTip={filters.brands?.length > 0}
                        options={filters.brands?.length ? filters.brands : []}
                        placeholder={filters.brands?.length ? filters.brands : 'Select your focus brands'}
                        searchPlaceholder={'Search for brand'}
                        onChange={(e) => {
                            props.unselectBrand(e);
                        }}
                        productSet={props.productSet}
                        searchOnChange={(value, status) => props.changeBrand(value, status)}
                        searchValue={filters.brands?.length ? filters.brands : ''}
                        searchType={
                            props.showAll ? 'brandSearchMultiSelect' : 'searchBrandForProductSetMultiSelect'
                        }
                        //productType={props.productType}
                        // multiBrandSelect={true}
                    />
                </div>
                <div className="my-scorecard__selections__container-label-category">
                    <div className="my-scorecard__selections__container-label">Category(s):</div>
                    <div>
                        <ByzzerMultiSelect
                            selectedOptions={filters.categories}
                            name={'competitive-productset'}
                            placeholder={'Select from the list'}
                            multiSelectWithCheckBox
                            onChange={(e) => props.changeCategory(e)}
                            options={props.categories || []}
                            seperator={','}
                            inlineFontStyle="none"
                        />
                    </div>
                </div>
                <div>
                    <div className="my-scorecard__selections__container-label">
                        <div
                            className={classnames({
                                'my-scorecard__selections__container-label-disabled': !hasScorecardAccess
                            })}
                        >
                            Market:
                        </div>
                        {!hasScorecardAccess && <i className="my-scorecard__selections__container-label-icon"/>}
                    </div>
                    <div
                        className={classnames({
                            'my-scorecard__selections__container-market-disabled': !hasScorecardAccess
                        })}
                    >
                        <ByzzerSearchableSelect
                            options={filters.markets?.length ? filters.markets : []}
                            placeholder={'All'}
                            enableToolTip={!filters.markets?.length}
                            toolTipText={allMarketsTipText}
                            searchPlaceholder={'Search for Market'}
                            onChange={(e, type = null) => {
                                props.changeMarket(e, type);
                            }}
                            searchOnChange={(value) => {
                                props.changeSingleMarketSearch(value);
                            }}
                            searchType={'marketSingleSearch'}
                            disabled={!hasScorecardAccess}
                            categories={filters.categories}
                        />
                    </div>
                </div>
                <div></div>
            </div>
        );
    };

    const scoreModal = (val) => {
        const fetchGrade = resultData[val.key];
        const gradeClass = fetchColorsForGrade(fetchGrade?.grade) === 'black' && 'no-grade';
        return (
            <div className='overall-scorecard__subclass-scores-modal'>
                <div className='overall-scorecard__subclass-scores-modal-title'>
                    {val.name}
                    <span className='overall-scorecard__subclass-scores-modal-title-info'>
                        <i>
                            <img
                                src={informationIcon}
                                onClick={() => {
                                    setStatusInfo(!statusInfo);
                                    setInfoItem(val);
                                }}
                                alt={'info'}
                            />
                        </i>
                    </span>
                </div>
                <div
                    className={`overall-scorecard__subclass-scores-modal-container ${fetchColorsForGrade(fetchGrade?.grade)}`}>
                    <div className={`overall-scorecard__subclass-scores-modal-container-grade ${gradeClass}`}>
                        {fetchGrade?.grade || 'No Data'}
                    </div>
                    {fetchGrade?.value > 0 && (
                        <div className='overall-scorecard__subclass-scores-modal-container-score'>
                            {fetchGrade?.value?.toFixed(0)}
                        </div>)
                    }
                </div>
                <div className='overall-scorecard__subclass-scores-modal-description'>{val.description}</div>
                {hasScorecardAccess && <div className='overall-scorecard__subclass-scores-modal-goto'>
                    <ByzzerLink
                        className={` ${(fetchGrade?.grade === 'No Data') && 'disable-link'}`}
                        label={'Go to Scorecard'}
                        onClick={() => navigateToScoreCard(val)}/>
                    <span
                        className={`overall-scorecard__subclass-scores-modal-goto-arrow ${(fetchGrade?.grade === 'No Data') && 'disable-link'}`}>
                        {'>'}
                    </span>
                </div>}
            </div>
        )
    }

    const renderScores = () => {
        return (
            <div className='overall-scorecard__subclass-scores'>
                <div className='overall-scorecard__subclass-scores-title'>Function Scores:</div>
                <div className='overall-scorecard__subclass-scores-container'>
                    {scoreModal(driverList[2])}
                    {scoreModal(driverList[3])}
                    {scoreModal(driverList[4])}
                    {scoreModal(driverList[5])}
                </div>

            </div>
        )
    }

    const formatChartData = (data) => {
        if (data) {
            data.forEach(item => {
                item.overall = item?.overall > 0 ? (Math.round(item?.overall)) : null;
                item.promotion_driver = item?.promotion_driver > 0 ? (Math.round(item?.promotion_driver)) : null;
                item.pricing_driver = item?.pricing_driver > 0 ? (Math.round(item?.pricing_driver)) : null;
                item.assortment_driver = item?.assortment_driver > 0 ? (Math.round(item?.assortment_driver)) : null;
                item.product_driver = item?.product_driver > 0 ? (Math.round(item?.product_driver)) : null;
            })
            return data;
        } else
            return [];
    }

    return (
        <div className={mainClasses}>
            <ByzzerMask loading={props.loading || loading}/>
            {/* <div className={subClasses}> */}
                <div className="my-scorecard__subclass-title">
                    View your Overall Score across drivers to understand at a high level how your brand is performing.
                    <ByzzerLink
                        className="my-scorecard__subclass-title-link"
                        label={'What does my score mean?'}
                        onClick={() => {
                            setScoreInfoModal(true);
                        }}
                    />
                </div>
                {/*
                    this needs to be hasScorecardAccess === false and not !hasScorecardAccess so that it is only true
                    when hasScorecardAccess is defined.
                 */}
                {hasScorecardAccess === false && (
                    <div className={'my-scorecard__subclass-purchase'}>
                        Purchase the
                        {' '}
                        <ByzzerLink theme={'dark'} onClick={() => sca.openPathInNewTab('/analytics-package')}>
                            Analytics Package
                        </ByzzerLink>
                            {' '}
                        to unlock your Brand Scorecards and Action Plans.
                    </div>
                )}
                {renderFilters()}
                {Object.keys(resultData).length > 0 && <div className={subClasses + '-trends'}>
                    <ByzzerOverallScoreDial className={subClasses + '-trends-dial'}
                                            value={resultData?.overallScore?.value?.toFixed(0)}
                                            grade={resultData?.overallScore?.grade}/>
                    <ByzzerOverallScoreTrendChart trendChartData={formatChartData(trendChartData.scoresHistory)}/>
                </div>}

                {Object.keys(resultData).length > 0 && renderScores()}

                {Object.keys(resultData).length === 0 && renderEmptyResult()}
            {/* </div> */}
            <ByzzerModal
                show={statusInfo}
                onClose={() => setStatusInfo(!statusInfo)}
                closeOnClickOutside={true}
                size={'extra-large'}
                heading={infoItem.name}
                type={'info'}
                headerType={'normal'}
            >
                <div className={'my-scorecard__grade__container__item2__card__item2__title-icon-info'}>
                    {infoItem.popupDescription}
                </div>
            </ByzzerModal>
            <ByzzerModal
                show={scoreInfoModal}
                onClose={() => setScoreInfoModal(!scoreInfoModal)}
                closeOnClickOutside={true}
                size={'medium'}
                //heading={driverList[infoItem].name}
                type={'info'}
                headerType={'none'}
            >
                <div className={'my-scorecard__subclass-title-link-info'}>
                    <div className={'my-scorecard__subclass-title-link-info-description'}>
                        Your score is based on your brand’s performance within the category and market. It is relative
                        to all other brands within the same category, and selling in the same market. The same scale is
                        used to score your performance at the overall level, all the way down to the individual actions.
                        Your score is on a scale from 100-600, with the highest brand in the category receiving a 600
                        and the lowest receiving a 100.
                    </div>
                    <div className={'my-scorecard__subclass-title-link-info-image'}>
                        <InlineSVG src={ScoreInfoImage}/>
                    </div>
                </div>
            </ByzzerModal>

        </div>
    )
}

export default OverallScorecard;
