import React from 'react';
import iconassortment from '../../../images/icons/alerticons/icon-assortment.svg';
import iconmarketshare from '../../../images/icons/alerticons/icon-market-share.svg';
import iconmarkettrends from '../../../images/icons/alerticons/icon-market-trends.svg';
import iconpricing from '../../../images/icons/alerticons/icon-pricing.svg';
import iconpromotions from '../../../images/icons/alerticons/icon-promotions.svg';
import iconmarkettrendalert from '../../../images/icons/alerticons/icon-market-trend-alert.svg';
import iconassortmentactionalert from '../../../images/icons/alerticons/icon-assortment-action-alert.svg'
import iconbrandtrend from '../../../images/icons/alerticons/icon-brand-trend.svg';
import iconpricepromotiontrend from '../../../images/icons/alerticons/icon-price-position-trend.svg';
import icongrowthalert from '../../../images/icons/alerticons/icon-growth-alert.svg';
import parse from 'react-html-parser';
import { ByzzerSearch, ByzzerButton, ByzzerSelect, ByzzerMultiSelect } from '@/components/form';

import {
    returnColorByDirection,
    formatPercent,
    numberWithCommas,
    formatPercentWithSign,
    txtColor,
    redColor,
    greenColor,
    coloredSpan,
    valueCheck,
    formatPercentWithoutSign,
    toTitleCase,
    formatDateDDMMYYYY,
    formatDateMMDDYYYY,
    numberWith2Decimal,
    returnNewtext
} from '../utils/alertUtil1_9';
import { ByzzerLink } from '@/components/form';
import { NavLink, useNavigate } from 'react-router-dom';
import { ByzzerButton as ByzButton } from '@byzzer/ui-components';
import { focusKPIOptions } from '@/components/FocusKPISelector/focusKPI.constants';

function AlertCardView1_9(props) {

    let alert = props.CardData;
    let viewReportAlertsSKU = props.viewReportAlertsSKU;
    const alertMap = props.alertMap;
    let results = alert.results;
    let alertResults = JSON.parse(JSON.stringify(results).replace(/Fmcg/g, 'FMCG'));
    let alertConfig = alert.actionConfig.insightParams ? alert.actionConfig.insightParams : alert.actionConfig;
    alertConfig.focus_brand = toTitleCase(alertConfig.focus_brand);
    alertResults.filter_brand = toTitleCase(alertResults.filter_brand);
    alertConfig.category = toTitleCase(alertConfig.category);  
  
    let custom_category ;

    const blurbValuePrefix = alertConfig?.focusKPI === 'Dollar Sales' ? '$' : '';
    const blurbValueSuffix = `${(Boolean(!blurbValuePrefix) ? ' ' : '')}${focusKPIOptions.find(({value}) => value === alertConfig?.focusKPI)?.data?.suffixDisplay}`;

    if(alertMap[alert.productSku] === 'MARKET_SHARE' || alertMap[alert.productSku] === 'ECOSYSTEM_ALERT' || alertMap[alert.productSku] === 'GROWTH_ALERT'){
        
        if (results.origParam !== undefined && results.origParam !== null) {
            custom_category = results.origParam.productSelections[0].category.length > 1 ? "the categories" : toTitleCase(results.origParam.productSelections[0].category[0]);
        } else {
            custom_category = alertConfig.category;
        }
    }
    
    // ------------------------------------CORE ALERTS------------------------------------

    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'MARKET_SHARE') {
        alert.title = 'Market Share Alert';
        alert.blurb = parse(
            `${alertResults.filter_brand}'s share of ${custom_category} is ${formatPercent(
                alertResults.share_of_category
            )}%, ${txtColor(`${alertResults.share_of_category_change_gain_or_decline} of ${formatPercent(
                alertResults.share_of_category_change
            )} points`, `${alertResults.share_of_category_change_gain_or_decline}`)}.`
        );
        alert.iconPath = iconmarketshare;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'COMPETITIVE_PRICING') {
        alert.title = 'Pricing Alert';
        alert.blurb = parse(
            `${alertResults.filter_brand}'s pricing position vs. competitors is ${txtColor(`${formatPercentWithSign(alertResults.ratio_change)}%`, alertResults.ratio_change, true)}.`
        );
        alert.iconPath = iconpricing;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'DISTRIBUTION_GAIN_AND_LOSSES') {
        alert.title = 'Distribution Alert';
        alert.blurb = parse(
            `${alertResults.filter_brand}'s share of distribution ${txtColor(`${alertResults.share_of_tdp_diff_direction} by ${formatPercent(Math.abs(alertResults.share_of_tdp_diff))} share points`, `${alertResults.share_of_tdp_diff_direction}`)}.`
        );
        alert.iconPath = iconassortment;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'PROMOTION_EFFICIENCY') {
        alert.title = 'Promotion Efficiency Alert';
        let change_promotion_efficiency_txt = `${returnNewtext(`${txtColor(`${alertResults.change_promotion_efficiency_direction} by ${formatPercent(
            Math.abs(alertResults.change_promotion_efficiency)
        )}%`, `${alertResults.change_promotion_efficiency_direction}`)}`, alertResults.change_promotion_efficiency_direction)}`;
        if(change_promotion_efficiency_txt !== ''){
            change_promotion_efficiency_txt = ', ' + change_promotion_efficiency_txt;
        }
        alert.blurb = parse(
            `${alertResults.filter_brand}'s promotion efficiency is $${alertResults.promotion_efficiency
            }${change_promotion_efficiency_txt}.`
        );
        alert.iconPath = iconpromotions;       
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'ECOSYSTEM_ALERT') {
        alert.title = 'Market Performance Alert';        
        let alertResults_category_sales_change_display_market_performance = valueCheck(alertResults.category_sales_change_direction) ? ` ${formatPercent(alertResults.category_sales_change)}%` : '';        
        let market_custom_category = (custom_category === "the categories") ? "The categories'" : custom_category ;
        alert.blurb = parse(
            `${market_custom_category} sales are ${blurbValuePrefix}${numberWith2Decimal(
                alertResults.category_sales
            )}${blurbValueSuffix}, ${txtColor(`${alertResults.category_sales_change_direction}${alertResults_category_sales_change_display_market_performance}`, `${alertResults.category_sales_change_direction}`)}.`
        );
        alert.iconPath = iconmarkettrends;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'GROWTH_ALERT') {
        alert.title = 'Growth Alert';
        alert.blurb = parse(
            `${alertResults.filter_brand} sales in ${custom_category} were
            ${valueCheck(alertResults.category_sales_change_direction)
                ? `${txtColor(`${alertResults.category_sales_change_direction} ${formatPercentWithoutSign(alertResults.category_sales_change)}%`, `${alertResults.category_sales_change_direction}`)}`
                : `${alertResults.category_sales_change_direction}`}        
            vs. the previous 4 weeks.`
        );
        alert.iconPath = icongrowthalert;
    }

    // ------------------------------------SMART ALERTS------------------------------------

    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'BRAND_TREND_ALERT') {
        alert.title = 'Brand Trend Alert';
        alert.blurb = parse(
            `${toTitleCase(alertResults.sales_change_brand)} in ${toTitleCase(alertResults.sales_change_category)} is 
                           <span style="color:${returnColorByDirection(
                alertResults.sales_change_direction
            )}">${alertResults.sales_change_direction}</span>
                ${valueCheck(alertResults.sales_change_direction)
                ? `<span style="color:${returnColorByDirection(
                    alertResults.sales_change_direction
                )}"> ${formatPercent(alertResults.sales_change)}%</span>`
                : ''}
                in sales this week vs. the previous 7 weeks.`
        );
        alert.iconPath = iconbrandtrend;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'PRICE_POSITION_TREND_ALERT') {
        alert.title = 'Price Position Trend Alert';
        alert.blurb = parse(
            `${toTitleCase(alertResults.filter_brand)} in ${toTitleCase(alertResults.filter_category)} is priced at the ${formatPercent(alertResults.price_percentile)}% percentile in ${toTitleCase(alertResults.top_market)}.`
        );
        alert.iconPath = iconpricepromotiontrend;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'MARKET_TREND_ALERT') {
        alert.title = 'Market Trend Alert';
        let alertResults_category_sales_change_display_market_performance = valueCheck(alertResults.category_sales_change_direction) ? ` ${formatPercent(alertResults.category_sales_change)}%` : '';
        alert.blurb = parse(
            `Market is 
            <span style="color:${returnColorByDirection(alertResults.sales_change_direction)}">${alertResults.sales_change_direction}</span>         
            <span style="color:${returnColorByDirection(alertResults.sales_change_direction)}"> 
            ${formatPercentWithoutSign(alertResults.sales_change_percent)}%</span> 
            in sales this week vs. the previous 7 weeks`
       
        );
        alert.iconPath = iconmarkettrendalert;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'ASSORTMENT_ACTION') {
       
        alert.title = 'Assortment Opportunity Alert';
        let duration = formatDateMMDDYYYY(alertResults.filter_duration.split('to')[1].trim());
        alert.blurb = parse(
            `There is ${coloredSpan(redColor, `$${numberWith2Decimal(alertResults.at_risk_dollar_impact)} at risk`)} and ${coloredSpan(greenColor, `$${numberWith2Decimal(alertResults.expand_dollar_impact)} in opportunities`)} to expand `
       
        );
        alert.iconPath = iconassortmentactionalert;
    }

    const navigate = useNavigate();
    
    const redirectToReport = async () => {
        navigate({
            pathname: '/my-reports/report-view',
            state: {
                userData: {reportSku: alert.productSku, reportParams: alert.actionConfig, actionId: alert.id},
                sourceUrl: '/dashboard/my_alerts'
            }
        });
    };

    return (
        <>
            <div className="card">
                <div className="card_image">
                    <img src={alert.iconPath} alt={alert.title} />
                    {/* <img src={reportIcon} alt={item.title} />  */}
                </div>
                <h2 className="card_heading_report">{alert.title}</h2>
                <p className="card_blurb">{alert.blurb}</p>                

                {viewReportAlertsSKU.includes(alert.productSku) &&
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '8fr 1fr 8fr'
                        }}>
                        <ByzzerButton
                            label={'See More'}
                            onClick={() => {
                                props.handleClick(alert);
                            }}
                        />

                        <span style={{
                            // fontSize: inherit,
                            fontWeight: 600,
                            color: '#000000', // $colorPrimaryPink,
                            width: '50%'
                            // cursor: pointer,
                            // borderBottom: 1px solid $colorPrimaryPink

                            // &:hover {
                            //   color: $colorPrimaryBlack
                            // }                          
                        }}> | </span>

                        <span>
                            <ByzzerLink
                                label={'View Report'}
                                onClick={() => redirectToReport()}
                            />
                        </span>
                    </div>
                }
                
                {!viewReportAlertsSKU.includes(alert.productSku) &&
                    <ByzButton
                        label={'See More'}
                        onClick={() => {
                            props.handleClick(alert);
                        }}
                    />
                }
            </div>
            {/* )} */}

        </>
    );
}

export default AlertCardView1_9;
