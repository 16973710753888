import './MyPPGs.scss';
import React, { forwardRef, memo, useEffect, useImperativeHandle } from 'react';
import { NavLink } from 'react-router-dom';
import { ByzzerMultiSelect, ByzzerSearch, ByzzerModal } from '@/components/form';
import format from 'date-fns/format';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { confirm } from '@/components/form/ByzzerModal';
import { TipIcon } from '@/components/icons';
import {useTenantApi} from '@/hooks/useTenantApi';
import { openPPGEditor } from '@/views/PPG/editor/PPGEditor';
import { ScorecardTableHeader as TableHeader } from '@/views/scorecard/ScorecardTableHeader';
import useState from 'react-usestateref';
import classnames from 'classnames';

export const MyPPGs = memo(
    // @ts-ignore
    forwardRef(({ className }, ref) => {
        const { deletePPG, getMyPPGs, getChrCodeDisplayMap } = useTenantApi();
        const rootClassNames = classnames(className, 'team-ppgs');
        const [filters, setFilters] = useState<Record<string, any>>({});
        const [filteredPpgs, setFilteredPpgs] = useState<any[]>([]);
        const [expanded1, setExpanded1] = useState(false);
        const [showSpinner1, setShowSpinner1] = useState(false);
        const [ppgs, setPPGs] = useState<any[]>([]);
        const [loading, setLoading] = useState(false);
        const [chrCodeDisplayMapping, setChrCodeDisplayMapping] = useState();
        const [statusInfo, setStatusInfo] = useState(false);
        const [sort, setSort, sortRef] = useState({
            by: 'title',
            direction: 1,
        });

        const baseClassName = 'ppg-list';

        useEffect(() => {
            loadPPGs();
            loadChrCodeDisplayMapping();
        }, []);

        useImperativeHandle(ref, () => ({
            async refresh() {
                return loadPPGs();
            },
        }));

        function toCharacteristicDisplay(codes) {
            return chrCodeDisplayMapping ? codes?.map((code) => chrCodeDisplayMapping?.[code]).join(', ') : '';
        }

        async function loadChrCodeDisplayMapping() {
            setChrCodeDisplayMapping(await getChrCodeDisplayMap());
        }

        async function loadPPGs() {
            try {
                setLoading(true);
                const loadedAndSortedPpgs = sortPpgs(sort.by, sort.direction, await getMyPPGs());
                setPPGs(loadedAndSortedPpgs);
                setFilteredPpgs(loadedAndSortedPpgs);
            } catch (err) {
                // todo: handle errors
            } finally {
                setLoading(false);
            }
        }

        function onCharacteristicsIconClick() {
            setStatusInfo(true);
        }
        async function onEdit(ppgId) {
            // @ts-ignore
            if (await openPPGEditor({ ppgId })) {
                loadPPGs();
            }
            // await alert({
            //     title: 'Coming Soon',
            //     content: <>
            //         <p>If this was implemented you would be editing the PPG with id {id}.</p>
            //         <p>But it's not so you're not.</p>
            //         <p>Remember, patience is a virtue.</p>
            //     </>,
            //     okLabel: 'Got It!'
            // })
        }

        async function onDelete(id) {
            if (
                await confirm({
                    title: 'Remove PPG List',
                    content: (
                        <>
                            <p>Are you sure you want to remove this PPG list?</p>
                        </>
                    ),
                    yesLabel: 'Yes, Remove',
                })
            ) {
                try {
                    setLoading(true);
                    await deletePPG(id);
                    loadPPGs();
                } catch (err) {
                    setLoading(false);
                }
            }
        }

        const getFocusCategories = () => {
            const allCategories = [...new Set(ppgs.map(({ categories }) => categories))].flat();

            return [...new Set(allCategories)]?.sort();
        };

        const getBuiltBy = () => {
            const allNames = [
                ...new Set(ppgs.map(({ createdBy: { firstName, lastName } }) => firstName + ' ' + lastName)),
            ].flat();
            return [...new Set(allNames)]?.sort();
        };

        const handleFilters = (value, source) => {
            setFilters({ ...filters, [source]: value });
        };

        useEffect(() => {
            let filteredData = ppgs;
            if (filters?.search) {
                filteredData = filterOnSearch(filteredData, filters?.search);
            }
            if (filters?.category) {
                filteredData = filterOnCategory(filteredData, filters?.category);
            }
            if (filters?.builtBy) {
                filteredData = filterOnCreator(filteredData, filters?.builtBy);
            }
            setFilteredPpgs([...new Set(filteredData)]);
        }, [filters]);

        const filterOnSearch = (ppgList, searchedValue) => {
            if (!(searchedValue?.length > 2)) return ppgList;

            let filteredOnSearch = ppgList;
            if (searchedValue?.length > 2) {
                filteredOnSearch = ppgList.filter((item) =>
                    item?.title.trim().toLowerCase().includes(searchedValue.trim().toLowerCase())
                );
            }

            return filteredOnSearch;
        };

        const filterOnCategory = (ppgList, userSelectedCategories) => {
            if (!(userSelectedCategories?.length > 0)) return ppgList;

            return ppgList.filter((item) => item.categories.some((cat) => userSelectedCategories.includes(cat)));
        };

        const filterOnCreator = (ppgList, userSelectedCreators) => {
            if (!(userSelectedCreators?.length > 0)) return ppgList;

            return ppgList.filter((item) =>
                userSelectedCreators.includes(item.createdBy.firstName + ' ' + item.createdBy.lastName)
            );
        };

        const sortPpgs = (by, direction, ppgList) => {
            let comparator;

            switch (by) {
                case 'title':
                case 'updatedDtm':
                    comparator = (a, b) => a[by].localeCompare(b[by], undefined, { sensitivity: 'base' }) * direction;
                    break;
                case 'categories':
                    comparator = (a, b) =>
                        a[by].join(', ').localeCompare(b[by].join(', '), undefined, { sensitivity: 'base' }) *
                        direction;
                    break;
                case 'createdBy':
                    comparator = (a, b) =>
                        (a[by].firstName + a[by].lastName).localeCompare(b[by].firstName + b[by].lastName, undefined, {
                            sensitivity: 'base',
                        }) * direction;
                    break;
                case 'type':
                    // comparator = (a, b) =>
                    //     (a[by] ?'Upload':'Characteristic Groups').localeCompare(b[by] ?'Upload':'Characteristic Groups', undefined, {
                    //         sensitivity: 'base',
                    //     }) * direction;
                    comparator = (a, b) => a[by].localeCompare(b[by], undefined, { sensitivity: 'base' }) * direction;
                    break;
                case 'characteristics':
                    comparator = (a, b) =>
                        toCharacteristicDisplay(a[by]).localeCompare(toCharacteristicDisplay(b[by]), undefined, {
                            sensitivity: 'base',
                        }) * direction;
                    break;
                default:
                    comparator = (a, b) => a[by].localeCompare(b[by], undefined, { sensitivity: 'base' }) * direction;
            }

            return [...ppgList].sort(comparator);
        };

        useEffect(() => {
            const { by, direction = 1 } = sortRef.current;
            setPPGs(sortPpgs(by, direction, ppgs));
            setFilteredPpgs(sortPpgs(by, direction, filteredPpgs));
        }, [sort]);

        const updateSort = (by?: any, direction?: any) => {
            if (sort.by === by) {
                // toggle sort direction
                setSort((prevState) => ({
                    by,
                    direction: prevState.direction * -1,
                }));
            } else {
                // use default asc order
                setSort({
                    by,
                    direction: 1,
                });
            }
        };

        const ppgTableHeaders = [
            { headerText: 'List Name', dataName: 'title' },
            { headerText: 'Category', dataName: 'categories' },
            { headerText: 'Built By', dataName: 'createdBy' },
            { headerText: 'Last Updated', dataName: 'updatedDtm' },
            { headerText: 'Set Up', dataName: 'type' },
            { headerText: 'Characteristics Used', dataName: 'characteristics' },
        ];

        return (
            <div className={rootClassNames}>
                <header className={'team-ppgs__header'}>
                    <h1 className={'team-ppgs__title'}>Your Team's PPGs</h1>
                    <div className={'team-ppgs__filters'}>
                        <ByzzerSearch
                            className={'team-ppgs__filter team-ppgs__search'}
                            placeholder={'Search by PPG name'}
                            type="ppgSearch"
                            expanded1={expanded1}
                            showSpinner1={showSpinner1}
                            onChange={(e) => handleFilters(e, 'search')}
                            name={'ppg-search'}
                        />
                        {/* @ts-ignore */}
                        <ByzzerMultiSelect
                            className={'team-ppgs__filter team-ppgs__filter--category'}
                            placeholder={'Filter by focus category'}
                            value={filters?.category}
                            options={getFocusCategories()}
                            multiSelectWithCheckBox
                            onChange={(e) => handleFilters(e, 'category')}
                            name={'focus-category'}
                        />
                        {/* @ts-ignore */}
                        <ByzzerMultiSelect
                            className={'team-ppgs__filter team-ppgs__filter--team-member'}
                            placeholder={'Built by'}
                            options={getBuiltBy()}
                            value={filters?.builtBy}
                            multiSelectWithCheckBox
                            onChange={(e) => handleFilters(e, 'builtBy')}
                            name={'built-by'}
                        />
                    </div>
                </header>
                <main className={'team-ppgs__content'}>
                    <ByzzerMask show={loading} loading={true}>
                        Loading your PPGs...
                    </ByzzerMask>
                    <table className={`${baseClassName}__table`}>
                        <thead className={`${baseClassName}__table-head`}>
                            <tr className={`${baseClassName}__table-row`}>
                                {ppgTableHeaders.map((header, index) => (
                                    // @ts-ignore
                                    <TableHeader
                                        key={header.dataName + index}
                                        sortActive={header.dataName === sort.by}
                                        sortDirection={sort.direction}
                                        onTitleClick={() => updateSort(header.dataName)}
                                        onSortClick={(direction) => updateSort(header.dataName, direction)}
                                        baseClass={baseClassName}
                                        tipComponent={
                                            header.dataName == 'characteristics' ? (
                                                // @ts-ignore
                                                <TipIcon theme={'dark'} onClick={onCharacteristicsIconClick} />
                                            ) : null
                                        }
                                    >
                                        {header.headerText}
                                    </TableHeader>
                                ))}
                                <th
                                    className={classnames(
                                        `${baseClassName + '-table-header'} ${baseClassName}__actions-header`
                                    )}
                                ></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPpgs.map(({ createdBy, ...ppg }) => (
                                <tr className={`${baseClassName}__value-row`} key={ppg.id}>
                                    <td className={`${baseClassName}__value-cell ${baseClassName}__name-cell`}>
                                        {ppg.title}
                                    </td>
                                    <td className={`${baseClassName}__value-cell ${baseClassName}__categories-cell`}>
                                        {ppg.categories.join(', ')}
                                    </td>
                                    <td className={`${baseClassName}__value-cell ${baseClassName}__created-by-cell`}>
                                        {createdBy.firstName} {createdBy.lastName}
                                    </td>
                                    <td className={`${baseClassName}__value-cell ${baseClassName}__modified-cell`}>
                                        <span className={`${baseClassName}__date`}>
                                            {format(new Date(ppg.updatedDtm), 'M/d/yyyy')}
                                        </span>
                                        &nbsp;
                                    </td>
                                    <td className={`${baseClassName}__value-cell ${baseClassName}__type-cell`}>
                                        {ppg.type=='UPC'?'Upload':'Characterstic Groups'}
                                    </td>
                                    <td
                                        className={`${baseClassName}__value-cell ${baseClassName}__characteristics-cell`}
                                    >
                                        {chrCodeDisplayMapping ? (
                                            toCharacteristicDisplay(ppg.characteristics)
                                        ) : (
                                            <div className={`${baseClassName}__chars-loading`}>Loading...</div>
                                        )}
                                    </td>
                                    {
                                        <td className={`${baseClassName}__actions-cell`}>
                                            { ppg.isEditable && 
                                                <>
                                                    <i
                                                        className={`${baseClassName}__action-icon ${baseClassName}__edit-icon`}
                                                        onClick={() => onEdit(ppg.id)}
                                                    />
                                                    <i
                                                        className={`${baseClassName}__action-icon ${baseClassName}__delete-icon`}
                                                        onClick={() => onDelete(ppg.id)}
                                                    />
                                                </>
                                            }
                                            <NavLink to={`/dashboard/ppg_report/${ppg.id}`}>
                                                <div className={classnames(`${baseClassName}__view`)}></div>
                                            </NavLink>
                                        </td>
                                    }

                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {chrCodeDisplayMapping && filteredPpgs?.length === 0 ? (
                        <div className={classnames(`${baseClassName}__no-ppgs-found`)}>
                            No PPGs found based on the search/filter criteria.
                        </div>
                    ) : null}
                </main>
                <ByzzerModal
                    show={statusInfo}
                    onClose={() => setStatusInfo(!statusInfo)}
                    closeOnClickOutside={true}
                    size={'small'}
                    heading={''}
                    type={'info'}
                    className={'customModal'}
                    headerType={'normal'}
                >
                    <p>
                        If the Type is “Characteristic”, the list of characteristics in this column are how the PPGs are
                        defined across the category. Every UPC in the category will be placed into a product group based
                        on its values for these characteristics.
                    </p>
                    <p>
                        If the Type is “UPC”, these characteristics apply to the remaining UPCs in the category that may
                        not have been included in the uploaded list. For example, if a user uploaded a list of PPGs that
                        included only their own brand's items, all competitive items in the category will be grouped to
                        the Characteristics listed in this column.
                    </p>
                </ByzzerModal>
            </div>
        );
    })
);
