import './ByzzerRadio.scss';
import classnames from 'classnames';

export function ByzzerRadio({
    children,
    label = children,
    name,
    value,
    placeholder,
    onChange,
    disabled,
    className,
    disabledTip,
    ...props
}) {
    return (
        <label
            className={classnames([
                `${className} byzzer-radio`,
                {
                    'byzzer-radio__disabled-tip': disabled && disabledTip,
                },
            ])}
            data-title={disabledTip}
        >
            <input type={'radio'} onChange={onChange} name={name} value={value} disabled={disabled} {...props} />
            <div className={disabled ? 'byzzer-radio__toggle-disabled' : 'byzzer-radio__toggle'} />
            {label && <span className={'byzzer-radio__label'}>{label}</span>}
        </label>
    );
}

export default ByzzerRadio;
