import './OptionalRequired.scss';
import classnames from "classnames";
import { HTMLAttributes, ReactNode } from "react";
import MarkdownEditor from '@uiw/react-markdown-editor';

export type OptionalRequiredProps = {
    required?: boolean;
    includeSuffix?: boolean;
    optionalSuffix?: ReactNode;
    requiredSuffix?: ReactNode;
    label?: ReactNode;
    isLabelBold?: boolean;
    defaultLabel?: ReactNode;
} & Partial<Omit<HTMLAttributes<HTMLDivElement>, 'defaultValue'>>; // Omitting defaultValue from HTMLAttributes to avoid confusion; defaultLabel is preferred

export function OptionalRequired({
    required = false,
    includeSuffix = false,
    isLabelBold = false,
    requiredSuffix = '',
    optionalSuffix = ' (Optional)',
    label,
    children = label,
    className,
    defaultLabel
}: OptionalRequiredProps) {

    const baseClassName = 'optional-required'

    const suffix: ReactNode = required ? requiredSuffix : optionalSuffix;

    const textChildrenWrappedWithSpan = ((children: ReactNode): ReactNode => {
        if (Array.isArray(children)) {
            return children?.map((child) => (typeof child === 'string' ? <span key={child}>{child}</span> : child));
        } else if (typeof children === 'string' && !children?.trim().length) {
            return defaultLabel;
        } else if (typeof children === 'string') {
            return (
                <MarkdownEditor.Markdown
                    className={classnames(baseClassName, className, {
                        [`${baseClassName}__bold-labels`]: isLabelBold,
                    })}
                    source={children}
                />
            );
        }
        return children ?? defaultLabel;
    })(children);

    return (
        <div className={classnames(baseClassName, className, {
            [`${baseClassName}--required`]: required,
            [`${baseClassName}--optional`]: !required,
        })}>
            {textChildrenWrappedWithSpan} {includeSuffix ? typeof suffix === 'string' ? <span className={`${baseClassName}__suffix`} >{suffix}</span> : suffix : ''}
        </div>
    )
}
