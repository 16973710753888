import { timePeriodOptionsForFolder } from '@/config/globalVars';
import {
    eachWeekOfInterval,
    subYears,
    eachMonthOfInterval,
    eachQuarterOfInterval,
    eachYearOfInterval,
    subMonths,
    subDays,
    subWeeks,
    differenceInCalendarWeeks,
    startOfWeekYear,
    endOfWeek,
    addYears,
    addWeeks,
    addMonths,
    nextSaturday,
    isSaturday,
    addMinutes,
    addQuarters,
    endOfQuarter,
    getDate,
    getDaysInMonth,
    format,
    eachWeekendOfMonth,
    lastDayOfMonth,
    getMonth,
    addDays,
    getYear, 
    getDay, 
    differenceInWeeks,
    lastDayOfQuarter,
    subQuarters,
    eachWeekendOfYear
} from 'date-fns';
import differenceInDays from 'date-fns/differenceInDays';
import { getTimperiodsForMonths } from './extractUtil';

export const maxWeeks = 156;

export const convertToUtc = (date) => {
    const dateVal = date.split('-');
    let DateVal = dateVal.length < 3 ? new Date(`${dateVal[0]}-01-01`) : new Date(`${dateVal[2]}-${dateVal[0]}-${dateVal[1]}`);

    let DateUtcVal = addMinutes(DateVal, DateVal.getTimezoneOffset());
    return DateUtcVal;
};

export const isDateValid = (date) => {
    return date !== undefined && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
};

export const getWeekCount = (val) => {
    const currMonth = getMonth(val) + 1;
    let currWeekCount;
    if (currMonth % 3 === 0) {
        currWeekCount = 5;
    } else {
        currWeekCount = 4;
    }
    return currWeekCount;
};

export const formatTimePeriodDataForSelection = (key, data) => {
    return data
        .sort((a, b) => b - a)
        .map((val) => {
            let mainVal;
            if (key === '1') {
                mainVal = val;
            } else if (key === '2') {
                mainVal = format(val, 'MM-dd-yyyy');
            } else if (key === '3') {
                mainVal = format(val, 'MM-dd-yyyy');
            } else if (key === '4') {
                mainVal = val;
            } else if (key === '5') {
                mainVal = format(val, 'MM-dd-yyyy');
            } else if (key === '6') {
                mainVal = format(val, 'MM-dd-yyyy');
            }
            return mainVal;
        });
};
export const formatTimePeriodData = (key, data, type = 'selected') => {
    return data
        .sort((a, b) => b - a)
        .map((val) => {
            let mainVal;
            if (key === '1') {
                mainVal = val;
            } else if (key === '2') {
                mainVal = format(val, 'MM-dd-yyyy');
            } else if (key === '3') {
                mainVal = format(val, 'MM-dd-yyyy');
            } else if (key === '4') {
                if(type === 'all'){
                    let lastWeek = val.date;
                    mainVal = format(lastWeek, 'MM-dd-yyyy');
                }else {
                    mainVal = val;
                }
            } else if (key === '5') {
                let currentValue = endOfQuarter(val);
                let currWeekCount = getWeekCount(currentValue);
                const getWeekendOfYear = eachWeekendOfMonth(currentValue);
                const weeksInAMonth = getWeekendOfYear.filter((val1, indx) => {
                    return isSaturday(val1);
                });
                let lastSaturday = weeksInAMonth[weeksInAMonth.length - 1];
                if (weeksInAMonth.length < currWeekCount) {
                    lastSaturday = addWeeks(lastSaturday, 1);
                }
                mainVal = format(lastSaturday, 'MM-dd-yyyy');
            } else if (key === '6') {
                mainVal = format(val, 'yyyy');
                const currentYear = convertToUtc(`12-01-${mainVal}`);
                const getWeekendOfYear = eachWeekendOfMonth(currentYear);
                let lastSaturday: any = getWeekendOfYear.filter((val1, indx) => {
                    return isSaturday(val1)
                });
                lastSaturday = lastSaturday[lastSaturday.length - 1];
                const differenceOfYearDates = differenceInDays(lastDayOfMonth(lastSaturday), lastSaturday);
                if (differenceOfYearDates > 3) {
                    lastSaturday = addWeeks(lastSaturday, 1);
                }
                mainVal = format(lastSaturday, 'MM-dd-yyyy');
            }
            return mainVal;
        });
};
export const getOptionsForSelectedFolder = (
    key,
    maxDate,
    type = 'selected',
    extendedDataYears = 2,
    futureDaysToAdd = 0
) => {
    let selectionType = type;
    if(key !== '4'){
        selectionType = type === 'all' ? 'selected' : type
    }
    // converting local time to utc for end date
    var endDate = new Date(maxDate);
    var endDateUtc = new Date(addMinutes(endDate, endDate.getTimezoneOffset()));

    // converting local time to utc for start date
    var now = new Date();
    var now_utc = new Date(addMinutes(now, now.getTimezoneOffset()));

    let result;
    let startDate = subWeeks(maxDate ? endDateUtc : now_utc, getYearsFromSubscriptionData(extendedDataYears) * 52);
    startDate = isSaturday(startDate) ? startDate : nextSaturday(startDate);

    const firstWeekDate = endOfWeek(startOfWeekYear(startDate));
    const maxDateUTC = endDateUtc;
    const curDateUTC = now_utc;
    let myNewEndDate;
    switch (key) {
        case '2':
            // weeks
            startDate = addWeeks(startDate, 1);
            result = eachWeekOfInterval(
                {
                    start: startDate,
                    end: maxDate ? maxDateUTC : subDays(curDateUTC, 9),
                },
                {
                    weekStartsOn: 6,
                }
            );
            if (selectionType === 'selection') {
                return formatTimePeriodDataForSelection(key, result);
            }
            return formatTimePeriodData(key, result);
        case '3':
            // 4 weeks
            startDate = addWeeks(startDate, 1);
            result = eachWeekOfInterval(
                {
                    start: startDate,
                    end: maxDate ? maxDateUTC : curDateUTC,
                },
                {
                    weekStartsOn: 6,
                }
            );
            const everyNth = (arr, nth) => arr.filter((e, i) => i % nth === nth - 1);
            if (selectionType === 'selection') {
                return formatTimePeriodDataForSelection(key, everyNth(result, 4));
            }
            return formatTimePeriodData(key, everyNth(result, 4));
        case '4':
            // months
            myNewEndDate = addDays(endDateUtc, futureDaysToAdd);
            const endDate = futureDaysToAdd > 0 ? myNewEndDate : maxDate ? maxDate : now;
            result = getTimperiodsForMonths(endDate, getYearsFromSubscriptionData(extendedDataYears));
            if (selectionType === 'selection') {
                return formatTimePeriodDataForSelection(key, result);
            }
            return formatTimePeriodData(key, result, selectionType);
        case '5':
            // quarters
            const endOfQrtrDate = endOfQuarter(startDate);
            startDate = addQuarters(endOfQrtrDate, 2);
            myNewEndDate = addDays(endDateUtc, futureDaysToAdd);
            const quarterIncreaseDateMargine = futureDaysToAdd > 0 ? myNewEndDate : endDateUtc ? differenceInDays(lastDayOfQuarter(maxDateUTC),maxDateUTC) === 0 ? maxDateUTC : subQuarters(maxDateUTC, 1) : curDateUTC;
            // quarterIncreaseDateMargine.setDate(quarterIncreaseDateMargine.getDate() + 1)
            result = eachQuarterOfInterval({
                start: startDate,
                end: quarterIncreaseDateMargine,
            });
            if (selectionType === 'selection') {
                return formatTimePeriodDataForSelection(key, result);
            }
            return formatTimePeriodData(key, result);
        case '6':
            // years
            myNewEndDate = addDays(endDateUtc, futureDaysToAdd);
            const differenceInStartWeeks = differenceInCalendarWeeks(startDate, firstWeekDate);
            // if (differenceInStartWeeks > 0) {
            //     startDate = addYears(firstWeekDate, 1);
            // }
            const getWeekendOfYear = eachWeekendOfYear(myNewEndDate);
            const weeksInAMonth = getWeekendOfYear.filter((val1, indx) => {
                return isSaturday(val1);
            });
            let lastSaturday = weeksInAMonth[weeksInAMonth.length - 1];
            const yearIncreaseDateMargine = futureDaysToAdd > 0 ? myNewEndDate : endDateUtc ? differenceInDays(lastSaturday,maxDateUTC) === 0 ? maxDateUTC : subYears(maxDateUTC, 1)  : subYears(curDateUTC, 1);
            yearIncreaseDateMargine.setDate(yearIncreaseDateMargine.getDate() + 1)
            result = eachYearOfInterval({
                start: startDate,
                end: yearIncreaseDateMargine,
            });
            if (selectionType === 'selection') {
                return formatTimePeriodDataForSelection(key, result);
            }
            return formatTimePeriodData(key, result);
        default:
            break;
    }
};


export const getListOfDatesForCustomDate = (formData) => {
    const { period, history, latestEndDate, customEndDate } = formData;
    var endDate = new Date(latestEndDate || customEndDate);
    var endDateUtc = new Date(addMinutes(endDate, endDate.getTimezoneOffset()));
    let startDate = subWeeks(endDate, history);
    startDate = isSaturday(startDate) ? startDate : nextSaturday(startDate);
    let result;
    /**
     * Added this check to avoid timezone issue
     */
    const ONE_WEEK_IN_MS = 7*24*3600*1000;
    if(endDate.getTime() - startDate.getTime() > ONE_WEEK_IN_MS) {
        startDate = addWeeks(startDate, 1);
    }
    result = eachWeekOfInterval(
        {
            start: startDate,
            end: endDate,
        },
        {
            weekStartsOn: 6,
        }
    );
    const everyNth = (arr, nth) => arr.filter((e, i) => i % nth === nth - 1);
    return formatTimePeriodData('3', everyNth(result, period));
};
export const getYearsFromSubscriptionData = (extendedDataYears = 3) => {
    return extendedDataYears;
};

export function calculateYtdWeeksForDate(givenDate: Date): number {
    const currentYear = getYear(givenDate);
    const startDate = new Date(currentYear + '/01/01'); //  FIND The first saturdayt of the year, see if its before/after 4th, if so use that sat, if not, use next sat****
    let adjStartDate = new Date(JSON.parse(JSON.stringify(startDate)));

    if (isSaturday(startDate) || isSaturday(addDays(startDate, 1)) || isSaturday(addDays(startDate, 2))) {
        if (getDay(startDate) == 6) {
            adjStartDate = addDays(startDate, 7);
        }
        if (getDay(addDays(startDate, 1)) == 6) {
            adjStartDate = addDays(startDate, 7);
        }
        if (getDay(addDays(startDate, 2)) == 6) {
            adjStartDate = addDays(startDate, 7);
        }
    }
    if (isSaturday(addDays(startDate, 3)) || isSaturday(addDays(startDate, 4)) || isSaturday(addDays(startDate, 5) || isSaturday(addDays(startDate, 6)))) {
        if (getDay(addDays(startDate, 3)) == 6) {
            adjStartDate = addDays(startDate, 3);
        }
        if (getDay(addDays(startDate, 4)) == 6) {
            adjStartDate = addDays(startDate, 4);
        }
        if (getDay(addDays(startDate, 5)) == 6) {
            adjStartDate = addDays(startDate, 5);
        }
        if (getDay(addDays(startDate, 6)) == 6) {
            adjStartDate = addDays(startDate, 6);
        }
    }

    const selectionStartDate = format((adjStartDate), 'yyyy/MM/dd');
    const newEndDate = addDays((givenDate), 1);
    const selectionEndDate = format(newEndDate, 'yyyy/MM/dd');

    return differenceInWeeks(new Date(selectionEndDate), new Date(selectionStartDate)) + 1;
}