import { useEffect, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Arrow from '~@images/Arrow.svg';
import closeIcon from '@images/icons/close-icon.svg';
import './ByzzerPDFViewer.scss';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { ByzzerButton } from '@byzzer/ui-components';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type PDFFile = string | File | null;
const BASE_CLASS_NAME = 'pdf-viewer';
export function ByzzerPDFViewer({handlePDFModal}) {
    const [file, setFile] = useState<PDFFile>('./Byzzer-Powered-by-NielsenIQ-Overview.pdf');
    const [pageNum, setPageNum] = useState<number>(1);
    const [numberOfPages, setNumberOfPages] = useState<number>();

    function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
        setNumberOfPages(nextNumPages);
    }

    return (
        <div className={`${BASE_CLASS_NAME}__container`}>
           <ByzzerButton
                label={<img src={closeIcon} alt="Close" />}
                disabled={false}
                onClick={() => handlePDFModal(true)}
                className={"pdf-close-button"}
                
            />
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                <Page
                    key={`page_${pageNum}`}
                    pageNumber={pageNum}
                    // width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                />
            </Document>
            <div className={`${BASE_CLASS_NAME}__controls`}>
                <ByzzerButton
                    label={<img src={Arrow} alt="Previous" />}
                    disabled={pageNum === 1}
                    onClick={() => setPageNum(pageNum - 1)}
                    className={"previous-button"}
                />
                <ByzzerButton
                    label={<img src={Arrow} alt="Next" />}
                    disabled={pageNum === numberOfPages}
                    onClick={() => setPageNum(pageNum + 1)}
                />
            </div>
        </div>
    );
}

export default ByzzerPDFViewer;
