import React, { useState, useEffect } from "react";
import classnames from 'classnames';
import './SubscriptionSimulators.scss';
import {useBetterNavigate} from '@/utils';
import { SimulatorCalculation, SimulatorChartConfig, SimulatorDatatype, SimulatorInputConfig, SimulatorInputGroup, SimulatorLeverConfig, SimulatorTableConfig } from "../../../types/SimulatorTypes";
import { useApp, useUser } from "@/contexts/UserContext";

export type MySimulatorsProps = {
    data?: any;
}

export type AvailableSimulator = {
    id?: number;
    sku?: string;
    datasetSku: string;
    title?: string;
    calculations: SimulatorCalculation[],
    chartConfig: SimulatorChartConfig,
    inputGroups: SimulatorInputGroup[];
    leverConfigs: SimulatorLeverConfig[];
    staticValues: SimulatorInputConfig[];
    tableConfigs:  SimulatorTableConfig[];

    runConfigOptions: any;
    // TODO - See where these come in, dont yet exist
    thumbnailUrl?: string;  // aka thumbnail_url??
    description?: string;
    features?: any[];
    // type?: string;
    // favorited?: boolean;
};

export const SubscriptionSimulators = ({
    ...props
}: MySimulatorsProps) => {

    const baseClassName = 'byz-my-simulators';

    const navigate = useBetterNavigate();
    const {user, subscription, reloadSubscription, subscribedSkus, admins, features} = useUser();
    const {allProducts} = useApp();
    const [allSimulators, setAllSimulators] = useState<any>([]);
    const [allSkus, setAllSkus] = useState<any[]>([]);
    const [skuFilter, setSkuFilter] = useState<any[]>([]);
    const [simulators, setSimulators] = useState<any[]>([]);
    const [filteredSimulators, setFilteredSimulators] = useState<ProductWithMeta[]>([]);

    useEffect( () => {
        if (allProducts) {
            const simulatorProducts = allProducts.filter((product) => product.type === 'simulator'); // pulls 'simulator' type products from allProducts...
            const allSku = simulatorProducts.map(({ sku }) => sku);
            setAllSimulators(simulatorProducts.sort((a, b) => a.title.localeCompare(b.title, 'en', { sensitivity: 'base' })));
            setAllSkus(allSku);
            if (skuFilter) {
                setSkuFilter(allSku);
            }
        }
    }, [allProducts]);

    useEffect(() => {
        if (simulators && skuFilter) {
            filterBySkus(skuFilter);
        }
    }, [simulators, skuFilter]);

    useEffect(() => {
        if (subscribedSkus && allSimulators) {
            setSimulators(allSimulators.filter(({type, sku}) => subscribedSkus.includes(sku) || type === 'simulator'));
        }
    }, [subscribedSkus, allSimulators]);

    function filterBySkus(skus) {
        const filteredReportsList = simulators.filter(({sku}) => skus?.includes(sku));
        setFilteredSimulators(filteredReportsList);
    }

    function validateAndShow(simulator: ProductWithMeta): boolean {
        const canAccessSimulator = subscribedSkus?.includes(simulator?.sku) || features?.enableSimulatorAccess;  // TODO: Expand and improve logic
        return canAccessSimulator;
    }

    function handleSimulatorClick(simulator: ProductWithMeta) {
        const canAccessSimulator = validateAndShow(simulator); // placeholder function to validate the user can still access a simulator

        if (canAccessSimulator) {  
            navigate(`/dashboard/configure_simulator`, {
                params: {
                    sku: simulator.sku,
                    datasetSku: simulator.metadata.datasetSku,
                    id: simulator.id
                },
            });
        }
    }


    return (
        <div className={classnames(`${baseClassName}`)}>
            <header className={classnames(`${baseClassName}__header`)}>
                <p>Select which type of scenario you'd like to run.</p>
            </header>
           <main className={classnames(`${baseClassName}__content`)}> {/* todo: add support for groups, similar to reports */}
                <div className={classnames(`${baseClassName}__simulator-cards`)}>
                    {Boolean(filteredSimulators?.length) && filteredSimulators?.map((simulator) => { // todo: make into standalone component
                        return (
                            <div key={`${simulator.title} ${simulator.sku}`} className={classnames(`${baseClassName}__simulator-card`)} onClick={() => handleSimulatorClick(simulator)}>
                                <div className={classnames(`${baseClassName}__simulator-card-thumbnail`, {
                                    [`${baseClassName}__simulator-card-thumbnail--locked`]: !subscribedSkus.includes(simulator.sku) && !features?.enableSimulatorAccess
                                })}
                                     style={{
                                         [`--thumbnail-image`]: `url(${simulator.thumbnailUrl ?? 'https://cdn.byzzer.com/images/thumbnails/simulator-placeholder.png'})`
                                     } as React.CSSProperties} >
                                    <span className={classnames(`${baseClassName}__simulator-card-thumbnail-image`)}
                                         />
                                    {/* {Boolean(simulator.features?.length) && (
                                        <span className={classnames(`${baseClassName}__simulator-card-thumbnail-badge`)}>
                                            {simulator.features?.[0]}
                                        </span>
                                    )} */}
                                </div>
                                <div className={classnames(`${baseClassName}__simulator-card-header`)}>
                                    <div className={classnames(`${baseClassName}__simulator-card-title`)}>
                                        <p>{simulator.title}</p>
                                    </div>
                                </div>
                                <div className={classnames(`${baseClassName}__simulator-card-description`)}>
                                    <div>{simulator.description}</div> {/* TODO: add favorites and info icon like reports have */}
                                </div>
                            </div>
                        )
                    })}
                </div>
           </main>
        </div>
    );

};

export default SubscriptionSimulators;

SubscriptionSimulators.displayName = 'SubscriptionSimulators';