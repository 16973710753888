import './TenantPicker.scss';
import { TenantSelector } from "@/components/TenantSelector";

export type TenantPickerProps = {};

export const TenantPicker = ({
    ...props
}: TenantPickerProps) => {


    const baseClassName = 'tenant-picker';


    return (
        <div className={baseClassName}>
            <header className={`${baseClassName}__header`}>
                <h1 className={`${baseClassName}__title`}>Select a Company</h1>
            </header>
                <TenantSelector/>
        </div>
    );

};

export default TenantPicker;

TenantPicker.displayName = 'ChooseCompany';
