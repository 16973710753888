import { FC, ReactElement } from 'react';
import lockIcon from '@/images/icons/storyIcons/lock.svg';
import { ByzzerCheckbox } from '@byzzer/ui-components';
import { StoryObjectiveSelection } from '@/types/StoriesTypes';

const StoryObjectiveSelections: FC<StoryObjectiveSelection> = ({
    className,
    disabled,
    objective,
    displayValue = true,
    tagRequired = true,
    enableTooltip = false,
}): ReactElement => {


    const baseClass = 'story-objective';

    return (
        <>
                <label className={baseClass}>

                    <ByzzerCheckbox
                        label={objective.objectiveName}
                        disabled={disabled}
                        value={objective.objectiveName}
                        checked={objective.isChecked === 'checked'}
                        onChange={undefined}
                    />

                    {enableTooltip && <span className="tooltiptext">{objective.displayMsg}</span>}

                    <div className={`${baseClass}__serialNo`}>
                        {displayValue ? (
                            <p className={objective.serialNo ? `${className}__story-objective__selection__selected` : ''}>{objective.serialNo}</p>
                        ) : (
                            <img src={lockIcon} className={'story-lock-icon'} />
                        )}
                    </div>

                </label>

                {tagRequired && objective.tag && objective.tag.trim !== '' && (
                    <span className={`${baseClass}__smart`}>{objective.tag}</span>
                )}
        </>
    );
};

export default StoryObjectiveSelections;