import { defaultConfigs, selectorLabels } from '@/config/globalVars';

export const storySelectorAttributes = (attributeOptions = []) => {
  // Possible option lists:
  // product:option1
  // market:option1
  // timePeriod:default
  // featuredCharacteristic:option1
  // comparisonMarket:option1
  // panelTimePeriod:default
  // More options will be added on the go
  const attrReqOptions = attributeOptions || defaultConfigs.story;
  let totalAttrObj = [];
  attrReqOptions.forEach((opt) => {
    totalAttrObj.push(setStorySelectorAttributes(opt))
  })

  return totalAttrObj;
}

const productDescription = `The focus product controls the universal products across all slides. 
If you want to filter the entire presentation to a subset of the category or categories, use the characteristic filters to do so.`
const productManufacturerDescription = `The focus product controls the universal products across all slides. 
Change the level to view for the manufacturer or across a department. If you want to filter the entire presentation to a subset of 
the category or categories, use the characteristic filters to do so.`
const brandDescription = `Rename the brand to something relevant for your selection and will fit in your presentation. 
For example, if you selected a whole brand, Jane's Cookies LLC you might want to rename it to "Jane's Cookies".`;
const categoryDescription = `Rename the product to something that will be relevant to you and the retailer. For example, the categories like Baby 
Food and Infant Formula with characteristic filter Organic would be renamed to "Organic Baby Feeding".`
const characteristicAliasDescription = `Rename the character to something that will be relevant to you and the retailer.`
const marketDescription = `Pick the market you want to feature across the entire presentation. You can select a retailer company (like Albertsons Companies Total) and all 
the banners will automatically be pulled in. Instead, you can also pick a specific banner such as Albertsons Companies Acme division or a few banners like 
Albertsons Companies Acme and Albertsons Companies Shaws to reflect where you are distributed.`;
const outletChannelDescription = `Pick the outlet you want to feature across the entire presentation. You can select one or multiple channels (like Club) and all the outlets 
will automatically be pulled in. Instead, you can also pick a specific or multiple outlets such as WHOLE FOODS MARKET to reflect where you are distributed.`;
const characteristicDescription = `The characteristic you choose will be used for the characteristic importance objective. The slide will highlight category 
sales at the retailer for each value of the characteristic. For example, if you select the characteristic flavor the slide will show the sales and growth for 
the top flavors.`;
const comparisonMarketDescription = `The comparison market you choose will be used for the performance for retailer vs comparison objective. The slides will 
display the trend for the comparison market vs. the focus market and the top items in the comparison market. The comparison market will automatically default 
to the remaining FMCG market for your focus retailer but can be updated to just the channel specific remaining market (e.g. remaining grocery) or a total US channel 
if you want a broader comparison set.`;
const brandManufacturerAliasDescription = `Rename the brand/manufacturer to something relevant for your selection and will fit in your presentation. 
For example, if you selected a whole manufacturer, Jane's Cookies LLC you might want to rename it to "Jane's Cookies".`
const marketAliasDescription = `Rename the market to something that will be relevant to you. For example, the retailer Albertsons Companies Total 
Company could be renamed to "Albertsons"`;
const outletChannelAliasDescription = `Rename the market to something that will be relevant to you. For example, the outlet WHOLE FOODS MARKET could be renamed to "Whole Foods"`;
const comparisonMarketAliasDescription = `Rename the Comparison market to something that will be relevant to you. For example, the retailer 
Albertsons Companies Total Company Competitive FMCG Retailers could be renamed to "Albertsons Competitive"`;
const timePeriodDescription = `Select a time period which is relevant for your retailer conversation. Typically the Latest 52 Weeks
or a custom period of 52 Weeks ending at the latest retailer quarter is selected if you have an annual
retailer review. If you meet with the retailer quarterly, you can use a shorter time period.`;


const marketBehavior = { 
  // categ review focus market
  option1: {
    'preSelect_RemainingMarkets': false,
    'hide_Lv1_Markets_if_lv2_selected': true,
    'hide_Lv2_Markets_if_lv1_selected': true
    },
  // categ review comparison market
  option1a: {
    'preSelect_RemainingMarkets': true,
    'hide_Lv1_Markets_if_lv2_selected': false,
    'hide_Lv2_Markets_if_lv1_selected': false
    },
    // categ management  
  option2: {
    'preSelect_RemainingMarkets': false,
    'hide_Lv1_Markets_if_lv2_selected': false,
    'hide_Lv2_Markets_if_lv1_selected': false
  }
};

export const setStorySelectorAttributes = (optionSet) => {
  let selectorAttrObj = [];
  // ------------------------------------------ STORIES CONFIGS ------------------------------------------
  switch (optionSet) {
    case 'product:option1':
    // Category Review Story
    selectorAttrObj = {
        selectorLabelName: selectorLabels.product,
        selectorType: 'product',
        subSelectors: { // Focus Brand Report : Option 1
          subSelectorLabelId: 'Focus Brand Story : Option 1', // Just to distinguish, not used in code
          subSelectorType: 'focusBrandStory', // This property is validated in code to identify which type of selector is to be rendered,added new case focusBrandOptionalStory which should be used in case where focus brand is	
          subSelectorDescription: {
            product: productDescription,
            categoryName: categoryDescription
          },
          focusBrand: true,
          multiBrand: false,
          competitiveProductSet: true,
          checkCharacterLimit: true,
          characteristicFilters: {
            multi: true,
            maxLimit: 5
          }
        }
      }
      break;
    case 'product:option2':
    // Shopper Snapshot story
      selectorAttrObj = {
        selectorLabelName: selectorLabels.product,
        selectorType: 'product',
        subSelectors: {
            // No Focus Brand report : Option 2
            subSelectorLabelId: 'No Focus Brand report : Option 2', // Just to distinguish, not used in code
            subSelectorType: 'noFocusBrandReport', // This property is validated in code to identify which type of selector is to be rendered
            subSelectorDescription: {
              product: `Focus products control the universal products across all slides.`,
              categoryName: categoryDescription
            },
            focusBrand: false,
            //adhocFocusBrand:true,
            competitiveProductSet: true,
            characteristicFilters: {
                multi: true,
                maxLimit: 5,
            },
        },
    };
    break;
    case 'product:option7':
    // Category Mngmt Story
      selectorAttrObj = {
        selectorLabelName: selectorLabels.product,
        selectorType: 'product',
        subSelectors: { // Focus Brand Report : Option 1
          subSelectorLabelId: 'Focus Brand Story : Option 1', // Just to distinguish, not used in code
          subSelectorType: 'focusBrandOptionalStory', // This property is validated in code to identify which type of selector is to be rendered	
          subSelectorDescription: {
            product: productDescription,
            categoryName: categoryDescription
          },
          focusBrand: true,
          multiBrand: true,
          competitiveProductSet: true,
          checkCharacterLimit: true,
          characteristicFilters: {
            multi: true,
            maxLimit: 5
          }
        }
      }
      break;
    case 'product:option4':
      // pricing and promotion story
      selectorAttrObj = {
        selectorLabelName: selectorLabels.product,
          selectorType: 'product',
          subSelectors: {
              subSelectorLabelId: "Focus Brand Story : Option 1",
              subSelectorType: "focusBrandReport",
              subSelectorDescription: {
                product: productDescription,
                categoryName: categoryDescription,
                brandName: brandDescription
              },
              focusBrand: true,
              multiBrand: true,
              ppgReport: true,
              competitiveProductSet: true,
              checkCharacterLimit: true,
              characteristicFilters: {
                multi: true,
                maxLimit: 5
              }
          }
      }
      break;
    case 'market:option2c':
      selectorAttrObj = {
        selectorLabelName: selectorLabels.focusMarket,
        selectorType: 'market',
        subSelectors: {
          // Select multiple markets report: Option 2
          subSelectorLabelId: 'Select multiple markets story: Option 2c', // Just to distinguish, not used in code
          subSelectorType: 'smartStoryMarkets', // This property is validated in code to identify which type of selector is to be rendered
          subSelectorDescription: {
            market: marketDescription,
            marketAlias: marketAliasDescription
          },
          markets: {
            marketGroup: 'rms',
            multi: true,
            //maxLimit: 10, //changed to no max limit
            showSelectedMarketsCount: true,
            showRemainingMarketsEnabled: false,
            showRemainingMarketsSwitch: false,
            marketBehavior: marketBehavior.option1,
            
            marketSections: {
              embodyTotalMarkets: false,
              embodyFmcgRetailers: true,
              embodySpecialityRetailers: true,
              embodyGeographyMarkets: false,
            },
            checkCharacterLimit: true,
          }
        },
      };
      break;
    case 'product:option3':
      // Brand Review Story
      selectorAttrObj = {
        selectorLabelName: selectorLabels.product,
        selectorType: 'product',
        subSelectors: { // Focus Brand Report : Option 1
          subSelectorLabelId: 'Focus Brand Story : Option 1', // Just to distinguish, not used in code
          subSelectorType: 'focusBrandManufacturerStory', // This property is validated in code to identify which type of selector is to be rendered	
          subSelectorDescription: {
            product: productManufacturerDescription,
            categoryName: categoryDescription,
            brandManufactureName: brandManufacturerAliasDescription
          },
          focusBrand: true,
          multiBrand: true,
          competitiveProductSet: true,
          brandManufacturer: true,
          checkCharacterLimit: true,
          characteristicFilters: {
            multi: true,
            maxLimit: 5
          }
        }
      }
      break;
      case 'product:option5':
        // categoryTrendAnalysis Story
          selectorAttrObj = {
            selectorLabelName: selectorLabels.trend,
            selectorType: 'product',
            subSelectors: {
                // No Focus Brand report : Option 2
                subSelectorLabelId: 'No Focus Brand report : Option 2', // Just to distinguish, not used in code
                subSelectorType: 'noFocusBrandReport', // This property is validated in code to identify which type of selector is to be rendered
                subSelectorDescription: {
                  product: `Focus products control the universal products across all slides.`,
                  categoryName: categoryDescription,
                  characteristicaliaslabelname:characteristicAliasDescription
                },
                focusBrand: false,
                //adhocFocusBrand:true,
                competitiveProductSet: true,
                characteristicFilters: {
                  required: true,
                  alwaysPositiveLookup: true,
                  multi: true,
                  maxLimit: 1,
                },
               
            },
        };
      break;
    case 'shopperSegment: option1':
      selectorAttrObj = {
        datatype: "cps",
        selectorLabelName: "Select Shopper Segment",
        selectorType: "shopper_segment"
      }
      break;
    case 'market:option1':
      selectorAttrObj = {
        selectorLabelName: selectorLabels.focusMarket,
        selectorType: 'market',
        subSelectors: {
          // Select Focus Market: Option 1
          subSelectorLabelId: 'Focus Market story: Option 1', // Just to distinguish, not used in code
          subSelectorType: 'focusMarketStory', // This property is validated in code to identify which type of selector is to be rendered
          subSelectorDescription: {
            market: marketDescription,
            marketAlias: marketAliasDescription
          },
          markets: {
            marketGroup: 'rms',
            multi: true,
            //maxLimit: 5,
            showSelectedMarketsCount: true,
            showRemainingMarketsEnabled: false,
            showRemainingMarketsSwitch: false,
            marketBehavior: marketBehavior.option1,
            
            marketSections: {
              embodyTotalMarkets: false,
              embodyFmcgRetailers: true,
              embodySpecialityRetailers: true,
              embodyGeographyMarkets: false,
            },
            checkCharacterLimit: true,
          },
        },
      };
      break;
    case 'market:option3':
    // Shopper Snapshot story
      selectorAttrObj = {
        selectorLabelName: selectorLabels.channelOutlets,
        selectorType: 'market',
        subSelectors: {
          // CPS Markets report: Option 3
          subSelectorLabelId: 'CPS Markets report: Option 3', // Just to distinguish, not used in code
          subSelectorType: 'cpsMarketsReport', // This property is validated in code to identify which type of selector is to be rendered
          subSelectorDescription: {
            market: outletChannelDescription,
            marketAlias: outletChannelAliasDescription
          },
          markets: {
            showSelectedMarketsCount: true,
            
            marketGroup: 'cps',
            multi: true,
            maxLimit: 20,
            marketSections: {
                embodyPanelTotal: true,
                embodyPanelByChannel: true,
            },
          },
      },
    };
    break;
    case 'market:option6':
    // Category Review Story
        selectorAttrObj = {
            selectorLabelName: selectorLabels.focusMarket,
            selectorType: 'market',
            subSelectors: {
              subSelectorLabelId: 'Focus Market story: Option 6', // Just to distinguish, not used in code
              subSelectorType: 'focusMarketStory', // This property is validated in code to identify which type of selector is to be rendered
              subSelectorDescription: {
                market: marketDescription,
                marketAlias: marketAliasDescription
              },
              markets: {
                marketGroup: 'rms',
                multi: false,
                //maxLimit: 5,
                showSelectedMarketsCount: true,
                showRemainingMarketsEnabled: false,
                showRemainingMarketsSwitch: false,
                marketBehavior: marketBehavior.option1,
                comparativeMarketsRequired: false,
                // allowedMarketLevels: 3,
                marketSections: {
                    embodyTotalMarkets: true,
                    embodyFmcgRetailers: true,
                    embodySpecialityRetailers: true,
                    embodyGeographyMarkets: true,
                },
                checkCharacterLimit: true,
                showAllMarkets: true,
                warnSelectMarketInPairs: false
              },
            },
        };
        break;
    case 'market:option7':
    // Category Mngmt Story
      selectorAttrObj = {
        selectorLabelName: selectorLabels.focusMarket,
        selectorType: 'market',
        subSelectors: {
          // Select Focus Market: Option 1
          subSelectorLabelId: 'Focus Market story: Option 7', // Just to distinguish, not used in code
          subSelectorType: 'smartReportMarkets', //'focusMarketStory', // This property is validated in code to identify which type of selector is to be rendered
          subSelectorDescription: {
            market:`Select one account and one remaining market for it. A remaining market must be chosen for this story.`,
            marketAlias: marketAliasDescription
          },
          markets: {
            marketGroup: 'rms',
            multi: true,
            maxLimit: 2,
            requiredRemMarketForEachRetailer: true,
            requiredRemMarketForTotalMarkets: true, // enables comparative market in total US Market section
            smartAccount: true,
            comparativeMarketsRequired: true,
            showSelectedMarketsCount: true,
            showRemainingMarketsEnabled: true,  // enables remaining markets in search and toggle is on
            showRemainingMarketsSwitch: true,
            marketBehavior: marketBehavior.option2,
            
            showAllMarkets: false,  // hides "Total FMCG Retailer" market in Total US
            marketSections: {
              embodyTotalMarkets: true,
              embodyFmcgRetailers: true,
              embodySpecialityRetailers: true,
              embodyGeographyMarkets: true,
            },
            checkCharacterLimit: true,
            warnSelectMarketInPairs: true
          },
        },
      };
      break;
    case 'market:option4':
      // Brand review story Panel 1
      selectorAttrObj = {
          selectorLabelName: selectorLabels.focusMarket,
          selectorType: 'market',
          subSelectors: {
            // Select Focus Market: Option 1
            subSelectorLabelId: 'Focus Market story: Option 4', // Just to distinguish, not used in code
            subSelectorType: 'smartReportMarkets', //'focusMarketStory', // This property is validated in code to identify which type of selector is to be rendered
            // market:`Select one account and one remaining market for it. A remaining market must be chosen for this story.`,
            subSelectorDescription: {
              market: '',
              marketAlias: marketAliasDescription
            },
            markets: {
              marketSections: {
                embodyTotalMarkets: true,
                embodyFmcgRetailers: true,
                embodySpecialityRetailers: true,
                embodyGeographyMarkets: true,
              },
              requireComparisonMarketType: false,
              marketGroup: 'rms',
              multi: false,
              maxLimit: 1,
              // requiredRemMarketForEachRetailer: true,
              // requiredRemMarketForTotalMarkets: true, // enables comparative market in total US Market section
              // smartAccount: true,
              // comparativeMarketsRequired: true,
              // showSelectedMarketsCount: true,
              // showRemainingMarketsEnabled: true,  // enables remaining markets in search and toggle is on
              showRemainingMarketsSwitch: false,
              // marketBehavior: marketBehavior.option2,
              
              // showAllMarkets: false,  // hides "Total FMCG Retailer" market in Total US
              // marketSections: {
              //   embodyTotalMarkets: true,
              //   embodyFmcgRetailers: true,
              //   embodySpecialityRetailers: true,
              //   embodyGeographyMarkets: true,
              // },
              // checkCharacterLimit: true,
              // warnSelectMarketInPairs: true
            },
          },
        };
        break;
    case 'market:option5':
    // Brand review story Panel 2 A
      selectorAttrObj = {
        selectorLabelName: selectorLabels.marketPerformanceObjective,
        selectorType: 'market',
        subSelectors: {
          // Market Type report: Option 5
          subSelectorLabelId: 'Market Type report: Option 5', // Just to distinguish, not used in code
          subSelectorType: 'marketTypeReport', // This property is validated in code to identify which type of selector is to be rendered
          selectorFunction: 'marketPerformance', // NEW USE THIS...
          subSelectorDescription: {
            // market:`Select one account and one remaining market for it. A remaining market must be chosen for this story.`,
            market: '',
            marketAlias: marketAliasDescription
          },
          markets: {
            marketSections: {
              embodyTotalMarkets: true,
              embodyFmcgRetailers: true,
              embodySpecialityRetailers: true,
              embodyGeographyMarkets: true,
            },
            requireComparisonMarketType: false,
            marketGroup: 'rms',
            // multi: true,
            // maxLimit: 10,
          },
        }
      };
      break;
    case 'market:option2':
      // Brand review story Panel 2 B
        selectorAttrObj = {
            selectorLabelName: selectorLabels.focusMarkets,
            selectorType: 'market',
            subSelectors: {
                subSelectorLabelId: 'Select multiple markets report: Option 2', // Just to distinguish, not used in code
                subSelectorType: 'multiMarketsReport', // This property is validated in code to identify which type of selector is to be rendered
                selectorFunction: 'multiMarketsStory',
                subSelectorDescription: {
                  market: '',
                  marketAlias: marketAliasDescription
                },
                markets: {
                    marketGroup: 'rms',
                    multi: true,
                    maxLimit: 10, //changed to allow choosing up to 20 rather than 10
                    showSelectedMarketsCount: true,
                    comparativeMarketsRequired: false,
                    
                    showAllMarkets: true,
                    warnSelectMarketInPairs: false,
                    marketSections: {
                        embodyTotalMarkets: true,
                        embodyFmcgRetailers: true,
                        embodySpecialityRetailers: true,
                        embodyGeographyMarkets: true,
                    },
                },
            },
        };
        break;
    case 'market:option8':
      // pricing and promotion story
      selectorAttrObj = {
        selectorLabelName: selectorLabels.focusMarket,
        selectorType: 'market',
          subSelectors: {
            subSelectorLabelId: 'Focus Market report: Option 1',
            subSelectorType: 'focusMarketReport',
            subSelectorDescription: {
              market: '',
              marketAlias: marketAliasDescription
            },
            markets: {
                marketGroup: 'rms',
                multi: false,
                maxLimit: 1,
                showSelectedMarketsCount: true,
                showRemainingMarketsSwitch: false,
                comparativeMarketsRequired: false,
                
                showAllMarkets: true,
                checkCharacterLimit: true,
                warnSelectMarketInPairs: false,
                marketSections: {
                  embodyTotalMarkets: true,
                  embodyFmcgRetailers: true,
                  embodySpecialityRetailers: true,
                  embodyGeographyMarkets: true,
                },
            }
        }
      }
      break;
    case 'timePeriod:default':
      selectorAttrObj = {
        selectorLabelName: selectorLabels.timePeriod,
        selectorType: 'timePeriod',
        subSelectors: {
          subSelectorDescription: timePeriodDescription
        } // TBD
      }
      break;
    case 'featuredCharacteristic:option1':
    // Category Review Story
      selectorAttrObj = {
        selectorLabelName: selectorLabels.characteristic,
        selectorType: 'characteristicFeature',
        subSelectors: { // Focus Brand Report : Option 1
          subSelectorLabelId: 'Focus Characteristic Feature story: Option 1', // Just to distinguish, not used in code
          subSelectorType: 'focusCharacteristicFeature', // This property is validated in code to identify which type of selector is to be rendered	
          subSelectorDescription: characteristicDescription,
          focusBrand: false,
          competitiveProductSet: false,
          characteristicFilters: {
            multi: false,
            maxLimit: 1
          }
        }
      }
      break;
    case 'comparisonMarket:option1':
    // Category Review Story
      selectorAttrObj = {
        selectorLabelName: selectorLabels.comparisonMarket,
        selectorType: 'comparisonMarket',
        subSelectors: {
          // Select Focus Market: Option 1
          subSelectorLabelId: 'Comparison Market story: Option 1', // Just to distinguish, not used in code
          subSelectorType: 'comparisonMarketStory', // This property is validated in code to identify which type of selector is to be rendered
          subSelectorDescription: {
            market: comparisonMarketDescription,
            marketAlias: comparisonMarketAliasDescription
          },
          markets: {
            marketGroup: 'rms',
            multi: true,
            // maxLimit: 1,
            requiredRemMarketForEachRetailer: true,
            requiredRemMarketForTotalMarkets: true,
            marketoptions: false,
            comparativeMarketsRequired: true,
            showSelectedMarketsCount: true,
            showRemainingMarketsEnabled: true,
            showRemainingMarketsSwitch: true,
            marketBehavior: marketBehavior.option1a,
            
            showAllMarkets: true,
            marketSections: {
              embodyTotalMarkets: true,
              embodyFmcgRetailers: true,
              embodySpecialityRetailers: true,
              embodyGeographyMarkets: true,
            },
            checkCharacterLimit: true,
          },
        },
      };
      break;
    case 'comparisonMarket:option2':
        selectorAttrObj = {
            selectorLabelName: selectorLabels.comparisonMarket,
            selectorType: 'comparisonMarket',
            subSelectors: {
              subSelectorLabelId: 'Comparison Market story: Option 2', // Just to distinguish, not used in code
              subSelectorType: 'comparisonMarketStory', // This property is validated in code to identify which type of selector is to be rendered
              subSelectorDescription: {
                market: comparisonMarketDescription,
                marketAlias: comparisonMarketAliasDescription
              },
              markets: {
                marketGroup: 'rms',
                multi: true,
                // maxLimit: 1,
                comparativeMarketsRequired: true,
                showSelectedMarketsCount: true,
                showRemainingMarketsEnabled: true,
                showRemainingMarketsSwitch: true,
                marketBehavior: marketBehavior.option1a,
                
                marketSections: {
                    embodyTotalMarkets: true,
                    embodyFmcgRetailers: true,
                    embodySpecialityRetailers: true,
                    embodyGeographyMarkets: true,
                },
                checkCharacterLimit: true,
              },
            },
        };
        break;
    case 'panelTimePeriod:default':
      selectorAttrObj = {
        selectorLabelName: selectorLabels.panelTimePeriod,
        selectorType: 'panelTimePeriod',
        subSelectors: {
          subSelectorDescription:`For panel slides, select a 52 week time period to maximize the sample used in the reporting.`,
          marketGroup: 'cps'
        } // TBD
      }
      break;
    default:
      break;
  }
  return selectorAttrObj;
}

export const characteristicFormValidation = (currentCharConditionValuesData) => {
  let status = true;
  for (let index = 0; index < currentCharConditionValuesData.length; index++) {
    if (currentCharConditionValuesData[index].valueItemValue) {
      const findData = currentCharConditionValuesData.filter((val) => {
        return (
          val.characteristicValue === currentCharConditionValuesData[index].characteristicValue &&
          (
            val.valueItemValue.join() === currentCharConditionValuesData[index].valueItemValue.join() || val.valueItemValue.filter((value) => {
              return currentCharConditionValuesData[index].valueItemValue.includes(value);
            }).length > 0
          )
        );
      });
      if (findData.length > 1) {
        status = false;
        break;
      }
    }
  }
  return status;
}
