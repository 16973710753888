import {ByzzerSelectOption} from "@byzzer/ui-components";
import {DodPresetConfig} from "@/components/DodConfigEditor/types";

export const QUICK_LAYOUT_OPTIONS: ByzzerSelectOption<Partial<DodPresetConfig>>[] = [
    {
        display: 'Quick Layout 1: Products in Rows; Market, Time Periods, Facts in Columns',
        value: 'ql1',
        data: {
            order: ['products', 'markets', 'time_periods', 'facts'],
            axes: {
                'products': 'row',
                'markets': 'col',
                'time_periods': 'col',
                'facts': 'col'
            },
            dimConfigs: {}
        },
        /*[{
            axis: 'row',
            type: 'products',
            dim: '',
            sortType: 'default',
            values: [],
            pageBy: false,
            hide: false,
            stack: false,
        }, {
            type: 'markets',
            dim: 'markets',
            axis: 'col',
            sortType: 'default',
            values: [],
            pageBy: false,
            hide: false,
            stack: false,
        }, {
            type: 'time_periods',
            dim: 'timePeriods',
            axis: 'col',
            sortType: 'default',
            values: [],
            pageBy: false,
            hide: false,
            stack: false,
        }, {
            type: 'facts',
            dim: 'facts',
            axis: 'col',
            sortType: 'default',
            values: [],
            pageBy: false,
            hide: false,
            stack: false,
        }]
        */
    }, {
        display: 'Quick Layout 2: Product, Market, Time Period in Rows; Facts in Columns',
        value: 'ql2',
        data: {
            order: ['products', 'markets', 'time_periods', 'facts'],
            axes: {
                'products': 'row',
                'markets': 'row',
                'time_periods': 'row',
                'facts': 'col'
            },
            dimConfigs: {}
        },
        /*[
            {
                type: 'products',
                dim: '',
                axis: 'row',
                sortType: 'default',
                values: [],
                pageBy: false,
                hide: false,
                stack: false,
            },
            {
                type: 'markets',
                dim: 'markets',
                axis: 'row',
                sortType: 'default',
                values: [],
                pageBy: false,
                hide: false,
                stack: false,
            },
            {
                type: 'time_periods',
                dim: 'timePeriods',
                axis: 'row',
                sortType: 'default',
                values: [],
                pageBy: false,
                hide: false,
                stack: false,
            },
            {
                type: 'facts',
                dim: 'facts',
                axis: 'col',
                sortType: 'default',
                values: [],
                pageBy: false,
                hide: false,
                stack: false,
            },
        ]*/
    }, {
        display: 'Quick Layout 3: Page by Time Period, Product in Rows; Markets and Facts in Columns',
        value: 'ql3',
        data: {
            order: ['products', 'markets', 'time_periods', 'facts'],
            axes: {
                'products': 'row',
                'markets': 'col',
                'time_periods': 'row',
                'facts': 'col'
            },
            dimConfigs: {
                timePeriods: {
                    pageBy: true
                }
            }
        }
        /*[{
            type: 'products',
            dim: '',
            axis: 'row',
            sortType: 'default',
            values: [],
            pageBy: false,
            hide: false,
            stack: false,
        }, {
            type: 'markets',
            dim: 'markets',
            axis: 'col',
            sortType: 'default',
            values: [],
            pageBy: false,
            hide: false,
            stack: false,
        }, {
            type: 'time_periods',
            dim: 'timePeriods',
            axis: 'row',
            sortType: 'default',
            values: [],
            hide:false, // hide property should not be true by default if pageBy is true
            pageBy: true, // timeperiod not in row[]/cols[], just in pageBy
            stack: false,
        }, {
            type: 'facts',
            dim: 'facts',
            axis: 'col',
            sortType: 'default',
            values: [],
            pageBy: false,
            hide: false,
            stack: false,
        }]*/
    }, {
        display: 'Quick Layout 4: Page by Time Period, Market; Products in Rows; Facts in Columns',
        value: 'ql4',
        data: {
            order: ['products', 'markets', 'time_periods', 'facts'],
            axes: {
                'products': 'row',
                'markets': 'row',
                'time_periods': 'row',
                'facts': 'col'
            },
            dimConfigs: {
                timePeriods: {
                    pageBy: true
                },
                markets: {
                    pageBy: true
                }
            }
        }
        /*[{
            type: 'products',
            dim: '',
            axis: 'row',
            sortType: 'default',
            values: [],
            pageBy: false,
            hide: false,
            stack: false,
        }, {
            type: 'markets',
            dim: 'markets',
            axis: 'row',
            sortType: 'default',
            values: [],
            pageBy: true, // same case, hence hidden
            hide: false, // hide property should not be true by default if pageBy is true
            stack: false,
        }, {
            type: 'time_periods',
            dim: 'timePeriods',
            axis: 'row',
            sortType: 'default',
            values: [],
            pageBy: true, // same case, hence hidden
            hide: false, // hide property should not be true by default if pageBy is true
            stack: false,
        }, {
            type: 'facts',
            dim: 'facts',
            axis: 'col',
            sortType: 'default',
            values: [],
            pageBy: false,
            hide: false,
            stack: false,
        }]*/
    }, {
        display: 'Quick Layout 5: Page by Market; Products in Rows, Time Periods and Facts in Columns',
        value: 'ql5',
        data: {
            order: ['products', 'markets', 'time_periods', 'facts'],
            axes: {
                'products': 'row',
                'markets': 'row',
                'time_periods': 'col',
                'facts': 'col'
            },
            dimConfigs: {
                markets: {
                    pageBy: true
                }
            }
        }
        /*[
            {
                type: 'products',
                dim: '',
                axis: 'row',
                sortType: 'default',
                values: [],
                pageBy: false,
                hide: false,
                stack: false,
            },
            {
                type: 'markets',
                dim: 'markets',
                axis: 'row',
                sortType: 'default',
                values: [],
                pageBy: true, // same case, hence hidden
                hide: false, // hide property should not be true by default if pageBy is true
                stack: false,
            },
            {
                type: 'time_periods',
                dim: 'timePeriods',
                axis: 'col',
                sortType: 'default',
                values: [],
                pageBy: false,
                hide: false,
                stack: false,
            },
            {
                type: 'facts',
                dim: 'facts',
                axis: 'col',
                sortType: 'default',
                values: [],
                pageBy: false,
                hide: false,
                stack: false,
            },
        ]*/,
    }, {
        display: 'Quick Layout 6: Page by Category; Product, Market, Time Periods in Rows; Facts in Columns',
        value:
            'ql6',
        data: {
            order: ['products', 'markets', 'time_periods', 'facts'],
            axes: {
                'products': 'row',
                'markets': 'row',
                'time_periods': 'row',
                'facts': 'col'
            },
            dimConfigs: {
                'categories': {
                    pageBy: true
                }
            }
        }, /*[
            {
                type: 'products',
                dim: 'categories',
                axis: 'row',
                sortType: 'default',
                values: [],
                pageBy: true,
                hide: false,
                stack: false,
            },
            {
                type: 'markets',
                dim: 'markets',
                axis: 'row',
                sortType: 'default',
                values: [],
                pageBy: false,
                hide: false,
                stack: false,
            },
            {
                type: 'time_periods',
                dim: 'timePeriods',
                axis: 'row',
                sortType: 'default',
                values: [],
                pageBy: false,
                hide: false,
                stack: false,
            },
            {
                type: 'facts',
                dim: 'facts',
                axis: 'col',
                sortType: 'default',
                values: [],
                pageBy: false,
                hide: false,
                stack: false,
            },
        ],*/
    }, {
        display: 'Quick Layout 7: Markets, Product, and Facts in rows; Time Periods in columns',
        value: 'ql7',
        data: {
            order: ['markets', 'products', 'facts', 'time_periods'],
            axes: {
                'products': 'row',
                'markets': 'row',
                'time_periods': 'col',
                'facts': 'row'
            },
            dimConfigs: {}
        }
        /*[
            {
                type: 'markets',
                dim: 'markets',
                axis: 'row',
                sortType: 'default',
                values: [],
                pageBy: false,
                hide: false,
                stack: false,
            },
            {
                type: 'products',
                dim: '',
                axis: 'row',
                sortType: 'default',
                values: [],
                pageBy: false,
                hide: false,
                stack: false,
            },
            {
                type: 'facts',
                dim: 'facts',
                axis: 'row',
                sortType: 'default',
                values: [],
                pageBy: false,
                hide: false,
                stack: false,
            },
            {
                type: 'time_periods',
                dim: 'timePeriods',
                axis: 'col',
                sortType: 'default',
                values: [],
                pageBy: false,
                hide: false,
                stack: false,
            },
        ]*/,
    }, {
        display: 'Custom Layout',
        value: 'cl'
        // this data is intentionally undefined here because a custom layout should not change anything
    }];


    export const quickLayoutAllowedOptions = {
        "ql1": ["cl", "ql1"],
        "ql2": ["ql4", "ql6", "cl", "ql2"],
        "ql3": ["cl", "ql3"],
        "ql4": ["ql2", "ql6", "cl", "ql4"],
        "ql5": ["cl", "ql5"],
        "ql6": ["ql2", "ql4", "cl", "ql6"],
        "ql7": ["cl", "ql7"],
        "cl": ["cl"]
    }