import './DodProductFiltersStep.scss';
import React, {ReactNode, useMemo, useState} from 'react';
import classnames from 'classnames';
import {StepRef, WizardContent, WizardStep} from '@byzzer/ui-components';
import {DodFactSetBuilder, DodFactSetBuilderProps} from '@/components/DodConfigEditor/builders/DodFactSetBuilder';
import ByzzerLink from '@/components/form/ByzzerLink';
import {DodWizardActions} from '../DodWizardActions';
import { DodResourceLink } from '../../common/DodResourceLink';

export type DodFactsStepProps = {
    title?: ReactNode;
    enabled?: boolean;
    exclude?: boolean;
} & DodFactSetBuilderProps;

const baseClassName = 'dod-run-config-filters-step';

const tip = <>
    <p>Select which facts to include in your run.</p>
    <p>Clicking the folder name (such as Total Facts or All TDP & Distribution Facts) will allow you to choose specific facts in the center panel. This will include the selected facts in your run for all of the product, market, and time period dimensions
        already selected.</p>
    <p>If you would like more information on what a fact is and how it is calculated, check out the&nbsp;
        <ByzzerLink href="https://learn.byzzer.ai/en/articles/6310950-data-on-demand-fact-list-and-definitions."
                target="_blank"
                rel="noreferrer"
        >
                fact definitions file!
        </ByzzerLink>
    </p>
    <DodResourceLink />
</>;

export const DodFactsStep = React.forwardRef<StepRef, DodFactsStepProps>(
    ({className, enabled, title = 'Facts', onValidityChange, exclude, ...props}, ref) => {
        const [filtersValid, setFiltersValid] = useState<boolean>(false);
        const count = useMemo<string>(() => {
            const count = props.value.reduce((sum, {
                yearAgo,
                fact,
                productSelectionsYearAgo,
                productSelections,
                brand,
                brandYearAgo,
                categoryYearAgo,
                category,
                changeVsYearAgo,
                percentChangeVsYearAgo
            }) => {
                return sum + [
                    yearAgo,
                    fact,
                    productSelectionsYearAgo,
                    productSelections,
                    brand,
                    brandYearAgo,
                    categoryYearAgo,
                    category,
                    changeVsYearAgo,
                    percentChangeVsYearAgo
                ].filter(Boolean).length
            }, 0);

            return count ? `(${count})` : '';
        }, [props.value])

        function handleValidationChange(e: ByzzerValidityChangeEvent) {
            setFiltersValid(e.isValid);
            onValidityChange?.(e);
        }

        return (
            <WizardStep className={classnames(baseClassName, className)} ref={ref} enabled={enabled}
                        exclude={exclude}
                        id={'facts'}
                        title={`${title} ${count}`}>
                <WizardContent>
                    <DodFactSetBuilder {...props} tip={tip} onValidityChange={handleValidationChange}/>
                </WizardContent>
                <DodWizardActions
                    disableNext={!filtersValid}
                    nextDisabledTip={'You must choose all required values to continue.'}
                    helpTip={tip}
                />
            </WizardStep>
        );
    }
);
