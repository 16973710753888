import { forwardRef, ReactNode, useEffect, useState } from 'react';
import classnames from "classnames";
import {
    ByzzerChangeEventHandler,
    ByzzerSelect,
    ByzzerSelectOption,
    WithValue,
} from '@byzzer/ui-components';
import { growthThresholdOptions } from '@/constants/threshold.constants';

const baseClassName = 'byz-growth-threshold';

export type ByzzerGrowthThresholdSelectProps = {
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<string[]>;
    value?: string[];
    disabledOptions?: string[];
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    maxSelections?: number;
} & OnlyRenderableIf;

const ByzzerGrowthThresholdSelect = forwardRef<WithValue<string>, ByzzerGrowthThresholdSelectProps>(
    (
        {
            onChange,
            name,
            label,
            className,
            disabledOptions = [],
            placeholder = 'Select your Growth Threshold',
            value,
            disabled,
            onlyRenderIf,
            maxSelections = 1
        },
        ref
    ) => {

        if (onlyRenderIf === false) return null;

        const [internalValue, setInternalValue] = useState<ByzzerSelectOption[]>();

        useEffect(() => {
            const newValue = growthThresholdOptions.filter((option) => value?.includes(option.value));
            setInternalValue(newValue);
        }, [value])

        function handleChange(e: ByzzerChangeEvent<string[]>) {
            if (onChange) {
                onChange({
                    name,
                    value: e.value
                });
            } else {
                setInternalValue(e.data);
            }
        }

        return (
                <ByzzerSelect
                    ref={ref}
                    name={name}
                    className={classnames(baseClassName, className)}
                    options={growthThresholdOptions}
                    disabled={disabled}
                    placeholder={placeholder}
                    label={label as any}
                    value={internalValue}
                    disabledOptions={disabledOptions}
                    onChange={handleChange}
                    allowMultiple={true}
                    maxSelections={maxSelections}
                />
        );
    }
);

export default ByzzerGrowthThresholdSelect;
