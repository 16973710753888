import './DashboardSimpleContent.scss';
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ByzzerMask } from "@/components/ByzzerMask/ByzzerMask";

const baseClassName = 'dashboard-content';

export function DashboardSimpleContent({ className, children, loading = false, ...props }) {

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <ByzzerMask show={loading} loading={loading} />
            {children}
        </div>
    )
}

export default DashboardSimpleContent;
