import './SortToggle.scss';
import {SortType} from "@/types/DodRun";
import React, {ReactNode, useMemo} from "react";
import classnames from "classnames";
import Tippy from "@tippyjs/react";

interface SortToggleProps {
    value?: SortType | 'default';

    onChange?(direction: SortType): void;

    manualTip?: ReactNode;
    sortable?: boolean;
}

const baseClassName = 'dyl-sort-toggle';

const DEFAULT_MANUAL_TIP = <>
    <p>Sorted manually. Click to toggle.</p>
    <p>Click "Manage Selections" to view the order.</p>
</>;

export function SortToggle({value = 'default', onChange, manualTip = DEFAULT_MANUAL_TIP, sortable = true}: SortToggleProps) {

    const tip = useMemo<ReactNode>(() => {
        switch (value) {
            case 'asc':
                return <>
                    <p>Sorted Ascending. Click to toggle.</p>
                </>;
            case 'desc':
                return <>
                    <p>Sorted Descending. Click to toggle.</p>
                </>;
            default:
                return manualTip
        }
    }, [value]);

    function handleClick() {
        let sortType: SortType;
        switch (value) {
            case 'asc':
                sortType = 'desc';
                break;
            case 'desc':
                sortType = 'manual'
                break;
            default:
                sortType = 'asc';
        }
        onChange?.(sortType);
    }

    if (!sortable) {
        return <i
            className={classnames(baseClassName, `${baseClassName}--placeholder`)}/>
    }

    return <Tippy
        content={<div className={`${baseClassName}__tip-content`}>{tip}</div>}
        placement={'bottom'}
        delay={[750, 0]}
    >
        <i className={classnames(baseClassName, `${baseClassName}--dir-${value}`)}
           onClick={handleClick}/>
    </Tippy>
}
