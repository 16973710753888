import './OBOWarning.scss';
import 'firebase/compat/auth';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@/contexts/UserContext';
import { ByzzerButton } from '@byzzer/ui-components';
import { ByzzerLogo } from '@/components/ByzzerLogo';

const baseClassName = 'obo-warning';

export function OBOWarning() {
    const navigate = useNavigate();
    const { user, csrOboAccessRoles, updateOboAllowAccess } = useUser();
    const isCsrSuperUser = Object.values<string>(csrOboAccessRoles).includes('superuser');

    function goToDashboard() {
        updateOboAllowAccess(true);
        if (user) {
            navigate('/dashboard', { replace: true });
        }
    }

    return (
        <div className={baseClassName}>
            <ByzzerLogo />
            <div className={`${baseClassName}__message`}>
                This client hasn't accepted the Terms & Conditions, so you cannot deliver data to them.
                <span>
                    {isCsrSuperUser
                        ? ' You are a superuser so can sign in to troubleshoot only.'
                        : ' Please have them accept T&Cs so you can OBO as them.'}
                </span>
                <p>{isCsrSuperUser ? 'DO NOT DELIVER DATA TO THIS CLIENT UNTIL THEY HAVE ACCEPTED T&CS' : ''}</p>
            </div>

            <footer className={`${baseClassName}__actions`}>
                {isCsrSuperUser && (
                    <ByzzerButton onClick={goToDashboard} disabled={false} data-test-signin-button>
                        Sign In
                    </ByzzerButton>
                )}
            </footer>
        </div>
    );
}

export default OBOWarning;
