import React, {Children, ReactNode} from "react";
import {useTrackEvent} from "@/analytics/AnalyticsContext";

type TrackClickProps = {
    action: string;
    name?: undefined;
    data?: any;
    children: ReactNode;
} | {
    name: string;
    action?: undefined;
    data?: any;
    children: ReactNode;
}

export function TrackClick({name, action = name, data, children}: TrackClickProps) {

    const trackEvent = useTrackEvent();
    const content = Children.map(children, (child, index) => {

        if (React.isValidElement(child)) {
            const onClick = child?.props.onClick;
            return React.cloneElement(child, {
                onClick: e => {
                    trackEvent?.({
                        type: 'click',
                        name: action,
                        data
                    })
                    onClick?.(e)
                }
            } as any)
        } else {
            // wrap any plan text elements in a span
            return <span onClick={() => trackEvent({
                type: 'click',
                name,
                data
            })}>{child}</span>
        }
    });

    return <>{content}</>
}
