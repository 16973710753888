import React, { useEffect, useState } from 'react';
import './MarketMapsContent.scss';
import { ByzzerTextInput } from '@byzzer/ui-components';
import EyeIcon from '@images/icons/eyeOpenIcon.svg';
import ExpandableTable from './MarketMapTable/ByzzerExpandableTable';
import {useTenantApi} from '@/hooks/useTenantApi';
import { ByzzerMask } from '../ByzzerMask/ByzzerMask';
import searchData from './MarketMapUtils';
import InlineSVG from 'react-inlinesvg/esm';
import { MarketMapState } from '@/types/ApiTypes';

const MarketMapsContent = ({ marketMap }) => {
    const baseClassName = 'retailer-maps';
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState<MarketMapState[]>([]);
    const [filteredData, setFilteredData] = useState<MarketMapState[]>(data);
    const { getMarketMaps } = useTenantApi();
    useEffect(() => {
        loadMarketMaps();
    }, []);

    useEffect(() => {
        if (searchText) {
            if (searchText.length > 1) {
                const dataFiltering = searchData(data, searchText);
                setFilteredData(dataFiltering);
            }
        } else {
            setFilteredData(data);
        }
    }, [searchText, data]);

    const loadMarketMaps = async () => {
        try {
            setLoading(true);
            let { folderData } = await getMarketMaps(marketMap);

            setData(folderData);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'Market Maps',
            key: ['folderName', 'fileName'],
        },
        {
            title: 'Modified',
            key: 'updatedAt',
        },
        { title: 'Actions', key: ['link', 'files'] },
    ];

    const rowColor = (rowData: { id: number }): string | undefined => {
        if (rowData.id && rowData.id % 2 == 0) {
            return 'rgba(255, 255, 255)';
        } else if (rowData.id && rowData.id % 2 !== 0) {
            return 'rgba(230, 230, 230)';
        }
    };

    const visibleOnInit = (rowData): boolean => {
        if (rowData?.expand) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <div className={baseClassName}>
                <div className={`${baseClassName}__search`}>
                    <div className={`${baseClassName}__search--label`}>{'Choose Market Map:'}</div>
                    <div className={`${baseClassName}__search--input-container`}>
                        <span className={`${baseClassName}__search--icon`}></span>
                        <input
                            placeholder={`Search ${marketMap} Map`}
                            value={searchText}
                            className={`${baseClassName}__search--input`}
                            onChange={(v) => setSearchText(v.target.value)}
                        />
                    </div>
                </div>
                <div className={`${baseClassName}__table`}>
                    <ByzzerMask show={loading} loading={loading} />
                    <ExpandableTable
                        columns={columns}
                        data={filteredData}
                        childDataKey={'files'}
                        rowKey={'fieldPath'}
                        rowColor={rowColor}
                        visibleOnInit={visibleOnInit}
                        hideCollapseExpandButtons={true}
                    ></ExpandableTable>
                </div>
            </div>
        </>
    );
};

export default MarketMapsContent;
