import React from 'react';
import { ByzzerCarousel } from '@/components/ByzzerCarousel';
import CaseStudy from './CaseStudy';

const baseClassName = `case-study`;

type CaseStudySlide = {
    slides: any[];
    case: string;
};

type CaseStudyWrapperProps = {
    caseStudies: CaseStudySlide[];
};

const CaseStudyWrapper: React.FC<CaseStudyWrapperProps> = ({ caseStudies, ...props }) => {

    return (
        <div className={`${baseClassName}__container`}>
            <div className={`${baseClassName}__heading_container`}>
                <div className={`${baseClassName}__heading_container__heading`}>Byzzer Case Studies</div>
                <div className={`${baseClassName}__heading_container__sub-heading`}>
                    See how small and emerging brands like yours have leveraged the power of Byzzer to tackle key
                    business needs.
                </div>
            </div>

            <ByzzerCarousel
                className={`${baseClassName}__carousel-wrapper`}
                itemClass={`${baseClassName}__carousel-wrapper-item`}
            >
                {caseStudies.map(({ slides, case: caseName }) => (
                    <CaseStudy key={caseName} baseClassName={baseClassName} caseStudies={slides} {...props} />
                ))}
            </ByzzerCarousel>
        </div>
    );
};

export default CaseStudyWrapper;
