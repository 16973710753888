import React, {forwardRef, ReactNode, useEffect, useImperativeHandle, useRef} from 'react';
import './CategorySelectForTopBrands.scss';
import { ByzzerChangeEventHandler, ByzzerSelect, ByzzerSelectOption, ByzzerSelectOptionGroup, toOption, toOptionGroup } from '@byzzer/ui-components';
// import {useTestTenantApi} from '@/hooks/useTestTenantApi';
import {useTenantApi} from '@/hooks/useTenantApi';
import useStateRef from 'react-usestateref';
import {CategoryOptionsForSelectedBrandsRef} from '../../views/onboarding/OnboardingCategories';
import { TopCategory } from '@/types/ApiTypes';

export type CategorySelectForTopBrandsProps = {
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<string[]>;
    value?: string[];
    disabledOptions?: string[];
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    maxSelections?: number;
    /**
     *  The brand you wish to find categories for.
     */
    brands?: string[];
    allowClear?: boolean;
    allowMultiple?: boolean;
} & OnlyRenderableIf;

const baseClassName = 'byz-category-select-for-top-brands';

export const CategorySelectForTopBrands = forwardRef<CategoryOptionsForSelectedBrandsRef, CategorySelectForTopBrandsProps>(
    ({
        onChange,
        name,
        label,
        className,
        disabledOptions = [],
        placeholder = '',
        value, // any non-primitative default will cause infinite loop if useEffect is used on it
        disabled,
        maxSelections = Infinity,
        onlyRenderIf,
        allowClear = true,
        allowMultiple = true,
        brands,
    }, ref) => {
// const { getCategoryByBrand, getCategoriesForTheme, brandSearch } = useTestTenantApi();
const { getTopCategoriesForBrands } = useTenantApi();
        const [categoryOptionsForSelectedBrands, setCategoryOptionsForSelectedBrands, categoryOptionsForSelectedBrandsRef] = useStateRef<ByzzerSelectOptionGroup[]>([]);
        const [additionalOptions, setAdditionalOptions, additionalOptionsRef] = useStateRef<ByzzerSelectOptionGroup>();
        const [internalValue, setInternalValue] = useStateRef<string[]>([]);
        const categorySearchIsCompleteRef = useRef<boolean | undefined>();
        const NOT_FOUND_IN_BRANDS_LABEL = "Not Found in Brand(s)";

        useEffect(() => {
            setInternalValue(value ?? []);

            const valuesNotFoundInOptions: ByzzerSelectOptionGroup = { // add categories chosen outside of this component (E.g. from CategorySelector.js/Advanced Search)
                label: NOT_FOUND_IN_BRANDS_LABEL,
                options: (value ?? [])?.filter((val) => {
                    const flattenedOptions = categoryOptionsForSelectedBrandsRef.current?.map((catOptGrp) => catOptGrp?.options?.map(({value}) => value))?.flat();
                    return !flattenedOptions?.includes((val));
                })?.map(toOption)
            };

            let catOptsForSelBrandsPlusOtherCats, revisedOptions, revisedAddlOptions;

            if (valuesNotFoundInOptions?.options?.length) { // add categories chosen outside of this component to options, otherwise they wont appear as chips

                catOptsForSelBrandsPlusOtherCats = [...new Set([...categoryOptionsForSelectedBrandsRef.current, valuesNotFoundInOptions])];
                setCategoryOptionsForSelectedBrands(catOptsForSelBrandsPlusOtherCats);
                setAdditionalOptions(valuesNotFoundInOptions);
            }

            const addlOptionsNotFoundInValue = {
                ...additionalOptionsRef.current,
                children: additionalOptionsRef.current?.options?.filter((addlOpt) => !value?.includes(addlOpt.value as string))
            }

            if (addlOptionsNotFoundInValue?.children?.length) { // when a selected category that was originally added from outside of this component is removed, back it out of the options as well; an 'undo' of the above
                revisedOptions = categoryOptionsForSelectedBrandsRef.current?.map((catOptGrp) => { //
                    if (catOptGrp.label === NOT_FOUND_IN_BRANDS_LABEL) {
                        return {
                            ...catOptGrp,
                            children: catOptGrp.options.filter((catOpt1) => !addlOptionsNotFoundInValue?.children?.some((catOpt2) => catOpt1.value === catOpt2.value))
                        }
                    } else {
                        return catOptGrp
                    }
                })
                revisedAddlOptions = {
                    label: NOT_FOUND_IN_BRANDS_LABEL,
                    children: (additionalOptionsRef.current?.options ?? [])?.filter((catOpt1) => !addlOptionsNotFoundInValue?.children?.some((catOpt2) => catOpt1.value === catOpt2.value))
                }
                setCategoryOptionsForSelectedBrands(revisedOptions);
                setAdditionalOptions(revisedAddlOptions);
            }

        }, [value])


        useEffect(() => {
            ; (async () => {

                categorySearchIsCompleteRef.current = false;

                const { topCategories }: { topCategories: TopCategory[] } = await getTopCategoriesForBrands(brands!);

                let duplicateCategories: string[] = [];

                topCategories.forEach((topCategory1, index) => {
                    const categoryDupe: boolean = topCategories.slice(index+1).some((topCategory2) => { // finds a duplicate later in the list...
                        return topCategory2.category === topCategory1.category;
                    })

                    const isNewDupe = !duplicateCategories.includes(topCategory1.category)

                    if (categoryDupe && isNewDupe) {
                        duplicateCategories.push(topCategory1.category)
                    }
                })

                const uniqueCategories = topCategories.filter((topCategory) => {
                    return !duplicateCategories.includes(topCategory.category);
                })

                let newOptionGroups: ByzzerSelectOptionGroup[] = [];

                uniqueCategories.forEach((unqCat) => {
                    const currentBrandEntry = newOptionGroups.find((optionGroup) => {
                        return optionGroup.label === unqCat.brand;
                    })
                    if (currentBrandEntry) {
                        currentBrandEntry.options = [...currentBrandEntry.options, {display: unqCat.category, value: unqCat.category, data: { categoryRank: unqCat.categoryRank, categoryDollars: unqCat.categoryDollars }}]
                    } else {
                        newOptionGroups = [
                            ...newOptionGroups,
                            {
                                label: unqCat.brand,
                                options: [{display: unqCat.category, value: unqCat.category, data: { categoryRank: unqCat.categoryRank, categoryDollars: unqCat.categoryDollars }}]
                            }
                        ]
                    }
                });

                if (duplicateCategories?.length) {
                    newOptionGroups = [
                        {
                            label: '(Multiple Brands)',
                            options: duplicateCategories.map((dupCat) => ({display: dupCat, value: dupCat}))
                        },
                        ...newOptionGroups
                    ]
                }

                let topCategoryOptionGroups = newOptionGroups;

                if (additionalOptions?.options?.length) {
                    topCategoryOptionGroups = [
                        ...topCategoryOptionGroups,
                        additionalOptions
                    ]
                }

                setCategoryOptionsForSelectedBrands(topCategoryOptionGroups);

                const sharedCategories = topCategoryOptionGroups?.find((topCategoryOptionGroup) => {
                    return topCategoryOptionGroup?.label === '(Multiple Brands)'
                })?.options?.map(({value}) => value) ?? [];

                const allOtherCategoriesSortedByDollars = [...new Set([...topCategories?.sort((a, b) => {
                    if (+a?.categoryDollars < +b?.categoryDollars) {
                        return 1;
                    } else {
                        return -1;
                    }
                })?.map(({category}) => category)])]?.filter((category) => !sharedCategories?.includes(category))

                const preSelectedCategories = [...sharedCategories, ...allOtherCategoriesSortedByDollars]?.slice(0, maxSelections)

                categorySearchIsCompleteRef.current = true;

                onChange?.({
                    value: preSelectedCategories,
                    name
                });

            })();
        }, [brands])


        function handleChange(e: any) {
            // setInternalValue(e?.value); // for performance reason, i'm setting internalValue right away with just a string array to more quickly populate chip(s) in the box.  internalValue is again updated with full ByzzerSelectOption shape after 'value' updates. User will not see any change.  performance of the select component(s) will be reviewed in future ticket(s)
            onChange?.({
                value: e?.value,
                name
            });
        }

        useImperativeHandle(
            ref,
            () => ({
                hasCategoryOptions: Boolean(categoryOptionsForSelectedBrandsRef?.current?.length),
                categorySearchIsComplete: categorySearchIsCompleteRef.current,
                categoryOptionsCount: categoryOptionsForSelectedBrandsRef?.current?.map(({options}) => options)?.flat()?.length
            }),
            [categoryOptionsForSelectedBrands, categorySearchIsCompleteRef.current, brands]
        )

        if (onlyRenderIf === false) return <></>;

        return (
            <ByzzerSelect
                name={name}
                options={categoryOptionsForSelectedBrands}
                disabled={disabled}
                placeholder={placeholder}
                label={label}
                value={internalValue}
                maxSelections={maxSelections}
                disabledOptions={disabledOptions}
                onChange={handleChange}
                allowMultiple={allowMultiple}
                allowClear={allowClear}
            // defaultMenuIsOpen={true} // if you need to test styling on drop down without it closing on you
            />
        )
    });

export default CategorySelectForTopBrands;

CategorySelectForTopBrands.displayName = 'CategorySelectForTopBrands';