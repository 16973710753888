import './ReferralSignUp.scss';
import React, {useDebugValue, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {ByzzerButton, ByzzerCheckbox, ByzzerInput, ByzzerLink} from "@/components/form";
import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";
import classnames from "classnames";
import {openTermsAndConditions} from "@/components/modals/TermsAndConditions";
import {useTenantApi} from "@/hooks/useTenantApi";
import {useUser} from "@/contexts/UserContext";
import {useBetterNavigate} from "@/utils";
import PasswordValidations, {findPasswordErrors} from "@/components/PasswordValidations/PasswordValidations";
import { useAccountService } from '@/hooks/useAccountService';

const baseClassName = 'referral-sign-up';

export function ReferralSignUp({className, ...props}) {
    const {acceptOffer, getReferral, signUpWithReferral} = useTenantApi();

    const navigate = useBetterNavigate();
    const {user} = useUser();
    const {referralId} = useParams();
    const [referral, setReferral] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [password, setPassword] = useState('');
    const [hasAcceptedTcs, setHasAcceptedTcs] = useState(false);
    const [mode, setMode] = useState('signUp');
    const [claimButtonText, setClaimButtonText] = useState('Claim');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const {login} = useAccountService();
    const DEFAULT_TITLE = <>{referral?.firstName}, you've got an offer!.</>;

    useEffect(() => {
        if(referralId) {
            loadReferral();
        }
    }, [referralId]);

    async function loadReferral() {

        try {
            const referral = await getReferral(referralId);
            setReferral(referral);
            setTitle(referral.title);
            setMessage(referral.message);
            if (user) {
                setMode('claim');
            } else if (referral.isExistingUser) {
                setMode('signIn')
                setClaimButtonText('Sign In And Claim')
            } else {
                setMode('signUp');
            }
            // validate that is hasn't expired
        } catch (err) {

        } finally {

            setLoading(false)
        }
    }

    function onPasswordChange({target}) {
        setPassword(target.value);
    }

    function canClaim() {

        switch(mode) {
            case 'claim':
                return true;
            case 'signIn':
                return password.length
            case 'signUp':
                return !findPasswordErrors(password)
        }
    }

    async function onClaimClick() {

        try {
            setSubmitting(true);
            switch (mode) {
                case 'claim':
                    await acceptOffer(referralId);
                    navigate('/dashboard', {replace: true});
                    break;
                case 'signIn':
                    await login({
                        email: referral.email,
                        password
                    });
                    break;
                case 'signUp':
                    await signUpWithReferral(referralId, {
                        password,
                        hasAcceptedTcs
                    });
                    await login({
                        email: referral.email,
                        password
                    });
                    navigate('/onboarding', {replace: true});
            }
        } finally {
            setSubmitting(false);
        }

    }

    return <div className={classnames(baseClassName, className, {
        [`${baseClassName}--loading`]: loading
    })} {...props}>
        <ByzzerMask loading={loading}>Loading Your Offer</ByzzerMask>
        <header className={`${baseClassName}__header`}>
            <div className={`${baseClassName}__title`}>{title || DEFAULT_TITLE}</div>
        </header>
        <main className={`${baseClassName}__content`}>

            {message && <p className={`${baseClassName}__message`}>{message}</p>}
            {/* EXISTING USER MODE */}
            {mode === 'claim' && (<>

            </>)}

            {/* SIGN IN MODE */}
            {mode === 'signIn' && (<>
                <p>Sign in to claim this offer.</p>
                <ByzzerInput
                    className={`${baseClassName}__input ${baseClassName}__email`}
                    label="Email"
                    name={'email'}
                    value={referral.email}
                    locked
                    disabled
                />
                <ByzzerInput
                    label="Password"
                    className={`${baseClassName}__input ${baseClassName}__password`}
                    type={'password'}
                    name={'password'}
                    validate={false}
                    value={password}
                    onChange={onPasswordChange}
                    placeholder="Enter you password"
                    required
                />
            </>)}

            {/* SIGN UP MODE */}
            {mode === 'signUp' && (<>
                <p>In the future you can log into Byzzer using this email and password.</p>
                <ByzzerInput
                    className={`${baseClassName}__input ${baseClassName}__email`}
                    label="Login Email"
                    name={'email'}
                    value={referral.email}
                    locked
                    disabled
                />
                <div className={`${baseClassName}__input-group ${baseClassName}__password`}>
                    <ByzzerInput
                        label="Choose A Password"
                        className={`${baseClassName}__input ${baseClassName}__password`}
                        type={'password'}
                        name={'password'}
                        validate={false}
                        value={password}
                        onChange={onPasswordChange}
                        required
                    />
                    <PasswordValidations password={password}/>
                </div>
                <ByzzerCheckbox
                    className={`${baseClassName}__input ${baseClassName}__terms`}
                    label={<>
                        <p>Accept Terms & Conditions</p>
                        <ByzzerLink
                            label={'See Details'}
                            onClick={openTermsAndConditions}/>
                    </>}
                    name={'hasAcceptedTcs'}
                    checked={hasAcceptedTcs}
                    onChange={({target}) => setHasAcceptedTcs(target.checked)}
                />
            </>)}
        </main>
        <footer className={`${baseClassName}__actions`}>
            <ByzzerButton busy={submitting} disabled={!canClaim()} onClick={onClaimClick}>{claimButtonText}</ByzzerButton>
        </footer>
    </div>
}
