import React, {forwardRef, memo, useEffect, useImperativeHandle, useState} from 'react';
import './CustomCharacteristics.scss';
import {ByzzerMultiSelect, confirm} from '@/components/form';
import {useTenantApi} from "@/hooks/useTenantApi";
import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";
import {openCharEditor} from './editor/CharEditor';
import './CustomCharacteristicTable.scss';
import {CustomCharacteristicsTableRow} from './CustomCharacteristicsTableRow';
import classnames from 'classnames';
// import upArrow from '@images/icons/upArrowWhite.svg';
// import downArrow from '@images/icons/downArrowWhite.svg';
import upArrow from '@images/icons/upArrowDark.svg';
import downArrow from '@images/icons/downArrowDark.svg';
import { useUser } from '@/contexts/UserContext';

export const CustomCharacteristicsTable = memo(forwardRef(({ className }, ref) => {
    const {deleteCustomChar} = useTenantApi();

    const baseClassName = 'custom-characteristics-table';
    const [loading, setLoading] = useState(false);
    const { customCharacteristics: contextCustomCharacteristics } = useUser();
    const [originalCustomCharacteristicsList, setOriginalCustomCharacteristicsList] = useState(contextCustomCharacteristics);
    const [sortedCustomCharacteristicsList, setSortedCustomCharacteristicsList] = useState([]);
    const [filteredCustomCharacteristicsList, setFilteredCustomCharacteristicsList] = useState([]);
    const [sortCommands, setSortCommands] = useState({
        sortKey: 'id',
        sortDir: 'desc'
    });
    const [tableFilterOptions, setTableFilterOptions] = useState({
        characteristics: [],
        createdBy: [],
        categories: [],
        types: [],
    });
    const [tableFilterValues, setTableFilterValues] = useState({
        characteristics: [],
        createdBy: [],
        categories: [],
        types: [],
    });
    const customCharacteristicsTableHeaders = [
        {
            name: 'Characteristic',
            key: 'label',
            sort: true,
        },
        {
            name: 'Created By',
            key: 'createdBy',
            sort: true,
        },
        {
            name: 'Created Date',
            key: 'createdDtm',
            sort: true,
        },
        {
            name: 'Category(s)',
            key: 'categories',
            sort: true,
        },
        {
            name: 'Type',
            key: 'type',
            sort: true,
        },
        {}
    ];

    useEffect(() => {
        if (originalCustomCharacteristicsList) {
            setOriginalCustomCharacteristicsList(contextCustomCharacteristics);
        };
    }, [contextCustomCharacteristics]);

    useEffect(() => {
        if (!originalCustomCharacteristicsList) return;
        const newSortedCustomCharacteristicsList = sortCustomCharacteristicsList(originalCustomCharacteristicsList, sortCommands);
        originalCustomCharacteristicsList && setSortedCustomCharacteristicsList(newSortedCustomCharacteristicsList);
        originalCustomCharacteristicsList && updateFilterOptionsLists(originalCustomCharacteristicsList);
    }, [originalCustomCharacteristicsList]);

    useEffect(() => {
        if (!sortedCustomCharacteristicsList) return;
        const newFilteredCustomCharacteristicsList = filterCustomCharacteristicsList(sortedCustomCharacteristicsList);
        setFilteredCustomCharacteristicsList(newFilteredCustomCharacteristicsList);
    }, [sortedCustomCharacteristicsList]);

    useEffect(() => {
        if (!sortCommands) return;
        const newSortedCustomCharacteristicsList = sortCustomCharacteristicsList(originalCustomCharacteristicsList, sortCommands);
        setSortedCustomCharacteristicsList(newSortedCustomCharacteristicsList, sortCommands);
    }, [sortCommands]);

    useEffect(() => {
        if (!tableFilterOptions) return;
    }, [tableFilterOptions]);

    const updateFilteredCustomCharacteristicsList = (dataset = sortedCustomCharacteristicsList) => {
        if (!dataset) return;
        setFilteredCustomCharacteristicsList(dataset);
    };

    useEffect(() => {
        if (originalCustomCharacteristicsList?.length && !tableFilterValues?.characteristics?.length && !tableFilterValues?.createdBy?.length && !tableFilterValues?.categories?.length) {
            updateFilteredCustomCharacteristicsList();
        } else {
            const newFilteredCustomCharacteristicsList = filterCustomCharacteristicsList(sortedCustomCharacteristicsList, tableFilterValues);
            setFilteredCustomCharacteristicsList(newFilteredCustomCharacteristicsList);
        };
    }, [tableFilterValues]);

    const sortCustomCharacteristicsList = (ccList = originalCustomCharacteristicsList, sortVals = sortCommands) => {
        const tempCCList = JSON.parse(JSON.stringify(ccList));

        if (!tempCCList?.length) return tempCCList;

        const sortKeyVal = sortVals.sortKey;
        const sortDirVal = sortVals.sortDir;

        const sortedTempCCList = tempCCList?.sort((a, b) => {
            let val1, val2;
            if (sortDirVal === 'asc') {
                val1 = a; val2 = b;
            } else {
                val1 = b; val2 = a;
            }
            switch (sortKeyVal) {
                case 'createdBy':
                    return (val1?.createdBy?.firstName?.trim()?.toLowerCase() + val1?.createdBy?.lastName?.trim()?.toLowerCase())?.localeCompare((val2?.createdBy?.firstName?.trim()?.toLowerCase() + val2?.createdBy?.lastName?.trim()?.toLowerCase()))
                case 'categories':
                    return (val1?.categories?.[0]?.trim()?.toLowerCase())?.localeCompare((val2?.categories?.[0]?.trim()?.toLowerCase()))
                default:
                    if (typeof val1?.[sortKeyVal] === 'string') {
                        return (val1?.[sortKeyVal]?.trim()?.toLowerCase() || "0")?.localeCompare((val2?.[sortKeyVal]?.trim()?.toLowerCase() || "0"))
                    } else if (typeof val1?.[sortKeyVal] === 'number' || typeof val1?.[sortKeyVal] === 'boolean') {
                        return JSON.stringify(val1?.[sortKeyVal]) - JSON.stringify(val2?.[sortKeyVal]);
                    }
            }
        })

        return sortedTempCCList;
    };

    const filterCustomCharacteristicsList = (ccList = sortedCustomCharacteristicsList, filters = tableFilterValues) => {
        if (!ccList?.length || (!filters?.characteristics?.length && !filters?.createdBy?.length && !filters?.categories?.length)) {
            return ccList;
        };

        const filteredCustomCharacteristicsValues = ccList?.filter((ccListItem) => {
            const characteristicsEval = filters?.characteristics?.includes(ccListItem?.label);
            const createdByEval = filters?.createdBy?.includes(`${ccListItem?.createdBy?.firstName} ${ccListItem?.createdBy?.lastName}`);
            const categoriesEval = filters?.categories?.filter((selectedCategory) => ccListItem?.categories?.includes(selectedCategory))?.length > 0;

            if (filters?.characteristics?.length && filters?.createdBy?.length && filters?.categories?.length) return (characteristicsEval && categoriesEval && createdByEval);
            else if (filters?.characteristics?.length && filters?.createdBy?.length) return (characteristicsEval && createdByEval);
            else if (filters?.createdBy?.length && filters?.categories?.length) return (createdByEval && categoriesEval);
            else if (filters?.characteristics?.length && filters?.categories?.length) return (characteristicsEval && categoriesEval);

            else if (filters?.characteristics?.length) return characteristicsEval;
            else if (filters?.categories?.length) return categoriesEval;
            else if (filters?.createdBy?.length) return createdByEval;
            return false
        });

        return filteredCustomCharacteristicsValues;
    }

    const updateSortCommands = (keyVal, dirVal) => {
        if (keyVal === sortCommands?.sortKey && dirVal === sortCommands?.sortDir) { // return to default sort (by id, descending) if the same sort arrow is clicked twice)
            setSortCommands({
                ...sortCommands,
                sortKey: 'id',
                sortDir: 'desc'
            });
        } else {
            setSortCommands({
                ...sortCommands,
                sortKey: keyVal,
                sortDir: dirVal
            })
        }
    };

    const updateTableFilterValues = (value, type) => {
        setTableFilterValues((prevFilters) => ({
            ...prevFilters,
            [type]: value ? value : [],
        }));
    }

    async function loadCustomCharactistics(customChars = contextCustomCharacteristics) {
        try {
            setLoading(true);
            setOriginalCustomCharacteristicsList(customChars);
        } catch (err) {
            // todo: handle errors
        } finally {
            setLoading(false);
        }
    }

    async function onDelete(idToDelete) {
        if (await confirm({
            title: 'Remove Custom Characteristics',
            content: <>
                <p>Are you sure you want to remove this Characteristics?</p>
            </>,
            yesLabel: 'Yes, Remove'
        })) {
            try {
                setLoading(true)
                await deleteCustomChar(idToDelete);
                // if delete success, remove the deleted custom characteristic from the table
                setFilteredCustomCharacteristicsList(filteredCustomCharacteristicsList.filter((customChar) => customChar.id !== idToDelete))
            } catch (err) {
                // tbd
            } finally {
                setLoading(false);
            }
        }
    }

    async function onEdit(charId) {
        if (await openCharEditor({ charId })) {
            await loadCustomCharactistics();
        }
    }

    useImperativeHandle(ref, () => ({
        async refresh() {
            return await loadCustomCharactistics();
        },
        updateFilteredCustomCharList(newCustomChar) {
            setFilteredCustomCharacteristicsList(preVal => ([...preVal, newCustomChar]));
        }
    }));

    const updateFilterOptionsLists = (ccList) => {
        let filtersList = {
            characteristics: [],
            createdBy: [],
            categories: [],
            types: [],
        };
        ccList.forEach((ccListItem) => {
            filtersList.characteristics.push(ccListItem?.label);
            filtersList.createdBy.push(`${ccListItem?.createdBy?.firstName} ${ccListItem?.createdBy?.lastName}`);
            filtersList.categories.push(...ccListItem?.categories);
            filtersList.types.push(ccListItem?.type);
        });
        Object.keys(filtersList).forEach((filterListKey) => {
            filtersList[filterListKey] = [...new Set(filtersList[filterListKey])]?.sort((a, b) => a?.trim()?.toLowerCase()?.localeCompare(b?.trim()?.toLowerCase()))
        })
        setTableFilterOptions(filtersList);
    };


    return (
        <div className={classnames(`${baseClassName}__container`)}>
            <ByzzerMask show={loading} loading={true}>Loading your characteristics...</ByzzerMask>
            <section className={classnames(`${baseClassName}__filters-section`)}>
                <span className={classnames(`${baseClassName}__title`)}>Characteristics created by your team</span>
                <div className={classnames(`${baseClassName}__filters-container`)}>
                    <div className={classnames(`${baseClassName}__filters`)}>
                        <span className={classnames(`${baseClassName}__filters-label`)}>Filter by: </span>
                        <ByzzerMultiSelect
                            allowClear={true}
                            placeholder="Filter by Characteristic name"
                            value={tableFilterValues?.characteristics}
                            options={tableFilterOptions?.characteristics}
                            onChange={(e) => updateTableFilterValues(e, 'characteristics')}
                            name={'characteristics'}
                            multiSelectWithCheckBox
                        />
                        <ByzzerMultiSelect
                            allowClear={true}
                            placeholder="Filter by Creator"
                            value={tableFilterValues?.createdBy}
                            options={tableFilterOptions?.createdBy}
                            onChange={(e) => updateTableFilterValues(e, 'createdBy')}
                            name={'createdBy'}
                            multiSelectWithCheckBox
                        />
                        <ByzzerMultiSelect
                            allowClear={true}
                            placeholder={'Filter by Category'}
                            value={tableFilterValues?.categories}
                            options={tableFilterOptions?.categories || []}
                            onChange={(e) => updateTableFilterValues(e, 'categories')}
                            multiSelectWithCheckBox
                            name={'categories'}
                        />
                    </div>
                </div>
            </section>
            <table className={classnames(`${baseClassName}`)}>
                <thead className={classnames(`${baseClassName}__head`)}>
                    <tr className={classnames(`${baseClassName}__header-row`)}>
                        {customCharacteristicsTableHeaders?.length ? customCharacteristicsTableHeaders?.map((ccTableheader, index) => {
                            return (
                                <th key={ccTableheader?.name + index} className={classnames(`${baseClassName}__header`)}>
                                    <div key={ccTableheader?.name + index} className={classnames(`${baseClassName}__header-wrapper`)}>
                                    <span className={`${baseClassName}__header-label`}>{ccTableheader?.name}</span>
                                    <div className={`${baseClassName}__header-sort-icons`}>
                                        {
                                            index + 1 === customCharacteristicsTableHeaders?.length ? (
                                                null
                                            ) : (
                                                <>
                                                    <img
                                                        alt='asc'
                                                        className={classnames(`${baseClassName}__header-sort-icon`, {
                                                            [`${baseClassName}__header-sort-icon--disabled`]: sortCommands?.sortKey !== ccTableheader?.key || (sortCommands?.sortKey === ccTableheader?.key && sortCommands?.sortDir !== 'asc')
                                                        })}
                                                        src={upArrow}
                                                        onClick={() => updateSortCommands(ccTableheader?.key, 'asc')}
                                                    />
                                                    <img
                                                        alt='desc'
                                                        className={classnames(`${baseClassName}__header-sort-icon`, {
                                                            [`${baseClassName}__header-sort-icon--disabled`]: sortCommands?.sortKey !== ccTableheader?.key || (sortCommands?.sortKey === ccTableheader?.key && sortCommands?.sortDir !== 'desc')
                                                        })}
                                                        src={downArrow}
                                                        onClick={() => updateSortCommands(ccTableheader?.key, 'desc')}
                                                    />
                                                </>
                                            )
                                        }
                                    </div>
                                    </div>
                                </th>
                            )
                        }) : null}
                    </tr>
                </thead>
                <tbody className={classnames(`${baseClassName}__body`)}>
                    {filteredCustomCharacteristicsList?.length ? filteredCustomCharacteristicsList?.map((ccListItem, index) => (
                        <CustomCharacteristicsTableRow
                            key={ccListItem.label + index}
                            ccListItem={ccListItem}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            baseClass={baseClassName + "-body-row"}
                        />
                    )) : null}
                </tbody>
            </table>
            {originalCustomCharacteristicsList && !filteredCustomCharacteristicsList?.length ? (
                <div className={classnames(`${baseClassName}__no-cc-found`)}>
                    <span>No custom characteristics found based on your filter criteria.</span>
                </div>
            ) : null}
        </div>
    )
}));
export default CustomCharacteristicsTable;
