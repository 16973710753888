import './ByzzerPhoneInput.scss';
import React, {useState} from 'react';
import classnames from 'classnames';
import PhoneInput from "react-phone-input-2";

const baseClassName = 'byzzer-phone-input';
export default function ByzzerPhoneInput({className, label, name, phone, onChange, disabled, ...props}) {

    function onNumberChange(phone, country, e, formattedValue) {

        if(e.target) {
            onChange?.(e)
        }
    }

    return <label className={classnames(baseClassName, 'byzzer-input', className, {
        [`${baseClassName}--disabled`]: disabled
    })}>
        {label && (
            <span className={`${baseClassName}__label`}>{label}</span>
        )}
        <PhoneInput
            country={'us'}
            inputProps={{
                name
            }}
            {...props}
            value={phone}
            onChange={onNumberChange}/>
    </label>
}