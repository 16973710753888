import './Maintenance.scss';
import { useUser } from '@/contexts/UserContext';
import maintenanceLogo from '@images/maintenanceLogo.svg';
import './Authentication.scss';
import { useBetterNavigate } from '@/utils';
import { ByzzerLink } from '@/components/form';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useAccountService } from '@/hooks/useAccountService';

export type MaintenanceProps = {
    eta?: Date;
}

/** Maintenance page */
function Maintenance({eta}: MaintenanceProps) {
    const { isCsrObo } = useUser();
    const {logout} = useAccountService();
    const navigate = useBetterNavigate();
    const signOut = () => {
        logout();
        if (isCsrObo) {
            navigate('/auth/csr_sign_in');
        }
    };

    const firebaseUser = firebase.auth().currentUser;

    return (
        // the authentication class is used to show byzzer icon on top of maintenance dialog
        <div className="authentication maintenance-overlay">
            <div className={`authentication__logo`}>
                <div className={`authentication__logo-image`} />
                <div className={`authentication__logo-text`}>Byzzer</div>
            </div>
            <div className="maintenance">
                <img className="maintenance-logo" src={maintenanceLogo} alt="maintenance-logo" />
                <div className="maintenance-content">
                    <h1 className="maintenance-header">We'll be back soon!</h1>
                    <div className="maintenance-content-body">
                        <p>
                            Sorry for the inconvenience but we're performing some maintenance at the moment. If you need
                            to, you can always contact us at
                            <span className="support-link link"> byzzer.support@smb.nielseniq.com</span> or use the
                            chat.
                        </p>
                        <div className="maintenance-eta">
                            {eta?.getTime() ??  0 > Date.now()
                                ? `We expect to be back online by ${eta?.toLocaleString('en-US', {
                                      dateStyle: 'long',
                                      timeStyle: 'short',
                                  })}`
                                : `We'll be back online shortly!`}
                        </div>
                        {firebaseUser && (
                            <div className="logout-section">
                                    Your session will continue when the system is back, or you may&nbsp;
                                    <ByzzerLink
                                        label="Log out"
                                        onClick={signOut}
                                        trackClick={`maintenance-logout`}
                                    />
                                    &nbsp;now.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Maintenance;
