import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
// import './AlertConfigSelection.scss';

export type AlertConfigSelectionProps = {
    baseClass?: string;
    name?: ReactNode;
    content?: ReactNode;
}

export const AlertConfigSelection = ({
    baseClass,
    name,
    content,
    ...props
}: AlertConfigSelectionProps) => {

    return (
        <div className={classnames(`${baseClass}__alert-data`)}>
            <p className={classnames(`${baseClass}__alert-field`)}>
                <b>{name}</b>
            </p>
            <div className={classnames(`${baseClass}__alert-values`)}>
                {content}
            </div>
        </div>
    );

};

export default AlertConfigSelection;

AlertConfigSelection.displayName = 'AlertConfigSelection';