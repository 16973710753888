import React from 'react';
import { QuoteItemsType } from '@/types/QuoteTypes';
import './QuoteReview.scss';

type QuoteItem = {
    sku: string;
    title: string;
    quantity: number;
    pricePerUnit: number;
    totalPrice: number;
};

type QuoteCartProps = {
    quoteItems?: QuoteItemsType[];
    baseClassName?: string;
};

const QuoteCart = ({ quoteItems, baseClassName }: QuoteCartProps) => {
    const total = quoteItems?.reduce((acc, datum) => {
        return datum.rate + acc;
    }, 0);

    const generateCartData = () => {
        if (quoteItems && quoteItems.length > 0) {
            return quoteItems.map((datum, index) => (
                <tr className="cart-table__row" key={index}>
                    <td>
                        <div>{datum.item}</div>
                    </td>
                    <td>
                        <div>{Math.abs(datum.quantity)}</div>
                    </td>
                    <td>
                        <div>{datum.rate}</div>
                    </td>
                </tr>
            ));
        } else {
            return (
                <tr className="cart-table__row">
                    <td><div>No products available</div></td>
                    <td><div/></td>
                    <td><div/></td>
                </tr>
            );
        }
    };

    return (
        <div className={`${baseClassName}__cart`}>
            <table className="cart-table">
                <thead>
                    <tr className="cart-table__header">
                        <th>
                            <p>Your Cart Details</p>
                        </th>
                        <th>
                            <p>Qty</p>
                        </th>
                        <th>
                            <p>Price</p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {generateCartData()}
                </tbody>
            </table>
            <div className={`${baseClassName}__cart-total`}>
                <p>Total:</p>
                <span>
                    ${Math.round(((total ? total : 0) + Number.EPSILON) * 100) / 100} (Note: Exclusive of Taxes)
                </span>
            </div>
        </div>
    );
};

export default QuoteCart;
