import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { STORY_CONSTANTS } from '@/utils/storiesUtil';

type ProgressInputs = {
    counter: number;
};

function StoryCircularProgressLoader({ counter }: ProgressInputs) {
    return (
        <>
            <div className={'circular-loader__progress'}>
                <CircularProgressbar
                    value={counter}
                    text={`${counter}%`}
                    styles={buildStyles({
                        textColor: STORY_CONSTANTS.colorBlack,
                        pathColor: STORY_CONSTANTS.colorGreen1,
                        trailColor: STORY_CONSTANTS.colorWhite,
                    })}
                />
            </div>
        </>
    );
}

export default StoryCircularProgressLoader;
