import './ByzzerUploadButton.scss';
import React from "react";
import classnames from "classnames";
import {ByzzerButton} from "./ByzzerButton";

const baseClassName = 'byzzer-upload-button';

export function ByzzerUploadButton({
                          trackClick,
                          label,
                          onError,
                          onClick,
                          onChange,
                          disabled,
                          className,
                          primary = "",
                          busy = false,
                          type = "file",
                          children,
                          ...props
                      }) {

    function handleClick() {
        if (!disabled && !busy && onClick) {
            onClick()
        }
    }

    function handleOnChange(e) {
        if (!disabled && !busy && onChange) {
            onChange(e)
        }
    }

    return (
        <label className={classnames(baseClassName, className, {
            [`${baseClassName}--disabled`]: disabled
        })}>
            <ByzzerButton className={`${baseClassName}__button`} onClick={handleClick} label={label}
                          trackClick={trackClick}
                          disabled={disabled} busy={busy} children={children}/>
            <input type="file" hidden onChange={handleOnChange}
                   accept={props.accept}/>
        </label>
    );
}

export default ByzzerUploadButton;

