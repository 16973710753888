import './DimensionRunConfigFilterssStep.scss';
import React, {ReactNode, useEffect, useRef, useState} from "react";
import classnames from "classnames";
import { DimensionRunConfigFilters, DimensionRunConfigFiltersProps} from "./DimensionRunConfigFilters";
import {AccordionWizard, WithUid} from "@byzzer/ui-components";

export type DimensionRunConfigFiltersStepProps = {
    title?: ReactNode;
} & DimensionRunConfigFiltersProps

const baseClassName = 'dimension-report-run-filters-step';

export const DimensionRunConfigFiltersStep = React.forwardRef<WithUid, DimensionRunConfigFiltersStepProps>(
    ({className, title, ...props}, ref ) => {


    const [filtersValid, setFiltersValid] = useState<boolean>(false);

    function handleValidationChange(e: ByzzerValidityChangeEvent) {
        setFiltersValid(e.isValid)
    }

    return <AccordionWizard.Step className={classnames(baseClassName, className)} ref={ref} title={title}>
        <AccordionWizard.Header className={classnames({
            'report-run-config-wizard-header--valid': filtersValid
        })}>
            <h1 className={'report-run-config-wizard__step-title'}>{title}</h1>
        </AccordionWizard.Header>
        <AccordionWizard.Content>
            <DimensionRunConfigFilters {...props} onValidityChange={handleValidationChange}/>
        </AccordionWizard.Content>
        <AccordionWizard.Actions disableNext={!filtersValid} nextDisabledTip={'You must choose all required values to continue.'}/>
    </AccordionWizard.Step>
})

export default DimensionRunConfigFiltersStep;
