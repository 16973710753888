import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './OnboardingEditor.scss';
import { OnboardingWizard } from "./OnboardingWizard";
import { ByzzerMask } from "@/components/ByzzerMask/ByzzerMask";
import { OnboardingContextValue } from "./OnboardingWizardContext";

export type OnboardingEditorProps = {
    data?: any;
}

const baseClassName = 'byz-onboarding-content';

export const OnboardingEditor = ({
    ...props
}: OnboardingEditorProps) => {
    return (
        <OnboardingWizard />
    );

};

export default OnboardingEditor;

OnboardingEditor.displayName = 'OnboardingEditor';