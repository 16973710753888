import React, {Fragment, useContext, useEffect, useRef} from "react";
import useState from 'react-usestateref';
import classnames from 'classnames';
import './SimulatorInputLevers.scss';
import { SimulatorConfig, SimulatorInputGroup, SimulatorInputGroupLayout, SimulatorLeverConfig, SimulatorLeverType, SimulatorValues } from "@/types/SimulatorTypes";
import {ByzzerButton, ByzzerChangeEvent, ByzzerChangeEventHandler, ByzzerTipIcon} from "@byzzer/ui-components";
import { getTypeByCode } from "@/views/simulators/utils";
import { ValueManager } from "@/views/simulators/SimulatorValueManager";
import { LeverInput } from "../LeverInput";
import { ScenarioSimulatorContext } from "../../../ScenarioSimulatorContext";


export type SimulatorInputLeversProps = {
    onChange?: (e: ByzzerChangeEvent<SimulatorValues>) => void;
    onApply?: ByzzerChangeEventHandler<SimulatorValues>;
    name?: string;
    onCollapse: () => void;
    valueManager: ValueManager;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export const DEFAULT_SIM_LEVERS_LAYOUT = 'label-input3x';

export const leverLayoutsWithHeaders = ['label-input3x', 'label-input2x'];

export const SimulatorInputLevers = ({
    onChange,
    onApply,
    // or create ref
    name,
    onCollapse,
    valueManager,
    ...props
}: SimulatorInputLeversProps) => {

    const inputCountsByLayout: Record<SimulatorInputGroupLayout, number> = {
        "label-input": 1,
        "label-input-label-input": 1,
        "label-input2x": 2,
        "label-input3x": 3,
    };

    const baseClassName = 'simulator-input-levers';

    const {
        value: {
            simulatorConfig,
            displayValues,
            simulatorConfig: {
                maxPromoWeeks
            }
        },
        state: {
            collapsed
        }
    } = useContext(ScenarioSimulatorContext);

    const [leverValues, setLeverValues] = useState<Record<string, number | undefined>>({});
    const [calculationCodes, setCalculationCodes] = useState<string[]>([]);

    useEffect(() => {
        if (simulatorConfig) {
            const calcCodes = simulatorConfig.calculations.map(v => v.code);
            setCalculationCodes(calcCodes ?? []);

            setLeverValues(valueManager.getRawValues());
        }
    }, [simulatorConfig?.dataset, displayValues])

    function handleChange(e: ByzzerChangeEvent<number | undefined>): void {

        valueManager.updateLever(e.name!, e.value);
        setLeverValues(valueManager.getRawValues());
        onChange?.({
            name,
            value: valueManager.getRawValues()
        });
    }

    function handleToggleCollapse() {
        onCollapse();
    }

    const ToggleCollapseElement = () => (
        <span className={classnames(`${baseClassName}__toggle-collapse-element`, {
            [`${baseClassName}__toggle-collapse-element--collapsed`]: collapsed,
            [`${baseClassName}__toggle-collapse-element--expanded`]: !collapsed,
        })}/>
    )

    const collapseLeversText = collapsed ? 'Expand to include financials & profit impact' : 'Hide financials & profit impact';

    return (
        <div className={classnames(`${baseClassName}`)} {...props}>
            {/* <header>
                <h1 className={'simulator-preview__title'}>
                    Levers
                </h1> // todo: add support for tool tips here
            </header> */}
            <main className={`${baseClassName}__lever-groups`}>
                {simulatorConfig?.inputGroups.map(({layout = DEFAULT_SIM_LEVERS_LAYOUT, tip, tipMarkdown, title, headers = [], inputs = []}, index) => {

                    const inputCount = inputCountsByLayout[layout];
                    const includeTitle = Boolean(title);
                    const includeHeaders = leverLayoutsWithHeaders.includes(layout);
                    const indexArray = Array.from({length: inputCount});

                    return (
                        <div key={title} className={classnames(`${baseClassName}__lever-group`, `${baseClassName}__lever-group--${layout}`, `${baseClassName}__lever-group--show-separator`)}>
                            {includeTitle && <h2 className={`${baseClassName}__lever-group-title`}>
                                <span>{title}</span>
                                {/* {Boolean(tip ?? tipMarkdown) && <ByzzerTipIcon tip={tip} tipMarkdown={tipMarkdown}/>} */}
                            </h2>}
                            {includeHeaders && Boolean(headers?.length) && (<>
                                <div/> {/* This creates an empty column for headers, it's not here by accident */}
                                {indexArray.map((_, i) => (
                                    <div key={i} className={`${baseClassName}__header`}>
                                        {headers[i]}
                                    </div>
                                ))}
                            </>)}
                            {inputs?.map(({label, inputCodes, tip, tipMarkdown}, i) => (
                                <Fragment key={i}>
                                    <div className={`${baseClassName}__label`}>
                                        <span>{label}:</span>
                                        {/* {Boolean(tip ?? tipMarkdown) && <ByzzerTipIcon tip={tip} tipMarkdown={tipMarkdown}/>} */}
                                    </div>
                                    {indexArray.map((_, i) => {
                                        const code = inputCodes[i];
                                        return (
                                            <LeverInput 
                                                key={code} 
                                                className={classnames(`${baseClassName}__input`)}
                                                name={code}
                                                type={getTypeByCode(code, simulatorConfig)}
                                                onChange={handleChange}
                                                value={leverValues[code]}
                                                readOnly={calculationCodes.includes(code) || !code}
                                                totalValue={leverValues['totalWeeks']} // todo: Integrate this into SimStudio so that weeks isnt hard-coded
                                                maxValue={code.includes('week') ? maxPromoWeeks : undefined} // todo: Integrate this into SimStudio so that weeks isnt hard-coded
                                            />
                                        )
                                    })}
                                </Fragment>
                            ))}
                        </div>
                    )
                })}
            </main>
            <div className={classnames(`${baseClassName}__toggle-collapse`)} onClick={handleToggleCollapse}>
                {ToggleCollapseElement()} {collapseLeversText}
            </div>
        </div>
    );

};

export default SimulatorInputLevers;

SimulatorInputLevers.displayName = 'SimulatorInputLevers';