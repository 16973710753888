import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { ByzzerChangeEventHandler, ByzzerSelect, ByzzerSelectOption } from '@byzzer/ui-components';
import {useTenantApi} from '@/hooks/useTenantApi';

export type SubCategorySelectProps = {
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<string[] | null>;
    value?: string[];
    disabledOptions?: string[];
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    maxSelections?: number;
    categories?: string[];
    allowClear?: boolean;
    allowMultiple?: boolean;
} & OnlyRenderableIf;

export function SubCategorySelect({
    name,
    label,
    onChange,
    disabledOptions = [],
    disabled,
    placeholder,
    className,
    maxSelections,
    categories,
    allowClear = true,
    allowMultiple = true,
    onlyRenderIf = false,
    value,
    ...props
}: SubCategorySelectProps) {
    const { getSubcategoriesForCategories } = useTenantApi();
    const [subCategories, setSubCategories] = useState<ByzzerSelectOption[]>([]);
    const categoriesLocalValue = useRef<string[]>([]);
    const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>([]);

    useEffect(() => {
        (async () => {
            await loadSubCategories();
            if (categories!== undefined && categories?.length > 0 && categoriesLocalValue.current.length === 0) {
                setSelectedSubCategories(value ?? []);
            } else if (categoriesLocalValue.current.length !== 0) {
                onChange?.({
                    value: [],
                    name,
                    data: {
                        subcategories: [],
                    },
                });
                setSelectedSubCategories([]);
            }
            categoriesLocalValue.current = categories ?? [];
        })();
    }, [categories]);

    async function loadSubCategories() {
        if (categories !== undefined && categories?.length > 0) {
            let subCategoriesData: any[] = await getSubcategoriesForCategories(categories);
            setSubCategories(subCategoriesData);
        } else {
            setSubCategories([]);
        }
    }

    function handleChange(e: any) {
        onChange?.({
            value: e?.value,
            name,
            data: {
                subcategories: e?.data?.map((dataItem) => dataItem?.value).flat(),
            },
        });
        setSelectedSubCategories(e.value);
    }

    if (onlyRenderIf === false) return <></>;

    return (
        <ByzzerSelect
            name={name}
            options={subCategories}
            disabled={disabled}
            placeholder={placeholder}
            label={label}
            value={selectedSubCategories}
            maxSelections={maxSelections}
            disabledOptions={disabledOptions}
            onChange={handleChange}
            allowMultiple={allowMultiple}
            allowClear={allowClear}
        />
    );
}

export default SubCategorySelect;
