import './ChoosePackage.scss';
import React from 'react';
import {useSuiteCommerceLink} from '@/components/SuiteCommerceSSO';
import {ByzzerButton} from '@/components/form';
import {DashboardNav} from "@/components/dashboard";
import DashboardFooter from "../../components/dashboard/DashboardFooter";
import classnames from "classnames";
import DashboardContent from "@/components/dashboard/DashboardContent";
import {useUser} from "@/contexts/UserContext";
import DashboardHeader from '@/components/dashboard/DashboardHeader';


const baseClassName = 'choose-package';

export function ChoosePackage({className, ...props}) {
    const ssoLink = useSuiteCommerceLink();
    const {user, subscription} = useUser();

    function onCtaClick() {
        ssoLink.openInNewTab();
    }

    return (
        <div className="dashboard">
            <DashboardNav disabled={true}/>
            <DashboardContent title={'Choose Your Package'} className={classnames(baseClassName, className)}>
                <p>Your account is verified!</p>
                <p>Subscription Order: {subscription?.title}</p>
                <p>
                    Head to the Shop to complete your subscription or report purchase and finish setting up your
                    account.
                </p>
                <ByzzerButton
                    className={`${baseClassName}__cta`}
                    data-test={`${baseClassName}_choose-package-footer-btn`}
                    label={'Head to the Shop'}
                    onClick={onCtaClick}
                />
            </DashboardContent>
            <DashboardHeader/>
        </div>
    );
}

export default ChoosePackage;
