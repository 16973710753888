import React, {forwardRef, useEffect, useState} from "react";
import classnames from "classnames";
import {TrackClick} from "@/analytics";
import {matchPath, NavLink, useLocation, useMatch} from "react-router-dom";
import "./DashboardNavItem.scss";

export const DashboardNavItem = forwardRef((
    {
        disabled = false,
        icon,
        children,
        content = children,
        url,
        otherUrls,
        type,
        onClick,
        subItems,
        className,
        title,
        collapsed,
        isHeader = false,
        toggleFromParent = false,
        toggleParentMenu,
        ...props
    },
    ref
) => {
    const baseClassName = "dashboard-nav-item";
    const [active, setActive] = useState(false);
    const {pathname} = useLocation();
    const paths = [url, ...otherUrls];

    useEffect(() => {
        if (!pathname) return;
        const pathnameMatches =
            paths?.filter((pathToCheck) => pathname.search(pathToCheck) > -1)
                ?.length > 0;
        setActive(pathnameMatches);
    }, [pathname]);

    const handleClick = (e) => {
        if (disabled) e.preventDefault() // prevent navigation if disabled
    }

    return (
        <TrackClick name={`${content} dashboard nav item clicked`}>
            <NavLink 
                to={url}
                ref={ref}
                className={classnames(
                    baseClassName, 
                    className, 
                    `${baseClassName}--${type}`, 
                    {
                        [`${baseClassName}--active`]: active,
                        [`${baseClassName}--collapsed`]: collapsed,
                        [`${baseClassName}--expanded`]: !collapsed,
                        [`${baseClassName}--disabled`]: disabled,
                    }
                )}
                {...props}
            >
                <div className={`${baseClassName}__text`}>
                    {content}
                </div>
            </NavLink>
        </TrackClick>
    )
});
