import classNames from 'classnames';
import './ByzzerLogo.scss';

type ByzzerLogoProps = {
    size?: 'small' | 'medium' | 'large'; // WIP - todo: configure sizes to meet different needs in the dashboard.  ReportViewer needs a smaller version, for example
    onLogoClick?: () => void;
    onLogoImageClick?: () => void;
    onLogoTextClick?: () => void;
}

export const ByzzerLogo = ({
    size = 'medium',
    onLogoClick,
    onLogoImageClick = onLogoClick, 
    onLogoTextClick = onLogoClick
}: ByzzerLogoProps) => {

    const baseClassName = 'byz-logo';

    function handleLogoImageClick() {
        onLogoImageClick?.()
    }

    function handleLogoTextClick() {
        onLogoTextClick?.()
    }

    function handleLogoClick() {
        onLogoClick?.()
    }

    return (
        <div className={classNames(`${baseClassName}`, {
            [`${baseClassName}__cursor`]: onLogoClick
        })} onClick={handleLogoClick}>
            <div className={classNames(`${baseClassName}__image`, `${baseClassName}__image--${size}`, {
                [`${baseClassName}__cursor`]: onLogoClick ?? onLogoImageClick
            })} onClick={handleLogoImageClick}/>
            <div className={classNames(`${baseClassName}__text`, `${baseClassName}__text--${size}`, {
                [`${baseClassName}__cursor`]: onLogoClick ?? onLogoTextClick
            })} onClick={handleLogoTextClick}>Byzzer</div>
        </div>
    );

};

export default ByzzerLogo;

ByzzerLogo.displayName = 'ByzzerLogo';