import { useTenantApi } from "@/hooks";
import { NotificationEvent } from "@/types/EventTypes";
import { ReactNode } from "react";

export const handlersByType: Record<string, Set<NotificationEventHandler>> = {};

type PendingUserApprovalBody = {
    firstName?: string;
    lastName?: string;
    email?: string;
};

type NotificationEventHandler<TEventBody = any> = (
    event: {
        body?: TEventBody;
    },
    tenantApi?: ReturnType<typeof useTenantApi>
) => {
    content?: ReactNode;
    title?: ReactNode;
    type?: string;
    actions?: {
        label?: ReactNode;
        showIf?: boolean;
        boolean?: string;
        action?: ((...args: any[]) => Promise<void>) | ((...args: any[]) => void);
    }[];
    duration?: number;
};

type NotificationType = 'pending_user_approval' | 'data_on_demand_update_toast' | string;

export function registerNotification<TEventBody>(
    type: NotificationType,
    handler: NotificationEventHandler<TEventBody>
) {
    handlersByType[type] = handlersByType[type] ?? new Set<NotificationEventHandler<TEventBody>>();
    handlersByType[type].add(handler);
}

// TODO - See if PendingApproval notification could/should be located in a different file
registerNotification<PendingUserApprovalBody>('pending_user_approval', ({body}) => {
    return {
        title: 'New Pending User Approval',
        content: <>
            <p>{body?.firstName} {body?.lastName}, {body?.email}</p>
        </>,
        duration: 30
    }
})
