import React, { useState } from 'react';
import './MarketMaps.scss';
import DashboardContent from '@/components/dashboard/DashboardContent';
import OutletWithProps from '@/components/OutletWithProps';
import { useParams } from 'react-router-dom';

function MarketMaps() {
    const baseClassName = 'market-maps';
    const { marketMap } = useParams();

    const links = [
        { url: '/dashboard/market_maps/retailer', text: 'Retailer Maps' },
        { url: '/dashboard/market_maps/syndicated', text: 'Syndicated Maps' },
    ];

    return (
        <DashboardContent title={'Market Maps'} className={baseClassName} links={links}>
            <OutletWithProps marketMap={marketMap}/>
        </DashboardContent>
    );
}

export default MarketMaps;
