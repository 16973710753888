import './AssessLevers.scss';
import { ByzzerErrorModal, ByzzerLink, ByzzerModal, ByzzerMultiSelect, ByzzerSelect } from '@/components/form';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { driverList, marketTypes } from '@/config/globalVars';
import {useTenantApi} from '@/hooks/useTenantApi';
import ByzzerSearchableSelect from '@/components/form/ByzzerSearchableSelect';
import classNames from 'classnames';
import classnames from 'classnames';
import { message, Tooltip } from 'antd';
import InlineSVG from 'react-inlinesvg/esm';
import { scorecardConfigs } from '@/config/scorecard.config';
import ScoreInfoImage from '@images/ScoreInfoImage.svg';
import { TrackClick } from '@/analytics';
import { useBetterNavigate } from '@/utils';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { Grade } from '@/components/Grade/Grade';
import { ScorecardTableHeader } from '@/views/scorecard/ScorecardTableHeader';
import { ScorecardEmptyState } from '@/pages/MyScorecardPage';
import { ByzzerTabs } from '@/components/ByzzerTabs';
import { tabsList, TabTypes } from './FunctionInsightScores';

const DRIVER_TITLES_BY_SKU = {
    assortment_opportunity: 'Opportunities',
    assortment_at_risk: 'Risks',
};
const drivers = scorecardConfigs
    .filter(({ key }) => key && !['Scorecard', 'Action Plan'].includes(key))
    .sort((a, b) => a.orderInTable - b.orderInTable)
    .map((driver) => ({
        ...driver,
        title: DRIVER_TITLES_BY_SKU[driver.sku] ?? driver.lever,
    }));

const baseClassName = 'assess-levers';

export function AssessLevers({
    gradeDetails,
    filters,
    onFiltersChange,
    changeMarketSearch,
    changeBrand,
    onMarketTypeChange,
    showAll,
    changeMarket,
    unselectBrand,
    productSet,
    changeCategory,
    changeSingleMarketSearch,
    viewByKey,
    categories = [],
    hasScorecardAccess,
    setOpportunityType,
    ...props
}) {
    const { calculateScores, calculateScoresCount } = useTenantApi();
    const defaultLever = 'promotion';
    const navigate = useBetterNavigate();
    const location: any = useLocation();
    const { lever } = useParams();
    const [loading, setLoading] = useState(false);

    const mainClasses = classNames('my-scorecard');
    const subClasses = classNames('my-scorecard__subclass');
    const [scores, setScores] = useState<any[]>([]);
    const [filteredAndSortedScores, setFilteredAndSortedScores] = useState<any[]>([]);
    const [overallData, setOverallData] = useState<any[]>([]);
    const [activeTab, setActiveTab] = useState<TabTypes>('markets');
    const [showWarning, setShowWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [contentType, setContentType] = useState('warning');
    const [statusInfo, setStatusInfo] = useState(false);
    const [scoreInfoModal, setScoreInfoModal] = useState(false);

    const [infoItem, setInfoItem] = useState<any>();
    const [filterItem, setFilterItem] = useState(true);
    const [filterItemData, setFilterItemData] = useState({ key: 'sales' });
    const [sortMode, setSortMode] = useState('');
    const [activeLever, setActiveLever] = useState(
        location?.state?.activeLever ? location?.state?.activeLever : lever ?? defaultLever
    );
    const [activeDrivers, setActiveDrivers] = useState<any[]>([]);
    const filtersRef = useRef(filters);
    const viewByRef = useRef(activeTab);
    const [searchParams] = useSearchParams();
    const [sort, setSort] = useState({
        by: 'sales',
        direction: -1,
    });
    const [brandCount, setBrandsCount] = useState(0);
    useEffect(() => {
        if (sort) {
            let comparator;
            const { by, direction = 1 } = sort;
            switch (by) {
                case 'sales':
                    comparator = (a, b) => (a.sales - b.sales) * direction;
                    break;
                case 'title':
                    const titleKey = activeTab.toLowerCase();
                    comparator = (a, b) => a.criteria[titleKey][0].localeCompare(b.criteria[titleKey][0]) * direction;
                    break;
                default:
                    comparator = (a, b) => (a[by]?.value - b[by]?.value) * direction;
            }

            setFilteredAndSortedScores(scores.filter(({ Grade }) => !Grade.noData).sort(comparator));
        } else {
            setFilteredAndSortedScores(scores.filter(({ Grade }) => !Grade.noData));
        }
    }, [sort, scores]);

    useEffect(() => {
        viewByRef.current = activeTab;
        if (activeTab === 'markets') {
            if (filters.markets?.length === 1 && filters.markets[0] === 'Total FMCG Retailers') {
                onFiltersChange({ markets: [], marketTypes: marketTypes[1] });
            }
        } else if (filters.markets?.length === 0) {
            onFiltersChange({ markets: [], marketTypes: '' });
        }
        if (validateFilters()) {
            loadScores();
        }
    }, [activeTab]);

    useEffect(() => {
        if (gradeDetails) {
            setActiveTab('markets');
            setActiveLever(gradeDetails.key);
        } else {
            setActiveTab('markets');
        }
    }, [gradeDetails]);

    useEffect(() => {
        setSort({
            by: 'sales',
            direction: -1,
        });
        setActiveDrivers(drivers.filter(({ driver }) => driver === activeLever));
        if (validateFilters()) {
            loadScores();
        }
        setOpportunityType(activeLever); 
    }, [activeLever]);

    useEffect(() => {
        filtersRef.current = filters;
        setOverallData([]);
        setScores([]);
        if (validateFilters()) {
            loadScores();
        }
    }, [filters]);

    useEffect(() => {
        if (viewByKey) {
            setActiveTab(viewByKey.toLowerCase());
        }
    }, [viewByKey]);

    useEffect(() => {
        loadData();
    }, [searchParams]);
    async function loadData() {
        try {
            setLoading(true);
            const brandName = location?.state?.brandParam;
            const categoryName = location?.state?.categoryParam;
            const marketName = location?.state?.marketParam;
            if (brandName !== null || categoryName !== null || marketName !== null)
                onFiltersChange({
                    markets: [...marketName],
                    categories: [...categoryName],
                    brands: [...brandName],
                    marketTypes: '',
                    activeLever: activeLever,
                });
        } catch (e) {
        } finally {
            setLoading(false);
        }
    }

    function toScoreTitle(score) {
        const titleKey = activeTab.toLowerCase();
        return titleKey === 'brands'? score.criteria[titleKey].join(', '):score.criteria[titleKey]?.[0];
    }

    const validateFilters = () => {
        return (
            filters.brands?.length > 0 &&
            filters.categories?.length > 0 &&
            (filters.marketTypes || filters.markets?.length > 0 || !filters.markets?.length)
        );
    };

    const onLeverTabClick = (selectedTab) => {
        setOverallData([]);
        setScores([]);
        setActiveLever(selectedTab.key);
        setOpportunityType(selectedTab);
    };

    const onViewByTabClick = (tab) => {
        setActiveTab(tab.toLowerCase());
    };

    const fetchDataByMarket = async () => {
        if (!hasScorecardAccess) return;

        try {
            setLoading(true);

            const filters = filtersRef.current;
            let allSelection: Record<string, any> = {
                categories: filters.categories,
                brands: filters.brands,
            };
            if (filters.marketTypes) {
                allSelection.marketTypes = [filters.marketTypes];
            } else {
                allSelection.brands = filters.brands;
            }

            const selectedParam = JSON.parse(JSON.stringify(allSelection));

            selectedParam.aggregated = true;
            if (selectedParam.marketTypes) {
                delete selectedParam.marketTypes;
                selectedParam.markets = ['Total US NielsenIQ Brand Score'];
            }
            if (!selectedParam?.markets?.length) {
                selectedParam.markets = ['Total US NielsenIQ Brand Score'];
            }
            let selections: Record<string, any>[] = [];
            if (filters.markets?.length > 0) {
                filters.markets?.forEach((market) => {
                    selections.push({
                        markets: [market],
                        categories: filters.categories,
                        brands: filters.brands,
                    });
                });
            } else {
                selections = [allSelection];
            }
            const [resultByAll, resultByMarket]: any = await Promise.allSettled([
                calculateScores('overall', [selectedParam]),
                calculateScores(activeLever.toLowerCase(), selections),
            ]);
            if (resultByAll.value) {
                setOverallData(resultByAll.value);
            } else {
                setOverallData([]);
            }
            if (resultByMarket.value) {
                setScores(
                    resultByMarket.value
                        .filter((item) => item.sales !== 'No Data')
                        .sort((a, b) => b['sales'] - a['sales'])
                );
            } else {
                setScores([]);
            }
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    async function fetchDataByCategory() {
        try {
            setLoading(true);
            const filters = JSON.parse(JSON.stringify(filtersRef.current));
            if (!filters?.markets?.length) {
                filters.markets = ['Total US NielsenIQ Brand Score'];
            }
            const resultByAll = await calculateScores('overall', [
                {
                    markets: filters.markets,
                    categories: filters.categories,
                    brands: filters.brands,
                    aggregated: true,
                },
            ]);
            if (resultByAll) {
                setOverallData(resultByAll);
            } else {
                setOverallData([]);
            }
            let selections: Record<string, any>[] = [];
            filters.categories?.forEach((category) => {
                selections.push({
                    markets: filters.markets,
                    categories: [category],
                    brands: filters.brands,
                });
            });
            const resultByCategory = await calculateScores(activeLever, selections);

            if (resultByCategory) {
                setScores(
                    resultByCategory.filter((item) => item.sales !== 'No Data').sort((a, b) => b['sales'] - a['sales'])
                );
            } else {
                setScores([]);
            }
        } finally {
            setLoading(false);
        }
    }

    async function fetchDataByBrands() {
        try {
            setLoading(true);
            const filters = JSON.parse(JSON.stringify(filtersRef.current));
            if (!filters?.markets?.length) {
                filters.markets = ['Total US NielsenIQ Brand Score'];
            }
            const countByBrand = await calculateScoresCount('brands', {
                markets: filters.markets,
                categories: filters.categories,
                brands: filters.brands,
            });
            setBrandsCount(countByBrand.brandsCount);
            // const resultByBrand = await calculateScores('brand', [
            //     {
            //         markets: filters.markets,
            //         categories: filters.categories,
            //         brands: filters.brands,
            //         aggregated: true,
            //         offset: 0,
            //         forBrands: true
            //     },
            // ]);
            let selections = {
                markets: filters.markets,
                categories: filters.categories,
                brands: filters.brands,
                aggregated: true,
                offset: 0,
                forBrands: true,
            };
            // resultByBrand.forEach((item) => {
            //     selections.push(item.criteria);
            // });
            const [resultByAll, resultByBrands] = await Promise.all([
                calculateScores('overall', [
                    {
                        markets: filters.markets,
                        categories: filters.categories,
                        brands: filters.brands,
                        aggregated: true,
                    },
                ]),
                calculateScores(activeLever, [selections]),
            ]);
            if (resultByAll) {
                setOverallData(resultByAll);
            } else {
                setOverallData([]);
            }
            if (resultByBrands) {
                setScores(resultByBrands);
            } else {
                setScores([]);
            }
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }

    const loadScores = useMemo(
        () =>
            debounce(async () => {
                if (!hasScorecardAccess) return;
                setBrandsCount(0);
                switch (viewByRef.current) {
                    case 'brands':
                        return fetchDataByBrands();
                    case 'categories':
                        return fetchDataByCategory();
                    case 'markets':
                    // this will fall through to the default which is to load the market data.  It's here for clarity
                    default:
                        return fetchDataByMarket();
                }
            }, 300),
        [activeLever]
    );

    function colorCode(score) {
        let classname = 'my-scorecard-opportunity__subclass-value-table-score-';
        if (score >= 500) classname = classname + 'green';
        else if (score >= 400 && score < 500) classname = classname + 'yellow';
        else if (score >= 300 && score < 400) classname = classname + 'orange';
        else if (score >= 200 && score < 300) classname = classname + 'red';
        else if (score < 200) classname = classname + 'dark-red';
        return classname;
    }

    const renderFilters = () => {
        return (
            <div className={'my-scorecard__selections__container'}>
                <div>
                    <div className="my-scorecard__selections__container-label">Brand:</div>
                    {/* <ByzzerSearch
                        placeholder={'Search for Brand'}
                        onChange={(e) => {
                            changeBrand?.(e);
                        }}
                        value={filterData.brands[0]}
                        categories={categInFocusBrand}
                        type={'brandSearch'}
                    /> */}
                    {/* @ts-ignore */}
                    <ByzzerSearchableSelect
                        seperator={','}
                        enableToolTip={filters.brands?.length > 0 ? true : false}
                        options={filters.brands?.length > 0 ? filters.brands : []}
                        placeholder={filters.brands?.length > 0 ? filters.brands : 'Select your focus brands'}
                        searchPlaceholder={'Search for brand'}
                        onChange={(e) => {
                            unselectBrand?.(e);
                        }}
                        productSet={productSet}
                        searchOnChange={(value, status) => changeBrand?.(value, status)}
                        searchValue={filters.brands?.length > 0 ? filters.brands : ''}
                        searchType={showAll ? 'brandSearchMultiSelect' : 'searchBrandForProductSetMultiSelect'}
                        //productType={productType}
                        // multiBrandSelect={true}
                    />
                </div>
                <div className="my-scorecard__selections__container-label-category">
                    <div className="my-scorecard__selections__container-label">Categories:</div>
                    <div>
                        {/* @ts-ignore */}
                        <ByzzerMultiSelect
                            selectedOptions={filters.categories}
                            disabled={!filters.brands}
                            name={'competitive-productset'}
                            placeholder={'Select from the list'}
                            multiSelectWithCheckBox
                            onChange={(e) => changeCategory?.(e)}
                            options={categories || []}
                            seperator={'OR'}
                            inlineFontStyle="none"
                        />
                    </div>
                </div>
                {activeTab === 'markets' && (
                    <div className={'my-scorecard__selections__container-label-market'}>
                        <div className={'my-scorecard__selections__container-label'}>Market Type:</div>
                        <div>
                            {/* @ts-ignore */}
                            <ByzzerSelect
                                readonly={true}
                                placeholder={'Select Market Type'}
                                allowClear={true}
                                options={marketTypes}
                                onChange={(e) => onMarketTypeChange(e)}
                                value={filters.marketTypes}
                            />
                        </div>
                    </div>
                )}

                <div>
                    <div className="my-scorecard__selections__container-label">Market:</div>
                    <div className="my-scorcard__selections__container market">
                        {activeTab === 'markets' ? (
                            // @ts-ignore
                            <ByzzerSearchableSelect
                                options={filters.markets?.length > 0 ? filters.markets : []}
                                placeholder={'Please Select Market'}
                                searchPlaceholder={'Search for Market'}
                                onChange={(e, type = null) => {
                                    changeMarket(e, type);
                                }}
                                searchOnChange={(value, status) => {
                                    changeMarketSearch(value, status);
                                }}
                                searchType={'marketSearch'}
                                searchValue={filters.markets ?? ''}
                                categories={filters.categories}
                                marketType={filters.marketTypes}
                            />
                        ) : (
                            // @ts-ignore
                            <ByzzerSearchableSelect
                                options={filters.markets?.length > 0 ? filters.markets : []}
                                placeholder={filters.markets?.length > 0 ? filters.markets : 'All'}
                                searchPlaceholder={'Search for Market'}
                                onChange={(e, type = null) => {
                                    changeMarket(e, type);
                                }}
                                searchOnChange={(value) => {
                                    changeSingleMarketSearch?.(value);
                                }}
                                searchType={'marketSingleSearch'}
                                categories={filters.categories}
                                enableToolTip
                                toolTipText="Select a specific market to see detailed scores for each tactic."
                            />
                        )}
                    </div>
                </div>
                {/* <ByzzerButton label={'Submit'} disabled={!validateFilters()} onClick={() => searchResult()} className={"my-scorecard__selections__button"} /> */}
            </div>
        );
    };
    /**
     *
     * @param by
     * @param direction optional
     */
    const sortScores = (by: string, direction?: number) => {
        if (direction) {
            // explicitly set sort direction
            setSort({
                by,
                direction,
            });
        } else if (sort.by === by) {
            // toggle sort direction
            setSort((prevState) => ({
                by,
                direction: prevState.direction * -1,
            }));
        } else {
            // use default asc order
            setSort({
                by,
                direction: 1,
            });
        }

        // if ((bool && filterItem && !mode) || mode === 'asc') {
        //     if (val?.key === 'sales') {
        //         setScores((data) => data.sort((a, b) => a?.sales - b?.sales));
        //     } else {
        //         setScores((data) => data.sort((a, b) => a[val?.key]['value'] - b[val?.key]['value']));
        //     }
        //     setFilterItem(false);
        //     setSortMode('asc');
        // } else if ((!filterItem && !mode) || mode === 'des') {
        //     setFilterItem(true);
        //     if (val?.key === 'sales') {
        //         setScores((data) => data.sort((a, b) => b?.sales - a?.sales));
        //     } else {
        //         setScores((data) => data.sort((a, b) => b[val?.key]['value'] - a[val?.key]['value']));
        //     }
        //     setSortMode('des');
        // }
        // // if(!bool){
        //
        // // }else{
        // //     setFilterItem(!filterItem);
        // // }
        // setFilterItemData(val);
    };
    const renderTableHeader = () => {
        return (
            <>
                {/* @ts-ignore */}
                <ScorecardTableHeader
                    onTitleClick={() => sortScores('title')}
                    onSortClick={(direction) => sortScores('title', direction)}
                    sortActive={sort.by === 'title'}
                    sortDirection={sort.direction}
                >
                    {activeTab.slice(0, 1).toUpperCase() + activeTab.slice(1)}
                </ScorecardTableHeader>
                {/* @ts-ignore */}
                <ScorecardTableHeader
                    onTitleClick={() => sortScores('sales')}
                    onSortClick={(direction) => sortScores('sales', direction)}
                    sortActive={sort.by === 'sales'}
                    sortDirection={sort.direction}
                >
                    $ Sales
                </ScorecardTableHeader>
                {/* @ts-ignore */}
                <ScorecardTableHeader
                    onTitleClick={() => sortScores('Grade')}
                    onSortClick={(direction) => sortScores('Grade', direction)}
                    sortActive={sort.by === 'Grade'}
                    sortDirection={sort.direction}
                >
                    Grade
                </ScorecardTableHeader>
                {activeDrivers.map((driver, key) => (
                    // @ts-ignore
                    <ScorecardTableHeader
                        key={driver.sku}
                        onTitleClick={() => sortScores(driver.key)}
                        onSortClick={(direction) => sortScores(driver.key, direction)}
                        sortActive={sort.by === driver.key}
                        sortDirection={sort.direction}
                        lever={driver.lever}
                        tip={driver.popupDescription}
                    >
                        {driver.title}
                    </ScorecardTableHeader>
                ))}
            </>
        );
    };

    const isDriverScoreEnabled = (markets) => {
        return (markets && (markets.length > 0)) || (activeTab === 'markets')
    }

    const loadReportDashboard = async (scoreCardObj, sku) => {
        const currentLever = scorecardConfigs.find((val) => val.key === sku && val.driver === activeLever);
        const levers = scorecardConfigs.filter((val) => val.driver === activeLever && val.key !== 'At Risk');
        if (!currentLever) {
            // why would this ever happen
            return;
        }

        navigate(`/scorecard/${currentLever.sku}`, {
            state: {
                focusBrands: scoreCardObj?.criteria?.brands,
                focusCategories: scoreCardObj?.criteria?.categories,
                markets: scoreCardObj?.criteria?.markets,
                companyCategories: productSet.map(({ name }) => name),
                activeLever: activeLever,
                currentLeverSku: currentLever.sku
                // scoreCardConfig,
                // drivers: {
                //     // Passed for hamurger menu options
                //     active: activeLever, // Pass current active driver
                //     list: leversList,
                // }
            },
            // statex: {
            //     userData: {
            //         reportSku: skuKey, // To be fetched from configs
            //         reportParams: scoreCardReportParams,
            //         scoreCardConfig,
            //         uncharged: true,
            //         drivers: {
            //             // Passed for hamurger menu options
            //             active: activeLever, // Pass current active driver
            //             list: leversList,
            //         },
            //         selectedMarket: selectedMarket,
            //         currentTab: currentTab,
            //         currentSelectionTab: currentSelectionTab,
            //     },
            // }
        });
    };
    const renderTableData = () => {
        return filteredAndSortedScores.map((score, index) => (
            <tr key={index}>
                <td className="my-scorecard-opportunity__subclass-value-table-market">
                    <div className={`${baseClassName}__score-title`}>{toScoreTitle(score)}</div>
                    <div className={`${baseClassName}__score-links`}>
                        <div
                            className={classnames(`${baseClassName}__score-link`, {
                                [`${baseClassName}__score-link--disabled`]: score.Grade?.grade === 'No Data',
                                'filter-disable': !filters?.markets?.length,
                            })}
                            onClick={() => isDriverScoreEnabled(filters?.markets) && loadReportDashboard(score, `Scorecard`)}
                        >
                            <Tooltip
                                    placement={"top"}
                                    title={isDriverScoreEnabled(filters?.markets) ? '' : 'Select a specific market to see details for each tactic'}
                            >
                                Scorecard
                            </Tooltip>
                        </div>
                        |
                        <div
                            className={classnames(`${baseClassName}__score-link`, {
                                [`${baseClassName}__score-link--disabled`]: score.Grade?.grade === 'No Data',
                                'filter-disable': !filters?.markets?.length,
                            })}
                            onClick={() => isDriverScoreEnabled(filters?.markets) && loadReportDashboard(score, `Action Plan`)}
                        >
                            <Tooltip
                                    placement={"top"}
                                    title={isDriverScoreEnabled(filters?.markets) ? '' : 'Select a specific market to see details for each tactic'}
                            >
                                Action Plan
                            </Tooltip>
                        </div>
                    </div>
                </td>
                <td>{'$' + new Intl.NumberFormat('en-US').format(score.sales.toFixed(0))}</td>
                <td>
                    {/* @ts-ignore */}
                    <Grade grade={score.Grade?.grade} />
                </td>
                {activeDrivers.map(({ key }, index) => {
                    let driverScoreMap = new Map();
                    driverScoreMap.set(key, score[key]);
                    driverScoreMap.set('criteria', score['criteria']);
                    const driverScore = Object.fromEntries(driverScoreMap);
                    //const driverScore = score[key]
                    const hasData = driverScore && !driverScore.noData;
                    const driverScoreValue = Boolean(driverScore[key]?.value);
                    return (
                        <td
                            key={key}
                            onClick={() => {
                                if (hasData && driverScoreValue && isDriverScoreEnabled(filters.markets)) {
                                    loadReportDashboard(driverScore, key);
                                }
                            }}
                            className={classnames({
                                [colorCode(driverScore[key]?.value.toFixed(0))]: hasData && driverScoreValue,
                                'filter-disable': !isDriverScoreEnabled(filters.markets),
                            })}
                        >
                            {hasData && driverScoreValue ? (
                                <Tooltip
                                    placement={"top"}
                                    title={isDriverScoreEnabled(filters.markets) ? '' : 'Select a specific market to see details for each tactic'}
                                >
                                    {driverScore[key]?.value.toFixed(0)}{' '}
                                </Tooltip>
                            ) : (
                                '--'
                            )
                            }
                        </td>
                    );
                })}
            </tr>
        ));
    };
    const lastScrollTop = useRef(0);
    const handleScroll = (e) => {
        const st = e.currentTarget.scrollTop;
        const sh = e.currentTarget.scrollHeight;
        const ch = e.currentTarget.clientHeight;
        const scrollPos = st / (sh - ch);
        if (st > lastScrollTop.current) {
            if (scrollPos >= 0.95 && scrollPos < 1) {
                handleScrollData(e);
            }
        }
        lastScrollTop.current = st;
    };
    const handleScrollData = async (e: any, newLoading?: any) => {
        if (brandCount > scores.length && !loading) {
            setLoading(true);
            const filters = JSON.parse(JSON.stringify(filtersRef.current));
            if (!filters?.markets?.length) {
                filters.markets = ['Total US NielsenIQ Brand Score'];
            }
            // const resultByBrand = await calculateScores('brand', [
            //     {
            //         markets: filters.markets,
            //         categories: filters.categories,
            //         brands: filters.brands,
            //         aggregated: true,
            //         offset: (scores.length/100),
            //         forBrands: true
            //     },
            // ]);
            let selections = {
                markets: filters.markets,
                categories: filters.categories,
                brands: filters.brands,
                aggregated: true,
                offset: Math.floor(scores.length / 100),
                forBrands: true,
            };
            // resultByBrand.forEach((item) => {
            //     selections.push(item.criteria);
            // });
            const [resultByBrands] = await Promise.all([calculateScores(activeLever, selections)]);

            setTimeout(() => {
                if (resultByBrands) {
                    setScores([...scores, ...resultByBrands]);
                }
                setLoading(false);
            }, 400);
        }
    };
    const renderResult = () => {
        return (
            <div className={classnames(`${baseClassName}__content`)}>
                <div className={`my-scorecard__product__tabs`}>
                    {driverList.map((value, key) => {
                        return (
                            value.description && (
                                <TrackClick key={key} name={`Brand Score Scorecards ${value.name} Tab clicked`}>
                                    <div
                                        key={value.key}
                                        className={classnames(`my-scorecard__product__tabs__tab`, {
                                            active: value.key === activeLever,
                                        })}
                                        onClick={() => onLeverTabClick(value)}
                                    >
                                        {value.name} &nbsp;&nbsp;
                                        {overallData.length > 0 && (
                                            // @ts-ignore
                                            <Grade grade={overallData[0][value?.key]?.grade} />)
                                        }
                                    </div>
                                </TrackClick>
                            )
                        );
                    })}
                    <div className={`my-scorecard__product__tabs__tab-extra`} />
                </div>
                <div className={'my-scorecard__grade__container__overall__label2'}>
                    The grades in the tabs above reflect the score across all retailers in the NIQ data, unless specific
                    Markets are selected. If Markets are selected, the tab will show the average grade of those markets.{' '}
                    <br />
                    To learn more about a score, click on the number in the scorecard.
                </div>
                <div
                    className="my-scorecard-opportunity__subclass-value-container-action"
                    onScroll={(e) => handleScroll(e)}
                >
                    <table className={`my-scorecard-opportunity__subclass-value-table`}>
                        <tbody>
                            <tr>{renderTableHeader()}</tr>
                            {renderTableData()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };
    if (hasScorecardAccess === false) {
        return <ScorecardEmptyState />;
    }
    const renderEmptyResult = () => {
        return (
            <div className={'overall-scorecard__empty'}>
                Select a Brand, Category and Market in order to view your scores.
            </div>
        );
    };
    return (
        <div className={mainClasses}>
            <ByzzerMask loading={loading} />
            {/* <div className={subClasses}> */}
                <div className="my-scorecard__subclass-title">
                    <span>
                        Build your functional strategy by assessing different actions. Click into any action's grade to
                        jump to a report analyzing your brand's performance. Go straight to your Action Plan to get
                        direct recommendations across all actions.
                    </span>
                    <TrackClick name={'Brand Score What Does My Score Mean clicked'}>
                        <ByzzerLink
                            className="my-scorecard__subclass-title-link"
                            label={'What does my score mean?'}
                            onClick={() => {
                                setScoreInfoModal(true);
                            }}
                        />
                    </TrackClick>
                </div>

                {/* <div className={"my-scorecard__grade__container-right"}>
                    <div className='my-scorecard__grade__container__overall'>
                        <ByzzerSelectOverallGrade type={'gradeInfo'} value={`Overall Score:`} grade={overallData.length > 0 && overallData[0][activeLever].grade}
                            options={'Your overall grade is based on performance across 4 drivers: Promotion, Pricing, Assortment and New Product launches. Update the selections to see any brand performance for a market(s) or category. Next, compare markets and categories and then assess your score for the levers for each driver to find your opportunities.'}
                            readonly />
                    </div>
                </div> */}
                <div className={'my-scorecard__product__tabs-info'}>View By:</div>
                {/* <div className={'my-scorecard__product__tabs my-scorecard__product__tabs__white'}> */}
                    <ByzzerTabs // https://www.figma.com/design/v3AFUy35pEiu6AsZ3uDqvS/Core?node-id=2896-21755&t=MhHBftPQkVkQaBpG-0
                        activeTab={activeTab}
                        tabsList={tabsList}
                        onClick={onViewByTabClick}
                        type={'filled'} // should only be for page headers per figma?
                    />
                    {/* {views.map((value, key) => {
                        return (
                            <TrackClick name={`Brand Score Scorecards ${value} Tab clicked`} key={key}>
                                <div
                                    className={`my-scorecard__product__tabs__tab ${value === viewBy && 'active'}`}
                                    onClick={() => onViewByTabClick(value)}
                                >
                                    {`${value}`}
                                </div>
                            </TrackClick>
                        );
                    })} */}
                {/* </div> */}
                {renderFilters()}
                {/* <div className={`my-scorecard__product__tabs`}>
                    {driverList.map((value, key) => {
                        return (
                            value.description && (
                                <TrackClick key={key} name={`Brand Score Scorecards ${value.name} Tab clicked`}>
                                    <div
                                        key={value.key}
                                        className={classnames(`my-scorecard__product__tabs__tab`, {
                                            active: value.key === activeLever,
                                        })}
                                        onClick={() => onLeverTabClick(value)}
                                    >
                                        {value.name} &nbsp;&nbsp;
                                        {overallData.length > 0 && (
                                            // @ts-ignore
                                            <Grade grade={overallData[0][value?.key]?.grade} />)
                                        }
                                    </div>
                                </TrackClick>
                            )
                        );
                    })}
                    <div className={`my-scorecard__product__tabs__tab-extra`} />
                </div> */}
                {scores.length > 0 && overallData.length > 0 && !overallData[0][activeLever].noData && renderResult()}
                {Object.keys(scores).length === 0 && renderEmptyResult()}
            {/* </div> */}

            {showWarning && (
                <ByzzerErrorModal
                    showWarning={showWarning}
                    setShowWarning={setShowWarning}
                    headerType={'none'}
                    contentType={contentType}
                    warningMessage={warningMessage}
                    size={'small'}
                />
            )}
            <ByzzerModal
                show={statusInfo}
                onClose={() => setStatusInfo(!statusInfo)}
                closeOnClickOutside={true}
                size={'extra-large'}
                heading={infoItem?.lever}
                type={'info'}
                headerType={'normal'}
            >
                <div className={'my-scorecard__grade__container__item2__card__item2__title-icon-info'}>
                    {infoItem?.popupDescription}
                </div>
            </ByzzerModal>

            <ByzzerModal
                show={scoreInfoModal}
                onClose={() => setScoreInfoModal(!scoreInfoModal)}
                closeOnClickOutside={true}
                size={'medium'}
                //heading={driverList[infoItem].name}
                type={'info'}
                headerType={'none'}
            >
                <div className={'my-scorecard__subclass-title-link-info'}>
                    <div className={'my-scorecard__subclass-title-link-info-description'}>
                        Your score is based on your brand’s performance within the category and market. It is relative
                        to all other brands within the same category, and selling in the same market. The same scale is
                        used to score your performance at the overall level, all the way down to the individual actions.
                        Your score is on a scale from 100-600, with the highest brand in the category receiving a 600
                        and the lowest receiving a 100.
                    </div>
                    <div className={'my-scorecard__subclass-title-link-info-image'}>
                        <InlineSVG src={ScoreInfoImage}/>
                    </div>
                </div>
            </ByzzerModal>
        </div>
    );
}

export default AssessLevers;
