import classnames from "classnames";
import './ByzzerTabs.scss';

const baseClassName = 'byz-tabs';


type ByzzerTabsProps<TValue> = {
    activeTab: TValue;
    tabsList: {display: string; value: TValue}[];
    onClick: (value: TValue) => void;
    alignment?: 'left' | 'center' | 'right' | 'space-between';
    type?: 'default' | 'contained' | 'filled';
};

export function ByzzerTabs<TValue>({ 
    activeTab, 
    tabsList, 
    onClick, 
    alignment = 'left', 
    type = 'default'
}: ByzzerTabsProps<StringLiteral<TValue>>) {
    function handleClick(value: StringLiteral<TValue>) {
        onClick(value)
    }
    return (
        <div className={classnames(`${baseClassName}`, {
            // [`${baseClassName}__contained`]: type === 'contained',
            [`${baseClassName}__filled`]: type === 'filled',
            [`${baseClassName}__align-left`]: alignment === 'left',
            [`${baseClassName}__align-center`]: alignment === 'center',
            [`${baseClassName}__align-right`]: alignment === 'right',
            [`${baseClassName}__align-space-between`]: alignment === 'space-between'
        })}>
            {tabsList.map(({display, value}) => {

                const tabIsActive = activeTab === value;

                return (
                    <a
                        className={classnames({
                            [`${baseClassName}__tab`]: type !== 'filled' && type !== 'contained',
                            [`${baseClassName}__tab-filled`]: type === 'filled',
                            [`${baseClassName}__tab--active`]: tabIsActive,
                            // [`${baseClassName}__tab-contained`]: type === 'contained',
                        })} 
                        key={value}
                        onClick={() => handleClick(value)}
                    >
                        {display}
                    </a>
                )
            })}
        </div>
    )
};

export default ByzzerTabs; 

ByzzerTabs.displayName = 'ByzzerTabs';