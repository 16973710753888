import './Grade.scss';
import classnames from "classnames";
import React, {useEffect, useState} from "react";

const baseClassName = 'grade';

export function Grade({grade, score}) {

    const NO_DATA = <>
        <div>No</div>
        <div>Data</div>
    </>
    const [gradeSuffix, setGradeSuffix] = useState('no-data');
    const [isValidGrade, setIsValidGrade] = useState(false);
    const [displayValue, setDisplayValue] = useState(NO_DATA)
    useEffect(() => {

        const normalizedGrade = grade?.toLowerCase();
        // only allow letter grades of a, b, c, d, f case insensitive;
        if(['a', 'b', 'c', 'd', 'f'].includes(normalizedGrade)) {
            setGradeSuffix(normalizedGrade);
            setIsValidGrade(true);
            setDisplayValue(score ?? grade);
        } else {
            setGradeSuffix('no-data');
            setIsValidGrade(false);
            setDisplayValue(NO_DATA);
        }
    }, [grade])

    return <div className={classnames(baseClassName, `${baseClassName}--${gradeSuffix}`)}>
        {displayValue}
    </div>
}

export default Grade;
