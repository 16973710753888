import classnames from "classnames";
import React from "react";

const baseClassName = 'toggle-group';

export function ToggleGroup({className = '', options = [], value, onChange, ...props}) {
    return (
        <div className={classnames(baseClassName, className)}>
            {options.map((option) => (
                <div key={option.value}
                    className={classnames(`${baseClassName}__option`, {
                        [`${baseClassName}__option--selected`]: value === option.value,
                    })}
                    onClick={() => onChange(option.value)}
                >
                    {option.display}
                </div>
            ))}
        </div>
    );
}
