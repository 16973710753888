import './MarketsToWatchRunConfigFilters.scss';
import React, {forwardRef, ReactNode, useContext, useEffect, useImperativeHandle, useRef, useState, ChangeEvent} from "react";
import classnames from "classnames";
import ByzzerMarketSearch from "@/components/MarketSelection/ByzzerMarketSearch/ByzzerMarketSearch";
import {AlertRunConfig} from "@/types/AlertRun";
import {
    AlertRunConfigWizardContext,
    AlertRunWizardState
} from "@/components/ConfigurationEditors/AlertConfigurationEditor/AlertRunConfigWizard/AlertRunConfigWizardContext";
import {MarketRunConfigOptions} from "@/types/RunConfigOptions";
import {FilterGroup, FilterSection} from '@/components/ConfigurationEditors/FilterGroup';
import OmniMarketSelect from '@/components/OmniMarketSelect/OmniMarketSelect';
import {MarketPicker} from "@/components/MarketPicker";
import {ByzzerInlineSelect, WizardStepContext, ByzzerTipIcon} from "@byzzer/ui-components";
import {LimitedLabel} from "@/components/LimitedLabel";
import {MarketTypeSelector} from '@/components/MarketSelection/MarketTypeSelector';
import {useMarketService} from "@/services/market.service";
import {useUser} from "@/contexts/UserContext";
import {LimitedMarketSelect} from "@/components/LimitedMarketSelect";
import {ByzzerSwitch} from "@/components/form";

export type MarketsToWatchRunConfigFiltersValue = Partial<AlertRunConfig>;

export type MarketsToWatchRunConfigFiltersProps =
    {
        name?: string;
        onValidityChange?: (e: ByzzerValidityChangeEvent) => void;
        /**
         * Used when MarketsToWatchRunConfigFilters is used as controlled component.  Should be used along with the value prop.
         * This prop should be omitted when used within a AlertRunConfigWizardContext because the value in the context will
         * always take precedence.
         */
        onChange?: (e: ByzzerChangeEvent<MarketsToWatchRunConfigFiltersValue>) => void;
        /**
         * Used when MarketsToWatchRunConfigFilters is used as controlled component.  Should be used along with the onChange prop.
         */
        value?: MarketsToWatchRunConfigFiltersValue;
        summary?: ReactNode;
        datatype?: any;
    }
    & Partial<Omit<MarketRunConfigOptions, 'title' | 'type'>>
    & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export type MarketsToWatchRunConfigFiltersRef = {
    value: MarketsToWatchRunConfigFiltersValue;
    isValid: boolean;
};

const baseClassName = 'market-to-watch-alert-run-filters';

export const MarketsToWatchRunConfigFilters = forwardRef<MarketsToWatchRunConfigFiltersRef, MarketsToWatchRunConfigFiltersProps>((
    {
        className,
        value,
        name,
        onChange,
        onValidityChange,
        datatype,
        maxMarkets = Infinity,
        limitMarketsToParentCompany,
        allowMarketTypeSelection,
        includeTotalUSMarkets = false,
        includeFmcgRetailers = false,
        includeSpecialityRetailers = false,
        includeGeographyMarkets = false,
        includePanelTotal = false,
        includePanelChannel = false,
        includeOmniTotalMarkets = false,
        includeOmniSubMarkets = false,
        excludeRemainingMarkets = false,
        requireRemainingMarket = false,
        /* BYZ-8104- we have added "hideTotalUSMarkets" flag to alby to hide and show the total US markets but at the same time we can use them as comparitive market if main market has US market as remaning or comparitive market.
         the same flag has been added in market.services to restrict the user from Total US markets search and market picker to wrap total US market tree with "hideTotalUSMarkets" flag. */
        hideTotalUSMarkets = false,

        requireTotalUSMarkets = false,
        requireFmcgRetailers = false,
        requireSpecialityRetailers = false,
        requireGeographyMarkets = false,
        requirePanelTotal = false,
        requirePanelChannel = false,
        requireOmniTotalMarkets = false,
        requireOmniSubMarkets = false,

        maxOmniTotalMarkets,
        maxOmniSubMarkets,
        marketTypeOptions,
        ...props
    }, ref) => {

    const {features: userFeatures = {}} = useUser();
    const {validateAllMarketsHaveMatchingRemainingMarket} = useMarketService();
    const {value: contextValue, onChange: onContextChange, sku, runType} = useContext(AlertRunConfigWizardContext);
    const {showMessage, hideMessage} = useContext(WizardStepContext);
    const [internalValue, setInternalValue] = useState<MarketsToWatchRunConfigFiltersValue>({});
    const [state, setState] = useState<AlertRunWizardState>({
        enableMarketTypePicker: allowMarketTypeSelection && Boolean(contextValue.markets?.length) ? false : allowMarketTypeSelection ? true : false,
        showRemainingMarkets: false
    });
    const wasValid = useRef<boolean>(false);
    const isOmni = datatype === 'omni';
    const isRms = datatype === 'rms';
    const isCps = datatype === 'cps';

    // todo: maybe this should be flag defined in Alby (but then again, maybe not)
    const limitMarketsToSameGroup = isCps;
    const showMarketTree = true;
    const showMarketSearch = true;
    const categoriesRefreshKey = `${contextValue.categories?.sort().join()}`

    useEffect(() => {

        const isValid = checkValidity();

        if (wasValid.current !== isValid) {
            onValidityChange?.({
                name,
                isValid
            });

            wasValid.current = isValid;
        }

    }, [internalValue.markets, internalValue.marketsToWatch]);

    // this could be problematic and lead to issues where the context and explicit value collide.
    // todo: find a clear way to choose which takes precedence context or value
    useEffect(() => {
        setInternalValue(contextValue ?? {})
    }, [contextValue])
    
    useEffect(() => {
        if (value) {
            setInternalValue(value ?? {});
        }
    }, [value]);

    function handleChange(e: ByzzerChangeEvent<any>) {
        onContextChange?.(e.name as keyof AlertRunConfig, e.value, e.data);
    }

    function handleMarketTypeChange(e: ByzzerChangeEvent<boolean>) {

        onContextChange?.('markets', [], '');
        onContextChange?.('marketsToWatch', undefined, '');
        setState(state => ({
            ...state,
            [e.name!]: e.value
        }))
    }

    function checkValidity(): boolean {
        const hasMarketToWatch = Boolean(internalValue.marketsToWatch?.channel);

        // @ts-ignore
        return (
            (hasMarketToWatch)
        );
    }

    useImperativeHandle(ref, () => ({
        get value() {
            return internalValue;
        },
        get isValid() {
            return checkValidity();
        },
    }));

    return (
        <FilterGroup className={classnames(baseClassName, className)}>
            <FilterSection onlyRenderIf={true}>                
                <MarketTypeSelector
                    name="marketsToWatch"
                    value={internalValue.marketsToWatch}
                    onChange={handleChange}
                    sku={sku}
                    marketTypeOptions={marketTypeOptions}
                />
            </FilterSection>
        </FilterGroup>
    );
})

MarketsToWatchRunConfigFilters.displayName = 'MarketsToWatchRunConfigFilters'
