import React, { useEffect, useRef, useState } from 'react';
import { createModal } from "react-modal-promise";
import { ByzzerButton } from "@byzzer/ui-components";
import './TermsAndConditions.scss';
import { TERMS_AND_CONDITIONS_FILE_LINK } from '@/constants/termsAndCondtions.constants';
import { useUser } from "@/contexts/UserContext";
import {useTenantApi} from '@/hooks/useTenantApi';
import { Spinner } from "@/components/Spinner/Spinner";
import classnames from 'classnames';

const baseClassName = 'terms-and-conditions';

function TermsAndConditions({ isOpen, onResolve, ...props }) {
    const { saveTcsSelection } = useTenantApi();
    const [scrollEnd, setScrollEnd] = useState(false);
    const [busy, setBusy] = useState(false);
    const { refreshUser, user } = useUser();

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) === e.target.clientHeight;
        setScrollEnd(bottom);
    };

    function handleCloseClick() {
        onResolve(false);
    }

    return (
        <div className={classnames(`${baseClassName}-wrapper`, `byzzer-modal-wrapper`)}>
            <div className={classnames(`${baseClassName}`, 'byzzer-modal')}>
                <header className={`${baseClassName}__header`}>
                    <h1 className={`${baseClassName}__title`}>Terms and Conditions</h1>
                    <span className={`${baseClassName}__close`} onClick={handleCloseClick}/>
                </header>
                <main className={`${baseClassName}__content`} onScroll={handleScroll}>
                    <object data={TERMS_AND_CONDITIONS_FILE_LINK + '#toolbar=0&navpanes=0&view=fitH'} type="application/pdf" width="100%" height="100%">
                        <p><a href={TERMS_AND_CONDITIONS_FILE_LINK}>Terms and Conditions</a></p>
                    </object>
                </main>
                {busy && <Spinner />}
                {user?.tcsAccepted && !busy && (
                    <footer className={`${baseClassName}__footer`}>
                        {/* <ByzzerButton className={`byzzer-button--outline`} onClick={() => handleTcsClick(false)}>
                            Decline
                        </ByzzerButton> */}
                        {/* <ByzzerButton className={`byzzer-button`} onClick={() => handleTcsClick(true)}>
                            Accept
                        </ByzzerButton> */}
                    </footer>
                )}
            </div>
        </div>
    );
}
export const openTermsAndConditions = createModal(TermsAndConditions);
