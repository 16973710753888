import React, { ReactNode } from "react";
import { OptionalRequired, OptionalRequiredProps } from "@/components/OptionalRequired";


type SelectSomeLabelProps = {
    max?: number;
    min?: number;
    single?: ReactNode;
    exact?: ReactNode; //Todo- need to find suitable name for "exact" prop
    limited?: ReactNode;
    unlimited?: ReactNode;
    required?: boolean;
    optionalSuffix?: ReactNode;
    includeSuffix?: boolean;
} & OptionalRequiredProps;

export function LimitedLabel({
    max,
    min,
    single,
    exact,
    limited,
    unlimited,
    required,
    includeSuffix,
    optionalSuffix,
    ...props
}: SelectSomeLabelProps) {
    let label: ReactNode;
    if (min && max === min) {
        label = exact;
    } else if (max === 1) {
        label = single;
    } else if (Number(max) > 1 && Number(max) < Infinity) {
        label = limited;
    } else {
        label = unlimited;
    }

    return (
        <OptionalRequired required={required} includeSuffix={includeSuffix} optionalSuffix={optionalSuffix} {...props}>
            {label}
        </OptionalRequired>
    );
}
