/** the MrktEntitlementType is already declared in @src/utils' **/
// export const  MrktEntitlementType = {
//     PRIOR_APPROVAL              : "prior_approval",
//     NOT_PRIOR_APPROVAL          : "not_prior_approval",
//     PARTIAL_PRIOR_APPROVAL      : "partial_prior_approval",
//     REV_SHARE                   : "rev_share",    
//     NOT_REV_SHARE               : "not_rev_share",   
//     CATEGORY_COVERED            : "category_covered",
//     CATEGORY_NOT_COVERED        : "category_not_covered",    
// }

export const MrktEntitlementTypeMessages = {
    TIP_LIMITED_PROMOTION: (
        <div className={'market-picker-node__tip-content'}>
            <p>This market has limited promotion data. For promo reporting, consider using Total FMCG Retailers.</p>
        </div>
    ),

    TIP_PRIOR_APPROVAL_REQUIRED: (
        <div className={'market-picker-node__tip-content'}>
            <p>
                This market requires prior approval by category from the retailer. Reach out to your account rep for assistance submitting a request.
            </p>
        </div>
    ),
    TIP_PRIOR_APPROVAL_REQUIRED_FOR_SPECIFIC_MARKETS: (
        <div className={'market-picker-node__tip-content'}>
            <p>
                This market requires prior approval by category from the retailer followed by an incremental purchase. Please reach out to your Byzzer sales rep.
            </p>
        </div>
    ),
    TIP_PURCHASE_REQUIRED: (
        <div className={'market-picker-node__tip-content'}>
            <p>This is a premium market.</p>
            <p>Contact your NIQ Representative to purchase.</p>
        </div>
    ),

    TIP_HAS_PRIOR_APPROVAL: (
        <div className={'market-picker-node__tip-content'}>
            <p>You have prior approval to run this retailer's account-level data for the category(s) selected.</p>
        </div>
    ),

    TIP_HAS_PARTIAL_PRIOR_APPROVAL: (
        <div className={'market-picker-node__tip-content'}>
            <p>
                You do not have prior approval to run this retailer's account-level data for all of the category(s)
                selected. Some category(s) will be excluded from the report
            </p>
        </div>
    ),

    TIP_HAS_PURCHASED: (
        <div className={'market-picker-node__tip-content'}>
            <p>You have purchased access to this premium retailer.</p>
        </div>
    ),

    TIP_NO_COVERAGE: (
        <div className={'market-picker-node__tip-content'}>
            <p>This market does not have coverage in your selected categories.</p>
        </div>
    ),

    TIP_WRONG_MASTER_COMPANY: (
        <div className={'market-picker-node__tip-content'}>
            <p>You can only choose markets with the same parent company.</p>
            <p>This market has a different parent company than your prior selections.</p>
        </div>
    ),

    TIP_WRONG_MARKET_GROUP: (
        <div className={'market-picker-node__tip-content'}>
            <p>This market has a different market type than your prior selections.</p>
        </div>
    ),

    TIP_NO_AVAILABLE_REMAINING_MARKETS: (
        <div className={'market-picker-node__tip-content'}>
            <p>This market can't be selected because it does not have any associated remaining markets.</p>
        </div>
    ),

    TIP_DISABLED_DEFAULT: (
        <div className={'market-picker-node__tip-content'}>
            <p>Hover over the icons to see why this market is disabled.</p>
        </div>
    ),
};

/**BYZ-7377 : verbiage for FMCG and unique channel markets */
export const MrktInfoIconCopy = {
    TOTAL_FMCG_RETAILERS: 'AKA xAOC, this includes Grocery, Drug, Mass, Dollar, Club, and Military.',
    TOTAL_FMCG_RETAILERS_CONVENIENCE: 'AKA xAOC+Conv, this includes Grocery, Drug, Mass, Dollar, Club, Military, and Convenience.',
    TOTAL_FMCG_RETAILERS_LIQUOR_CONVENIENCE: 'AKA xAOC+Liq+Conv, this includes Grocery, Drug, Mass, Dollar, Club, Military, Liquor Plus, and Convenience.',
    TOTAL_FMCG_RETAILERS_PET_STORES: 'AKA xAOC+Pet, this includes Grocery, Drug, Mass, Dollar, Club, Military, and all Pet across neighborhood and major chains.',
    TOTAL_PET_STORES: 'Contains both neighborhood pet and major chain retailers.',
    TOTAL_NEIGHBORHOOD_PET_RETAIL: 'Does not include major chain retailers. This is a subset of Total Pet.',   
    GIANT_FOOD_TOTAL: 'Giant Food Total maps to Giant Food of Maryland, LLC (headquartered near Landover, MD).',
    THE_GIANT_COMPANY: 'The Giant Company is headquartered in Carlisle, PA.',
     ALLIANCE_RETAIL_GROUP_TOTAL: 'There is no Total AWG and you should not add the markets up to make a Total AWG',
};

