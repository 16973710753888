import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './MarketFilters.scss';
import { FilterGroup, FilterSection } from "@/components/ConfigurationEditors/FilterGroup";
import { MarketRunConfigOptions } from "@/types/RunConfigOptions";
import { DefaultConfigWizardContextValue, DefaultConfigWizardContext, DefaultConfigWizardState } from "../../DefaultsConfigWizard/DefaultsConfigWizardContext";
import { useUser } from "@/contexts/UserContext";
import ByzzerMarketSearch from "@/components/MarketSelection/ByzzerMarketSearch/ByzzerMarketSearch";
import { MarketPicker } from "@/components/MarketPicker";
import { DefaultConfigLabels } from "@/pages/Onboarding/OnboardingSteps";

export type MarketFiltersRef = {
    value?: DefaultConfigWizardContextValue['value'];
}

export type MarketFiltersProps = {
    name?: string;
    onChange?: (e: ByzzerChangeEvent<DefaultConfigWizardContextValue['value']>) => void;
    value?: DefaultConfigWizardContextValue['value'];
    summary?: ReactNode;
    labelsAndTips?: DefaultConfigLabels;
} & Partial<Omit<MarketRunConfigOptions, 'type' | 'title'>> 
  & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export const MarketFilters = forwardRef<MarketFiltersRef, MarketFiltersProps>((
    {
        className,
        includeFmcgRetailers,
        includeGeographyMarkets,
        includeSpecialityRetailers,
        includeTotalUSMarkets,
        limitMarketsToParentCompany,
        maxMarkets,
        name,
        value,
        onChange,
        labelsAndTips,
        summary = labelsAndTips?.markets_header,
        ...props
    }, ref) => {

    const baseClassName = 'byz-market-filters';

    const { value: contextValue, onChange: onContextChange, state: contextState } = useContext(
        DefaultConfigWizardContext
    );

    const [internalValue, setInternalValue] = useState<DefaultConfigWizardContextValue['value']>({});

    const [state, setState] = useState<DefaultConfigWizardState>({
        // enableMarketTypePicker: false,
        // showRemainingMarkets: false
    });

    const { company, features: userFeatures = {}, defaultRunConfig, categories: subscriptionCategories } = useUser();

    function handleChange(e: ByzzerChangeEvent<unknown>) {
        onContextChange?.(e.name as keyof typeof defaultRunConfig, e.value, e.data);
    }

    useImperativeHandle(ref, () => ({
        get value() {
            return internalValue;
        },
    }));

    useEffect(() => {
        setInternalValue(contextValue ?? {});
    }, [contextValue]);

    useEffect(() => {
        if (value) {
            setInternalValue(value);
        }
    }, [value]);

    useEffect(() => {

        const firstMarket = contextValue.markets?.[0];
        const requiredMasterCompany = limitMarketsToParentCompany ? firstMarket?.masterCompany : undefined;
        const requiredMarketGroup = firstMarket?.marketGroup;

        setState(state => ({
            ...state,
            requiredMasterCompany,
            requiredMarketGroup,
        }));
    }, [contextValue.markets]);
    
    const categoriesRefreshKey = `${contextValue.categories?.sort().join()}`

    const categories = Boolean(!contextValue.categories?.length) ? subscriptionCategories : contextValue.categories;

    return (
        <FilterGroup className={classnames(baseClassName, className)}>
            {summary && <div className={`${baseClassName}__summary`}>{summary}</div>}
            <FilterSection
                onlyRenderIf={true}
            >
                <ByzzerMarketSearch
                    name={'markets'}
                    productSku={'57'}
                    categories={categories}
                    requiredMasterCompany={state.requiredMasterCompany}
                    requiredMarketGroup={state.requiredMarketGroup}
                    showRemainingMarkets={state.showRemainingMarkets}
                    value={contextValue.markets}
                    maxSelections={maxMarkets}
                    onChange={handleChange}
                />

                <MarketPicker
                    key={categoriesRefreshKey}
                    value={contextValue.markets}
                    onChange={handleChange}
                    maxSelections={maxMarkets}
                    categories={categories}
                    requiredMasterCompany={state?.requiredMasterCompany}
                    requiredMarketGroup={state?.requiredMarketGroup}
                    name={'markets'}
                    productSku={'57'}
                    showRemainingMarkets={state.showRemainingMarkets}
                    displayMarketGroup={'rms'}
                    purchasedMarketKeys={company?.purchasedMarketKeys}
                    filterType="all"
                />
            </FilterSection>
        </FilterGroup>
    );

});

export default MarketFilters;

MarketFilters.displayName = 'MarketFilters';