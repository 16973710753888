export const reportDataValidMessage = 'Your selections result in data.';
export const reportDataInvalidMessage =
    "There is no data for the selections you've made. Please try different selections in order to run the report.";
export const productLevelsHigherThanCategory = ['Total Store', 'Department', 'Supercategory'];
export const additionalCharDimensionSkus = ['45'];
export const additionalCharDimensions = ['Category'];
export const productLevelInfoHoverMessage =
    'Set the aggregation level for your report to view by Manufacturer or by Brand.';
export const productLevelOptions = ['Brand', 'Manufacturer'];
export const omniTimePeriodOptions = ['Latest Month', 'Latest Year', 'Year-to-Date'];
export const validationRequiredReportTypes = ['rms', 'omni'];
export const cpsRequiredReportType = ['cps'];
export const additionalCharacteristicsDimensions = [{characteristicsCode:'Category',characteristic:'Category',isCustom:false}];
