import { ByzzerButton } from "@byzzer/ui-components";
import React, { useState } from 'react';
import { create as createModal } from 'react-modal-promise';
import ByzzerModal2 from '@/components/modals/ByzzerModal2';
import { ByzzerLink } from "@/components/form";
import { TrackClick } from '@/analytics';
import { ByzzerRadio } from '@byzzer/ui-components';
import { DomainMismatchOption, UserInvite } from '@/types/InvitationTypes';
import { openTermsAndConditions } from "@/components/modals/TermsAndConditions";

export function DomainMismatchWarning({ 
    invitees, 
    onResolve, 
    domainMismatchInvitees 
}: {
    invitees:  UserInvite[];
    onResolve?: (data?: any) => void;
    domainMismatchInvitees: UserInvite[];
}) {
    const [busy, setBusy] = useState(false);
    const [reason, setReason] = useState('');

    const domainMismatchOptions: DomainMismatchOption[] = [
        {
            value: 'anotherDomain',
            display: `${domainMismatchInvitees?.length > 1 ? 'They use' : 'This email uses'} another domain that also belongs to your company.`,
        },
        {
            value: 'personal',
            display: `${domainMismatchInvitees?.length > 1 ? 'These are their personal emails' : 'This is their personal email'} because your company email isn't ready yet.`,
        },
        {
            value: 'externalMember',
            display: `${domainMismatchInvitees?.length > 1 ? 'They are external team members who are' : 'This is an external team member who is'} not a part of your company.`,
        },
    ];

    const baseClassName = 'domain-mismatch-warning';

    function handleReasonSelect(e) {
        setReason(e?.value);
    }

    function onCloseClick() {
        onResolve?.(false);
    }

    const domainMismatchReasonCode = reason;

    const onSendClick = async () => {
        setBusy(true);
        try {
            setBusy(false);
            onResolve?.({
                type: 'domainMismatch',
                data: { domainMismatchReasonCode },
            });
        } catch (e) {
            console.error(e);
        } finally {
            setBusy(false);
        }
    };

    return (
        // @ts-ignore
        <ByzzerModal2 className={`${baseClassName}__modal`}>
            <ByzzerModal2.Header className={`${baseClassName}__modal-title`} onClose={onCloseClick}>
                {domainMismatchInvitees?.length > 1 ? 'Emails do' : 'The Email does'} not match your company domain
            </ByzzerModal2.Header>
            {/* @ts-ignore */}
            <ByzzerModal2.Content>
                <div className={`${baseClassName}__modal-description`}>
                    That's no problem, they can still be invited! Tell us a little more about{' '}
                    {domainMismatchInvitees?.length > 1 ? 'them' : 'this user'}.
                </div>
                {domainMismatchOptions.map((option) => {
                    const { value, display } = option;
                    return (
                        <div className={`${baseClassName}__modal-options-item`}>
                            <ByzzerRadio
                                name={'reason'}
                                key={value}
                                value={value}
                                label={display}
                                checked={reason === value}
                                onChange={handleReasonSelect}
                            />
                        </div>
                    );
                })}
                {invitees?.length && (
                    <div className={`${baseClassName}__modal-details`}>
                        <span>Invited user(s):</span>
                        <span className={`${baseClassName}__modal-emails`}>{invitees?.map((inv) => inv.email).join(', ')}</span>
                    </div>
                )}
                {domainMismatchInvitees?.length && (
                    <div className={`${baseClassName}__modal-details`}>
                        <span>Domain mismatched invitee(s):</span>
                        <span className={`${baseClassName}__modal-emails`}>{domainMismatchInvitees?.map((inv) => inv.email).join(', ')}</span>
                    </div>
                )}
                <div className={`${baseClassName}__modal-tnc`}>
                    Please review the &nbsp;
                    <ByzzerLink label={'Terms & Conditions'} onClick={openTermsAndConditions} /> to make sure this user
                    can be added to your account per the NielsenIQ data-sharing guidelines.
                </div>
            </ByzzerModal2.Content>
            <ByzzerModal2.Footer className={`${baseClassName}__modal-footer`}>
                <ByzzerButton
                    className={`${baseClassName}__modal-footer-cancel`}
                    type="negative"
                    onClick={() => onResolve?.(false)}
                    label="Cancel"
                />
                <TrackClick // todo: may be able to remove track click if we choose to store data in invitation schema
                    name={'invitation_for_mismatched_domain'}
                    data={domainMismatchReasonCode}
                >
                    <ByzzerButton
                        label={busy ? 'Loading...' : 'Send Invite'}
                        disabled={!reason || busy}
                        className={`${baseClassName}__modal-footer-invite`}
                        onClick={onSendClick}
                    />
                </TrackClick>
            </ByzzerModal2.Footer>
        </ByzzerModal2>
    );
}
// @ts-ignore
export const openDomainMismatchWarning = createModal(DomainMismatchWarning);
