import { ByzzerPDFViewer } from "@/components/ByzzerPDFViewer";
import ByzzerModal2 from "@/components/modals/ByzzerModal2";


function MyHomePagePDFViewer({handlePDFModal}){
return (
    <ByzzerModal2 className={undefined} wrapperClassName type>
        <ByzzerModal2.Content className>
            <ByzzerPDFViewer handlePDFModal={handlePDFModal}></ByzzerPDFViewer>
        </ByzzerModal2.Content>
    </ByzzerModal2>
)
}

export default MyHomePagePDFViewer;