import './ProductRunConfigFiltersStep.scss';
import React, {ReactNode, useState} from "react";
import classnames from "classnames";
import {WithUid, WizardActions, WizardContent, WizardHeader, WizardStep} from "@byzzer/ui-components";
import {ProductRunConfigFilters, ProductRunConfigFiltersProps} from "./ProductRunConfigFilters";

export type ProductRunConfigFiltersStepProps = {
    title?: ReactNode;
} & ProductRunConfigFiltersProps;

const baseClassName = 'product-run-config-filters-step';

export const ProductRunConfigFiltersStep = React.forwardRef<WithUid, ProductRunConfigFiltersStepProps>(
    ({className, title, ...props}, ref) => {

        const [filtersValid, setFiltersValid] = useState<boolean>(false);

        function handleValidationChange(e: ByzzerValidityChangeEvent) {
            setFiltersValid(e.isValid)
        }

        return (
            <WizardStep
                className={classnames(baseClassName, className)} 
                ref={ref}
                title={title}
            >
                <WizardHeader className={classnames({
                    'alert-run-config-wizard-header--valid': filtersValid
                })}>
                    <h1 className={'alert-run-config-wizard__step-title'}>
                        {title}
                    </h1>
                </WizardHeader>

                <WizardContent>
                    <ProductRunConfigFilters 
                        {...props}
                        onValidityChange={handleValidationChange}
                    />
                </WizardContent>

                <WizardActions 
                    disableNext={!filtersValid}
                    nextDisabledTip={'You must choose all required values to continue.'}
                />
            </WizardStep>
        )
});

ProductRunConfigFiltersStep.displayName = 'ProductRunConfigFiltersStep';
