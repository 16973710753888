import './GreetingTile.scss';
import React, { useContext } from 'react';
import classnames from 'classnames';
import { ByzzerLink } from '@/components/form';
import { useNavigate } from 'react-router-dom';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useUser } from '@/contexts/UserContext';
import { HomepageContext, useHomePageContext } from '@/contexts/HomepageContext';
import { Spinner } from '@/components/Spinner/Spinner';
import { isEmpty } from 'lodash';

const baseClassName = 'greeting-tile';

export function GreetingTile({
    className,
    tileUpdate = false,
    onFilterPanelClose,
    onFilterPanelOpen,
    enableFilterPanel
}) {
    const { refreshReason, isContextLoading} = useContext(HomepageContext);
    const { tiles, reportletData} = useHomePageContext() ?? {};
    
    const {user, categories, company} = useUser()


    const navigate = useNavigate();
    const goToSubscription = () => {
        navigate('/dashboard/my_subscription/subscription');
    };
    const goToProfile = () => {
        navigate('/dashboard/user_profile/preferences');
    };
    const categoryBool = !categories || categories?.length === 0;
    const tileBool = !tiles || tiles?.length === 0;
    let mainCardClass = '_hide';
    let titleCard = '';
    let titleContent = '';
    const reportletMessage = reportletData?.parameterValues?.message;
    const reportletCategories = reportletData?.parameterValues?.primaryCategory;

    if (categoryBool && !tileUpdate && !isContextLoading) {
        mainCardClass = '_default';
        titleCard = 'Account configuration is not complete.';
        titleContent =
            'You still need to choose your subscription categories before you can use Byzzer. You or an account admin can do this in the';
    } else if (!categoryBool && tileBool && !isContextLoading) {
        mainCardClass = '_default';
        titleCard = 'Your default selections have no data.';
        titleContent = "You may have selected a market or category that doesn't carry your brand. Go to your";
    } else if (!isContextLoading && refreshReason == 'defaultSync') {
        mainCardClass = `_default`;
        titleCard = 'Your preference has been updated.';
        titleContent = 'Your dashboard is being refreshed with the latest data.';
    }else if (refreshReason === 'preferenceChange') {
        mainCardClass = '_default';
        titleCard = 'Your preference have changed.';
        titleContent = 'Updating data with your new preferences.';
    } else if (refreshReason === 'subscriptionChange') {
        mainCardClass = '_default';
        titleCard = 'Your subscription categories have changed.';
        titleContent = "We're updating your Homepage with your new categories.";
    } else if (refreshReason === 'dataRefresh') {
        mainCardClass = '_default';
        titleCard = 'The Byzzer data has refreshed.';
        titleContent = 'We’re updating your Homepage with the latest data.';
    } 
    return (
        <div className={classnames(baseClassName, className)} key={categories?.length}>
            <div className={`${baseClassName}__title_card`}>
                <span className={`${baseClassName}__title`}>Welcome{`, ${user?.firstName}`}!</span>
                { <div className="filter_panel__dropdown__link">
                    {!isContextLoading && (
                        <>
                            {enableFilterPanel ? 'Collapse when done ' : 'Expand to apply new data '}
                            {enableFilterPanel ? (
                                <UpOutlined onClick={onFilterPanelClose} />
                            ) : (
                                <DownOutlined onClick={onFilterPanelOpen} />
                            )}{' '}
                        </>
                    )}
                    {isContextLoading && (
                        <div className={`${baseClassName}__title_card--spinner`}>
                            <Spinner />
                        </div>
                    )}
                </div>}
            </div>
            <div className={`${baseClassName}__title_card${mainCardClass}`} key="tile-card">
                <h2 className={`${baseClassName}__title_default`}>{titleCard}</h2>
                <div className={`${baseClassName}__title_subtext`}>                    
                    {`${titleContent} \u00A0` }
                    {categoryBool && !tileUpdate && !isContextLoading && (
                        <>
                            <ByzzerLink label={'Subscription'} onClick={goToSubscription} />
                            {"\u00A0 page."}
                        </>
                    )}
                    {!categoryBool && tileBool && !isContextLoading && (
                        <>
                            <ByzzerLink label={'Profile'} onClick={goToProfile} />
                            {"\u00A0 to change your selections.\u00A0"}                            
                        </>
                    )}
                </div>
            </div>
            
        </div>
    );
}
