import { forwardRef, ReactNode, useCallback, useRef, useEffect, useState } from 'react';
import classnames from "classnames";
import {ByzzerChangeEventHandler, ByzzerSearch, ByzzerSelectOption, WithValue} from '@byzzer/ui-components';
import {useTenantApi} from '@/hooks/useTenantApi';
import { useApp } from '@/contexts/UserContext';

const baseClassName = 'manufacturer-search';

export type ByzzerManufacturerSearchProps = {
    value?: string[];
    className?: string;
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<string[]>;
    placeholder?: string;
    maxSelections?: number;
    disabled?: boolean;
    categories?: string[];
} & OnlyRenderableIf;

export const ByzzerManufacturerSearch = forwardRef<WithValue<string[]>, ByzzerManufacturerSearchProps>(
    (
        {
            className,
            label,
            name,
            onChange,
            value,
            placeholder = 'Search Your focus Manufacturer',
            disabled,
            categories,
            maxSelections,
            onlyRenderIf = true,
            ...props
        },
        ref
    ) => {
        const {findManufacturersInCategories, manufacturerForCategoriesSearch, searchManufacturerForFocusProduct} = useTenantApi();
        const { categories: categoriesFromSubscription}  = useApp();
        const [internalValue, setInternalValue] = useState<string[]>([]);
        const categoriesRef = useRef<string[]>();

        useEffect(() => {
            setInternalValue(value ?? [])   
        }, [value])

        useEffect(() => {
            categoriesRef.current = categories;
        }, [categories])

        const searchManufacturers = async (searchText: string): Promise<string[]> => {
            let response = await manufacturerForCategoriesSearch({inputText: searchText, categories:categoriesRef.current ?? categoriesFromSubscription});
            return response.manufacturers;
        };

        if(onlyRenderIf === false) return <></>
        const handleChange = (e: ByzzerChangeEvent<ByzzerSelectOption[]>) => {
            onChange?.({
                value: e?.value?.map((brandItem) => brandItem.value),
                name,
                data: e?.data
            })
        }
        return (
            <div className={baseClassName}>
                <ByzzerSearch
                    ref={ref}
                    name={name}
                    label={label}
                    search={searchManufacturers}
                    placeholder={placeholder}
                    value={internalValue}
                    maxSelections={maxSelections}
                    className={classnames(baseClassName, className)}
                    onChange={handleChange}
                />
            </div>
        );
    }
);
export default ByzzerManufacturerSearch;
