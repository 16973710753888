export const LABELS = {
    CHANNELS: 'Total US',
    ACCOUNTS: 'Accounts',
    REGIONS: 'Regions',
    PREMIUM_MARKET_PRIOR_APPROVAL: 'Premium Market & Requires Prior Approval',
    PREMIUM_MARKET_REQUIRES_PURCHASE: 'Premium Market, Requires Purchase',
    REQUIRES_PRIOR_APPROVAL: 'Requires Prior Approval',
    CATEGORY_NOT_COVERED: 'Category Not Covered',
};

export const MARKET_TYPE = {
    CHANNEL: 'channel',
    ACCOUNT: 'account',
    REGION: 'region',
};