import './NoWrap.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";

export type NoWrapProps = React.HTMLAttributes<HTMLDivElement> & {}

const baseClassName = 'no-wrap';

export function NoWrap({className, children, ...props}: NoWrapProps) {
    return <span className={classnames(baseClassName, className)} {...props}>
        {children}
    </span>
}

export default NoWrap;
