import './ReportRunConfigSummaryStep.scss';
import React, {ReactNode, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import classnames from "classnames";
import {WithUid, WizardContext, WizardStepMessageOptions} from "@byzzer/ui-components";
import ReportRunConfigSummary from "@/components/ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigSummaryStep/ReportRunConfigSummary";
// import ReportRunConfigSummary from "@/components/ConfigurationEditor/RunConfigSummaryStep/ReportRunConfigSummary"; // todo: see if this or the one above is needed.  merge conflict
import {ReportRunConfig} from "@/types/ReportRun";
import {useTenantApi} from '@/hooks/useTenantApi';
import {ReportRunConfigWizardContext} from '../ReportRunConfigWizard/ReportRunConfigWizardContext';
import {WizardActions, WizardContent, WizardHeader, WizardStep} from "@byzzer/ui-components";
import {
    AllRunConfigOptions,
    DimensionRunConfigOptions,
    MarketRunConfigOptions,
    ProductRunConfigOptions,
    RunConfigOptions, ShopperSegmentRunConfigOptions,
    TimeRunPeriodConfigOptions
} from "@/types/RunConfigOptions";
import {useReportRunService} from "@/services/reportRun.service";
import { ReportRunValidationResult } from '@/types/ApiTypes';

export type RunConfigSummaryStepProps = {
    title?: ReactNode;
    className?: string;
    onNext?: () => void;
    enabled?: boolean;
    sku?: string;
    busy?: boolean;
    busyNextText?: string;
    busyNextTooltip?: ReactNode;
    runConfigOptions?: RunConfigOptions[];
}


const baseClassName = 'run-config-summary-step';

export const ReportRunConfigSummaryStep = React.forwardRef<WithUid, RunConfigSummaryStepProps>((
    {
        className,
        onNext,
        title = 'Review And Run',
        sku,
        enabled,
        runConfigOptions,
        busy,
        busyNextText,
        busyNextTooltip,
        ...props
    }, ref) => {
        const { validateRunConfig} = useTenantApi();
    const stepRef = useRef<any>();
    const {activeUid} = useContext(WizardContext)
    const {value: runConfig} = useContext(ReportRunConfigWizardContext);
    const {validateRunConfigForSku} = useReportRunService();
    const [validating, setValidating] = useState(false);
    const [validationResult, setValidationResult] = useState<ReportRunValidationResult | null>(null);
    const [validationMessage, setValidationMessage] = useState<WizardStepMessageOptions | undefined>()
    let disableNext = validating || !validationResult?.canRun || busy;
    const nextText = !busy ? 'Generate Report' : busyNextText ?? "Running...";
    const lastValidatedConfig = useRef<string>('');
    const { datatype } = runConfig;
    let disabledTip;

    if (validating) {
        disabledTip = 'Validating your selections';
    } else if (busy) {
        disabledTip = busyNextTooltip ?? 'Your report is generating';
    } else {
        disabledTip = 'See errors above.';
    }

    let nextIconType: string | undefined;

    if (validating || busy) {
        nextIconType = 'busy';
    } else if (validationResult?.canRun !== true) {
        nextIconType = 'error';
    } else {
        nextIconType = 'ok';
    }

    // this is required to allow multiple refs to the step.  needs the dependency array or will cause infinite loop
    useImperativeHandle(ref, () => stepRef.current, []);
    /**
     * api call to fetch if data set is present or not
     * returns boolean
     */
    const checkIfReportDataIsValid = async () => {

        if (!runConfig) return;
        const comparableRunConfig = JSON.stringify(runConfig);

        // don't bother revalidating the config if it hasn't changed
        if (lastValidatedConfig.current === comparableRunConfig) return;

        lastValidatedConfig.current = comparableRunConfig;

        // before bothering to hit the server, make sure all of the required value are set.
        if(!validateRunConfigForSku(sku, runConfig)) {

            setValidationMessage({
                type: 'error',
                content: "You cannot run this report because you have not selected all of the required values."
            });
            // this is a bad idea, but I'm doing it this way for the sake of time, we should be using an explicit
            // invalid or valid flag
            setValidationResult({canRun: false});
            return;
        }


        try {
            setValidating(true);
            setValidationMessage(undefined);
            const validationResult = await validateRunConfig('subscription', sku as string, runConfig as ReportRunConfig);

            if (validationResult.hasOwnProperty('range')) {
                switch (validationResult.range) {
                    case '<75':
                        setValidationMessage({
                            type: "error",
                            content: <>
                                <p>Your selections are not releasable due to low sample.</p>
                                <p>Choose a larger product set, market, or period in order to run this report.</p>
                            </>
                        })
                        break;
                    case '>75':
                        setValidationMessage({
                            type: "warning",
                            content: <>
                                <p>All of your selections are releasable, but some have low sample. If you see a * in the report, use that value directionally.</p>
                            </>
                        });
                        break; 
                    case '75-150':
                        setValidationMessage({
                            type: "warning",
                            content: <>
                                <p>Your selections are releasable but with low sample, between 75-150 occasions. Use
                                    directionally.</p>
                            </>
                        });
                        break; 
                    case '<75>75':
                        setValidationMessage({
                            type: "warning",
                            content: <>
                                <p>Some of your selections are not releasable due to low sample. If you run this report, some values that you selected may not be present.</p>
                            </>
                        });
                        break; 
                    case '>150': // for some CPS reports, perhaps others
                    default:
                        setValidationMessage(undefined);
                        break;
                }
            } else if (!validationResult.canRun) {
                setValidationMessage({
                    type: 'error',
                    content: (
                        <>
                            <p>
                                {datatype === 'omni'
                                    ? "There is no data or not enough sample for the selections you've made."
                                    : "There is no data for the selections you've made."}
                            </p>
                            <p>Please try different selections in order to run the report.</p>
                        </>
                    ),
                });
            } else {
                setValidationMessage(undefined);
            }
            setValidationResult(validationResult);//checkReportValidation(validateReportData));
        } finally {
            setValidating(false);
        }
    }

    useEffect(() => {
        if (activeUid === stepRef.current?.uid) {
            checkIfReportDataIsValid();
        }
    }, [activeUid]);

    function handleNext(): boolean {
        onNext?.();
        return true;
    }

    return <WizardStep className={classnames(baseClassName, className)}
                       byzRef={stepRef}
                       enabled={enabled}
                       message={validationMessage}
                       title={title}>
        <WizardHeader>
            <h1 className={'report-run-config-wizard__step-title'}>{title}</h1>
        </WizardHeader>
        <WizardContent>
            <ReportRunConfigSummary runConfig={runConfig}/>
        </WizardContent>
        <WizardActions disableNext={disableNext}
                       className={'report-run-config-wizard__step-actions'}
                       nextDisabledTip={disabledTip}
                       nextIconType={nextIconType}
                       nextText={nextText}
                       beforeNext={handleNext}/>
    </WizardStep>
});

export default ReportRunConfigSummaryStep;
