import './ProductRunConfigFiltersStep.scss';
import React, {ReactNode, useState} from "react";
import classnames from "classnames";
import {StepRef, WizardActions, WizardContent, WizardHeader, WizardStep} from "@byzzer/ui-components";
import {ProductRunConfigFilters, ProductRunConfigFiltersProps} from "./ProductRunConfigFilters";

export type ProductRunConfigFiltersStepProps = {
    title?: ReactNode;
} & ProductRunConfigFiltersProps;

const baseClassName = 'product-run-config-filters-step';

export const ProductRunConfigFiltersStep = React.forwardRef<StepRef, ProductRunConfigFiltersStepProps>((
    {
        className,
        title,
        ...props
    }, ref) => {

    const [filtersValid, setFiltersValid] = useState<boolean>(false);

    function handleValidationChange(e: ByzzerValidityChangeEvent) {
        setFiltersValid(e.isValid)
    }


    return <WizardStep className={classnames(baseClassName, className)} ref={ref}
                       title={title}>
        <WizardHeader className={classnames({
            'report-run-config-wizard-header--valid': filtersValid
        })}>
            <h1 className={'report-run-config-wizard__step-title'}>{title}</h1>
        </WizardHeader>
        <WizardContent>
            <ProductRunConfigFilters {...props}
                                     onValidityChange={handleValidationChange}/>
        </WizardContent>
        <WizardActions disableNext={!filtersValid}
                       nextDisabledTip={'You must choose all required values to continue.'}/>
    </WizardStep>
});
