import classnames from "classnames";

// TODO: Create separate CSS file for this component to obviate the need for the baseclassname prop

export function CategoryIntersectionMultiValueLabel({children, ...props}: any, baseClassName: string) {
    const metadata = props?.data?.data;
    const {
        categoryMatch,
        aggregationlevel
    } = metadata;
    const isMatch = Boolean(categoryMatch?.length);

    return (aggregationlevel === 'total_store' ? <>{children}</> : (
        <>
            <span className={classnames({
                [`${baseClassName}--match-result-is-match`]: isMatch,
                [`${baseClassName}--match-result-no-match`]: !isMatch
            })}/>
            {children}
        </>
    ))
}

export default CategoryIntersectionMultiValueLabel;

CategoryIntersectionMultiValueLabel.displayName = 'CategoryIntersectionMultiValueLabel';