import { rest } from 'msw';

export const MOCK_CATEGORIES = ['DOG FOOD'];
export const MOCK_CHARACTERISTICS = {
    characteristics: [
        { chr_code: 'brand', chr_display_name: 'Brand' },
        { chr_code: 'branded_vs_private_label', chr_display_name: 'Branded vs Private Label' },
        { chr_code: 'bc_calorie_claim_total_store', chr_display_name: 'Calorie Claim' },
        { chr_code: 'ih_cbd_benefit_claim', chr_display_name: 'CBD Benefit Claim' },
        { chr_code: 'ih_cbd_qualifies', chr_display_name: 'CBD Qualifies' },
        { chr_code: 'claim', chr_display_name: 'Claim' },
        { chr_code: 'common_consumer_name', chr_display_name: 'Common Consumer Name' },
        { chr_code: 'compare_to_claim', chr_display_name: 'Compare to Claim' },
        { chr_code: 'deal_derived', chr_display_name: 'Deal' },
        { chr_code: 'bc_ethnicity_total_store', chr_display_name: 'Ethnicity' },
        { chr_code: 'flavor', chr_display_name: 'Flavor Detail' },
        { chr_code: 'bc_form_total_store', chr_display_name: 'Form' },
        { chr_code: 'form', chr_display_name: 'Form Detail ' },
        { chr_code: 'formulation', chr_display_name: 'Formulation' },
        { chr_code: 'licensed_trademark', chr_display_name: 'Licensed Trademark' },
        { chr_code: 'brand_owner', chr_display_name: 'Manufacturer' },
        { chr_code: 'manufacturing_process', chr_display_name: 'Manufacturing Process' },
        { chr_code: 'natural_positioning_stated', chr_display_name: 'Natural Positioning Stated' },
        { chr_code: 'organic_positioning_stated', chr_display_name: 'Organic Positioning Stated' },
        { chr_code: 'package_material_substance', chr_display_name: 'Package Material' },
        { chr_code: 'package_general_shape', chr_display_name: 'Package Shape' },
        { chr_code: 'package_top_opening', chr_display_name: 'Package Top Opening' },
        { chr_code: 'brand_high', chr_display_name: 'Parent Brand' },
        { chr_code: 'brand_owner_high', chr_display_name: 'Parent Company' },
        { chr_code: 'pet_container', chr_display_name: 'Pet Container' },
        { chr_code: 'pet_gastro_intestin', chr_display_name: 'Pet Gastro Intestinal' },
        { chr_code: 'pet_gluten_free', chr_display_name: 'Pet Gluten Free' },
        { chr_code: 'pet_gmo', chr_display_name: 'Pet GMO' },
        { chr_code: 'pet_grain_free', chr_display_name: 'Pet Grain Free' },
        { chr_code: 'pet_life_stage', chr_display_name: 'Pet Life Stage' },
        { chr_code: 'pet_meal_enhancer', chr_display_name: 'Pet Meal Enhancer' },
        { chr_code: 'pet_natural', chr_display_name: 'Pet Natural' },
        { chr_code: 'pet_organic', chr_display_name: 'Pet Organic' },
        { chr_code: 'pet_flavor', chr_display_name: 'Pet Product Flavor' },
        { chr_code: 'pet_limited_ingredient', chr_display_name: 'Pet Product Limited Ingredient Claim' },
        { chr_code: 'pet_liquid_type', chr_display_name: 'Pet Product Liquid Type' },
        { chr_code: 'pet_preservation', chr_display_name: 'Pet Product Mode of Preservation' },
        { chr_code: 'pet_size', chr_display_name: 'Pet Size' },
        { chr_code: 'pet_derm_skin', chr_display_name: 'Pet Skin Health' },
        { chr_code: 'pet_starch_free', chr_display_name: 'Pet Starch Free' },
        { chr_code: 'pet_breed', chr_display_name: 'Pet Target Breed' },
        { chr_code: 'pet_weight_control', chr_display_name: 'Pet Weight Control' },
        { chr_code: 'product_storage_as_stated', chr_display_name: 'Product Storage Method' },
        { chr_code: 'bc_random_fixed_weight_total_store', chr_display_name: 'Random vs Fixed Weight' },
        { chr_code: 'bc_segment_master', chr_display_name: 'Segment' },
        { chr_code: 'strategic_ingredient_prsnc_clm', chr_display_name: 'Strategic Ingredient Claim' },
        { chr_code: 'unicorn_subcategory', chr_display_name: 'Sub-Category' },
        { chr_code: 'nlsn_brand', chr_display_name: 'SubBrand' },
        { chr_code: 'bc_target_animal_total_store', chr_display_name: 'Target Animal' },
        { chr_code: 'total_size', chr_display_name: 'Total Size' },
        { chr_code: 'number_in_multipack', chr_display_name: 'Units in Multipack' },
    ],
};

const MOCK_CUSTOM_CHARACTERISTICS = [
    {
        id: 946,
        label: 'DogFoodChar',
        type: 'Characteristics',
        isEditable: true,
        createdDtm: '2023-01-02T12:32:31.871Z',
        updatedDtm: '2023-01-02T12:32:38.926Z',
        createdBy: { id: 3988, firstName: 'Jen', lastName: 'Doe', email: 'admin1@activate.qa.byzzer.com' },
        categories: ['DOG FOOD'],
    },
    {
        id: 967,
        label: 'seventh package shape is bottle',
        type: 'Characteristics',
        isEditable: true,
        createdDtm: '2023-01-10T12:23:44.846Z',
        updatedDtm: '2023-01-10T12:23:54.276Z',
        createdBy: { id: 3988, firstName: 'Jen', lastName: 'Doe', email: 'admin1@activate.qa.byzzer.com' },
        categories: ['DISH SOAP'],
    },
    {
        id: 989,
        label: 'TryCustom',
        type: 'Characteristics',
        isEditable: true,
        createdDtm: '2023-02-09T14:29:10.443Z',
        updatedDtm: '2023-02-09T14:29:18.454Z',
        createdBy: { id: 3988, firstName: 'Jen', lastName: 'Doe', email: 'admin1@activate.qa.byzzer.com' },
        categories: ['SHELF STABLE HOT SAUCE AND WING SAUCE'],
    },
    {
        id: 956,
        label: 'DogFood',
        type: 'Characteristics',
        isEditable: true,
        createdDtm: '2023-01-04T12:52:38.033Z',
        updatedDtm: '2023-01-04T12:52:44.768Z',
        createdBy: { id: 3988, firstName: 'Jen', lastName: 'Doe', email: 'admin1@activate.qa.byzzer.com' },
        categories: ['DOG FOOD'],
    },
    {
        id: 992,
        label: 'Form(Custom)',
        type: 'Characteristics',
        isEditable: true,
        createdDtm: '2023-02-13T09:02:33.332Z',
        updatedDtm: '2023-02-13T09:02:41.950Z',
        createdBy: { id: 3988, firstName: 'Jen', lastName: 'Doe', email: 'admin1@activate.qa.byzzer.com' },
        categories: ['COFFEE'],
    },
];
const MOCK_CUSTOM_CHARACTERISTICS_ID = 956;

const MOCK_CUSTOM_CHARACTERISTICS_VALUE = {
    id: 956,
    label: 'DogFood',
    type: 'Characteristics',
    isEditable: true,
    createdDtm: '2023-01-04T12:52:38.033Z',
    updatedDtm: '2023-01-04T12:52:44.768Z',
    categories: ['DOG FOOD'],
    segments: [
        {
            id: 519378,
            name: 'FormisChopped',
            criteria: [[{ chrCode: 'Form', value: ['CHOPPED'], comparator: '=' }]],
            upcs: [
                '003810034047',
                '200028829127',
                '002310011006',
                '200031463735',
                '002310001008',
                '002310011801',
                '200038360497',
                '002310011902',
                '200026876456',
                '002310012151',
                '001780015497',
                '001780016966',
                '002310001078',
                '002310011858',
                '200030974352',
                '200027660301',
                '200030233860',
                '200030739088',
                '002310011800',
                '617582836959',
                '200027962053',
                '200084156347',
                '200028699093',
                '617582869646',
                '200027494190',
                '200031890529',
                '200027328712',
                '001780017966',
                '200030119390',
                '200044184747',
                '001780015498',
                '200029094219',
                '200031591275',
                '200031838760',
                '200031029610',
                '002310012152',
                '200027068501',
                '200031889744',
                '001780015499',
                '003810033019',
                '002310003320',
                '002310011344',
                '002310011856',
                '002310011927',
                '002310001006',
                '200029359210',
                '617582837004',
                '200027492711',
                '337582814878',
                '001780015494',
                '002310012166',
                '002310011816',
                '002310001308',
                '001780015496',
                '003810033035',
                '002310011343',
                '817582803818',
                '647582805356',
                '200031699474',
                '002310011857',
                '001780015495',
                '200031327873',
                '617582845580',
                '200031666648',
                '617582866007',
                '200027458629',
                '200029575359',
                '200038256838',
                '200058490872',
                '002310001076',
                '001780016960',
                '001780016962',
                '200028861042',
                '002310011345',
                '002310081004',
                '076994950746',
                '001780016627',
                '002310012389',
                '002310011078',
            ],
        },
    ],
};

const MOCK_CHARACTERISTICS_VALUE = [
    'A PUP ABOVE (THE GROCERY PUP LLC)',
    'A+ ANSWERS (ANSWERS PET FOOD)',
    'ACANA (CHAMPION PETFOODS)',
    "ACTIVE CARE (BREEDER'S CHOICE PET FOODS INC)",
    'ADDICTION (ADDICTION FOODS)',
    'ADIRONDACK (BLACKWOOD PET FOODS, LLC)',
    'AGAINST THE GRAIN (AGAINST THE GRAIN PET FOODS)',
    'ALMO NATURE (ALMO NATURE S.P.A.)',
    'ALPHA CHEF (ALPHA CHEF)',
    'ALPHA PAW (ALPHA PAW LLC)',
    'AMERICAN NATURAL PREMIUM (BURLO PET SERVICES INC)',
    'ANCESTRY (SAMMY SNACKS INC)',
    'ANNAMAET (ANNAMAET PETFOOD, INC.)',
    'APPLAWS (MPM PRODUCTS USA INC)',
    'APPLE LOVE (NATURAL PETS LLC)',
    'ARMADA GRAIN COMPANY (ARMADA GRAIN COMPANY)',
    'ARTEMIS (ARTEMIS PET FOOD COMPANY INC)',
    'ATTA BOY (AMERICAN NUTRITION INC.)',
    'AUJOU (RAWZ NATURAL PET FOOD)',
    "AUNT JENI'S HOME MADE (AUNT JENI'S HOME MADE)",
    "AVO DERM (BREEDER'S CHOICE PET FOODS INC)",
    'BAHIA BLUE (BAHIA BLUE)',
    'BALANCE+ (ZMF PET FOOD AND TREATS)',
    'BARK BISTRO (BARK BISTRO COMPANY)',
    'BARK BROTH (SN PET HOLDINGS LLC)',
    'BASICS (BASICS BRANDS LLC)',
    'BEAVERDAM (BEAVERDAM PET FOOD INC)',
    'BECAUSE ANIMALS (BECAUSE ANIMALS INC)',
    "BECAUSE IT'S BETTER (BECAUSE IT'S BETTER INC)",
    "BEEF & MORE (BEEF 'N MORE INC)",
    'BEFORE B.G. GRAIN (MERRICK PET CARE LTD)',
    "BEN'S BEST (SHAWNEE MILLING CO)",
    "BENCH & FIELD (BENCH & FIELD/DIV MARTIN'S FEED MILLS)",
    'BIG RED (PRO-PET INC.)',
    'BIG SPOON ROASTERS (BIG SPOON ROASTERS)',
    'BIL JAC PICKY NO MORE (KELLY FOODS CORPORATION)',
    'BIL-JAC (BIL-JAC FOODS INC)',
    'BILLY + MARGOT (REAL PET FOOD COMPANY)',
    'BIXBI (BOULDER ORGANICS LLC)',
    'BLACK GOLD (BLACK GOLD PET FOODS INC)',
    'BLACKWOOD (BLACKWOOD PET FOODS, LLC)',
    'BLUE (THE BLUE BUFFALO CO)',
    'BLUE RIDGE (BLUE RIDGE FISH HATCHERY)',
    'BLUE RIDGE BEEF (BLUE RIDGE BEEF)',
    'BLUE SEAL (KENT NUTRITION GROUP INC)',
    'BONES & CO (THE BONES & CO)',
    'BOREAL (BOREAL PET FOODS)',
    'BORN FREE (BORN FREE FOODS LLC)',
    'BOSS DOG (BOSS DOG BRAND INC)',
    'BRAVO! (BRAVO! LLC)',
    'BRAVO! HOMESTYLE COMPLETE (BRAVO! LLC)',
    'BROTHERS COMPLETE (BROTHERS COMPLETE LLC)',
    'BRUTUS (BRUTUS BROTH LLC)',
    'BULLY MAX (BULLY MAX LLC)',
    'BULLY PERFORMANCE (BULLY PERFORMANCE LLC)',
    "BUTCHER'S BEST (NUNN MILLING COMPANY INC)",
    "BUTCHER'S SELECTS (MARS INCORPORATED)",
    'BY NATURE (BRIGHT PET NUTRITION GROUP)',
    'CAFE NARA (CARU PET FOOD COMPANY)',
    'CALIFORNIA NATURAL (NATURA PET PRODUCTS)',
    'CANADA FRESH (PETKIND PET PRODUCTS INC.)',
    'CANAGAN (SYMPLY PET FOODS LTD)',
    'CANIDAE (CANIDAE CORPORATION)',
    'CANINE BASIC (VITA BRAN/CALIF PET FOOD)',
    'CANINE CAVIAR (CANINE CAVIAR PET FOODS INC)',
    'CANINE NATURALS (GLOBAL ONE PET)',
    'CANISOURCE (CANADA INC)',
    'CANYON CREEK RANCH (CANYON CREEK RANCH)',
    'CARNA4 (CARNA4 INC)',
    'CARNA4 FLORA4 (CARNA4 INC)',
    'CARU (CARU PET FOOD COMPANY)',
    'CASTOR & POLLUX (CASTOR & POLLUX PET WORKS)',
    'CATERED BOWL (COMPLETE CATERED CONCEPTS)',
    'CESAR (MARS INCORPORATED)',
    'CHAMP (SUNSHINE MILLS INC)',
    "CHEF'S PANTRY (AMERICAN NUTRITION INC.)",
    'CHICKEN SOUP FOR THE SOUL (CHICKEN SOUP FOR THE SOUL FOODS LLC)',
    'CHICKEN SOUP FOR THE SOUL (CHICKEN SOUP FOR THE SOUL PET FOOD LLC)',
    'CIRCLE J EXTREME DOG FUEL (CIRCLE J EXTREME LLC)',
    'CLASSIC ENTREES (MENU FOODS INC.)',
    'CLEAR CONSCIENCE PET (CLEAR CONSCIENCE PET LLC)',
    'CLEAR CONSCIENCE PET PAWJUS (CLEAR CONSCIENCE PET LLC)',
    'CLOUD STAR (CLOUD STAR CORPORATION)',
    'COCO LICIOUS (PARTY ANIMAL INC)',
    'COUNTRY ACRES (COUNTRY ACRES LLC)',
    'COUNTRY PET (COUNTRY PET FOODS)',
    'COUNTRY PET NATURALS (COUNTRY PET NATURALS)',
    'COUNTRY VALUE (SCHELL & KAMPETER, INC.)',
    'COUNTRY VET (CONSUMERS SUPPLY DISTRIBUTING LLC)',
    'CRAVE (MARS INCORPORATED)',
    "CRUMPS' NATURALS (THE CRUMP GROUP INC.)",
    "DAD'S (AINSWORTH PET NUTRITION)",
    "DAVE'S (DAVE'S PET FOOD)",
    'DEFENDER (HI-TEK RATIONS INC)',
    'DEFENDER (SUNSHINE MILLS INC)',
    'DIAMOND (DIAMOND PET FOODS INC)',
    "DOC'S CHOICE (RANCHERS CHOICE FOODS INC)",
    'DOCTORS FOSTER AND SMITH (FOSTER & SMITH INC)',
    'DOG BREW BY BUSCH (ANHEUSER-BUSCH INC)',
    'DOG FOR DOG (DOG FOR DOG)',
    'DOGSWELL (DOGSWELL LLC)',
    "DR. B'S LONGEVITY (DR. B'S HOLISTIC PET PRODUCTS, LLC)",
    "DR. GARY'S BEST BREED (BEST BREED INC)",
    "DR. HARVEY'S (DR. HARVEY'S)",
    'DR. MARTY (DR. MARTY)',
    'DR. MERCOLA (MERCOLA HEALTH RESOURCES, LLC)',
    'DR. POL (DOCSON MARKS LLC)',
    "DR. TIM'S (DR. TIM'S PET FOOD COMPANY)",
    'EAGLE MOUNTAIN (MID AMERICA PET FOOD, LLC)',
    'EAGLE PACK (EAGLE PET PRODUCTS INC)',
    'EARTH ANIMAL (JDN INC)',
    'EARTHBORN HOLISTIC (MIDWESTERN PET FOODS INC)',
    'ELEVATE (AMERICAN NUTRITION INC.)',
    'ELEVATE (KENTUCKY PERFORMANCE PRODUCTS LLC)',
    'EMER AID SUSTAIN (EMER AID LLC)',
    "ENHANCE (STEVE'S REAL FOOD INC)",
    'ESSENCE (PETS GLOBAL INC)',
    'ESSEX COTTAGE FARMS (ESSEX COTTAGE FARMS LTD)',
    'ETTA SAYS! (TREAT PLANET LLC)',
    'EUKANUBA (THE IAMS COMPANY)',
    "EVANGER'S (EVANGER'S DOG AND CAT FOOD)",
    'EVERMORE (EVERMORE PET FOOD INC)',
    'EVO (NATURA PET PRODUCTS)',
    'EVOLVE (SUNSHINE MILLS INC)',
    'EVOLVE (TRIUMPH PET INDUSTRIES INC)',
    'EXCLUSIVE (PMI NUTRITION LLC)',
    "EXTREME! (F.M. BROWN'S SONS, INC.)",
    'FARM DOG (FARM DOG NATURALS LLC)',
    "FARMER KEN'S (CHARLES PET FOOD LLC)",
    'FARMERS MARKET (REAL PET FOOD COMPANY)',
    'FARMHOUSE NATURALS (SUNSHINE MILLS INC)',
    'FARMINA N&D (FARMINA PET FOODS)',
    'FARMINA N&D OCEAN (FARMINA PET FOODS)',
    'FARMINA NATURAL & DELICIOUS (FARMINA PET FOODS)',
    'FIELD DOG (KENT)',
    'FIELD TRIAL (SUNSHINE MILLS INC)',
    'FIRST MATE (TAPLOW VENTURES LTD CORPORATION)',
    'FIRSTMATE (FIRSTMATE PET FOODS)',
    'FISH 4 DOGS (FISH 4 DOGS LTD)',
    'FLAVORS (BEAUMONT PRODUCTS INC)',
    'FLINT RIVER RANCH (FFRR LLC)',
    'FORTIS (US PET NUTRITION LLC)',
    'FORZA10 (SANYPET S.P.A.)',
    'FREELY (FREELY PET LLC)',
    "FRENCHIE'S KITCHEN (FRENCHIE'S KITCHEN LLC)",
    'FRESH IS BEST (FRESH IS BEST INC)',
    'FRESHPET (FRESHPET)',
    'FROMM (FROMM FAMILY FOODS, INC.)',
    'FRUITABLES (COMPANA PET BRANDS)',
    'FULL BALANCE (MUENSTER MILLING COMPANY INC)',
    'GAINES MEAL (BIG HEART PET BRANDS)',
    'GATHER (PETCUREAN PET NUTRITION USA INC)',
    'GENTLE GIANTS (GENTLE GIANTS PRODUCTS INC)',
    'GO! SOLUTIONS (PETCUREAN PET NUTRITION USA INC)',
    'GOOD DOG (SUNSHINE MILLS INC)',
    "GRANDMA LUCY'S (GRANDMA LUCY'S LLC)",
    "GRANDMA LUCY'S MOXIE (GRANDMA LUCY'S LLC)",
    "GRANDMA LUCY'S VALOR (GRANDMA LUCY'S LLC)",
    "GRANDMA MAE'S COUNTRY NATURALS (GRANDMA MAE'S COUNTRY NATURALS LLC)",
    'GRAVY TRAIN (BIG HEART PET BRANDS)',
    'GREAT LIFE (GREAT LIFE PERFORMANCE PET PRODUCTS)',
    'GREEN JUJU (GREEN JUJU KITCHEN LLC)',
    'GREEN JUJU (GREEN JUJU)',
    'GRIZZLY SUPER FOODS (GRIZZLY PET PRODUCTS LLC)',
    'GROCERY PUP (GROCERY PUP)',
    'HALO (HALO PURELY FOR PETS)',
    'HALO ELEVATE (HALO PURELY FOR PETS)',
    'HALSHAN (HALSHAN PREMIUM FOOD)',
    'HAPPY HEALTHY DOG (HAPPY HEALTHY DOG)',
    'HAPPY HIPS (DOGSWELL LLC)',
    'HEALTH DIET (PETON DISTRIBUTORS INC)',
    'HEALTH WISE (NATURA PET PRODUCTS)',
    'HEALTHY BY DESIGN (SIMMONS ALLIED PET FOOD INC)',
    'HEALTHY DOGMA (HEALTHY DOGMA)',
    'HEALTHY ELEMENTS (HEALTHY ELEMENTS LLC)',
    'HEIRLOOM PET PRODUCTS (HEIRLOOM PET PRODUCTS)',
    'HI-POINT (SHAWNEE MILLING CO)',
    'HI-STANDARD 21/12 (HI-STANDARD)',
    'HI-STANDARD 22/12 (HI-STANDARD)',
    'HI-STANDARD 23/16 (HI-STANDARD)',
    'HI-STANDARD 26/18 (HI-STANDARD)',
    'HI-STANDARD PURE (HI-STANDARD)',
    'HI-TEK RATIONS (HI-TEK RATIONS INC)',
    'HI-TOR (TRIUMPH PET INDUSTRIES INC)',
    'HIGH HOPES (LIFE WORKS LLC)',
    "HILL'S BIOACTIVE RECIPE (HILL'S PET NUTRITION, INC.)",
    "HILL'S HEALTHY ADVANTAGE (HILL'S PET NUTRITION, INC.)",
    "HILL'S IDEAL BALANCE (HILL'S PET NUTRITION, INC.)",
    "HILL'S PRESCRIPTION DIET (HILL'S PET NUTRITION, INC.)",
    "HILL'S SCIENCE DIET (HILL'S PET NUTRITION, INC.)",
    "HILL'S SCIENCE DIET & PETSMART (HILL'S PET NUTRITION, INC.)",
    'HIMALAYAN DOG CHEW (HIMALAYAN CORPORATION)',
    'HOLI (3D CUSTOM FOODS CORP)',
    'HOLISTIC HEALTH EXTENSION (VETS CHOICE PROFESSIONAL PET PRODUCTS)',
    'HOLISTIC SELECT (WELL PET LLC)',
    'HOMESTYLE STEW (VARIETY PET FOODS LLC)',
    'HONESTLY BARE (SOCIETE DES PRODUITS NESTLE SA)',
    'HORIZON AMICUS (HORIZON MANUFACTURING INC)',
    'HORIZON COMPLETE (HORIZON MANUFACTURING INC)',
    'HORIZON LEGACY (HORIZON MANUFACTURING INC)',
    'HORIZON PULSAR (HORIZON MANUFACTURING INC)',
    'HORIZON TAIGA (HORIZON MANUFACTURING INC)',
    "HOUN' DAWG (LIPSCOMB GRAIN & SEED CO INC)",
    'HOUND & GATOS (HOUND & GATOS PET FOODS CORP)',
    'HUNT CLUB (FARRELL-GOFF INC)',
    'HUNTAWAY (MERIT MEATS LIMITED)',
    "HUNTER'S SPECIAL (SUNSHINE MILLS INC)",
    'I AND LOVE AND YOU (I AND LOVE AND YOU)',
    'I VET HEALTHY GOURMET (I VET PROFESSIONAL FORMULAS)',
    'IAMS (THE IAMS COMPANY)',
    'IDENTITY (IDENTITY PET NUTRITION LLC)',
    'INABA (INABA FOODS, USA)',
    'INCEPTION (PETS GLOBAL INC)',
    'INFINIA (PMI NUTRITION LLC)',
    'INNOVA (NATURA PET PRODUCTS)',
    'INSTINCT THE RAW BRAND (INSTINCT THE RAW BRAND)',
    'INTREPID (INTREPID PET)',
    'INTUITION (SOUTHERN STARZ INC)',
    'INUKSHUK (COREY NUTRITION COMPANY INC)',
    'ISLE OF DOGS NOURISH (ISLE OF DOGS)',
    'JAC PET NUTRITION (JAC PET NUTRITION)',
    "JIMINY'S (JIMINY'S LLC)",
    'JINX (JINX INC)',
    'JOY (BEST FEEDS & FARM SUPPLIES)',
    'JOY SPECIAL (BEST FEEDS & FARM SUPPLIES)',
    'JUST FOOD FOR DOGS (JUST FOOD FOR DOGS LLC)',
    'K-9 KRAVING (BOESL PACKING CO INC)',
    'K9 NATURAL (K9 NATURAL FOOD LTD)',
    'KAL KAN (MARS INCORPORATED)',
    'KARMA (MARS INCORPORATED)',
    'KASIKS (TAPLOW VENTURES LTD)',
    'KENT HI-PROTEIN 27 (KENT NUTRITION GROUP INC)',
    'KETCHPUP (FAST PET FOOD LLC)',
    'KETOGENIC PET WORKS (WYSONG MEDICAL CORPORATION)',
    'KETONA (KETONATURAL PET FOODS INC)',
    'KIBBLES & NUGGETS & NIBBLES (AINSWORTH PET NUTRITION)',
    'KIBBLES DELIGHT (SUNSHINE MILLS INC)',
    "KIBBLES'N BITS (BIG HEART PET BRANDS)",
    'KINETIC PERFORMANCE DOG FOOD (3 AMIGOS NUTRITION GROUP LLC)',
    'KIWI KITCHENS (PET NUTRITION NZ LIMITED PARTNERSHIP)',
    'KOHA (NOOTIE LLC)',
    "KRUSE'S (KRUSE FEED & SUPPLY)",
    'LASSIE (PET-AG INC)',
    'LICK YOUR CHOPS (LICK YOUR CHOPS, INC.)',
    "LIFE'S ABUNDANCE (LIFE'S ABUNDANCE INC)",
    'LIFETIME (TROUW NUTRITION CANADA INC)',
    'LIVELONG HEALTHY & STRONG (LIVELONG PET NUTRITION)',
    'LONE STAR (TEXAS FARM PRODUCTS COMPANY)',
    'LOTUS (LOTUS PET FOOD INC)',
    'LOVE GRUB (REXROAD FOODS)',
    'LOVE YOUR PET (LOVE YOUR PET BAKERY)',
    'LUCY PET PRODUCTS (LUCY PET PRODUCTS)',
    'MAINTAIN (AMERICAN NUTRITION INC.)',
    "MARY'S FREE RANGE (PITMAN FARMS INC)",
    'MAX BONE (MAX BONE INC)',
    'MAX-BONE (MAXFOWLES LLC)',
    'MAXIMUM BULLY (ELITE K9 NUTRITION LLC)',
    'MAZURI (PMI NUTRITION LLC)',
    'MEAT MATES (K9 NATURAL FOOD LTD)',
    'MERIDIAN DAYBREAK (NUNN MILLING COMPANY INC)',
    'MERIDIAN RIVERBEND (NUNN MILLING COMPANY INC)',
    'MERIDIAN TWILIGHT (NUNN MILLING COMPANY INC)',
    'MERIDIAN WESTWARD (NUNN MILLING COMPANY INC)',
    'MERRICK (MERRICK PET CARE LTD)',
    'MERRICK HOLLY JOLLY POT ROAST (MERRICK PET CARE LTD)',
    'MERRICK WINGALING (MERRICK PET CARE LTD)',
    'MIXABLES (VARIETY PET FOODS LLC)',
    'MUENSTER (MUENSTER MILLING COMPANY INC)',
    'MY LITTLE WOLF (WAGGERS PET PRODUCTS INC)',
    'MY PERFECT PET (MY PERFECT PET LLC)',
    'NATIVE (KENT NUTRITION GROUP INC)',
    'NATURA PETZ (NATURA PETZ ORGANICS)',
    'NATURAL BALANCE (NATURAL BALANCE PET FOODS)',
    'NATURAL LIFE (NATURAL LIFE PET PRODUCTS INC.)',
    'NATURAL PET PANTRY (NATURAL PET PANTRY)',
    'NATURAL PLANET (KLN FAMILY BRANDS)',
    'NATURAL VALUE (NATURAL VALUE)',
    'NATURAL WAY SELECTS (CLASSIC MEDIA INC)',
    'NATURALLY COMPLETE (NESTLE PURINA PET CARE COMPANY)',
    "NATURE'S DIET (NATURE'S DIET PET FOOD LLC)",
    "NATURE'S DOMAIN (NATURE'S DOMAIN PET FOOD)",
    "NATURE'S KITCHEN (MAVERICK PET FOODS INC)",
    "NATURE'S LOGIC (NL ENTERPRISES LLC)",
    "NATURE'S MENU (SUNSHINE MILLS INC)",
    "NATURE'S RECIPE (BIG HEART PET BRANDS)",
    "NATURE'S SELECT (NATURE'S SELECT PREMIUM PET PRODUCTS)",
    "NATURE'S VARIETY (NATURE'S VARIETY INC)",
    "NATURE'S VARIETY INSTINCT (NATURE'S VARIETY INC)",
    'NEURA (OLD MOTHER HUBBARD DOG FOOD CO)',
    'NEW ZEALAND COUNTRY PET (NEW ZEALAND COUNTRY PET LIMITED)',
    "NEWMAN'S OWN (NEWMAN'S OWN INC)",
    'NO BRAND LISTED (HI-VI DOG FOOD CO)',
    'NO BRAND LISTED (NO COMPANY LISTED)',
    'NO BRAND LISTED (SOUTHWEST PET PRODUCTS INC)',
    'NOBL (GUARDIAN PET FOOD COMPANY)',
    'NOM NOM (NOM NOM NOW INC)',
    'NORTH COAST (NORTH COAST SEAFOODS)',
    'NORTHWEST NATURALS (NORTHWEST NATURALS)',
    'NOW FRESH (PETCUREAN PET NUTRITION USA INC)',
    "NUGGET'S HEALTHY EATS (NUGGET'S BRANDS INC)",
    'NULO (NULO, INC.)',
    'NUMMY TUM-TUM (NUMMY TUM TUM PET PRODUCTS)',
    'NUNN-BETTER (NUNN MILLING COMPANY INC)',
    'NURTURE (WORLD PET FOODS, INC.)',
    'NURTURE FARMS (SUNSHINE MILLS INC)',
    'NURTURE HEAVENLY HARVEST (SUNSHINE MILLS INC)',
    'NUTRA-NUGGETS (DIAMOND PET FOODS INC)',
    'NUTRENA (CARGILL INCORPORATED)',
    'NUTRI SOURCE (KLN FAMILY BRANDS)',
    'NUTRIENCE (ROLF C. HAGEN INC.)',
    'NUTRISCA (DOGSWELL LLC)',
    'NUTRO (THE NUTRO COMPANY)',
    'OC RAW DOG (OC RAW DOG LLC)',
    'OLD GLORY (SUNSHINE MILLS INC)',
    'OLD MOTHER HUBBARD (WELL PET LLC)',
    "OLE JACK'S (MID SOUTH FEEDS INC)",
    'OLEWO (OLEWO USA)',
    'OLLIE (OLLIE PET INC)',
    'OPEN FARM (OPEN FARM INC)',
    'OPTIMEAL (ZARKAVA LIMITED)',
    "ORGANOMICS (EVANGER'S DOG AND CAT FOOD)",
    'ORIJEN (CHAMPION PETFOODS)',
    'OXGORD PET CARE (OXGORD INC.)',
    'PARAMOUNT (US PET NUTRITION LLC)',
    'PARTY ANIMAL (PARTY ANIMAL INC)',
    "PAULA DEEN HOME COOKIN' (HUGS PET PRODUCTS)",
    'PAULA DEEN HUGS (HUGS PET PRODUCTS)',
    'PEDIGREE (MARS INCORPORATED)',
    'PERFECT BALANCE (MUENSTER MILLING COMPANY INC)',
    'PET BOTANICS (CARDINAL LABORATORIES INC)',
    'PET CUREAN (PETCUREAN PET NUTRITION USA INC)',
    'PET DELI (RB BEARINGER CO)',
    'PET GUARD (PET GUARD INC)',
    'PET KIND (PETKIND PET PRODUCTS INC.)',
    'PET PLATE (PET PLATE INC)',
    'PET PREP (READY STORE LLC)',
    'PET R SCIENCE (ROGUE PET SCIENCE LLC)',
    'PET TOWN (AMERICAN NUTRITION INC.)',
    'PETOTEM (CJ FOODS INC)',
    "PINNACLE (BREEDER'S CHOICE PET FOODS INC)",
    'PINNACLE (PINNACLE PET INC)',
    'PLATO (KDR PET TREATS LLC)',
    'POOCH PLANET (WORLDWISE INC)',
    'PORTLAND PET FOOD COMPANY (PORTLAND PET FOOD COMPANY)',
    'POWER BARK (POWER BARK LLC)',
    'PRECISE (PRECISE PET PRODUCTS)',
    'PREMIUM EDGE (SCHELL & KAMPETER, INC.)',
    'PRIMAL (PRIMAL PET FOODS INC)',
    'PRIVATE LABEL',
    'PRO KAL (RED COLLAR PET FOODS INC)',
    'PRO PAC (MIDWESTERN PET FOODS INC)',
    'PROFESSIONAL+ (SCHELL & KAMPETER, INC.)',
    'PROUD PAWS (ADM ALLIANCE NUTRITION INC)',
    'PUPFORD (PUPFORD LLC)',
    'PURE BITES (PURE TREATS INC)',
    'PURE DOG FOOD (PURE DOG FOOD LLC)',
    'PURINA (NESTLE PURINA PET CARE COMPANY)',
    'PURINA ALPO (NESTLE PURINA PET CARE COMPANY)',
    'PURINA BE HAPPY (NESTLE PURINA PET CARE COMPANY)',
    'PURINA BELLA (NESTLE PURINA PET CARE COMPANY)',
    'PURINA BENEFUL (NESTLE PURINA PET CARE COMPANY)',
    'PURINA BEYOND (NESTLE PURINA PET CARE COMPANY)',
    "PURINA CHEF MICHAEL'S (NESTLE PURINA PET CARE COMPANY)",
    'PURINA DOG CHOW (NESTLE PURINA PET CARE COMPANY)',
    'PURINA FIT & TRIM (NESTLE PURINA PET CARE COMPANY)',
    'PURINA FRISKIES (NESTLE PURINA PET CARE COMPANY)',
    'PURINA KIBBLES AND CHUNKS (NESTLE PURINA PET CARE COMPANY)',
    'PURINA LITTLE BITES (NESTLE PURINA PET CARE COMPANY)',
    'PURINA MIGHTY DOG (NESTLE PURINA PET CARE COMPANY)',
    'PURINA MOIST & MEATY (NESTLE PURINA PET CARE COMPANY)',
    'PURINA ONE (NESTLE PURINA PET CARE COMPANY)',
    'PURINA PRO PLAN (NESTLE PURINA PET CARE COMPANY)',
    'PURINA PUPPY CHOW (NESTLE PURINA PET CARE COMPANY)',
    'PURINA VETERINARY DIETS (NESTLE PURINA PET CARE COMPANY)',
    'PURPOSE (PURPOSE PET FOOD)',
    'R-B (R-B)',
    'RACHAEL RAY NUTRISH (AINSWORTH PET NUTRITION)',
    'RAISED RIGHT (RAISED RIGHT)',
    'RAW BISTRO (RAW BISTRO INC)',
    'RAW DYNAMIC (RAW DYNAMIC LLC)',
    'RAWZ (RAWZ NATURAL PET FOOD)',
    'REAL MEAT (CANZ LLC)',
    'REAL MEAT (THE REAL MEAT COMPANY)',
    'RED BANDANNA (RED BANDANNA PET FOOD)',
    'RED FLANNEL (PMI NUTRITION LLC)',
    'REDBARN (REDBARN PET PRODUCTS INC)',
    'REDBARN NATURALS (REDBARN PET PRODUCTS INC)',
    "REDBUD'S (REDBUD'S RAW GOURMET DOG FOOD)",
    'REDPAW (REDPAW INC)',
    'REPLENISH (REPLENISH PET INC)',
    'REWARD (BIG HEART PET BRANDS)',
    'ROGUE ORIGINS 5 IN1 (ROGUE PET SCIENCE LLC)',
    'ROLI ROTI (ROLI ROTI)',
    'ROOTLAB (NESTLE PURINA PET CARE COMPANY)',
    'ROYAL CANIN (ROYAL CANIN USA INC)',
    "RUDY GREEN'S (RUDY GREEN INC)",
    'SHAWNEE (SHAWNEE MILLING CO)',
    'SHOW TIME (MID SOUTH FEEDS INC)',
    'SIDE BY SIDE (HABI INC)',
    'SILVER SAGE (WILD ROSE FOODS)',
    'SIMPLY NATURAL (CENTRAL GARDEN & PET COMPANY)',
    'SIMPLY PERFECTION (PERFECTION PET FOODS LLC)',
    'SKIPPY (BIG HEART PET BRANDS)',
    'SMACK (SMACK PET FOOD INC)',
    'SMALL BATCH (SMALL BATCH PET FOOD CO)',
    'SMART BONES (SPECTRUM BRANDS INC)',
    'SMART SNACKS (SMART SNACKS COMPANY)',
    'SMILING DOG (HERBSMITH INC)',
    'SOJOS (WELL PET LLC)',
    'SOLID GOLD (SOLID GOLD HEALTH PRODUCTS FOR PETS INC)',
    'SOUTHERN STATES (SOUTHERN STATES COOPERATIVE)',
    'SPORT DOG FOOD (SPORT DOG FOOD PET PRODUCTS)',
    'SPORTMIX (MIDWESTERN PET FOODS INC)',
    "SPORTSMAN'S PRIDE (SUNSHINE MILLS INC)",
    'SPORTSTRAIL (MIDWESTERN PET FOODS INC)',
    'SPOT FARMS (ARTHUR PET PRODUCTS LLC)',
    'SQUARE PET (SQUARE PET NUTRITION LLC)',
    "STELLA & CHEWY'S (STELLA & CHEWY'S LLC)",
    "STELLA & CHEWY'S STELLA'S (STELLA & CHEWY'S LLC)",
    "STEVE'S REAL FOOD (STEVE'S REAL FOOD INC)",
    'STEWART RAW NATURALS (GIMBORN PET SPECIALTIES LLC)',
    'STRONGHEART (FARMLAND FOODS DIV)',
    'STRONGHEART (STRONGHEART PRODUCTS INC)',
    'SUNSHINE (SUNSHINE MILLS INC)',
    'SUPREME (KLN FAMILY BRANDS)',
    'SUPREME SOURCE (AMERICAN NUTRITION INC.)',
    'SWELL (SWELL GELATO LLC)',
    'TASTE OF THE WILD (TASTE OF THE WILD PET FOOD)',
    'TEAM DOG (TRIKOS INTERNATIONAL)',
    'TENDER & TRUE (TENDER & TRUE PET NUTRITION)',
    'THE BEAR & THE RAT (THE BEAR & THE RAT: COOL TREATS FOR DOGS)',
    'THE BERKSHIRE DOG (THE BERKSHIRE DOG LLC)',
    'THE GOODLIFE RECIPE (THE GOODLIFE RECIPE PETCARE CO)',
    'THE HONEST KITCHEN (THE HONEST KITCHEN INC)',
    'THE MISSING LINK (DESIGNING HEALTH INC)',
    'THE NATURAL DOG COMPANY (THE NATURAL DOG COMPANY)',
    'THE NEW ZEALAND NATURAL PET FOOD CO (THE NEW ZEALAND NATURAL PET FOOD CO)',
    'THE OSSO GOOD COMPANY (THE OSSO GOOD CO)',
    'THE PRIDE DOG FOOD (THE HYLAND COMPANY INC)',
    'THE SIMPLE FOOD PROJECT (THE SIMPLE FOOD PROJECT)',
    'THREE DOG BAKERY (THREE DOG BAKERY)',
    'THRIFTY (SUNSHINE MILLS INC)',
    'TIKI CAT (PETROPICS LLC)',
    'TIKI DOG (PETROPICS LLC)',
    'TIKI DOG LOMI LOMI LUAU (PETROPICS LLC)',
    'TOMBOY (HI-TEK RATIONS INC)',
    'TOP RUNNER (INTERNATIONAL PET FOODS COMPANY)',
    'TOPPS (SPECIALTY FEEDS INC)',
    'TOTAL FEEDS (TOTAL FEEDS INC)',
    'TRAIL BLAZER (SUNSHINE MILLS INC)',
    'TRIPETT (PETKIND PET PRODUCTS INC.)',
    'TRISHA YEARWOOD (TRISHA YEARWOOD ENTERPRISES LLC)',
    'TRIUMPH (SUNSHINE MILLS INC)',
    'TRIUMPH (TRIUMPH PET INDUSTRIES INC)',
    'TRUDOG BOOST ME (TRUPET LLC)',
    'TRUDOG FEED ME (TRUPET LLC)',
    'TRUE MEALS (GENERAL MILLS)',
    "TUCKER'S (RAW BASICS LLC)",
    "TUCKER'S WAG-A-ROUNDS (RAW BASICS LLC)",
    "TUFFY'S (TUFFY'S PET FOODS INC)",
    'TURBOPUP (TURBOPUP LLC)',
    'TUSCAN NATURAL (PYRAMID PET PRODUCTS INC)',
    'TWIN PET (SIMMONS ALLIED PET FOOD INC)',
    'ULTIMATES (MIDWESTERN PET FOODS INC)',
    'UNDER THE SUN (CANIDAE CORPORATION)',
    'UNDER THE WEATHER (UNDER THE WEATHER)',
    'UNIDENTIFIED (MULTIPLE BRAND OWNERS)',
    'URBAN WOLF (ESSEX COTTAGE FARMS LTD)',
    'V-DOG (V-DOG INC)',
    'VARIETY PET FOODS (VARIETY PET FOODS LLC)',
    'VERUS (VERUS PET FOODS)',
    'VET BASICS (BREEDERS EDGE INC)',
    'VET LIFE (FARMINA PET FOODS)',
    'VETDIET (VETDIET INTERNATIONAL INC)',
    'VETERINARY SELECT (SUNSHINE MILLS INC)',
    'VIBRA PET (VIBRAPET FOODS)',
    'VICTOR (MID AMERICA PET FOOD, LLC)',
    'VICTOR MULTI PRO (MID AMERICA PET FOOD, LLC)',
    'VISIONARY (VISIONARY PET)',
    'VITAKRAFT (VITAKRAFT SUNSEED INC)',
    'VITAL ESSENTIALS (CARNIVORE MEAT COMPANY LLC)',
    'WAG SOUP (WET NOSES INC)',
    'WAGGERS (WAGGERS PET PRODUCTS INC)',
    'WAGGERS MY LITTLE WOLF (AMERICAN PET)',
    'WALK ABOUT (WALK ABOUT PET PRODUCTS)',
    'WALK ABOUT (WALK ABOUT TREATS)',
    'WARM MEALS (WEXFORD FARMS PET FOOD LLC)',
    'WELCOME HOME (WELCOME HOME PET PRODUCTS)',
    'WELLNESS (WELL PET LLC)',
    'WELLY CHEF (WELLY TAILS INC)',
    "WENDLAND'S (JUPE FEEDS INC)",
    'WERUVA (WERUVA INTERNATIONAL INC.)',
    'WET NOSES (WET NOSES INC)',
    'WHOLE EARTH FARMS (MERRICK PET CARE LTD)',
    'WHOLE LIFE (WHOLE LIFE PET)',
    'WHOLESOME BLEND (TROUW NUTRITION CANADA INC)',
    'WHOLESOMES (MIDWESTERN PET FOODS INC)',
    'WILD CALLING! (WILD CALLING! PET FOODS)',
    'WILD COAST RAW (WILD COAST RAW LLC)',
    'WILD EARTH (WILD EARTH INC)',
    'WILD FRONTIER (THE NUTRO COMPANY)',
    'WILDOLOGY (MIDCO DISTRIBUTING LLC)',
    'WISHBONE (ADDICTION FOODS)',
    'WOLF SPRING (SLURP INC)',
    'WYSONG (WYSONG MEDICAL CORPORATION)',
    'YOGI-DOG (YOG-DOG LLC)',
    'YUM-IT UP! (DOSKOCIL MANUFACTURING COMPANY INC)',
    'YUMMERS (YUMMERS PET SUPPLY CORPORATION)',
    'ZEAL CANADA (CANADIAN JERKY CO. LTD.)',
    'ZIGNATURE (PETS GLOBAL INC)',
    'ZIWI (ZIWIPEAK LTD)',
    'ZOE (ROLF C. HAGEN INC.)',
    'ZOIC (ZOIC PET FOODS)',
    "ZUKE'S (NESTLE PURINA PET CARE COMPANY)",
];

export const MOCK_HANDLERS = [
    rest.post(`${import.meta.env.REACT_APP_BASEURL}/product/characteristics`, (req, res, ctx) => {
        return res(ctx.json(MOCK_CHARACTERISTICS));
    }),
    rest.get(`${import.meta.env.REACT_APP_BASEURL}/my/customChars`, (req, res, ctx) => {
        return res(ctx.json(MOCK_CUSTOM_CHARACTERISTICS));
    }),
    rest.get(`${import.meta.env.REACT_APP_BASEURL}/my/customChars/:id`, (req, res, ctx) => {
        return res(ctx.json(MOCK_CUSTOM_CHARACTERISTICS_VALUE));
    }),
    rest.post(`${import.meta.env.REACT_APP_BASEURL}/product/characteristicValues`, (req, res, ctx) => {
        return res(ctx.json(MOCK_CHARACTERISTICS_VALUE));
    }),
];
