import './ByzzerNotifications.scss';
import React, {useEffect} from 'react';
import {useApp, useEvents} from '@/contexts/UserContext';
import './handlers';
import {handlersByType} from './NotificationHandlers';
// todo: replace this with a proper notification library
import {notification} from 'antd';
import classnames from 'classnames';
import {ByzzerButton} from "@/components/form";
import { useTenantApi } from '@/hooks';


// this is a little to ignore older messages popups
const now = new Date();

// if you need to add a new notification for an event see https://adlm.nielseniq.com/confluence/pages/viewpage.action?pageId=416219356
export default function ByzzerNotifications({className, ...props}) {
    const {events, messages} = useApp();
    const tenantApis = useTenantApi()
    useEffect(() => {
        // ;(async () => { // NOT SURE IF ASYNC IS NEEDED HERE?
            const [event] = events;
            const handlers = handlersByType[event?.type] ?? [];
            const baseClassName = 'byzzer-notification';
            handlers.forEach(async (handler) => {
                const {content, title, type, actions = [], duration} = handler(event, tenantApis);
                const visibleActions = actions.filter(({showIf}) => showIf !== false);
                notification.open({
                    message: title,
                    description: (
                        <div className={classnames(baseClassName, `${baseClassName}--${type}`)}>
                            <main className={`${baseClassName}__content`}>
                                {content}
                            </main>
                            {visibleActions.length > 0 && (
                                <footer className={`${baseClassName}__actions`}>
                                    {visibleActions.map(({label, action}, index) => (
                                        <ByzzerButton key={index} label={label} onClick={action}/>
                                    ))}
                                </footer>
                            )}
                        </div>
                    ),
                    duration: duration ?? 10,
                });
            });
        // })()
    }, [events]);

    useEffect(() => {
        const [message] = messages;

        if (message?.timestamp.toDate() > now) {
            notification.open({
                message: message.title,
                description: (
                    <div className={classnames('byzzer-notification', `byzzer-notification--${message.type}`)}>
                        {message.body}

                    </div>
                ),
                duration: message.duration ?? 10,
            });
        }
    }, [messages])
    return <></>;
}
