import { FocusKPI } from "@/types/ApiTypes";
import { ByzzerSelectOption } from "@byzzer/ui-components";

export const focusKPIOptions: ByzzerSelectOption<{
    suffixDisplay?: string;
}, FocusKPI>[] = [
    { display: 'Dollar Sales', value: 'Dollar Sales', data: {
        suffixDisplay: ''
    } },
    { display: 'Unit Sales', value: 'Unit Sales', data: {
        suffixDisplay: 'units'
    } },
    { display: 'EQ Units', value: 'EQ Units', data: {
        suffixDisplay: 'EQ units'
    } },
];
