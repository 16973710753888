import React from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import './ProductCodingRequest.scss';
import OutletWithProps from "@/components/OutletWithProps";

function ProductCodingRequest() {

    const baseClassName = 'product-coding-request';
    const navigate = useNavigate();

    async function onComplete(step) {
        switch (step) {
            case `info`:
                navigate('success', {replace: true});
                break;
            case `reSubmit`:
                navigate('', {replace: true});
                break;
            case `done`:
                navigate(`/dashboard`);
                break;
            default:
                navigate('', {replace: true});
        }
    }

    return (
        <OutletWithProps className={baseClassName} onComplete={onComplete}/>
    );
}

export default ProductCodingRequest;
