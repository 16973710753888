
const searchData = (dataArray, searchTerm) => {
    const searchKeys: string[] = ['file', 'fileName', 'folderName'];
    return dataArray.flatMap((obj) => {
        const objHasSearchTerm = Object.entries(obj).some(
            ([key, value]) => searchKeys.includes(key)  && String(value).toLowerCase().includes(searchTerm.toLowerCase())
        );
        if (objHasSearchTerm && !obj.files) return [obj];

        const matchedChildren = searchData(obj.files ?? [], searchTerm).map((file) => ({ ...file, expand: true }));
        return objHasSearchTerm || matchedChildren.length > 0
            ? [
                  {
                      ...obj,
                      files: matchedChildren,
                      expand: true
                  },
              ]
            : [];
    });
};

export default searchData