import React, { useState, useEffect } from 'react';
import './ProductCodingRequest.scss';
import upcBarcode from '@images/icons/upc-barcode.svg';
import upcGreenTick from '@images//icons/upc-green-tick.svg';
import upcRedCross from '@images/icons/upc-red-cross.svg';
import InlineSVG from 'react-inlinesvg/esm';
import { ByzzerLink } from "@/components/form";

function ProductCodingMoreInfo(props) {
    const [upcDigit, setUpcDigit] = useState([
        { digit: '01234567890', icon: upcGreenTick },
        { digit: '001234567890', icon: upcGreenTick },
        { digit: '012345678909', icon: upcRedCross },
    ]);
    return (
        <>
            {props.type === 'imageInfo' && (
                <>
                    <div className="product-coding-request__img-title">Submitting products for coding at NIQ</div>
                    <div className="product-coding-request__img-info">
                        By filling out this form, you’ve let us at byzzer know that your product needs to be brought
                        into our reports - thanks for the heads up! With this information we can look up the product,
                        but it won’t contain all the detailed coding that you may have come to love in our reports. If
                        you want additional product details - health & wellness facts like organic or natural, key
                        features like package material or shape, consumer differentiators like flavor or scent - we need
                        to get the product to NIQ to be fully coded. All you need to do is upload the images of
                        the product, and we’ll take it from here!
                    </div>
                    <div className="product-coding-request__img-sub-title">Image requirements:</div>
                    <div className="product-coding-request__img-sub-title-info">
                        <ul className="product-coding-request__img-sub-title-list">
                            <li className="product-coding-request__img-sub-title-point">
                                The images must include copies of all sides of the product (front, back, top, bottom,
                                right, and left sides - including blank sides).
                            </li>
                            <li className="product-coding-request__img-sub-title-point">
                                Do not embed images in Word or Excel; these generally are not usable due to file size
                                compression.
                            </li>
                            <li className="product-coding-request__img-sub-title-point">
                                Images should be at least 1 MB in size in order to read the product information clearly.
                            </li>
                            <li className="product-coding-request__img-sub-title-point">
                                The images must contain the final artwork of the product/packaging. Sales Sheets and
                                Information Sheets will not be accepted.
                            </li>
                        </ul>
                    </div>
                </>
            )}

            {props.type === 'upcCheckingInfo' && (
                <div className="product-coding-request__upc-check">
                    <div className="product-coding-request__upc-check-title">UPC Check-Digits</div>
                    <div className="product-coding-request__upc-check-info1">
                        The check-digit follows the 12-digit UPC on the packaging of a product. Our UPCs at byzzer are
                        coded without check-digits, so if you have included it in your UPC search, that may be why you
                        aren’t finding your item.
                    </div>
                    <div className="product-coding-request__upc-check-info2">
                        For the below UPC, you would search the 11-digit string, with or without a leading zero, and
                        excluding the “9”.
                    </div>
                    <div className="product-coding-request__upc-check-barcode">
                        <img src={upcBarcode} />
                    </div>
                    {upcDigit.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="product-coding-request__upc-check-tick">
                                    <img src={item.icon} />
                                </div>
                                <div className="product-coding-request__upc-check-digit">
                                    <span>
                                        {(index !== upcDigit.length - 1 && item.digit) ||
                                            (index === upcDigit.length - 1 && item.digit.substring(0, 11))}
                                    </span>
                                    <span className="product-coding-request__upc-check-digit-highlight">
                                        {index === upcDigit.length - 1 && item.digit.substring(11)}
                                    </span>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            )}


            {props.type === 'instructions' && (
                <>
                <div className="product-coding-request__instruction">
                    <div className="product-coding-request__instruction-title"></div>
                    <div className="product-coding-request__instruction-info1">
                        New Product Coding requests can be sent to the New Product Coding team, at <span className="product-coding-request__instruction-email">NPCimages@nielseniq.com</span>
                    </div>
                    <div className="product-coding-request__instruction-info2">
                        To submit a request, provide the following information:
                    </div>

                    <div className='product-coding-request__instruction-list'>
                        <li >Is this your own product? Yes/No</li>
                        <li>Coding Option: (1) Code Immediately, (2) Delay until product has sales, (3) Code by a certain date - [insert Saturday date]</li>
                        <li>UPC Number to code</li>
                        <li>Container type (such as Bottle)</li>
                        <li>Container Material Substance (such as Plastic)</li>
                        <li>Additional information (Observable information that cannot be collected from the label: design/color of paper products, color of bottle or liquid for soap, single or multi-pack, etc.)</li>
                        <li>Attach images of each side of the product (front/back/left/right/top/bottom). Images must be clear with text on the label legible and must include the UPC code on the packaging.</li>
                    </div>
                    
                </div>
                <div className='product-coding-request__instruction-info3'>
                    <hr/>
                    <div className='product-coding-request__instruction-info3-subinfo1'>
                        <b>What Next?</b>
                        <p>New products that have sales will be available in Byzzer within 4 weeks. For a more detailed schedule, go to the Chat and type in "New Product Coding timelines".</p>
                        <b>Can I follow-up on a request?</b>
                        <p>For follow-ups, reach out to us through Chat or email: <span 
                        className="product-coding-request__instruction-info3-subinfo1-email">byzzer.support@smb.nielseniq.com</span></p>
                    </div>
                </div>
                </>
            )}
        </>
    );
}

export default ProductCodingMoreInfo;
