import './DraggableProductDimensions.scss';
import React, {ReactNode} from "react";
import classnames from "classnames";
import {RowColConfig} from "@/components/DodConfigEditor/types";
import {DragDropContext, Droppable, DropResult} from "react-beautiful-dnd";
import {DodFilters} from "@/types/DodRun";
import {
    DraggableProductDimension
} from "@/components/DodConfigEditor/builders/DodLayoutBuilder/DodDesignYourLayout/DraggableProductDimension";
import {useEventDataWithUserInfo, useTrackEvent} from "@/analytics/AnalyticsContext";

export interface DraggableProductDimensionsProps {
    className?: string;
    dimensions: RowColConfig[];

    onChange?(dimensions: RowColConfig[]);
}

const baseClassName = 'draggable-product-dimensions';

const productLabelMapping: Record<keyof DodFilters | string, string> = {
    brands: "Brand",
    categories: "Category",
    departments: "Department",
    manufacturers: "Manufacturer",
    parentCompanies: "Parent Company",
    productDescriptions: "Product Description",
    subcategories: "Subcategory",
    superCategories: "Super Category",
    upcs: 'UPC'
}
const dimHiddenTips: Record<keyof DodFilters | string, ReactNode> = {
    categories: <>
        <p>Hide Category from your output to remove Category breaks in your data.</p>
        <p>This will allow you to aggregate data across Categories, so you can aggregate data up to the Super Category
            level or sum Brand level sales across multiple Categories.</p>
    </>
}

export function DraggableProductDimensions({className, dimensions, onChange}: DraggableProductDimensionsProps) {

    const trackEvent = useTrackEvent();
    const getEventData = useEventDataWithUserInfo();

    const stackable = dimensions.length > 1;

    function handleDragEnd(result: DropResult) {

        if (!result.destination) {
            return;
        }

        const from = result.source.index;
        const to = result.destination.index;
        const [moved] = dimensions.splice(from, 1);
        dimensions.splice(to, 0, moved);

        onChange?.([...dimensions]);

        trackEvent({
            type: 'click',
            name: 'dod_layout_value_reorder',
            data: getEventData({ dodWizardStep: 'layout', filter: 'product' }),
        });
    }

    function handleDimensionChange(config: RowColConfig) {
        onChange?.(dimensions.map(d => d.dim === config.dim ? config : d));
    }

    return <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable-products">
            {(provided, snapshot) => (
                <div {...provided.droppableProps}
                     ref={provided.innerRef}
                     className={classnames(baseClassName, className)}>
                    {dimensions.map((config, index) => (
                        <DraggableProductDimension config={config} index={index} key={config.dim}
                                                   label={productLabelMapping[config.dim] ?? config.dim}
                                                   hiddenTip={dimHiddenTips[config.dim]}
                                                   hideStack={!stackable}
                                                   onConfigChange={handleDimensionChange}/>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </DragDropContext>
}

