import './ByzzerMultiSelect.scss';
import React, { useState, useRef, useEffect, createRef } from 'react';
import classNames from 'classnames';
import { useClickOutsideHandler } from '@/utils/utils';
import ByzzerCheckbox from './ByzzerCheckbox';
import { ByzzerErrorModal } from '@/components/form/ByzzerErrorModal';
import classnames from 'classnames';
import ByzzerLink from './ByzzerLink';

const baseClassName = 'byzzer-multiselect';
export const ByzzerMultiSelect = ({
    label,
    name,
    value,
    placeholder,
    onChange,
    onClear,
    direction = 'down',
    options = [],
    subOptions = [],
    className,
    seperator,
    disabled,
    maxSelection,
    selectedOptions,
    multiSelectWithCheckBox,
    inlineFontStyle = 'bold',
    enableToolTip = false,
    allowClear = false,
    enableSearch = true,
    advanceSearch = false,
    advanceSearchLabel,
    advanceSearchOnClick,
    selectAll = false,
    disabledValues = [],
    disabledMsg = 'Not Available',
    optionalLabel = false,
    variant = 'default',
    ...props
}) => {
    /**
     * state management variable declaration
     */
    const compRef = useRef();
    const inputRef = createRef();
    const [filter, setFilter] = useState('');
    const [$value, setValue] = useState(value);
    const [$multiselectValue, setMultiSelectValue] = useState(selectedOptions?.length > 0 ? selectedOptions : []);
    const [expanded, setExpanded] = useState(false);
    const [incNumber, changeIncNumber] = useState(0);
    const [showWarning, setShowWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [contentType, setContentType] = useState('warning');
    const [allFlag, setAllFlag] = useState(false);
    const filterRegex = new RegExp(filter.trim()?.split(/\s+/).join('.*') || '.*', 'i');

    useEffect(() => {
        if (!value || value.length <= 0) {
            setMultiSelectValue([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        setMultiSelectValue(selectedOptions?.length > 0 ? selectedOptions : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptions]);

    /**
     * condition to check dropdown checkbox
     * @param {*} value
     * return boolean
     */
    const DisableCheckBox = (value) => {
        return maxSelection && $multiselectValue.length >= maxSelection && $multiselectValue.indexOf(value) === -1;
    };
    const renderSubOptions = (parentOption) => {
        const subOptionsFromParent = subOptions.find((val) => val.parent === parentOption);
        if (subOptionsFromParent) {
            return (
                <div className={'multi-check-box-sub-options'}>
                    {subOptionsFromParent.child.map((value, indexOfOption) => {
                        return (
                            <ByzzerCheckbox
                                label={value}
                                key={value}
                                checked={$multiselectValue.includes(value)}
                                name={value}
                                disabled={DisableCheckBox(value)}
                                className={'byzzer-select-option multi-check-box'}
                                onChange={(e) => handleOptionClick(value, e, indexOfOption)}
                            />
                        );
                    })}
                </div>
            );
        }
    };

    /**
     *  to render dropdown options
     */
    const filteredOptions = options?.filter(isAMatch)?.map((option, indexOfOption) => {
        if (option) {
            const { value = option, display = option } = option;
            if (multiSelectWithCheckBox) {
                return (
                    <React.Fragment key={value}>
                        <ByzzerCheckbox
                            label={display}
                            key={value}
                            checked={$multiselectValue.includes(value)}
                            name={display}
                            disabled={DisableCheckBox(value)}
                            className={'byzzer-select-option multi-check-box'}
                            onChange={(e) => handleOptionClick(option, e, indexOfOption)}
                        />
                        {subOptions.length > 0 && renderSubOptions(option)}
                    </React.Fragment>
                );
            }
            return (
                <div
                    key={value}
                    className={`byzzer-select-option ${baseClassName}-option`}
                    onClick={() => handleOptionClick(option)}
                >
                    {display}
                </div>
            );
        }
    });

    /**
     * classes for the component
     */
    const classes = classNames(
        'byzzer-select', 
        `${baseClassName}`,
        className, {
            'byzzer-select--expanded': expanded,
            'byzzer-select--disabled': disabled,
        }
    );

    /**
     * To check mouse click outside the component
     */
    useClickOutsideHandler(compRef, () => {
        setExpanded(false);
    });

    /**
     * trigger if the option selected
     * @param {*} value
     * @param {*} e
     */
    function handleOptionClick(value, e, indexOfOption) {
        if (maxSelection && $multiselectValue.length >= maxSelection && e?.target?.checked) {
            setShowWarning(true);
            setContentType('warning');
            setWarningMessage('You can not select more than ' + maxSelection);
            return false;
        } else {
            if (multiSelectWithCheckBox) {
                if (e?.target?.checked) {
                    $multiselectValue.push(value);
                    if ($multiselectValue.length === options.length) {
                        setAllFlag(true);
                    }
                } else {
                    if (allFlag) {
                        setAllFlag(false);
                    }
                    var index = $multiselectValue.indexOf(value);
                    if (index !== -1) {
                        $multiselectValue.splice(index, 1);
                    }
                }
                setMultiSelectValue($multiselectValue);
            }
            setValue(value);
            // setFilter('');
            changeIncNumber(incNumber + 1);
            if (onChange) {
                onChange($multiselectValue, value, indexOfOption);
            }
        }
    }

    function handleAllClick(value, e, indexOfOption) {
        options.map((item) => {
            if (multiSelectWithCheckBox) {
                if (e?.target?.checked) {
                    setAllFlag(true);
                    let findIndex = $multiselectValue.findIndex((val) => val === item);
                    if (findIndex === -1) $multiselectValue.push(item);
                } else {
                    setAllFlag(false);
                    var index = $multiselectValue.indexOf(item);
                    if (index !== -1) {
                        $multiselectValue.splice(index, 1);
                    }
                }
            }
            setValue(item);
            changeIncNumber(incNumber + 1);
            if (onChange) {
                onChange($multiselectValue, value, indexOfOption);
            }
        });
    }

    /**
     * show or hide dropdown options
     */
    function toggle() {
        if (!expanded && enableSearch) {
            inputRef.current.focus();
        }
        setExpanded(!expanded);
    }

    function onFilterChange({ target }) {
        setExpanded(true);
        setFilter(target.value);
    }

    function isAMatch(entry) {
        return !filter || filterRegex.test(entry.display || entry);
    }

    const clearSelection = (e) => {
        if (allowClear && $multiselectValue?.length > 0) {
            e.preventDefault();
            setMultiSelectValue([]);

            if (onChange) {
                onChange([]);
            }
            onClear?.();
            // alert();
        }
    };

    return (
        <div className={classes}>
            {label && (
                <div className={`${baseClassName}__label`}>
                    {label}
                    {optionalLabel && <span>(Optional)</span>}
                </div>
            )}
            <label className={classnames('byzzer-select__control', {
                [`byzzer-select__control--${variant}`]: variant
            })} ref={compRef}>
                <input
                    type={'text'}
                    readOnly
                    name={name}
                    className={ 
                        classnames(
                            `${baseClassName}__input`,
                            {
                                [`${baseClassName}__input--disabled`]: disabled
                            }
                        )
                    }
                    onChange={() => setExpanded(true)}
                    disabled={disabled}
                    onClick={toggle}
                    placeholder={placeholder}
                    value={
                        multiSelectWithCheckBox
                            ? seperator
                                ? $multiselectValue?.join
                                    ? $multiselectValue?.join(' ' + seperator + ' ')
                                    : []
                                : $multiselectValue
                            : $value
                    }
                    autoCorrect={'off'}
                    autoComplete={'off'}
                    spellCheck={'off'}
                />
                {enableToolTip && $multiselectValue.length > 0 && (
                    <span className="tooltiptext">
                        {multiSelectWithCheckBox
                            ? seperator
                                ? $multiselectValue?.join
                                    ? $multiselectValue?.join(' ' + seperator + ' ')
                                    : []
                                : $multiselectValue
                            : $value}
                    </span>
                )}
                <div
                    onClick={(e) => clearSelection(e)}
                    className={
                        allowClear && $multiselectValue.length > 0 ? 'byzzer-select__close' : 'byzzer-select__trigger'
                    }
                />

                <div
                    className={classnames(
                        `${baseClassName}-options`,
                        `${baseClassName}__options--open-${direction}`,
                        'byzzer-select-options',
                        `byzzer-select__options--open-${direction}`
                    )}
                >
                    {enableSearch && (
                        <input
                            autoFocus={false}
                            type={'text'}
                            ref={inputRef}
                            placeholder={'Search here...'}
                            onChange={onFilterChange}
                            value={filter}
                            className={`${baseClassName}__search`}
                        />
                    )}
                    <div
                        className={`byzzer-select-options__scrollarea ${
                            enableSearch ? `${baseClassName}-options__scrollarea` : ''
                        }`}
                    >
                        {selectAll && (
                            <ByzzerCheckbox
                                label={'All'}
                                checked={allFlag}
                                name={'All'}
                                disabled={DisableCheckBox('All')}
                                className={'byzzer-select-option multi-check-box'}
                                onChange={(e) => handleAllClick('All', e)}
                            />
                        )}
                        {filteredOptions}
                    </div>
                    {advanceSearch && (
                        <ByzzerLink
                            label={advanceSearchLabel}
                            onClick={(e) => {
                                setFilter('');
                                advanceSearchOnClick();
                            }}
                        />
                    )}
                </div>
                {showWarning && (
                    <ByzzerErrorModal
                        showWarning={showWarning}
                        setShowWarning={setShowWarning}
                        headerType={'none'}
                        contentType={contentType}
                        warningMessage={warningMessage}
                        size={'small'}
                    />
                )}
            </label>
        </div>
    );
};

export default ByzzerMultiSelect;
