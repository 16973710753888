import { useEffect } from 'react';
import { TimePeriodPicker } from '@/components/TimePeriodPicker';
import { selectorStates } from '@/config/globalVars';
import { useStoriesContext } from '@/views/story/buildStory/StoriesContext';
import { TimePeriod } from '@/types/ReportRun';

export const StoryTimePeriodSelector = () => {
    const {
        setDataSpecificationValues,
        dataSpecificationValues,
        updatePanelStatus,
        getSelectorConfiguration,
    } = useStoriesContext();
    let { selectorConfiguration, selectorIndex } = getSelectorConfiguration('time_period');
    const onTimePeriodChange = (e: ByzzerChangeEvent<TimePeriod>) => {
        setDataSpecificationValues({
            ...dataSpecificationValues,
            runConfig: { ...dataSpecificationValues.runConfig, timePeriod: e.value },
        });
    };
    //TODO : THIS CODE WILL BE MOVED TO storiesContext FILE
    useEffect(() => {
        let timePeriod = dataSpecificationValues.runConfig.timePeriod;

        if (timePeriod.type === 'relative') {
            if (timePeriod.period) {
                updatePanelStatus(selectorIndex, selectorStates.completed);
            } else {
                updatePanelStatus(selectorIndex, selectorStates.pending);
            }
        } else {
            //@ts-ignore
            if (timePeriod.period && timePeriod.period.endDate && timePeriod.period.weeks) {
                updatePanelStatus(selectorIndex, selectorStates.completed);
            } else {
                updatePanelStatus(selectorIndex, selectorStates.pending);
            }
        }
    }, [dataSpecificationValues.runConfig.timePeriod]);
    return (
        <>
            <TimePeriodPicker
                name={'timePeriod'}
                value={dataSpecificationValues.runConfig.timePeriod}
                onChange={onTimePeriodChange}
                datatype={selectorConfiguration?.datatype}
                sku={dataSpecificationValues.sku}              
            />
        </>
    );
};
