import React, { useState, useEffect } from "react";
import classnames from 'classnames';
import './SimulatorConfigEditor.scss';
import DashboardContent from "@/components/dashboard/DashboardContent";
import { SimulatorBuilder } from "@/views/simulators";
import {ByzzerButton} from "@byzzer/ui-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SimulatorConfig, SimulatorRunConfig, ScenarioFilterRunConfigOptions } from "@/types/SimulatorTypes";
import { useUser } from "@/contexts/UserContext";
import {alert as ByzzerAlert} from '@/components/form';
import { PRESET_DEFAULT_FILTER_VALUES } from "@/views/simulators/SimulatorBuilder/simulatorPresetsForTesting";
import { useSimulatorRunService } from "@/services/simulatorRun.service";
import { getProductBySku } from "@/services/product.service";

export type SimulatorConfigEditorProps = {
    data?: any;
}

export type ScenarioSimulatorConfigOptions = {
    sku?: string;
    filterEditor: {
        filterConfigOptions: ScenarioFilterRunConfigOptions;
    };
    simulatorConfig: SimulatorConfig;
}

export const emptyFilterRunConfig: SimulatorRunConfig = {
    categories: [],
    brands: [],
    ppgId: null,
    ppgGroupNames: [],
    timePeriod: { type: 'relative' }
}

export const SimulatorConfigEditor = ({
    ...props
}: SimulatorConfigEditorProps) => {

    const baseClassName = 'byz-simulator-config-editor';
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { allProducts, subscribedSkus, features, defaultRunConfig } = useUser();
    const { getSimulatorDefaultRunConfigBySku } = useSimulatorRunService();
    const [simulatorConfigOptions, setSimulatorConfigOptions] = useState<ScenarioSimulatorConfigOptions>();
    const [defaultFilterRunConfig, setDefaultFilterRunConfig] = useState<SimulatorRunConfig>(emptyFilterRunConfig);
    const [simulator, setSimulator] = useState<ProductWithMeta>();

    const sku = searchParams.get('sku') as string;
    const datasetSku = searchParams.get('datasetSku');

    useEffect(() => {
        ;(async () => {
            if (await validateOrExit()) {
                await loadConfig();
            }
        })();
    }, [sku, allProducts]);

    async function validateOrExit(): Promise<boolean> {
        if (!subscribedSkus.includes(sku) && !features?.enableSimulatorAccess && !simulatorConfigOptions) {
            await ByzzerAlert({
                // @ts-ignore
                title: 'Product Not Available',
                content: 'The product you selected is not available in your current subscription.',
            });
            navigate(-1);
            return false;
        }
        return true;
    }

    const editingSavedScenario = false; // placeholder 

    async function loadConfig(): Promise<void> {
        try {
            const simulator = getProductBySku(sku);

            if (!simulator) {
                await ByzzerAlert({
                    // @ts-ignore
                    title: 'Product Not Found',
                    content: `We were unable to find the product you are trying to configure.`,
                });

                return navigate(-1);
            }

            setSimulator(simulator);

            if (editingSavedScenario) {
                // tbd
            } else {
                const defaultSimulatorRunConfig = getSimulatorDefaultRunConfigBySku(sku!);

                setDefaultFilterRunConfig(PRESET_DEFAULT_FILTER_VALUES ?? defaultSimulatorRunConfig)

                setSimulatorConfigOptions({
                    filterEditor: {
                        filterConfigOptions: simulator?.metadata?.configOptions,
                    },
                    simulatorConfig: {
                        ...simulator?.metadata.simulatorConfig,
                        dataset: simulator?.metadata.datasetConfig
                    }
                });
            }
        } catch (error) {
            // tbd
            console.error(`SimulatorConfigEditor - loadConfig fired - 'error' ===>>`, error);
        }
    }


    return Boolean(simulator && simulatorConfigOptions) && (
        // @ts-ignore
        <DashboardContent
            title={<>Design Your {simulator?.title} Simulator</>}
            className={`${baseClassName}__container`}
            // loading={!runConfigOptions}
            subTitle={
                <span>
                    {/* tbd */}
                </span>
            }
            extras={(
                <div className={`${baseClassName}__header-actions`}>
                    <ByzzerButton type={'negative'} label={'Cancel'} onClick={() => navigate(-1)}/>
                </div>
            )}
        >
            <SimulatorBuilder
                sku={sku!}
                datasetSku={datasetSku!}
                defaultFilterRunConfig={defaultFilterRunConfig}
                title={simulator?.title}
                simulatorConfigOptions={simulatorConfigOptions!}
            />
        </DashboardContent>
    );

};

export default SimulatorConfigEditor;

SimulatorConfigEditor.displayName = 'SimulatorConfigEditor';