import React, { useEffect, useState, useRef } from 'react';
import { ByzzerRadio, ByzzerCheckbox } from '@/components/form';

import {useTenantApi} from '@/hooks/useTenantApi';
import './EmailRecipientSelector.scss';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { useAccountService } from '@/hooks/useAccountService';
import { useUser } from '@/contexts/UserContext';

const EmailRecipientSelector = ({
    title = 'Who do you want to notify?',
    retainVal,
    recipientType,
    recipientList,
    setRecipients,
    changeRecipientType,
}) => {
    const { user } = useUser();
    const { getTeamMembers } = useTenantApi();
    const [selectedMembers, setSelectedMembers] = useState<any>([]);
    const [profileData, setProfileData] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [teamMembers, setTeamMembers] = useState<any>();
    const [requireValueRetention, setRequireValueRetention] = useState(retainVal);
    const profileDetailsRef = useRef<any>();
    const { getUser } = useAccountService();
    profileDetailsRef.current = profileData;

    useEffect(() => {
        if (requireValueRetention && recipientType !== 'just-me') {
            getMembersList(recipientType);
        } else {
            getProfileDetails();
            setRequireValueRetention(false);
        }
    }, []);

    const getProfileDetails = () => {
        setIsLoading(true);
        if (user) {
            const { id, firstName, lastName, email, teamId } = user;
            //#1.35-remove profile data
            const profileDetails = {
                id,
                firstName,
                lastName,
                email,
                teamId,
            }
            setProfileData(profileDetails);
            profileDetailsRef.current = profileDetails;
            setRecipients([email], true);
            setIsLoading(false);
        }
    };

    const getMembersList = async (type) => {
        setIsLoading(true);
        if(Object.keys(profileDetailsRef.current).length === 0){
            getProfileDetails();
        }
        let users;
        if (!(teamMembers?.length > 0)) {
            users = await getTeamMembers();
        } else {
            users = teamMembers;
        }
        let teamEmailList: any = [];
        let teamUsers = users;
        if (type === 'my-team') {
            // filter all members of Logged In User's Team Only
            teamUsers = users.filter((user) => user?.teamId === profileDetailsRef.current?.teamId);
        }
        if (requireValueRetention && type === 'selected-member') {
            teamUsers.forEach((user) => {
                if (recipientList.includes(user.email)) {
                    user.selected = true;
                    teamEmailList.push(user?.email);
                } else {
                    user.selected = false;
                }
            });
            if(teamEmailList.length === 0){
                teamEmailList.push(profileDetailsRef.current?.email)
            }
            setSelectedMembers(teamEmailList);
        } else {
            teamUsers.forEach((e) => (e.selected = false));
            teamUsers.forEach((user) => teamEmailList.push(user?.email));
        }
        let list = !requireValueRetention && type === 'selected-member' ? [profileDetailsRef.current] : teamEmailList;
        setRecipients(list, false); //arr of email Ids
        setTeamMembers(users);
        if(requireValueRetention){
            setRequireValueRetention(false);
        }
        setIsLoading(false);
    };

    const OnRecipientTypeChange = async (e) => {
        let currentRecipientType = e.target.value;
        changeRecipientType(currentRecipientType);
        setSelectedMembers([]);
        setRecipients([]);
        if (currentRecipientType === 'just-me') {
            setRecipients([profileData?.email], true);
        } else {
            getMembersList(currentRecipientType);
        }
    };

    const memberSelected = (checkedStatus, memberInfo, index) => {
        let teamMembersTemp = teamMembers;
        teamMembersTemp[index].selected = checkedStatus;

        setTeamMembers([...teamMembersTemp]);
        let memberEmailsList = selectedMembers;
        if (checkedStatus && !memberEmailsList.includes(memberInfo?.email)) {
            memberEmailsList.push(memberInfo?.email);
            setSelectedMembers(memberEmailsList);
            setRecipients(memberEmailsList, false);
        } else if (!checkedStatus) {
            let temp = memberEmailsList.filter((e) => e !== memberInfo?.email);
            setSelectedMembers(temp);
            setRecipients(temp, false);
        }
    };

    const renderTeamMembers = () => {
        let teamList = teamMembers;

        return (
            <>
                {teamList.map((member, index) => (
                    <tr>
                        <td>
                            {/* @ts-ignore */}
                            <ByzzerCheckbox
                                onChange={(e) => {
                                    memberSelected(e.target.checked, member, index);
                                }}
                                disabled={profileData?.id === member.id}
                                checked={member.selected || profileData?.id === member.id}
                                key={`member-${index}`}
                            />
                        </td>
                        <td>{member?.teamName}</td>
                        <td>{member?.firstName + ' ' + member?.lastName}</td>
                        <td>{member.email}</td>
                    </tr>
                ))}
            </>
        );
    };

    return (
        <div className="email-recipient-selector">
            <ByzzerMask loading={isLoading} />
            <div className="email-recipient-selector-header-container">
                <header className="email-recipient-selector-header-container-text">
                    <span>{title}</span>
                </header>
            </div>
            <div>
                <div>
                    {/* @ts-ignore */}
                    <ByzzerRadio
                        label={`Just Me`}
                        value={'just-me'}
                        checked={recipientType === 'just-me'}
                        onChange={OnRecipientTypeChange}
                        name="email_sending"
                    />
                </div>

                <div>
                    {/* @ts-ignore */}
                    <ByzzerRadio
                        label={`My Team`}
                        value={'my-team'}
                        checked={recipientType === 'my-team'}
                        onChange={OnRecipientTypeChange}
                        name="email_sending"
                    />
                </div>

                <div>
                    {/* @ts-ignore */}
                    <ByzzerRadio
                        label={`Everyone on my subscription`}
                        value={'everyone'}
                        checked={recipientType === 'everyone'}
                        onChange={OnRecipientTypeChange}
                        name="email_sending"
                    />
                </div>

                <div>
                    {/* @ts-ignore */}
                    <ByzzerRadio
                        label={`Pick by member`}
                        value={'selected-member'}
                        checked={recipientType === 'selected-member'}
                        onChange={OnRecipientTypeChange}
                        name="email_sending"
                    />
                </div>
                {recipientType === 'selected-member' && (
                    <div className="member-table-wrapper">
                        <table className={'email-recipient-selector-member-table'}>
                            {teamMembers?.length > 0 && (
                                <tbody>
                                    <tr>
                                        <th>Receive Alert?</th>
                                        <th>Team</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                    </tr>
                                    {renderTeamMembers()}
                                </tbody>
                            )}
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmailRecipientSelector;
