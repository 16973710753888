import { setStorySelectorAttributes } from '@/utils/storySelectorUtil';
import { selectorLabels, defaultConfigs } from '@/config/globalVars';

export const selectorAttributes = (attributeOptions = []) => {
    // Possible option lists:
    // dimension:default
    // product:option1
    // product:option2
    // product:option3
    // product:option4
    // product:option5
    // market:option1
    // market:option2
    // market:option3
    // market:option4
    // market:option5
    // timePeriod:default
    // More options will be added on the go
    const attrReqOptions = attributeOptions || defaultConfigs.report;
    let totalAttrObj = [];
    attrReqOptions.forEach((opt) => {
        totalAttrObj.push(setSelectorAttributes(opt));
    });

    return totalAttrObj;
};

export const setSelectorAttributes = (optionSet) => {
    let selectorAttrObj = [];
    switch (optionSet) {
        case 'product:option1':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Focus Brand Report : Option 1
                    subSelectorLabelId: 'Focus Brand Report : Option 1', // Just to distinguish, not used in code
                    subSelectorType: 'focusBrandReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: true,
                    competitiveProductSet: true,
                    characteristicFilters: {
                        multi: true,
                        maxLimit: 5,
                    },
                },
            };
            break;
        case 'product:option1A':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Focus Brand Only Report : Option 1A
                    subSelectorLabelId: 'Focus Brand Only Report : Option 1A', // Just to distinguish, not used in code
                    subSelectorType: 'focusBrandOnlyReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: true,
                    competitiveProductSet: true,
                    characteristicFilters: {
                        multi: true,
                        maxLimit: 5,
                    },
                },
            };
            break;
        case 'product:option1B':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Focus Brand Optional Report : Option 1B
                    subSelectorLabelId: 'Focus Brand Optional Report : Option 1B', // Just to distinguish, not used in code
                    subSelectorType: 'focusBrandOptionalReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: true,
                    competitiveProductSet: true,
                    characteristicFilters: {
                        multi: true,
                        maxLimit: 5,
                    },
                },
            };
            break;
        case 'product:option2':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // No Focus Brand report : Option 2
                    subSelectorLabelId: 'No Focus Brand report : Option 2', // Just to distinguish, not used in code
                    subSelectorType: 'noFocusBrandReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: false,
                    //adhocFocusBrand:true,
                    competitiveProductSet: true,
                    characteristicFilters: {
                        multi: true,
                        maxLimit: 5,
                    },
                },
            };
            break;
        case 'product:option3':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Select 1 characteristic dimension report : Option 3
                    subSelectorLabelId: 'Select 1 characteristic dimension report : Option 3', // Just to distinguish, not used in code
                    subSelectorType: 'singleCharacteristicDimensionReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: false,
                    //adhocFocusBrand:true,
                    competitiveProductSet: true,
                    characteristicDimension: {
                        multi: false,
                        maxLimit: 1,
                    },
                },
            };
            break;
        case 'product:option4':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Select multiple characteristic dimension report : Option 4
                    subSelectorLabelId: 'Select multiple characteristic dimension report : Option 4', // Just to distinguish, not used in code
                    subSelectorType: 'multiCharacteristicDimentionReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: false,
                    competitiveProductSet: true,
                    characteristicDimension: {
                        multi: true,
                        maxLimit: 5,
                    },
                },
            };
            break;
        case 'product:option4a':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Select 7 characteristic dimensions report : Option 4a
                    subSelectorLabelId: 'Select 7 characteristic dimensions report : Option 4a', // Just to distinguish, not used in code
                    subSelectorType: 'brandLevelMultiCharacteristicDimensionReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: true,
                    competitiveProductSet: true,
                    characteristicFilters: {
                        multi: true,
                        maxLimit: 5,
                    },
                    characteristicDimension: {
                        multi: true,
                        allRequired: true,
                        maxLimit: 7,
                    },
                },
            };
            break;
        case 'product:option5':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Select up to 10 Brands Report : Option 5
                    subSelectorLabelId: 'Select up to 10 Brands : Option 5', // Just to distinguish, not used in code
                    subSelectorType: 'multiBrandsReport', // This property is validated in code to identify which type of selector is to be rendered
                    competitiveProductSet: true,
                    brands: {
                        multi: true,
                        maxLimit: 10,
                    },
                },
            };
            break;
        case 'product:option6':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Focus Brand Only Report : Option 1A
                    subSelectorLabelId: 'Focus Brand Only Report : Option 6', // Just to distinguish, not used in code
                    subSelectorType: 'focusBrandOnlyReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: true,
                    competitiveProductSet: true,
                    characteristicFilters: {
                        required: true,
                        alwaysPositiveLookup: true,
                        multi: true,
                        maxLimit: 5,
                    },
                },
            };
            break;
        case 'product:option7':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // H&W Report : Option 7
                    subSelectorLabelId: 'H&W Report : Option 1', // Just to distinguish, not used in code
                    subSelectorType: 'hwReport', // This property is validated in code to identify which type of selector is to be rendered
                    characteristicThemes: {
                        multi: false,
                    },
                    competitiveProductSet: true,
                    characteristicsForTheme: {
                        multi: true,
                    },
                },
            };
            break;
        case 'product:option-fs':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Financial Services report : Option-fs
                    subSelectorLabelId: 'Financial Services report : Option-fs', // Just to distinguish, not used in code
                    subSelectorType: 'fsReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: false,
                    salesThreshold: {
                        multi: true,
                        required: false,
                    },
                    growthThreshold: {
                        multi: false,
                        required: false,
                    },
                    competitiveProductSet: true,
                    characteristicFilters: {
                        multi: true,
                        maxLimit: 5,
                    },
                },
            };
            break;
        case 'market:option1':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.focusMarket,
                selectorType: 'market',
                subSelectors: {
                    // Select Focus Market: Option 1
                    subSelectorLabelId: 'Focus Market report: Option 1', // Just to distinguish, not used in code
                    subSelectorType: 'focusMarketReport', // This property is validated in code to identify which type of selector is to be rendered
                    markets: {
                        marketGroup: 'rms',
                        multi: false,
                        maxLimit: 1,
                        // just passthrough parameters for the backend
                        marketSections: {
                            embodyTotalMarkets: true,
                            embodyFmcgRetailers: true,
                            embodySpecialityRetailers: true,
                            embodyGeographyMarkets: true,
                        },
                    },
                },
            };
            break;
        case 'market:option2':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.focusMarkets,
                selectorType: 'market',
                subSelectors: {
                    // Select multiple markets report: Option 2
                    subSelectorLabelId: 'Select multiple markets report: Option 2', // Just to distinguish, not used in code
                    subSelectorType: 'multiMarketsReport', // This property is validated in code to identify which type of selector is to be rendered
                    markets: {
                        marketGroup: 'rms',
                        multi: true,
                        maxLimit: 20, //changed to allow choosing up to 20 rather than 10
                        marketSections: {
                            embodyTotalMarkets: true,
                            embodyFmcgRetailers: true,
                            embodySpecialityRetailers: true,
                            embodyGeographyMarkets: true,
                        },
                    },
                },
            };
            break;
        case 'market:option2a':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.focusMarkets,
                selectorType: 'market',
                subSelectors: {
                    // Select multiple markets report: Option 2
                    subSelectorLabelId: 'Select multiple markets report: Option 2a', // Just to distinguish, not used in code
                    subSelectorType: 'smartReportMarkets', // This property is validated in code to identify which type of selector is to be rendered
                    markets: {
                        marketGroup: 'rms',
                        multi: true,
                        maxLimit: 5, //changed to allow choosing up to 5
                        marketSections: {
                            embodyTotalMarkets: true,
                            embodyFmcgRetailers: true,
                            embodySpecialityRetailers: true,
                            embodyGeographyMarkets: true,
                        },
                    },
                },
            };
            break;
        case 'market:option2b':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.focusMarkets,
                selectorType: 'market',
                subSelectors: {
                    // Select multiple markets report: Option 2
                    subSelectorLabelId: 'Select multiple markets report: Option 2b', // Just to distinguish, not used in code
                    subSelectorType: 'smartReportMarkets', // This property is validated in code to identify which type of selector is to be rendered
                    markets: {
                        marketGroup: 'rms',
                        multi: true,
                        maxLimit: 10, //changed to allow choosing up to 5 and each remaining markets must
                        requiredRemMarketForEachRetailer: true,
                        requiredRemMarketForTotalMarkets: true,
                        requiredRemMarketForGeography: true,
                        smartAccount: true,
                        marketSections: {
                            embodyTotalMarkets: true,
                            embodyFmcgRetailers: true,
                            embodySpecialityRetailers: true,
                            embodyGeographyMarkets: true,
                        },
                    },
                },
            };
            break;
        /*******
         Market Selector: Option 2 - but should be 5 markets instead of 20 and should only show options where Market Type=Account.
         The user also must choose a Remaining market for each market they select, so will actually make 10 selections,
         but only 5 different Accounts. This will be the below Option market:option2c
         ********/
        case 'market:option2c':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.focusMarkets,
                selectorType: 'market',
                subSelectors: {
                    // Select multiple markets report: Option 2
                    subSelectorLabelId: 'Select multiple markets report: Option 2c', // Just to distinguish, not used in code
                    subSelectorType: 'smartReportMarkets', // This property is validated in code to identify which type of selector is to be rendered
                    markets: {
                        marketGroup: 'rms',
                        multi: true,
                        requiredRemMarketForEachRetailer: true,
                        marketoptions: true,
                        marketSections: {
                            embodyTotalMarkets: true,
                            embodyFmcgRetailers: true,
                            embodySpecialityRetailers: true,
                            embodyGeographyMarkets: true,
                        },
                    },
                },
            };
            break;
        case 'market:option3':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.channelOutlets,
                selectorType: 'market',
                subSelectors: {
                    // CPS Markets report: Option 3
                    subSelectorLabelId: 'CPS Markets report: Option 3', // Just to distinguish, not used in code
                    subSelectorType: 'cpsMarketsReport', // This property is validated in code to identify which type of selector is to be rendered
                    markets: {
                        marketGroup: 'cps',
                        multi: true,
                        maxLimit: 20,
                        marketSections: {
                            embodyPanelTotal: true,
                            embodyPanelByChannel: true,
                        },
                    },
                },
            };
            break;
        case 'market:option4':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.outlets,
                selectorType: 'market',
                subSelectors: {
                    // CPS Markets report: Option 4
                    subSelectorLabelId: 'CPS Markets report: Option 4', // Just to distinguish, not used in code
                    subSelectorType: 'cpsAccountsMarketsReport', // This property is validated in code to identify which type of selector is to be rendered
                    markets: {
                        marketGroup: 'cps',
                        multi: true,
                        maxLimit: 10,
                        marketSections: {
                            embodyPanelTotal: false,
                            embodyPanelByChannel: true,
                        },
                    },
                },
            };
            break;
        case 'market:option5':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.focusMarkets,
                selectorType: 'market',
                subSelectors: {
                    // Market Type report: Option 5
                    subSelectorLabelId: 'Market Type report: Option 5', // Just to distinguish, not used in code
                    subSelectorType: 'marketTypeReport', // This property is validated in code to identify which type of selector is to be rendered
                },
            };
            break;
        case 'timePeriod:default':
            selectorAttrObj = {
                selectorLabelName: selectorLabels.timePeriod,
                selectorType: 'timePeriod',
                subSelectors: {}, // TBD
            };
            break;
        case 'dimension:default':
            selectorAttrObj = {
                selectorLabelName: 'Select Comparison Type',
                selectorType: 'dimension',
                selectedItem: '',
                subSelectors: {}, // TBD
            };
            break;
        default:
            break;
    }
    return selectorAttrObj;
};

export const characteristicFormValidation = (currentCharConditionValuesData) => {
    let status = true;
    for (let index = 0; index < currentCharConditionValuesData.length; index++) {
        if (currentCharConditionValuesData[index].valueItemValue) {
            const findData = currentCharConditionValuesData.filter((val) => {
                return (
                    val.characteristicValue === currentCharConditionValuesData[index].characteristicValue &&
                    (val.valueItemValue.join() === currentCharConditionValuesData[index].valueItemValue.join() ||
                        val.valueItemValue.filter((value) => {
                            return currentCharConditionValuesData[index].valueItemValue.includes(value);
                        }).length > 0)
                );
            });
            if (findData.length > 1) {
                status = false;
                break;
            }
        }
    }
    return status;
};

export const scoreCardSelectorAttributes = (attributeOptions = []) => {
    const scAttrReqOptions = attributeOptions || defaultConfigs.report;
    let scTotalAttrObj = {};
    scAttrReqOptions.forEach((opt) => {
        scTotalAttrObj = { ...scTotalAttrObj, ...setScoreCardSelectorAttributes(opt) };
    });

    return scTotalAttrObj;
};

export const setScoreCardSelectorAttributes = (optionSet) => {
    let scSelectorAttrObj = [];
    switch (optionSet) {
        case 'product:option1':
            scSelectorAttrObj = {
                category: {
                    multi: true,
                    required: true,
                },
                brand: {
                    multi: false,
                    required: true,
                },
                characteristicFilters: {
                    limit: 5,
                    required: false,
                },
            };
            break;
        case 'product:option2':
            scSelectorAttrObj = {
                category: {
                    multi: true,
                    required: true,
                },
                brand: {
                    multi: false,
                    required: true, // upc is required if this is not filled
                },
                upc: {
                    required: true, // brand is required if this is not filled
                },
                characteristicFilters: {
                    required: false,
                },
            };
            break;
        case 'market:option1':
            scSelectorAttrObj = {
                markets: {
                    required: true,
                    multi: false,
                    remaining: false,
                },
            };
            break;
        case 'market:option2':
            scSelectorAttrObj = {
                markets: {
                    required: true,
                    multi: true,
                    remaining: false,
                },
            };
            break;
        case 'market:option3':
            scSelectorAttrObj = {
                markets: {
                    required: true,
                    multi: false,
                    remaining: true,
                },
            };
            break;
        case 'market:option4':
            scSelectorAttrObj = {
                markets: {
                    required: true,
                    multi: true,
                    remaining: true,
                },
            };
            break;
        case 'timePeriod:default':
            scSelectorAttrObj = {
                timePeriod: true,
            };
            break;
        default:
            break;
    }
    return scSelectorAttrObj;
};

export function switchMarketSelector(marketOption, props, clearData = true){
    let selectorsObj = [...props?.reportSelectors];

    let marketIndex;
    let additionalSubSelectors;
        if (props?.productType === 'story') {
            let currentSelectorState  = { ...props?.selectorState };
            currentSelectorState.marketOption = marketOption;
            if (marketOption === 'market:option2' && clearData) {
                currentSelectorState.marketPerformanceSelections = {};
                currentSelectorState.focusMarketsPerformanceSelectedData = [];
                props?.setFromMarketTypeSelector(true);
                marketIndex = selectorsObj?.findIndex((element) => element.selectorType === 'market' && element.subSelectors?.selectorFunction === 'marketPerformance');
            } else if (marketOption === 'market:option2' && !clearData) {
                props?.setFromMarketTypeSelector(true);
                marketIndex = selectorsObj?.findIndex((element) => element.selectorType === 'market' && element.subSelectors?.selectorFunction === 'marketPerformance');
            } else if (marketOption === 'market:option5') {
                currentSelectorState.marketsToWatchSelection = {};
                marketIndex = selectorsObj?.findIndex((element) => element.selectorType === 'market' && element.subSelectors?.selectorFunction === 'multiMarketsStory');
            }
            props?.setSelectorsData(currentSelectorState);
            additionalSubSelectors = setStorySelectorAttributes(marketOption).subSelectors;
        } else {
            if (marketOption === 'market:option2') {
                props?.setFromMarketTypeSelector(true);
            }
            marketIndex = selectorsObj.findIndex((element) => element.selectorType === 'market');
            additionalSubSelectors = setSelectorAttributes(marketOption).subSelectors;
        }
        selectorsObj[marketIndex] = {
            ...selectorsObj[marketIndex],
            subSelectors: additionalSubSelectors
        };
    selectorsObj[marketIndex] = {
        ...selectorsObj[marketIndex],
        subSelectors: additionalSubSelectors,
    };
    props?.setReportSelectors(selectorsObj);
}
