import '@/components/AppInitializing.scss';
import React from 'react';
import {buildInfo} from '@/config';
import { memoize } from 'lodash';

const baseClassName = 'app-initializing';

export const AppInitializing = memoize(({type = 'logo'}) => {

    const isProd = import.meta.env.REACT_APP_BYZZER_ENV.toLowerCase() === 'production';

    function renderAnimation() {
        switch (type) {
            case 'spinner':
                return <svg className={`${baseClassName}__spinner`} viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg">
                    <circle pathLength={1} cx="50" cy="50" r="45"/>
                </svg>
            case 'logo':
            default:
                return <div className={`${baseClassName}__logos`}>
                    <div className={`${baseClassName}__logo-a`}/>
                    <div className={`${baseClassName}__logo-b`}/>
                </div>
        }
    }

    return (
        <div className={baseClassName}>
            {renderAnimation()}
            <div className={`${baseClassName}__version-info`}>
                <p>Byzzer v{buildInfo.version}</p>
                {!isProd && (<>
                    <p><b>Branch:</b> {buildInfo.git.branch}</p>
                    <p><b>SHA:</b> {buildInfo.git.sha}{buildInfo.git.dirty ? '-dirty' : ''}</p>
                </>)}
            </div>
        </div>
    )
})
