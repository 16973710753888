import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './OnboardingPage.scss';
import { DashboardContent, DashboardNav } from "@/components/dashboard";
import { ByzzerMask } from "@/components/ByzzerMask/ByzzerMask";
import DashboardHeader from "@/components/dashboard/DashboardHeader";
import OnboardingEditor from "./OnboardingEditor";

export type OnboardingPageProps = {
    data?: any;
}

const baseClassName = 'byz-onboarding-page';

export const OnboardingPage = ({
    ...props
}: OnboardingPageProps) => {


    return (
        <div className={"dashboard"}>
            <DashboardNav />
            <DashboardContent 
                title={'Welcome to Byzzer!'}
                subTitle={"Let's launch your onboarding process and set you up for success!"}
                className={`${baseClassName}__container`}
            >
                <OnboardingEditor />
            </DashboardContent>
            <DashboardHeader />
        </div>
    );

};

export default OnboardingPage;

OnboardingPage.displayName = 'OnboardingPage';