import React, { useState, useEffect } from 'react';
import { useTenantApi } from '@/hooks/useTenantApi';
import ReportInformationSidePanel from './ReportInformationSidePanel';
import { ByzzerButton } from '@byzzer/ui-components';
import { getProductBySku } from '@/services/product.service';
import { alertsDescriptionUrl } from '@/config/globalVars';
import MarkdownEditor from '@uiw/react-markdown-editor';
import './ReportInformation.scss';

// Define the types for the component props
type ReportInformationProps = {
    sku: string;
    url?: string;
    runReport?: () => void;
    readonly?: boolean;
    disabled?: boolean;
    reportWrapper?: boolean;
};

type ProductInfo = {
    title?: string;
    overview?: string;
};

const ReportInformation = ({
    sku,
    url = `${alertsDescriptionUrl}/${sku}.html`,
    runReport,
    readonly = false,
    disabled,
    reportWrapper = false,
}: ReportInformationProps) => {
    const { getProductInformation } = useTenantApi();
    const [skuInformation, setSkuInformation] = useState<any>(null);
    const [productInfo, setProductInfo] = useState<ProductInfo | null>(null);
    const imageBucketURL = import.meta.env.REACT_APP_IMAGES_BASE_URL;
    const reportGuideDocumentPath = `${imageBucketURL}/report-guides/${sku}-guide.pdf`;

    useEffect(() => {
        getSkuInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sku]);

    useEffect(() => {
        if (sku) {
            const product = getProductBySku(sku);
            setProductInfo(product);
        }
    }, [sku]);

    const getSkuInfo = () => {
        const skuInfo = getProductInformation(sku)?.data;
        setSkuInformation(skuInfo);
    };

    const onReportRun = () => {
        runReport?.();
    };

    // temporary until markdowns are created, falls back on iframe if it's not filled in
    const ProductOverviewContent: React.FC<{ productInfo: ProductInfo | null }> = ({ productInfo }) => {
        if (
            Boolean(productInfo) &&
            typeof productInfo?.overview === 'string' &&
            Boolean(productInfo?.overview?.length)
        ) {
            console.log('ReportInformation - md');
            return <MarkdownEditor.Markdown source={productInfo?.overview} />;
        } else {
            console.log('ReportInformation - iFrame');
            return (
                <iframe
                    title="Byzzer"
                    src={url}
                    height={'fit-content'}
                    style={{ minHeight: `${window.innerHeight - 150}px`, paddingBottom: '5px' }}
                    width={'100%'}
                    frameBorder="0"
                />
            );
        }
    };

    const onDownloadGuideClick = () => {
        if (reportGuideDocumentPath) {
            window.open(reportGuideDocumentPath, '_blank'); // Open in a new tab
        }
    };

    return (
        <>
            <div className={'report-information'} key={sku}>
                <div className={'report-information__side'}>
                    <ReportInformationSidePanel key="report-side-panel" skuInformation={skuInformation} />
                </div>
                <div className={'report-information__main'}>
                    {Boolean(productInfo) && Boolean(Object.keys(productInfo ?? {}).length) && reportWrapper && (
                        <div className={'report-information__main-header'}>
                            {productInfo?.title ? productInfo.title + ' Report Information' : ''}
                        </div>
                    )}
                    <ProductOverviewContent productInfo={productInfo} />
                    {runReport && !readonly && (
                        <div className="report-information__main__runreport">
                            <ByzzerButton type={'negative'} label={'Download Guide'} onClick={onDownloadGuideClick} />
                            <ByzzerButton label={'Run Report'} onClick={onReportRun} disabled={disabled} />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
export default ReportInformation;
