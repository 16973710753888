import React, { useEffect, ReactNode, forwardRef } from "react";
import useState from 'react-usestateref';
import classnames from 'classnames';
import './ByzzerPPGGroupPicker.scss';
import { ByzzerChangeEventHandler, ByzzerSelectOption, ByzzerWindowedSelect, WithValue } from "@byzzer/ui-components";
import { useTenantApi } from "@/hooks/useTenantApi";
import { SingleValue } from "react-select";
import { RunConfigMarket } from "@/types/ReportRun";
import { createPpgGroupNameOption } from "@/views/simulators/utils";
import { PpgGroupNames } from "@/types/ApiTypes";


export type ByzzerPPGGroupPickerProps = {
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<string[]>;
    value?: string[];
    disabledOptions?: string[];
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    allowClear?: boolean;
    showDescription?: boolean;
    onlyRenderIf?: boolean;

    ppgId: number;

    // filters: DefaultPPGInfoFilters,

    categories: string[]; // needs conversion to keys
    brands?: string[];
    markets?: RunConfigMarket[]; // todo: see if we need to support array
    timePeriod?: any; // todo: add time period type and figure out how to integrate it downstream

    maxSelections?: number;
    busy?: boolean;
    setBusy?: (isBusy: boolean) => void;
}

export type PPGGroupInternalValue = Record<string, string>;

export type PPGGroupOptions = Record<string, string[]>;

export type SelectorType = {
    key: string;
    options: string[];
    ppgType: 'upload' | 'characteristic';
}

export type PpgGroupValidationStatus = 'neutral' | 'valid' | 'invalid';

export type DefaultPPGValueType = {brand: string[], size: string, pack: string};

export const ByzzerPPGGroupPicker = forwardRef<WithValue<boolean | undefined>, ByzzerPPGGroupPickerProps>(({
    onChange,
    name,
    label,
    className,
    disabledOptions = [],
    placeholder = 'Select from the list',
    value,
    disabled,
    onlyRenderIf,
    allowClear = true,
    showDescription = true,

    ppgId,

    categories,
    brands,
    markets,
    timePeriod,

    maxSelections = 1,
    busy,
    setBusy,
    ...props
}, ref ) => {

    const baseClassName = 'byz-ppg-group-picker';

    const [internalValue, setInternalValue, internalValueRef] = useState<string>();
    const [allPpgGroups, setAllPpgGroups, allPpgGroupsRef] = useState<ByzzerSelectOption[]>([]);

    const marketNamesMap = markets?.map((runConfigMarket) => runConfigMarket?.name);

    const { getPpgGroupNames } = useTenantApi();

    useEffect(() => {
        if (value) {
            const newValue = value?.[0];
            setInternalValue(newValue);
        }
    }, [value]);


    function sortByLabel(a, b) {
        return a?.display?.localeCompare(b?.display, 'en', {sensitivity: 'base'})
    }
    useEffect(() => {

        if (ppgId && categories?.length) { // TODO: Known bug when it first loads clears previously selected value

            (async (ppgId, categories, brands) => {

                setBusy?.(true);

                try {

                    if (!ppgId) {
                        setAllPpgGroups([])
                        return;
                    }

                    let ppgInfoResponse: PpgGroupNames | undefined;

                    ppgInfoResponse = await getPpgGroupNames({
                        ppgId,
                        categoryNames: categories,
                        ...(brands?.length ? { brandNames: brands } : {}), // {brandNames: ["FOLGERS (THE FOLGER COFFEE COMPANY)"]}
                        // ...(marketNames?.length ? { marketNames: marketNamesMap } : {}),  // todo: see if we need to support array
                        // ...(timePeriod ? {timePeriod} : {}),  // todo: add time period type and figure out how to integrate it downstream
                    })
                    const allPpgGroupNames = ppgInfoResponse?.groups?.map((group) => {
                        return group.groupName
                    });
                    setAllPpgGroups([...new Set(allPpgGroupNames ?? [])].map((name) => createPpgGroupNameOption(name, ppgId)).sort(sortByLabel))

                    if (!ppgInfoResponse) {
                        setBusy?.(false)
                        return;
                    }
                    setBusy?.(false)

                } catch (err) {
                    // tbd
                    setBusy?.(false);
                    return;
                }

            })(ppgId, categories, brands)
            

        }
        // else if (ppgId === null) {
        //     reset();
        // }
    }, [ppgId, categories, brands]);


    const handleValueSelect = (newValue: any) => {
        const value: string | undefined = (newValue as SingleValue<ByzzerSelectOption>)?.value;
        if (onChange) {
            onChange({
                value: value ? [value] : [],
                name
            })
        }
        else {
            setInternalValue(value);
        }
    }

    function reset() {
        setAllPpgGroups([]);
        onChange?.({
            name,
            value: []
        })
    }

    return (
        <div className={classnames(`${baseClassName}`)}>
            <div className={`${baseClassName}__selectors`}>
                <ByzzerWindowedSelect
                    options={allPpgGroups}
                    value={internalValue}
                    onChange={handleValueSelect}
                    allowClear={true}
                    disabled={disabled || !allPpgGroups?.length}
                    name={name}
                    label={label}
                    placeholder={placeholder ?? "Select from the list"}
                />
            </div>
        </div>
    );

});

export default ByzzerPPGGroupPicker;

ByzzerPPGGroupPicker.displayName = 'ByzzerPPGGroupPicker';
