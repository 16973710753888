import { useState, useEffect, useMemo, ReactNode } from "react";
import classnames from 'classnames';
import './ByzzerCard.scss';
import { ByzzerMask } from "@/components/ByzzerMask/ByzzerMask";
import { BadgeType, ReportCardBadge } from "@/views/MyReports/ReportCardBadge";
import { renderMarkdownOrRawContent } from "@/utils";
import { OptionalTrackClickHOC } from "@/components/OptionalTrackClickHOC";

export type ByzzerCardProps = {
    id?: string;
    name?: ReactNode;
    className?: string;
    thumbnailUrl: string;
    title?: ReactNode;
    subTitle?: ReactNode;
    actions?: ReactNode; // Perhaps this can be an array instead of a single node. Might be more intuitive for the consuming component and DevEx.
    description?: ReactNode;
    // badge?: ReactNode;
    // headingSize?: 'small' | 'medium' | 'large';
    // subHeadingSize?: 'small' | 'medium' | 'large';
    // descriptionStyle?: 'normal' | 'italic';
    badgeLocation?: 'top-left' | 'top-center' |'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';
    isSelected?: boolean;
    isSelectable?: boolean;
    isLocked?: boolean;
    shouldTrackClick?: boolean;
    trackClickData?: any;
    trackClickName?: string;
    badges?: BadgeType[];
    /**
     * Defaults to medium (md)
     */
    thumbnailSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    centeredContent?: boolean;
    // isHidden?: boolean;
    // onThumbnailClick?: (data?: any) => void;
    // onHeadingClick?: (data?: any) => void;
    // onSubHeadingClick?: (data?: any) => void;
    onClick?: (id?: ByzzerCardProps['id']) => void;
    // onHover?: (data?: any) => void;
    // onMouseLeave?: (data?: any) => void;
    onLoad?: (data?: any) => Promise<void>;
    // cardProps?: Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;
}

const baseClassName = 'byz-card';

export const ByzzerCard = ({
    id,
    name,
    className,
    thumbnailUrl,
    title,
    subTitle,
    actions,
    description,
    // badge,
    badgeLocation = 'bottom-right',
    isSelected,
    isSelectable,
    isLocked,
    shouldTrackClick,
    trackClickName,
    trackClickData,
    thumbnailSize = 'md',
    centeredContent = false,
    badges,
    // isHidden,
    // onThumbnailClick,
    // onHeadingClick,
    // onSubHeadingClick,
    onClick,
    onLoad,
    ...props
}: ByzzerCardProps) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [internalIsSelected, setInternalIsSelected] = useState<boolean>((isSelectable && isSelected) ?? false);

    useEffect(() => {
        if (isSelectable) {
            setInternalIsSelected(isSelected ?? false);
        }
    }, [isSelected]);

    async function handleLoad() {
        if (!onLoad || isLoading) return;

        setIsLoading(true);

        try {
            await onLoad();
        } catch (error) {
            // tbd
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (onLoad && !isLoading) {
            handleLoad();
        }
    }, []);

    function handleClick() {
        if (!isLocked && !isLoading) {
            onClick?.();
            if (isSelectable) {
                setInternalIsSelected(currSelectedState => !currSelectedState);
            }
        }
    }
    const showActions = Boolean(actions);

    const showBadges = Boolean(badges?.length);

    const showSubTitle = Boolean(subTitle);
    const showDescription = Boolean(description);

    const titleContent = useMemo(() => renderMarkdownOrRawContent(title), [title]);
    const descriptionContent = useMemo(() => renderMarkdownOrRawContent(description), [description]);

    return (
        <OptionalTrackClickHOC shouldTrackClick={shouldTrackClick} trackClickName={trackClickName} trackClickData={trackClickData}>
            <div 
                className={classnames(`${baseClassName}`, className, {
                    [`${baseClassName}--locked`]: isLocked,
                    [`${baseClassName}--selected`]: isSelectable && internalIsSelected,
                    [`${baseClassName}--can-click`]: isSelectable && !isLocked,
                })}
            >
                <ByzzerMask loading={isLoading} show={isLoading}/>
                {/* <header> */}
                    {/* tbd */}
                {/* </header> */}
                <div 
                    className={classnames(
                        `${baseClassName}__thumbnail-wrapper`, 
                        `${baseClassName}__thumbnail-wrapper-${thumbnailSize}`, 
                        {
                            [`${baseClassName}__thumbnail-wrapper--locked`]: isLocked,
                        }
                    )}
                    onClick={handleClick}
                >
                    <img className={classnames(`${baseClassName}__thumbnail`)} src={thumbnailUrl}/> {/* switch to pure css */}
                    {showBadges && (
                        <div className={classnames(`${baseClassName}__badge-container`)}>
                            <div className={classnames(`${baseClassName}__badge-wrapper`)}>
                                {badges?.map((badgeText, index) => {
                                    return (
                                        <ReportCardBadge
                                            key={index}
                                            badgeText={badgeText!}
                                            className={`${baseClassName}__badge`}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    )}
                </div>
                
                <div className={classnames(`${baseClassName}__title-wrapper`, {
                    [`${baseClassName}__title-wrapper-with-actions`]: showActions,
                    [`${baseClassName}__title-wrapper-no-actions`]: !showActions,
                    [`${baseClassName}__title-wrapper-centered`]: centeredContent
                })}>
                    <span className={classnames(`${baseClassName}__title`)} onClick={handleClick}>
                        {titleContent}
                    </span>
                    {showActions && (
                        <div className={classnames(`${baseClassName}__actions`)}>
                            {actions}
                        </div>
                    )}
                </div>
                {showSubTitle && (
                    <span 
                        className={classnames(`${baseClassName}__subTitle`, {
                            [`${baseClassName}__subTitle-with-actions`]: showActions,
                            [`${baseClassName}__subTitle-no-actions`]: !showActions,
                            [`${baseClassName}__subTitle-centered`]: centeredContent
                        })}
                        onClick={handleClick}
                    >
                        {subTitle}
                    </span>
                )}
                {showDescription && (
                    <span className={classnames(`${baseClassName}__description`, {
                        [`${baseClassName}__description-centered`]: centeredContent
                    })}>
                        {descriptionContent}
                    </span>
                )}
                {/* <footer> */}
                    {/* tbd */}
                {/* </footer> */}
            </div>
        </OptionalTrackClickHOC>
    );

};

export default ByzzerCard;

ByzzerCard.displayName = 'ByzzerCard';