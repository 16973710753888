import React from 'react';
import { NavLink } from 'react-router-dom';
import DeleteIcon from '@/images/icons/DeleteIcon.svg';
import EditIcon from '@/images/icons/new-edit-icon.svg';
import './CustomCharacteristicTableRow.scss';
import format from 'date-fns/format';
import classnames from 'classnames';

export const CustomCharacteristicsTableRow = ({
    ccListItem,
    onEdit,
    onDelete,
    baseClass
}) => {
    const baseClassName = baseClass;

    return (
        <tr className={classnames(`${baseClassName}`)}>
            <td className={classnames(`${baseClassName}__data`)}>{ccListItem?.label}</td>
            <td className={classnames(`${baseClassName}__data`)}>{ccListItem?.createdBy?.firstName} {ccListItem?.createdBy?.lastName}</td>
            <td className={classnames(`${baseClassName}__data`)}>{format(new Date(ccListItem?.createdDtm), 'MM/dd/yyyy, hh:mma')}</td>
            <td className={classnames(`${baseClassName}__data`)}>{ccListItem?.categories.join(', ')}</td>
            <td className={classnames(`${baseClassName}__data`)}>{ccListItem?.type === 'UPC' ? 'Upload' : 'Characteristic Groups'}</td>
            <td className={classnames(`${baseClassName}__data-action`)}>
                <div>
                {
                    ccListItem?.isEditable && <>
                    <img className={classnames(`${baseClassName}__del-btn`)} src={EditIcon} alt={'edit'} onClick={() => onEdit(ccListItem?.id)} />
                    <img className={classnames(`${baseClassName}__del-btn`)} src={DeleteIcon} alt={'delete'} onClick={() => onDelete(ccListItem?.id)} />
                    </>
                }
                {<NavLink to={`/dashboard/custom_characteristics_report/${ccListItem?.id}`}>
                    <div className={classnames(`${baseClassName}__view`)}></div>
                </NavLink>}
                </div>
            </td>
        </tr>
    );
};