import './AggregationLevelSelect.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {ByzzerChangeEventHandler, ByzzerSelect, ByzzerSelectProps} from "@byzzer/ui-components";
import {ReportAggregationLevel} from "@/types/ReportRun";

export type AggregationLevelSelectProps = Omit<ByzzerSelectProps, 'options' | 'onChange'> & {
    onChange: ByzzerChangeEventHandler<ReportAggregationLevel>
} & OnlyRenderableIf;

const baseClassName = 'aggregation-level-select';

const AGGREGATION_LEVEL_OPTIONS = [
    {display: 'Total Store', value: 'total_store'},
    {display: 'Department', value: 'department'},
    {display: 'Super Category', value: 'super_category'},
    {display: 'Category', value: 'category'},
]

export function AggregationLevelSelect({className, onlyRenderIf, ...props}: AggregationLevelSelectProps) {

    if(onlyRenderIf === false) return <></>

    return <ByzzerSelect {...props}
                         className={classnames(baseClassName, className)}
                         options={AGGREGATION_LEVEL_OPTIONS}/>
}

export default AggregationLevelSelect;
