import './DraggableDimensionValues.scss';
import classnames from "classnames";
import cloneDeep from 'lodash/cloneDeep';
import {ByzzerChangeEventHandler} from "@byzzer/ui-components";
import {DragDropContext, Droppable, DropResult} from "react-beautiful-dnd";
import {DraggableDimensionValue} from "./DraggableDimensionValue";
import {useEventDataWithUserInfo, useTrackEvent} from "@/analytics/AnalyticsContext";

export interface DraggableDimensionValuesProps {
    name: string;
    className?: string;
    values: string[];
    onChange: ByzzerChangeEventHandler<string[]>
}

const baseClassName = 'draggable-dimension-values';

export function DraggableDimensionValues({className, values, name, onChange}: DraggableDimensionValuesProps) {
    const trackEvent = useTrackEvent();
    const getEventData = useEventDataWithUserInfo();

    function handleDragEnd(result: DropResult): void {
        const clonedValues = cloneDeep(values)

        if (!result.destination) return;

        const from = result.source.index;
        const to = result.destination.index
        const [moved] = clonedValues.splice(from, 1);
        clonedValues.splice(to, 0, moved);

        onChange?.({
            name,
            value: [...clonedValues]
        });

        trackEvent({
            type: 'click',
            name: 'dod_layout_value_reorder',
            data: getEventData({ dodWizardStep: 'layout', filter: name }),
        });
    }

    return <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable-values">
            {(provided, snapshot) => (
                <div {...provided.droppableProps}
                     ref={provided.innerRef}
                     className={classnames(baseClassName, className)}>
                    {values.map((value, index) => (
                        <DraggableDimensionValue label={value} index={index} key={value}/>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </DragDropContext>
}


