import useIsMobile from "@/hooks/useIsMobile";
import { printScreen } from "@/utils";
import { ByzzerButton } from "@byzzer/ui-components";

type AlertViewActions = {
    goBack: () => void;
    showPrintButton?: boolean;
}

export const AlertViewActions = ({goBack}) => {
    const isMobile = useIsMobile();
    const showPrintButton = !isMobile;
    return (
        <div className="report-run-selector__container actions_my_detailed_alert">
            <div className="report-run-selector__actions">
                {showPrintButton && <ByzzerButton onClick={printScreen} type={'negative'}>Print</ByzzerButton>}
                <ByzzerButton onClick={goBack} type={'negative'}>Back</ByzzerButton>
            </div>
        </div>
    )
};