export const firebaseConfig = {
    apiKey: import.meta.env.REACT_APP_API_KEY,
    authDomain: import.meta.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: import.meta.env.REACT_APP_DATABASE_URL,
    projectId: import.meta.env.REACT_APP_PROJECT_ID,
    storageBucket: import.meta.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: import.meta.env.REACT_APP_APP_ID,
    measurementId: import.meta.env.REACT_APP_MEASUREMENT_ID,
};
export const buildInfo = {
    git: {
        sha: import.meta.env.REACT_APP_GIT_SHA,
        tag: import.meta.env.REACT_APP_GIT_TAG,
        dirty: import.meta.env.REACT_APP_GIT_DIRTY === 'true',
        branch: import.meta.env.REACT_APP_GIT_BRANCH
    },
    dtm: new Date(import.meta.env.REACT_APP_BUILD_DTM),
    version: import.meta.env.REACT_APP_VERSION,
    env: import.meta.env.REACT_APP_BYZZER_ENV,
};

export const versionInfo = {
    showFullVersionInfo: import.meta.env.REACT_APP_SHOW_FULL_VERSION === 'true',
}

if (import.meta.env.REACT_APP_HIDE_BUILD_INFO !== 'true') {
    // console.groupCollapsed("%cbyzzer.config", "color: white;font-weight: bold;background: #4465b4;font-size: 9px;padding: 2px 3px;border-radius: 3px;")
    for (const [key, value] of Object.entries(buildInfo)) {
        if(value instanceof Object) {
            for (const [childKey, childValue] of Object.entries(value)) {
                // console.log(`%c${key}.${childKey}`, 'font-weight:bold;', `${childValue}`);
            }
        } else {
            // console.log(`%c${key}`, 'font-weight:bold;', `${value}`);
        }
    }
    console.groupEnd();
}
export const emailConfig = {
    supportEmail: import.meta.env.REACT_APP_SUPPORT_EMAIL || 'byzzer.support@smb.nielseniq.com',
    verifyMeEmail: import.meta.env.REACT_APP_VERIFYME_EMAIL || 'verifyme@byzzer.com',
    salesEmail: import.meta.env.REACT_APP_SALES_EMAIL || 'sales@byzzer.com',
};
export const testConfig = {
    testMode: import.meta.env.REACT_APP_TEST_MODE || ''
}

export const gtmConfig = { // Google Tag Manager
    gtmId: import.meta.env.REACT_APP_GTM_ID,
};

export const intercomConfig = {
    appId: import.meta.env.REACT_APP_INTERCOM_APPID,
    key: import.meta.env.REACT_APP_INTERCOM_KEY,
    shouldInitialize: import.meta.env.REACT_APP_ENABLE_INTERCOM_BOT === 'true',
}
export const logRocketConfig = {
    appId: import.meta.env.REACT_APP_LOG_ROCKET_APP_ID || 'ifeoib/byzzer'
}

export const googleAnalyticsConfig = {
    appId: import.meta.env.REACT_APP_GA_APP_ID,
    name: 'byzzer'
}

export const zingChartConfig = {
    appLicense: import.meta.env.REACT_APP_ZINGCHART_LICENSE
}

export const disableApiAuth = import.meta.env.BYZ_DISABLE_API_AUTH === true;

export const disableProfileSave = import.meta.env.REACT_APP_DISABLE_PROFILE_SAVE === "true";

export const baseApiUrl = import.meta.env.REACT_APP_BASEURL;
export const baseAppUrl = import.meta.env.REACT_APP_CLIENTURL;

export * from './mixpanel';
