import './ExpandablePanelContainer.scss';
import React, {createRef, useEffect, useRef, useState} from "react";
import classnames from "classnames";
import {useExpandablePanels} from "@/components/ExpandablePanel/ExpandablePanelContext";

export type ExpandablePanelContainerProps = {
    className?: string;
    name?: string;
};

const baseClassName = 'expandable-panel-container';

export function ExpandablePanelContainer({className, name}: ExpandablePanelContainerProps) {

    const {registerContainer} = useExpandablePanels();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        registerContainer(name, ref);
    }, [])

    return <div className={classnames(baseClassName, className)} ref={ref}/>
}

export default ExpandablePanelContainer;
