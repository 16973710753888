import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { clone } from 'lodash';
import { ByzzerCheckbox, ByzzerRadio, ByzzerRadioGroup, ByzzerCheckableChangeEvent } from '@byzzer/ui-components';
import { ByzzerInput, ByzzerLink } from '@/components/form';
import ByzzerPhoneInput from '@/components/form/ByzzerPhoneInput';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { useUser } from '@/contexts/UserContext';
import { confirm, openErrorModal } from '@/components/form/ByzzerModal';
import { openTermsAndConditions } from '@/components/modals/TermsAndConditions';
import { QuoteFooter } from '../QuoteFooter';
import { paymentMethod } from '@/config/quote.config';
import {useTenantApi} from '@/hooks/useTenantApi';
import { QuoteDetail } from '@/types/QuoteTypes';
import './QuoteAccept.scss';

const initialQuoteAcceptInputData = {
    firstName: '',
    lastName: '',
    phone: '',
    paymentOption: '',
    acceptTerms: false,
};
const baseClassName = 'byz-quote-accept';

const QuoteAccept = () => {
    const navigate = useNavigate();
    const [quoteAcceptInputData, setQuoteAcceptInputData] = useState(clone(initialQuoteAcceptInputData));
    const [quote, setQuote] = useState<QuoteDetail>();
    const [loadingMessage, setLoadingMessage] = useState<string>('');
    const { userQuote, refreshUserQuote } = useUser();
    const { acceptQuote } = useTenantApi();
    useEffect(() => {
        if (userQuote && userQuote?.quoteData) {
            const { quoteData } = userQuote;
            setQuote(quoteData)
            setLoadingMessage('');

        }
    }, [userQuote]);

    const totalCommitment = (quote?.quoteDetails?.items ?? []).reduce((acc, item) => {
        return item.rate + acc;
    }, 0);

    const term = quote?.quoteDetails?.items?.length ? quote?.quoteDetails?.items[0]?.term_in_months : 12;


    function handleChangePaymentOption(e: ByzzerChangeEvent<string>) {
        setQuoteAcceptInputData((state) => ({
            ...state,
            paymentOption: e.value,
        }));
    }

    function onInputChange({ target }) {
        setQuoteAcceptInputData((state) => ({
            ...state,
            [target.name]: target.value?.trim() ?? '',
        }));
    }

    const handlePlaceOrder = async () => {
        const result = await confirm({
            title: 'Accept Quote',
            content: (
                <div className="accept-order-msg">
                    <p>Quote: {quote?.id || ''} </p>
                    <p>Are you sure you want to accept your Quote?</p>
                </div>
            ),
            yesLabel: 'Accept',
            noLabel: 'Cancel',
            className: 'accept-quote-warning',
        });

        // Note: Might required to store the decision somewhere in table, so that we can prompt everytime when user log-in
        if (result) {
            setLoadingMessage('Accepting Quote...');
            try {

                const acceptRequest = {
                    quoteId: quote?.id || 0,
                    employee: { ...quoteAcceptInputData },
                    billingFrequency: quoteAcceptInputData.paymentOption,
                };
                const postQuoteResponse = await acceptQuote(acceptRequest);
                if (postQuoteResponse) {
                    refreshUserQuote(undefined) // user no longer has unaccepted quote
                    navigate('/');
                } else {
                    // NOTE: Add a notification bar to show accept quote has been failed
                    setLoadingMessage('');
                }
            } catch (err: any) {
                // NOTE: Add a notification bar to show accept quote has been failed
                // To Do: Handle quote post error response
                return openErrorModal({
                    title: `Error while processing your request.`,
                    content: <p>Fear not our engineering team is on the job.</p>,
                    errorId: err.id
                });
            } finally {
                setLoadingMessage('');

            }
        }
    };


    const handleTerms = ({ checked }: ByzzerCheckableChangeEvent<string>) => {
        setQuoteAcceptInputData((state) => ({
            ...state,
            acceptTerms: checked,
        }));
    };

    const openTermsAndConditionsModal = () => {
        openTermsAndConditions().then((response) => handleTerms(response))
    }

    const enablePlaceOrder = () => {
        return (
            quoteAcceptInputData.paymentOption &&
            quoteAcceptInputData.firstName &&
            quoteAcceptInputData.lastName &&
            quoteAcceptInputData.phone &&
            quoteAcceptInputData.acceptTerms &&
            (quote?.quoteDetails?.billingAddress?.addr1 || quote?.quoteDetails?.billingAddress?.city || quote?.quoteDetails?.billingAddress?.zip)
        );
    };

    const termsAndConditions = (
        <div className={`${baseClassName}__legal-agreement`}>
            <ByzzerCheckbox 
                label={
                    <p>
                        I agree to the {' '}
                        <ByzzerLink
                            label={'Terms & Conditions'}
                            onClick={openTermsAndConditions}
                        />
                    </p>
                }
                onChange={handleTerms} 
                checked={quoteAcceptInputData.acceptTerms} 
            />
        </div>
    );

    const roundedToTwoDecimalAmount = (amount: number) => {
        return Math.round((amount + Number.EPSILON) * 100) / 100;
    };

    return (
        <>
            <div className={classnames(`${baseClassName}`)}>
                <ByzzerMask loading={Boolean(loadingMessage)} show={Boolean(loadingMessage)}>
                    <span>{loadingMessage}</span>
                </ByzzerMask>
                <div className={`${baseClassName}__billing`}>
                    <h2
                        className={`${baseClassName}__billing-title`}
                    >{`Review Your Payment (Quote # - ${quote?.id})`}</h2>
                    <div className={`${baseClassName}__billing-options`}>
                        <div className={`${baseClassName}__billing-options-label`}>Payment Options</div>
                        <div className={`${baseClassName}__billing-options-list`}>
                            <ByzzerRadioGroup
                                value={quoteAcceptInputData.paymentOption}
                                onChange={handleChangePaymentOption}
                            >
                                <div className={`${baseClassName}__billing-options-list-item`}>
                                    <ByzzerRadio value={'monthly'} label={'Pay Monthly'} />
                                </div>
                                <div className={`${baseClassName}__billing-options-list-item`}>
                                    <ByzzerRadio value={'upfront'} label={'Pay Upfront'} />
                                </div>
                            </ByzzerRadioGroup>
                        </div>
                    </div>
                    <div className={`${baseClassName}__billing-method`}>
                        <div className={`${baseClassName}__billing-method-label`}>Payment Method : {paymentMethod}</div>
                    </div>
                    <div className={`${baseClassName}__billing-address`}>
                        <div className={`${baseClassName}__billing-address-label`}>Enter Your Billing Address</div>
                        <div className={`${baseClassName}__billing-address-details`}>
                            <div className={`${baseClassName}__billing-address-details-title`}>Your details</div>
                            <div className={`${baseClassName}__billing-address-details-name`}>
                                <ByzzerInput
                                    name="firstName"
                                    label="First Name"
                                    className=""
                                    placeholder="First Name"
                                    onChange={onInputChange}
                                    value={quoteAcceptInputData.firstName} sublabel={undefined} button={undefined} disabled={undefined} locked={undefined} onIconClick={undefined} onButtonClick={undefined} onBlur={undefined}                                ></ByzzerInput>
                                <ByzzerInput
                                    name="lastName"
                                    label="Last Name"
                                    className=""
                                    placeholder="Last Name"
                                    onChange={onInputChange}
                                    value={quoteAcceptInputData.lastName} sublabel={undefined} button={undefined} disabled={undefined} locked={undefined} onIconClick={undefined} onButtonClick={undefined} onBlur={undefined}                                ></ByzzerInput>
                            </div>
                            <div className={`${baseClassName}__billing-address-details-phone`}>
                                <ByzzerPhoneInput
                                    label="Phone Number"
                                    className={`${baseClassName}__input ${baseClassName}__phone`}
                                    name={'phone'}
                                    value={quoteAcceptInputData.phone}
                                    validate={false}
                                    onChange={onInputChange}
                                    placeholder={'404-475-8166'}
                                    required phone={undefined} disabled={undefined} />
                            </div>
                        </div>
                        <div className={`${baseClassName}__billing-address-details`}>
                            <div className={`${baseClassName}__billing-address-details-title`}>Billing details</div>
                            <div className={`${baseClassName}__billing-address-details-name`}>
                                {quote?.quoteDetails?.billingAddress?.billToAddressee}
                            </div>
                            <div className={`${baseClassName}__billing-address-details-name`}>
                                {[quote?.quoteDetails?.billingAddress?.addr1, quote?.quoteDetails?.billingAddress?.addr2, quote?.quoteDetails?.billingAddress?.addr2]
                                    .filter(Boolean)
                                    .join(', ')}
                            </div>
                            <div className={`${baseClassName}__billing-address-details-place`}>
                                {[quote?.quoteDetails?.billingAddress?.city, quote?.quoteDetails?.billingAddress?.state, quote?.quoteDetails?.billingAddress?.zip]
                                    .filter(Boolean)
                                    .join(', ')}
                            </div>
                            <div className={`${baseClassName}__billing-address-details-place`}>
                                {quote?.quoteDetails?.billingAddress?.country}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${baseClassName}__summary`}>
                    <div className={`${baseClassName}__summary-label`}>Summary</div>
                    <table className={`${baseClassName}__summary-table`}>
                        <tbody>
                            {quoteAcceptInputData.paymentOption === 'monthly' && (
                                <tr className={`${baseClassName}__summary-table-row`}>
                                    <td>Monthly Commitment</td>
                                    <td>${roundedToTwoDecimalAmount(totalCommitment / term)}</td>
                                </tr>
                            )}
                            {quoteAcceptInputData.paymentOption === 'monthly' && (
                                <tr className={`${baseClassName}__summary-table-row`}>
                                    <td>Number of Recurrences</td>
                                    <td>{term}</td>
                                </tr>
                            )}
                            <tr className={`${baseClassName}__summary-table-row`}>
                                <td>Total Commitment</td>
                                <td>${roundedToTwoDecimalAmount(totalCommitment)}</td>
                            </tr>
                            <tr className={`${baseClassName}__summary-table-row`}>
                                <td>Tax</td>
                                <td>${roundedToTwoDecimalAmount(quote?.quoteDetails?.taxAmount || 0)}</td>
                            </tr>
                            <tr className={`${baseClassName}__summary-table-row`}>
                                <td>Total Payment (Including Adjustments, One-time Purchases, Promotions and Taxes)</td>
                                <td>${roundedToTwoDecimalAmount(totalCommitment + (quote?.quoteDetails?.taxAmount || 0))}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <QuoteFooter
                    acceptOrder={handlePlaceOrder}
                    additionalContent={termsAndConditions}
                    disableAcceptBtn={!enablePlaceOrder()}
                    orderLaterData={{}}
                    quote={quote}
                    setLoadingMessage={setLoadingMessage}
                />
            </div>
        </>
    );
};

export default QuoteAccept;
