import './DodSummedSelectionEditor.scss';
import React, { ReactNode, useState, useMemo } from 'react';
import classnames from 'classnames';
import { ByzzerButton, ByzzerChangeEventHandler, ByzzerTextInput } from '@byzzer/ui-components';
import { confirm } from '@/components/form';
import { useDodWizard } from '@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext';
import { SummedSelection } from '@/types/DodRun';
import { DodPresetType } from '@/types/ApiTypes';

const baseClassName = 'dod-summed-selection-editor';

export type ChildrenParams<ValueType> = {
    onChange: ByzzerChangeEventHandler<ValueType>;
    values?: ValueType;
    className: string;
};

export type DodSummedSelectionEditorProps<ValueType> = {
    name?: string;
    children(params: ChildrenParams<ValueType>): ReactNode;
    value?: SummedSelection<ValueType>;
    onChange?: ByzzerChangeEventHandler<SummedSelection<ValueType>>;
    onApply?: ByzzerChangeEventHandler<SummedSelection<ValueType>>;
    onCancel?(): void;
    includeActions?: boolean;
    filterType?: string;
    dodWizardStep?: string;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange' | 'children'>>;

export function DodSummedSelectionEditor<ValueType = string[]>({
    children,
    className,
    name,
    value,
    onChange,
    onApply,
    onCancel,
    includeActions,
    filterType,
    dodWizardStep,
    ...props
}: DodSummedSelectionEditorProps<ValueType>) {

    const {presets, deletePreset} = useDodWizard();
    const [summedSelectionValues, setSummedSelectionValues] = useState<ValueType>(value?.values ?? [] as any);
    const [summedSelectionName, setSummedSelectionName] = useState<string>(value?.name || '');
    const hasValidName = Boolean(summedSelectionName?.trim());
    const isValueAnArray = Array.isArray(summedSelectionValues);
    const hasValidValue = (!isValueAnArray && summedSelectionValues) || (isValueAnArray && summedSelectionValues?.length);
    const isInvalid = !hasValidValue || !hasValidName || ['latestDates', 'previousPeriods'].includes(filterType || '');

    function handleValueChange({ value }) {
        setSummedSelectionValues(value);
    }

    function handleAliasChange({ value }) {
        setSummedSelectionName(value);
    }

    function onCancelClick() {
        onCancel?.();
    }
    function tipForApply() {
        if(['latestDates', 'previousPeriods'].includes(filterType || '')) {
            return <>Summing these periods can lead to duplication. Please sum from a different time periods folder instead.</>;
        }
        if (!hasValidValue) {
            return <>Select at least one item to create summed selection</>;
        }
        if (!hasValidName) {
            return <>Summed selection name is required</>;
        }
        return null;
    }
    async function onApplyClick() {
      if(filterType === "customCharacteristics" && (summedSelectionValues as string[])?.length) {

        if(await confirm({
            title: 'FYI',
            content: <>
                <p>Including Custom Characteristics will slow down the Data Point counter.</p>
                <p>If it's calculating for longer than usual, that's why.</p>
            </>,
            yesLabel: 'Ok',
            noLabel: `Don't Include It`
        })) {
            // remove selected preset values
            if (['product', 'market', 'time_period'].includes(dodWizardStep!) && presets?.[dodWizardStep!]) {
                deletePreset(dodWizardStep! as DodPresetType);
            }

            onApply?.({
                name,
                value: {
                    name: summedSelectionName,
                    values: summedSelectionValues!,
                },
            });
        }
      } else {
        // remove selected preset values
        if (['product', 'market', 'time_period'].includes(dodWizardStep!) && presets?.[dodWizardStep!]) {
            deletePreset(dodWizardStep! as DodPresetType);
        }

        onApply?.({
            name,
            value: {
                name: summedSelectionName,
                values: summedSelectionValues!,
            },
        });
      }
    }

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <ByzzerTextInput
                className={`${baseClassName}__name`}
                label={'Name Your Summed Selections'}
                placeholder="Summed Selection Name"
                value={summedSelectionName}
                onChange={handleAliasChange}
            />
            {children({
                onChange: handleValueChange,
                values: summedSelectionValues,
                className: `${baseClassName}__value-picker`,
            })}
            {includeActions && (
                <div className={`${baseClassName}__actions`}>
                    <ByzzerButton type={'negative'} onClick={onCancelClick}>
                        Cancel
                    </ByzzerButton>
                    <ByzzerButton onClick={onApplyClick} disabled={isInvalid} disabledTip={tipForApply()}>
                        Apply
                    </ByzzerButton>
                </div>
            )}
        </div>
    );
}

export default DodSummedSelectionEditor;
