import './DraggableDimensionValue.scss';
import {RowColConfig} from "@/components/DodConfigEditor/types";
import {Draggable} from "react-beautiful-dnd";
import classnames from "classnames";
import React from "react";

interface DraggableDimensionValueProps {
    className?: string;
    label: string;
    index: number;

    onConfigChange?(config: RowColConfig): void;
}

const baseClassName = 'draggable-dimension-value';

export function DraggableDimensionValue({
                                            className,
                                            label,
                                            index,
                                            onConfigChange,
                                            ...props
                                        }: DraggableDimensionValueProps) {

    return (
        <Draggable
            isDragDisabled={false}
            draggableId={label}
            index={index}
        >
            {(provided, snapshot) => (
                <div
                    className={classnames(baseClassName, className)} {...props}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <i className={`${baseClassName}__dnd-handle`}/>
                    <h1 className={`${baseClassName}__label`}>{label}</h1>
                </div>
            )}
        </Draggable>
    );
}
