import { ppgTemplateUrl, ppgPreUploadHeading } from '@/config/globalVars';
import {ByzzerButton} from '@byzzer/ui-components';
import React, { useState } from 'react';
import { TrackClick } from '@/analytics';

export function PreUploadStep({ onComplete, busy, enableUploadStep, uploadHeading }) {
    const [heading, setHeading] = useState(uploadHeading);

    function onDownloadTemplateClick() {
        window.open(ppgTemplateUrl);
    }

    async function onUploadClick() {
        enableUploadStep?.(true);
        setHeading(ppgPreUploadHeading);
    }

    return (
        <>
            {heading !== 'Upload PPG List' && (
                <div className="ppg-editor__info">
                    <>
                        <div className="ppg-editor__info-section">
                            Creating a definition with a UPC upload will lock your definition to that set of UPCs. So,
                            if new items come into the category, they will not get picked up. You should use this option
                            if you are focused on a specific set of UPCs that you do not expect to change over time.
                        </div>
                        <div className="ppg-editor__info-section">
                        If you want a dynamic PPG definition, consider using the “Characteristics” option.
                        </div>
                    </>
                    <div className={'ppg-editor__actions'}>
                        {/* @ts-ignore */}
                        <ByzzerButton
                            className={'ppg-editor__button'}
                            label={'Yes, I want to use a UPC upload'}
                            onClick={onUploadClick}
                        />
                    </div>
                </div>
            )}
            {heading == 'Upload PPG List' && (
                <div className="ppg-editor__info">
                    <div className="ppg-editor__info-section">
                        You can upload a PPG definition for the entire category, or for only your UPCs.
                    </div>
                    <div className="ppg-editor__info-section">
                        <h2>Your file format must be:</h2>
                        <ul>
                            <li>
                                UPCs in Column A. Confirm that they are either 12-digits or in integer format with no
                                check-digit at the end.
                            </li>
                            <li>PPG Names in Column B.</li>
                        </ul>
                    </div>
                    <div className="ppg-editor__info-section">
                        <h2>Some tips/requirements for your mapping file:</h2>
                        <ul>
                            <li>Do not include headers</li>
                            <li>Upload in a .csv or .xlsx file type</li>
                            <li>
                                PPGs must be mutually exclusive, so the same UPC can not be present in multiple PPGs.
                            </li>
                        </ul>
                    </div>
                    <div className="ppg-editor__actions">
                        <TrackClick name={`PPG Upload Form Upload clicked`}>
                            <ByzzerButton label={'Upload'} onClick={onComplete} busy={busy} />
                        </TrackClick>
                        <TrackClick name={`PPG Upload Form Download clicked`}>
                            <ByzzerButton onClick={onDownloadTemplateClick} type={'negative'}>
                                Download Template
                            </ByzzerButton>
                        </TrackClick>
                    </div>
                </div>
            )}
        </>
    );
}
