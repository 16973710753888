import './ByzzerToggle.scss';
import React, {ReactNode} from 'react';
import classnames from 'classnames';
import {ByzzerSwitch} from "@/components/form/index";
import {ByzzerSwitchProps} from "@/components/form/ByzzerSwitch";

export type ByzzerToggleOption<ValueType = any> = {
    display: ReactNode;
    value: ValueType;
}
export type ByzzerToggleProps<ValueType = any> = {
    label?: ReactNode;
    leftOption: ByzzerToggleOption<ValueType>;
    rightOption: ByzzerToggleOption<ValueType>;
    value?: ValueType
    onChange: (value: ValueType) => void;
    className?: string;
}
const baseClassName = 'byzzer-toggle';
export default function ByzzerToggle<ValueType = any>(
    {
        label,
        leftOption,
        rightOption,
        value,
        onChange,
        className,
        ...props
    }: ByzzerToggleProps<any>) {

    function onToggle({target}) {
        onChange?.(target.checked ? rightOption.value : leftOption.value);
    }

    function onOptionClick(option) {
        onChange?.(option?.value);
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        {label && <div className={`${baseClassName}__label`}>{label}</div>}
        <div className={`${baseClassName}__control`}>
            <div className={classnames(`${baseClassName}__option`, {
                [`${baseClassName}__option--selected`]: value === leftOption?.value
            })} onClick={() => onOptionClick(leftOption)}>
                {leftOption.display}
            </div>
            <ByzzerSwitch
                onChange={onToggle}
                value={rightOption?.value === value}
            />
            <div className={classnames(`${baseClassName}__option`, {
                [`${baseClassName}__option--selected`]: value === rightOption?.value
            })} onClick={() => onOptionClick(rightOption)}>
                {rightOption.display}
            </div>
        </div>
    </div>
}
